import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner, Badge } from 'reactstrap';
import axios from 'supports/api';

export default () => {
  const [isOnline, setIsOnline] = useState(false);

  const callServer = () => {
    axios
      .get('/')
      .then(() => {
        setIsOnline(true);
      })
      .catch((err) => {
        setIsOnline(false);
        console.log(err);
      });
  };

  useEffect(() => {
    callServer();
    const checkServer = setInterval(() => {
      callServer();
    }, 60000); // every 60s
    return () => clearInterval(checkServer);
  }, []);

  if (isOnline) {
    return (
      <span>
        <FontAwesomeIcon
          className="mx-auto"
          icon={['fas', 'circle']}
          color="#28a745"
          size="1x"
        />
        <Badge color="success" className="mx-2">
          Online
        </Badge>
      </span>
    );
  }
  return (
    <span>
      <Spinner size="sm" color="danger" />
      <Badge color="danger" className="mx-2">
        Offline
      </Badge>
    </span>
  );
};
