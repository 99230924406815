import React, { Component } from 'react';
import axios from 'supports/api';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkTabs } from 'supports/helpers/permissions';

class AdminAccountingGLGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnPostDisabled: true,
      btnPostText: 'Post',
      txtPostDisabled: false,
      txtUnpostDisabled: false,
      btnUnpostDisabled: true,
      btnUnpostText: 'Unpost',
      datePosting: '',
      dateUnposting: '',
      descriptionPost: '',
      descriptionUnpost: '',
      activeTab: this.props.tabaccess[0],
      outstandingGLJournal: 0,
      postedGLJournal: 0,
    };
  }

  // componentDidMount(){

  // }

  handleDatePosting = (dt) => {
    const data = {
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
    };

    axios
      .post(`/gljournal/getOutstandingGLJournal`, data)
      .then((res) => {
        this.setState({
          outstandingGLJournal: res.data.result,
          datePosting: dt,
          descriptionPost: `Total Outstanding GL Journal ${moment(dt).format(
            'MMM YYYY',
          )} : ${res.data.result}`,
          btnPostDisabled: res.data.result === 0,
        });
      })
      .catch((err) => {
        console.log(err);
        alert(`Terjadi kesalahan pada system. ${err}`);
      });
  };

  handleDateUnposting = (dt) => {
    const data = {
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
    };

    axios
      .post(`/gljournal/getPostedGLJournal`, data)
      .then((res) => {
        this.setState({
          postedGLJournal: res.data.result,
          dateUnposting: dt,
          descriptionUnpost: `Total Posted GL Journal ${moment(dt).format(
            'MMM YYYY',
          )} : ${res.data.result}`,
          btnUnpostDisabled: res.data.result === 0,
        });
      })
      .catch((err) => {
        console.log(err);
        alert(`Terjadi kesalahan pada system. ${err}`);
      });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    this.props.history.replace(`?page=${1}&tab=${tab.toString()}`);
  };

  handlePost = () => {
    if (this.state.datePosting) {
      if (
        window.confirm(
          `Apakah anda yakin ingin posting ${moment(
            this.state.datePosting,
          ).format('MMM YYYY')} ?`,
        )
      ) {
        this.setState({
          btnPostText: 'Posting...',
          btnPostDisabled: true,
          txtPostDisabled: true,
        });

        const data = {
          year: this.state.datePosting.getFullYear(),
          month: this.state.datePosting.getMonth() + 1,
        };
        axios
          .post(`/gljournal/posting`, data)
          .then((res) => {
            alert('Posting success !');
            this.setState({
              btnPostText: 'Post',
              btnPostDisabled: true,
              txtPostDisabled: false,
              descriptionPost: `Total Outstanding GL Journal ${moment(
                this.state.datePosting,
              ).format('MMM YYYY')} : 0`,
            });
          })
          .catch((err) => {
            console.log(err);
            alert(`Terjadi kesalahan pada system. ${err}`);
            this.setState({
              btnPostText: 'Post',
              btnPostDisabled: false,
              txtPostDisabled: false,
            });
          });
      }
    } else {
      alert('bulan belum dipilih');
    }
  };

  handleUnpost = () => {
    if (this.state.dateUnposting) {
      if (
        window.confirm(
          `Apakah anda yakin ingin unposting ${moment(
            this.state.dateUnposting,
          ).format('MMM YYYY')} ?`,
        )
      ) {
        this.setState({
          btnUnpostText: 'Unposting...',
          btnUnpostDisabled: true,
          txtUnpostDisabled: true,
        });

        const data = {
          year: this.state.dateUnposting.getFullYear(),
          month: this.state.dateUnposting.getMonth() + 1,
        };
        axios
          .post(`/gljournal/unposting`, data)
          .then((res) => {
            alert('Unposting success !');
            this.setState({
              btnUnpostText: 'Unpost',
              btnUnpostDisabled: true,
              txtUnpostDisabled: false,
              descriptionUnpost: `Total Posted GL Journal ${moment(
                this.state.dateUnposting,
              ).format('MMM YYYY')} : 0`,
            });
          })
          .catch((err) => {
            console.log(err);
            alert(`Terjadi kesalahan pada system. ${err}`);
            this.setState({
              btnUnpostText: 'Unpost',
              btnUnpostDisabled: false,
              txtUnpostDisabled: false,
            });
          });
      }
    } else {
      alert('bulan belum dipilih');
    }
  };

  renderPosting() {
    return (
      <Card>
        <CardBody>
          <div className="container-fluid white-box">
            <div className="row">
              <div className="col-6 my-auto">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Bulan: </InputGroupText>
                  </InputGroupAddon>
                  <InputGroupAddon addonType="append">
                    <DatePicker
                      selected={this.state.datePosting}
                      onChange={this.handleDatePosting}
                      dateFormat="MMM yyyy"
                      showMonthYearPicker
                      className="form-control"
                      disabled={this.state.txtPostDisabled}
                      id="bulanPosting"
                    />
                  </InputGroupAddon>
                  <InputGroupAddon addonType="append">
                    <Button
                      className="float-right"
                      onClick={this.handlePost}
                      disabled={this.state.btnPostDisabled}
                    >
                      {this.state.btnPostText}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-4 my-auto">
                <textarea
                  className="form-control"
                  rows={3}
                  disabled
                  ref="descriptionPost"
                  value={this.state.descriptionPost}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderUnposting() {
    return (
      <Card>
        <CardBody>
          <div className="container-fluid white-box">
            <div className="row">
              <div className="col-6 my-auto">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Bulan: </InputGroupText>
                  </InputGroupAddon>
                  <InputGroupAddon addonType="append">
                    <DatePicker
                      selected={this.state.dateUnposting}
                      onChange={this.handleDateUnposting}
                      dateFormat="MMM yyyy"
                      showMonthYearPicker
                      className="form-control"
                      disabled={this.state.txtUnpostDisabled}
                    />
                  </InputGroupAddon>
                  <InputGroupAddon addonType="append">
                    <Button
                      className="float-right"
                      onClick={this.handleUnpost}
                      disabled={this.state.btnUnpostDisabled}
                    >
                      {this.state.btnUnpostText}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-4 my-auto">
                <textarea
                  className="form-control"
                  rows={3}
                  disabled
                  ref="descriptionUnpost"
                  value={this.state.descriptionUnpost}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderTabs() {
    const arrJSX = this.props.tabaccess.map((tabName) => {
      return (
        <NavItem key={tabName}>
          <NavLink
            active={this.state.activeTab === tabName}
            onClick={() => {
              this.toggleTab(tabName);
            }}
          >
            {tabName}
          </NavLink>
        </NavItem>
      );
    });

    return arrJSX;
  }

  renderForm() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="white-box py-2">
            <div className="row px-5 py-5">
              <div className="col-12">
                <div className="line-green mb-4" />
                <div className="general-title text-gray">Posting</div>
              </div>
            </div>
            <div className="row px-5 py-3">
              <div className="col-12">
                <Nav tabs>{this.renderTabs()}</Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="Posting">{this.renderPosting()}</TabPane>
                  <TabPane tabId="Unposting">{this.renderUnposting()}</TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderForm();
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  const tabaccess = checkTabs(auth.access, ['Posting', 'Unposting']);
  return { auth, tabaccess };
};

export default withRouter(
  connect(mapStateToProps, {})(AdminAccountingGLGenerator),
);
