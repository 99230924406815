import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';
// import Profile from 'components/dropdowns/Profile';
import logo from 'supports/assets/logo.png';

// import { signOut } from 'redux/modules/auth';
import { API_URL_1 } from 'supports/api-url/apiurl';
import StatusDisplay from './StatusDisplay';
import Burger from './Burger';
import './header.css';

const toggleSideBar = (e) => {
  e.stopPropagation();
  if (document.getElementById('mobileNav').style.width === '250px') {
    document.getElementById('mobileNav').style.width = '0px';
    document.getElementById('db-body').style.filter = 'brightness(1)';
  } else {
    document.getElementById('mobileNav').style.width = '250px';
    document.getElementById('db-body').style.filter = 'brightness(0.6)';
  }
};

export default () => {
  const auth = useSelector((state) => state.auth);
  // const dispatch = useDispatch();

  //   const onSignOutClick = () => {
  //     signOut();
  //     // history.push('/');
  //   };

  const RenderProfile = () => {
    if (auth.email !== '') {
      return (
        <div className="ml-auto">
          <div
            className="mr-5 d-none d-md-flex flex-row"
            style={{ height: '100%' }}
          >
            {/* <Profile toggle={() => this.toggle()} /> */}
            <div className="ml-4 body-text-gray font-weight-bold d-flex flex-row align-items-center">
              {auth.firstName} {auth.lastName}
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  };

  return (
    <Navbar
      color="light"
      expand="md"
      className="d-flex px-0 admin-header d-flex flex-row"
      fixed="top"
    >
      <div className="d-flex flex-row align-items-center">
        <Burger isLoggedIn={auth.email} onClick={toggleSideBar} />
        <Link to="/">
          <img src={logo} alt="logo" className="navbar-logo py-3" />
        </Link>
      </div>
      <div className="ml-5">
        <StatusDisplay />
      </div>
      {API_URL_1 !== 'https://api-v1.purwadhika.com' && (
        <div className="ml-3">THIS IS A MOCK SITE</div>
      )}
      <div className="ml-3">Currently connected to {API_URL_1}</div>
      <RenderProfile />
    </Navbar>
  );
};
