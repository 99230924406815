import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
  Button,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Alert,
} from 'reactstrap';
import CustomPagination from 'components/commons/CustomPagination';
import { useLocation } from 'react-router';
import Loader from 'react-loader-spinner';
import axios from 'supports/api';
import moment from 'moment';
import idLocale from 'moment/locale/id';
import './style.css';
import BackButton from './components/BackButton';
import MentoringModal from './components/MentoringModal';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MentoringList = (props) => {
  const { match } = props;
  const query = useQuery();
  const { attendeeId, programId } = match.params;
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get('page') || 1;
  const itemsPerPage = 10;

  const [listSession, setListSession] = useState([]);

  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalBulkUpdate, setModalBulkUpdate] = useState(false);

  const [deleteData, setDeleteData] = useState({
    programMentoringSessionId: null,
    livestreamSessionId: null,
    schedule: null,
  });
  const [editData, setEditData] = useState({
    programMentoringSessionId: null,
    livestreamSessionId: null,
    meetingId: null,
    meetingPassword: null,
    date: null,
    time: null,
    email: null,
    programMentorId: null,
  });

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const onDismissEdit = () => setVisibleEdit(false);

  const [visibleEditAlert, setVisibleEditAlert] = useState(false);
  const onDismissEditAlert = () => setVisibleEditAlert(false);

  const fetchListSession = useCallback(async () => {
    try {
      const sessions = await axios.get(
        `/v2/program-mentoring-session?attendeeId=${attendeeId}`,
      );
      setListSession(sessions.data.data || []);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [attendeeId]);

  useEffect(() => {
    fetchListSession();
  }, [fetchListSession]);

  const onDeleteClick = (
    programMentoringSessionId,
    livestreamSessionId,
    schedule,
  ) => {
    setModalDelete(true);
    setDeleteData({
      ...deleteData,
      programMentoringSessionId,
      livestreamSessionId,
      schedule,
    });
  };

  const confirmDeleteClick = async () => {
    try {
      const result = await axios.delete(
        `/v2/program-mentoring-session?livestreamSessionId=${deleteData.livestreamSessionId}&programMentoringSessionId=${deleteData.programMentoringSessionId}&schedule=${deleteData.schedule}`,
      );
      if (result.data.status === 'SUCCESS') {
        setDeleteData({
          programMentoringSessionId: null,
          livestreamSessionId: null,
        });
        fetchListSession();
        setModalDelete(false);
        setVisible(false);
      }
    } catch (error) {
      setModalDelete(false);
      setVisible(true);
    }
  };

  const onEditClick = (
    programMentoringSessionId,
    livestreamSessionId,
    meetingId,
    meetingPassword,
    schedule,
    programMentorId,
  ) => {
    setModalEdit(true);
    let rawSchedule = moment(schedule).format('YYYY-MM-DD HH:mm');
    rawSchedule = rawSchedule.split(' ');
    const resultDate = rawSchedule[0];
    const time = rawSchedule[1];
    setEditData({
      ...editData,
      programMentoringSessionId,
      livestreamSessionId,
      meetingId,
      meetingPassword,
      date: resultDate,
      time,
      email: listSession[0].attendee.attendeeEmail,
      programMentorId,
    });
  };

  const onCreateClick = () => {
    setModalCreate(true);
  };

  const onBulkUpdateClick = () => {
    setModalBulkUpdate(true);
  };

  const [inputData, setInputData] = useState({
    date: '',
    time: '',
    meetingId: '',
    meetingPassword: '',
    programMentorId: '',
  });
  const inputDate = (dateInput) => {
    setInputData({ ...inputData, date: dateInput.target.value });
  };
  const inputTime = (timeInput) => {
    setInputData({ ...inputData, time: timeInput.target.value });
  };
  const inputMeetingId = (idInput) => {
    setInputData({
      ...inputData,
      meetingId: idInput.target.value.replace(/[^0-9]/g, ''),
    });
  };
  const inputMeetingPassword = (passwordInput) => {
    setInputData({ ...inputData, meetingPassword: passwordInput.target.value });
  };

  const inputProgramMentorId = (programMentorIdInput) => {
    setInputData({
      ...inputData,
      programMentorId: programMentorIdInput.target.value,
    });
  };

  const [mentorList, setMentorList] = useState(null);

  const fetchMentorList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await axios.get(
        `/v2/program-mentor?programId=${programId}&inHouse=1`,
      );
      setMentorList(result.data.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [programId]);

  useEffect(() => {
    fetchMentorList();
  }, [fetchMentorList]);

  const RenderOptionMentor = () => {
    if (mentorList.length) {
      return mentorList.map((mentor) => {
        return (
          <option key={mentor.id} value={mentor.id}>
            {mentor.name}
          </option>
        );
      });
    }
    return (
      <option key={0} value={0} disabled>
        Belum ada data
      </option>
    );
  };

  const RenderDay = () => {
    moment.locale('id', idLocale);
    const days = moment.weekdays();
    return days.map((day, index) => {
      return (
        <option key={day} value={index + 1}>
          {day}
        </option>
      );
    });
  };

  useEffect(() => {
    if (inputData.date) {
      const current = moment().format('YYYY-MM-DD');
      const check = moment(inputData.date).isBefore(current);
      if (check) {
        setInputData({ ...inputData, date: '' });
        setVisibleEdit(true);
      } else setVisibleEdit(false);
    }
  }, [inputData]);

  const confirmEditClick = async () => {
    const result = {
      date: inputData.date || editData.date,
      time: inputData.time || editData.time,
    };
    try {
      const editResult = await axios.put(
        `/v2/program-mentoring-session?programMentoringSessionId=${editData.programMentoringSessionId}&livestreamSessionId=${editData.livestreamSessionId}`,
        {
          programId,
          attendeeId,
          meetingId: inputData.meetingId || editData.meetingId,
          meetingPassword:
            inputData.meetingPassword || editData.meetingPassword,
          programMentorId:
            inputData.programMentorId || editData.programMentorId,
          email: editData.email,
          schedule: `${result.date} ${result.time}:00`,
          scheduleInit: `${editData.date} ${editData.time}:00`,
        },
      );
      if (editResult) {
        setEditData({
          meetingId: null,
          meetingPassword: null,
          schedule: null,
          email: listSession[0].attendee.attendeeEmail,
        });
        setInputData({
          date: '',
          time: '',
          meetingId: '',
          meetingPassword: '',
        });
        fetchListSession();
        setModalEdit(false);
      }
    } catch (error) {
      setVisibleEditAlert(true);
      setModalEdit(false);
      console.log(error);
    }
  };

  const RenderTable = () => {
    const sessionData = listSession;
    const slicedSessionList = sessionData.slice(
      (page - 1) * itemsPerPage,
      (page - 1) * itemsPerPage + itemsPerPage,
    );

    return slicedSessionList.map((session, index) => {
      return (
        <tr className="d-flex" key={session.id}>
          <td className="col-1">{(page - 1) * itemsPerPage + index + 1}</td>
          <td className="col-2">
            {moment(session.schedule).format('DD-MM-YYYY h:mm a')}
          </td>
          <td className="col-4">{session.livestreamSession?.meetingId}</td>
          <td className="col-3">
            {session.livestreamSession?.meetingPassword}
          </td>
          <td className="col-2 forum-link">
            {session.id && (
              <>
                <Button
                  color="info"
                  size="sm"
                  onClick={() =>
                    onEditClick(
                      session.id,
                      session.livestreamSession.id,
                      session.livestreamSession.meetingId,
                      session.livestreamSession.meetingPassword,
                      session.schedule,
                      session.programMentor.id,
                    )
                  }
                  className="mr-2"
                >
                  Edit
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() =>
                    onDeleteClick(
                      session.id,
                      session.livestreamSession.id,
                      session.schedule,
                    )
                  }
                >
                  Delete
                </Button>
              </>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="body-app">
      <div className="container">
        {loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <Loader type="ThreeDots" color="#53b59f" height={40} width={40} />
          </div>
        ) : (
          <div
            className="p-3"
            style={{
              backgroundColor: '#ffffff',
              height: '100%',
              borderRadius: '6px',
              boxShadow: '0 20px 40px 0 rgba(102, 102, 102, 0.16)',
            }}
          >
            <div className="row d-flex p-3">
              <div className="col-12">
                <h5 className="text-center">Sessions Mentoring</h5>
              </div>
            </div>
            <div className="col-12">
              <div className="col-12 pl-0 pr-0 d-flex justify-content-between">
                <BackButton />
                <div className="d-flex">
                  <Button
                    onClick={onCreateClick}
                    className="btn-cst-primary mb-3 mr-3"
                  >
                    + Create
                  </Button>
                  <Button
                    onClick={onBulkUpdateClick}
                    className="btn-cst-primary mb-3 mr-0"
                  >
                    Edit
                  </Button>
                </div>
              </div>
              <Alert
                color="danger"
                isOpen={visible}
                toggle={onDismiss}
                className="col-4"
              >
                Cannot delete D-1 session
              </Alert>
              <Alert
                color="danger"
                isOpen={visibleEditAlert}
                toggle={onDismissEditAlert}
                className="col-4"
              >
                Cannot edit D-1 session
              </Alert>
              <Table size="sm" striped bordered responsive hover>
                <thead
                  className="font-weight-bold"
                  style={{ fontSize: '14px', color: '#505050' }}
                >
                  <tr className="d-flex">
                    <th className="col-1">No</th>
                    <th className="col-2">SCHEDULE</th>
                    <th className="col-4">MEETING ID</th>
                    <th className="col-3">MEETING PASSWORD</th>
                    <th className="col-2">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <RenderTable />
                </tbody>
              </Table>
              <div className="col-12 d-flex justify-content-center">
                <CustomPagination
                  pages={Math.ceil(listSession.length / itemsPerPage) || 1}
                  active={page}
                />
              </div>
            </div>
          </div>
        )}{' '}
      </div>
      <Modal isOpen={modalEdit} toggle={() => setModalEdit(false)}>
        <ModalHeader toggle={() => setModalEdit(false)}>
          Edit Schedule
        </ModalHeader>
        <ModalBody>
          <Alert color="danger" isOpen={visibleEdit} toggle={onDismissEdit}>
            Cannot select date less than today
          </Alert>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Meetingroom ID </Label>
              <Input
                type="text"
                placeholder="Room ID"
                onChange={inputMeetingId}
                value={editData.meetingId}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Meetingroom Password </Label>
              <Input
                type="text"
                placeholder="Room Password"
                onChange={inputMeetingPassword}
                defaultValue={editData.meetingPassword}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelect">Mentor</Label>
              <Input
                type="select"
                name="select"
                onChange={inputProgramMentorId}
                value={inputData.programMentorId || editData.programMentorId}
              >
                <RenderOptionMentor />
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="exampleDate">Date</Label>
              <Input
                type="date"
                name="date"
                id="exampleDate"
                placeholder="date placeholder"
                onChange={inputDate}
                defaultValue={moment(editData.date).format('YYYY-MM-DD')}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleTime">Time</Label>
              <Input
                type="time"
                name="time"
                id="exampleTime"
                placeholder="time placeholder"
                onChange={inputTime}
                defaultValue={editData.time}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => confirmEditClick()}>
            Edit
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalEdit(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalDelete} toggle={() => setModalDelete(false)}>
        <ModalHeader toggle={() => setModalDelete(false)} />
        <ModalBody>Are you sure delete this session?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => confirmDeleteClick()}>
            Delete
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalDelete(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <MentoringModal
        type="create"
        RenderOptionMentor={RenderOptionMentor}
        isOpen={modalCreate}
        setModalCreate={setModalCreate}
        programId={programId}
        attendeeEmail={query.get('email')}
        attendeeId={attendeeId}
        RenderDay={RenderDay}
        fetchListSession={fetchListSession}
      />
      <MentoringModal
        type="update"
        RenderOptionMentor={RenderOptionMentor}
        isOpen={modalBulkUpdate}
        setModalBulkUpdate={setModalBulkUpdate}
        programId={programId}
        attendeeEmail={props.location.state?.attendeeEmail}
        attendeeId={attendeeId}
        RenderDay={RenderDay}
        fetchListSession={fetchListSession}
      />
    </div>
  );
};

export default MentoringList;
