import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import axios from 'supports/api';
import CustomPagination from 'components/commons/CustomPagination';
import { useLocation, useHistory } from 'react-router-dom';
import { Table, Button, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchApprovalPenerimaan,
  filterApprovalByTujPenerimaan,
  filterApprovalPenerimaan,
  filterApprovalByPym,
} from 'redux/modules/payment';

const TableApproval = ({
  renderData,
  filterPym,
  filterTujPenerimaan,
  click,
  setSelectedId,
  setFromTab,
}) => {
  const itemsPerPage = 10;
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const page = params.get('page');
  const [idLoading, setIdLoading] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const countData = useSelector(({ payment }) => payment.countApprovalPym);

  useEffect(() => {
    if (Math.ceil(countData / itemsPerPage) < page && countData !== 0) {
      history.push('?page=1');
    }
  }, [countData, history, page]);

  const request = useCallback(() => {
    if (!filterPym && filterTujPenerimaan === 'All') {
      dispatch(fetchApprovalPenerimaan(page));
    } else if (filterPym && filterTujPenerimaan !== 'All') {
      dispatch(filterApprovalPenerimaan(page, filterPym, filterTujPenerimaan));
    } else if (filterPym && filterTujPenerimaan === 'All') {
      dispatch(filterApprovalByPym(page, filterPym));
    } else {
      dispatch(filterApprovalByTujPenerimaan(page, filterTujPenerimaan));
    }
  }, [dispatch, page, filterPym, filterTujPenerimaan]);

  const handleReject = (id) => {
    setIsLoading(true);
    setIdLoading(id);
    axios
      .put(`/payment/reject-payment-wihtout-invoice/${id}`)
      .then((res) => {
        request();
        setIsLoading(false);
        setIdLoading(null);
        alert('Reject Success');
      })
      .catch((err) => {
        alert(`Terjadi kesalahan system ${err}`);
        setIsLoading(false);
        setIdLoading(null);
      });
  };

  const handleApprove = (id) => {
    setIsLoading(true);
    setIdLoading(id);

    axios
      .put(`/admin/approve-payment/${id}`)
      .then((res) => {
        request();
        setIsLoading(false);
        setIdLoading(null);
        alert('Approve Payment Success');
      })
      .catch((err) => {
        alert(`Terjadi kesalahan system. ${err}`);
        setIsLoading(false);
        setIdLoading(null);
      });
  };

  const renderTable = () => {
    if (renderData) {
      return renderData.map((val) => {
        const {
          id,
          receiptNumber,
          confirmedBy,
          confirmedAt,
          tujuanPenerimaan,
          nominal,
          accountNumber,
          namaPembayar,
          mutationDate,
        } = val;
        return (
          <tbody>
            <tr>
              <td>{receiptNumber}</td>
              <td>{tujuanPenerimaan}</td>
              <td>Rp. {nominal.toLocaleString()}</td>
              <td>{accountNumber}</td>
              <td>{namaPembayar}</td>
              <td>{moment(mutationDate).format('DD MMM YYYY hh:mm A')}</td>
              <td>
                <div className="text-center">{confirmedBy}</div>
                <div
                  className="mt-1 main-text text-center"
                  style={{ fontSize: '12px' }}
                >
                  {moment(confirmedAt).format('DD MMM YYYY hh:mm A')}
                </div>
              </td>
              <td className="text-center">
                <div className="d-flex flex-column align-items-center">
                  <Button
                    color="success"
                    style={{
                      width: '72px',
                      backgroundColor: 'rgb(50, 178, 128)',
                    }}
                    size="sm"
                    onClick={() => handleApprove(id)}
                  >
                    {isLoading && idLoading === id ? (
                      <Spinner size="sm" color="white" />
                    ) : (
                      'Approve'
                    )}
                  </Button>
                  <Button
                    color="danger"
                    className="mt-1"
                    style={{ width: '72px' }}
                    size="sm"
                    onClick={() => handleReject(id)}
                  >
                    {isLoading && idLoading === id ? (
                      <Spinner size="sm" color="white" />
                    ) : (
                      'Reject'
                    )}
                  </Button>
                  <Button
                    color="secondary"
                    className="mt-1"
                    style={{ width: '72px' }}
                    size="sm"
                    onClick={() => {
                      click();
                      setSelectedId(id);
                      setFromTab(true);
                    }}
                  >
                    Details
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        );
      });
    }
  };

  return (
    <div>
      <div className="general-title text-gray mt-3">Approval</div>
      <div className="mt-3">
        <CustomPagination
          pages={Math.ceil(countData / itemsPerPage) || 1}
          active={page}
        />
      </div>
      <div>
        <Table striped bordered responsive hover>
          <thead
            className="font-weight-bold"
            style={{ fontSize: '14px', color: '#505050' }}
          >
            <tr>
              <th>Payment Receipt</th>
              <th>Tujuan Penerimaan</th>
              <th>Nominal</th>
              <th>Account Number</th>
              <th>Receive From</th>
              <th>Mutation Date</th>
              <th>Confirmed By</th>
              <th>Action</th>
            </tr>
          </thead>
          {renderTable()}
        </Table>
      </div>
    </div>
  );
};

export default TableApproval;
