import React, { useState, useEffect, useCallback } from 'react';
import axios from 'supports/api';
import { Button, Table, Badge } from 'reactstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import PCard from 'pages/finance/transactions/main/PCard';
import CustomPagination from 'components/commons/CustomPagination';
import OnlineCourseSessionFormModal from './OnlineCourseSessionFormModal';
import ModalDelete from '../components/ModalDelete';

const OnlineCourseSessionListPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get('page') || 1;
  const itemsPerPage = 10;
  const { onlineCourseModuleId } = useParams();

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(false);

  const [courseFormModalIsOpen, setCourseFormModalIsOpen] = useState(false);
  const [onlineCourseModule, setOnlineCourseModule] = useState({});
  const [onlineCourseSessionList, setOnlineCourseSessionList] = useState([]);
  const [
    selectedOnlineCourseSession,
    setSelectedOnlineCourseSession,
  ] = useState(null);

  const getOnlineCourseModuleData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `/v2/online-course/module/${onlineCourseModuleId}`,
      );
      const { courseSessions, ...courseModule } = data?.data;
      setOnlineCourseModule(courseModule);
      setOnlineCourseSessionList(courseSessions);
    } catch (err) {
      console.log(err);
    }
  }, [onlineCourseModuleId]);

  useEffect(() => {
    getOnlineCourseModuleData();
  }, [getOnlineCourseModuleData]);

  const onButtonDeleteClick = async () => {
    try {
      const result = await axios.delete(
        `v2/online-course-session/${selectedDeleteId}`,
      );
      if (result.data.status === 'SUCCESS') {
        setSelectedDeleteId(null);
        getOnlineCourseModuleData();
        setOpenModalDelete(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  const TableData = () => {
    const arrJSX = onlineCourseSessionList
      ?.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage,
      )
      .map((courseSession) => {
        return (
          <tr key={courseSession?.title}>
            <td>{courseSession?.order}</td>
            <td>{courseSession?.title}</td>
            <td>{courseSession?.courseTopics?.length}</td>
            <td>
              <Link to={`${location.pathname}/${courseSession?.id}`}>
                <Button size="sm" className="mr-1">
                  Details
                </Button>
              </Link>
              <Button
                className="mr-1"
                size="sm"
                onClick={() => {
                  setCourseFormModalIsOpen(true);
                  setSelectedOnlineCourseSession(courseSession);
                }}
              >
                Edit
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  setOpenModalDelete(true);
                  setSelectedDeleteId(courseSession.id);
                }}
              >
                Delete
              </Button>
            </td>
          </tr>
        );
      });

    return arrJSX;
  };

  return (
    <div className="container" style={{ marginTop: '40px' }}>
      <div className="d-flex flex-row justify-content-between mb-2">
        <div
          className="font-weight-bold"
          style={{ fontSize: '32px', color: '#505050' }}
        >
          {onlineCourseModule?.title} <Badge>Module</Badge>
        </div>
        <Link
          to={`${location?.pathname.slice(
            0,
            location?.pathname.lastIndexOf('/'),
          )}`}
          className="mt-auto"
        >
          <Button color="danger">Back</Button>
        </Link>
      </div>
      {/* <HeaderDetails /> */}
      <div className="d-flex justify-content-between mb-2">
        <CustomPagination
          pages={Math.ceil(onlineCourseSessionList?.length / itemsPerPage) || 1}
          active={page}
        />
        <Button
          color="success"
          style={{ backgroundColor: '#32b280' }}
          onClick={() => {
            setCourseFormModalIsOpen(true);
            setSelectedOnlineCourseSession(null);
          }}
        >
          Add Session
        </Button>
      </div>
      <PCard style={{ minHeight: '600px' }}>
        <div className="d-flex flex-row justify-content-between">
          <Table striped bordered responsive hover>
            <thead
              className="font-weight-bold"
              style={{ fontSize: '14px', color: '#505050' }}
            >
              <tr>
                <th>#</th>
                <th>SESSION</th>
                <th>TOPICS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              <TableData />
            </tbody>
          </Table>
        </div>
      </PCard>
      <OnlineCourseSessionFormModal
        isOpen={courseFormModalIsOpen}
        toggle={() => setCourseFormModalIsOpen(!courseFormModalIsOpen)}
        onlineCourseModuleId={onlineCourseModule?.id}
        lastOrderNumber={onlineCourseSessionList?.length}
        selectedCourseSession={selectedOnlineCourseSession}
        refresh={() => {
          getOnlineCourseModuleData();
          setCourseFormModalIsOpen(false);
          setSelectedOnlineCourseSession(null);
        }}
      />
      <ModalDelete
        isOpen={openModalDelete}
        toggle={() => setOpenModalDelete(!openModalDelete)}
        onButtonDeleteClick={onButtonDeleteClick}
      />
    </div>
  );
};

export default OnlineCourseSessionListPage;
