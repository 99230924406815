import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse } from 'reactstrap';
import KTPImageModal from 'components/Transactions/create-transaction/modals/ktp-image-modal';
import NPWPImageModal from 'components/Transactions/create-transaction/modals/npwp-image-modal';
import EditUserModal from './EditUserModal';
import StatusLabel from './StatusLabel';
import MembershipLabel from './MembershipLabel';
import './style.css';

const API_URL_1 = process.env.REACT_APP_BASE_URL;

const ShowUserData = () => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [KTPModalIsOpen, setKTPModalIsOpen] = useState();
  const [NPWPModalIsOpen, setNPWPModalIsOpen] = useState();
  const userData = useSelector((state) => state.trxUser.userData);
  const {
    firstName,
    lastName,
    //   email,
    phone,
    isVerified,
    membership,
    profilePicture,
    identityCard,
    // identityCardImg,
    npwp,
    // npwpImg,
    address,
  } = userData;

  return (
    <Collapse isOpen={!!userData?.id}>
      <div className="d-flex flex-column p-3">
        <div className="d-inline-flex flex-column flex-sm-column flex-md-row">
          <div className="d-flex flex-row py-1 mr-3">
            <img
              src={API_URL_1 + profilePicture}
              alt={profilePicture || 'noimage'}
              style={{ height: 100, width: 100 }}
              className="mr-3 border"
            />
          </div>
          <div className="d-flex flex-column flex-sm-column flex-md-row py-1">
            <div className="pr-4">
              <div className="font-weight-lighter" style={{ fontSize: '14px' }}>
                Name
              </div>
              <div className="mb-1 font-weight-bold">
                {firstName} {lastName}
              </div>
              <div className="font-weight-lighter" style={{ fontSize: '14px' }}>
                Status
              </div>
              <div className="mb-1">
                <StatusLabel isVerified={isVerified} />
              </div>
              {/* <div className="font-weight-lighter" style={{ fontSize: '!2px' }}>Email</div>
                    <div className="pb-1">{email}</div> */}
            </div>
            <div className="pr-4">
              <div className="font-weight-lighter" style={{ fontSize: '14px' }}>
                Phone
              </div>
              <div className="mb-1 font-weight-bold">{phone}</div>
              <div className="font-weight-lighter" style={{ fontSize: '14px' }}>
                KTP
              </div>
              <div className="mb-1 font-weight-bold">
                {identityCard || 'N/A'}{' '}
                {identityCard && (
                  <FontAwesomeIcon
                    icon={['fas', 'image']}
                    onClick={() => setKTPModalIsOpen(true)}
                    className="image-link"
                  />
                )}
              </div>
            </div>
            <div className="pr-4">
              <div className="font-weight-lighter" style={{ fontSize: '14px' }}>
                Membership
              </div>
              <div className="mb-1">
                <MembershipLabel {...membership} />
              </div>
              <div className="font-weight-lighter" style={{ fontSize: '14px' }}>
                NPWP
              </div>
              <div className="mb-1 font-weight-bold">
                {npwp || 'N/A'}{' '}
                {npwp && (
                  <FontAwesomeIcon
                    icon={['fas', 'image']}
                    onClick={() => setNPWPModalIsOpen(true)}
                    className="image-link"
                  />
                )}
              </div>
            </div>
            <div className="pr-4">
              <div className="font-weight-lighter" style={{ fontSize: '14px' }}>
                Address
              </div>
              <div className="mb-1 font-weight-bold">{address || 'N/A'}</div>
            </div>
            <div>
              <Button
                onClick={() => {
                  setEditModalIsOpen(true);
                }}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
        <EditUserModal
          isOpen={editModalIsOpen}
          toggle={() => {
            setEditModalIsOpen(!editModalIsOpen);
          }}
        />
        <KTPImageModal
          isOpen={KTPModalIsOpen}
          toggle={() => setKTPModalIsOpen(!KTPModalIsOpen)}
        />
        <NPWPImageModal
          isOpen={NPWPModalIsOpen}
          toggle={() => setNPWPModalIsOpen(!NPWPModalIsOpen)}
        />
      </div>
    </Collapse>
  );
};

export default ShowUserData;
