import React, { Component } from 'react';
import axios from 'supports/api';
import {
  CustomInput,
  Button,
  InputGroup,
  InputGroupAddon,
  Badge,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { checkForm } from 'supports/helpers/permissions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import GeneralTooltips from './commons/GeneralTooltips';
import HTMLEditor from './HTMLEditor';
import HTMLEditor2 from './HTMLEditor2';

class AdminScholarshipCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branches: [], // selection
      programs: [], // selection
      filteredPrograms: [],
      selectedProgram: {},
      filteredSchedule: [],

      selectedProductItemId: 0,
      selectedBranchId: 0,
      selectedCategoryId: 0,
      selectedProgramId: 0,

      branchId: 0,
      provider: '',
      name: '',
      about: '',
      aboutDetail: '',
      logoObject: {},
      logoName: 'Pilih Gambar',
      logoURL: '',
      imageObject: {},
      imageName: 'Pilih Gambar',
      imageURL: '',
      aboutProvider: '',
      information: [{ title: '', content: '' }],
      procedure: [{ title: '', content: '' }],
      registrationEndDate: moment().startOf('date').toDate(),
      tooltip: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      access: false,
    };
    this.handleRegistrationEndDate = this.handleRegistrationEndDate.bind(this);
  }

  componentDidMount() {
    if (this.props.render) {
      this.getAllData();
    }
  }

  getAllData() {
    axios
      .get(`/branch/all`)
      .then((res) => {
        this.setState({ branches: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/admin/program/live`)
      .then((res) => {
        this.setState({ programs: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleRegistrationEndDate(date) {
    this.setState({
      registrationEndDate: date,
    });
  }

  handleInput(state, index, value) {
    const tempArr = this.state[`${state}`].slice();
    tempArr[index].item = value;
    this.setState({ [`${state}`]: tempArr });
  }

  handleInput2(type, input) {
    this.setState({ [type]: input });
  }

  handleInput3(state, index, value, prop) {
    const tempArr = this.state[`${state}`].slice();
    tempArr[index][prop] = value;
    this.setState({ [`${state}`]: tempArr });
  }

  handleNumber(value) {
    const number = parseInt(value.split(',').join(''));
    if (value.split(',').join('') === '') {
      this.setState({ normalPriceDisplayed: '0', normalPrice: 0 });
    } else {
      this.setState({
        normalPriceDisplayed: number.toLocaleString(),
        normalPrice: number,
      });
    }
  }

  allowNumbersOnly(e) {
    const code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }

  handleLogo() {
    const file = document.getElementById('logoImage').files[0];
    if (file) {
      this.setState({
        logoName: file.name,
        logoObject: file,
        logoURL: URL.createObjectURL(file),
      });
    }
  }

  handleImage() {
    const file = document.getElementById('image').files[0];
    if (file) {
      this.setState({
        imageName: file.name,
        imageObject: file,
        imageURL: URL.createObjectURL(file),
      });
    }
  }

  async handleDelete(state, index) {
    console.log(this.state[`${state}`]);
    const tempArr = this.state[`${state}`].slice();
    tempArr.splice(index, 1);
    if (this.state[`${state}`].length > 1) {
      await this.setState({ [`${state}`]: tempArr });
    } else {
      alert('Minimal harus ada 1');
    }
    console.log(this.state[`${state}`]);
  }

  async handleDeleteEnd(state) {
    console.log(this.state[`${state}`]);
    const tempArr = this.state[`${state}`].slice();
    tempArr.pop();
    if (this.state[`${state}`].length > 1) {
      await this.setState({ [`${state}`]: tempArr });
    } else {
      alert('Minimal harus ada 1');
    }
    console.log(this.state[`${state}`]);
  }

  onAddInput(state) {
    const temp = this.state[`${state}`];
    temp.push({ item: '' });
    this.setState({ [`${state}`]: temp });
  }

  onAddInput2(state, prop) {
    const temp = this.state[`${state}`];
    temp.push(prop);
    this.setState({ [`${state}`]: temp });
  }

  printEverything() {
    console.log(this.state.selectedProductItemId);
    console.log(this.state.selectedBranchId);
    console.log(this.state.registrationEndDate);
    console.log(this.state.name);
    console.log(this.state.about);
    console.log(this.state.aboutDetail);
    console.log(this.state.logoName);
    console.log(this.state.imageName);
    console.log(this.state.aboutProvider);
    console.log(this.state.information);
    console.log(this.state.procedure);
  }

  async onCreateScholarship() {
    // this.printEverything()
    await this.checkInput();
    if (this.state.access === true) {
      if (window.confirm('Are you sure?')) {
        const formData = new FormData();
        const headers = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };

        formData.append('productItemId', this.state.selectedProductItemId);
        formData.append('provider', this.state.provider);
        formData.append('name', this.state.name);
        formData.append('about', this.state.about);
        formData.append('aboutDetail', this.state.aboutDetail);
        formData.append('logo', this.state.logoObject);
        formData.append('image', this.state.imageObject);
        formData.append('aboutProvider', this.state.aboutProvider);
        formData.append('information', JSON.stringify(this.state.information));
        formData.append('procedure', JSON.stringify(this.state.procedure));
        formData.append('registrationEndDate', this.state.registrationEndDate);

        axios
          .post(`/scholarship/create`, formData, headers)
          .then((res) => {
            alert('Membuat Scholarship Baru Success');
            this.props.history.push('/admin/scholarship-manage?page=1');
            console.log(res);
          })
          .catch((err) => {
            alert('Membuat Scholarship Baru Gagal');
            console.log(err);
          });
      }
    } else {
      alert('Mohon lengkapi form ini.');
    }
  }

  async checkInput() {
    const tempArr = this.state.tooltip.slice();
    let access = true;
    this.state.provider.length === 0
      ? (function () {
          tempArr[0] = true;
          access = false;
        })()
      : (tempArr[0] = false);
    this.state.name.length === 0
      ? (function () {
          tempArr[1] = true;
          access = false;
        })()
      : (tempArr[1] = false);
    this.state.logoName === 'Pilih Gambar'
      ? (function () {
          tempArr[2] = true;
          access = false;
        })()
      : (tempArr[2] = false);
    this.state.imageName === 'Pilih Gambar'
      ? (function () {
          tempArr[3] = true;
          access = false;
        })()
      : (tempArr[3] = false);
    this.state.about.length === 0
      ? (function () {
          tempArr[4] = true;
          access = false;
        })()
      : (tempArr[4] = false);
    this.state.aboutDetail.length === 0
      ? (function () {
          tempArr[5] = true;
          access = false;
        })()
      : (tempArr[5] = false);
    this.state.aboutProvider.length === 0
      ? (function () {
          tempArr[6] = true;
          access = false;
        })()
      : (tempArr[6] = false);
    let informationStatus = true;
    this.state.information.forEach((item) => {
      if (item.title === '' || item.content === '') {
        informationStatus = false;
      }
    });
    informationStatus === false
      ? (function () {
          tempArr[7] = true;
          access = false;
        })()
      : (tempArr[7] = false);
    let procedureStatus = true;
    this.state.procedure.forEach((item) => {
      if (item.title === '' || item.content === '') {
        procedureStatus = false;
      }
    });
    procedureStatus === false
      ? (function () {
          tempArr[8] = true;
          access = false;
        })()
      : (tempArr[8] = false);
    console.log(tempArr);
    await this.setState({ tooltip: tempArr, access });
  }

  closeTooltip(index) {
    const tempArr = this.state.tooltip.slice();
    if (typeof index === 'object') {
      index.forEach((item) => {
        tempArr[item] = false;
      });
      this.setState({ tooltip: tempArr });
    } else {
      tempArr[index] = false;
      this.setState({ tooltip: tempArr });
    }
  }

  onCategorySelect(selectedCategoryId) {
    this.setState({
      selectedCategoryId,
      selectedProgramId: 0,
      selectedBranchId: 0,
      selectedProductItemId: 0,
    });
    this.filterPrograms(selectedCategoryId);
  }

  onProgramSelect(selectedProgramId) {
    this.setState({
      selectedProgramId,
      selectedBranchId: 0,
      selectedProductItemId: 0,
    });
    this.filterProductItems(selectedProgramId);
  }

  onBranchSelect(selectedBranchId) {
    this.setState({
      selectedBranchId,
      selectedProductItemId: 0,
    });
    this.filterSchedule(selectedBranchId);
  }

  onScheduleSelect(scheduleId) {
    // var parsedItem = JSON.parse(item)
    let normalPrice = 0;
    let registrationFee = 0;
    this.state.filteredSchedule.forEach((item, index) => {
      if (parseInt(item.id) === parseInt(scheduleId)) {
        console.log('check', item);
        normalPrice = item.normalPrice;
        registrationFee = item.registrationFee;
      }
    });
    this.setState({
      selectedProductItemId: scheduleId,
      selectedNormalPrice: normalPrice,
      registrationFee,
    });
  }

  filterPrograms(selectedCategoryId) {
    const filteredPrograms = [];
    this.state.programs.forEach((item, index) => {
      if (parseInt(selectedCategoryId) === parseInt(item.program_categoryId)) {
        filteredPrograms.push(item);
      }
    });
    this.setState({ filteredPrograms });
  }

  filterProductItems(selectedProgramId) {
    let selectedProgram = '';
    this.state.programs.forEach((item, index) => {
      if (parseInt(selectedProgramId) === parseInt(item.id)) {
        selectedProgram = item;
      }
    });
    this.setState({ selectedProgram });
  }

  filterSchedule(selectedBranchId) {
    const filteredSchedule = [];
    this.state.selectedProgram.programs.forEach((item, index) => {
      if (parseInt(selectedBranchId) === parseInt(item.branchId)) {
        filteredSchedule.push(item);
      }
    });

    this.setState({ filteredSchedule });
  }

  renderCategorySelect() {
    return (
      <div className="col-6">
        <div className="tag-gray dker font-weight-bold mb-2">
          Pilih Kategori :{' '}
        </div>
        <select
          className="form-control"
          defaultValue={0}
          id="categoryId"
          ref="categoryId"
          onChange={() => {
            this.onCategorySelect(this.refs.categoryId.value);
          }}
        >
          <option value={0} disabled hidden>
            Pilih Kategori
          </option>
          <option value={1}>Job Connector</option>
          <option value={2}>Startup Incubation</option>
          <option value={3}>Part-Time Course</option>
          <option value={6}>Code Starter for Kids</option>
        </select>
      </div>
    );
  }

  renderProgramSelect() {
    const arrJSX = this.state.filteredPrograms.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });
    if (this.state.selectedCategoryId !== 0) {
      return (
        <div className="col-6">
          <div className="tag-gray dker font-weight-bold mb-2">
            Pilih Program :
          </div>
          <select
            className="form-control"
            ref="programId"
            defaultValue={0}
            id="programId"
            value={this.state.selectedProgramId}
            onChange={() => this.onProgramSelect(this.refs.programId.value)}
          >
            <option value={0} disabled hidden>
              Pilih Program
            </option>
            {arrJSX}
          </select>
        </div>
      );
    }
  }

  renderBranches() {
    const arrJSX = this.state.branches.map((item) => {
      return (
        <option key={`branch${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });

    if (this.state.selectedProgramId) {
      return (
        <div className="col-6">
          <div className="tag-gray dker font-weight-bold mb-2">
            Pilih Lokasi :
          </div>
          <select
            className="form-control"
            ref="branch"
            defaultValue={0}
            id="branch"
            value={this.state.selectedBranchId}
            onChange={() => this.onBranchSelect(this.refs.branch.value)}
          >
            <option value={0} disabled hidden>
              Pilih Lokasi
            </option>
            {arrJSX}
          </select>
        </div>
      );
    }
  }

  renderSchedules() {
    const arrJSX = this.state.filteredSchedule.map((item, index) => {
      return (
        <option key={`productItem${item.id}`} value={item.id}>
          {item.code} {moment(item.startDate).format('DD MMM').toString()} -{' '}
          {moment(item.endDate).format('DD MMM YYYY').toString()}
        </option>
      );
    });

    if (this.state.selectedBranchId) {
      return (
        <div className="col-6">
          <div className="tag-gray dker font-weight-bold mb-2">
            Pilih Schedule :
          </div>
          <select
            className="form-control"
            ref="scheduleId"
            defaultValue={0}
            id="scheduleId"
            value={this.state.selectedProductItemId}
            onChange={() => this.onScheduleSelect(this.refs.scheduleId.value)}
          >
            <option value={0} disabled hidden>
              Pilih Schedule
            </option>
            {arrJSX}
          </select>
        </div>
      );
    }
  }

  renderInformationInput() {
    const arrJSX = this.state.information.map((item, index) => {
      return (
        <div key={item.id} className="row my-2">
          <div className="col-1 my-auto">
            <Badge>0{index + 1}</Badge>
          </div>
          <div className="col-11">
            <div className="row mb-3">
              <div className="col-12">
                <div className="tag-gray dker font-weight-bold mb-3">
                  Title :
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={item.title}
                  ref={`inputinformationtitle${index}`}
                  onChange={() =>
                    this.handleInput3(
                      'information',
                      index,
                      this.refs[`inputinformationtitle${index}`].value,
                      'title',
                    )
                  }
                  onFocus={() => this.closeTooltip(7)}
                />
              </div>
              {/* <div className="offset-1 col-1">
                                <Button color="danger" onClick={() => {this.handleDelete("faq", index)}}>Delete</Button>
                            </div> */}
            </div>
            <div className="row mb-3">
              <div className="py-3 col-12">
                <div className="tag-gray dker font-weight-bold mb-3">
                  Content :
                </div>
                <HTMLEditor2
                  id={`inputinformationcontent${index}`}
                  handleInput={(state, index, value, prop) =>
                    this.handleInput3(state, index, value, prop)
                  }
                  value={item.content}
                  name="information"
                  index={index}
                  prop="content"
                  closeTooltip={() => this.closeTooltip(7)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });

    return arrJSX;
  }

  renderProcedureInput() {
    const arrJSX = this.state.procedure.map((item, index) => {
      return (
        <div key={item.id} className="row my-2">
          <div className="col-1 my-auto">
            <Badge>0{index + 1}</Badge>
          </div>
          <div className="col-11">
            <div className="row mb-3">
              <div className="col-12">
                <div className="tag-gray dker font-weight-bold mb-3">
                  Title :
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={item.title}
                  ref={`inputproceduretitle${index}`}
                  onChange={() =>
                    this.handleInput3(
                      'procedure',
                      index,
                      this.refs[`inputproceduretitle${index}`].value,
                      'title',
                    )
                  }
                  onFocus={() => this.closeTooltip(8)}
                />
              </div>
              {/* <div className="offset-1 col-1">
                                <Button color="danger" onClick={() => {this.handleDelete("faq", index)}}>Delete</Button>
                            </div> */}
            </div>
            <div className="row mb-3">
              <div className="py-3 col-12">
                <div className="tag-gray dker font-weight-bold mb-3">
                  Content :
                </div>
                <HTMLEditor2
                  id={`inputprocedurecontent${index}`}
                  handleInput={(state, index, value, prop) =>
                    this.handleInput3(state, index, value, prop)
                  }
                  value={item.content}
                  name="procedure"
                  index={index}
                  prop="content"
                  closeTooltip={() => this.closeTooltip(8)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });

    return arrJSX;
  }

  renderForm() {
    if (this.state.selectedProductItemId !== 0) {
      return (
        <div>
          <div className="row mb-3">
            <div className="py-3 col-2">
              <div className="tag-gray dker font-weight-bold mb-3">
                Registration End Date:
              </div>
              <DatePicker
                selected={this.state.registrationEndDate}
                onChange={this.handleRegistrationEndDate}
                dateFormat="dd/MM/yyy"
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="py-3 col-12">
              <div className="tag-gray dker font-weight-bold mb-3">
                <div className="d-inline-block" id="providerTag">
                  Provider :
                </div>
                <GeneralTooltips
                  position="right"
                  toggle={this.state.tooltip[0]}
                  id="providerTag"
                  content="masukkan provider"
                />
              </div>
              <input
                type="text"
                className="form-control"
                ref="provider"
                placeholder="Sample Event Title"
                id="provider"
                value={this.state.provider}
                onChange={() =>
                  this.handleInput2('provider', this.refs.provider.value)
                }
                onFocus={() => this.closeTooltip(0)}
              />
            </div>
          </div>
          <div className="row">
            <div className="py-3 col-12">
              <div className="tag-gray dker font-weight-bold mb-3">
                <div className="d-inline-block" id="nameTag">
                  Name :
                </div>
                <GeneralTooltips
                  position="right"
                  toggle={this.state.tooltip[1]}
                  id="nameTag"
                  content="masukkan nama scholarship"
                />
              </div>
              <input
                type="text"
                className="form-control"
                ref="scholarshipName"
                placeholder="Sample Event Title"
                id="scholarshipName"
                value={this.state.name}
                onChange={() =>
                  this.handleInput2('name', this.refs.scholarshipName.value)
                }
                onFocus={() => this.closeTooltip(1)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="py-3 col-6">
              <div className="tag-gray dker font-weight-bold mb-3">
                <div className="d-inline-block" id="logoTag">
                  Upload Logo:
                </div>
                <GeneralTooltips
                  position="right"
                  toggle={this.state.tooltip[2]}
                  id="logoTag"
                  content="pilih gambar"
                />
              </div>
              <CustomInput
                type="file"
                id="logoImage"
                name="logoImage"
                label={this.state.logoName}
                onChange={() => this.handleLogo()}
              />
            </div>
            <div className="py-3 col-3">
              <div className="tag-gray dker font-weight-bold mb-3">
                Preview:
              </div>
              <img src={this.state.logoURL} alt="" className="w-100" />
            </div>
          </div>
          <div className="row mb-3">
            <div className="py-3 col-6">
              <div className="tag-gray dker font-weight-bold mb-3">
                <div className="d-inline-block" id="imageTag">
                  Upload Image:
                </div>
                <GeneralTooltips
                  position="right"
                  toggle={this.state.tooltip[3]}
                  id="imageTag"
                  content="pilih gambar"
                />
              </div>
              <CustomInput
                type="file"
                id="image"
                name="image"
                label={this.state.imageName}
                onChange={() => this.handleImage()}
              />
            </div>
            <div className="py-3 col-3">
              <div className="tag-gray dker font-weight-bold mb-3">
                Preview:
              </div>
              <img src={this.state.imageURL} alt="" className="w-100" />
            </div>
          </div>
          <div className="row">
            <div className="py-3 col-12">
              <div className="tag-gray dker font-weight-bold mb-3">
                <div className="d-inline-block" id="aboutTag">
                  about :
                </div>
                <GeneralTooltips
                  position="right"
                  toggle={this.state.tooltip[4]}
                  id="aboutTag"
                  content="masukkan tentang scholarship"
                />
              </div>
              <textarea
                className="form-control"
                type="text"
                ref="about"
                placeholder="Maximum of ~200 char."
                rows={3}
                id="about"
                value={this.state.about}
                onChange={() =>
                  this.handleInput2('about', this.refs.about.value)
                }
                onFocus={() => this.closeTooltip(4)}
              />
            </div>
          </div>
          <div className="row">
            <div className="py-3 col-12">
              <div className="tag-gray dker font-weight-bold mb-3">
                <div className="d-inline-block" id="aboutDetailTag">
                  about detail :
                </div>
                <GeneralTooltips
                  position="right"
                  toggle={this.state.tooltip[5]}
                  id="aboutDetailTag"
                  content="masukkan detail scholarship"
                />
              </div>
              <textarea
                className="form-control"
                type="text"
                ref="aboutDetail"
                placeholder="Maximum of ~200 char."
                rows={3}
                id="aboutDetail"
                value={this.state.aboutDetail}
                onChange={() =>
                  this.handleInput2('aboutDetail', this.refs.aboutDetail.value)
                }
                onFocus={() => this.closeTooltip(5)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="py-3 col-12">
              <div className="tag-gray dker font-weight-bold mb-3">
                <div className="d-inline-block" id="aboutProviderTag">
                  about provider :
                </div>
                <GeneralTooltips
                  position="right"
                  toggle={this.state.tooltip[6]}
                  id="aboutProviderTag"
                  content="masukkan tentang provider"
                />
              </div>
              <div>
                <HTMLEditor
                  id="aboutProvider"
                  handleInput={(type, input) => this.handleInput2(type, input)}
                  name="aboutProvider"
                  closeTooltip={() => this.closeTooltip(6)}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="py-3 col-12">
              <div className="row mb-3">
                <div className="col">
                  <div className="tag-gray dker font-weight-bold">
                    <div className="d-inline-block mr-2">Information :</div>
                    <div className="d-inline-block" id="informationTag">
                      <InputGroup>
                        <Button
                          color="success"
                          onClick={() => {
                            this.onAddInput2('information', {
                              title: '',
                              content: '',
                            });
                          }}
                        >
                          Add +
                        </Button>
                        <InputGroupAddon addonType="append">
                          <Button
                            color="danger"
                            onClick={() => {
                              this.handleDeleteEnd('information');
                            }}
                          >
                            Decrease -
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </div>
                    <GeneralTooltips
                      position="right"
                      toggle={this.state.tooltip[7]}
                      id="informationTag"
                      content="pastikan semua kolom information terisi"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">{this.renderInformationInput()}</div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="py-3 col-12">
              <div className="row mb-3">
                <div className="col">
                  <div className="tag-gray dker font-weight-bold">
                    <div className="d-inline-block mr-2">Procedure :</div>
                    <div className="d-inline-block" id="procedureTag">
                      <InputGroup>
                        <Button
                          color="success"
                          onClick={() => {
                            this.onAddInput2('procedure', {
                              title: '',
                              content: '',
                            });
                          }}
                        >
                          Add +
                        </Button>
                        <InputGroupAddon addonType="append">
                          <Button
                            color="danger"
                            onClick={() => {
                              this.handleDeleteEnd('procedure');
                            }}
                          >
                            Decrease -
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </div>
                    <GeneralTooltips
                      position="right"
                      toggle={this.state.tooltip[8]}
                      id="procedureTag"
                      content="pastikan semua kolom procedure terisi"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">{this.renderProcedureInput()}</div>
              </div>
            </div>
          </div>
          <div className="mt-5 row">
            <div className="offset-8">
              <input
                type="button"
                className="btn-rounded-green-border float-right"
                value="Create"
                onClick={() => {
                  this.onCreateScholarship();
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  renderCreateScholarship() {
    return (
      <div className="white-box my-5 container-fluid">
        <div className="row px-5 py-5">
          <div className="col-10">
            <div className="row mb-3">
              <div className="col-6">
                <div className="line-green mb-3" />
                <div className="general-title text-gray">New Scholarship</div>
              </div>
            </div>
            <div className="row py-2">
              {this.renderCategorySelect()}
              {this.renderProgramSelect()}
            </div>
            <div className="row py-2">
              {this.renderBranches()}
              {this.renderSchedules()}
            </div>
            {this.renderForm()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.render) {
      return this.renderCreateScholarship();
    }

    return <Redirect to="/" />;
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const render = checkForm(auth.access, 'Scholarship Create');
  return { auth, render };
};

export default withRouter(connect(mapStateToProps, {})(AdminScholarshipCreate));
