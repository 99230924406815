import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Collapse, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const SearchBar = ({
  searchParams,
  handleSearchParams,
  handleStartDate,
  handleEndDate,
  journalDateFrom,
  journalDateTo,
  onHandleJournalType,
  journalType,
  handleFetch,
  handleStatus,
  journalStatus,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const loading = useSelector((state) => state.glJournal.loading);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div
      className="d-flex flex-column mr-md-3"
      style={{ minWidth: '358px', maxWidth: '600px' }}
    >
      <div className="w-100 mr-md-5 my-3">
        <div style={{ width: '100%' }}>
          <button
            type="button"
            className="btn btn-sm btn-secondary my-2 btn-collapse-pym"
            onClick={toggle}
          >
            <div className="d-flex justify-content-between">
              <span>Filter by</span>
              <span>
                <FontAwesomeIcon icon={!isOpen ? faChevronDown : faChevronUp} />
              </span>
            </div>
          </button>
        </div>
        <Collapse isOpen={isOpen}>
          <Card>
            <CardBody>
              <div className="mb-3 d-flex flex-row">
                <div
                  style={{ width: '25%' }}
                  className="tag-gray font-weight-bold dker mr-2 d-flex align-items-center"
                >
                  Ref. Number
                </div>
                <div style={{ width: '75%' }}>
                  <input
                    type="text"
                    className="form-control"
                    value={searchParams}
                    onChange={handleSearchParams}
                  />
                </div>
              </div>
              <div className="mb-3 w-100 d-flex flex-row">
                <div
                  className="tag-gray dker font-weight-bold mr-2 d-flex align-items-center"
                  style={{ width: '25%' }}
                >
                  Date From
                </div>
                <div style={{ width: '75%' }} className="d-flex flex-row">
                  <DatePicker
                    className="form-control"
                    selected={journalDateFrom}
                    selectsStart
                    startDate={journalDateFrom}
                    endDate={journalDateTo}
                    dateFormat="dd/MM/yyy"
                    onChange={handleStartDate}
                    showMonthYearDropdown
                    minDate={moment().subtract(6, 'months').toDate()}
                    maxDate={moment().add(6, 'months').toDate()}
                  />
                  <div className="tag-gray dker font-weight-bold mx-2 d-flex align-items-center">
                    To
                  </div>
                  <DatePicker
                    className="form-control"
                    selected={journalDateTo}
                    selectsEnd
                    startDate={journalDateFrom}
                    endDate={journalDateTo}
                    dateFormat="dd/MM/yyy"
                    onChange={handleEndDate}
                    showMonthYearDropdown
                    minDate={moment().subtract(6, 'months').toDate()}
                    maxDate={moment().add(6, 'months').toDate()}
                  />
                </div>
              </div>
              <div className="mb-3 d-flex flex-row">
                <div
                  className="tag-gray dker font-weight-bold  mr-2 d-flex align-items-center"
                  style={{ width: '25%' }}
                >
                  Journal Type
                </div>
                <div style={{ width: '75%' }}>
                  <input
                    type="text"
                    className="form-control"
                    value={journalType}
                    onChange={onHandleJournalType}
                  />
                </div>
              </div>
              <div className="d-flex flex-row">
                <div
                  className="tag-gray dker font-weight-bold  mr-2 d-flex align-items-center"
                  style={{ width: '25%' }}
                >
                  Status Approval
                </div>
                <div
                  style={{ width: '75%' }}
                  className="d-flex flex-row  align-items-center"
                >
                  <FormGroup check>
                    <Label check>
                      <Input
                        value="1"
                        onChange={handleStatus}
                        type="radio"
                        name="radio1"
                        checked={journalStatus === '1'}
                      />{' '}
                      Sudah Approval
                    </Label>
                  </FormGroup>
                  <FormGroup check className="ml-2">
                    <Label check>
                      <Input
                        value="0"
                        onChange={handleStatus}
                        type="radio"
                        name="radio1"
                        checked={journalStatus === '0'}
                      />{' '}
                      Belum Approval
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-2">
                <button
                  type="button"
                  className="btn btn-success btn-sm mt-2"
                  style={{
                    backgroundColor: 'rgb(50, 178, 128)',
                  }}
                  onClick={handleFetch}
                  disabled={false}
                >
                  {loading ? (
                    <div
                      className="spinner-border text-light spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <span>Search From Database</span>
                      <FontAwesomeIcon icon={faSearch} className="ml-2" />
                    </>
                  )}
                </button>
              </div>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </div>
  );
};

export default SearchBar;
