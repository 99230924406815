import React, { useCallback, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  FormText,
} from 'reactstrap';
import axios from 'supports/api';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';

const priceList = [
  { id: 'normalPrice', label: 'Normal Price' },
  { id: 'earlyPrice', label: 'Early Bird Price' },
  { id: 'registrationFee', label: 'Registration Fee' },
];

const validationSchema = Yup.object().shape({
  normalPrice: Yup.number().required('Wajib diisi'),
  earlyPrice: Yup.number().required('Wajib diisi'),
  registrationFee: Yup.number().required('Wajib diisi'),
});

const PriceInputModal = ({
  isOpen,
  toggle,
  handleAddPrices,
  selectedPrice,
  resetSelectedPrice,
}) => {
  const [currencies, setCurrencies] = useState([]);

  const fetchCurrencies = useCallback(async () => {
    try {
      const { data } = await axios.get('/v2/currency');

      setCurrencies(data.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  const formik = useFormik({
    initialValues: {
      currency: selectedPrice.currency || {},
      normalPrice: selectedPrice.normalPrice || '',
      earlyPrice: selectedPrice.earlyPrice || '',
      registrationFee: selectedPrice.registrationFee || 0,
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleAddPrices(values);
      resetForm();
      toggle();
    },
    enableReinitialize: true,
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    resetForm,
  } = formik;

  const handleCancel = () => {
    resetForm();
    resetSelectedPrice();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Add Price</ModalHeader>
      <ModalBody>
        <FormikProvider value={formik}>
          <Form>
            <FormGroup>
              <Label
                for="select-currency"
                className="text-gray font-weight-bold"
              >
                Currency
              </Label>
              <InputGroup className="mb-3">
                <Input
                  id="select-currency"
                  name="select"
                  type="select"
                  value={values.currency.id}
                  onChange={(e) => {
                    const selectedCurrencyId = Number(e.target.value);
                    const foundCurrency = currencies.find(
                      (currency) => currency.id === selectedCurrencyId,
                    );
                    setFieldValue('currency', foundCurrency || {});
                  }}
                >
                  <option value={null}>Select Currency</option>
                  {currencies.map((currency) => {
                    return (
                      <option key={currency.id} value={currency.id}>
                        {currency.name}
                      </option>
                    );
                  })}
                </Input>
              </InputGroup>

              {values.currency.id &&
                priceList.map((price) => {
                  const isRegistrationFee = price.label === 'Registration Fee';

                  return (
                    <React.Fragment key={price.id}>
                      <FormGroup className="mb-3">
                        <Label
                          for={price.id}
                          className="text-gray font-weight-bold"
                        >
                          {price.label}
                        </Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            {values.currency.symbol}
                          </InputGroupAddon>
                          <NumberFormat
                            id={price.id}
                            allowNegative={false}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            className="form-control text-input"
                            value={values[price.id]}
                            disabled={isRegistrationFee}
                            onValueChange={(value) =>
                              setFieldValue(
                                price.id,
                                isRegistrationFee ? 0 : value.floatValue,
                              )
                            }
                          />
                        </InputGroup>
                        {touched[price.id] && errors[price.id] && (
                          <FormText tooltip>{errors[price.id]}</FormText>
                        )}
                      </FormGroup>
                    </React.Fragment>
                  );
                })}
            </FormGroup>
          </Form>
        </FormikProvider>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Set Price
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default PriceInputModal;
