import React from 'react';
import Status from 'components/Transactions/create-transaction/commons/Status';

export default ({ name }) => {
  switch (name) {
    case 'Membership Gold':
      return (
        <Status style={{ backgroundColor: '#FFD700' }} className="my-1">
          Gold
        </Status>
      );
    case 'Membership Platinum':
      return (
        <Status
          style={{ backgroundColor: '#E5E4E2', color: 'black' }}
          className="my-1"
        >
          Platinum
        </Status>
      );
    default:
      return (
        <Status style={{ backgroundColor: '#b3b3b3' }} className="my-1">
          N/A
        </Status>
      );
  }
};
