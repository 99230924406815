import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
// import { checkForm } from 'supports/helpers/permissions';
import CustomPagination from 'components/commons/CustomPagination';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchTransactions } from 'redux/modules/transactions';
import DatePicker from 'react-datepicker';
import { setSearchDate } from 'redux/modules/trxSearch';
import TransactionCardSkeleton from './TransactionCardSkeleton';
import TransactionCard from './TransactionCard';
import PCard from './PCard';
import SearchBox from './SearchBox';
import TransactionTable from './TransactionTable';
import { useTransactionSorter } from './sortHook';
import './main.css';

const MonthYearSelector = () => {
  const searchDate = useSelector((state) => state.trxSearch.searchDate);
  const dispatch = useDispatch();

  return (
    <div style={{ width: '150px' }}>
      <DatePicker
        className="form-control"
        selected={searchDate}
        onChange={(value) => dispatch(setSearchDate(value))}
        dateFormat="MMMM yyyy"
        showMonthYearPicker
      />
    </div>
  );
};

const TransactionData = ({ transactions, page, loadStatus, refresh }) => {
  const data = transactions.slice((page - 1) * 10, (page - 1) * 10 + 10);
  if (loadStatus === 'loading') {
    return [
      <TransactionCardSkeleton key="0" />,
      <TransactionCardSkeleton key="1" />,
      <TransactionCardSkeleton key="2" />,
      <TransactionCardSkeleton key="3" />,
      <TransactionCardSkeleton key="4" />,
      <TransactionCardSkeleton key="5" />,
    ];
  }

  return data.map((item) => {
    return (
      <TransactionCard
        key={`transaction${item.id}`}
        {...item}
        refresh={refresh}
      />
    );
  });
};

const TransactionPage = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const page = params.get('page');
  const itemsPerPage = 10;

  const searchDate = useSelector((state) => state.trxSearch.searchDate);
  const loadStatus = useSelector((state) => state.transactions.status);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTransactions(searchDate));
  }, [dispatch, searchDate]);

  const transactions = useSelector((state) => state.transactions.data);
  const sortedTransactions = useTransactionSorter(transactions);

  useEffect(() => {
    if (
      !page ||
      (Math.ceil(sortedTransactions.length / itemsPerPage) < page &&
        sortedTransactions.length !== 0)
    ) {
      history.push('?page=1');
    }
  }, [page, sortedTransactions, history]);

  return (
    <div className="container" style={{ marginTop: '40px' }}>
      <SearchBox />
      <div className="d-flex flex-row justify-content-between mb-2">
        <div
          className="font-weight-bold"
          style={{ fontSize: '32px', color: '#505050' }}
        >
          Transactions
        </div>
        <Link to="create-transaction" className="mt-auto">
          <Button color="success" style={{ backgroundColor: '#32b280' }}>
            Create Transaction
          </Button>
        </Link>
      </div>
      <PCard style={{ minHeight: '600px' }}>
        <div className="d-flex flex-row justify-content-between">
          <CustomPagination
            pages={Math.ceil(sortedTransactions.length / itemsPerPage) || 1}
            active={page}
          />
          <MonthYearSelector />
        </div>
        <TransactionTable>
          <TransactionData
            transactions={sortedTransactions}
            page={page}
            loadStatus={loadStatus}
            refresh={() => dispatch(fetchTransactions(searchDate))}
          />
        </TransactionTable>
      </PCard>
    </div>
  );
  // const render = checkForm(auth.access, 'Transaction Programs');
};

export default TransactionPage;
