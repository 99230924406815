import { useFormik } from 'formik';
import React from 'react';
import NumberFormat from 'react-number-format';
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { number, object, string } from 'yup';
import axios from 'supports/api';

const AddPromoModal = ({ isOpen, toggle, type, selectedData, refresh }) => {
  const Units = [
    {
      value: 1,
      label: 'Rupiah (IDR)',
      unit: 'Rp',
    },
    {
      value: 2,
      label: 'Persentase (%)',
      unit: '%',
    },
  ];

  const postTransactionPromos = async (data) => {
    try {
      await axios.post('/v2/transaction-promos', data);
      alert('Sukses Menambahkan Promo');
    } catch (error) {
      console.log(error);
    }
  };

  const putTransactionPromos = async (data, id) => {
    try {
      await axios.put(`/v2/transaction-promos/${id}`, data);
      alert('Edit Promo Sukses');
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedData?.name || '',
      unit: selectedData?.unit || '',
      nominal: selectedData?.nominal || 0,
    },
    validationSchema: object().shape({
      name: string().required('Mohon Isi Nama Promo'),
      unit: string().required('Mohon Pilih Unit Promo'),
      nominal: number().required('Mohon Isi Nominal Promo'),
    }),
    onSubmit: async (value, { resetForm }) => {
      try {
        if (type === 'create') {
          await postTransactionPromos(value);
        } else if (type === 'edit') {
          await putTransactionPromos(value, selectedData?.id);
        }
        toggle();
        resetForm();
        refresh();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const {
    getFieldProps,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    values,
    resetForm,
    isValid,
  } = formik;

  const onClose = () => {
    toggle();
    resetForm();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        {type === 'create' ? 'Create New' : 'Edit'} Promo
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Name</Label>
            <Input
              {...getFieldProps('name')}
              type="text"
              value={values.name}
              placeholder="Input promo name"
              onChange={handleChange}
            />
            {Boolean(touched.name) && Boolean(errors.name) && (
              <FormText>{errors.name}</FormText>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Select Unit</Label>
            <Input
              type="select"
              defaultValue={selectedData?.unit || ''}
              value={values.unit}
              onChange={({ target }) => setFieldValue('unit', target.value)}
            >
              <option value="" disabled hidden>
                Select Unit
              </option>
              {Units.map((val) => {
                return (
                  <option key={val.value} value={val.unit}>
                    {val.label}
                  </option>
                );
              })}
            </Input>
            {Boolean(touched.unit) && Boolean(errors.unit) && (
              <FormText>{errors.unit}</FormText>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Nominal</Label>
            <NumberFormat
              customInput={Input}
              thousandSeparator
              value={values.nominal}
              onValueChange={(val) => {
                setFieldValue('nominal', val.floatValue);
              }}
              placeholder="Input nominal"
            />
            {Boolean(touched.nominal) && Boolean(errors.nominal) && (
              <FormText>{errors.nominal}</FormText>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit} disabled={!isValid}>
          {type === 'create' ? 'Confirm' : 'Save'}
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddPromoModal;
