import React, { useState } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import Axios from 'supports/api';
import Loader from 'react-loader-spinner';
import qs from 'query-string';

const StudentCard = (props) => {
  const [loading, setLoading] = useState(false);

  const { item } = props;

  const location = useLocation();
  const queryParam = qs.parse(location.search);

  const print = (id) => {
    setLoading(true);
    Axios.get(`/studentInfo/print/${id}`)
      .then(() => {
        setLoading(false);
        window.open(
          `${process.env.REACT_APP_BASE_URL}/studentInfo/print/${id}`,
        );
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <tr>
      <th>{item.nama}</th>
      <td>{item.program}</td>
      <td>{moment(item.tanggalLahir).format('YYYY MMM DD')}</td>
      <td>{item.gender}</td>
      <td>{item.kabKota}</td>
      <td align="center">
        <Link
          to={`?${qs.stringify({ ...queryParam, id: item.id, tab: 'data' })}`}
        >
          <Button
            color="secondary"
            key="buttonDetail"
            size="sm"
            className="my-1 mr-2"
          >
            Details
          </Button>
        </Link>
        <Button
          color="secondary"
          key="buttonDetail"
          size="sm"
          className="my-1"
          onClick={() => print(item.id)}
        >
          {loading ? (
            <Loader type="Oval" color="white" height={15} width={30} />
          ) : (
            'Print'
          )}
        </Button>
      </td>
    </tr>
  );
};

export default StudentCard;
