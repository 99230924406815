import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import Select from 'react-select';
import Axios from 'supports/api';

const validate = ({ username }) => {
  const errors = {};

  if (!username) {
    errors.username = 'Required';
  }

  return errors;
};

const UserDataModal = ({
  toggle,
  isModalOpen,
  listRoles,
  selectedUsers,
  refresh,
}) => {
  const {
    id,
    username,
    firstName,
    lastName,
    email,
    isAdmin,
    roles,
  } = selectedUsers;
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableInput, setDisableInput] = useState(false);

  const [inputRole, setInputRole] = useState([]);
  const [inputAdmin, setInputAdmin] = useState(false);

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      setDisableInput(true);
      setInputRole(roles);
      setInputAdmin(isAdmin);
    } else {
      setIsEditMode(false);
      setDisableInput(false);
    }
  }, [id, roles, isAdmin, username]);

  const editDataUser = async (values) => {
    setLoading(true);
    try {
      const data = {
        isAdmin: inputAdmin,
        roles: inputRole,
        username: values.username,
      };
      await Axios.put(`/admin/user-menu/${id}`, data);
      setLoading(false);
      alert('Data User Berhasil Diubah');
    } catch (err) {
      alert(`Terjadi kesalahan pada system. ${err}`);
      console.log(err);
      setLoading(false);
    }
  };

  const enableInput = () => {
    setDisableInput(false);
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {isEditMode && !disableInput && 'Edit'}
        {disableInput && 'Details'}
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <Formik
            initialValues={{
              username: username ?? '',
            }}
            validate={inputAdmin ? validate : false}
            onSubmit={async (values) => {
              await editDataUser(values);
              await refresh();
              toggle();
            }}
          >
            {({ touched, errors }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Name:
                      </div>
                      <div>
                        <input
                          className="form-control"
                          placeholder="First Name"
                          type="text"
                          disabled
                          value={`${firstName} ${lastName}`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Email:
                      </div>
                      <div>
                        <input
                          className="form-control"
                          placeholder="Email"
                          type="text"
                          disabled
                          value={email}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        UserName:
                      </div>
                      <Field id="username" name="username">
                        {({ field }) => (
                          <div>
                            <input
                              className="form-control"
                              placeholder="UserName"
                              type="text"
                              disabled={username ? true : disableInput}
                              value={username}
                              {...field}
                            />
                          </div>
                        )}
                      </Field>
                      {touched.username && errors.username && (
                        <div>{errors.username}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Roles:
                      </div>
                      <div>
                        <Select
                          isDisabled={disableInput}
                          isMulti
                          options={listRoles}
                          name="roleName"
                          defaultValue={inputRole}
                          onChange={(e) => {
                            setInputRole(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Admin:
                      </div>
                      <div>
                        <input
                          className="ml-2"
                          type="checkbox"
                          disabled={disableInput}
                          checked={inputAdmin}
                          onChange={(e) => {
                            setInputAdmin(e.target.checked);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3 d-flex justify-content-end">
                      {isEditMode && disableInput && (
                        <Button
                          type="button"
                          className="mr-2"
                          color="primary"
                          onClick={enableInput}
                        >
                          Edit
                        </Button>
                      )}
                      {!disableInput && (
                        <Button
                          disabled={loading}
                          className="mr-2"
                          color="success"
                          type="submit"
                        >
                          {loading && (
                            <div
                              className="spinner-border spinner-border-sm mr-2 text-center"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          Save
                        </Button>
                      )}
                      <Button type="button" color="danger" onClick={toggle}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UserDataModal;
