import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchParams } from 'redux/modules/trxSearch';
import { Input } from 'reactstrap';

const SearchParams = () => {
  const searchParams = useSelector((state) => state.trxSearch.searchParams);
  const dispatch = useDispatch();
  const onChange = (e) => {
    dispatch(setSearchParams(e.target.value));
  };
  return (
    <div className="mb-2 mr-auto" style={{ maxWidth: '500px', width: '100%' }}>
      <div
        className="font-weight-bold"
        style={{ fontSize: '18px', color: '#505050' }}
      >
        Search
      </div>
      <div>
        <Input
          onChange={onChange}
          value={searchParams}
          placeholder="Name || Email || Invoice#"
        />
      </div>
    </div>
  );
};

export default SearchParams;
