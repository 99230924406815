import React from 'react';
import Skeleton from 'react-loading-skeleton';

const PoSkeleton = ({ active }) => {
  return (
    <tr>
      <td className="text-center" style={{ width: '8.56rem' }}>
        <div>
          <Skeleton className="mt-1" />
          <p className="main-text mt-2" style={{ fontSize: '12px' }}>
            <Skeleton />
          </p>
        </div>
      </td>
      <td className="text-center" style={{ width: '9.375rem' }}>
        <div>
          <p>
            <Skeleton />
          </p>
          <span style={{ fontSize: '12px' }}>
            <Skeleton />
          </span>
        </div>
      </td>
      <td style={{ width: '11.562rem' }}>
        <Skeleton />
      </td>
      <td style={{ width: '8.125rem' }}>
        <Skeleton />
      </td>
      {(active === 'PO List' ||
        active === 'PO Approval' ||
        active === 'PO Pencairan' ||
        active === 'PO Finalization') && (
        <>
          <td style={{ width: '9.375rem' }}>
            <Skeleton />
          </td>
          <td style={{ width: '8.56rem' }}>
            <Skeleton />
          </td>
          <td style={{ width: '8.56rem' }}>
            <Skeleton />
          </td>
        </>
      )}
      {active === 'PO Pencairan' ||
      active === 'PO Finalization' ||
      active === 'Edit Mutation Date' ? (
        <td style={{ width: '8.4375rem' }}>
          <Skeleton />
        </td>
      ) : null}
      {active === 'PO Pencairan' && (
        <td style={{ width: '8.125rem' }}>
          <Skeleton />
        </td>
      )}
      <td>
        <Skeleton />
      </td>
      <td className="text-center" style={{ width: '7.25rem' }}>
        <div className="d-flex flex-column align-items-center">
          <Skeleton style={{ width: '5.625rem' }} />
          <Skeleton className="mt-1" style={{ width: '5.625rem' }} />
          <Skeleton className="mt-1" style={{ width: '5.625rem' }} />
        </div>
      </td>
    </tr>
  );
};

export default PoSkeleton;
