import React, { useState } from 'react';
import { Modal, ModalBody, Input, Button } from 'reactstrap';
import axios from 'supports/api';
import moment from 'moment';
import Spinner from 'react-loader-spinner';

const VoucherButton = ({
  voucherIsValid,
  onCheckVoucher,
  onUseVoucher,
  loading = false,
}) => {
  if (loading) {
    return (
      <Button>
        <Spinner />
      </Button>
    );
  }
  if (voucherIsValid) {
    return <Button onClick={onUseVoucher}>Use Voucher</Button>;
  }

  return <Button onClick={onCheckVoucher}>Check Voucher</Button>;
};

const VoucherDetails = ({ details, voucherIsValid = false }) => {
  const { vendor, validFrom, validTo, nominal } = details;
  if (voucherIsValid) {
    return (
      <div>
        <div className="row mt-4">
          <div className="col">
            <div className="tag-gray">Vendor :</div>
            <div className="body-text-gray">{vendor ?? ''}</div>
          </div>
          <div className="col">
            <div className="tag-gray">Valid From :</div>
            <div className="body-text-gray">
              {moment(validFrom).format('DD MMM YYYY')}
            </div>
          </div>
          <div className="col">
            <div className="tag-gray">Valid To :</div>
            <div className="body-text-gray">
              {moment(validTo).format('DD MMM YYYY')}
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <div className="tag-gray">Value :</div>
            <div className="general-title pb-4">
              {nominal?.toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div />;
};

const InputVoucherModal = ({ isOpen, toggle, transactionId }) => {
  const [voucher, setVoucher] = useState();
  const [voucherIsValid, setVoucherIsValid] = useState(false);
  const [voucherObject, setVoucherObject] = useState({});
  // const [loading, setLoading] = useState(false);
  //   const [voucherMessage, setVoucherMessage] = useState();

  const onCheckVoucher = () => {
    if (voucher) {
      axios
        .post(`/transaction/verify-voucher`, {
          number: voucher,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.message === 'Voucher is valid') {
            setVoucherIsValid(true);
            setVoucherObject(res.data.result);
          }
          alert(res.data.message);
        })
        .catch((err) => {
          alert(err);
          console.log(err);
          // setLoading(false);
        });
    } else {
      alert('Please input voucher');
    }
  };

  const onSendClick = () => {
    if (voucherIsValid) {
      const formData = new FormData();
      formData.append('bankId', 1);
      formData.append('payment_methodId', 2);
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          voucherNumber: voucher,
        },
      };
      axios
        .post(`/transaction/create-payment/${transactionId}`, formData, options)
        .then(() => {
          alert('success');
          toggle();
          window.location.reload();
        })
        .catch((err) => {
          alert(err);
          console.log(err);
        });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <Input onChange={(e) => setVoucher(e.target.value)} value={voucher} />
        <VoucherDetails
          details={voucherObject}
          voucherIsValid={voucherIsValid}
        />
        <VoucherButton
          voucherIsValid={voucherIsValid}
          onCheckVoucher={onCheckVoucher}
          onUseVoucher={onSendClick}
        />
      </ModalBody>
    </Modal>
  );
};

export default InputVoucherModal;
