const statuses = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Pending',
    value: 'Pending',
  },
  {
    label: 'Rejected',
    value: 'Rejected',
  },
];

export default statuses;
