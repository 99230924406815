import React from 'react';

const Status = ({ style, className, children }) => {
  return (
    <div
      className={`px-2 font-weight-bold ${className}`}
      style={{
        color: '#ffffff',
        backgroundColor: '#32b280',
        height: '100%',
        borderRadius: '3px',
        textAlign: 'center',
        fontSize: '12px',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Status;
