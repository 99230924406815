import React from 'react';
import { Form, FormFeedback, CustomInput, Label } from 'reactstrap';

import noimage from './no_image.png';

const InputImageComponent = ({ thumbnailImage, handleThumbnailImageFile }) => {
  return (
    <div className="d-flex flex-row justify-content-between">
      <div className="w-100 mr-5">
        <Form className="mb-2">
          <Label
            for="formThumbnailImage"
            className="text-gray font-weight-bold"
          >
            Thumbnail Image
          </Label>
          <CustomInput
            id="formThumbnailImage"
            type="file"
            className="mb-1"
            valid={thumbnailImage.valid}
            invalid={thumbnailImage.invalid}
            label={thumbnailImage.file.name}
            onChange={({ target }) => handleThumbnailImageFile(target.files[0])}
          />
          <FormFeedback invalid="true">*{thumbnailImage.error}</FormFeedback>
        </Form>
      </div>
      <div className="d-flex flex-row justify-content-between w-75">
        <div>
          <Label for="thumbnail-preview" className="text-gray font-weight-bold">
            Thumbnail Preview
          </Label>
          <div style={{ width: 120, height: 120 }} className="border rounded">
            <img
              id="thumbnail-preview"
              src={noimage}
              alt="thumbnailImage"
              className="w-100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputImageComponent;
