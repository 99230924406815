import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendar as farCalendar } from '@fortawesome/free-regular-svg-icons';
import {
  fab,
  faInstagram,
  faFacebook,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCalendar,
  faCalendarAlt,
  faSchool,
  faMoneyBillWave,
  faBook,
  faChartLine,
  faIdCard,
  faLandmark,
  faTag,
  faUserTie,
  faGraduationCap,
  faClock,
  faSearch,
  faEnvelope,
  faMobileAlt,
  faFileInvoice,
  faMapMarkerAlt,
  faChevronDown,
  faTimesCircle,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faPlus,
  faMinus,
  faMinusCircle,
  faTimes,
  faUser,
  faCheck,
  faLock,
  faCheckCircle,
  faSignOutAlt,
  faBriefcase,
  faPrint,
  faFileDownload,
  faCross,
  faCircleNotch,
  faEdit,
  faHandsHelping,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faImage,
} from '@fortawesome/free-solid-svg-icons';

export default library.add(
  fab,
  faFileInvoice,
  faIdCard,
  faLandmark,
  faTag,
  faGraduationCap,
  faCalendarAlt,
  faClock,
  faSearch,
  faCalendar,
  faMobileAlt,
  farCalendar,
  faEnvelope,
  faMapMarkerAlt,
  faCheckCircle,
  faTimesCircle,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faPlus,
  faMinus,
  faMinusCircle,
  faTimes,
  faUser,
  faInstagram,
  faFacebook,
  faLinkedin,
  faYoutube,
  faCheck,
  faLock,
  faSignOutAlt,
  faChartLine,
  faBook,
  faMoneyBillWave,
  faUserTie,
  faSchool,
  faBriefcase,
  faPrint,
  faFileDownload,
  faCross,
  faCircleNotch,
  faHandsHelping,
  faEdit,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faImage,
);
