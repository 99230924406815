import React, { Component } from 'react';
import axios from 'supports/api';
import {
  Card,
  CardBody,
  Button,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import Select from 'react-select';
import CustomPagination from './commons/CustomPagination';
import AdminFinanceMasterTujuanPengeluaranRow from './AdminFinanceMasterTujuanPengeluaranRow';

class AdminFinanceMasterTujuanPengeluaran extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listTujuanPengeluaran: [],
      filtered: [],
      listAccountMaster: [],

      newTujuanPengeluaran: '',
      newAccountMasterId: '',
    };

    // this.update = this.update.bind(this)
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getAccountMaster();
  }

  getData() {
    axios
      .get(`/masterTujuanPengeluaran/getAll`)
      .then((res) => {
        const { result } = res.data;

        const items = [];
        result.forEach((item) => {
          items.push({
            id: item.id,
            tujuanPengeluaran: item.tujuanPengeluaran,
            accountMasterId: item.accountMaster.id,
            accountMaster: `${item.accountMaster.number} - ${item.accountMaster.name}`,
          });
        });
        this.setState({ listTujuanPengeluaran: items });
      })
      .then(() => {
        this.filter(this.refs.searchParams.value);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAccountMaster() {
    axios
      .get(`/accountMaster/getSimpleList`)
      .then((res) => {
        const { result } = res.data;

        const items = [];
        result.forEach((item) => {
          items.push({
            value: item.id,
            label: `${item.number} - ${item.name}`,
          });
        });
        this.setState({ listAccountMaster: items });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleInput = (type, input) => {
    this.setState({ [type]: input });
  };

  handleAccountMaster = (selectedOption) => {
    this.setState({ newAccountMasterId: selectedOption });
  };

  handleAdd = () => {
    if (this.state.newTujuanPengeluaran !== '') {
      if (this.state.newAccountMasterId !== '') {
        if (
          window.confirm(
            'Apakah anda yakin ingin menambahkan tujuan pengeluaran baru ?',
          )
        ) {
          const data = {
            tujuanPengeluaran: this.state.newTujuanPengeluaran,
            accountMasterId: this.state.newAccountMasterId.value,
          };

          axios
            .post(`/masterTujuanPengeluaran/create`, data)
            .then((res) => {
              this.getData();
              this.setState({
                newAccountMasterId: '',
                newTujuanPengeluaran: '',
              });
              alert('Tujuan pengeluaran baru telah ditambahkan');
            })
            .catch((err) => {
              console.log(err);
              alert(err.message);
            });
        }
      } else {
        alert('Mohon mengisi account');
      }
    } else {
      alert('Mohon mengisi tujuan pengeluaran');
    }
  };

  // Update Row. used by children
  // update(idx, item){
  //     console.log(idx, item, this.state.filtered)
  //     this.state.filtered[idx].tujuanPengeluaran = item.tujuanPengeluaran;
  //     this.state.filtered[idx].accountMaster = item.accountMaster;
  //     this.state.filtered[idx].accountMasterId = item.accountMasterId;
  // }

  filter(filter) {
    const filtered = [];
    this.state.listTujuanPengeluaran.forEach((item) => {
      if (
        item.tujuanPengeluaran.toLowerCase().indexOf(filter) !== -1 ||
        item.accountMaster.toLowerCase().indexOf(filter) !== -1
      ) {
        filtered.push({
          id: item.id,
          tujuanPengeluaran: item.tujuanPengeluaran,
          accountMaster: item.accountMaster,
          accountMasterId: item.accountMasterId,
        });
      }
    });

    this.setState({ filtered });
    this.props.history.push('?page=1');
  }

  renderSearchBar() {
    return (
      <Card>
        <CardBody>
          <div className="container-fluid white-box">
            <div className="row">
              <div className="col-6 my-auto">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Search: </InputGroupText>
                  </InputGroupAddon>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Tujuan Pengeluaran / Account Number / Account Name"
                    ref="searchParams"
                    onChange={() => this.filter(this.refs.searchParams.value)}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderTableHeader() {
    return (
      <thead>
        <tr>
          <th>#</th>
          <th>Tujuan Pengeluaran</th>
          <th>Account Number / Account Name</th>
          <th>
            {/* <CustomInput type="checkbox" id="checkAll" label="Cair Semua" value={this.state.isCheckedAll} checked={this.state.isCheckedAll} inline onChange={ this.handleCheck } /> */}
          </th>
        </tr>
        <tr>
          <td />
          <td>
            <input
              type="text"
              value={this.state.newTujuanPengeluaran}
              className="form-control"
              placeholder="Input New Tujuan Pengeluaran"
              ref="newTujuanPengeluaran"
              onChange={() =>
                this.handleInput(
                  'newTujuanPengeluaran',
                  this.refs.newTujuanPengeluaran.value,
                )
              }
            />
          </td>
          <td>
            <Select
              value={this.state.newAccountMasterId}
              options={this.state.listAccountMaster}
              onChange={this.handleAccountMaster}
              id="selectAccountMaster"
            />
          </td>
          <td>
            <Button className="btn btn-primary" onClick={this.handleAdd}>
              Add
            </Button>
          </td>
        </tr>
      </thead>
    );
  }

  renderTableData() {
    const { search } = this.props.location;
    const params = new URLSearchParams(search);
    const page = params.get('page');

    const sliced = this.state.filtered.slice(
      (page - 1) * 10,
      (page - 1) * 10 + 10,
    );
    const arrJSX = sliced.map((item, index) => {
      return (
        <AdminFinanceMasterTujuanPengeluaranRow
          key={`mtp${item.id}`}
          index={index}
          number={(page - 1) * 10 + (index + 1)}
          id={item.id}
          tujuanPengeluaran={item.tujuanPengeluaran}
          accountMaster={item.accountMaster}
          accountMasterId={item.accountMasterId}
          listAccountMaster={this.state.listAccountMaster}
          // update = { this.update }
          refreshList={this.getData}
        />
      );
    });
    return <tbody>{arrJSX}</tbody>;
  }

  renderForm() {
    const { search } = this.props.location;
    const params = new URLSearchParams(search);
    const page = params.get('page');

    return (
      <div className="row">
        <div className="col">
          <div className="white-box py-2">
            <div className="row px-5 py-5">
              <div className="col-12">
                <div>
                  <div className="line-green mb-4" />
                  <div className="general-title text-gray">
                    Master Tujuan Pengeluaran
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-5 py-2">
              <div className="col">
                {this.renderSearchBar()}
                <Table striped bordered responsive hover>
                  {this.renderTableHeader()}
                  {this.renderTableData()}
                </Table>
                <CustomPagination
                  pages={Math.ceil(this.state.filtered.length / 10) || 1}
                  active={page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderForm();
  }
}

export default AdminFinanceMasterTujuanPengeluaran;
