import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkToken } from 'redux/modules/auth';
import { Route, Switch } from 'react-router-dom';

import SignIn from 'pages/sign-in';
import Header from 'components/Header';
import AdminPage from './components/AdminPage';
import './supports/css/bootstrap.css';
import './supports/css/app.css';
import './supports/css/animate.css';
import './FAIcons';
import Sandbox from './Sandbox';

export default () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkToken());
  }, [dispatch]);

  return (
    <div>
      <Header />
      <div
        className="body-app"
        style={{ minHeight: '100vh', backgroundColor: '#e8e8e8' }}
      >
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/admin/sandbox" component={Sandbox} />
          <Route path="/admin" component={AdminPage} />
        </Switch>
      </div>
    </div>
  );
};
