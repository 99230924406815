import React from 'react';

import { Input, Button, InputGroup, InputGroupAddon } from 'reactstrap';

const DiscountInput = ({ normalPrice, discountState }) => {
  const { discount, setDiscount } = discountState;

  const onChangeDiscount = (value) => {
    const parseValue = parseInt((value || '0').split(',').join(''), 10);

    if (parseValue <= normalPrice) {
      setDiscount(parseValue);
    }
  };

  return (
    <div>
      <div className="text-gray font-weight-bold mb-2">Discount</div>
      <InputGroup>
        <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
        <Input
          type="text"
          value={discount.toLocaleString('en-US')}
          onChange={({ target }) => onChangeDiscount(target.value)}
        />
        <InputGroupAddon addonType="append">
          <Button color="danger" disabled style={{ width: '30px' }}>
            -
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};

const DetailPrice = ({ item, discountState }) => {
  const { normalPrice = 0 } = item;

  return (
    <div className="d-flex flex-row justify-content-between">
      <div
        className="d-flex flex-column"
        style={{ width: '100%', maxWidth: '350px' }}
      >
        <div className="text-gray font-weight-bold mb-2">Base Price</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input type="text" disabled value={normalPrice.toLocaleString()} />
        </InputGroup>

        {item?.id && (
          <DiscountInput
            normalPrice={normalPrice}
            discountState={discountState}
          />
        )}
      </div>
      <div
        className="d-flex flex-column"
        style={{ width: '100%', maxWidth: '350px' }}
      >
        <div className="text-gray font-weight-bold mb-2">Total Base</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input type="text" disabled value={normalPrice.toLocaleString()} />
        </InputGroup>

        {item?.id && (
          <>
            <div className="text-gray font-weight-bold mb-2">
              Total Discount
            </div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
              <Input
                type="text"
                disabled
                value={discountState.discount.toLocaleString()}
              />
            </InputGroup>
          </>
        )}

        <div className="text-gray font-weight-bold mb-2">Total Price</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
          <Input
            type="text"
            disabled
            value={(normalPrice - discountState.discount).toLocaleString()}
          />
        </InputGroup>
      </div>
    </div>
  );
};

export default DetailPrice;
