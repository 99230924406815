import React, { Component } from 'react';
import {
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  InputGroup,
  InputGroupAddon,
  Badge,
} from 'reactstrap';
import axios from 'supports/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

class EditPaymentModal extends Component {
  constructor(props) {
    super(props);
    const { payment_methodId, mutationDate, bankId } = props;
    this.state = {
      banks: [],
      nominal: 0,
      paymentMethods: [],
      nominalDisplayed: '0',
      payment_methodId,
      mutationDate: mutationDate ? moment(mutationDate).toDate() : new Date(),
      bankId,
      fileObject: {},
    };
  }

  componentDidMount() {
    axios
      .get(`/bank/all`)
      .then((res) => {
        this.setState({ banks: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/transaction/payment-methods`)
      .then((res) => {
        this.setState({ paymentMethods: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    if (this.props.nominal === null) {
      this.setState({
        nominal: this.props.accountsReceivable,
        nominalDisplayed: this.props.accountsReceivable.toLocaleString(),
      });
    } else {
      this.setState({
        nominal: this.props.nominal,
        nominalDisplayed: this.props.nominal.toLocaleString(),
      });
    }
  }

  onBankIdSelect(bankId) {
    this.setState({ bankId });
  }

  onPaymentMethodSelect(method) {
    this.setState({ payment_methodId: method });
  }

  allowNumbersOnly = (e) => {
    const code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  };

  handleNumber = (value) => {
    const number = parseInt(value.split(',').join(''), 10);
    if (value.split(',').join('') === '') {
      this.setState({ nominalDisplayed: '0', nominal: 0 });
    } else {
      this.setState({
        nominalDisplayed: number.toLocaleString(),
        nominal: number,
      });
    }
  };

  handleMutationDate = (date) => {
    this.setState({ mutationDate: date });
  };

  handleFile = (file) => {
    if (file) {
      this.setState({
        fileObject: file,
      });
    }
  };

  handleText = (type, text) => {
    this.setState({ [type]: text });
  };

  onEditPaymentClick = () => {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    const {
      fileObject,
      payment_methodId,
      bankId,
      nominal,
      mutationDate,
    } = this.state;
    formData.append('proof', fileObject);
    formData.append('payment_methodId', payment_methodId);
    formData.append('bankId', bankId);
    formData.append('nominal', nominal);
    formData.append('mutationDate', mutationDate);

    axios
      .put(`/admin/payment/${this.props.id}`, formData, options)
      .then((res) => {
        alert(res.data.message);
        this.props.toggle();
        this.props.refresh();
      })
      .catch((err) => {
        console.log(err.response.data);
        const { message, error } = err.response.data;
        alert(`${message}\n${error}`);
      });
  };

  BankSelect = () => {
    const arrJSX = this.state.banks.map((item) => {
      return (
        <option key={`bankselection${item.id}`} value={item.id}>
          {item.bankName} - {item.accountNumber}
        </option>
      );
    });
    return (
      <select
        className="form-control"
        id="selectBankId"
        value={this.state.bankId}
        onChange={(e) => this.onBankIdSelect(e.target.value)}
      >
        <option value={0} disabled hidden>
          Pilih Bank
        </option>
        {arrJSX}
      </select>
    );
  };

  NominalBadge = () => {
    if (this.props.confirmed) {
      return <Badge>Payment sudah diconfirm. Nominal tidak dapat diubah</Badge>;
    }
    return null;
  };

  ProofBadge = () => {
    if (this.props.confirmed) {
      return <Badge>Payment sudah diconfirm. Bukti tidak dapat diubah</Badge>;
    }
    return null;
  };

  PaymentMethodSelect = () => {
    const arrJSX = this.state.paymentMethods.map((item) => {
      return (
        <option value={item.id} key={JSON.stringify(item)}>
          {item.name}
        </option>
      );
    });
    return (
      <select
        className="form-control"
        id="paymentMethod"
        value={this.state.payment_methodId}
        onChange={(e) => this.onPaymentMethodSelect(e.target.value)}
      >
        <option value={0} disabled hidden>
          Pilih Metode
        </option>
        {arrJSX}
      </select>
    );
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        size="lg"
        unmountOnClose
      >
        <ModalHeader>Edit Payment</ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            <div className="row py-2">
              <div className="col-6">
                <div className="tag-gray dker font-weight-bold mb-2">
                  Nominal: <this.NominalBadge />
                </div>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    {this.props.currency.symbol}
                  </InputGroupAddon>
                  <input
                    disabled={this.props.confirmed}
                    type="text"
                    className="form-control"
                    id="newPaymentNominal"
                    placeholder="100,000"
                    value={this.state.nominalDisplayed}
                    onKeyPress={this.allowNumbersOnly}
                    onChange={(e) => this.handleNumber(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="col-6">
                <div className="tag-gray dker font-weight-bold mb-2">
                  Payment Method:
                </div>
                <this.PaymentMethodSelect />
              </div>
            </div>
            <div className="row py-2">
              <div className="col-6">
                <div className="tag-gray dker font-weight-bold mb-2">Bank:</div>
                <this.BankSelect />
              </div>
              <div className="col-6">
                <div className="tag-gray dker font-weight-bold mb-2">
                  Tanggal Mutasi:
                </div>
                <DatePicker
                  className="form-control"
                  selected={this.state.mutationDate}
                  dateFormat="dd/MM/yyy"
                  onChange={this.handleMutationDate}
                  showMonthYearDropdown
                  minDate={moment().subtract(6, 'months').toDate()}
                  maxDate={moment().add(6, 'months').toDate()}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="container-fluid">
            <div className="row">
              <div className="col-9" />
              <div className="col-3">
                <Button color="success" onClick={this.onEditPaymentClick}>
                  Save
                </Button>
                <Button color="danger" onClick={this.props.toggle}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EditPaymentModal;
