import React, { Component } from 'react';
import {
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  CustomInput,
} from 'reactstrap';
import axios from 'supports/api';
import renderHTML from 'react-render-html';
import { API_URL_1 } from 'supports/api-url/apiurl';
import HTMLEditor from '../HTMLEditor';

const INITIAL_FORM = {
  name: '',
  title: '',
  profession: '',
  description: '',
  speakerImage: {},
  fileURL: '',
};

// const SpeakerPreview = () => {
//   return <div />;
// };

class SpeakerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_FORM,
      initialDataLoaded: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.speakerData && !prevState.initialDataLoaded) {
      return {
        name: nextProps.speakerData.name || '',
        title: nextProps.speakerData.title || '',
        profession: nextProps.speakerData.profession || '',
        description: nextProps.speakerData.description || '',
        speakerImage: {},
        fileURL: API_URL_1 + nextProps.speakerData.image || '',
        initialDataLoaded: true,
      };
    }
    return null;
  }

  onCreateSpeaker = () => {
    const formData = new FormData();

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const data = {
      name: this.state.name,
      title: this.state.title,
      profession: this.state.profession,
      description: this.state.description,
    };

    formData.append('image', this.state.speakerImage);
    formData.append('data', JSON.stringify(data));

    axios
      .post(`/admin/create/speaker`, formData, options)
      .then(() => {
        alert('Membuat Speaker Baru Success');
        this.resetFields();
        this.props.refresh();
        this.props.toggle();
      })
      .catch((err) => {
        alert(err);
        console.log(err.message);
      });
  };

  resetFields = () => {
    this.setState({
      ...INITIAL_FORM,
      initialDataLoaded: false,
    });
  };

  handleText = (type, text) => {
    this.setState({ [type]: text });
  };

  handleFile = (state, file) => {
    if (file) {
      this.setState({
        [state]: file,
        fileURL: URL.createObjectURL(file),
      });
    }
  };

  onUpdateSpeaker = () => {
    const formData = new FormData();

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const data = {
      name: this.state.name,
      title: this.state.title,
      profession: this.state.profession,
      description: this.state.description,
      fileUrl: this.props.speakerData.image,
    };

    formData.append('image', this.state.speakerImage);
    formData.append('data', JSON.stringify(data));

    axios
      .put(`/admin/speaker/${this.props.speakerData.id}`, formData, options)
      .then(() => {
        alert('Update existing speaker Success');
        this.resetFields();
        this.props.refresh();
        this.props.toggle();
      })
      .catch((err) => {
        alert(err);
        console.log(err.message);
      });
  };

  render() {
    const { isEdit } = this.props;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        size="lg"
        unmountOnClose
        onClosed={this.resetFields}
      >
        <ModalHeader>
          {isEdit ? 'Edit Existing Speaker' : 'Create New Speaker'}
        </ModalHeader>

        <ModalBody>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 py-2">
                <div className="mb-2">
                  <div className="font-weight-bold">Speaker Name </div>
                  <input
                    type="text"
                    className="form-control"
                    id="speakerName"
                    placeholder="Charlie"
                    value={this.state.name}
                    onChange={({ target }) =>
                      this.handleText('name', target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <div className="font-weight-bold">Profession </div>
                  <input
                    type="text"
                    className="form-control"
                    id="speakerProfession"
                    placeholder="UI/UX Designer"
                    value={this.state.profession}
                    onChange={({ target }) =>
                      this.handleText('profession', target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  {/* Need to change title to company in the database for clarity */}
                  <div className="font-weight-bold">Company </div>
                  <input
                    type="text"
                    className="form-control"
                    id="speakerTitle"
                    placeholder="Purwadhika"
                    value={this.state.title}
                    onChange={({ target }) =>
                      this.handleText('title', target.value)
                    }
                  />
                </div>
                <div className="mb-2">
                  <div className="font-weight-bold">Description </div>
                  {/* <textarea className="form-control" type="text" ref="speakerDescription" placeholder="Kurang kerjaan jadi orang" rows={3} id="speakerDescription" value={this.state.description} onChange={() => this.handleText("description", this.refs.speakerDescription.value)}/> */}
                  <HTMLEditor
                    value={this.state.description}
                    handleInput={(type, input) => this.handleText(type, input)}
                    name="description"
                    placeholder="Telah memiliki X tahun pengalaman. Telah menghasilkan project Y"
                    closeTooltip={() => null}
                  />
                </div>
                <div className="mb-2">
                  <div className="font-weight-bold">Profile Picture </div>
                  <CustomInput
                    type="file"
                    id="speakerImage"
                    name="speakerImage"
                    label={this.state.fileName}
                    onChange={({ target }) =>
                      this.handleFile('speakerImage', target.files[0])
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="my-3">
                  <h4>Web Preview: </h4>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="general-title">
                      <img
                        src={this.state.fileURL}
                        alt="pengajar"
                        id="imgPreview"
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>
                  <div className="offset-1 col-6">
                    <div className="general-title">{this.state.name}</div>
                    <div className="body-text-gray font-weight-bold mt-4">
                      {this.state.profession}
                    </div>
                    <div className="body-text-gray font-weight-light">
                      {this.state.title}
                    </div>
                    <div className="body-text-gray mt-4">
                      {renderHTML(this.state.description)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {isEdit ? (
            <Button color="success" onClick={this.onUpdateSpeaker} size="sm">
              Update
            </Button>
          ) : (
            <Button color="success" onClick={this.onCreateSpeaker} size="sm">
              Create
            </Button>
          )}
          <Button color="danger" onClick={this.props.toggle} size="sm">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SpeakerForm;
