import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import axios from 'supports/api';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const validate = ({ title, description, order }) => {
  const errors = {};

  if (!title) {
    errors.title = 'Required';
  }
  if (!description) {
    errors.description = 'Required';
  }
  if (!order) {
    errors.order = 'Required';
  }

  return errors;
};

const LivestreamSessionModal = ({
  toggle,
  isModalOpen,
  programLivestreamModuleId,
  selectedLivestreamSession,
  refresh,
}) => {
  const { id, title, description, order } = selectedLivestreamSession;

  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableInput, setDisableInput] = useState(false);

  useEffect(() => {
    // for determining isEditMode
    if (id) {
      setIsEditMode(true);
      setDisableInput(true);
    } else {
      setIsEditMode(false);
      setDisableInput(false);
    }
  }, [id]);

  const createLivestreamSession = async (formData) => {
    setLoading(true);
    try {
      const body = formData;
      await axios.post(
        `/v2/program-livestream/module/${programLivestreamModuleId}/session`,
        body,
      );
      setLoading(false);
      alert('Livestream Session Berhasil Dibuat');
    } catch (err) {
      alert(`Terjadi kesalahan pada system. ${err}`);
      console.log(err);
      setLoading(false);
    }
  };

  const editLivestreamSession = async (formData) => {
    setLoading(true);
    try {
      const body = formData;
      await axios.put(
        `/v2/program-livestream/module/${programLivestreamModuleId}/session/${id}`,
        body,
      );
      setLoading(false);
      alert('Livestream Session Berhasil Diubah');
    } catch (err) {
      alert(`Terjadi kesalahan pada system. ${err}`);
      console.log(err);
      setLoading(false);
    }
  };

  const enableInput = () => {
    setDisableInput(false);
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {isEditMode && !disableInput && 'Edit'}
        {!isEditMode && !disableInput && 'Create'} Livestream Session{' '}
        {disableInput && 'Details'}
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <Formik
            initialValues={{
              title: title ?? '',
              description: description ?? '',
              order: order ?? '',
            }}
            validate={validate}
            onSubmit={async (values) => {
              if (isEditMode) {
                await editLivestreamSession(values);
              } else {
                await createLivestreamSession(values);
              }
              await refresh();
              toggle();
            }}
          >
            {({ touched, errors, setFieldValue, values }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Livestream Session Title:
                      </div>
                      <Field id="title" name="title">
                        {({ field }) => (
                          <div>
                            <input
                              className="form-control"
                              placeholder="Judul Session JC Livestream"
                              type="text"
                              disabled={disableInput}
                              {...field}
                            />
                          </div>
                        )}
                      </Field>
                      {touched.title && errors.title && (
                        <div>{errors.title}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Description:
                      </div>
                      {/* <Field id="description" name="description">
                        {({ field }) => (
                          <div>
                            <input
                              className="form-control"
                              placeholder="Deskripsi Session JC Livestream"
                              type="text"
                              disabled={disableInput}
                              {...field}
                            />
                          </div>
                        )}
                      </Field> */}
                      <Field id="description" name="description">
                        {() => (
                          <CKEditor
                            editor={Editor}
                            data={description || values.question}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue('description', data);
                            }}
                            config={{
                              toolbar: [
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                'link',
                                'bulletedList',
                                'numberedList',
                                '|',
                                'code',
                                'codeBlock',
                                'insertImage',
                                'blockQuote',
                                'undo',
                                'redo',
                                '|',
                                'removeFormat',
                              ],
                            }}
                            disabled={disableInput}
                            placeholder="Deskripsi Session JC Livestream"
                          />
                        )}
                      </Field>
                      {touched.description && errors.description && (
                        <div>{errors.description}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Order:
                      </div>
                      <Field id="order" name="order">
                        {({ field }) => (
                          <div>
                            <input
                              className="form-control"
                              placeholder="Order Session JC Livestream"
                              type="text"
                              disabled={disableInput}
                              {...field}
                            />
                          </div>
                        )}
                      </Field>
                      {touched.order && errors.order && (
                        <div>{errors.order}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3 d-flex justify-content-end">
                      {isEditMode && disableInput && (
                        <Button
                          type="button"
                          className="mr-2"
                          color="primary"
                          onClick={enableInput}
                        >
                          Edit
                        </Button>
                      )}
                      {!disableInput && (
                        <Button
                          disabled={loading}
                          className="mr-2"
                          color="success"
                          type="submit"
                        >
                          {loading && (
                            <div
                              className="spinner-border spinner-border-sm mr-2 text-center"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {isEditMode ? 'Save' : 'Create'}
                        </Button>
                      )}
                      <Button type="button" color="danger" onClick={toggle}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LivestreamSessionModal;
