import React from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkForm } from 'supports/helpers/permissions';

const SideBarLink = ({
  link,
  page,
  onClick,
  title,
  permissions,
  secondTitle,
}) => {
  const location = useLocation();
  const match = useRouteMatch();
  const { pathname } = location;
  const { path } = match;
  const active = path + link === pathname ? 'active' : '';
  let linkpath = '';
  let display = '';
  const access = useSelector((state) => state?.auth?.access);
  // link passed should start with a slash (/)
  if (link) {
    linkpath = path + link;

    if (page) {
      linkpath += `?page=${page}`;
    }
  }

  // accepts string or array of strings
  const renderWithPermissions = (keys) => {
    const forms = [].concat(keys);
    forms.forEach((item) => {
      if (checkForm(access, item)) {
        display = '';
      }
    });
  };

  if (permissions) {
    display = 'd-none';
    renderWithPermissions(permissions);
  }

  return (
    <Link to={linkpath} style={{ textDecoration: 'none' }}>
      <div
        className={`px-1 py-3 sidebar-link ${active} ${display}`}
        onClick={onClick}
        role="button"
        onKeyDown={() => {}}
        tabIndex={0}
      >
        <div className="font-14 ml-4">• {title}</div>
        {secondTitle && (
          <div className="font-14 sidebar-second-title">{secondTitle}</div>
        )}
      </div>
    </Link>
  );
};

export default SideBarLink;
