import React from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  FormFeedback,
} from 'reactstrap';

const DiscountInput = ({
  disabled,
  displayValue,
  error,
  type,
  handleCurrencyInput,
  selectedCurrency,
}) => {
  if (type === 'program' || type === 'event') {
    return (
      <div>
        <div className="text-gray font-weight-bold mb-2">Discount</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            {selectedCurrency?.symbol}
          </InputGroupAddon>
          <Input
            type="text"
            disabled={disabled}
            value={displayValue}
            onChange={({ target }) =>
              handleCurrencyInput('discount', target.value)
            }
          />
          <InputGroupAddon addonType="append">
            <Button color="danger" disabled style={{ width: '30px' }}>
              -
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </div>
    );
  }
  return null;
};

export default DiscountInput;
