import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Axios from 'supports/api';
import Loader from 'react-loader-spinner';
import './studentForm.css';
import StudentDetailTable from './StudentDataDetailTable';

const StudentDataDetail = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  const { search } = location;
  const params = new URLSearchParams(search);
  const id = params.get('id');

  useEffect(() => {
    if (id) {
      setLoading(true);
      Axios.get(`/studentInfo/getById/${id}`)
        .then((res) => {
          setData(res.data.result);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [id]);

  if (auth.token === '' && auth.loginChecked === true) {
    return (
      <Redirect
        to={{
          pathname: '/',
          from: location.pathname + location.search,
        }}
      />
    );
  }
  return (
    <div className=" mb-4">
      <Card className="p-5">
        <center>
          <h3>DATABASE STUDENT</h3>
        </center>
        {loading ? (
          <div
            className="d-flex justify-content-center"
            style={{ minHeight: '100vh' }}
          >
            <Loader type="ThreeDots" color="black" />
          </div>
        ) : (
          <StudentDetailTable data={data} />
        )}
      </Card>
    </div>
  );
};

export default StudentDataDetail;
