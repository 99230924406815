import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from 'redux/modules/auth';
import SBCollapse from './SBCollapse';
import SBLink from './SBLink';
import './sidebarstyle.css';

// Sidebar v0.2
export default () => {
  const [activeCollapse, setActiveCollapse] = useState(0);
  const sidebar = useRef(null);
  const dispatch = useDispatch();

  const closeSideBar = (e) => {
    if (
      sidebar.current.contains(e.target) ||
      document.getElementById('sidebar-button').contains(e.target)
    ) {
      e.preventDefault();
    } else {
      sidebar.current.style.width = '0px';
      document.getElementById('db-body').style.filter = 'brightness(1)';
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', closeSideBar, false);
    return () => document.removeEventListener('mouseup', closeSideBar, false);
  }, []);

  const onSignOutClick = () => {
    dispatch(signOut());
  };

  const handleCollapseToggle = (id) => {
    if (activeCollapse === id) {
      setActiveCollapse(0);
    } else {
      setActiveCollapse(id);
    }
  };

  return (
    <div ref={sidebar} className="sidebar-body h-100" id="mobileNav">
      <div style={{ minWidth: '250px' }} className="my-5">
        <div className="px-2 ml-2 font-weight-bold">Dashboard</div>
        <SBCollapse
          header="Programs"
          icon="school"
          onClick={handleCollapseToggle}
          collapseId="Programs"
          activeId={activeCollapse}
        >
          <SBLink title="Master" link="/programs" />
          <SBLink title="Attendees" link="/attendees" />
          {/* <SBLink title="Forum" link="/forum" /> */}
          {/* <SBLink title="Forum" link="/v2/forum" /> */}
          {/* <SBLink title="Exam Schedule" link="/exam-schedule" page={1} /> */}
        </SBCollapse>
        <SBCollapse
          header="Services"
          icon="hands-helping"
          onClick={handleCollapseToggle}
          collapseId="Services"
          activeId={activeCollapse}
          permissions={['Memberships Manage']}
        >
          <SBLink title="Master" link="/services" page={1} />
        </SBCollapse>
        <SBCollapse
          header="Transactions"
          icon="file-invoice"
          onClick={handleCollapseToggle}
          collapseId="Transactions"
          activeId={activeCollapse}
          permissions={[
            'Transaction Programs',
            'Transaction Events',
            'Transaction Memberships',
            'Transaction Others',
            'Transaction Promos',
          ]}
        >
          <SBLink title="Manage" link="/transactions" />
          <SBLink title="Create" link="/create-transaction" />
          <SBLink
            title="Promo"
            link="/promo"
            permissions={['Transaction Promos']}
          />
        </SBCollapse>
        <SBCollapse
          header="Finance"
          icon="landmark"
          onClick={handleCollapseToggle}
          collapseId="Finance"
          activeId={activeCollapse}
        >
          <SBLink
            title="Purchase Order"
            link="/purchase-order"
            page={1}
            permissions={[
              'PO List',
              'PO Data',
              'PO Approval',
              'PO Pencairan',
              'PO Finalization',
              'Edit Mutation Date',
            ]}
          />
          <SBLink
            title="EDC Journal"
            link="/edc-journal"
            page={1}
            permissions="EDC Journal"
          />
          <SBLink
            title="Manage Payment"
            link="/payment"
            page={1}
            permissions={['List', 'Data', 'Approval']}
          />
          <SBLink
            title="Generate EFaktur"
            link="/efaktur"
            page={1}
            permissions="EFaktur"
          />
        </SBCollapse>
        {/* <SBCollapse
          header="Accounting"
          icon="book"
          onClick={handleCollapseToggle}
          collapseId="Accounting"
          activeId={activeCollapse}
          permissions={['GL Generator', 'GL Journal', 'Posting']}
        >
          <SBLink
            title="GL Generator"
            link="/general-ledger-generator"
            page={1}
            permissions="GL Generator"
          />
          <SBLink
            title="GL Journal"
            link="/general-ledger-journal"
            page={1}
            permissions="GL Journal"
          />
          <SBLink
            title="Posting"
            link="/accounting-posting"
            page={1}
            permissions="Posting"
          />
        </SBCollapse> */}
        <SBCollapse
          header="Reports"
          icon="chart-line"
          onClick={handleCollapseToggle}
          collapseId="Reports"
          activeId={activeCollapse}
          permissions={[
            'Sales Reports',
            'Finance Reports',
            'Account Reports',
            'Enquiries Reports',
            'Bank Reports',
          ]}
        >
          <SBLink
            title="Sales Reports"
            link="/sales-reports"
            permissions="Sales Reports"
          />
          <SBLink
            title="Program Sales Reports"
            link="/program-sales-reports"
            permissions="Sales Reports"
          />
          <SBLink
            title="Bank Reports"
            link="/bank-reports"
            permissions="Bank Reports"
          />
          <SBLink
            title="Enquiries Reports"
            link="/enquiries-reports"
            permissions="Enquiries Reports"
          />
        </SBCollapse>
        <SBCollapse
          header="Vouchers"
          icon="money-bill-wave"
          onClick={handleCollapseToggle}
          collapseId="Vouchers"
          activeId={activeCollapse}
          permissions={['Vouchers Manage']}
        >
          <SBLink
            title="Manage"
            link="/vouchers"
            page={1}
            permissions="Vouchers Manage"
          />
          <SBLink
            title="Referral Code"
            link="/referral-code"
            page={1}
            permissions="Vouchers Manage"
          />
        </SBCollapse>
        {/* <SBCollapse
          header="Scholarship"
          icon="graduation-cap"
          onClick={handleCollapseToggle}
          collapseId="Scholarship"
          activeId={activeCollapse}
          permissions={['Scholarship Manage', 'Scholarship Create']}
        >
          <SBLink
            title="Manage"
            link="/scholarship-manage"
            page={1}
            permissions="Scholarsip Manage"
          />
          <SBLink
            title="Create"
            link="/scholarship-create"
            permissions="Scholarship Create"
          />
        </SBCollapse> */}
        {/* <SBCollapse
          header="Master"
          icon="user-tie"
          onClick={handleCollapseToggle}
          collapseId={11}
          activeId={activeCollapse}
        >
          <SBLink
            title="Tujuan Penerimaan"
            link="/master-tujuan-penerimaan"
            page={1}
          />
          <SBLink
            title="Tujuan Pengeluaran"
            link="/master-tujuan-pengeluaran"
            page={1}
          />
        </SBCollapse> */}
        <SBCollapse
          header="Student"
          icon="user-tie"
          onClick={handleCollapseToggle}
          collapseId="student"
          activeId={activeCollapse}
        >
          <SBLink title="Student List" link="/student-list" page={1} />
          <SBLink title="Student Report" link="/student-report" page={1} />
          <SBLink title="Student Birthday" link="/student-birthday" page={1} />
          {/* <SBLink title="Student Exam Result" link="/batch-exam" page={1} />

          <SBLink
            title="Digital Signature"
            link="/digital-sign/batch"
            page={1}
          /> */}
        </SBCollapse>
        <SBCollapse
          header="Livestream"
          icon="book"
          onClick={handleCollapseToggle}
          collapseId="livestream"
          activeId={activeCollapse}
        >
          {/* <SBLink title="Online Course List" link="/online-course" page={1} />
          <SBLink
            title="Online Course"
            secondTitle="Mentoring Sessions"
            link="/mentoring-sessions"
          />
          <SBLink
            title="Online Course Video"
            secondTitle="Access"
            link="/video-access"
            page={1}
          /> */}
          <SBLink title="Livestream List" link="/livestream" page={1} />
          <SBLink
            title="Livestream Schedule"
            link="/livestream-schedule"
            page={1}
          />
          <SBLink
            title="Livestream"
            secondTitle="Mentoring Sessions"
            link="/livestream-mentoring-sessions"
            page={1}
          />
          <SBLink
            title="Livestream Access"
            link="/livestream-access"
            page={1}
          />
        </SBCollapse>
        <SBCollapse
          header="Users"
          icon="user-tie"
          onClick={handleCollapseToggle}
          collapseId="users"
          activeId={activeCollapse}
          permissions="Super Admin"
        >
          <SBLink
            title="User List"
            link="/user-menu"
            permissions="Super Admin"
          />
        </SBCollapse>
        <SBCollapse
          header="Sign Out"
          icon="sign-out-alt"
          onClick={onSignOutClick}
        />
      </div>
    </div>
  );
};
