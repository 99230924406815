export const installmentTypeOptions = [
  {
    label: 'Reject Danacita',
    value: 'Reject Danacita',
  },
  {
    label: 'Cash Bertahap',
    value: 'Cash Bertahap',
  },
  {
    label: 'Balloon Payment',
    value: 'Balloon Payment',
  },
  {
    label: 'Special Case',
    value: 'Special Case',
  },
];

export const installmentPaymentInitialValue = {
  id: null,
  date: null,
  paidAt: null,
  status: '',
  nominal: 0,
  tempNominal: 0,
  transactionId: null,
  orderConfirmationNumber: '',

  // Additional value
  isSaved: false,
  readOnly: false,
  allowEdit: true,
  originalValue: {},
};
