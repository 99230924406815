import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'supports/api';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import DatePicker from 'react-datepicker';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Row,
  Col,
  CustomInput,
  Input,
  Label,
  Modal,
  Button,
} from 'reactstrap';
import { addToList, editToList } from 'redux/modules/payment';
import { API_URL_1 } from 'supports/api-url/apiurl';

const DataTab = ({
  tujuanPenerimaan,
  paymentMethod,
  bank,
  selectedId,
  fromTab,
  setFromTab,
  setSelectedId,
  editShow,
  setEditShow,
}) => {
  // custom state
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState({
    fileName: null,
    fileObj: null,
    isExist: false,
  });
  const [form, setForm] = useState({
    idTujuanPenerimaan: 0,
    tujuanPenerimaan: '',
    paymentReceipt: '',
    namaPembayar: '',
    idBank: 0,
    noRekening: 0,
    namaRekening: '',
    namaBank: '',
    mutationDate: new Date(),
    nominalDisplay: '0',
    nominalValue: 0,
    idPayMethod: 0,
    payMethod: '',
    remarks: '',
  });
  const [saveShow, setSaveShow] = useState(true);
  const [vTujPenerimaan, setVTujPenerimaan] = useState(false);
  const [vNominal, setVNominal] = useState(false);
  const [vNoRekening, setVNoRekening] = useState(false);
  const [vPayMethod, setVPayMethod] = useState(false);
  const [vNamaPembayar, setVNamaPembayar] = useState(false);
  const [insertedId, setInsertedId] = useState([]);

  const dispatch = useDispatch();
  const status = useSelector(({ payment }) => payment.status);
  const gAddToList = useSelector(({ payment }) => payment.addPayment);

  useEffect(() => {
    if (gAddToList) {
      setInsertedId(gAddToList.id);
      setImage({
        fileName: gAddToList.proof ? gAddToList.proof.split(`/`)[3] : null,
        fileObj: gAddToList.proof ? gAddToList.proof : null,
        isExist: !!gAddToList.proof,
      });
    }
  }, [gAddToList]);

  useEffect(() => {
    if (form.idTujuanPenerimaan) setVTujPenerimaan(false);
    if (form.nominalValue) setVNominal(false);
    if (form.payMethod) setVPayMethod(false);
    if (form.idBank) setVNoRekening(false);
    if (form.namaPembayar) setVNamaPembayar(false);
  }, [form]);

  useEffect(() => {
    if (selectedId) {
      axios
        .get(`/payment/getById/${selectedId}`)
        .then((res) => {
          const {
            masterTujuanPenerimaanId,
            tujuanPenerimaan,
            bankId,
            nominal,
            payment_methodId,
            paymentMethod,
            proof,
            remarks,
            mutationDate,
            accountName,
            accountNumber,
            receiptNumber,
            bankName,
            namaPembayar,
            firstName,
            lastName,
          } = res.data.result[0];

          setForm({
            idTujuanPenerimaan: masterTujuanPenerimaanId,
            tujuanPenerimaan,
            idBank: bankId,
            noRekening: accountNumber,
            namaRekening: accountName,
            namaBank: bankName,
            nominalValue: nominal,
            nominalDisplay: nominal.toLocaleString(),
            idPayMethod: payment_methodId,
            payMethod: paymentMethod,
            remarks,
            mutationDate: moment(mutationDate).toDate(),
            namaPembayar: namaPembayar || `${firstName} ${lastName}`,
            paymentReceipt: receiptNumber,
          });
          if (proof) {
            setImage({
              fileName: proof.split(`/`)[3] || null,
              fileObj: proof || null,
              isExist: !!proof,
            });
          }
        })
        .catch((err) => alert(`Terjadi kesalahan system ${err}`));
      setEditShow(true);
    } else {
      setFromTab(false);
      setSelectedId(null);
      setSaveShow(true);
      renewForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  // ================RENDER DROPDOWN START=========================
  const renderTujuanPenerimaan = () => {
    return tujuanPenerimaan.map((val) => {
      return (
        <option key={val} value={val.id}>
          {val.name}
        </option>
      );
    });
  };

  const renderBank = () => {
    return bank.map((val) => {
      return <option value={val.id}>{val.accountNumber}</option>;
    });
  };

  const renderPaymentMethod = (e) => {
    return paymentMethod.map((val) => {
      return <option value={val.id}>{val.name}</option>;
    });
  };

  const handleTujuanPenerimaan = (e) => {
    axios
      .get(`/masterTujuanPenerimaan/${e.currentTarget.value}`)
      .then((res) => {
        const { id, name } = res.data.result[0];
        setForm({
          ...form,
          idTujuanPenerimaan: id,
          tujuanPenerimaan: name,
        });
      });
  };

  const handleBank = (e) => {
    axios
      .get(`/bank/get-by-id/${e.currentTarget.value}`)
      .then((res) => {
        const { id, accountNumber, accountName, bankName } = res.data.result[0];
        setForm({
          ...form,
          idBank: id,
          noRekening: accountNumber,
          namaRekening: accountName,
          namaBank: bankName,
        });
      })
      .catch((err) => alert(`Terjadi kesalahan system. ${err}`));
  };

  const handlePaymentMethod = (e) => {
    axios
      .get(`/paymentMethod/${e.currentTarget.value}`)
      .then((res) => {
        const { id, name } = res.data.result[0];
        setForm({
          ...form,
          idPayMethod: id,
          payMethod: name,
        });
      })
      .catch((err) => alert(`Terjadi kesalahan system. ${err}`));
  };
  // ================RENDER DROPDOWN END=========================

  // ==========HANDLER ONCHANGE START=======================
  const handleNamaPembayar = (e) =>
    setForm({ ...form, namaPembayar: e.target.value });

  const handleMutationDate = (date) => setForm({ ...form, mutationDate: date });

  const handleRemarks = (e) => setForm({ ...form, remarks: e.target.value });

  const handleImage = (value) => {
    if (value.target.files[0]) {
      setImage({
        ...image,
        fileName: value.target.files[0].name,
        fileObj: value.target.files[0],
      });
    } else {
      setImage({
        ...image,
        fileName: null,
        fileObj: null,
      });
    }
  };
  // ==========HANDLER ONCHANGE END=======================

  // custom function for display price
  const formatDisplay = (num) => {
    const number = parseInt(num.split(',').join(''));
    if (num.split(',').join('') === '') {
      setForm({
        ...form,
        nominalDisplay: '0',
        nominalValue: 0,
      });
    } else {
      setForm({
        ...form,
        nominalDisplay: number.toLocaleString(),
        nominalValue: number,
      });
    }
  };

  // =================HANDLE IMAGE START========================

  // render input image
  const renderInputImg = () => {
    if (!image.fileName) {
      return (
        <CustomInput
          id="imageName"
          name="imageName"
          type="file"
          accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
          onChange={handleImage}
          disabled={fromTab}
          label={image.imageFile ? image.imageName : null}
        />
      );
    }
    return (
      <div>
        <div className="text-center">{image.fileName}</div>
        <div className="d-flex flex-row justify-content-center">
          <span
            className="main-text cursor-pointer"
            style={{ fontSize: '12px' }}
            onClick={
              selectedId
                ? () => handleDeleteImg(selectedId)
                : insertedId
                ? () => handleDeleteImg(insertedId)
                : () => handleDeleteImg()
            }
          >
            Delete
          </span>
          <span
            className="ml-3 main-text cursor-pointer"
            onClick={() => setModal(true)}
            style={{ fontSize: '12px' }}
          >
            View
          </span>
        </div>
        {renderModal()}
      </div>
    );
  };

  // handle delete image
  const handleDeleteImg = (id) => {
    if (!image.isExist || !id) {
      setImage({ fileName: null, fileObj: null, isExist: false });
    } else {
      axios
        .put(`/payment/delete-proofImg/${id}`, {
          proofImg: image.fileName,
        })
        .then((res) => {
          setImage({
            ...image,
            fileName: null,
            fileObj: null,
            isExist: false,
          });
        })
        .catch((err) => alert(`Terjadi kesalahan system ${err}`));
    }
  };

  // handle view image
  const renderModal = () => {
    const validate = () => {
      if (image.isExist) {
        return (
          <img
            src={`${API_URL_1}${image.fileObj}`}
            alt="proof-img"
            className="w-100 h-100"
          />
        );
      }
      return (
        <img
          src={URL.createObjectURL(image.fileObj)}
          alt="proof-img"
          className="w-100 h-100"
        />
      );
    };
    return (
      <Modal size="md" isOpen={modal} toggle={() => setModal(false)} centered>
        {validate()}
      </Modal>
    );
  };
  // =================HANDLE IMAGE END========================

  // =================HANDLE BUTTON START=====================
  const renewForm = () => {
    setForm({
      idTujuanPenerimaan: 0,
      tujuanPenerimaan: '',
      paymentReceipt: '',
      namaPembayar: '',
      idBank: 0,
      noRekening: 0,
      namaRekening: '',
      namaBank: '',
      mutationDate: new Date(),
      nominalDisplay: '0',
      nominalValue: 0,
      idPayMethod: 0,
      payMethod: '',
      remarks: '',
    });
    setImage({
      fileName: null,
      fileObj: null,
      isExist: false,
    });
    setVTujPenerimaan(false);
    setVNominal(false);
    setVPayMethod(false);
    setVNoRekening(false);
    setVNamaPembayar(false);
  };

  const handleNew = () => {
    setFromTab(false);
    setSelectedId(null);
    setEditShow(false);
    setSaveShow(true);
    renewForm();
  };

  const handleSave = () => {
    if (
      form.idTujuanPenerimaan &&
      form.nominalValue &&
      form.payMethod &&
      form.idBank &&
      form.namaPembayar
    ) {
      const formData = new FormData();
      formData.append('tujuanPenerimaan', form.idTujuanPenerimaan);
      formData.append('dateMutation', form.mutationDate);
      formData.append('nominal', form.nominalValue);
      formData.append('paymentMethod', form.idPayMethod);
      formData.append('bankId', form.idBank);
      formData.append('namaPembayar', form.namaPembayar);
      formData.append('proofImage', image.fileObj);
      formData.append('remarks', form.remarks);

      dispatch(addToList(formData));
      setSaveShow(false);
    } else {
      alert('please fill out all required fields');
      if (!form.idTujuanPenerimaan) setVTujPenerimaan(true);
      if (!form.nominalValue) setVNominal(true);
      if (!form.payMethod) setVPayMethod(true);
      if (!form.idBank) setVNoRekening(true);
      if (!form.namaPembayar) setVNamaPembayar(true);
    }
  };

  const handleEdit = () => {
    const formData = new FormData();
    formData.append('tujuanPenerimaan', form.idTujuanPenerimaan);
    // formData.append('paymentReceipt', form.paymentReceipt);
    formData.append(
      'mutationDate',
      moment(form.mutationDate).format('YYYY MM DD HH:mm:ss'),
    );
    formData.append('nominal', form.nominalValue);
    formData.append('payment_methodId', form.idPayMethod);
    formData.append('bankId', form.idBank);
    formData.append('namaPembayar', form.namaPembayar);
    formData.append('proof', image.fileObj);
    formData.append('remarks', form.remarks);
    if (
      form.idTujuanPenerimaan &&
      form.tujuanPenerimaan &&
      form.namaPembayar &&
      form.idBank &&
      form.noRekening &&
      form.namaRekening &&
      form.namaBank &&
      form.nominalDisplay &&
      form.nominalValue &&
      form.idPayMethod &&
      form.payMethod
    ) {
      if (selectedId) {
        dispatch(editToList(selectedId, formData));
      } else {
        dispatch(editToList(insertedId, formData));
      }
    } else {
      alert('please fill out all required fields');
      if (!form.idTujuanPenerimaan) setVTujPenerimaan(true);
      if (!form.nominalValue) setVNominal(true);
      if (!form.payMethod) setVPayMethod(true);
      if (!form.idBank) setVNoRekening(true);
      if (!form.namaPembayar) setVNamaPembayar(true);
    }
  };
  // =================HANDLE BUTTON END=====================

  const {
    idTujuanPenerimaan,
    paymentReceipt,
    namaRekening,
    idBank,
    namaBank,
    mutationDate,
    nominalDisplay,
    namaPembayar,
    idPayMethod,
    remarks,
  } = form;

  return (
    <Card>
      <CardBody>
        <Form className="mt-2">
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Tujuan Penerimaan</Label>
                <Col sm={7}>
                  <Input
                    className="is-invalid-edit"
                    type="select"
                    onChange={handleTujuanPenerimaan}
                    invalid={vTujPenerimaan}
                    disabled={fromTab}
                    value={idTujuanPenerimaan}
                  >
                    <option value={0} hidden>
                      -PILIH TUJUAN PENERIMAAN-
                    </option>
                    {renderTujuanPenerimaan()}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Account Number</Label>
                <Col sm={7}>
                  <Input
                    className="is-invalid-edit"
                    type="select"
                    onChange={handleBank}
                    invalid={vNoRekening}
                    disabled={fromTab}
                    value={idBank}
                  >
                    <option value={0} hidden>
                      -PILIH NOMOR REKENING-
                    </option>
                    {renderBank()}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Payment Receipt</Label>
                <Col sm={7}>
                  <Input
                    className="is-invalid-edit"
                    type="text"
                    name="paymentReceipt"
                    value={paymentReceipt}
                    disabled
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Account Name</Label>
                <Col sm={7}>
                  <Input type="text" value={namaRekening || '-'} disabled />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Receive From</Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    className="is-invalid-edit"
                    placeholder="-NAMA PEMBAYAR-"
                    name="namaPembayar"
                    onChange={(e) => handleNamaPembayar(e)}
                    disabled={fromTab}
                    invalid={vNamaPembayar}
                    value={namaPembayar}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Bank</Label>
                <Col sm={7}>
                  <Input type="text" value={namaBank || '-'} disabled />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Mutation Date</Label>
                <Col sm={7}>
                  <DatePicker
                    dateFormat="dd/MM/yyy HH:mm"
                    className="form-control date-picker-pym"
                    selected={mutationDate}
                    onChange={handleMutationDate}
                    disabled={fromTab}
                    showTimeInput
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Proof Picture</Label>
                <Col sm={7}>
                  <div className={fromTab ? 'disabled-div' : null}>
                    {renderInputImg()}
                  </div>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Nominal</Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    className="is-invalid-edit"
                    name="nominal"
                    placeholder="0"
                    onChange={(e) => formatDisplay(e.target.value)}
                    value={nominalDisplay}
                    invalid={vNominal}
                    disabled={fromTab}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Remarks</Label>
                <Col sm={7}>
                  <Input
                    type="textarea"
                    name="remarks"
                    disabled={fromTab}
                    onChange={(e) => handleRemarks(e)}
                    value={remarks}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Payment Method</Label>
                <Col sm={7}>
                  <Input
                    type="select"
                    className="is-invalid-edit"
                    disabled={fromTab}
                    invalid={vPayMethod}
                    value={idPayMethod}
                    onChange={handlePaymentMethod}
                  >
                    <option value={0} hidden>
                      -PILIH METODE PEMBAYARAN-
                    </option>
                    {renderPaymentMethod()}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <div
        className="d-flex flex-row-reverse"
        style={{
          marginTop: '-39px',
          right: '0%',
          top: '-1%',
          position: 'absolute',
        }}
      >
        <Button
          className="btn-add-pym ml-1"
          color="success"
          size="md"
          style={{
            backgroundColor: '#32b280',
            width: '80px',
            height: '43px',
            // marginTop: '-82px',
            // left: '93%',
            // position: 'absolute',
          }}
          onClick={handleNew}
        >
          New
        </Button>
        {!fromTab ? (
          saveShow && !editShow ? (
            <Button
              className="btn-add-pym"
              color="secondary"
              size="md"
              disabled={fromTab}
              onClick={handleSave}
              style={{
                // marginleft: '800px',
                width: '80px',
                // marginTop: '-82px',
                // left: '85%',
                // position: 'absolute',
              }}
            >
              {status === 'loading' ? (
                <div className="d-flex justify-content-center">
                  <Loader
                    className="text-center"
                    type="Oval"
                    color="white"
                    height={18}
                  />
                </div>
              ) : (
                'Save'
              )}
            </Button>
          ) : (
            <Button
              className="btn-add-pym"
              color="secondary"
              size="md"
              disabled={fromTab}
              onClick={handleEdit}
              style={{
                // marginleft: '800px',
                width: '80px',
                // marginTop: '-82px',
                // left: '85%',
                // position: 'absolute',
              }}
            >
              {status === 'loading' ? (
                <div className="d-flex justify-content-center">
                  <Loader
                    className="text-center"
                    type="Oval"
                    color="white"
                    height={18}
                  />
                </div>
              ) : (
                'Edit'
              )}
            </Button>
          )
        ) : null}
      </div>
    </Card>
  );
};

export default DataTab;
