import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import axios from 'supports/api';
import CustomPagination from 'components/commons/CustomPagination';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Table, Button, Spinner } from 'reactstrap';
import {
  fetchListPenerimaan,
  filterListPenerimaan,
  filterListByTujPenerimaan,
  filterListByPym,
} from 'redux/modules/payment';

const TableList = ({
  renderData,
  filterPym,
  filterTujPenerimaan,
  click,
  setSelectedId,
  setFromTab,
  setEditShow,
}) => {
  const itemsPerPage = 10;
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const page = params.get('page');
  const [idLoading, setIdLoading] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const countData = useSelector(({ payment }) => payment.countListPym);

  useEffect(() => {
    if (Math.ceil(countData / itemsPerPage) < page && countData !== 0) {
      history.push('?page=1');
    }
  }, [countData, history, page]);

  const reloadRender = useCallback(() => {
    if (!filterPym && filterTujPenerimaan === 'All') {
      dispatch(fetchListPenerimaan(page));
    } else if (filterPym && filterTujPenerimaan !== 'All') {
      dispatch(filterListPenerimaan(page, filterPym, filterTujPenerimaan));
    } else if (filterPym && filterTujPenerimaan === 'All') {
      dispatch(filterListByPym(page, filterPym));
    } else if (!filterPym && filterTujPenerimaan !== 'All') {
      dispatch(filterListByTujPenerimaan(page, filterTujPenerimaan));
    }
  }, [dispatch, page, filterPym, filterTujPenerimaan]);

  const handleConfirm = (id, receiptNumber) => {
    setIsLoading(true);
    setIdLoading(id);

    if (!receiptNumber) {
      axios
        .put(`/admin/confirm-payment/${id}`)
        .then((res) => {
          alert('Confirm Success');
          reloadRender();
          setIsLoading(false);
          setIdLoading(null);
        })
        .catch((err) => {
          setIsLoading(false);
          setIdLoading(null);
          alert(`Terjadi kesalahan system`);
        });
    } else {
      axios
        .put(`/payment/confirm-payment-without-invoice/${id}`)
        .then((res) => {
          alert('Confirm Success');
          setIsLoading(false);
          setIdLoading(null);
          reloadRender();
        })
        .catch((err) => {
          setIsLoading(false);
          setIdLoading(null);
          alert(`Terjadi kesalahan system`);
        });
    }
  };

  const renderTable = () => {
    if (renderData) {
      return renderData.map((val) => {
        const {
          id,
          receiptNumber,
          tujuanPenerimaan,
          nominal,
          accountNumber,
          namaPembayar,
          mutationDate,
        } = val;
        return (
          <tbody key={val}>
            <tr>
              <td>{receiptNumber || 'null'}</td>
              <td>{tujuanPenerimaan}</td>
              <td>Rp. {nominal.toLocaleString()}</td>
              <td>{accountNumber}</td>
              <td>{namaPembayar}</td>
              <td>{moment(mutationDate).format('DD MMM YYYY hh:mm:ss')}</td>
              <td className="text-center">
                <div className="d-flex flex-column align-items-center">
                  <Button
                    color="success"
                    size="sm"
                    style={{
                      width: '72px',
                      backgroundColor: 'rgb(50, 178, 128)',
                    }}
                    onClick={() => handleConfirm(id, receiptNumber)}
                  >
                    {isLoading && idLoading === id ? (
                      <Spinner size="sm" color="white" />
                    ) : (
                      'Confirm'
                    )}
                  </Button>
                  <Button
                    className="mt-1"
                    color="secondary"
                    size="sm"
                    style={{ width: '72px' }}
                    onClick={() => {
                      click();
                      setSelectedId(id);
                      setFromTab(false);
                      setEditShow(true);
                    }}
                  >
                    Details
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        );
      });
    }
  };

  return (
    <div>
      <div className="general-title text-gray mt-3">List</div>
      <div className="mt-3">
        <CustomPagination
          pages={Math.ceil(countData / itemsPerPage) || 1}
          active={page}
        />
      </div>
      <div>
        <Table striped bordered responsive hover>
          <thead
            className="font-weight-bold"
            style={{ fontSize: '14px', color: '#505050' }}
          >
            <tr>
              <th>Payment Receipt</th>
              <th>Tujuan Penerimaan</th>
              <th>Nominal</th>
              <th>Account Number</th>
              <th>Receive From</th>
              <th>Mutation Date</th>
              <th>Action</th>
            </tr>
          </thead>
          {renderTable()}
        </Table>
      </div>
    </div>
  );
};

export default TableList;
