import { useEffect, useState } from 'react';

const useStatusFilter = ({ data, studentStatus }) => {
  const [filtered, setFiltered] = useState(data);
  useEffect(() => {
    const filter = data.filter((student) => {
      if (studentStatus === '') {
        return true;
      }
      if (studentStatus === 'Tidak Aktif / Lulus') {
        return student.status !== 'Aktif';
      }
      return student.status === studentStatus;
    });
    setFiltered(filter);
  }, [data, studentStatus]);
  return filtered;
};

export default useStatusFilter;
