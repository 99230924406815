import CustomPagination from 'components/commons/CustomPagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Table } from 'reactstrap';
import {
  filterStudentReportData,
  initialFetchStudentReport,
} from 'redux/modules/studentReport';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router';
import GenerateAttendanceSheetModal from './GenerateAttendanceSheetModal';

const StudentReportPage = () => {
  const [filterProgram, setFilterProgram] = useState('');
  const [filterBranch, setFilterBranch] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [batchData, setBatchData] = useState({});
  const [generateModalIsOpen, setGenerateModalIsOpen] = useState(false);

  const dispatch = useDispatch();

  const itemsPerPage = 10;
  const location = useLocation();
  const history = useHistory();
  const queryParam = qs.parse(location.search);
  const { page } = queryParam;

  const handleToPageOne = () => {
    history.push(
      `?${qs.stringify({
        ...queryParam,
        page: 1,
      })}`,
    );
  };

  const { filtered, options, loading } = useSelector(
    (state) => state.studentReport,
  );

  useEffect(() => {
    dispatch(initialFetchStudentReport());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      filterStudentReportData({
        filterProgram,
        filterBranch,
        filterQuery,
      }),
    );
  }, [dispatch, filterProgram, filterBranch, filterQuery]);

  const renderBatches = () => {
    const sliced = filtered.slice(
      (page - 1) * itemsPerPage,
      (page - 1) * itemsPerPage + itemsPerPage,
    );

    return sliced.map((batch) => {
      let countRegistered = 0;
      batch.attendees.forEach((attendee) => {
        if (attendee.student_info) {
          countRegistered += 1;
        }
      });
      return (
        <tr key={batch.id}>
          <td>{batch.code}</td>
          <td>{batch.attendees.length}</td>
          <td>{countRegistered}</td>
          <td>
            <Button
              color={batch.id === batchData.id ? 'success' : 'secondary'}
              size="sm"
              onClick={() => setBatchData(batch)}
            >
              Details
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderAttendees = () => {
    return batchData.attendees?.map((attendee) => {
      let status = '';
      let color = '';
      if (!attendee.attendeeName || !attendee.identityCard) {
        status = 'Murid Belum Terdaftar';
        color = 'bg-danger text-white';
      } else if (!attendee.student_info) {
        status = 'Belum Registrasi';
        color = 'bg-warning';
      } else {
        status = 'Sudah Registrasi';
        color = 'bg-success text-white';
      }

      const { personalityTestUrl } = attendee.user;

      return (
        <tr>
          <td>{attendee.attendeeName}</td>
          <td>{attendee.attendeePhone}</td>
          <td>{attendee.attendeeEmail}</td>
          <td>{attendee.identityCard}</td>
          <td>{attendee.jacketSize}</td>
          <td>
            {personalityTestUrl ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`//${personalityTestUrl}`}
              >
                Click Here
              </a>
            ) : (
              'N/A'
            )}
          </td>
          <td className={color}>{status}</td>
        </tr>
      );
    });
  };

  const renderProgramOptions = () => {
    return options.programNames.map((programName) => {
      return (
        <option key={programName.id} value={programName.id}>
          {programName.name}
        </option>
      );
    });
  };

  const renderBranchOptions = () => {
    return options.branches.map((branch) => {
      return (
        <option key={branch.id} value={branch.id}>
          {branch.name}
        </option>
      );
    });
  };

  return (
    <div
      className="white-box container-fluid py-2"
      style={{ minHeight: '100vh' }}
    >
      <div className="row px-1 py-5">
        <div className="col-12  offset-sm-1 col-sm-10">
          <div className="line-green mb-4" />
          <div className="general-title text-gray mb-5">
            Student Data Report
          </div>
          <Card className="mb-1" style={{ backgroundColor: '#f8f8f8' }}>
            <CardBody>
              <div className="col-12 d-flex flex-row">
                <div className="col-4">
                  <input
                    className="form-control"
                    placeholder="Search Batch Code"
                    value={filterQuery}
                    onChange={(e) => {
                      setFilterQuery(e.target.value);
                      handleToPageOne();
                    }}
                  />
                </div>
                <div className="col-4">
                  <select
                    value={filterProgram}
                    onChange={(e) => {
                      setFilterProgram(e.target.value);
                      handleToPageOne();
                    }}
                    className="form-control"
                  >
                    <option value="">All Program</option>
                    {loading ? (
                      <option value="">Loading Programs...</option>
                    ) : (
                      renderProgramOptions()
                    )}
                  </select>
                </div>
                <div className="col-4">
                  <select
                    value={filterBranch}
                    onChange={(e) => {
                      setFilterBranch(e.target.value);
                      handleToPageOne();
                    }}
                    className="form-control"
                  >
                    <option value="">All Branch</option>
                    {loading ? (
                      <option value="">Loading Branches...</option>
                    ) : (
                      renderBranchOptions()
                    )}
                  </select>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="mb-2 d-flex">
            <CardBody>
              <Table bordered striped size="sm">
                <thead>
                  <tr>
                    <th>Kode Batch</th>
                    <th>Jumlah Murid</th>
                    <th>Jumlah Info Murid Terdaftar</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{renderBatches()}</tbody>
              </Table>
              <div className="d-flex flex-row justify-content-center">
                <CustomPagination
                  pages={Math.ceil(filtered.length / itemsPerPage) || 1}
                  active={page}
                />
              </div>
            </CardBody>
          </Card>
          {batchData.id ? (
            <Card>
              <CardBody>
                <div>
                  <h4 className="text-center">
                    Batch {batchData.code}{' '}
                    <Button
                      color="success"
                      onClick={() => setGenerateModalIsOpen(true)}
                      className="float-right"
                      style={{ backgroundColor: '#53b59f' }}
                    >
                      Generate Absensi
                    </Button>
                  </h4>
                </div>
                <Table size="sm" striped bordered>
                  <thead>
                    <tr>
                      <th>Nama Murid</th>
                      <th>No. Hp/Telpon</th>
                      <th>Email</th>
                      <th>NIK</th>
                      <th>Ukuran Jacket</th>
                      <th>Personality Test URL</th>
                      <th style={{ width: '15%' }}>Status Registrasi</th>
                    </tr>
                  </thead>
                  <tbody>{renderAttendees()}</tbody>
                </Table>
              </CardBody>
            </Card>
          ) : null}
        </div>
      </div>
      <GenerateAttendanceSheetModal
        isOpen={generateModalIsOpen}
        toggle={() => setGenerateModalIsOpen(!generateModalIsOpen)}
        programId={batchData?.id}
      />
    </div>
  );
};

export default StudentReportPage;
