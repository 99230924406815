import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomPaginationV2 from 'components/commons/CustomPaginationV2';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import qs from 'query-string';
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const EFakturListTab = ({
  handleUpdateQuery,
  handleFetchTransactions,
  data,
  status,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  customerName,
  setCustomerName,
  invoiceNumber,
  setInvoiceNumber,
}) => {
  const [dataStatus, setDataStatus] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  const itemsPerPage = 10;
  const location = useLocation();
  const history = useHistory();

  const queryParam = qs.parse(location.search);
  const { page } = queryParam;

  const [isOpen, setIsOpen] = useState(true);
  const toggleCollapse = () => setIsOpen(!isOpen);

  const handleToPageOne = () => {
    history.push(
      `?${qs.stringify({
        ...queryParam,
        page: 1,
      })}`,
    );
  };

  useEffect(() => {
    if (dataStatus) {
      const filtered = data.filter((trx) => {
        return trx.taxInvoiceDate && trx.taxInvoiceNumber;
      });
      setFilteredData(filtered);
    } else {
      const filtered = data.filter((trx) => {
        return (
          !(trx.taxInvoiceDate && trx.taxInvoiceNumber) &&
          trx.transaction_statusId !== 4
        );
      });
      setFilteredData(filtered);
    }
  }, [dataStatus, data]);

  const renderTableBody = () => {
    const sliced = filteredData.slice((page - 1) * 10, (page - 1) * 10 + 10);
    return sliced.map((trx) => {
      // format taxInvoiceNumber
      let { taxInvoiceNumber } = trx;
      if (taxInvoiceNumber) {
        const splitTaxInvoiceNumber = taxInvoiceNumber.split('');
        splitTaxInvoiceNumber.splice(3, 0, '.');
        splitTaxInvoiceNumber.splice(7, 0, '-');
        splitTaxInvoiceNumber.splice(10, 0, '.');
        taxInvoiceNumber = splitTaxInvoiceNumber.join('');
      }

      return (
        <tr key={trx.id}>
          <td>{trx.fullName}</td>
          <td>{trx.invoiceNumber}</td>
          <td>{moment(trx.invoiceCreatedAt).format('DD/MM/YYYY')}</td>
          <td>{taxInvoiceNumber}</td>
          <td>
            {trx.taxInvoiceDate &&
              moment(trx.taxInvoiceDate).format('DD/MM/YYYY')}
          </td>
          <td>
            <Link
              to={`?${qs.stringify({
                ...queryParam,
                id: trx.id,
                tab: 'data',
              })}`}
            >
              <Button
                color="secondary"
                key="buttonDetail"
                size="sm"
                className="my-1 mr-2"
              >
                Details
              </Button>
            </Link>
          </td>
        </tr>
      );
    });
  };

  return (
    <Row>
      <Col sm="12">
        <Card body>
          {/* Start Filter Region */}
          <div className={isOpen ? 'dropup' : null}>
            <button
              type="button"
              className="btn btn-secondary mt-2 dropdown-toggle btn-collapse-pym"
              onClick={toggleCollapse}
            >
              Filter
            </button>
          </div>
          <Collapse isOpen={isOpen}>
            <Card className="mt-1">
              <CardBody>
                <Form>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Nama Customer</Label>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={customerName || ''}
                          name="fullName"
                          onChange={(e) => setCustomerName(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Invoice Number</Label>
                      <Col sm={7}>
                        <Input
                          type="text"
                          value={invoiceNumber || ''}
                          name="invoiceNumber"
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Invoice Start Date</Label>
                      <Col sm={7}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          selected={fromDate}
                          onChange={(pickedDate) => setFromDate(pickedDate)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Invoice End Date</Label>
                      <Col sm={7}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          selected={toDate}
                          onChange={(pickedDate) => setToDate(pickedDate)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Status Data Faktur</Label>
                      <Col
                        sm={7}
                        className="d-flex flex-row align-items-center"
                      >
                        <Col className="p-0" sm={6}>
                          <input
                            type="radio"
                            name="status"
                            value=""
                            checked={!dataStatus}
                            onChange={() => {
                              setDataStatus('');
                              handleToPageOne();
                            }}
                          />{' '}
                          Belum Terisi
                        </Col>
                        <Col className="p-0" sm={6}>
                          <input
                            type="radio"
                            name="status"
                            value="1"
                            checked={dataStatus}
                            onChange={() => {
                              setDataStatus('1');
                              handleToPageOne();
                            }}
                          />{' '}
                          Sudah Terisi
                        </Col>
                      </Col>
                    </FormGroup>
                  </Col>
                </Form>
                <div className="d-flex flex-row-reverse">
                  <Button
                    color="success"
                    className="mt-0"
                    style={{
                      backgroundColor: 'rgb(50, 178, 128)',
                      width: '20%',
                    }}
                    onClick={() => {
                      handleUpdateQuery();
                      handleFetchTransactions();
                    }}
                  >
                    {status === 'loading' ? (
                      <Loader
                        type="ThreeDots"
                        color="#ffffff"
                        height={20}
                        width={40}
                      />
                    ) : (
                      <>
                        Search
                        <FontAwesomeIcon icon={faSearch} className="ml-2" />
                      </>
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Collapse>
          {/* End Filter Region */}
          {/* Start Table Region */}
          {filteredData.length ? (
            <>
              <div className="mt-3 d-flex flex-row justify-content-between align-items-center">
                <CustomPaginationV2
                  pages={Math.ceil(filteredData.length / itemsPerPage) || 1}
                  active={page}
                />
                <div>{filteredData.length} Total Transaksi</div>
              </div>
              <div>
                <Table striped bordered responsive hover>
                  <thead
                    className="font-weight-bold"
                    style={{ fontSize: '14px', color: '#505050' }}
                  >
                    <tr>
                      <th>Nama Customer</th>
                      <th>Invoice</th>
                      <th>Tgl. Invoice</th>
                      <th>No. Faktur</th>
                      <th>Tgl. Faktur</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableBody()}</tbody>
                </Table>
              </div>{' '}
            </>
          ) : null}
          {status === 'success' && filteredData.length === 0 ? (
            <div className="mt-3 d-flex flex-row justify-content-center">
              <h3>No Data Found</h3>
            </div>
          ) : null}
          {/* End Table Region */}
        </Card>
      </Col>
    </Row>
  );
};

export default EFakturListTab;
