// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';
// import { encrypt } from 'supports/helpers/encryption';
// import { appKey } from 'supports/config';

const initialState = {
  status: 'idle',
  error: null,
  data: [],
  dataMinimal: [],
  filteredDataMinimal: [],
};

const { actions, reducer } = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    fetchingTransactions: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    fetchSuccess: (state, action) => {
      state.status = 'success';
      state.data = action.payload;
    },
    fetchFailure: (state, action) => {
      state.status = 'failure';
      state.error = action.payload;
    },
    fetchMinimalSuccess: (state, action) => {
      state.status = 'success';
      state.dataMinimal = action.payload;
      state.filteredDataMinimal = action.payload;
    },
    filterMinimalTransactions: (state, action) => {
      const { name, orderConfirmationNumber } = action.payload;
      const nameLower = name.toLowerCase();
      const invoiceUpper = orderConfirmationNumber.toUpperCase();
      state.filteredDataMinimal = state.dataMinimal.filter((trx) => {
        if (!trx.orderConfirmationNumber) trx.orderConfirmationNumber = 'N/A';
        return (
          trx.orderConfirmationNumber.includes(invoiceUpper) &&
          trx.firstName
            .trim()
            .concat(' ', trx.lastName.trim())
            .toLowerCase()
            .includes(nameLower)
        );
      });
    },
  },
});

export default reducer;

export const {
  fetchingTransactions,
  fetchSuccess,
  fetchFailure,
  fetchMinimalSuccess,
  filterMinimalTransactions,
} = actions;

export const fetchTransactions = (date = new Date()) => async (dispatch) => {
  dispatch(fetchingTransactions());
  try {
    const options = {
      params: {
        dateFilter: date,
      },
    };
    const { data } = await axios.get(`/admin/transaction/list`, options);
    dispatch(fetchSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const fetchMinimalTransactions = (params) => async (dispatch) => {
  dispatch(fetchingTransactions());
  try {
    const { date, searchInput } = params;
    const options = {
      params: {
        monthFilter: date,
      },
    };
    const { data } = await axios.get(`/admin/transaction/min-list`, options);
    dispatch(fetchMinimalSuccess(data.result));
    dispatch(filterMinimalTransactions(searchInput));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};
