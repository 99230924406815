// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  status: 'idle',
  error: null,
  data: [],
};

const { actions, reducer } = createSlice({
  name: 'programSales',
  initialState,
  reducers: {
    fetchingProgramSales: (state) => {
      state.loading = true;
      state.status = 'loading';
      state.error = null;
    },
    fetchSuccess: (state, action) => {
      state.loading = false;
      state.status = 'success';
      state.data = action.payload;
    },
    fetchFailure: (state, action) => {
      state.loading = false;
      state.status = 'failure';
      state.error = action.payload;
    },
  },
});

export default reducer;

export const {
  fetchingProgramSales,
  fetchSuccess,
  fetchFailure,
  filterProgramSales,
} = actions;

export const fetchProgramSalesByTrxId = (id) => async (dispatch) => {
  dispatch(fetchingProgramSales());
  try {
    const { data } = await axios.get(`/admin/program-sales/${id}`);
    dispatch(fetchSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};
