import React, { useState, useEffect } from 'react';
import {
  Alert,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import axios from 'supports/api';

// Object creator
import createTransactionItem from 'components/Transactions/create-transaction/modals/itemCreator';

const INITIAL_FILTER = {
  categoryId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Category',
  },
  categoryTopicId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Topic',
  },
  levelId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Level',
  },
};

const INITIAL_FORM = {
  online_course: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select an Online Course',
  },
  discount: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    error: 'Input discount',
  },
};

const AddOnlineCourseModal = (props) => {
  const [state, setState] = useState({
    ...INITIAL_FILTER,
    ...INITIAL_FORM,
  });
  const [categories, setCategories] = useState([]);
  const [categoryTopics, setCategoryTopics] = useState([]);
  const [levels, setLevels] = useState([]);
  const [online_courses, setOnlineCourses] = useState([]);

  useEffect(() => {
    axios
      .get(`/online-course/categories`)
      .then(({ data }) => {
        setCategories(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/online-course/category-topics`)
      .then(({ data }) => {
        setCategoryTopics(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/online-course/levels`)
      .then(({ data }) => {
        setLevels(data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (
      state.categoryId.value &&
      state.categoryTopicId.value &&
      state.levelId.value
    ) {
      const options = {
        params: {
          categoryId: state.categoryId.value,
          categoryTopicId: state.categoryTopicId.value,
          levelId: state.levelId.value,
        },
      };
      axios
        .get(`/online-course/filter`, options)
        .then(({ data }) => {
          setOnlineCourses(data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    state.categoryId.value,
    state.categoryTopicId.value,
    state.levelId.value,
  ]);

  const resetState = () => {
    setState({
      ...INITIAL_FORM,
      ...INITIAL_FILTER,
    });
  };

  const validateForm = async (form) => {
    let result = 0;
    const exclude = [];
    const invalidField = [];
    await Object.keys(form).forEach(async (obj) => {
      if (!form[obj].valid && !exclude.includes(obj)) {
        await setState((prevState) => ({
          [obj]: { ...prevState[obj], invalid: true },
        }));
        console.log(obj);
        invalidField.push(obj);
        result += 1;
      }
    });

    if (result > 0) {
      let errorMessage = 'Invalid fields: \n';
      invalidField.forEach((item) => {
        console.log(item);
        errorMessage += `${item} \n`;
      });
      throw new Error(errorMessage);
    }
    return true;
  };

  const onAddClick = () => {
    const { online_course, discount } = state;
    let optional = {};
    if (online_course.type === 'program') {
      optional = { discount };
    }
    const validation = validateForm({ online_course, ...optional }); // returns a promise

    validation
      .then(async (valid) => {
        if (valid) {
          const selectedOnlineCourse = JSON.parse(online_course.value);

          const transactionItem = createTransactionItem({
            id: selectedOnlineCourse.id, // universal
            price: selectedOnlineCourse.normalPrice, // universal
            // discount: state.discount.valid ? state.discount.value : 0, // program
            productType: 'online_course', // universal,
          });

          await props.addTransactionItem([transactionItem]);
          props.toggle();
        } else {
          alert('There are invalid fields');
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleCurrencyInput = (state, value) => {
    let number = parseInt(value.split(',').join(''), 10);
    let displayValue = number.toLocaleString('en-US');
    const program = JSON.parse(state.program.value);
    let price = 0;
    let registrationFee = 0;
    if (program) {
      price = program.normalPrice;
      registrationFee = program.registrationFee;
    }
    if (state === 'discount' && number > price + registrationFee) {
      return null;
    }

    if (value.split(',').join('') === '') {
      number = 0;
      displayValue = '0';
    }
    return setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value: number, displayValue },
    }));
  };

  const handleSelect = (state, value) => {
    const checkState = (checkedState) => {
      const { levelId } = INITIAL_FILTER;
      const { online_course } = INITIAL_FORM;
      switch (checkedState) {
        case 'categoryId':
          return { levelId, online_course };
        case 'levelId':
          return { online_course };
        default:
          return {};
      }
    };

    const formReset = checkState(state);
    let valid = false;
    let invalid = true;
    if (value !== 0) {
      valid = true;
      invalid = false;
    }

    setState((prevState) => ({
      ...prevState,
      ...formReset,
      [state]: { value, valid, invalid },
    }));
  };

  const PriceDetails = ({ item, discount = 0, qty = 1 }) => {
    const online_course = JSON.parse(item);
    let price = 0;
    const registrationFee = 0;

    if (online_course) {
      price = online_course.normalPrice;
    }

    const TotalDiscount = () => {
      if (online_course.type === 'program' || online_course.type === 'event') {
        return (
          <div>
            <div className="text-gray font-weight-bold mb-2">
              Total Discount
            </div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
              <Input
                type="text"
                disabled
                value={(discount * qty).toLocaleString()}
              />
            </InputGroup>
          </div>
        );
      }
      return null;
    };

    const totalPrice = (price + registrationFee - discount) * qty;
    return (
      <div className="d-flex flex-row justify-content-between">
        <div
          className="d-flex flex-column"
          style={{ width: '100%', maxWidth: '350px' }}
        >
          <div className="text-gray font-weight-bold mb-2">Base Price</div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
            <Input type="text" disabled value={price.toLocaleString()} />
          </InputGroup>
          <DiscountInput {...state.discount} type={online_course.type} />
        </div>
        <div
          className="d-flex flex-column"
          style={{ width: '100%', maxWidth: '350px' }}
        >
          <div className="text-gray font-weight-bold mb-2">Total Base</div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
            <Input
              type="text"
              disabled
              value={(price * qty).toLocaleString()}
            />
          </InputGroup>
          <TotalDiscount />
          <div className="text-gray font-weight-bold mb-2">Total Price</div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
            <Input type="text" disabled value={totalPrice.toLocaleString()} />
          </InputGroup>
        </div>
      </div>
    );
  };

  const DiscountInput = ({ disabled, displayValue, error, type }) => {
    if (type === 'program' || type === 'event') {
      return (
        <div>
          <div className="text-gray font-weight-bold mb-2">Discount</div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
            <Input
              type="text"
              disabled={disabled}
              value={displayValue}
              onChange={({ target }) =>
                handleCurrencyInput('discount', target.value)
              }
            />
            <InputGroupAddon addonType="append">
              <Button color="danger" disabled style={{ width: '30px' }}>
                -
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <FormFeedback invalid="true">*{error}</FormFeedback>
        </div>
      );
    }
    return null;
  };

  const CategorySelector = ({ value, valid, invalid, error }) => {
    const arrJSX = categories?.map((item) => {
      return (
        <option key={JSON.stringify(item)} value={item.id}>
          {item.name}
        </option>
      );
    });

    return (
      <FormGroup>
        <div className="text-gray font-weight-bold mb-2">Category</div>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) => handleSelect('categoryId', target.value)}
        >
          <option value={0} disabled hidden>
            Select Category
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  const CategoryTopicSelector = ({ value, valid, invalid, error }) => {
    const arrJSX = categoryTopics?.map((item) => {
      return (
        <option key={JSON.stringify(item)} value={item.id}>
          {item.name}
        </option>
      );
    });

    return (
      <FormGroup>
        <div className="text-gray font-weight-bold mb-2">Topic</div>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) =>
            handleSelect('categoryTopicId', target.value)
          }
        >
          <option value={0} disabled hidden>
            Select Topic
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  const LevelSelector = ({ value, valid, invalid, error }) => {
    const arrJSX = levels?.map((item) => {
      return (
        <option key={`selectlevel${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });
    return (
      <FormGroup>
        <div className="text-gray font-weight-bold mb-2">Level</div>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) => handleSelect('levelId', target.value)}
        >
          <option value={0} disabled hidden>
            Pilih Level
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  const OnlineCourseSelector = ({ value, valid, invalid, error }) => {
    if (
      state.categoryId.value &&
      state.categoryTopicId.value &&
      state.levelId.value
    ) {
      const arrJSX = online_courses.map((item) => {
        return (
          <option key={`selectprogram${item.id}`} value={JSON.stringify(item)}>
            {item.title}
          </option>
        );
      });
      if (online_courses.length > 0) {
        return (
          <FormGroup>
            <div className="text-gray font-weight-bold mb-2">Online Course</div>
            <Input
              type="select"
              valid={valid}
              invalid={invalid}
              value={value}
              onChange={({ target }) =>
                handleSelect('online_course', target.value)
              }
            >
              <option value={0} disabled hidden>
                Pilih Online Course
              </option>
              {arrJSX}
            </Input>
            <FormFeedback invalid="true">*{error}</FormFeedback>
          </FormGroup>
        );
      }
      return (
        <FormGroup>
          <div className="text-gray font-weight-bold mb-2">Online Course</div>
          <Input
            type="text"
            disabled
            invalid
            value="No available Online Course"
          />
        </FormGroup>
      );
    }
    return null;
  };

  const RenderAlert = () => {
    const { invalid, error } = state.online_course;

    if (invalid) {
      return (
        <Alert color="danger" className="py-1">
          {error}
        </Alert>
      );
    }
    return null;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      className={props.className}
      size="lg"
      onClosed={resetState}
    >
      <ModalHeader>Add Program Item</ModalHeader>
      <ModalBody>
        <Card className="my-1">
          <CardHeader>Select Program</CardHeader>
          <CardBody>
            <RenderAlert />
            <CategorySelector {...state.categoryId} />
            <CategoryTopicSelector {...state.categoryTopicId} />
            <LevelSelector {...state.levelId} />
            <OnlineCourseSelector {...state.online_course} />
          </CardBody>
        </Card>
        <Card className="my-1">
          <CardBody>
            <PriceDetails
              item={state.online_course.value}
              // discount={state.discount.value}
            />
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-row float-right">
          <Button
            color="success"
            style={{ backgroundColor: '#53b59f' }}
            onClick={onAddClick}
          >
            Add
          </Button>
          <Button className="ml-2" color="danger" onClick={props.toggle}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddOnlineCourseModal;
