// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import Axios from 'supports/api';

const initialState = {
  loading: false,
  status: 'idle',
  error: null,
  data: [],
  filtered: [],
  options: {
    programNames: [],
    branches: [],
  },
};

const { actions, reducer } = createSlice({
  name: 'studentReport',
  initialState,
  reducers: {
    fetchingStudentReportStart: (state) => {
      state.loading = true;
      state.status = 'loading';
    },
    setStudentReportData: (state, action) => {
      state.data = action.payload;
      state.filtered = action.payload;
    },
    setOptions: (state, action) => {
      state.options.programNames = action.payload.programNames;
      state.options.branches = action.payload.branches;
    },
    fetchingStudentReportSuccess: (state) => {
      state.loading = false;
      state.status = 'success';
    },
    fetchingStudentReportFailure: (state, action) => {
      state.loading = false;
      state.status = 'failure';
      state.error = action.payload;
    },
    filterStudentReportData: (state, action) => {
      const { filterProgram, filterBranch, filterQuery } = action.payload;

      let newFiltered = state.data;

      if (filterQuery) {
        newFiltered = newFiltered.filter((item) => {
          const { code } = item;
          return (
            code.toString().toLowerCase().indexOf(filterQuery.toLowerCase()) !==
            -1
          );
        });
      }

      if (filterProgram) {
        newFiltered = newFiltered.filter((item) => {
          return Number(filterProgram) === item.programHeaderId;
        });
      }

      if (filterBranch) {
        newFiltered = newFiltered.filter((item) => {
          return Number(filterBranch) === item.branchId;
        });
      }

      state.filtered = newFiltered;
    },
  },
});

export default reducer;

export const {
  fetchingStudentReportStart,
  fetchingStudentReportFailure,
  fetchingStudentReportSuccess,
  setOptions,
  setStudentReportData,
  filterStudentReportData,
} = actions;

export const initialFetchStudentReport = () => async (dispatch) => {
  dispatch(fetchingStudentReportStart());
  try {
    const res = await Axios.get(`/program/batch-attendees`);
    const programNames = await Axios.get(`/admin/program/all-header-names`, {
      params: { type: 'program' },
    });
    const branches = await Axios.get(`/admin/branch/list`);

    dispatch(setStudentReportData(res.data.result));
    dispatch(
      setOptions({
        programNames: programNames.data.result,
        branches: branches.data.result,
      }),
    );
    dispatch(fetchingStudentReportSuccess());
  } catch (err) {
    dispatch(fetchingStudentReportFailure(err));
  }
};
