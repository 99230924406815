import React, { useState } from 'react';
import {
  ModalHeader,
  ModalBody,
  Modal,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { Formik, Form, Field } from 'formik';
import axios from 'supports/api';
import NumberFormat from 'react-number-format';

const validate = ({
  code,
  name,
  nominal,
  vendor,
  quota,
  validFrom,
  validTo,
}) => {
  const errors = {};

  if (!code) {
    errors.code = 'Required';
  }
  if (!name) {
    errors.name = 'Required';
  }
  if (nominal === '' || nominal < 0) {
    errors.nominal = 'Required';
  }
  if (!vendor) {
    errors.vendor = 'Required';
  }
  if (quota === '' || quota < 0) {
    errors.quota = 'Required';
  }
  if (!validFrom) {
    errors.validFrom = 'Required';
  }
  if (!validTo) {
    errors.validTo = 'Required';
  }

  return errors;
};

const CreateReferralCodeModal = ({
  isOpen,
  toggle,
  refresh,
  isEditMode,
  selectedReferralCodeData,
}) => {
  const {
    id,
    code,
    name,
    nominal,
    vendor,
    quota,
    isPercentage,
    validFrom,
    validTo,
    isAvailable,
  } = selectedReferralCodeData;
  const [loading, setLoading] = useState(false);

  const createReferralCode = async (formData) => {
    setLoading(true);
    try {
      const body = formData;
      body.totalUsage = 0;
      await axios.post(`/v2/referral-code`, formData);
      setLoading(false);
      alert('Referral Code Berhasil Dibuat');
    } catch (err) {
      window.alert(`Terjadi kesalahan pada system. ${err}`);
      setLoading(false);
    }
  };

  const editReferralCode = async (formData) => {
    setLoading(true);
    try {
      const body = formData;
      body.id = id;
      await axios.put(`/v2/referral-code/`, body);
      setLoading(false);
      alert('Referral Code Berhasil Diubah');
    } catch (err) {
      setLoading(false);
      window.alert(`Terjadi kesalahan pada system. ${err}`);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" unmountOnClose>
      <ModalHeader>
        {isEditMode ? 'Edit' : 'Generate'} Referral Code
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <Formik
            initialValues={{
              code: code ?? '',
              name: name ?? '',
              nominal: nominal ?? '',
              vendor: vendor ?? '',
              quota: quota ?? '',
              isPercentage: isPercentage ?? false,
              validFrom: validFrom ?? '',
              validTo: validTo ?? '',
              isAvailable: isAvailable ?? true,
            }}
            validate={validate}
            onSubmit={async (values) => {
              if (isEditMode) {
                await editReferralCode(values);
              } else {
                await createReferralCode(values);
              }
              await refresh();
              toggle();
            }}
          >
            {({ values, touched, errors, setFieldValue }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-6 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Referral Code:
                      </div>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="purwadhika-123"
                        id="code"
                        name="code"
                      />
                      {touched.code && errors.code && <div>{errors.code}</div>}
                    </div>
                    <div className="col-6 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Nama:
                      </div>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="John Doe"
                        id="name"
                        name="name"
                      />
                      {touched.name && errors.name && <div>{errors.name}</div>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Nominal:
                      </div>
                      <InputGroup id="nominal">
                        {!values.isPercentage && (
                          <InputGroupAddon addonType="prepend">
                            Rp.
                          </InputGroupAddon>
                        )}
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={!values.isPercentage}
                          decimalScale={2}
                          fixedDecimalScale={values.isPercentage}
                          value={values.nominal}
                          onValueChange={(val) =>
                            setFieldValue('nominal', val.floatValue)
                          }
                        />
                        {values.isPercentage && (
                          <InputGroupAddon addonType="append">
                            %
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                      {touched.nominal && errors.nominal && (
                        <div>{errors.nominal}</div>
                      )}
                      <Field
                        type="checkbox"
                        className="mx-2 mt-3"
                        id="isPercentage"
                        name="isPercentage"
                      />
                      <span className="tag-gray dker font-weight-bold">
                        Presentase
                      </span>
                    </div>
                    <div className="col-6 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Vendor:
                      </div>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder="Purwadhika"
                        id="vendor"
                        name="vendor"
                      />
                      {touched.vendor && errors.vendor && (
                        <div>{errors.vendor}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="py-1 col-3">
                      <div className="tag-gray dker font-weight-bold mb-3">
                        Valid From :
                      </div>
                      <DatePicker
                        className="form-control"
                        selectsStart
                        dateFormat="dd/MM/yyy"
                        showMonthYearDropdown
                        selected={
                          (values.validFrom && new Date(values.validFrom)) ||
                          null
                        }
                        onChange={(date) =>
                          setFieldValue(
                            'validFrom',
                            new Date(new Date(date).setHours(0, 0, 0, 0)),
                          )
                        }
                      />
                      {touched.validFrom && errors.validFrom && (
                        <div>{errors.validFrom}</div>
                      )}
                    </div>
                    <div className="py-1 col-3">
                      <div className="tag-gray dker font-weight-bold mb-3">
                        Valid To:
                      </div>
                      <DatePicker
                        className="form-control"
                        selectsStart
                        dateFormat="dd/MM/yyy"
                        showMonthYearDropdown
                        selected={
                          (values.validTo && new Date(values.validTo)) || null
                        }
                        onChange={(date) =>
                          setFieldValue(
                            'validTo',
                            new Date(new Date(date).setHours(0, 0, 0, 0)),
                          )
                        }
                      />
                      {touched.validTo && errors.validTo && (
                        <div>{errors.validTo}</div>
                      )}
                    </div>
                    <div className="py-1 col-6">
                      <div className="tag-gray dker font-weight-bold mb-3">
                        Quota:
                      </div>
                      <Field
                        type="number"
                        className="form-control"
                        placeholder="1xx"
                        id="quota"
                        name="quota"
                      />
                      {touched.quota && errors.quota && (
                        <div>{errors.quota}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="py-3 col-6">
                      <Field
                        type="checkbox"
                        className="mx-2 mt-3"
                        id="isAvailable"
                        name="isAvailable"
                      />
                      <span className="tag-gray dker font-weight-bold">
                        Available
                      </span>
                    </div>
                    <div className="py-3 col-6 d-flex justify-content-end">
                      <Button className="mr-2" type="submit" color="success">
                        {loading && (
                          <div
                            className="spinner-border spinner-border-sm mr-2 text-center"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        {isEditMode ? 'Edit' : 'Create'}
                      </Button>
                      <Button color="danger" onClick={toggle}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CreateReferralCodeModal;
