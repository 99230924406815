import React from 'react';
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  Label,
  Table,
  Row,
  Col,
  Container,
} from 'reactstrap';
import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';
import Select from 'react-select';

const TransactionPromosModal = ({
  isOpen,
  toggle,
  promoList,
  handleAddTransactionPromos,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedPromos: 0,
    },

    onSubmit: (value, { resetForm }) => {
      const findPromo = promoList.find(
        (val) => val.id === value.selectedPromos,
      );
      if (!findPromo) {
        alert('Please Select a Promo');
      } else {
        const data = {
          id: findPromo.id,
          name: findPromo.name,
          unit: findPromo.unit,
          nominal: findPromo.nominal,
        };
        handleAddTransactionPromos(data);
        toggle();
        resetForm();
      }
    },
  });

  const MapPromosOptions = () => {
    const result = promoList.map((value) => {
      return { value: value.id, label: value.name };
    });

    return result;
  };

  const { setFieldValue, handleSubmit, values, resetForm } = formik;

  const onCancel = () => {
    toggle();
    resetForm();
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel}>
      <ModalHeader toggle={onCancel}>Add Promo</ModalHeader>
      <ModalBody>
        <Form>
          <Label>Select Promo</Label>
          <Container>
            <Row>
              <Col md={12} style={{ padding: '0px' }}>
                <Select
                  value={values.selectedPromos}
                  options={MapPromosOptions()}
                  onChange={(val) => setFieldValue('selectedPromos', val.value)}
                />
              </Col>
            </Row>
          </Container>

          {values.selectedPromos !== 0 ? (
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Unit</th>
                  <th>Nominal</th>
                </tr>
              </thead>
              {promoList.map((val) => {
                if (val.id === values.selectedPromos) {
                  return (
                    <tr>
                      <td>{val.name}</td>
                      <td>{val.unit}</td>
                      <td>
                        <NumberFormat
                          thousandSeparator
                          value={val.nominal}
                          disabled
                          style={{ backgroundColor: 'inherit', border: '0px' }}
                        />
                      </td>
                    </tr>
                  );
                }

                return null;
              })}
            </Table>
          ) : null}
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Confirm
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default TransactionPromosModal;
