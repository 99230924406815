import React, { Component } from 'react';
import { Button } from 'reactstrap';
import axios from 'supports/api';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import EditPaymentModal from './EditPaymentModal';

const ConfirmButton = ({ isConfirmed, onClick, isLoading }) => {
  if (!isConfirmed) {
    if (isLoading)
      return (
        <Button color="secondary" disabled>
          <Loader type="Oval" color="white" height={18} width="100%" />
        </Button>
      );
    return (
      <Button
        color="success"
        onClick={onClick}
        style={{ backgroundColor: '#53b59f' }}
      >
        Confirm
      </Button>
    );
  }
  return null;
};

const PrintButton = ({ isConfirmed, onClick, loading }) => {
  if (isConfirmed) {
    return (
      <Button color="info" className="py-1" onClick={onClick}>
        <span style={{ color: 'white' }}>
          {loading ? (
            <div
              className="spinner-border spinner-border-sm mx-auto text-center"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <FontAwesomeIcon
              className="mx-auto text-center"
              icon={['fas', 'file-download']}
              size="1x"
            />
          )}
        </span>
      </Button>
    );
  }
  return null;
};

const RenderCheckCross = ({ status }) => {
  if (status)
    return (
      <FontAwesomeIcon
        className="mx-auto text-center"
        icon={['fas', 'check-circle']}
        color="green"
        size="1x"
      />
    );
  return (
    <FontAwesomeIcon
      className="mx-auto text-center"
      icon={['fas', 'times-circle']}
      color="red"
      size="1x"
    />
  );
};

const RenderProof = ({ proof }) => {
  if (proof) {
    return (
      <a
        href={process.env.REACT_APP_BASE_URL + proof}
        rel="noopener noreferrer"
        target="_blank"
        className="mx-1"
        title="Lihat Bukti"
      >
        <FontAwesomeIcon
          className="mx-auto text-center"
          icon={['fas', 'search']}
          color="black"
          size="1x"
        />
      </a>
    );
  }
  return (
    <FontAwesomeIcon
      className="mx-auto text-center"
      icon={['fas', 'times-circle']}
      color="red"
      size="1x"
    />
  );
};

// const CairButton = ({isLiquid}) => {
//     if(!isLiquid){
//         return <Button className="font-10 font-sm-8 font-md-12" onClick={() => this.onCair()}>Cair</Button>
//     }
//     else return null
// }

class PaymentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
      isLoading: false,
      receiptLoading: false,
    };
  }

  toggleEditModal = () => {
    this.setState((prevState) => ({ editModal: !prevState.editModal }));
  };

  onConfirm = () => {
    if (!this.props.nominal) {
      alert('Payment belum ada nilai nominal');
    } else if (!this.props.proof) {
      alert('Belum ada bukti pembayaran yg diupload');
    } else if (!this.props.mutationDate) {
      alert('Silahkan input tanggal mutasi terlebih dahulu.');
    } else if (!this.props.transactionId) {
      if (
        window.confirm(
          `Payment ini akan diconfirm dengan nominal ${
            this.props.currency.symbol
          }${this.props.nominal.toLocaleString()}. Lanjutkan?`,
        )
      ) {
        axios
          .put(`/admin/confirm-payment/${this.props.id}`)
          .then(() => {
            this.setState((prevState) => ({ ...prevState, isLoading: false }));
            alert('Confirm success');
            this.props.refresh();
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });
      }
    } else if (this.props.accountsReceivable) {
      if (
        window.confirm(
          `Payment ini akan diconfirm dengan nominal ${
            this.props.currency.symbol
          }${this.props.nominal.toLocaleString()}. Lanjutkan?`,
        )
      ) {
        this.setState((prevState) => ({ ...prevState, isLoading: true }));

        setTimeout(() => {
          axios
            .put(`/admin/confirm-payment/${this.props.id}`)
            .then(() => {
              alert('Confirm success');
              this.setState((prevState) => ({
                ...prevState,
                isLoading: false,
              }));
              this.props.refresh();
            })
            .catch(({ response }) => {
              let error = '';
              if (response) {
                error = response.data.message;
              } else {
                error = 'No response from server';
              }
              this.setState((prevState) => ({
                ...prevState,
                isLoading: false,
              }));
              alert(error);
            });
        }, 400); // Loads for a minimal of 0.4s
      }
    } else {
      alert('Transaksi sudah lunas');
    }
  };

  onCair = () => {
    if (!this.props.proof) {
      alert('Belum ada bukti pembayaran yg diupload');
    } else if (!this.props.confirmed) {
      alert('Pembayaran belum dikonfirmasi');
    } else if (!this.props.liquid) {
      if (window.confirm('Payment ini akan dianggap cair. Lanjutkan?')) {
        axios
          .put(`/admin/liquidpayment/${this.props.id}`)
          .then(() => {
            this.props.refresh();
            alert('Cair success');
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      alert('Transaksi sudah cair');
    }
  };

  onPrint = () => {
    this.setState((prevState) => ({
      ...prevState,
      receiptLoading: true,
    }));
    axios
      .get(`/admin/print/payment-receipt/${this.props.id}`)
      .then(() => {
        this.setState((prevState) => ({
          ...prevState,
          receiptLoading: false,
        }));
        window.open(
          `${process.env.REACT_APP_BASE_URL}/admin/print/payment-receipt/${this.props.id}`,
        );
      })
      .catch((err) => {
        this.setState((prevState) => ({
          ...prevState,
          receiptLoading: false,
        }));
        alert(err);
        console.log(err);
      });
  };

  renderPaymentMethod = (paymentMethod) => {
    return paymentMethod ? <span>{paymentMethod}</span> : <span>N/A</span>;
  };

  renderPaymentReceiptNumber = (number) => {
    const text = number ?? 'N/A';

    return <span>{text}</span>;
  };

  renderMutationDate = (date) => {
    return date ? moment(date).format('DD/MM/YYYY') : <span>N/A</span>;
  };

  renderBank = (bank) => {
    const text = bank ? `${bank.bankName} - ${bank.accountNumber}` : 'N/A';

    return <span>{text}</span>;
  };

  renderNominal = (nominal, currency) => {
    if (typeof nominal === 'number') {
      return (
        <span>
          {currency.symbol}
          {nominal.toLocaleString(currency.code === 'IDR' ? 'id-ID' : 'en-US')}
        </span>
      );
    }
    return <span>N/A</span>;
  };

  RenderDetails = () => {
    const { nominal, method, bank, currency, voucher } = this.props;

    return (
      <div className="d-flex flex-column">
        <div>Jumlah: {this.renderNominal(nominal, currency)}</div>
        <div>Metode: {this.renderPaymentMethod(method)}</div>
        {method === 'Voucher' && (
          <div>Voucher Code: {voucher.number || ''}</div>
        )}

        <div>Bank: {this.renderBank(bank)}</div>
      </div>
    );
  };

  render() {
    const {
      receiptNumber,
      mutationDate,
      proof,
      isConfirmed,
      isLiquid,
      currency,
    } = this.props;

    return (
      <tr>
        <td>{this.renderPaymentReceiptNumber(receiptNumber)}</td>
        <td>
          <this.RenderDetails />
        </td>
        <td>{this.renderMutationDate(mutationDate)}</td>
        <td>
          <RenderProof proof={proof} />
        </td>
        <td>
          <RenderCheckCross status={isConfirmed} />
        </td>
        <td>
          <RenderCheckCross status={isLiquid} />
        </td>
        <td style={{ width: '120px' }}>
          <div className="d-flex flex-column">
            <Button
              color="info"
              onClick={this.toggleEditModal}
              className="py-1 mb-1"
            >
              Edit
            </Button>
            <ConfirmButton
              isLoading={this.state.isLoading}
              onClick={this.onConfirm}
              isConfirmed={isConfirmed}
            />
            <PrintButton
              onClick={this.onPrint}
              isConfirmed={isConfirmed}
              loading={this.state.receiptLoading}
            />
            {/* <CairButton isLiquid={isLiquid}/> */}
          </div>
        </td>
        <EditPaymentModal
          isOpen={this.state.editModal}
          toggle={this.toggleEditModal}
          {...this.props}
          accountsReceivable={this.props.accountsReceivable}
          refresh={this.props.refresh}
          currency={currency}
        />
      </tr>
    );
  }
}

export default PaymentCard;
