import React from 'react';
import moment from 'moment';
import { Collapse } from 'reactstrap';
import { useFormikContext } from 'formik';
import { installmentPaymentInitialValue } from '../../static';
import { transactionInstallmentSchema } from '../../schema/TransactionInstallmentSchema';
import TransactionInstallmentPaymentForm from './components/TransactionInstallmentPaymentForm';
import TransactionInstallmentPaymentTable from './components/TransactionInstallmentPaymentTable';

const TransactionInstallmentPayment = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  let totalNominalPayment = 0;

  // To calculate total nominal payment
  values.installmentPayments.forEach((installmentPayment) => {
    totalNominalPayment += installmentPayment.nominal;
  });

  // To determine nominal payment more than equal account receivable
  const isPaymentFulfilled = totalNominalPayment >= values.accountReceivable;

  const onCreate = (index) => {
    const newValues = values.installmentPayments;

    newValues[index].isSaved = true;
    newValues[index].allowEdit = false;
    newValues[index].nominal = newValues[index].tempNominal;

    // Original Value for function "Cancel" needs, to revert the changes of data
    // Add more input value here
    newValues[index].originalValue = {
      nominal: newValues[index].tempNominal,
      tempNominal: newValues[index].tempNominal,
    };

    // Calculate totalNominalPayment with current nominal
    const nominalAfterCreated =
      totalNominalPayment + newValues[index].tempNominal;

    // Compare new total nominal payment with account receivable
    const newIsPaymentFulfilled =
      nominalAfterCreated >= values.accountReceivable;

    // If total nominal payment not fulfilled yet then add new row of payment
    if (!newIsPaymentFulfilled) {
      const newInitialValue = {
        ...installmentPaymentInitialValue,
        date: moment(newValues[index].date).add(1, 'month').toDate(),
      };

      newValues.push(newInitialValue);
    }

    setFieldValue('installmentPayments', newValues);
  };

  const onEdit = (index) => {
    const newValues = values.installmentPayments;

    newValues[index].allowEdit = false;

    // add total nominal payment with edited element
    const nominalAfterEdited =
      totalNominalPayment -
      newValues[index].nominal +
      newValues[index].tempNominal;

    // Compare new total nominal payment with account receivable
    const newIsPaymentFulfilled =
      nominalAfterEdited >= values.accountReceivable;

    // Get "isSaved" data from last element of values
    const isLastIndexFilled = newValues[newValues.length - 1].isSaved;

    // If total nominal payment not fulfilled yet and the last element of values has been saved then add new row
    if (!newIsPaymentFulfilled && isLastIndexFilled) {
      let { date } = newValues[index];

      date = moment(newValues[newValues.length - 1].date)
        .add(1, 'month')
        .toDate();

      const newInitialValue = {
        ...installmentPaymentInitialValue,
        date,
      };

      newValues.push(newInitialValue);
    }

    // If total nominal payment has fulfilled and the last element of values has not been saved then delete last row
    if (newIsPaymentFulfilled && !isLastIndexFilled) {
      newValues.pop();
    }

    newValues[index].nominal = newValues[index].tempNominal;

    // Original Value for function "Cancel" needs, to revert the changes of data
    // Add more input value here
    newValues[index].originalValue = {
      nominal: newValues[index].tempNominal,
      tempNominal: newValues[index].tempNominal,
    };

    setFieldValue('installmentPayments', newValues);
  };

  const onDelete = (index) => {
    let newValues = values.installmentPayments;

    // Get length of values
    const valuesLength = newValues.length;

    // Get deleted element
    const deletedElement = newValues[index];

    // Get "isSaved" data from last element of values
    const isLastIndexFilled = newValues[valuesLength - 1].isSaved;

    if (valuesLength > 1) {
      // If length more than 1, then formating date in each row, then filtered to remove the deleted element
      newValues = newValues
        .map((installmentPayment, installmentPaymentIndex) => {
          if (installmentPaymentIndex >= index + 1) {
            return {
              ...installmentPayment,
              date: newValues[installmentPaymentIndex - 1].date,
            };
          }

          return installmentPayment;
        })
        .filter(
          (_installmentPayment, installmentPaymentIndex) =>
            installmentPaymentIndex !== index,
        );

      // Substract total nominal payment with deleted element
      const nominalAfterDeleted = totalNominalPayment - deletedElement.nominal;

      // Compare new total nominal payment with account receivable
      const newIsPaymentFulfilled =
        nominalAfterDeleted >= values.accountReceivable;

      // If total nominal payment not fulfilled yet and the last element of values has been saved then add new row
      if (!newIsPaymentFulfilled && isLastIndexFilled) {
        let { date } = deletedElement;

        if (index !== valuesLength - 1) {
          date = moment(newValues[newValues.length - 1].date)
            .add(1, 'month')
            .toDate();
        }

        const newInitialValue = {
          ...installmentPaymentInitialValue,
          date,
        };

        newValues.push(newInitialValue);
      }

      setFieldValue('installmentPayments', newValues);
    } else {
      setFieldValue(`installmentPayments.${index}`, {
        ...installmentPaymentInitialValue,
        date: moment(values.billingDate).set('date', 1).toDate(),
      });
    }
  };

  // Handle submit
  const onSubmit = async ({ index, formType }) => {
    const value = values.installmentPayments[index];

    try {
      if (formType !== 'delete') {
        // Validate each row of transaction installment payment when submit
        await Promise.all(
          Object.keys(value).map((valueKey) =>
            transactionInstallmentSchema.validateAt(
              `installmentPayments[${index}][${valueKey}]`,
              values,
            ),
          ),
        );
      }

      let confirmationData = {};

      switch (formType) {
        case 'create':
          confirmationData = {
            action: () => onCreate(index),
          };
          break;

        case 'edit':
          confirmationData = {
            action: () => onEdit(index),
          };
          break;

        case 'delete':
          confirmationData = {
            action: () => onDelete(index),
          };
          break;

        default:
          break;
      }

      confirmationData.action();
    } catch (error) {
      Object.keys(value).forEach((valueKey) => {
        setFieldTouched(`installmentPayments.${index}.${valueKey}`, true);
      });
    }
  };

  return (
    <Collapse isOpen={values.readOnly}>
      <div className="p-4">
        <div
          className="mb-2 text-bold"
          style={{ color: isPaymentFulfilled ? 'green' : 'red' }}
        >
          Total Nominal Pembayaran : Rp {totalNominalPayment.toLocaleString()}
        </div>

        <TransactionInstallmentPaymentTable>
          <tbody>
            <TransactionInstallmentPaymentForm onSubmit={onSubmit} />
          </tbody>
        </TransactionInstallmentPaymentTable>
      </div>
    </Collapse>
  );
};

export default TransactionInstallmentPayment;
