import React from 'react';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { checkForm } from 'supports/helpers/permissions';

const SideBarCollapse = ({
  header,
  children,
  collapseId,
  activeId,
  onClick,
  icon,
  permissions,
}) => {
  const access = useSelector((state) => state?.auth?.access);
  const active = collapseId && activeId ? collapseId === activeId : false;

  let display = '';
  // accepts string or array of strings
  const renderWithPermissions = (keys) => {
    const forms = [].concat(keys);
    forms.forEach((item) => {
      if (checkForm(access, item)) {
        display = '';
      }
    });
  };

  if (permissions) {
    display = 'd-none';
    renderWithPermissions(permissions);
  }

  return (
    <div
      key={`sidebarheader${header}`}
      style={{ borderBottom: '1px solid #e8e8e8' }}
    >
      <div
        className={`py-3 font-14 pl-2 sidebar-header ${
          active ? 'active' : ''
        } ${display}`}
        onClick={() => onClick(collapseId)}
        role="button"
        onKeyDown={() => onClick(collapseId)}
        tabIndex={0}
      >
        <FontAwesomeIcon className="mx-3" icon={['fas', icon]} />
        {header}
      </div>
      <Collapse isOpen={active}>{children}</Collapse>
    </div>
  );
};

export default SideBarCollapse;
