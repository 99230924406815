import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BatchList from './BatchList';
import StudentList from './StudentList';
import MentoringList from './MentoringList';

const MentoringSessions = (props) => {
  const { match } = props;
  const { path } = match;
  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <Redirect to={`${path}/batch`} />
      </Route>
      <Route exact path={`${path}/batch`} component={BatchList} />
      <Route
        exact
        path={`${path}/:programId/student`}
        component={StudentList}
      />
      <Route
        exact
        path={`${path}/:programId/student/:attendeeId/sessions`}
        component={MentoringList}
      />
    </Switch>
  );
};

export default MentoringSessions;
