import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Collapse,
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  filteringStudentData,
  initialFetchStudentData,
  setFilterQuery,
  setFilterBranch,
  setFilterProgram,
  setFilterBatch,
  filteringBatchesOption,
} from 'redux/modules/studentData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomPaginationV2 from 'components/commons/CustomPaginationV2';
import qs from 'query-string';
import Loader from 'react-loader-spinner';
import StudentCard from './StudentCard';
import ExportExcel from './ExportCSV';

const StudentDataList = () => {
  const [filterOpen, setFilterOpen] = useState(false);

  const dispatch = useDispatch();

  const { filterQuery, filterProgram, filterBranch, filterBatch } = useSelector(
    (state) => state.studentData.input,
  );

  const data = useSelector((state) => state.studentData.data);
  const filtered = useSelector((state) => state.studentData.filtered);
  const options = useSelector((state) => state.studentData.options);
  const loading = useSelector((state) => state.studentData.loading);

  const location = useLocation();
  const history = useHistory();
  const queryParam = qs.parse(location.search);
  const { page } = queryParam;

  useEffect(() => {
    dispatch(initialFetchStudentData());
  }, [dispatch]);

  const tableData = () => {
    const sliced = filtered.slice((page - 1) * 10, (page - 1) * 10 + 10);
    const arrJSX = sliced.map((item, index) => {
      return <StudentCard key={item.id} index={index} item={item} />;
    });

    return <tbody>{arrJSX}</tbody>;
  };

  useEffect(() => {
    if (data.length) {
      dispatch(
        filteringStudentData({
          filterQuery,
          filterProgram,
          filterBranch,
          filterBatch,
        }),
      );
    }
  }, [
    dispatch,
    filterQuery,
    filterProgram,
    filterBranch,
    filterBatch,
    history,
    data,
  ]);

  useEffect(() => {
    if (filterBranch || filterProgram) {
      dispatch(
        filteringBatchesOption({
          branchId: filterBranch,
          programHeaderId: filterProgram,
        }),
      );
    }
  }, [dispatch, filterBranch, filterProgram]);

  const handleToPageOne = () => {
    history.push(
      `?${qs.stringify({
        ...queryParam,
        page: 1,
      })}`,
    );
  };

  const renderProgramOptions = () => {
    return options.programNames.map((programName) => {
      return (
        <option key={programName.id} value={programName.id}>
          {programName.name}
        </option>
      );
    });
  };

  const renderBranchOptions = () => {
    return options.branches.map((branch) => {
      return (
        <option key={branch.id} value={branch.id}>
          {branch.name}
        </option>
      );
    });
  };

  const renderBatchOptions = () => {
    return options.filteredBatches.map((batch) => {
      // its program code basically
      return (
        <option key={batch.id} value={batch.id}>
          {batch.code}
        </option>
      );
    });
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <Loader type="ThreeDots" color="black" />
      </div>
    );
  }
  return (
    <div className="white-box">
      <div className="row">
        <div className="col-12">
          <Card>
            <CardBody>
              <div className="container-fluid white-box">
                <div className="row">
                  <div className="col-5 my-auto">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Search: </InputGroupText>
                      </InputGroupAddon>
                      <input
                        value={filterQuery}
                        type="text"
                        className="form-control"
                        placeholder="name/program/gender/kota"
                        onChange={(e) => {
                          handleToPageOne();
                          dispatch(setFilterQuery(e.target.value));
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="secondary">Enter</Button>
                      </InputGroupAddon>
                    </InputGroup>
                    <div className="mt-2 d-flex">
                      <div
                        className={`custom-outline ${
                          filterOpen ? 'filter-button' : ''
                        }`}
                        onClick={() => setFilterOpen(!filterOpen)}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        More Filters{' '}
                        <span>
                          <FontAwesomeIcon
                            className="mx-auto text-center"
                            icon={[
                              'fas',
                              `chevron-${filterOpen ? 'up' : 'down'}`,
                            ]}
                            size="1x"
                          />
                        </span>
                      </div>
                    </div>
                    <Collapse isOpen={filterOpen}>
                      <div className="form-group mt-1">
                        <select
                          value={filterProgram}
                          onChange={(e) => {
                            handleToPageOne();
                            dispatch(setFilterProgram(e.target.value));
                          }}
                          className="form-control mb-1"
                        >
                          <option value="">All Programs</option>
                          {renderProgramOptions()}
                        </select>
                        <select
                          value={filterBranch}
                          onChange={(e) => {
                            handleToPageOne();
                            dispatch(setFilterBranch(e.target.value));
                          }}
                          className="form-control mb-1"
                        >
                          <option value="">All Branch</option>
                          {renderBranchOptions()}
                        </select>
                        <select
                          value={filterBatch}
                          onChange={(e) => {
                            handleToPageOne();
                            dispatch(setFilterBatch(e.target.value));
                          }}
                          className="form-control"
                        >
                          <option value="">All Batch</option>
                          {renderBatchOptions()}
                        </select>
                      </div>
                    </Collapse>
                  </div>
                  <div className="col-7 d-flex align-items-start justify-content-end">
                    <ExportExcel
                      csvData={filtered || data}
                      fileName="studentData"
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Table striped bordered responsive hover>
            <thead>
              <tr>
                <th>Nama</th>
                <th>Program</th>
                <th>Tanggal Lahir</th>
                <th>Gender</th>
                <th>Kota</th>
                <th>Action</th>
              </tr>
            </thead>
            {tableData()}
          </Table>
          <CustomPaginationV2
            pages={Math.ceil(filtered.length / 10) || 1}
            active={page}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentDataList;
