import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import axios from 'supports/api';

// Object creator
import createTransactionItem from 'components/Transactions/create-transaction/modals/itemCreator';

import FilterEvent from './components/FilterEvent';
import DetailPrice from './components/DetailPrice';

const filterSchema = Yup.object({
  type: Yup.string().required(),
  category: Yup.string().required(),
  programCategoryId: Yup.number().required(),
});

const initialState = {
  type: '',
  category: '',
  programCategoryId: 0,
};

const AddAlumniEventModal = ({ toggle, isOpen, addTransactionItem }) => {
  const [filterState, setFilterState] = useState({
    ...initialState,
  });
  const [discount, setDiscount] = useState(0);

  const [alumniEvents, setAlumniEvents] = useState([]);
  const [selectedAlumniEvent, setSelectedAlumniEvent] = useState({});

  const [
    alumniEventProgramCategories,
    setAlumniEventProgramCategories,
  ] = useState([]);

  const getAlumniEventProgramCategories = useCallback(async () => {
    try {
      const resAlumniEventProgramCategories = await axios.get(
        '/v2/alumni-event-program-category',
      );

      setAlumniEventProgramCategories(
        resAlumniEventProgramCategories.data.data,
      );
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getAlumniEvents = useCallback(async () => {
    try {
      const resAlumniEvents = await axios.get('/v2/alumni-event/all', {
        params: {
          ...filterState,
        },
      });

      setAlumniEvents(resAlumniEvents.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [filterState]);

  useEffect(() => {
    getAlumniEventProgramCategories();
  }, [getAlumniEventProgramCategories]);

  useEffect(() => {
    getAlumniEvents();
  }, [getAlumniEvents]);

  const onAddClick = async () => {
    try {
      await filterSchema.validate(filterState);

      const transactionItem = createTransactionItem({
        id: selectedAlumniEvent.id, // universal
        price: selectedAlumniEvent.normalPrice, // universal
        discount,
        productType: 'alumni_events', // universal,
      });

      await addTransactionItem([transactionItem]);

      toggle();
    } catch (error) {
      console.log(error);
    }
  };

  const resetState = () => {
    setFilterState({
      ...initialState,
    });
    setSelectedAlumniEvent({});
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" onClosed={resetState}>
      <ModalHeader>Add Program Item</ModalHeader>
      <ModalBody>
        <Card className="my-1">
          <CardHeader>Select Program</CardHeader>
          <CardBody>
            <FilterEvent
              setDiscount={setDiscount}
              alumniEvents={alumniEvents}
              filter={{ filterState, setFilterState }}
              programCategories={alumniEventProgramCategories}
              selectedEvent={{
                selectedAlumniEvent,
                setSelectedAlumniEvent,
              }}
            />
          </CardBody>
        </Card>
        <Card className="my-1">
          <CardBody>
            <DetailPrice
              item={selectedAlumniEvent}
              discountState={{ discount, setDiscount }}
            />
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-row float-right">
          <Button
            color="success"
            style={{ backgroundColor: '#53b59f' }}
            onClick={onAddClick}
          >
            Add
          </Button>
          <Button className="ml-2" color="danger" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddAlumniEventModal;
