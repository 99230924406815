import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import Axios from 'supports/api';
import { useLocation } from 'react-router';
import qs from 'query-string';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faEdit,
  faImage,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { API_URL_1 } from 'supports/api-url/apiurl';
import taxValueChecker from 'supports/helpers/taxValueChecker';

const EFakturDataTab = ({ handleFetchTransactions }) => {
  const [transactionData, setTransactionData] = useState({});
  const [previousData, setPreviousData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [imagePath, setImagePath] = useState('');
  const [imageModalHeader, setImageModalHeader] = useState('');

  const location = useLocation();

  const { id, tab } = qs.parse(location.search);

  useEffect(() => {
    const fetchTransactionDataById = async () => {
      try {
        if (id) {
          const transaction = await Axios.get(`/transaction/paid/${id}`);
          const { taxInvoiceDate, invoiceCreatedAt } = transaction.data.result;
          transaction.data.result.taxInvoiceDate = taxInvoiceDate
            ? new Date(taxInvoiceDate)
            : new Date();
          transaction.data.result.invoiceCreatedAt = new Date(invoiceCreatedAt);
          setTransactionData(transaction.data.result);
        }
      } catch (err) {
        window.alert(err);
        console.log(err);
      }
    };
    fetchTransactionDataById();
  }, [id]);

  useEffect(() => {
    setEditMode(false);
  }, [tab]);

  const handleInput = (e) => {
    setTransactionData({
      ...transactionData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setPreviousData(transactionData);
    setEditMode(true);
  };

  const handleCancel = () => {
    setTransactionData(previousData);
    setEditMode(false);
  };

  const handleEditTransaction = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!id) {
        setLoading(false);
        setEditMode(false);
        setTransactionData(previousData);
        return window.alert('No transaction selected');
      }
      if (window.confirm('Apakah anda ingin mengubah data transaksi ini?')) {
        await Axios.put(
          `/transaction/update-tax/${transactionData.id}`,
          transactionData,
        );
        handleFetchTransactions();
        setLoading(false);
        setEditMode(false);
        return window.alert('Data transaksi berhasil diubah.');
      }
      return setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      return window.alert(`Terjadi kesalahan system. ${err}`);
    }
  };

  const handleModal = (image) => {
    setModalOpen(!modalOpen);
    setImageModalHeader(`${image.toUpperCase()} Image`);
    if (image === 'npwp') {
      setImagePath(transactionData.npwpImg);
    } else {
      setImagePath(transactionData.identityCardImg);
    }
  };

  const DataTableBody = () => {
    const tableJsxArr = [];
    const {
      programSales,
      onlineCourseSales,
      serviceSales,
      subscriptionSales,
      alumniEventSales,
      invoiceCreatedAt,
    } = transactionData;

    let grandNominal = 0;
    let grandDiscount = 0;
    let grandQty = 0;
    let grandPPN = 0;
    let grandDPP = 0;
    let grandTotalNominal = 0;
    const taxValue = taxValueChecker(invoiceCreatedAt);
    const generateTableRow = (sales, productType) => {
      const { price, registrationFee, finalPrice, discount } = sales;

      let itemName = '';
      let itemQty = 1;
      if (productType === 'program') {
        itemName = `Pendaftaran Program ${sales.name}`;
        itemQty = sales.qty;
      } else if (productType === 'onlineCourse') {
        itemName = `${sales.category} ${sales.name}`;
      } else {
        itemName = sales.name;
      }

      // per item
      const itemNominal = Math.round(
        (price + (registrationFee || 0)) / (1 + taxValue),
      );

      // per item discount
      const itemDiscount = Math.round(discount / (1 + taxValue));

      // perItem * qty (totalDPP)
      const itemDPP = Math.round(finalPrice / (1 + taxValue));

      // totalPPN
      const itemPPN = Math.round((finalPrice / (1 + taxValue)) * taxValue);

      // total yang dibayar
      const itemTotalNominal = itemDPP + itemPPN;

      grandNominal += itemNominal * itemQty;
      grandDiscount += itemDiscount * itemQty;
      grandQty += itemQty;
      grandPPN += itemPPN;
      grandDPP += itemDPP;
      grandTotalNominal += itemTotalNominal;

      return (
        <tr>
          <td>1</td>
          <td>{itemName}</td>
          <td style={{ textAlign: 'right' }}>
            Rp.{itemNominal.toLocaleString()}
          </td>
          <td style={{ textAlign: 'right' }}>
            Rp.{itemDiscount.toLocaleString()}
          </td>
          <td style={{ textAlign: 'center' }}>{itemQty}</td>
          <td style={{ textAlign: 'right' }}>Rp.{itemDPP.toLocaleString()}</td>
          <td style={{ textAlign: 'right' }}>Rp.{itemPPN.toLocaleString()}</td>
          <td style={{ textAlign: 'right' }}>
            Rp.{itemTotalNominal.toLocaleString()}
          </td>
        </tr>
      );
    };

    programSales.forEach((sales) => {
      const salesItemObj = generateTableRow(sales, 'program');
      tableJsxArr.push(salesItemObj);
    });

    serviceSales.forEach((sales) => {
      const salesItemObj = generateTableRow(sales, 'service');
      tableJsxArr.push(salesItemObj);
    });

    onlineCourseSales.forEach((sales) => {
      const salesItemObj = generateTableRow(sales, 'onlineCourse');
      tableJsxArr.push(salesItemObj);
    });

    subscriptionSales.forEach((sales) => {
      const salesItemObj = generateTableRow(sales, 'subscription');
      tableJsxArr.push(salesItemObj);
    });

    alumniEventSales.forEach((sales) => {
      const salesItemObj = generateTableRow(sales, 'alumniEvent');
      tableJsxArr.push(salesItemObj);
    });

    tableJsxArr.push(
      <tr key="Grand Total">
        <td colSpan={2}>
          <span className="float-right font-weight-bold">Grand Total</span>
        </td>
        <td style={{ textAlign: 'right' }}>
          Rp.{grandNominal.toLocaleString()}
        </td>
        <td style={{ textAlign: 'right' }}>
          Rp.{grandDiscount.toLocaleString()}
        </td>
        <td style={{ textAlign: 'center' }}>{grandQty}</td>
        <td style={{ textAlign: 'right' }}>Rp.{grandDPP.toLocaleString()}</td>
        <td style={{ textAlign: 'right' }}>Rp.{grandPPN.toLocaleString()}</td>
        <td style={{ textAlign: 'right' }}>
          Rp.{grandTotalNominal.toLocaleString()}
        </td>
      </tr>,
    );

    return tableJsxArr;
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <Form onSubmit={handleEditTransaction}>
              <Row>
                <Col sm="12" className="mb-2">
                  <h4 className="text-gray">Transaction Detail</h4>
                </Col>
                <Col sm="6">
                  <Row>
                    <Col sm="12">
                      <FormGroup row>
                        <Label sm={4}>No. Invoice</Label>
                        <Col sm={7}>
                          <Input
                            type="text"
                            value={transactionData.invoiceNumber || ''}
                            name="invoiceNumber"
                            onChange={(e) => handleInput(e)}
                            disabled
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup row>
                        <Label sm={4}>Tgl. Invoice</Label>
                        <Col sm={7}>
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            selected={transactionData.invoiceCreatedAt}
                            onChange={(selectedDate) =>
                              setTransactionData({
                                ...transactionData,
                                invoiceCreatedAt: selectedDate,
                              })
                            }
                            disabled
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup row>
                        <Label sm={4}>No. Faktur</Label>
                        <Col sm={7}>
                          <NumberFormat
                            className="form-control"
                            value={transactionData.taxInvoiceNumber}
                            placeholder="000.000-00.00000000"
                            format="###.###-##.########"
                            onValueChange={(e) =>
                              setTransactionData({
                                ...transactionData,
                                taxInvoiceNumber: e.value,
                              })
                            }
                            disabled={!editMode}
                            required
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup row>
                        <Label sm={4}>Tgl. Faktur</Label>
                        <Col sm={7}>
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            selected={transactionData.taxInvoiceDate}
                            onChange={(selectedDate) =>
                              setTransactionData({
                                ...transactionData,
                                taxInvoiceDate: selectedDate,
                              })
                            }
                            disabled={!editMode}
                            required
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col sm="6">
                  <Row>
                    <Col sm="12">
                      <FormGroup row>
                        <Label sm={4}>Nama Customer</Label>
                        <Col sm={7}>
                          <Input
                            type="text"
                            value={transactionData.fullName || ''}
                            name="fullName"
                            onChange={(e) => handleInput(e)}
                            disabled
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup row>
                        <Label sm={4}>Alamat</Label>
                        <Col sm={7}>
                          <Input
                            type="text"
                            value={transactionData.address || ''}
                            name="address"
                            onChange={(e) => handleInput(e)}
                            disabled
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup row>
                        <Label sm={4}>NPWP</Label>
                        <Col sm={7}>
                          <NumberFormat
                            className="form-control"
                            value={transactionData.npwp || ''}
                            placeholder="00.000.000.0-000.000"
                            format="##.###.###.#-###.###"
                            onValueChange={(e) =>
                              setTransactionData({
                                ...transactionData,
                                npwp: e.value,
                              })
                            }
                            disabled={!editMode}
                          />
                        </Col>
                        <Col
                          className="d-flex flex-row align-items-center"
                          sm={1}
                        >
                          <FontAwesomeIcon
                            style={{ cursor: 'pointer' }}
                            className="text-center"
                            icon={faImage}
                            size="lg"
                            onClick={() => handleModal('npwp')}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup row>
                        <Label sm={4}>NIK</Label>
                        <Col sm={7}>
                          <Input
                            type="text"
                            value={transactionData.identityCard || ''}
                            name="identityCard"
                            onChange={(e) => handleInput(e)}
                            disabled={!editMode}
                            maxLength={16}
                          />
                        </Col>
                        <Col
                          className="d-flex flex-row align-items-center"
                          sm={1}
                        >
                          <FontAwesomeIcon
                            style={{ cursor: 'pointer' }}
                            className="text-center"
                            icon={faImage}
                            size="lg"
                            onClick={() => handleModal('ktp')}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col sm="12">
                  <div className="d-flex justify-content-center mt-3">
                    <Button
                      type="submit"
                      color="success"
                      disabled={!editMode}
                      className="mr-2"
                    >
                      {loading ? (
                        <div
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                      )}
                      Save
                    </Button>
                    <Button
                      color={editMode ? 'danger' : 'secondary'}
                      onClick={editMode ? handleCancel : handleEdit}
                    >
                      {editMode ? (
                        <>
                          <FontAwesomeIcon icon={faBan} className="mr-2" />
                          Cancel
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faEdit} className="mr-2" />
                          Edit
                        </>
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
          <CardBody>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nama Produk</th>
                  <th>Harga Satuan</th>
                  <th>Discount</th>
                  <th>Jumlah Barang</th>
                  <th>DPP</th>
                  <th>PPN</th>
                  <th>Nominal</th>
                </tr>
              </thead>
              <tbody>{transactionData.id ? <DataTableBody /> : null}</tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        size="lg"
      >
        <ModalHeader>Preview {imageModalHeader}</ModalHeader>
        <ModalBody>
          <div>
            <img
              style={{
                width: '100%',
                maxHeight: '600px',
                objectFit: 'contain',
              }}
              width="100%"
              src={API_URL_1 + imagePath}
              alt="npwp card"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex-row float-right">
            <Button
              onClick={() => setModalOpen(!modalOpen)}
              color="success"
              style={{ backgroundColor: '#53b59f' }}
            >
              OK
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default EFakturDataTab;
