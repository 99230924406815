import React, { Component } from 'react';
import axios from 'supports/api';
import {
  Card,
  Label,
  CardBody,
  FormGroup,
  CustomInput,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
} from 'reactstrap';
import moment from 'moment';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkForm } from 'supports/helpers/permissions';
import CreateVoucherModal from './CreateVoucherModal';
import VoucherCard from './VoucherCard';
import CustomPagination from '../commons/CustomPagination';

class VouchersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vouchers: [],
      filtered: [],
      modal: false,
      tooltip: [false, false, false, false],
      access: false,
    };
  }

  componentDidMount() {
    const render = checkForm(this.props.auth.access, 'Vouchers Manage');
    if (render) {
      this.getData();
    }
  }

  getData = () => {
    axios
      .get(`/admin/allvouchers`)
      .then((res) => {
        const { result } = res.data;
        const { search } = this.props.location;
        const params = new URLSearchParams(search);
        const page = params.get('page');
        if (page > Math.abs(result.length / 10) || !page) {
          this.props.history.push('?page=1');
        }
        this.setState({ vouchers: result, filtered: result });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  evalAccess = (tooltip, access) => {
    this.setState({ tooltip, access });
  };

  toggleModal = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  TableHeader = () => {
    return (
      <thead>
        <tr>
          <th>id</th>
          <th>Batch</th>
          <th>Number</th>
          <th>Nominal</th>
          <th>Vendor</th>
          <th>Valid From</th>
          <th>Valid To</th>
          <th>Available</th>
          {/* <th>Actions</th> */}
        </tr>
      </thead>
    );
  };

  TableData = () => {
    const { search } = this.props.location;
    const params = new URLSearchParams(search);
    const page = params.get('page');
    const sliced = this.state.filtered.slice(
      (page - 1) * 10,
      (page - 1) * 10 + 10,
    );
    const arrJSX = sliced.map((item, index) => {
      return <VoucherCard key={item.id} index={index} item={item} />;
    });

    return <tbody>{arrJSX}</tbody>;
  };

  filter(filter) {
    const filtered = [];
    this.state.vouchers.forEach((item) => {
      const { batch, number, nominal, vendor } = item;
      if (
        batch.toString().toLowerCase().indexOf(filter) !== -1 ||
        number.toString().indexOf(filter) !== -1 ||
        nominal.toString().toLocaleLowerCase().indexOf(filter) !== -1 ||
        vendor.toLocaleLowerCase().indexOf(filter) !== -1
      ) {
        filtered.push(item);
      }
    });

    const statusFilter = [];
    filtered.forEach((item) => {
      if (document.getElementById('checkValid').checked) {
        if (
          moment(item.validFrom) < moment() &&
          moment() < moment(item.validTo).add(1, 'days')
        ) {
          if (document.getElementById('checkAvailable').checked) {
            if (item.available) {
              statusFilter.push(item);
            }
          } else if (document.getElementById('checkUnavailable').checked) {
            if (!item.available) {
              statusFilter.push(item);
            }
          } else {
            statusFilter.push(item);
          }
        }
      }
      if (document.getElementById('checkInvalid').checked) {
        if (
          moment(item.validFrom) > moment() ||
          moment() > moment(item.validTo)
        ) {
          if (document.getElementById('checkAvailable').checked) {
            if (item.available) {
              statusFilter.push(item);
            }
          } else if (document.getElementById('checkUnavailable').checked) {
            if (!item.available) {
              statusFilter.push(item);
            }
          } else {
            statusFilter.push(item);
          }
        }
      }
      if (
        document.getElementById('checkAvailable').checked &&
        document.getElementById('checkInvalid').checked === false &&
        document.getElementById('checkValid').checked === false
      ) {
        if (item.available) {
          statusFilter.push(item);
        }
      }
      if (
        document.getElementById('checkUnavailable').checked &&
        document.getElementById('checkInvalid').checked === false &&
        document.getElementById('checkValid').checked === false
      ) {
        if (!item.available) {
          statusFilter.push(item);
        }
      }
    });
    if (
      document.getElementById('checkValid').checked ||
      document.getElementById('checkInvalid').checked ||
      document.getElementById('checkAvailable').checked ||
      document.getElementById('checkUnavailable').checked
    ) {
      this.setState({ filtered: statusFilter });
    } else {
      this.setState({ filtered });
    }
    this.props.history.push('?page=1');
  }

  renderSearchBar() {
    return (
      <Card>
        <CardBody>
          <div className="container-fluid white-box">
            <div className="row">
              <div className="col-5 my-auto">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Search: </InputGroupText>
                  </InputGroupAddon>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="#/name/item"
                    ref="searchParams"
                    onChange={() => {
                      this.filter(this.refs.searchParams.value);
                    }}
                  />
                  {/* <InputGroupAddon addonType="append">
                                    <Button color="secondary">Enter</Button>
                                    </InputGroupAddon> */}
                </InputGroup>
              </div>
              <div className="col-7 my-auto">
                <FormGroup>
                  <Label for="exampleCheckbox">Status: </Label>
                  <div>
                    <CustomInput
                      type="checkbox"
                      id="checkValid"
                      label="Valid"
                      inline
                      onChange={() => {
                        this.filter(this.refs.searchParams.value);
                      }}
                    />
                    <CustomInput
                      type="checkbox"
                      id="checkInvalid"
                      label="Invalid"
                      inline
                      onChange={() => {
                        this.filter(this.refs.searchParams.value);
                      }}
                    />
                    <CustomInput
                      type="checkbox"
                      id="checkAvailable"
                      label="Available"
                      inline
                      onChange={() => {
                        this.filter(this.refs.searchParams.value);
                      }}
                    />
                    <CustomInput
                      type="checkbox"
                      id="checkUnavailable"
                      label="Unavailable"
                      inline
                      onChange={() => {
                        this.filter(this.refs.searchParams.value);
                      }}
                    />
                  </div>
                </FormGroup>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderAdminVouchers() {
    const render = checkForm(this.props.auth.access, 'Vouchers Manage');
    if (render) {
      const { search } = this.props.location;
      const params = new URLSearchParams(search);
      const page = params.get('page');
      return (
        <div className="white-box container-fluid py-2">
          <div className="row px-1 py-5">
            <div className="col-12 offset-sm-1 col-sm-10">
              <div>
                <div className="line-green mb-4" />
                <div className="general-title text-gray">
                  Vouchers
                  <Button className="float-right" onClick={this.toggleModal}>
                    + Create Vouchers
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row px-1 py-3">
            <div className="col-12 offset-sm-1 col-sm-10">
              {this.renderSearchBar()}
              <Table striped bordered responsive hover>
                <this.TableHeader />
                <this.TableData />
              </Table>
              <CustomPagination
                pages={Math.ceil(this.state.filtered.length / 10) || 1}
                active={page}
              />
            </div>
          </div>
          <CreateVoucherModal
            isOpen={this.state.modal}
            toggle={this.toggleModal}
            tooltip={this.state.tooltip}
            evalAccess={this.evalAccess}
            refresh={this.getData}
          />
        </div>
      );
    }
    return <Redirect to="/" />;
  }

  render() {
    return this.renderAdminVouchers();
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return { auth };
};

export default withRouter(connect(mapStateToProps, {})(VouchersPage));
