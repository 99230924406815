import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'supports/api';
import { fetchPoHeadersById } from 'redux/modules/purchaseOrder';
import { Input, Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import ModalUploadPOItemProof from './ModalUploadPOItems';
import ModalPreviewPOItemProof from './ModalPreviewPOItemProof';

const TablePOData = ({
  poId,
  selectedId,
  poItemDetailById,
  setpoItemDetailById,
  readOnly,
  setPoItems,
  poItems,
  vPrice,
  setVPrice,
  vDetails,
  setVDetails,
}) => {
  const [form, setForm] = useState({
    id: 0,
    details: '',
    priceDisplay: '0',
    priceValue: 0,
    files: [],
  });

  // const [poItems, setPoItems] = useState([]);
  const [toggle, setToggle] = useState(null);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [idLoadingSave, setIdLoadingSave] = useState(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [idLoadingDelete, setIdLoadingDelete] = useState(null);
  const [editDetails, setEditDetails] = useState('');
  const [editPrice, setEditPrice] = useState({
    display: '',
    value: 0,
  });
  const [uploadModal, setUploadModal] = useState({
    opened: false,
    selectedItemId: '',
  });
  const [previewModal, setPreviewModal] = useState({
    opened: false,
    selectedItemId: '',
  });

  const dispatch = useDispatch();
  const gPoItems = useSelector(
    ({ purchaseOrder }) => purchaseOrder.poHeaders.poItems,
  );

  const { details, priceDisplay, priceValue, files } = form;

  const renderPoItems = useCallback(() => {
    if (gPoItems) setPoItems(gPoItems);
  }, [gPoItems, setPoItems]);

  useEffect(() => {
    renderPoItems();
  }, [renderPoItems]);

  const validateFalse = useCallback(() => {
    if (details) setVDetails(false);
    if (priceValue && priceDisplay) setVPrice(false);
  }, [details, priceValue, priceDisplay, setVDetails, setVPrice]);

  useEffect(() => {
    validateFalse();
  }, [validateFalse]);

  const handleClear = () => {
    setForm({
      ...form,
      details: '',
      priceDisplay: '0',
      priceValue: 0,
      files: [],
    });
    setVDetails(false);
    setVPrice(false);
  };

  const editFormatDisplay = (num) => {
    const nums = parseInt(num.split(',').join(''));
    if (num.split(',').join('') === '') {
      setEditPrice({
        display: '0',
        value: 0,
      });
    } else {
      setEditPrice({
        ...editPrice,
        display: nums.toLocaleString(),
        value: nums,
      });
    }
  };

  const formatDisplay = (num) => {
    const number = parseInt(num.split(',').join(''));
    if (num.split(',').join('') === '') {
      setForm({
        ...form,
        priceDisplay: '0',
        priceValue: 0,
      });
    } else {
      setForm({
        ...form,
        priceDisplay: number.toLocaleString(),
        priceValue: number,
      });
    }
  };

  const handleRefetchPoItem = async () => {
    try {
      if (!selectedId) {
        dispatch(fetchPoHeadersById(poId));
      } else {
        const res = await axios.get(`/poHeader/getById/${selectedId}`);

        setpoItemDetailById(res.data.result.poItems);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadImage = async (files) => {
    if (uploadModal.selectedItemId) {
      const formData = new FormData();

      for (let i = 0; i < files.length; i += 1) {
        formData.append('proofs', files[i]);
      }

      await axios.put(
        `/poItem/update-proofs/${uploadModal.selectedItemId}`,
        formData,
      );

      await handleRefetchPoItem();
    } else {
      setForm({ ...form, files });
    }
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleAdd = () => {
    if (details && priceValue) {
      if (window.confirm('Are you sure you want to add these details ?')) {
        setIsLoadingAdd(true);

        const formData = new FormData();

        for (let i = 0; i < files.length; i += 1) {
          formData.append('proofs', files[i]);
        }

        formData.append('poHeaderId', !selectedId ? poId : selectedId);
        formData.append('details', details);
        formData.append('price', priceValue);

        axios
          .post(`/poItem/create`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => {
            setIsLoadingAdd(false);
            !selectedId
              ? dispatch(fetchPoHeadersById(poId))
              : axios
                  .get(`/poHeader/getById/${selectedId}`)
                  .then((res) => {
                    setpoItemDetailById(res.data.result.poItems);
                    dispatch(fetchPoHeadersById(selectedId));
                  })
                  .catch((err) => alert(`Terjadi kesalahan ystem ${err}`));
          })
          .catch((err) => {
            alert(`Terjadi kesalahan system ${err}`);
            setIsLoadingAdd(false);
          });

        handleClear();
      }
    } else {
      alert('Please Insert Details and Price !');
      if (!details) setVDetails(true);
      if (!priceValue) setVPrice(true);
    }
  };

  const handleSave = (id, details, price) => {
    setIsLoadingSave(true);
    setIdLoadingSave(id);
    const data = {
      details: editDetails || details,
      price: editPrice.value ? editPrice.value : price,
    };
    axios
      .put(`/poItem/update/${id}`, data)
      .then((res) => {
        setIsLoadingSave(false);
        setIdLoadingSave(null);
        !selectedId
          ? dispatch(fetchPoHeadersById(poId))
          : axios
              .get(`/poHeader/getById/${selectedId}`)
              .then((res) => {
                setpoItemDetailById(res.data.result.poItems);
              })
              .catch((err) => alert(`Terjadi kesalahan ystem ${err}`));
      })
      .catch((err) => {
        alert(`Terjadi kesalahan system ${err}`);
        setIsLoadingSave(false);
        setIdLoadingSave(null);
      });
    setToggle(null);
    setEditDetails('');
    setEditPrice({
      display: '',
      value: 0,
    });
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure want to delete these details ?')) {
      setIsLoadingDelete(true);
      setIdLoadingDelete(id);
      axios
        .delete(`/poItem/delete/${id}`)
        .then((res) => {
          setIsLoadingDelete(false);
          setIdLoadingDelete(null);
          !selectedId
            ? dispatch(fetchPoHeadersById(poId))
            : axios
                .get(`/poHeader/getById/${selectedId}`)
                .then((res) => {
                  setpoItemDetailById(res.data.result.poItems);
                  dispatch(fetchPoHeadersById(selectedId));
                })
                .catch((err) => alert(`Terjadi kesalahan system ${err}`));
        })
        .catch((err) => {
          setIsLoadingDelete(false);
          setIdLoadingDelete(null);
          alert(`Terjadi kesalahan system ${err}`);
        });
    }
  };

  const renderBody = () => {
    if (!selectedId) {
      if (poItems) {
        return poItems.map((val, idx) => {
          const { id, details, price, files } = val;
          if (toggle === id) {
            return (
              <tr>
                <td>#</td>
                <td>
                  <Input
                    type="text"
                    placeholder="Input Details"
                    defaultValue={details}
                    onChange={(e) => setEditDetails(e.target.value)}
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    placeholder="0"
                    value={editPrice.display}
                    defaultValue={price ? price.toLocaleString() : null}
                    onChange={(e) => editFormatDisplay(e.target.value)}
                  />
                </td>
                <td align="center">
                  {files?.length ? (
                    <FontAwesomeIcon
                      icon={faFileAlt}
                      style={{
                        width: '25px',
                        height: '25px',
                        color: '#6c757d',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        setPreviewModal({ opened: true, selectedItemId: id })
                      }
                    />
                  ) : (
                    'N/A'
                  )}
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <Button
                      color="primary"
                      className="btn text-center"
                      style={{ width: '100px' }}
                      onClick={() =>
                        setUploadModal({ opened: true, selectedItemId: id })
                      }
                    >
                      Upload
                    </Button>
                    <Button
                      className="btn btn-primary text-center ml-2"
                      style={{ width: '100px' }}
                      onClick={() => handleSave(id, details, price)}
                    >
                      {isLoadingSave && idLoadingSave === id ? (
                        <Spinner size="sm" color="white" />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <Button
                      className="btn btn-danger text-center ml-2"
                      style={{ width: '100px' }}
                      onClick={() => setToggle(null)}
                    >
                      Cancel
                    </Button>
                  </div>
                </td>
              </tr>
            );
          }
          return (
            <tr>
              <td>{idx + 1}</td>
              <td>{details}</td>
              <td>Rp {price ? price.toLocaleString() : null}</td>
              <td align="center">
                {files?.length ? (
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    style={{
                      width: '25px',
                      height: '25px',
                      color: '#6c757d',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      setPreviewModal({ opened: true, selectedItemId: id })
                    }
                  />
                ) : (
                  'N/A'
                )}
              </td>
              <td>
                <div className="d-flex justify-content-center">
                  <Button
                    color="primary"
                    className="btn text-center"
                    style={{ width: '100px' }}
                    onClick={() =>
                      setUploadModal({ opened: true, selectedItemId: id })
                    }
                  >
                    Upload
                  </Button>
                  <Button
                    className="btn btn-primary text-center ml-2"
                    style={{ width: '100px' }}
                    onClick={() => {
                      setToggle(id);
                      setEditPrice({
                        ...editPrice,
                        display: price.toLocaleString(),
                        value: price,
                      });
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    className="btn btn-danger text-center ml-2"
                    style={{ width: '100px' }}
                    onClick={() => handleDelete(id)}
                  >
                    {isLoadingDelete && idLoadingDelete === id ? (
                      <Spinner size="sm" color="white" />
                    ) : (
                      'Delete'
                    )}
                  </Button>
                </div>
              </td>
            </tr>
          );
        });
      }
    }
    return poItemDetailById.map((val, idx) => {
      const { id, details, price, files } = val;
      if (toggle === id) {
        return (
          <tr key={id}>
            <td>#</td>
            <td>
              <Input
                type="text"
                placeholder="Input Details"
                defaultValue={details}
                onChange={(e) => setEditDetails(e.target.value)}
              />
            </td>
            <td>
              <Input
                type="text"
                placeholder="0"
                value={editPrice.display}
                defaultValue={price ? price.toLocaleString() : null}
                onChange={(e) => editFormatDisplay(e.target.value)}
              />
            </td>
            <td align="center">
              {files?.length ? (
                <FontAwesomeIcon
                  icon={faFileAlt}
                  style={{
                    width: '25px',
                    height: '25px',
                    color: '#6c757d',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    setPreviewModal({ opened: true, selectedItemId: id })
                  }
                />
              ) : (
                'N/A'
              )}
            </td>
            {!readOnly ? (
              <td>
                <div className="d-flex justify-content-center">
                  <Button
                    color="primary"
                    className="btn text-center"
                    style={{ width: '100px' }}
                    onClick={() =>
                      setUploadModal({ opened: true, selectedItemId: id })
                    }
                  >
                    Upload
                  </Button>
                  <Button
                    className="btn btn-primary text-center ml-2"
                    style={{ width: '100px' }}
                    onClick={() => handleSave(id, details, price)}
                  >
                    {isLoadingSave && idLoadingSave === id ? (
                      <Spinner size="sm" color="white" />
                    ) : (
                      'Save'
                    )}
                  </Button>
                  <Button
                    className="btn btn-danger text-center ml-2"
                    style={{ width: '100px' }}
                    onClick={() => setToggle(null)}
                  >
                    Cancel
                  </Button>
                </div>
              </td>
            ) : null}
          </tr>
        );
      }
      return (
        <tr key={id}>
          <td>{idx + 1}</td>
          <td>{details}</td>
          <td>Rp {price ? price.toLocaleString() : null}</td>
          <td align="center">
            {files?.length ? (
              <FontAwesomeIcon
                icon={faFileAlt}
                style={{
                  width: '25px',
                  height: '25px',
                  color: '#6c757d',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  setPreviewModal({ opened: true, selectedItemId: id })
                }
              />
            ) : (
              'N/A'
            )}
          </td>
          {!readOnly ? (
            <td>
              <div className="d-flex justify-content-center">
                <Button
                  color="primary"
                  className="btn text-center"
                  style={{ width: '100px' }}
                  onClick={() =>
                    setUploadModal({ opened: true, selectedItemId: id })
                  }
                >
                  Upload
                </Button>

                <Button
                  className="btn btn-primary text-center ml-2"
                  style={{ width: '100px' }}
                  onClick={() => {
                    setToggle(id);
                    setEditPrice({
                      ...editPrice,
                      display: price.toLocaleString(),
                      value: price,
                    });
                  }}
                >
                  Edit
                </Button>
                <Button
                  className="btn btn-danger text-center ml-2"
                  style={{ width: '100px' }}
                  onClick={() => handleDelete(id)}
                >
                  {isLoadingDelete && idLoadingDelete === id ? (
                    <Spinner size="sm" color="white" />
                  ) : (
                    'Delete'
                  )}
                </Button>
              </div>
            </td>
          ) : null}
        </tr>
      );
    });
  };

  return (
    <>
      <tbody>{renderBody()}</tbody>
      {!readOnly ? (
        <tfoot>
          <tr>
            <td>#</td>
            <td>
              <Input
                type="text"
                placeholder="Input Details"
                value={details}
                name="details"
                invalid={vDetails}
                onChange={handleChange}
              />
            </td>
            <td>
              <Input
                type="text"
                placeholder="0"
                name="price"
                invalid={vPrice}
                value={priceDisplay}
                onChange={(e) => formatDisplay(e.target.value)}
              />
            </td>
            <td align="center">
              {form.files?.length ? (
                <FontAwesomeIcon
                  icon={faFileAlt}
                  style={{
                    width: '25px',
                    height: '25px',
                    color: '#6c757d',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    setPreviewModal({ opened: true, selectedItemId: '' })
                  }
                />
              ) : (
                'N/A'
              )}
            </td>

            <td>
              <div className="d-flex justify-content-center">
                <Button
                  className="btn btn-success text-center ml-2"
                  style={{
                    width: '100px',
                    backgroundColor: 'rgb(50, 178, 128)',
                  }}
                  onClick={handleAdd}
                >
                  {isLoadingAdd ? <Spinner size="sm" color="white" /> : 'Add'}
                </Button>
                <Button
                  className="btn btn-danger ml-2"
                  style={{ width: '100px' }}
                  onClick={handleClear}
                >
                  Clear
                </Button>
              </div>
            </td>
          </tr>
        </tfoot>
      ) : null}
      <ModalUploadPOItemProof
        isOpen={uploadModal.opened}
        handleUploadImage={handleUploadImage}
        toggle={() => setUploadModal({ ...uploadModal, opened: false })}
      />
      <ModalPreviewPOItemProof
        isOpen={previewModal.opened}
        files={
          previewModal.selectedItemId
            ? poItemDetailById.find(
                ({ id }) => id === previewModal.selectedItemId,
              )?.files ||
              poItems.find(({ id }) => id === previewModal.selectedItemId)
                ?.files
            : []
        }
        toggle={() => setPreviewModal({ ...previewModal, opened: false })}
      />
    </>
  );
};

export default TablePOData;
