import React, { useEffect, useState } from 'react';
import {
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const OnlineCourseDetail = () => {
  const auth = useSelector((state) => state.auth);
  const data = useSelector((state) => state.onlineCourse.data);
  const location = useLocation();

  const history = useHistory();
  const { search } = location;
  const params = new URLSearchParams(search);
  const id = params.get('id');

  const [input, setInput] = useState();

  useEffect(() => {
    const onlineCourseDetails = data.filter(
      (onlineCourse) => onlineCourse.id === parseInt(id, 10),
    )[0];
    setInput(onlineCourseDetails);
  }, [data, id]);

  const handleInputCourseOverview = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleInputCourseDetails = (e) => {
    setInput({
      ...input,
      courseDetails: {
        ...input.courseDetails,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleInputCourseDicsount = (e) => {
    setInput({
      ...input,
      onlineCourseDiscount: {
        ...input.onlineCourseDiscount,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        `Apakah anda ingin mengubah data online course : "${input.title}"?`,
      )
    ) {
      //   dispatch(editStudentInfo(input));
    }
  };

  const allowNumbersOnly = (e) => {
    const code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  };

  if (auth.token === '' && auth.loginChecked === true) {
    return (
      <Redirect
        to={{
          pathname: '/',
          from: location.pathname + location.search,
        }}
      />
    );
  }

  return (
    <div className=" mb-4">
      <Card className="p-5">
        <center>
          <h3>DATABASE ONLINE COURSE</h3>
        </center>
        <Form className="mt-2 mb-4" onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between mb-3 table-buttons">
            <div>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                type="button"
              >
                <FontAwesomeIcon
                  className="text-center mr-2"
                  icon={['fas', 'chevron-left']}
                  size="1x"
                />
                Back
              </Button>
            </div>
            <div>
              <Link to={`/admin/online-course/edit/${id}`}>
                <Button
                  type="button"
                  className="mr-2"
                  color="primary"
                  disabled={!(input?.id > 0)}
                >
                  <FontAwesomeIcon
                    className="text-center mr-2"
                    icon={faEdit}
                    size="1x"
                  />
                  Edit
                </Button>
              </Link>
            </div>
          </div>
          <div className="d-flex flex-column">
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <b>OVERVIEW</b>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Code</Label>
                </div>
                <div className="col-10">{input?.code}</div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Title</Label>
                </div>
                <div className="col-10">
                  <input
                    type="text"
                    value={input?.title}
                    name="title"
                    onChange={(e) => handleInputCourseOverview(e)}
                    required
                    disabled
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Sub Header</Label>
                </div>
                <div className="col-10">
                  <input
                    type="text"
                    value={input?.subHeader}
                    name="subHeader"
                    onChange={(e) => handleInputCourseOverview(e)}
                    required
                    disabled
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Harga Normal</Label>
                </div>
                <div className="col-10">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
                    <input
                      type="text"
                      className="form-control"
                      value={input?.normalPrice?.toLocaleString()}
                      name="normalPrice"
                      onChange={(e) => handleInputCourseOverview(e)}
                      required
                      onKeyPress={allowNumbersOnly}
                      disabled
                    />
                  </InputGroup>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Discount</Label>
                </div>
                <div className="col-1">
                  <input
                    type="number"
                    value={input?.onlineCourseDiscount?.percentage}
                    name="percentage"
                    onChange={(e) => handleInputCourseDicsount(e)}
                    required
                    disabled
                    style={{ width: '50%', textAlign: 'right' }}
                  />
                  <Label>%</Label>
                </div>
                <div className="col-3">
                  <Label>Start Date </Label>
                  <input
                    type="date"
                    value={
                      input?.onlineCourseDiscount?.startDate &&
                      moment(input?.onlineCourseDiscount?.startDate).format(
                        'YYYY MMM DD',
                      )
                    }
                    name="startDate"
                    onChange={(e) => handleInputCourseDicsount(e)}
                    required
                    disabled
                    style={{
                      marginLeft: '5px',
                      width: '50%',
                      textAlign: 'left',
                    }}
                  />
                </div>
                <div className="col-3">
                  <Label>End Date</Label>
                  <input
                    type="date"
                    value={
                      input?.onlineCourseDiscount?.endDate &&
                      moment(input?.onlineCourseDiscount?.endDate).format(
                        'YYYY MMM DD',
                      )
                    }
                    name="endDate"
                    onChange={(e) => handleInputCourseDicsount(e)}
                    required
                    disabled
                    style={{
                      marginLeft: '5px',
                      width: '50%',
                      textAlign: 'left',
                    }}
                  />
                </div>
                <div className="col-3">
                  <Label>Member Only</Label>
                  <input
                    type="checkbox"
                    checked={input?.onlineCourseDiscount?.memberOnly}
                    value={input?.onlineCourseDiscount?.memberOnly}
                    name="memberOnly"
                    onChange={(e) => handleInputCourseDicsount(e)}
                    required
                    disabled
                    style={{
                      marginLeft: '5px',
                      width: '50%',
                      textAlign: 'left',
                    }}
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Thumbnail</Label>
                </div>
                <div className="col-10">
                  <img
                    src={process.env.REACT_APP_BASE_URL + input?.thumbnail}
                    alt="thumbnail"
                    height={200}
                    width={300}
                    style={{ borderRadius: '6px' }}
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Trailer</Label>
                </div>
                <div className="col-10">
                  <div
                    style={{
                      position: 'relative',
                      height: 0,
                      paddingBottom: '56.25%',
                      paddingTop: '1px',
                      paddingLeft: '1px',
                      paddingRight: '1px',
                    }}
                  >
                    <iframe
                      src={input?.trailer}
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: 0,
                        // padding: '2px',
                        top: 0,
                        borderRadius: '6px',
                      }}
                      title="Trailer"
                      frameBorder={0}
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <b>DETAILS</b>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Content</Label>
                </div>
                <div className="col-10">
                  <textarea
                    type="text"
                    value={input?.courseDetails?.content}
                    name="content"
                    onChange={(e) => handleInputCourseDetails(e)}
                    required
                    disabled
                    rows="10"
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Syllabus</Label>
                </div>
                <div className="col-10">
                  <textarea
                    type="text"
                    value={input?.courseDetails?.syllabus}
                    name="syllabus"
                    onChange={(e) => handleInputCourseDetails(e)}
                    required
                    disabled
                    rows="10"
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Audience</Label>
                </div>
                <div className="col-10">
                  <textarea
                    type="text"
                    value={input?.courseDetails?.audience}
                    name="audience"
                    onChange={(e) => handleInputCourseDetails(e)}
                    required
                    disabled
                    rows="10"
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="col-12">
              <div className="d-flex flex-row">
                <div className="col-2">
                  <Label>Notes</Label>
                </div>
                <div className="col-10">
                  <textarea
                    type="text"
                    value={input?.courseDetails?.notes}
                    name="notes"
                    onChange={(e) => handleInputCourseDetails(e)}
                    required
                    disabled
                    rows="10"
                  />
                </div>
              </div>
            </FormGroup>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default OnlineCourseDetail;
