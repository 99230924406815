import React from 'react';
import Status from 'components/Transactions/create-transaction/commons/Status';

export default ({ isVerified }) => {
  if (isVerified) {
    return (
      <Status style={{ backgroundColor: '#32b280' }} className="my-1">
        Verified
      </Status>
    );
  }
  return (
    <Status style={{ backgroundColor: '#ef767a' }} className="my-1">
      Unverified
    </Status>
  );
};
