import React, { Component } from 'react';
import {
  Alert,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import axios from 'supports/api';
import { TextForm } from 'components/CustomForms';

// Object creator
import createTransactionItem from 'components/Transactions/create-transaction/modals/itemCreator';

const INITIAL_STATE = {
  services: [],
  categories: [],
  filterCategory(services = this.services || []) {
    const temp = [];
    services.forEach((item) => {
      if (
        parseInt(this.categoryId.value, 10) ===
        parseInt(item.service_categoryId, 10)
      ) {
        temp.push(item);
      }
    });
    return temp;
  },
};

const INITIAL_FILTER = {
  categoryId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Category',
  },
};

const INITIAL_FORM = {
  selectedServices: [],
  service: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Service',
  },
  discount: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    error: 'Input discount',
  },
  description: {
    value: '',
    valid: false,
    invalid: false,
    error: 'Input description',
  },
  notes: {
    value: '',
    valid: false,
    invalid: false,
    error: 'Input notes',
  },
  overridePrice: {
    value: 0,
    displayValue: '0',
  },
};

class AddServiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      ...INITIAL_FILTER,
      ...INITIAL_FORM,
    };
  }

  componentDidMount() {
    axios
      .get(`/admin/service/list`)
      .then(({ data }) => {
        this.setState({ services: data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/admin/service/categories`)
      .then(({ data }) => {
        this.setState({ categories: data.result });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  resetState = () => {
    this.setState({ ...INITIAL_FORM, ...INITIAL_FILTER });
  };

  validateForm = async (form) => {
    let result = 0;
    await Object.keys(form).forEach(async (obj) => {
      if (!form[obj].valid) {
        await this.setState((prevState) => ({
          [obj]: { ...prevState[obj], invalid: true },
        }));
        result += 1;
      }
    });

    if (result > 0) {
      return false;
    }
    return true;
  };

  onAddClick = () => {
    const { service, description } = this.state;
    const validation = this.validateForm({ service, description }); // returns a promise

    validation.then(async (valid) => {
      if (valid) {
        const item = JSON.parse(this.state.service.value);

        const selectedPrice =
          item.price === 0 ? this.state.overridePrice.value : item.price;

        const transactionItem = createTransactionItem({
          id: item.id, // universal
          price: selectedPrice, // universal
          description: this.state.description.value, // services
          notes: this.state.notes.value, // services
          productType: 'service', // universal
        });

        await this.props.addTransactionItem([transactionItem]);
        this.props.toggle();
      } else {
        alert('There are invalid fields');
      }
    });
  };

  handleInput = (state, value) => {
    const valid = true;
    const invalid = false;

    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value, valid, invalid },
    }));
  };

  handleSelect = (state, value) => {
    const checkState = (checkedState) => {
      const { service } = INITIAL_FORM;
      switch (checkedState) {
        case 'categoryId':
          return { service };
        default:
          return {};
      }
    };

    const formReset = checkState(state);
    let valid = false;
    let invalid = true;
    if (value !== 0) {
      valid = true;
      invalid = false;
    }

    this.setState((prevState) => ({
      ...prevState,
      ...formReset,
      [state]: { value, valid, invalid },
    }));
  };

  handleCurrencyInput = (state, value) => {
    let number = parseInt(value.split(',').join(''), 10);
    let displayValue = number.toLocaleString();

    if (value.split(',').join('') === '') {
      number = 0;
      displayValue = '0';
    }

    return this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value: number, displayValue },
    }));
  };

  PriceDetails = ({ item, discount = 0, children }) => {
    const productItem = JSON.parse(item);
    let price = 0;
    if (productItem) {
      price = productItem.price;
    }

    if (productItem.price === 0) {
      return (
        <div>
          <div className="text-gray font-weight-bold mb-2">Base Price</div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">IDR</InputGroupAddon>
            <Input
              type="text"
              value={this.state.overridePrice.displayValue.toLocaleString()}
              id="priceField"
              onChange={(e) =>
                this.handleCurrencyInput('overridePrice', e.target.value)
              }
            />
          </InputGroup>
          {children}
          <div className="text-gray font-weight-bold mb-2">Total Price</div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">IDR</InputGroupAddon>
            <Input
              type="text"
              disabled
              value={this.state.overridePrice.value.toLocaleString()}
            />
          </InputGroup>
        </div>
      );
    }

    const totalPrice = price - discount;
    return (
      <div>
        <div className="text-gray font-weight-bold mb-2">Base Price</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">IDR</InputGroupAddon>
          <Input type="text" disabled value={price.toLocaleString()} />
        </InputGroup>
        {children}
        <div className="text-gray font-weight-bold mb-2">Total Price</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">IDR</InputGroupAddon>
          <Input type="text" disabled value={totalPrice.toLocaleString()} />
        </InputGroup>
      </div>
    );
  };

  RenderCategories = ({ value, valid, invalid, error }) => {
    const arrJSX = this.state.categories.map((item) => {
      return (
        <option value={item.id} key={JSON.stringify(item)}>
          {item.name}
        </option>
      );
    });

    return (
      <FormGroup>
        <div className="text-gray font-weight-bold mb-2">Category</div>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) =>
            this.handleSelect('categoryId', target.value)
          }
        >
          <option value={0} disabled hidden>
            Select Category
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  RenderServices = ({ value, valid, invalid, error }) => {
    const services = this.state.filterCategory();

    const arrJSX = services.map((item) => {
      return (
        <option key={`selectservices${item.id}`} value={JSON.stringify(item)}>
          {item.name}
        </option>
      );
    });

    if (services.length > 0) {
      return (
        <FormGroup>
          <div className="text-gray font-weight-bold mb-2">Pilih Service</div>
          <Input
            type="select"
            valid={valid}
            invalid={invalid}
            value={value}
            onChange={({ target }) =>
              this.handleSelect('service', target.value)
            }
          >
            <option value={0} disabled hidden>
              Pilih Service
            </option>
            {arrJSX}
          </Input>
          <FormFeedback invalid="true">*{error}</FormFeedback>
        </FormGroup>
      );
    }
    return null;
  };

  RenderAlert = () => {
    const { invalid, error } = this.state.service;
    if (invalid) {
      return (
        <Alert color="danger" className="py-1">
          {error}
        </Alert>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        size="lg"
        onClosed={this.resetState}
      >
        <ModalHeader>Add Service Item</ModalHeader>
        <ModalBody>
          <Card className="my-1">
            <CardHeader>Select Service</CardHeader>
            <CardBody>
              <this.RenderAlert />
              <this.RenderCategories {...this.state.categoryId} />
              <this.RenderServices {...this.state.service} />
            </CardBody>
          </Card>
          <Card className="my-1">
            <CardHeader>Added Items</CardHeader>
            <CardBody>
              <this.PriceDetails
                item={this.state.service.value}
                discount={this.state.discount.value}
              >
                {/* <this.DiscountInput {...this.state.discount}/> */}
              </this.PriceDetails>
              <TextForm
                {...this.state.description}
                label="Description"
                onInput={(value) => this.handleInput('description', value)}
              />
              <TextForm
                {...this.state.notes}
                label="Notes *optional"
                onInput={(value) => this.handleInput('notes', value)}
              />
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex-row float-right">
            <Button
              color="success"
              style={{ backgroundColor: '#53b59f' }}
              onClick={this.onAddClick}
            >
              Add
            </Button>
            <Button className="ml-2" color="danger" onClick={this.props.toggle}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddServiceModal;
