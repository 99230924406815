import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  poNumber: '',
  tujuanPengeluaran: '',
  createdBy: '',
  poDateFrom: new Date(),
  poDateTo: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  remarks: '',
  branchId: '',
  status: '',
};

const { actions, reducer } = createSlice({
  name: 'poSearch',
  initialState,
  reducers: {
    setPoSearch: (state, action) => {
      state.poNumber = action.payload;
    },
    setTujuanPengeluaran: (state, action) => {
      state.tujuanPengeluaran = action.payload;
    },
    setCreatedBy: (state, action) => {
      state.createdBy = action.payload;
    },
    setPoDateFrom: (state, action) => {
      state.poDateFrom = action.payload;
    },
    setPoDateTo: (state, action) => {
      state.poDateTo = action.payload;
    },
    setRemarks: (state, action) => {
      state.remarks = action.payload;
    },
    setBranchId: (state, action) => {
      state.branchId = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export default reducer;

export const {
  setPoSearch,
  setTujuanPengeluaran,
  setCreatedBy,
  setPoDateFrom,
  setPoDateTo,
  setRemarks,
  setBranchId,
  setStatus,
} = actions;
