import React from 'react';
import { Button, InputGroup, InputGroupAddon } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const ReferralCodeInput = ({
  onAdd,
  onRemove,
  referralCode,
  isReferralCodeEnabled,
}) => {
  const validationSchema = Yup.object().shape({
    referralCode: Yup.string().required('Wajib diisi'),
  });

  const initialValues = {
    referralCode,
  };

  if (referralCode) {
    return (
      <div className="float-right">
        <span className="font-weight-bold">
          REFERRAL CODE APPLIED: {referralCode}
        </span>{' '}
        <Button color="link" style={{ color: 'red' }} onClick={onRemove}>
          remove
        </Button>
      </div>
    );
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        if (isReferralCodeEnabled) {
          return onAdd(values.referralCode);
        }

        return alert(
          'Tidak dapat menggunakan referral code! \n - Referral code tidak dapat digunakan dengan tipe produk selain program \n - Referral code hanya diperbolehkan untuk pembelian 1 program \n - Referral code hanya diperbolehkan untuk pembelian program dengan 1 quantity',
        );
      }}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <Form>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Referral Code</InputGroupAddon>
            <Field name="referralCode" className="form-control" />
            <InputGroupAddon addonType="append">
              <Button type="submit" color="primary">
                Apply
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      )}
    </Formik>
  );
};

export default ReferralCodeInput;
