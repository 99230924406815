// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentReceipt: '',
  tujuanPenerimaan: '',
  namaPembayar: '',
  data: [],
  approval: [],
  addPayment: [],
  updatePayment: [],
  printPayment: [],
  countListPym: 0,
  countApprovalPym: 0,
  countPrintPayment: 0,
  status: '',
  error: null,
};

const { actions, reducer } = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentReceipt: (state, action) => {
      state.paymentReceipt = action.payload;
    },
    setTujuanPenerimaan: (state, action) => {
      state.tujuanPenerimaan = action.payload;
    },
    setNamaPembayar: (state, action) => {
      state.namaPembayar = action.payload;
    },
    fetchstart: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    fetchListSuccess: (state, action) => {
      state.status = 'success';
      state.countListPym = action.payload.count;
      state.data = action.payload.rows;
    },
    fetchApprovalSuccess: (state, action) => {
      state.status = 'success';
      state.countApprovalPym = action.payload.count;
      state.approval = action.payload.rows;
    },
    addData: (state, action) => {
      state.status = 'success';
      state.addPayment = action.payload;
    },
    updateData: (state, action) => {
      state.status = 'success';
      state.updatePayment = action.payload;
    },
    confirmData: (state, action) => {
      state.status = 'success';
      state.confirmPayment = action.payload;
    },
    fetchFailure: (state, action) => {
      state.status = 'failure';
      state.error = action.payload;
    },
    fetchPrintPaymentsSuccess: (state, action) => {
      state.printPayment = action.payload.rows;
      state.countPrintPayment = action.payload.count;
      state.status = 'success';
    },
  },
});

export default reducer;

export const {
  setPaymentReceipt,
  setTujuanPenerimaan,
  setNamaPembayar,
  fetchstart,
  fetchListSuccess,
  fetchFailure,
  addData,
  updateData,
  fetchApprovalSuccess,
  fetchPrintPaymentsSuccess,
} = actions;

export const fetchListPenerimaan = (page) => async (dispatch) => {
  dispatch(fetchstart());
  try {
    const { data } = await axios.post(`/payment/list-penerimaan`, {
      page,
    });
    dispatch(fetchListSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const filterListPenerimaan = (
  page,
  paymentReceipt,
  tujuanPenerimaan,
) => async (dispatch) => {
  dispatch(fetchstart());
  try {
    const { data } = await axios.post(`/payment/filter-list-penerimaan`, {
      page,
      paymentReceipt,
      tujuanPenerimaan,
    });
    dispatch(fetchListSuccess(data.data));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const filterListByPym = (page, paymentReceipt) => async (dispatch) => {
  dispatch(fetchstart());
  try {
    const { data } = await axios.post(`/payment/filter-list-by-pym`, {
      page,
      paymentReceipt,
    });
    dispatch(fetchListSuccess(data.data));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const filterListByTujPenerimaan = (page, tujuanPenerimaan) => async (
  dispatch,
) => {
  dispatch(fetchstart());
  try {
    const { data } = await axios.post(`/payment/filter-list-by-mastertujuan`, {
      page,
      tujuanPenerimaan,
    });
    dispatch(fetchListSuccess(data.data));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const fetchApprovalPenerimaan = (page) => async (dispatch) => {
  dispatch(fetchstart());
  try {
    const { data } = await axios.post(`/payment/approval`, {
      page,
    });
    dispatch(fetchApprovalSuccess(data.data));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const filterApprovalPenerimaan = (
  page,
  paymentReceipt,
  tujuanPenerimaan,
) => async (dispatch) => {
  dispatch(fetchstart());
  try {
    const { data } = await axios.post(`/payment/filter-approval-penerimaan`, {
      page,
      paymentReceipt,
      tujuanPenerimaan,
    });
    dispatch(fetchApprovalSuccess(data.data));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const filterApprovalByPym = (page, paymentReceipt) => async (
  dispatch,
) => {
  dispatch(fetchstart());
  try {
    const { data } = await axios.post(`/payment/filter-approval-by-pym`, {
      page,
      paymentReceipt,
    });
    dispatch(fetchApprovalSuccess(data.data));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const filterApprovalByTujPenerimaan = (page, tujuanPenerimaan) => async (
  dispatch,
) => {
  dispatch(fetchstart());
  try {
    const { data } = await axios.post(
      `/payment/filter-approval-by-mastertujuan`,
      {
        page,
        tujuanPenerimaan,
      },
    );
    dispatch(fetchApprovalSuccess(data.data));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const addToList = (form) => async (dispatch) => {
  dispatch(fetchstart());
  try {
    const { data } = await axios.post(`/payment/add-to-payment`, form);
    dispatch(addData(data.result));
    alert('Data berhasil disimpan');
  } catch (err) {
    dispatch(fetchFailure(err));
    alert(`Terjadi kesalahan system ${err}`);
  }
};

export const editToList = (id, form) => async (dispatch) => {
  dispatch(fetchstart());
  try {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const { data } = await axios.put(
      `/payment/update-payment/${id}`,
      form,
      options,
    );
    dispatch(updateData(data.result));
    alert('Edit Success');
  } catch (err) {
    dispatch(fetchFailure(err));
    alert(`Terjadi kesalahan system ${err}`);
  }
};

export const fetchPrintPayments = (
  page,
  isPrinted,
  paymentReceipt,
  tujuanPenerimaan,
  namaPembayar,
) => async (dispatch) => {
  dispatch(fetchstart());
  try {
    const { data } = await axios.post(`/payment/get-payment-to-print`, {
      page,
      isPrinted,
      paymentReceipt,
      tujuanPenerimaan,
      namaPembayar,
    });
    dispatch(fetchPrintPaymentsSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
    alert(`Terjadi kesalahan system ${err}`);
  }
};
