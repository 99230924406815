import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import axios from 'supports/api';
import { useDropzone } from 'react-dropzone';

const validate = ({ title, materialFile }) => {
  const errors = {};

  if (!title) {
    errors.title = 'Required';
  }
  if (!materialFile) {
    errors.materialFile = 'Required';
  }

  return errors;
};

const LivestreamMaterialModal = ({
  toggle,
  isModalOpen,
  programLivestreamSessionId,
  refresh,
}) => {
  const [loading, setLoading] = useState(false);

  const createLivestreamMaterial = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('materialFile', values.materialFile);

      await axios.post(
        `/v2/program-livestream/session/${programLivestreamSessionId}/material`,
        formData,
      );
      setLoading(false);
      alert('Livestream Session Berhasil Dibuat');
    } catch (err) {
      alert(`Terjadi kesalahan pada system. ${err}`);
      console.log(err);
      setLoading(false);
    }
  };

  const DropzoneMaterial = ({ setFieldValue }) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      accept: '.pdf, .docx, .csv, .rar, .zip, .7z, .txt',
      maxFiles: 1,
      onDrop: (files) => {
        setFieldValue('materialFile', files[0]);
      },
    });

    const files = acceptedFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    return (
      <section>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag & drop file, or click to select file</p>
        </div>
        <aside>
          Nama File:
          <ul>{files}</ul>
        </aside>
      </section>
    );
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Upload Livestream Material</ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <Formik
            validate={validate}
            initialValues={{ title: '', materialFile: null }}
            onSubmit={async (values) => {
              await createLivestreamMaterial(values);
              await refresh();
              toggle();
            }}
            enableReinitialize
          >
            {({ touched, errors, setFieldValue }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Livestream Material Title:
                      </div>
                      <Field id="title" name="title">
                        {({ field }) => (
                          <div>
                            <input
                              className="form-control"
                              placeholder="Judul Material JC Livestream"
                              type="text"
                              {...field}
                            />
                          </div>
                        )}
                      </Field>
                      {touched.title && errors.title && (
                        <div>{errors.title}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        File Materi:
                      </div>
                      <DropzoneMaterial setFieldValue={setFieldValue} />
                      {touched.materialFile && errors.materialFile && (
                        <div>{errors.materialFile}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3 d-flex justify-content-end">
                      <Button
                        disabled={loading}
                        className="mr-2"
                        color="success"
                        type="submit"
                      >
                        {loading && (
                          <div
                            className="spinner-border spinner-border-sm mr-2 text-center"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                        Upload
                      </Button>
                      <Button type="button" color="danger" onClick={toggle}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LivestreamMaterialModal;
