import cryptoJs from 'crypto-js';

// ----------------------------encrypt----------------------------------
// encrypt helps decipher a plain text to unreadable text
// encrypt allowed sending a plain text to backend in much safer ways

// eslint-disable-next-line import/prefer-default-export
export const encryptV2 = ({ key, ivKey, data, isSameEncryptionKey }) => {
  let encryptKey = key;
  let options = {};

  if (isSameEncryptionKey) {
    encryptKey = cryptoJs.enc.Utf8.parse(key);

    const iv = cryptoJs.enc.Utf8.parse(ivKey);

    options = {
      iv,
      mode: cryptoJs.mode.CTR,
      padding: cryptoJs.pad.Pkcs7,
    };
  }

  const encryptedJs = cryptoJs.AES.encrypt(
    data,
    encryptKey,
    options,
  ).toString();

  return encryptedJs;
};
