import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody, CustomInput, Button } from 'reactstrap';
import { getUserData } from 'redux/modules/trxUser';
import axios from 'supports/api';

const API_URL_1 = process.env.REACT_APP_BASE_URL;

export default ({ isOpen, toggle }) => {
  const [fileURL, setFileURL] = useState('');
  const [KTPFile, setKTPFile] = useState(null);
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.trxUser);
  const { identityCardImg } = userData;

  const handleFile = (file) => {
    if (file) {
      setKTPFile(file);
      setFileURL(URL.createObjectURL(file));
    }
  };

  const resetFile = () => {
    document.getElementById('KTP').value = '';
    setKTPFile(null);
  };

  const saveFile = () => {
    const formData = new FormData();
    formData.append('identityCardImg', KTPFile);
    axios
      .put(`/admin/user/documents/${userData.id}`, formData)
      .then(() => {
        dispatch(getUserData(userData.email));
        resetFile();
        alert('Upload berhasil');
        toggle();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  useEffect(() => {
    if (identityCardImg) {
      setFileURL(API_URL_1 + identityCardImg);
    }
  }, [identityCardImg]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalBody>
        <CustomInput
          type="file"
          label={
            identityCardImg
              ? 'Pilih photo baru jika ingin reupload'
              : 'Belum ada photo KTP, silakan upload'
          }
          id="KTP"
          onChange={(e) => handleFile(e.target?.files[0])}
        />

        {fileURL && (
          <div
            style={{
              border: '1px dashed black',
              width: '100%',
            }}
          >
            <img
              src={fileURL}
              alt="ktp"
              style={{
                width: '100%',
                maxHeight: '500px',
                objectFit: 'contain',
              }}
            />
          </div>
        )}
        {KTPFile && (
          <Button onClick={saveFile} className="w-100 mt-2" type="primary">
            Upload
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
};
