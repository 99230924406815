import React, { useEffect, useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import axios from 'supports/api';

const MentoringModal = (props) => {
  const {
    RenderOptionMentor,
    isOpen,
    setModalCreate,
    setModalBulkUpdate,
    programId,
    attendeeEmail,
    attendeeId,
    RenderDay,
    fetchListSession,
    type,
  } = props;

  const [inputData, setInputData] = useState({
    date: '',
    time: '',
    meetingId: '',
    meetingPassword: '',
    programMentorId: '',
    day: '',
    quantity: '',
  });
  const [btnDisable, setBtnDisable] = useState(true);

  useEffect(() => {
    const {
      date,
      time,
      meetingId,
      meetingPassword,
      programMentorId,
    } = inputData;
    if (date && time && meetingId && meetingPassword && programMentorId) {
      setBtnDisable(false);
    }
  }, [inputData]);

  const inputDate = (dateInput) => {
    setInputData({ ...inputData, date: dateInput.target.value });
  };
  const inputTime = (timeInput) => {
    setInputData({ ...inputData, time: timeInput.target.value });
  };
  const inputMeetingId = (idInput) => {
    setInputData({
      ...inputData,
      meetingId: idInput.target.value.replace(/[^0-9]/g, ''),
    });
  };
  const inputMeetingPassword = (passwordInput) => {
    setInputData({ ...inputData, meetingPassword: passwordInput.target.value });
  };
  const inputProgramMentorId = (programMentorIdInput) => {
    setInputData({
      ...inputData,
      programMentorId: programMentorIdInput.target.value,
    });
  };
  const inputDay = (dayInput) => {
    setInputData({
      ...inputData,
      day: dayInput.target.value,
    });
  };
  const inputTotalSession = (totalSessionInput) => {
    setInputData({
      ...inputData,
      quantity: totalSessionInput.target.value,
    });
  };

  const onSubmit = async () => {
    try {
      const {
        date,
        time,
        meetingId,
        meetingPassword,
        programMentorId,
        day,
        quantity,
      } = inputData;

      const schedule = `${date} ${time}:00`;
      let result;
      if (type === 'create') {
        result = await axios.post(`/v2/program-mentoring-session`, {
          programId,
          email: attendeeEmail,
          attendeeId,
          meetingId,
          meetingPassword,
          programMentorId,
          schedule,
          day: day - 1,
          quantity,
        });
      } else {
        result = await axios.put(
          `/v2/program-mentoring-session/all?attendeeId=${attendeeId}`,
          {
            meetingId,
            meetingPassword,
            programMentorId,
            programId,
            schedule,
            day: day - 1,
          },
        );
      }
      if (result.data.status === 'SUCCESS') {
        fetchListSession();
        if (type === 'create') setModalCreate(false);
        else setModalBulkUpdate(false);
        setInputData({
          date: '',
          time: '',
          meetingId: '',
          meetingPassword: '',
          programMentorId: '',
          day: '',
          quantity: '',
        });
        setBtnDisable(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() =>
        type === 'create' ? setModalCreate(false) : setModalBulkUpdate(false)
      }
    >
      <ModalHeader
        toggle={() =>
          type === 'create' ? setModalCreate(false) : setModalBulkUpdate(false)
        }
      >
        {type === 'create'
          ? 'Create Mentoring Sessions'
          : 'Edit Mentoring Sessions'}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="exampleEmail">Meetingroom ID </Label>
            <Input
              type="text"
              placeholder="Room ID"
              onChange={inputMeetingId}
              value={inputData.meetingId}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Meetingroom Password </Label>
            <Input
              type="text"
              placeholder="Room Password"
              onChange={inputMeetingPassword}
            />
          </FormGroup>
          {type === 'create' && (
            <FormGroup>
              <Label for="exampleEmail">Total Mentoring Session </Label>
              <Input
                type="number"
                placeholder="Total"
                onChange={inputTotalSession}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label for="exampleSelect">Day</Label>
            <Input
              type="select"
              name="select"
              onChange={inputDay}
              value={inputData.day}
            >
              <option value="0" hidden>
                Pilih hari
              </option>
              <RenderDay />
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="exampleSelect">Mentor</Label>
            <Input
              type="select"
              name="select"
              onChange={inputProgramMentorId}
              value={inputData.programMentorId}
            >
              <option value="0" hidden>
                Pilih mentor
              </option>
              <RenderOptionMentor />
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="exampleDate">Start Date</Label>
            <Input
              type="date"
              name="date"
              id="exampleDate"
              placeholder="date placeholder"
              onChange={inputDate}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleTime">Time</Label>
            <Input
              type="time"
              name="time"
              id="exampleTime"
              placeholder="time placeholder"
              onChange={inputTime}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color={btnDisable ? 'secondary' : 'primary'}
          onClick={onSubmit}
          disabled={btnDisable}
        >
          Submit
        </Button>{' '}
        <Button
          className="ml-2"
          color="danger"
          onClick={() =>
            type === 'create'
              ? setModalCreate(false)
              : setModalBulkUpdate(false)
          }
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MentoringModal;
