import React, { Component } from 'react';
import axios from 'supports/api';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  Modal,
} from 'reactstrap';
import moment from 'moment';
// import { checkForm } from 'supports/helpers/permissions.js'
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { API_URL_1 } from '../../supports/api-url/apiurl';
import ScheduleCard from './ScheduleCard';
import ScheduleForm from './ScheduleForm';
import ProgramHeaderForm from './ProgramHeaderForm';

const CustomTab = ({ tabId, activeTab, onClick, title }) => {
  return (
    <NavItem>
      <NavLink
        active={tabId === activeTab}
        onClick={() => onClick(tabId)}
        className="rounded-0"
      >
        {title}
      </NavLink>
    </NavItem>
  );
};

const INITIAL_STATE = {
  programHeader: {},
  programs: [],
  activeTab: 'Live',
  addModal: false,
  editHeaderModal: false,
};

const INITIAL_METHODS = {
  filterByTab() {
    const filteredArr = [];

    this.programs.forEach((item) => {
      if (!item.isLive && this.activeTab === 'Not Live') {
        filteredArr.push(item);
      } else if (
        item.isLive &&
        this.activeTab === 'Live' &&
        moment(item.startDate).add(1, 'days') > moment()
      ) {
        filteredArr.push(item);
      } else if (
        item.isLive &&
        this.activeTab === 'Past' &&
        moment(item.startDate).add(1, 'days') < moment()
      ) {
        filteredArr.push(item);
      }
    });
    return filteredArr;
  },
};

class ProgramDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      ...INITIAL_METHODS,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { search } = this.props.location;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    axios
      .get(`/admin/program/header/${id}`)
      .then(({ data }) => {
        if (!data.result) {
          alert('Invalid id');
          this.props.history.push('');
        }
        this.setState((prevState) => ({
          ...prevState,
          programHeader: data.result ? data.result : {},
          programs: data.result ? data.result.programs : [],
        }));
      })
      .catch((err) => {
        // alert(err.response.data.error)
        console.log(err.message);
      });
  };

  onNavClick = (tabId) => {
    this.setState((prevState) => ({ ...prevState, activeTab: tabId }));
  };

  onEditProgramHeader = () => {
    this.setState((prevState) => ({
      ...prevState,
      editHeaderModal: !prevState.editHeaderModal,
    }));
  };

  ProgramDetailsCard = ({ image, name, category }) => {
    const { search } = this.props.location;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    return (
      <Card className="my-1" style={{ height: '220px' }}>
        <CardHeader className="font-weight-bold">
          Program Details{' '}
          <Button
            className="float-right"
            size="sm"
            onClick={this.onEditProgramHeader}
          >
            Edit
          </Button>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-12 font-sm-10 font-md-12">
              <div className="d-inline-flex flex-row flex-sm-column flex-md-row">
                <div className="d-flex flex-row py-1">
                  <img
                    src={API_URL_1 + image}
                    alt={image || 'noimage'}
                    style={{ maxHeight: 100 }}
                    className="pr-3"
                  />
                </div>
                <div className="d-flex flex-column flex-sm-column flex-md-row py-1">
                  <div className="pr-5">
                    <div className="font-weight-bold text-gray">Category</div>
                    <div className="pb-1">{category}</div>
                    <div className="font-weight-bold text-gray">Name</div>
                    <div className="pb-1">{name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        <Modal
          isOpen={this.state.editHeaderModal}
          toggle={this.onEditProgramHeader}
          size="lg"
        >
          <ProgramHeaderForm
            edit
            programHeaderId={id}
            toggle={this.onEditProgramHeader}
            refresh={this.getData}
          />
        </Modal>
      </Card>
    );
  };

  ProgramDescriptionCard = ({ description }) => {
    return (
      <Card className="my-1" style={{ height: '220px' }}>
        <CardHeader className="font-weight-bold">Description</CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-12 font-sm-10 font-md-12">{description}</div>
          </div>
        </CardBody>
      </Card>
    );
  };

  RenderDate = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      if (moment(startDate).isSame(endDate)) {
        return `${moment(startDate).format('DD MMM YYYY')}`;
      }
      return `${moment(startDate).format('DD MMM')} - ${moment(endDate).format(
        'DD MMM YYYY',
      )}`;
    }
    return 'N/A';
  };

  onAddClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      addModal: !prevState.addModal,
    }));
  };

  RenderSchedules = () => {
    const arrJSX = [];

    this.state.filterByTab().forEach((item) => {
      arrJSX.push(
        <ScheduleCard
          {...item}
          refresh={this.getData}
          key={JSON.stringify(item)}
          programCategoryId={this.state.programHeader.categoryId}
          programCategoryType={this.state.programHeader.categoryType}
          isMultipleCurrency={this.state.programHeader.isMultipleCurrency}
        />,
      );
    });
    return (
      <Card>
        <CardHeader className="font-weight-bold">
          <div className="d-flex flex-row justify-content-between">
            <div className="my-auto">Schedules</div>
            <div>
              <Button color="success" onClick={this.onAddClick} size="sm">
                +Add
              </Button>
            </div>
          </div>
        </CardHeader>
        <div>
          <Nav pills fill justified className="border-left border-right">
            <CustomTab
              tabId="Not Live"
              activeTab={this.state.activeTab}
              title="Not Live"
              onClick={this.onNavClick}
            />
            <CustomTab
              tabId="Live"
              activeTab={this.state.activeTab}
              title="Live"
              onClick={this.onNavClick}
            />
            <CustomTab
              tabId="Past"
              activeTab={this.state.activeTab}
              title="Past"
              onClick={this.onNavClick}
            />
          </Nav>
          <div className="border-left border-bottom border-right border-top">
            {arrJSX}
          </div>
        </div>
      </Card>
    );
  };

  render() {
    const backlink = `programs`;
    // var render = checkForm(this.props.auth.access, "Payment Confirmation")
    // if(render){
    return (
      <div className="white-box py-3 container">
        <div className="row">
          <div className="col-12">
            <div>
              <div className="line-green mb-4" />
              <div className="general-title text-gray">
                Program Details{' '}
                <Link to={backlink}>
                  <Button className="float-right">Back</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-3">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm">
                <this.ProgramDetailsCard {...this.state.programHeader} />
              </div>
              <div className="col-12 col-sm">
                <this.ProgramDescriptionCard {...this.state.programHeader} />
              </div>
            </div>
            <this.RenderSchedules />
          </div>
        </div>
        <Modal isOpen={this.state.addModal} size="lg" toggle={this.onAddClick}>
          <ScheduleForm
            programCategoryId={this.state.programHeader.categoryId}
            programCategoryType={this.state.programHeader.categoryType}
            toggle={this.onAddClick}
            refresh={this.getData}
            isMultipleCurrency={this.state.programHeader.isMultipleCurrency}
          />
        </Modal>
      </div>
    );
    // }
    // else return <Redirect to={"/"}/>
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return { auth };
};

export default withRouter(connect(mapStateToProps, {})(ProgramDetails));
