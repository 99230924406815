import React, { useState } from 'react';
// import { useHistory, useLocation } from 'react-router';
import { Button, Card, CardBody } from 'reactstrap';
// import qs from 'query-string';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudentBirthday } from 'redux/modules/studentBirthday';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import useStatusFilter from './statusFilterHook';
import StudentBirthdayTable from './StudentBirthdayTable';

const date = new Date();

const StudentBirthdayPage = () => {
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [endDate, setEndDate] = useState(date);
  const [studentStatus, setStudentStatus] = useState('Aktif');

  const dispatch = useDispatch();

  const { data, status } = useSelector((state) => state.studentBirthday);
  const filteredData = useStatusFilter({ data, studentStatus });

  //   const location = useLocation();
  //   const history = useHistory();
  //   const queryParam = qs.parse(location.search);
  //   const { page } = queryParam;

  //   const handleToPageOne = () => {
  //     history.push(
  //       `?${qs.stringify({
  //         ...queryParam,
  //         page: 1,
  //       })}`,
  //     );
  //   };

  const handleFetch = () => {
    dispatch(fetchStudentBirthday({ startDate, endDate }));
  };

  return (
    <div
      className="white-box container-fluid py-2"
      style={{ minHeight: '100vh' }}
    >
      <div className="row px-1 py-5">
        <div className="col-12  offset-sm-1 col-sm-10">
          <div className="line-green mb-4" />
          <div className="general-title text-gray mb-5">
            Student Birthday List
          </div>
          <Card className="mb-1" style={{ backgroundColor: '#f8f8f8' }}>
            <CardBody>
              <div className="col-12 d-flex flex-row flex-wrap">
                <div className="col-4">
                  <div>Start Date</div>
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={(pickedDate) => setStartDate(pickedDate)}
                    dateFormat="d/MMMM"
                    showYearPicker={false}
                  />
                </div>
                <div className="col-4">
                  <div>End Date</div>
                  <DatePicker
                    className="form-control"
                    selected={endDate}
                    onChange={(pickedDate) => setEndDate(pickedDate)}
                    dateFormat="d/MMMM"
                    showYearPicker={false}
                  />
                </div>
                <div className="col-4">
                  <div>Student Status</div>
                  <select
                    value={studentStatus}
                    onChange={(e) => {
                      setStudentStatus(e.target.value);
                      //   handleToPageOne();
                    }}
                    className="form-control"
                  >
                    <option value="">Semua Status</option>
                    <option valye="Aktif">Aktif</option>
                    <option valye="Tidak Aktif / Lulus">
                      Tidak Aktif / Lulus
                    </option>
                  </select>
                </div>
                <div className="col-12 mt-2 d-flex justify-content-end">
                  <Button onClick={handleFetch}>
                    <span className="mr-2">Search</span>
                    <FontAwesomeIcon
                      className="mx-auto text-center"
                      icon={['fas', 'search']}
                    />
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
          {status === 'loading' ? (
            <div className="d-flex justify-content-center">
              <Loader type="ThreeDots" color="black" />
            </div>
          ) : null}
          {data.length && status !== 'loading' ? (
            <StudentBirthdayTable data={filteredData} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StudentBirthdayPage;
