import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LivestreamListPage from './livestream-list';
import LivestreamMaterialListPage from './livestream-material-list';
import LivestreamModuleListPage from './livestream-module-list';
import LivestreamSessionListPage from './livestream-session-list';

const LivestreamRouter = (props) => {
  const { match } = props;
  const { path } = match;
  return (
    <Switch>
      <Route exact path={`${path}`} component={LivestreamListPage} />
      <Route
        exact
        path={`${path}/:programLivestreamId/module`}
        component={LivestreamModuleListPage}
      />
      <Route
        exact
        path={`${path}/:programLivestreamId/module/:programLivestreamModuleId/session`}
        component={LivestreamSessionListPage}
      />
      <Route
        exact
        path={`${path}/:programLivestreamId/module/:programLivestreamModuleId/session/:programLivestreamSessionId/material`}
        component={LivestreamMaterialListPage}
      />
    </Switch>
  );
};

export default LivestreamRouter;
