import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPO, fetchPOList } from 'redux/modules/purchaseOrder';
import {
  setPoSearch,
  setTujuanPengeluaran,
  setCreatedBy,
  setPoDateTo,
  setPoDateFrom,
  setRemarks,
  setBranchId,
  setStatus,
} from 'redux/modules/poSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Collapse,
  Input,
  Button,
} from 'reactstrap';
import statuses from '../static/statuses';

const Filter = ({ active, page, setSearch, search }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const gTujuanPengeluaran = useSelector(
    ({ purchaseOrder }) => purchaseOrder.tujuanPengeluaran,
  );

  const searchPoNumber = useSelector((state) => state.poSearch.poNumber);
  const searchTujPengeluaran = useSelector(
    (state) => state.poSearch.tujuanPengeluaran,
  );
  const searchCreatedBy = useSelector((state) => state.poSearch.createdBy);
  const searchPoDateTo = useSelector((state) => state.poSearch.poDateTo);
  const searchPoDateFrom = useSelector((state) => state.poSearch.poDateFrom);
  const searchRemarks = useSelector((state) => state.poSearch.remarks);

  const branches = useSelector(({ purchaseOrder }) => purchaseOrder.branches);

  const toggleCollapse = () => setIsOpen(!isOpen);

  const renderTujuanPengeluaran = () => {
    return gTujuanPengeluaran.map((val) => {
      return (
        <option key={val.id} value={val.id}>
          {val.tujuanPengeluaran}
        </option>
      );
    });
  };

  const handleSelect = (e) => {
    setSearch({
      ...search,
      tujuanPengeluaran: e.currentTarget.value,
    });
    // dispatch(setTujuanPengeluaran(e.currentTarget.value));
  };

  const handleChangePo = (e) => {
    setSearch({
      ...search,
      poNumber: e.currentTarget.value,
    });
    // dispatch(setPoSearch(e.target.value));
  };

  const handleCreatedBy = (e) => {
    setSearch({
      ...search,
      createdBy: e.currentTarget.value,
    });
    // dispatch(setCreatedBy(e.target.value));
  };

  const handleRemarks = (e) => {
    setSearch({ ...search, remarks: e.currentTarget.value });
  };

  const handleDateFrom = (selectedDate) => {
    setSearch({ ...search, poDateFrom: selectedDate });
  };

  const handleDateTo = (selectedDate) => {
    setSearch({ ...search, poDateTo: selectedDate });
  };

  const handleClick = () => {
    dispatch(setPoSearch(search.poNumber));
    dispatch(setTujuanPengeluaran(search.tujuanPengeluaran));
    dispatch(setCreatedBy(search.createdBy));
    dispatch(setPoDateTo(search.poDateTo));
    dispatch(setPoDateFrom(search.poDateFrom));
    dispatch(setRemarks(search.remarks));
    dispatch(setBranchId(search.branchId));
    dispatch(setStatus(search.status));

    let isProcess = false;
    let isCair = false;
    let isApproved = false;
    let booleanGlJournal = false;
    if (active === 'PO List') {
      isProcess = false;
      isCair = false;
      isApproved = false;
      booleanGlJournal = false;

      dispatch(
        fetchPOList(
          page,
          isProcess,
          isCair,
          isApproved,
          booleanGlJournal,
          searchPoNumber,
          search.tujuanPengeluaran,
          searchCreatedBy,
          search.branchId,
          search.status,
        ),
      );
    } else if (active === 'PO Approval') {
      isProcess = true;
      isCair = false;
      isApproved = false;
      booleanGlJournal = false;

      dispatch(
        fetchPO(
          page,
          isProcess,
          isCair,
          isApproved,
          booleanGlJournal,
          searchPoNumber,
          searchTujPengeluaran,
          searchCreatedBy,
          searchRemarks,
          searchPoDateFrom,
          searchPoDateTo,
        ),
      );
    } else if (active === 'PO Pencairan') {
      isProcess = true;
      isCair = false;
      isApproved = true;
      booleanGlJournal = false;

      dispatch(
        fetchPO(
          page,
          isProcess,
          isCair,
          isApproved,
          booleanGlJournal,
          searchPoNumber,
          searchTujPengeluaran,
          searchCreatedBy,
          searchRemarks,
          searchPoDateFrom,
          searchPoDateTo,
        ),
      );
    } else if (active === 'PO Finalization') {
      isProcess = true;
      isCair = true;
      isApproved = true;
      booleanGlJournal = false;

      dispatch(
        fetchPO(
          page,
          isProcess,
          isCair,
          isApproved,
          booleanGlJournal,
          searchPoNumber,
          searchTujPengeluaran,
          searchCreatedBy,
        ),
      );
    } else if (active === 'Edit Mutation Date') {
      isProcess = true;
      isCair = true;
      isApproved = true;
      booleanGlJournal = true;

      dispatch(
        fetchPO(
          page,
          isProcess,
          isCair,
          isApproved,
          booleanGlJournal,
          searchPoNumber,
          searchTujPengeluaran,
          searchCreatedBy,
        ),
      );
    }
  };

  return (
    <>
      <div className={isOpen ? 'dropup' : null}>
        <button
          type="button"
          className="btn btn-secondary mt-2 dropdown-toggle btn-collapse-pym"
          onClick={toggleCollapse}
        >
          Filter
        </button>
      </div>
      <Collapse isOpen={isOpen}>
        <Card className="mt-1">
          <CardBody>
            <Form>
              <Row form>
                {(active === 'PO Approval' || active === 'PO Pencairan') && (
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Date From</Label>
                      <Col sm={7}>
                        <DatePicker
                          dateFormat="dd/MM/yyy"
                          className="form-control date-picker-pym"
                          selected={search.poDateFrom}
                          onChange={handleDateFrom}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                )}

                <Col sm={6} md={6}>
                  <FormGroup row>
                    <Label sm={4}>PO Number</Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        onChange={(e) => handleChangePo(e)}
                        value={search.poNumber}
                      />
                    </Col>
                  </FormGroup>
                </Col>

                {(active === 'PO Approval' || active === 'PO Pencairan') && (
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Date To</Label>
                      <Col sm={7}>
                        <DatePicker
                          dateFormat="dd/MM/yyy"
                          className="form-control date-picker-pym"
                          selected={search.poDateTo}
                          onChange={handleDateTo}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                )}

                {active !== 'PO List' ? (
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>User</Label>
                      <Col sm={7}>
                        <Input
                          type="text"
                          onChange={(e) => handleCreatedBy(e)}
                          value={search.createdBy}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                ) : (
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Branch</Label>
                      <Col sm={7}>
                        <Input
                          type="select"
                          onChange={(e) =>
                            setSearch({
                              ...search,
                              branchId: e.target.value,
                            })
                          }
                          value={search.branchId}
                        >
                          <option value="">All</option>
                          {branches.map((branch) => (
                            <option key={branch.id} value={branch.id}>
                              {branch.name}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row form>
                <Col sm={6} md={6}>
                  <FormGroup row>
                    <Label sm={4}>Tujuan Pengeluaran</Label>
                    <Col sm={7}>
                      <Input
                        type="select"
                        onChange={(e) => handleSelect(e)}
                        value={search.tujuanPengeluaran}
                      >
                        <option value="">All</option>
                        {renderTujuanPengeluaran()}
                      </Input>
                    </Col>
                  </FormGroup>
                </Col>

                {active === 'PO Approval' || active === 'PO Pencairan' ? (
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Remarks</Label>
                      <Col sm={7}>
                        <Input
                          type="text"
                          onChange={(e) => handleRemarks(e)}
                          value={search.remarks}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                ) : null}

                {active === 'PO List' && (
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Status</Label>
                      <Col sm={7}>
                        <Input
                          type="select"
                          onChange={(e) =>
                            setSearch({
                              ...search,
                              status: e.target.value,
                            })
                          }
                          value={search.status}
                        >
                          {statuses.map((status) => (
                            <option value={status.value}>{status.label}</option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </Form>
          </CardBody>
          <div className="d-flex flex-row-reverse">
            <Button
              color="success"
              className="mt-0"
              style={{
                position: 'absolute',
                backgroundColor: 'rgb(50, 178, 128)',
                width: '20%',
              }}
              onClick={handleClick}
            >
              Search <FontAwesomeIcon icon={faSearch} className="ml-2" />
            </Button>
          </div>
        </Card>
      </Collapse>
    </>
  );
};

export default Filter;
