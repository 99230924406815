import React from 'react';
import moment from 'moment';

import { Card, CardBody, Table } from 'reactstrap';

moment.locale('id');

const StudentBirthdayTable = ({ data }) => {
  const tableBody = data.map((student, index) => {
    return (
      <tr key={student.id}>
        <td>{index + 1}</td>
        <td>{student.nama}</td>
        <td>{moment(student.tanggalLahir).format('DD/MMMM')}</td>
        <td>{student.program_code}</td>
      </tr>
    );
  });

  return (
    <Card className="mb-2 d-flex">
      <CardBody>
        <Table bordered striped size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Nama</th>
              <th>Tanggal Ulang Tahun</th>
              <th>Batch</th>
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default StudentBirthdayTable;
