// import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useParamsSorter = (transactions) => {
  const searchParams = useSelector((state) => state.trxSearch.searchParams);
  const filteredResults = [];

  transactions.forEach((transaction) => {
    const { user, invoiceNumber, orderConfirmationNumber } = transaction;
    const { firstName, lastName, email } = user;

    const fullname = `${firstName} ${lastName}`.toLocaleLowerCase();
    const emailaddress = email.toLocaleLowerCase();
    const invoice = invoiceNumber?.toLocaleLowerCase() || '';
    const search = searchParams.toLocaleLowerCase();
    const order = orderConfirmationNumber?.toLocaleLowerCase() || '';

    if (
      fullname.indexOf(search) !== -1 ||
      emailaddress.indexOf(search) !== -1 ||
      invoice.indexOf(search) !== -1 ||
      order.indexOf(search) !== -1
    ) {
      filteredResults.push(transaction);
    }
  });

  return filteredResults;
};

export const useStatusSorter = (transactions) => {
  const searchStatuses = useSelector((state) => state.trxSearch.searchStatuses);
  const selectedStatuses = searchStatuses
    .filter((item) => item.checked)
    .map((obj) => parseInt(obj.id, 10));

  if (selectedStatuses.length === 0) {
    return transactions;
  }

  const filteredResults = [];
  transactions.forEach((item) => {
    if (selectedStatuses.includes(parseInt(item.transaction_statusId, 10))) {
      filteredResults.push(item);
    }
  });

  return filteredResults;
};

export const useCategorySorter = (transactions) => {
  const searchCategories = useSelector(
    (state) => state.trxSearch.searchCategories,
  );
  const selectedCategories = searchCategories
    .filter((item) => item.checked)
    .map((obj) => parseInt(obj.id, 10));

  if (selectedCategories.length === 0) {
    return transactions;
  }

  const filteredResults = [];
  transactions.forEach((item) => {
    let programSalesCheck = false;
    item.programSales.forEach((programSale) => {
      if (selectedCategories.includes(parseInt(programSale.categoryId, 10))) {
        programSalesCheck = true;
      }
    });

    if (programSalesCheck) {
      filteredResults.push(item);
    }
  });

  return filteredResults;
};

export const useTransactionSorter = (transactions) => {
  return useParamsSorter(useCategorySorter(useStatusSorter(transactions)));
};
