import React, { Component } from 'react';
import axios from 'supports/api';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from 'reactstrap';
import DataFrame from 'dataframe-js';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import createPlotlyComponent from 'react-plotly.js/factory';

const { Plotly } = window;
const Plot = createPlotlyComponent(Plotly);

class EnquiryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dfEnquiries: null,
      dfTransactions: null,
      dfOutstanding: null,
      dfPayment: null,
      selectedGroupBy: 'category',
      selectedGroupByPie: 'category',
      selectedSort: true,
      selectedFilterBy: 'none',
      selectedValue: '',
      selectedFilterByBar: 'none',
      selectedValueBar: '',
      itemCount: 100,
      fromDate: moment().date(1).toDate(),
      toDate: moment().toDate(),
      activeTab: 'Quantity/Group',
      fetchingData: false,
      errorText: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      this.setState({ fetchingData: true, errorText: '' });
      const resEnq = await axios.get(`/enquiry/all`, {
        params: {
          startDate: moment(this.state.fromDate).hours(0).minutes(0).toDate(),
          endDate: moment(this.state.toDate).hours(23).minutes(59).toDate(),
        },
      });
      const resTrx = await axios.get(`/admin/report/alltransactions`, {
        params: {
          startDate: moment(this.state.fromDate).hours(0).minutes(0).toDate(),
          endDate: moment(this.state.toDate).hours(23).minutes(59).toDate(),
        },
      });
      let dfPayment = 0;
      let dfOutstanding = 0;
      // await resTrx.data.result.forEach((item) => {

      //   if(item.transaction_statusId !== 4) {
      //     TotalSales += item.totalPrice;
      //   }
      // })
      await resTrx.data.result.forEach((item) => {
        // paid and unpaid
        dfPayment += item.totalPrice;
        dfOutstanding += item.accountsReceivable;
      });

      this.setState({
        dfOutstanding,
        dfPayment,
        dfTransactions: new DataFrame(resTrx.data.result).map((row) =>
          row.set('createdAt', new Date(row.get('createdAt')).toDateString()),
        ),
        dfEnquiries: new DataFrame(resEnq.data.result).map((row) =>
          row.set('createdAt', new Date(row.get('createdAt')).toDateString()),
        ),
        fetchingData: false,
      });
    } catch (err) {
      this.setState({ fetchingData: false, errorText: 'Terjadi Error' });
    }
  };

  getDateArray = (start, end) => {
    const arr = [];
    const dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  handlefromDatePicker = (date) => {
    this.setState({
      fromDate: moment(date).hours(0).minutes(1).toDate(),
    });
  };

  handletoDatePicker = (date) => {
    this.setState({
      toDate: moment(date).hours(23).minutes(59).toDate(),
    });
  };

  onBtnFilterDateClick = () => {
    this.getData();
  };

  renderBarPlot = () => {
    if (this.state.dfEnquiries && this.state.dfTransactions) {
      // console.log(this.state.dfEnquiries.groupBy('city').aggregate(group => group.count()).rename('aggregation', 'groupCount'))
      let dfEn = this.state.dfEnquiries;
      let dfTrx = this.state.dfTransactions;

      if (
        this.state.selectedFilterByBar !== 'none' &&
        this.state.selectedValueBar !== ''
      ) {
        dfEn = this.state.dfEnquiries.filter(
          (row) =>
            row.get(this.state.selectedFilterByBar) ===
            this.state.selectedValueBar,
        );
        dfTrx = this.state.dfTransactions.filter(
          (row) =>
            row.get(
              this.state.selectedFilterByBar === 'branch'
                ? 'branchName'
                : this.state.selectedFilterByBar,
            ) === this.state.selectedValueBar,
        );
      }

      const jumlahPrice = dfTrx.stat.sum('totalPrice').toLocaleString();
      const jumlahOutstanding = this.state.dfOutstanding.toLocaleString();
      const jumlahPayment = (
        dfTrx.stat.sum('totalPrice') - this.state.dfOutstanding
      ).toLocaleString();
      if (this.state.selectedGroupBy !== 'createdAt') {
        dfEn = dfEn
          .groupBy(this.state.selectedGroupBy)
          .aggregate((group) => group.count())
          .rename('aggregation', 'groupCount')
          .sortBy('groupCount', this.state.selectedSort)
          .head(this.state.itemCount);
        // console.log(dfEn.select('city').toArray().flat())
        dfTrx = dfTrx
          .groupBy(
            this.state.selectedGroupBy === 'branch'
              ? 'branchName'
              : this.state.selectedGroupBy,
          )
          .aggregate((group) => group.count())
          .rename('aggregation', 'groupCount')
          .sortBy('groupCount', this.state.selectedSort)
          .head(this.state.itemCount);
      } else {
        dfEn = dfEn
          .groupBy(this.state.selectedGroupBy)
          .aggregate((group) => group.count())
          .rename('aggregation', 'groupCount')
          .head(this.state.itemCount);
        // console.log(dfEn.select('city').toArray().flat())
        dfTrx = dfTrx
          .groupBy(this.state.selectedGroupBy)
          .aggregate((group) => group.count())
          .rename('aggregation', 'groupCount')
          .head(this.state.itemCount);
      }

      return (
        <Plot
          data={[
            {
              type: 'bar',
              x: dfEn.select(this.state.selectedGroupBy).toArray().flat(),
              y: dfEn.select('groupCount').toArray().flat(),
              text: dfEn.select('groupCount').toArray().flat().map(String),
              textposition: 'auto',
              hoverinfo: 'text+x',
              name: `Enquiry`,
            },
            {
              type: 'bar',
              x: dfTrx
                .select(
                  this.state.selectedGroupBy === 'branch'
                    ? 'branchName'
                    : this.state.selectedGroupBy,
                )
                .toArray()
                .flat(),
              y: dfTrx.select('groupCount').toArray().flat(),
              text: dfTrx.select('groupCount').toArray().flat().map(String),
              textposition: 'auto',
              hoverinfo: 'text+x',
              name: `Transaction`,
            },
          ]}
          layout={{
            width: 1500,
            height: 700,
            title: `Enquiry : ${dfEn
              .select('groupCount')
              .toArray()
              .flat()
              .reduce((a, b) => a + b, 0)}, Transaction : ${dfTrx
              .select('groupCount')
              .toArray()
              .flat()
              .reduce(
                (a, b) => a + b,
                0,
              )}<br>Total Sales: Rp. ${jumlahPrice}<br>Total Payment: Rp. ${jumlahPayment}<br>Total Outstanding: Rp. ${jumlahOutstanding}`,
            margin: { t: 150, b: 200 },
          }}
        />
      );
    }

    return <h3>Loading...</h3>;
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  renderTabs = () => {
    const arrJSX = [
      'Quantity/Group',
      'Quantity/Day',
      'Quantity Percentage/Group',
    ].map((tabName) => {
      return (
        <NavItem>
          <NavLink
            active={this.state.activeTab === tabName}
            onClick={() => {
              this.toggleTab(tabName);
            }}
          >
            {tabName}
          </NavLink>
        </NavItem>
      );
    });

    return arrJSX;
  };

  renderTab1 = () => {
    return (
      <div>
        <div className="row pl-3 pt-2">
          <div className="col-2 my-auto pl-5">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Filter By : </InputGroupText>
              </InputGroupAddon>
              <select
                id="selectedFilterByBar"
                className="form-control"
                onChange={async (e) => {
                  await this.setState({
                    selectedFilterByBar: e.target.value,
                  });
                }}
              >
                <option value="none">- Select -</option>
                {/* <option value="city">City</option> */}
                <option value="category">Category</option>
                <option value="product">Product</option>
                <option value="studyMethod">Study Method</option>
                <option value="studySchedule">Study Schedule</option>
                <option value="institution">Education</option>
                <option value="occupation">Occupation</option>
                <option value="referral">Referral</option>
                <option value="hasRelative">Has Relative</option>
                <option value="formName">Data Source</option>
                {/* <option value="branch">Branch</option> */}
              </select>
            </InputGroup>
          </div>
          <div className="col-3 my-auto pl-5">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Value : </InputGroupText>
              </InputGroupAddon>
              <select
                id="selectedValueBar"
                className="form-control"
                onChange={async (e) => {
                  await this.setState({
                    selectedValueBar: e.target.value,
                  });
                }}
              >
                {this.renderOptionSelectedValueBar()}
              </select>
            </InputGroup>
          </div>
        </div>
        <div className="row pl-3 pt-2">
          <div className="col-2 my-auto pl-5">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Item Count : </InputGroupText>
              </InputGroupAddon>
              <input
                id="inputItemCount"
                type="number"
                defaultValue={this.state.itemCount}
                className="form-control"
                onInput={async (e) => {
                  await this.setState({
                    itemCount: parseInt(e.target.value, 10),
                  });
                }}
              />
            </InputGroup>
          </div>
          <div className="col-2 my-auto pl-5">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Group By : </InputGroupText>
              </InputGroupAddon>
              <select
                id="selectedGroupBy"
                className="form-control"
                onChange={async (e) => {
                  await this.setState({
                    selectedGroupBy: e.target.value,
                  });
                }}
              >
                {/* <option value="city">City</option> */}
                <option value="category">Category</option>
                <option value="product">Product</option>
                <option value="studyMethod">Study Method</option>
                <option value="studySchedule">Study Schedule</option>
                <option value="institution">Education</option>
                <option value="occupation">Occupation</option>
                <option value="referral">Referral</option>
                <option value="hasRelative">Has Relative</option>
                <option value="formName">Data Source</option>
                {/* <option value="branch">Branch</option> */}
                {/* <option value="createdAt">Date</option> */}
              </select>
            </InputGroup>
          </div>
          <div className="col-2 my-auto pl-5">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Sort : </InputGroupText>
              </InputGroupAddon>
              <select
                id="selectedSort"
                ref="selectedSort"
                className="form-control"
                onChange={async () => {
                  if (this.refs.selectedSort.value === 'dsc') {
                    await this.setState({ selectedSort: true });
                  } else {
                    await this.setState({ selectedSort: false });
                  }
                }}
              >
                <option value="dsc">Descending</option>
                <option value="asc">Ascending</option>
              </select>
            </InputGroup>
          </div>
        </div>
        <div className="row px-5 py-3">
          <div className="col-12">{this.renderBarPlot()}</div>
        </div>
      </div>
    );
  };

  renderOptionSelectedValueBar = () => {
    if (this.state.selectedFilterByBar !== 'none') {
      let listValue = [];
      listValue.push(
        ...this.state.dfEnquiries
          .unique(this.state.selectedFilterByBar)
          .toArray()
          .flat(),
      );
      listValue.push(
        ...this.state.dfTransactions
          .unique(
            this.state.selectedFilterByBar === 'branch'
              ? 'branchName'
              : this.state.selectedFilterByBar,
          )
          .toArray()
          .flat(),
      );

      listValue = [...new Set(listValue)];

      const listJSX = [<option value="">- Select -</option>];
      listJSX.push(
        ...listValue.map((val) => {
          return <option value={val}>{val}</option>;
        }),
      );
      return listJSX;
    }
    return <option value="" />;
  };

  renderOptionSelectedValue = () => {
    if (this.state.selectedFilterBy !== 'none') {
      let listValue = [];
      listValue.push(
        ...this.state.dfEnquiries
          .unique(this.state.selectedFilterBy)
          .toArray()
          .flat(),
      );
      listValue.push(
        ...this.state.dfTransactions
          .unique(
            this.state.selectedFilterBy === 'branch'
              ? 'branchName'
              : this.state.selectedFilterBy,
          )
          .toArray()
          .flat(),
      );

      listValue = [...new Set(listValue)];

      const listJSX = [<option value="">- Select -</option>];
      listJSX.push(
        ...listValue.map((val) => {
          return <option value={val}>{val}</option>;
        }),
      );
      return listJSX;
    }
    return <option value="" />;
  };

  renderLinePlot = () => {
    if (this.state.dfEnquiries && this.state.dfTransactions) {
      const arrDate = this.getDateArray(this.state.fromDate, this.state.toDate);
      // console.log(this.state.toDate)
      // console.log(arrDate)
      const plotData = {
        x: [],
        yEnquiry: [],
        yTransaction: [],
      };
      let jumlahPrice = 0;
      if (
        this.state.selectedFilterBy !== 'none' &&
        this.state.selectedValue !== ''
      ) {
        arrDate.forEach((date) => {
          plotData.x.push(date.toDateString());
          plotData.yEnquiry.push(
            this.state.dfEnquiries
              .filter(
                (row) =>
                  row.get('createdAt') === date.toDateString() &&
                  row.get(this.state.selectedFilterBy) ===
                    this.state.selectedValue,
              )
              .count(),
          );
          plotData.yTransaction.push(
            this.state.dfTransactions
              .filter(
                (row) =>
                  row.get('createdAt') === date.toDateString() &&
                  row.get(
                    this.state.selectedFilterBy === 'branch'
                      ? 'branchName'
                      : this.state.selectedFilterBy,
                  ) === this.state.selectedValue,
              )
              .count(),
          );
        });
        jumlahPrice = this.state.dfTransactions
          .filter(
            (row) =>
              row.get(
                this.state.selectedFilterBy === 'branch'
                  ? 'branchName'
                  : this.state.selectedFilterBy,
              ) === this.state.selectedValue,
          )
          .stat.sum('totalPrice')
          .toLocaleString();
      } else {
        arrDate.forEach((date) => {
          plotData.x.push(date.toDateString());
          plotData.yEnquiry.push(
            this.state.dfEnquiries
              .filter((row) => row.get('createdAt') === date.toDateString())
              .count(),
          );
          plotData.yTransaction.push(
            this.state.dfTransactions
              .filter((row) => row.get('createdAt') === date.toDateString())
              .count(),
          );
        });

        jumlahPrice = this.state.dfTransactions.stat
          .sum('totalPrice')
          .toLocaleString();
      }

      return (
        <Plot
          data={[
            {
              type: 'scatter',
              x: plotData.x,
              y: plotData.yEnquiry,
              text: plotData.yEnquiry,
              textposition: 'auto',
              hoverinfo: 'text+x',
              name: 'Enquiry',
            },
            {
              type: 'scatter',
              x: plotData.x,
              y: plotData.yTransaction,
              text: plotData.yTransaction,
              textposition: 'auto',
              hoverinfo: 'text+x',
              name: 'Transaction',
            },
          ]}
          layout={{
            width: 1500,
            height: 600,
            title: `Enquiry : ${plotData.yEnquiry.reduce(
              (a, b) => a + b,
              0,
            )}, Transaction : ${plotData.yTransaction.reduce(
              (a, b) => a + b,
              0,
            )} ; Rp. ${jumlahPrice}`,
            margin: { b: 200 },
          }}
        />
      );
    }

    return <h3>Loading...</h3>;
  };

  renderPiePlot = () => {
    if (this.state.dfEnquiries && this.state.dfTransactions) {
      // console.log(this.state.dfEnquiries.groupBy('city').aggregate(group => group.count()).rename('aggregation', 'groupCount'))
      const dfEn = this.state.dfEnquiries
        .groupBy(this.state.selectedGroupByPie)
        .aggregate((group) => group.count())
        .rename('aggregation', 'groupCount')
        .sortBy('groupCount', true)
        .head(8);
      // console.log(dfEn.select('city').toArray().flat())
      const dfTrxCount = this.state.dfTransactions
        .groupBy(
          this.state.selectedGroupByPie === 'branch'
            ? 'branchName'
            : this.state.selectedGroupByPie,
        )
        .aggregate((group) => group.count())
        .rename('aggregation', 'groupCount')
        .sortBy('groupCount', true)
        .head(8);
      const dfTrxSum = this.state.dfTransactions
        .groupBy(
          this.state.selectedGroupByPie === 'branch'
            ? 'branchName'
            : this.state.selectedGroupByPie,
        )
        .aggregate((group) => group.stat.sum('totalPrice'))
        .rename('aggregation', 'groupSum')
        .sortBy('groupSum', true)
        .head(8);
      // console.log('DF TRX', this.state.dfTransactions)
      return (
        <div>
          <Plot
            data={[
              {
                type: 'pie',
                labels: dfTrxSum
                  .select(
                    this.state.selectedGroupByPie === 'branch'
                      ? 'branchName'
                      : this.state.selectedGroupByPie,
                  )
                  .toArray()
                  .flat(),
                values: dfTrxSum.select('groupSum').toArray().flat(),
                // hovertext: dfTrx.select('groupCount').toArray().flat(),
                domain: { column: 1 },
                name: `Transaction`,
                title: {
                  text: 'Transaction',
                  font: {
                    size: 24,
                  },
                },
                marker: {
                  colors: [
                    '#284a5c',
                    '#005f6f',
                    '#007374',
                    '#008769',
                    '#329850',
                    '#75a42e',
                    '#b7aa00',
                    '#ffa600',
                  ],
                },
              },
            ]}
            layout={{
              width: 1500,
              height: 600,
              title: `Transaction : ${dfTrxCount
                .select('groupCount')
                .toArray()
                .flat()
                .reduce(
                  (a, b) => a + b,
                  0,
                )} ; Rp. ${this.state.dfTransactions.stat
                .sum('totalPrice')
                .toLocaleString()}`,
            }}
            // grid: {rows: 1, columns: 2} }}
          />
          <Plot
            data={[
              {
                type: 'pie',
                labels: dfEn
                  .select(this.state.selectedGroupByPie)
                  .toArray()
                  .flat(),
                values: dfEn.select('groupCount').toArray().flat(),
                domain: { column: 0 },
                name: `Enquiry`,
                title: {
                  text: 'Enquiry',
                  font: {
                    size: 24,
                  },
                },
                marker: {
                  colors: [
                    '#284a5c',
                    '#005f6f',
                    '#007374',
                    '#008769',
                    '#329850',
                    '#75a42e',
                    '#b7aa00',
                    '#ffa600',
                  ],
                },
              },
            ]}
            layout={{
              width: 1500,
              height: 600,
              title: `Enquiry : ${dfEn
                .select('groupCount')
                .toArray()
                .flat()
                .reduce((a, b) => a + b, 0)}`,
            }}
            // grid: {rows: 1, columns: 2} }}
          />
        </div>
      );
    }

    return <h3>Loading...</h3>;
  };

  renderTab2 = () => {
    return (
      <div>
        <div className="row pl-3 pt-2">
          <div className="col-2 my-auto pl-5">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Filter By : </InputGroupText>
              </InputGroupAddon>
              <select
                id="selectedFilterBy"
                className="form-control"
                onChange={async (e) => {
                  await this.setState({
                    selectedFilterBy: e.target.value,
                  });
                }}
              >
                <option value="none">- Select -</option>
                <option value="city">City</option>
                <option value="occupation">Occupation</option>
                <option value="referral">Referral</option>
                <option value="product">Product</option>
                <option value="category">Category</option>
                <option value="branch">Branch</option>
              </select>
            </InputGroup>
          </div>
          <div className="col-3 my-auto pl-5">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Value : </InputGroupText>
              </InputGroupAddon>
              <select
                id="selectedValue"
                className="form-control"
                onChange={async (e) => {
                  await this.setState({
                    selectedValue: e.target.value,
                  });
                }}
              >
                {this.renderOptionSelectedValue()}
              </select>
            </InputGroup>
          </div>
        </div>
        <div className="row px-5 py-3">
          <div className="col-12">{this.renderLinePlot()}</div>
        </div>
      </div>
    );
  };

  renderTab3 = () => {
    return (
      <div>
        <div className="row pl-3 pt-2">
          <div className="col-2 my-auto pl-5">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>Group By : </InputGroupText>
              </InputGroupAddon>
              <select
                id="selectedGroupByPie"
                className="form-control"
                onChange={async (e) => {
                  await this.setState({
                    selectedGroupByPie: e.target.value,
                  });
                }}
              >
                <option value="city">City</option>
                <option value="occupation">Occupation</option>
                <option value="referral">Referral</option>
                <option value="product">Product</option>
                <option value="category">Category</option>
                <option value="branch">Branch</option>
              </select>
            </InputGroup>
          </div>
        </div>
        <div className="row px-5 py-3">
          <div className="col-12">{this.renderPiePlot()}</div>
        </div>
      </div>
    );
  };

  renderButtonFilterDate = () => {
    if (!this.state.fetchingData) {
      return (
        <input
          type="button"
          className="btn btn-primary"
          value="Filter Date"
          onClick={this.onBtnFilterDateClick}
        />
      );
    }

    return <Spinner color="primary" />;
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="white-box py-5">
          <div className="row pl-3">
            <div className="col-2 my-auto pl-5">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Date From : </InputGroupText>
                </InputGroupAddon>
                <DatePicker
                  className="form-control"
                  selected={this.state.fromDate}
                  onChange={this.handlefromDatePicker}
                  dateFormat="dd MMM yyyy"
                />
              </InputGroup>
            </div>
            <div className="col-2 my-auto pl-5">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Date To : </InputGroupText>
                </InputGroupAddon>
                <DatePicker
                  className="form-control"
                  selected={this.state.toDate}
                  onChange={this.handletoDatePicker}
                  dateFormat="dd MMM yyyy"
                />
              </InputGroup>
            </div>
            <div className="col-3 my-auto pl-5">
              {this.renderButtonFilterDate()}
              <p style={{ color: 'red' }}>{this.state.errorText}</p>
            </div>
          </div>
          <div className="row pl-5 pt-2">
            <div className="col">
              <Nav tabs>{this.renderTabs()}</Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="Quantity/Group">{this.renderTab1()}</TabPane>
                <TabPane tabId="Quantity/Day">{this.renderTab2()}</TabPane>
                <TabPane tabId="Quantity Percentage/Group">
                  {this.renderTab3()}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EnquiryReport;
