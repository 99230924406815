import React, { useState, useEffect, useCallback } from 'react';
import axios from 'supports/api';
import { Button, Table, Badge } from 'reactstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import PCard from 'pages/finance/transactions/main/PCard';
import CustomPagination from 'components/commons/CustomPagination';
import OnlineCourseTopicFormModal from './OnlineCourseTopicFormModal';
import CourseTopicCard from './CourseTopicCard';
import ModalDelete from '../components/ModalDelete';

const OnlineCourseTopicListPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get('page') || 1;
  const itemsPerPage = 10;
  const { onlineCourseSessionId } = useParams();

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(false);

  const [courseFormModalIsOpen, setCourseFormModalIsOpen] = useState(false);
  const [onlineCourseSession, setOnlineCourseSession] = useState({});
  const [onlineCourseTopicList, setOnlineCourseTopicList] = useState([]);
  const [selectedOnlineCourseTopic, setSelectedOnlineCourseTopic] = useState(
    null,
  );

  const getOnlineCourseSessionData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `/v2/online-course/session/${onlineCourseSessionId}`,
      );
      const { courseTopics, ...courseSession } = data?.data;
      setOnlineCourseSession(courseSession);
      setOnlineCourseTopicList(courseTopics);
    } catch (err) {
      console.log(err);
    }
  }, [onlineCourseSessionId]);

  useEffect(() => {
    getOnlineCourseSessionData();
  }, [getOnlineCourseSessionData]);

  const onEditClick = (courseTopic) => {
    setSelectedOnlineCourseTopic(courseTopic);
    setCourseFormModalIsOpen(true);
  };

  const onButtonDeleteClick = async () => {
    try {
      const result = await axios.delete(
        `v2/online-course-topic/${selectedDeleteId}`,
      );
      if (result.data.status === 'SUCCESS') {
        setSelectedDeleteId(null);
        getOnlineCourseSessionData();
        setOpenModalDelete(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  const TableData = () => {
    const arrJSX = onlineCourseTopicList
      ?.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage,
      )
      .map((courseTopic) => {
        return (
          <>
            <CourseTopicCard
              key={courseTopic.id}
              courseTopic={courseTopic}
              onEditClick={() => onEditClick(courseTopic)}
              setOpenModalDelete={setOpenModalDelete}
              selectedId={selectedDeleteId}
              setSelectedDeleteId={setSelectedDeleteId}
            />
          </>
        );
      });

    return arrJSX;
  };
  return (
    <div className="container" style={{ marginTop: '40px' }}>
      <div className="d-flex flex-row justify-content-between mb-2">
        <div
          className="font-weight-bold"
          style={{ fontSize: '32px', color: '#505050' }}
        >
          {onlineCourseSession?.title} <Badge>Session</Badge>
        </div>
        <Link
          to={`${location?.pathname.slice(
            0,
            location?.pathname.lastIndexOf('/'),
          )}`}
          className="mt-auto"
        >
          <Button color="danger">Back</Button>
        </Link>
      </div>
      <div className="d-flex justify-content-between mb-2">
        <CustomPagination
          pages={Math.ceil(onlineCourseTopicList?.length / itemsPerPage) || 1}
          active={page}
        />
        <Button
          color="success"
          style={{ backgroundColor: '#32b280' }}
          onClick={() => {
            setCourseFormModalIsOpen(true);
            setSelectedOnlineCourseTopic(null);
          }}
        >
          Add Topic
        </Button>
      </div>
      <PCard style={{ minHeight: '600px' }}>
        <div className="d-flex flex-row justify-content-between">
          <Table striped bordered responsive hover>
            <thead
              className="font-weight-bold"
              style={{ fontSize: '14px', color: '#505050' }}
            >
              <tr>
                <th>#</th>
                <th>TOPIC</th>
                <th>DESCRIPTION</th>
                <th>DURATION</th>
                <th>VIDEO</th>
                <th>MATERI</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              <TableData />
            </tbody>
          </Table>
        </div>
      </PCard>
      <OnlineCourseTopicFormModal
        isOpen={courseFormModalIsOpen}
        toggle={() => setCourseFormModalIsOpen(!courseFormModalIsOpen)}
        onlineCourseSessionId={onlineCourseSession?.id}
        folderId={onlineCourseSession.folderId}
        lastOrderNumber={onlineCourseTopicList?.length}
        selectedCourseTopic={selectedOnlineCourseTopic}
        refresh={() => {
          getOnlineCourseSessionData();
          setCourseFormModalIsOpen(false);
          setSelectedOnlineCourseTopic(null);
        }}
      />
      <ModalDelete
        isOpen={openModalDelete}
        toggle={() => setOpenModalDelete(!openModalDelete)}
        onButtonDeleteClick={onButtonDeleteClick}
      />
    </div>
  );
};

export default OnlineCourseTopicListPage;
