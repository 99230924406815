import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import qs from 'query-string';
import StudentDataList from './StudentDataList';
import StudentDetail from './StudentDataDetail';

const StudentDataPage = () => {
  const location = useLocation();
  const history = useHistory();

  //   const [activeTab, setActiveTab] = useState(
  //     qs.parse(location.search).tab || 'list',
  //   );

  const activeTab = qs.parse(location.search).tab;

  //   const toggle = (tab) => {
  //     if (activeTab !== tab) setActiveTab(tab);
  //   };

  const handleTabs = (tab) => {
    const queryParam = qs.parse(location.search);
    const newQueryParam = {
      ...queryParam,
      tab,
    };
    history.push(`?${qs.stringify(newQueryParam)}`);
    // toggle(tab);
  };

  useEffect(() => {
    const queryParam = qs.parse(location.search);
    if (!queryParam.tab) {
      const newQueryParam = {
        ...queryParam,
        tab: 'list',
        page: 1,
      };
      history.push(`?${qs.stringify(newQueryParam)}`);
    }
  }, [history, location.search]);

  return (
    <div
      className="white-box container-fluid py-2"
      style={{ minHeight: '100vh' }}
    >
      <div className="row px-1 py-5">
        <div className="col-12 offset-sm-1 col-sm-10">
          <div>
            <div className="line-green mb-4" />
            <div className="general-title text-gray">Student Data</div>
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: '8.3%', paddingRight: '8.3%' }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              active={activeTab === 'list'}
              onClick={() => handleTabs('list')}
            >
              List
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activeTab === 'data'}
              onClick={() => handleTabs('data')}
            >
              Data
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="list">
            <StudentDataList />
          </TabPane>
          <TabPane tabId="data">
            <StudentDetail />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default StudentDataPage;
