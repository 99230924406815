import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import qs from 'query-string';

const OnlineCourseCard = ({
  id,
  title,
  subHeader,
  normalPrice,
  //   trailer,
  thumbnail,
  //   slug,
  totalStudent,
}) => {
  const location = useLocation();
  const queryParam = qs.parse(location.search);
  return (
    <div
      className="row my-3 py-2 border justify-content-between"
      style={{ borderRadius: '6px' }}
    >
      <div className="col-2">
        <img
          src={process.env.REACT_APP_BASE_URL + thumbnail}
          alt={thumbnail}
          height={100}
          width={150}
          style={{ borderRadius: '6px' }}
        />
      </div>
      <div className="col-8">
        <div className="row">
          <div className="col-3">Title</div>
          <div className="col-9">: {title}</div>
        </div>
        <div className="row">
          <div className="col-3">SubHeader</div>
          <div className="col-9">: {subHeader}</div>
        </div>
        <div className="row">
          <div className="col-3">Normal Price</div>
          <div className="col-9">
            : Rp {normalPrice && normalPrice.toLocaleString()}
          </div>
        </div>
        <div className="row">
          <div className="col-3">Total Student</div>
          <div className="col-9">: {totalStudent}</div>
        </div>
      </div>
      <div className="col-2">
        <Link to={`?${qs.stringify({ ...queryParam, id, tab: 'data' })}`}>
          <Button color="secondary" className="mb-1 w-100" size="sm">
            Details
          </Button>
        </Link>
        <Link to={`${location.pathname}/${id}`}>
          <Button size="sm" className="w-100">
            Modules
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default OnlineCourseCard;
