import React from 'react';
import { Pagination, PaginationItem, PaginationLink, Button } from 'reactstrap';
import { now } from 'moment';

const RenderPrevious = ({ activePage, setPage }) => {
  if (activePage > 1) {
    return (
      <PaginationItem>
        <PaginationLink onClick={() => setPage(activePage - 1)} previous />
      </PaginationItem>
    );
  }
  return null;
};

const RenderNext = ({ activePage, pages, setPage }) => {
  if (activePage < pages) {
    return (
      <PaginationItem>
        <PaginationLink onClick={() => setPage(activePage + 1)} next />
      </PaginationItem>
    );
  }
  return null;
};

const LoopPagination = ({ activePage, pages, setPage }) => {
  const arrJSX = [];
  for (let i = 1; i <= pages; i += 1) {
    if (i === activePage - 3) {
      arrJSX.push(
        <PaginationItem key={`page${i * now()}`} disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      );
    } else if (Math.abs(activePage - i) <= 2) {
      arrJSX.push(
        <PaginationItem key={`page${i * now()}`} active={i === activePage}>
          <PaginationLink onClick={() => setPage(i)} style={{ zIndex: 0 }}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    } else if (i === activePage + 3) {
      arrJSX.push(
        <PaginationItem key={`page${i * now()}`} disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      );
    }
  }
  return arrJSX;
};

const CustomPaginationState = ({ pages = 1, active, setPage, ...props }) => {
  const activePage = parseInt(active || 1, 10);

  return (
    <div {...props}>
      <Pagination listClassName="m-0">
        <PaginationItem>
          <Button
            onClick={() => setPage(1)}
            style={{ borderRadius: '3px 0px 0px 3px' }}
          >
            First
          </Button>
        </PaginationItem>
        <RenderPrevious activePage={activePage} setPage={setPage} />
        <LoopPagination
          activePage={activePage}
          pages={pages}
          setPage={setPage}
        />
        <RenderNext activePage={activePage} pages={pages} setPage={setPage} />
        <PaginationItem>
          <Button
            onClick={() => setPage(pages)}
            style={{ borderRadius: '0px 3px 3px 0px' }}
          >
            Last
          </Button>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default CustomPaginationState;
