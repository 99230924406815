/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import axios from 'supports/api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  Card,
  CardBody,
  Form,
  Row,
  Col,
  Table,
  FormGroup,
  Input,
  Label,
  Collapse,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Button,
} from 'reactstrap';
import { fetchTujuanPengeluaran, createPO } from 'redux/modules/purchaseOrder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faDollarSign,
  faSave,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { TablePOData } from '../components';

const PODataTab = ({
  selectedId,
  setSelectedId,
  readOnly,
  setReadOnly,
  setpoDetailShow,
  poDetailShow,
  saveShow,
  setSaveShow,
  addToLiquid,
  setAddToLiquid,
  gBank,
  btnAddJournalShow,
  setBtnAddJournalShow,
  readOnlyFromEditMutation,
  setReadOnlyFromEditMutation,
  editMutation,
  setEditMutation,
  setBtnLiquidShow,
  btnLiquidShow,
  setAddToJournal,
  addToJournal,
  insertId,
  setInsertId,
}) => {
  const [form, setForm] = useState({
    idTujPengeluaran: 0,
    tujPengeluaran: '',
    dueDate: null,
    periodStartDate: null,
    periodEndDate: null,
    branchId: 0,
    totalPrice: 0,
    remarks: '',
    noRekTujuan: '',
    namaRekTujuan: '',
    namaBankTujuan: '',
    cabangTujuan: '',
    mutationDate: null,
  });
  const [poNumberForm, setPoNumberForm] = useState('');
  const [poDateForm, setPoDateForm] = useState(null);
  const [totalPriceForm, setTotalPriceForm] = useState(0);
  const [bankForm, setBankForm] = useState({
    idBank: 0,
    fromAccountName: '',
    fromBankName: '',
    fromAccNumber: '',
  });

  const [poItemDetailById, setpoItemDetailById] = useState([]);
  const [vTujPengeluaran, setVTujPengeluaran] = useState(false);
  const [vRemarks, setVRemarks] = useState(false);
  const [vDetails, setVDetails] = useState(false);
  const [vPrice, setVPrice] = useState(false);
  const [vBranch, setVBranch] = useState(false);
  const [vDueDate, setVDueDate] = useState(false);
  const [vPeriodDate, setVPeriodDate] = useState({
    start: false,
    end: false,
  });
  const [detailShow, setDetailShow] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoadingAddToGL, setIsLoadingAddTOGL] = useState(false);
  const [isLoadingEditMutation, setIsLoadingEditMutation] = useState(false);
  const [isLoadingLiquid, setIsLoadingLiquid] = useState(false);
  const [addToGlClicked, setAddToGlClicked] = useState(false);
  const [isLoadingAddToGlClicked, setIsLoadingAddToGlClicked] = useState(false);
  const [poItems, setPoItems] = useState([]);

  const dispatch = useDispatch();
  const gTujuanPengeluaran = useSelector(
    ({ purchaseOrder }) => purchaseOrder.tujuanPengeluaran,
  );
  const dataPO = useSelector(({ purchaseOrder }) => purchaseOrder.dataPo);
  const gStatus = useSelector(({ purchaseOrder }) => purchaseOrder.status);
  const gPoTotalPrice = useSelector(
    ({ purchaseOrder }) => purchaseOrder.poHeaders.totalPrice,
  );
  const branches = useSelector(({ purchaseOrder }) => purchaseOrder.branches);
  useEffect(() => {
    // fetch masterTujuanPengeluaran from API
    dispatch(fetchTujuanPengeluaran());
  }, [dispatch]);

  useEffect(() => {
    setInsertId(dataPO);
  }, [dataPO, setInsertId]);

  useEffect(() => {
    if (form.idTujPengeluaran) setVTujPengeluaran(false);
    if (form.remarks) setVRemarks(false);
    if (form.branchId) setVBranch(false);
    if (form.dueDate) setVDueDate(false);
    if (form.periodStartDate) setVPeriodDate({ start: false });
    if (form.periodEndDate) setVPeriodDate({ end: false });
  }, [form]);

  useEffect(() => {
    setPoNumberForm(dataPO.code ? dataPO.code : null);
    setPoDateForm(dataPO.tglPO ? moment(dataPO.tglPO).toDate() : null);
  }, [dataPO]);

  useEffect(() => {
    setTotalPriceForm(gPoTotalPrice ? gPoTotalPrice.toLocaleString() : null);
  }, [gPoTotalPrice]);

  const handleNew = () => {
    setForm({
      idTujPengeluaran: 0,
      tujPengeluaran: '',
      dueDate: null,
      totalPrice: 0,
      remarks: '',
      noRekTujuan: '',
      namaRekTujuan: '',
      namaBankTujuan: '',
      cabangTujuan: '',
      branchId: 0,
      periodStartDate: null,
      periodEndDate: null,
    });
    setPoNumberForm('');
    setPoDateForm(null);
    setTotalPriceForm(0);
    setBankForm({
      idBank: 0,
      fromAccountName: '',
      fromBankName: '',
      fromAccNumber: '',
    });
    setVTujPengeluaran(false);
    setVRemarks(false);
    setVPrice(false);
    setVDetails(false);
    setVBranch(false);
    setVDueDate(false);
    setVPeriodDate({ start: false, end: false });
    setDetailShow(false);
    setSaveShow(false);
    setReadOnly(false);
    setpoDetailShow(false);
    setAddToLiquid(false);
    setBtnAddJournalShow(false);
    setReadOnlyFromEditMutation(false);
    setSelectedId(null);
    setEditMutation(false);
    setPoItems([]);
    setBtnLiquidShow(false);
    setAddToJournal(false);
  };

  useEffect(() => {
    if (selectedId) {
      axios
        .get(`/poHeader/getById/${selectedId}`)
        .then((res) => {
          const {
            code,
            tglPO,
            totalPrice,
            masterTujuanPengeluaranId,
            remarks,
            namaBankTujuan,
            namaRekeningTujuan,
            nomorRekeningTujuan,
            cabangTujuan,
            dueDate,
            masterTujuanPengeluaran,
            tanggalMutasi,
            bankId,
            periodStart,
            periodEnd,
            branchId,
          } = res.data.result;
          setPoNumberForm(code);
          setPoDateForm(moment(tglPO).toDate());
          setTotalPriceForm(totalPrice ? totalPrice.toLocaleString() : null);
          setBankForm({
            ...bankForm,
            idBank:
              readOnlyFromEditMutation || editMutation || addToJournal
                ? bankId
                : null,
          });
          setForm({
            ...form,
            idTujPengeluaran: masterTujuanPengeluaranId,
            tujPengeluaran: masterTujuanPengeluaran.tujuanPengeluaran,
            // purchaseOrder: code,
            // poDate: moment(tglPO).toDate(),
            dueDate: moment(dueDate).toDate(),
            // totalPrice: totalPrice ? totalPrice.toLocaleString() : null,
            remarks,
            noRekTujuan: nomorRekeningTujuan,
            namaRekTujuan: namaRekeningTujuan,
            namaBankTujuan,
            cabangTujuan,
            mutationDate:
              readOnlyFromEditMutation || editMutation || addToJournal
                ? moment(tanggalMutasi).toDate()
                : null,
            // idBank:
            //   readOnlyFromEditMutation || editMutation || addToJournal
            //     ? bankId
            //     : null,
            branchId,
            periodStartDate: periodStart ? moment(periodStart).toDate() : null,
            periodEndDate: periodEnd ? moment(periodEnd).toDate() : null,
          });
          setpoItemDetailById(res.data.result.poItems);
        })
        .catch((err) => alert(`Terjadi kesalahan system ${err}`));
    } else {
      setSelectedId(null);
      setReadOnly(false);
      setpoDetailShow(false);
      handleNew();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  useEffect(() => {
    if (addToGlClicked) {
      setIsLoadingAddToGlClicked(true);
      axios
        .get(`/bank/get-by-id/${bankForm.idBank}`)
        .then((res) => {
          setAddToGlClicked(false);
          setIsLoadingAddToGlClicked(false);
          setBankForm({
            ...bankForm,
            idBank: res.data.result[0].id,
            fromAccountName: res.data.result[0].accountName,
            fromBankName: res.data.result[0].bankName,
            fromAccNumber: res.data.result[0].accountNumber,
          });
        })
        .catch((err) => {
          alert(`Terjadi kesalahan system ${err}`);
          setIsLoadingAddToGlClicked(false);
        });
    }
  }, [addToGlClicked, bankForm]);

  const handleDueDate = (date) => setForm({ ...form, dueDate: date });
  const handlePeriodStartDate = (date) => {
    setForm({ ...form, periodStartDate: date, periodEndDate: date });
  };
  const handlePeriodEndDate = (date) => {
    if (date < form.periodStartDate) {
      alert('Period End date cannot be before the Period start date');
    } else {
      setForm({ ...form, periodEndDate: date });
    }
  };

  const hanldeMutationDate = (date) => setForm({ ...form, mutationDate: date });

  const renderTujuanPengeluaran = () => {
    return gTujuanPengeluaran.map((val) => {
      return (
        <option key={val.id} value={val.id}>
          {val.tujuanPengeluaran}
        </option>
      );
    });
  };

  const handleTujuanPengeluaran = (e) => {
    axios
      .get(`/masterTujuanPengeluaran/${e.currentTarget.value}`)
      .then((res) => {
        const { id, tujuanPengeluaran } = res.data.result;
        setForm({
          ...form,
          idTujPengeluaran: id,
          tujPengeluaran: tujuanPengeluaran,
        });
      })
      .catch((err) => alert(`Terjadi kesalahan system ${err}`));
  };

  const renderBank = () => {
    return gBank.map((val) => {
      return (
        <option
          value={val.id}
        >{`${val.accountName} - (${val.accountNumber})`}</option>
      );
    });
  };

  const handleBank = (e) => {
    axios
      .get(`/bank/get-by-id/${e.currentTarget.value}`)
      .then(
        (res) =>
          setBankForm({
            ...bankForm,
            idBank: res.data.result[0].id,
            fromAccountName: res.data.result[0].accountName,
            fromBankName: res.data.result[0].bankName,
            fromAccNumber: res.data.result[0].accountNumber,
          }),
        // setForm({
        //   ...form,
        // idBank: res.data.result[0].id,
        // fromAccountName: res.data.result[0].accountName,
        // fromBankName: res.data.result[0].bankName,
        // fromAccNumber: res.data.result[0].accountNumber,
        // }),
      )
      .catch((err) => alert(`Terjadi kesalahan system ${err}`));
  };

  const handleSave = (id, insertedId) => {
    if (window.confirm('Are you sure you want to add these data ?')) {
      setIsLoadingSave(true);
      const data = {
        masterTujuanPengeluaranId: form.idTujPengeluaran,
        remarks: form.remarks,
        namaBankTujuan: form.namaBankTujuan,
        namaRekeningTujuan: form.namaRekTujuan,
        nomorRekeningTujuan: form.noRekTujuan,
        cabangTujuan: form.cabangTujuan,
        dueDate: form.dueDate,
        periodStart: form.periodStartDate,
        periodEnd: form.periodEndDate,
        branchId: form.branchId,
      };
      if (!insertedId) {
        axios
          .put(`/poHeader/update/${id}`, data)
          .then((res) => {
            alert('Add data success');
            setIsLoadingSave(false);
            axios
              .get(`/poHeader/getById/${id}`)
              .then((res) => {
                const {
                  code,
                  tglPO,
                  totalPrice,
                  masterTujuanPengeluaranId,
                  remarks,
                  namaBankTujuan,
                  namaRekeningTujuan,
                  nomorRekeningTujuan,
                  cabangTujuan,
                  dueDate,
                  masterTujuanPengeluaran,
                  periodStart,
                  periodEnd,
                  branchId,
                } = res.data.result;
                setForm({
                  idTujPengeluaran: masterTujuanPengeluaranId,
                  tujPengeluaran: masterTujuanPengeluaran.tujuanPengeluaran,
                  dueDate: moment(dueDate).toDate(),
                  totalPrice: totalPrice ? totalPrice.toLocaleString() : null,
                  remarks,
                  noRekTujuan: nomorRekeningTujuan,
                  namaRekTujuan: namaRekeningTujuan,
                  namaBankTujuan,
                  cabangTujuan,
                  branchId,
                  periodStartDate: periodStart
                    ? moment(periodStart).toDate()
                    : null,
                  periodEndDate: periodEnd ? moment(periodEnd).toDate() : null,
                });
                setPoNumberForm(code);
                setPoDateForm(moment(tglPO).toDate());
                setpoItemDetailById(res.data.result.poItems);
              })
              .catch((err) => alert(`Terjadi kesalahan system ${err}`));
          })
          .catch((err) => {
            alert(`Terjadi kesalahan system ${err}`);
            setIsLoadingSave(false);
          });
      } else {
        axios
          .put(`/poHeader/update/${insertedId}`, data)
          .then((res) => {
            alert('Add data success');
            setIsLoadingSave(false);
            axios
              .get(`/poHeader/getById/${insertedId}`)
              .then((res) => {
                const {
                  code,
                  tglPO,
                  totalPrice,
                  masterTujuanPengeluaranId,
                  remarks,
                  namaBankTujuan,
                  namaRekeningTujuan,
                  nomorRekeningTujuan,
                  cabangTujuan,
                  dueDate,
                  masterTujuanPengeluaran,
                  periodStart,
                  periodEnd,
                  branchId,
                } = res.data.result;
                setForm({
                  idTujPengeluaran: masterTujuanPengeluaranId,
                  tujPengeluaran: masterTujuanPengeluaran.tujuanPengeluaran,
                  dueDate: moment(dueDate).toDate(),
                  totalPrice: totalPrice ? totalPrice.toLocaleString() : null,
                  remarks,
                  noRekTujuan: nomorRekeningTujuan,
                  namaRekTujuan: namaRekeningTujuan,
                  namaBankTujuan,
                  cabangTujuan,
                  branchId,
                  periodStartDate: periodStart
                    ? moment(periodStart).toDate()
                    : null,
                  periodEndDate: periodEnd ? moment(periodEnd).toDate() : null,
                });
                setPoNumberForm(code);
                setPoDateForm(moment(tglPO).toDate());
                setpoItemDetailById(res.data.result.poItems);
              })
              .catch((err) => alert(`Terjadi kesalahan system ${err}`));
          })
          .catch((err) => {
            alert(`Terjadi kesalahan system ${err}`);
            setIsLoadingSave(false);
          });
      }
    }
  };

  const handleCreate = () => {
    if (
      form.idTujPengeluaran &&
      form.tujPengeluaran &&
      form.remarks &&
      form.branchId &&
      form.dueDate &&
      form.periodStartDate &&
      form.periodEndDate
    ) {
      if (window.confirm('Are you sure you want to create Purchase Order ?')) {
        const data = {
          masterTujuanPengeluaranId: form.idTujPengeluaran,
          remarks: form.remarks,
          namaBankTujuan: form.namaBankTujuan,
          namaRekeningTujuan: form.namaRekTujuan,
          nomorRekeningTujuan: form.noRekTujuan,
          cabangTujuan: form.cabangTujuan,
          dueDate: form.dueDate,
          periodStart: form.periodStartDate,
          periodEnd: form.periodEndDate,
          branchId: form.branchId,
        };
        dispatch(createPO(data));

        setDetailShow(true);
        setSaveShow(true);
      }
    } else {
      alert('please fill out all required fields');
      if (!form.idTujPengeluaran) setVTujPengeluaran(true);
      if (!form.remarks) setVRemarks(true);
      if (!form.branchId) setVBranch(true);
      if (!form.dueDate) setVDueDate(true);
      if (!form.periodStartDate) setVPeriodDate({ start: true });
      if (!form.periodEndDate) setVPeriodDate({ end: true });
    }
  };

  const handleConfirmAddToGL = (id) => {
    setIsLoadingAddTOGL(true);
    const data = {
      tanggalMutasi: form.mutationDate,
      idBank: bankForm.idBank,
    };
    axios
      .post(`/gl-journal/addToGLJournal/${id}`, data)
      .then((res) => {
        setIsLoadingAddTOGL(false);
        setOpenModal(false);
        alert(`Success add ${poNumberForm} to GL Journal`);
      })
      .catch((err) => {
        setIsLoadingAddTOGL(false);
        setOpenModal(false);
        alert(`Terjadi kesalahan system ${err}`);
      });
  };

  const renderModal = (id) => {
    return (
      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(true)}
        size="md"
        centered
      >
        <ModalBody>
          <div className="text-center">
            {`Are you sure want to add ${poNumberForm}`}
          </div>
          <div className="text-center">to GL Journal with details</div>
          <div className="mt-4">
            <Container>
              <hr />
              <Row>
                <Col sm={6}>From Account Name</Col>
                <Col sm={6}>: {bankForm.fromAccountName}</Col>
              </Row>
              <hr />
              <Row>
                <Col sm={6}>From Account Number</Col>
                <Col sm={6}>: {bankForm.fromAccNumber}</Col>
              </Row>
              <hr />
              <Row>
                <Col sm={6}>From Bank Name</Col>
                <Col sm={6}>: {bankForm.fromBankName}</Col>
              </Row>
              <hr />
              <Row>
                <Col sm={6}>Mutation Date</Col>
                <Col sm={6}>
                  : {moment(form.mutationDate).format('DD/MM/YYYY')}
                </Col>
              </Row>
              <hr />
            </Container>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            style={{ backgroundColor: '#32b280' }}
            onClick={() => handleConfirmAddToGL(id)}
          >
            {isLoadingAddToGL ? <Spinner size="sm" color="white" /> : 'Yes'}
          </Button>
          <Button
            color="danger"
            onClick={() => {
              setAddToGlClicked(false);
              setIsLoadingAddToGlClicked(false);
              setOpenModal(false);
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const handleLiquid = (id) => {
    if (bankForm.idBank && form.mutationDate) {
      if (window.confirm(`Are you sure want to release ${poNumberForm} ?`)) {
        setIsLoadingLiquid(true);
        const data = {
          tanggalMutasi: form.mutationDate,
          idBank: bankForm.idBank,
        };
        axios
          .post(`/poHeader/poToLiquid/${id}`, data)
          .then((res) => {
            alert(`${poNumberForm} has been released`);
            setIsLoadingLiquid(false);
          })
          .catch((err) => {
            alert(`Terjadi Kesalahan System ${err}`);
            setIsLoadingLiquid(false);
          });
      }
    } else {
      if (!bankForm.idBank && !form.mutationDate)
        alert('Please input Bank ID and Mutation Date');
      if (!bankForm.idBank && form.mutationDate) {
        alert('Please input ID Bank');
      }
      if (!form.mutationDate && bankForm.idBank) {
        alert('Please input Mutation Date');
      }
    }
  };

  const handleEditMutation = (id) => {
    setIsLoadingEditMutation(true);
    const data = {
      mutationDate: form.mutationDate,
    };
    axios
      .put(`/poHeader/editMutationDate/${id}`, data)
      .then((res) => {
        setIsLoadingEditMutation(false);
        alert('Edit Mutation Date Success');
      })
      .catch((err) => {
        setIsLoadingEditMutation(false);
        alert(`Terjadi kesalahan system ${err}`);
      });
  };

  const handleAddToJournal = () => {
    if (bankForm.idBank && form.mutationDate) {
      setOpenModal(true);
      setAddToGlClicked(true);
    } else {
      if (!bankForm.idBank && !form.mutationDate)
        alert('Please input Bank ID and Mutation Date');
      if (!bankForm.idBank && form.mutationDate) {
        alert('Please input ID Bank');
      }
      if (!form.mutationDate && bankForm.idBank) {
        alert('Please input Mutation Date');
      }
    }
  };

  const {
    idTujPengeluaran,
    dueDate,
    // poDate,
    // purchaseOrder,
    noRekTujuan,
    namaRekTujuan,
    // totalPrice,
    namaBankTujuan,
    cabangTujuan,
    remarks,
    // idBank,
    mutationDate,
    branchId,
    periodStartDate,
    periodEndDate,
  } = form;

  return (
    <Card>
      <CardBody>
        <Form className="mt-2">
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Tujuan Pengeluaran</Label>
                <Col sm={7}>
                  <Input
                    className="is-invalid-edit"
                    type="select"
                    value={idTujPengeluaran}
                    invalid={vTujPengeluaran}
                    onChange={handleTujuanPengeluaran}
                    disabled={readOnly}
                  >
                    <option value={0} hidden>
                      -PILIH TUJUAN PENGELUARAN-
                    </option>
                    {renderTujuanPengeluaran()}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Nomor Rekening Tujuan</Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    className="is-invalid-edit"
                    // invalid={vNoRekTujuan}
                    value={noRekTujuan}
                    disabled={readOnly}
                    onChange={(e) =>
                      setForm({ ...form, noRekTujuan: e.target.value })
                    }
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>PO Number</Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    className="is-invalid-edit"
                    value={poNumberForm}
                    disabled
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Nama Rekening Tujuan</Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    className="is-invalid-edit"
                    // invalid={vNamaRekTujuan}
                    value={namaRekTujuan}
                    disabled={readOnly}
                    onChange={(e) =>
                      setForm({ ...form, namaRekTujuan: e.target.value })
                    }
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>PO Date</Label>
                <Col sm={7}>
                  <DatePicker
                    dateFormat="dd/MM/yyy"
                    className="form-control date-picker-pym"
                    selected={poDateForm}
                    disabled
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Nama Bank Tujuan</Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    className="is-invalid-edit"
                    // invalid={vNamaBankTujuan}
                    value={namaBankTujuan}
                    disabled={readOnly}
                    onChange={(e) =>
                      setForm({ ...form, namaBankTujuan: e.target.value })
                    }
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Total Price</Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    className="is-invalid-edit"
                    placeholder="Rp 0"
                    value={`Rp ${
                      totalPriceForm ? totalPriceForm.toLocaleString() : 0
                    }`}
                    disabled
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Cabang Tujuan</Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    className="is-invalid-edit"
                    value={cabangTujuan}
                    disabled={readOnly}
                    onChange={(e) =>
                      setForm({ ...form, cabangTujuan: e.target.value })
                    }
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Due Date</Label>
                <Col sm={7}>
                  <DatePicker
                    dateFormat="dd/MM/yyy"
                    className="form-control date-picker-pym"
                    selected={dueDate}
                    disabled={readOnly}
                    onChange={handleDueDate}
                  />
                  {vDueDate && (
                    <div style={{ color: 'red' }}>Due date can't be empty</div>
                  )}
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Remarks</Label>
                <Col sm={7}>
                  <Input
                    type="textarea"
                    name="remarks"
                    value={remarks}
                    disabled={readOnly}
                    invalid={vRemarks}
                    onChange={(e) =>
                      setForm({ ...form, remarks: e.target.value })
                    }
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          {addToLiquid ? (
            <Row form>
              <Col sm={6} md={6}>
                <FormGroup row>
                  <Label sm={4}>Choose Bank</Label>
                  <Col sm={7}>
                    <Input
                      className="is-invalid-edit"
                      type="select"
                      value={bankForm.idBank}
                      disabled={readOnlyFromEditMutation || editMutation}
                      onChange={handleBank}
                    >
                      <option value={0} hidden>
                        -PILIH BANK-
                      </option>
                      {renderBank()}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
              <Col sm={6} md={6}>
                <FormGroup row>
                  <Label sm={4}>Mutation Date</Label>
                  <Col sm={7}>
                    <DatePicker
                      dateFormat="dd/MM/yyy"
                      className="form-control date-picker-pym"
                      onChange={hanldeMutationDate}
                      disabled={readOnlyFromEditMutation}
                      selected={mutationDate}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          ) : null}
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Period Start Date</Label>
                <Col sm={7}>
                  <DatePicker
                    dateFormat="dd/MM/yyy"
                    className="form-control date-picker-pym is-invalid-edit"
                    selected={periodStartDate}
                    disabled={readOnly}
                    onChange={handlePeriodStartDate}
                  />
                  {vPeriodDate.start && (
                    <div style={{ color: 'red' }}>
                      Period start date can't be empty
                    </div>
                  )}
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Branch</Label>
                <Col sm={7}>
                  <Input
                    className="is-invalid-edit"
                    type="select"
                    value={branchId}
                    invalid={vBranch}
                    onChange={(e) =>
                      setForm({ ...form, branchId: e.target.value })
                    }
                    disabled={readOnly}
                  >
                    <option value={0} hidden>
                      -PILIH CABANG-
                    </option>
                    {branches.map((branch) => (
                      <option key={branch.id} value={branch.id} s>
                        {branch.name}
                      </option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col sm={6} md={6}>
              <FormGroup row>
                <Label sm={4}>Period End Date</Label>
                <Col sm={7}>
                  <DatePicker
                    dateFormat="dd/MM/yyy"
                    className="form-control date-picker-pym"
                    selected={periodEndDate}
                    disabled={readOnly}
                    onChange={handlePeriodEndDate}
                  />
                  {vPeriodDate.end && (
                    <div style={{ color: 'red' }}>
                      Period end date can't be empty
                    </div>
                  )}
                </Col>
              </FormGroup>
            </Col>
          </Row>

          {renderModal(selectedId)}
        </Form>
        <Collapse isOpen={detailShow || poDetailShow}>
          <Table striped bordered responsive hover>
            <thead
              className="font-weight-bold"
              style={{ fontSize: '14px', color: '#505050' }}
            >
              <tr>
                <th>#</th>
                <th>Details</th>
                <th>Price</th>
                <th>Proof</th>
                {!readOnly ? <th className="text-center">Action</th> : null}
              </tr>
            </thead>
            <TablePOData
              poId={dataPO.id}
              selectedId={selectedId}
              poItemDetailById={poItemDetailById}
              setpoItemDetailById={setpoItemDetailById}
              readOnly={readOnly}
              setPoItems={setPoItems}
              poItems={poItems}
              vDetails={vDetails}
              setVDetails={setVDetails}
              vPrice={vPrice}
              setVPrice={setVPrice}
            />
          </Table>
        </Collapse>
      </CardBody>
      <div
        className="d-flex flex-row-reverse"
        style={{
          marginTop: '-39px',
          right: '0%',
          position: 'absolute',
        }}
      >
        <Button
          className="btn-add-pym ml-1"
          color="success"
          size="md"
          style={{
            backgroundColor: '#32b280',
            width: '100px',
          }}
          onClick={handleNew}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-1" /> New
        </Button>
        {!saveShow && !readOnly ? (
          <Button
            className="btn-add-pym"
            color="secondary"
            size="md"
            style={{
              width: '100px',
            }}
            onClick={handleCreate}
          >
            {gStatus === 'loading' ? (
              <Spinner size="sm" color="white" />
            ) : (
              <span>
                <FontAwesomeIcon icon={faSave} className="mr-1" />
                Save
              </span>
            )}
          </Button>
        ) : !readOnly ? (
          <Button
            className="btn-add-pym"
            color="secondary"
            size="md"
            style={{
              width: '100px',
            }}
            onClick={() => handleSave(selectedId, insertId.id)}
          >
            {isLoadingSave ? (
              <Spinner size="sm" color="white" />
            ) : (
              <span>
                <FontAwesomeIcon icon={faSave} className="mr-1" /> Add
              </span>
            )}
          </Button>
        ) : btnAddJournalShow ? (
          <Button
            style={{ width: '100px' }}
            onClick={() => handleAddToJournal(selectedId)}
          >
            {isLoadingAddToGlClicked ? (
              <Spinner size="sm" color="white" />
            ) : (
              <span>
                <FontAwesomeIcon icon={faFileAlt} className="mr-1" />
                Add GL
              </span>
            )}
          </Button>
        ) : editMutation ? (
          <Button
            style={{ width: '160px' }}
            onClick={() => handleEditMutation(selectedId)}
          >
            {isLoadingEditMutation ? (
              <Spinner size="sm" color="white" />
            ) : (
              'Edit Mutation Date'
            )}
          </Button>
        ) : btnLiquidShow ? (
          <Button
            style={{ width: '100px' }}
            onClick={() => handleLiquid(selectedId)}
          >
            {isLoadingLiquid ? (
              <Spinner size="sm" color="white" />
            ) : (
              <span>
                <FontAwesomeIcon icon={faDollarSign} className="mr-1" /> Liquid
              </span>
            )}
          </Button>
        ) : null}
      </div>
    </Card>
  );
};

export default PODataTab;
