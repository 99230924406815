import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Table, Modal, Button, CardBody, Card, Spinner } from 'reactstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGLJournalList, selectGl } from 'redux/modules/glJournal';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'supports/api';
// import Loader from 'react-loader-spinner';
import CustomPagination from '../../../components/commons/CustomPagination';
import SearchBar from '../../../components/GLJournal/SearchBar';

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th>Journal Type</th>
        <th>Ref. Number</th>
        <th>Journal Date</th>
        <th>Total Debit</th>
        <th>Total Credit</th>
        <th>Remarks</th>
        <th>Action</th>
      </tr>
    </thead>
  );
};

const TableData = ({
  journals = [],
  page = 1,
  rowsPerPage = 1,
  toggleTab,
  journalDateFrom,
  journalDateTo,
}) => {
  const [isLoadingDelete, setIsLoadingdelete] = useState(false);
  const [idLoadingDelete, setIdLoadingDelete] = useState(null);
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);
  const [idLoadingProcess, setIdLoadingProcess] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = (id, num) => {
    if (
      window.confirm(
        `Apakah Anda yakin ingin menghapus GL dengan Reference Number ${num} ?`,
      )
    ) {
      setIsLoadingdelete(true);
      setIdLoadingDelete(id);

      Axios.delete(`/gl-journal/delete-gl/${id}`)
        .then((res) => {
          dispatch(
            fetchGLJournalList({
              journalDateFrom,
              journalDateTo,
            }),
          );
          setIsLoadingdelete(false);
          setIdLoadingDelete(null);
          window.alert(res.data.message);
        })
        .catch((err) => {
          setIsLoadingdelete(false);
          setIdLoadingDelete(null);
          window.alert(err);
        });
    }
  };

  const handleProcess = (id, num, debit, credit) => {
    if (debit === credit) {
      if (
        window.confirm(
          `Apakah Anda yakin ingin memproses GL dengan Reference Number ${num} ?`,
        )
      ) {
        setIsLoadingProcess(true);
        setIdLoadingProcess(id);
        Axios.put(`/gl-journal/approve-by-id/${id}`)
          .then((res) => {
            dispatch(
              fetchGLJournalList({
                journalDateFrom,
                journalDateTo,
              }),
            );
            setIsLoadingProcess(false);
            setIdLoadingProcess(null);
            window.alert(res.data.message);
          })
          .catch((err) => {
            setIsLoadingProcess(false);
            setIdLoadingProcess(null);
            window.alert(err);
          });
      }
    } else {
      window.alert('Debit & Credit Are Not Balances !');
    }
  };

  const arrJSX = journals
    .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
    .map((row) => {
      return (
        <tr key={row.id}>
          <td>{row.journalType}</td>
          <td>{row.referenceNumber}</td>
          <td>{moment(row.journalDate).format('DD/MM/YYYY')}</td>
          <td>
            <div className="float-left">Rp.</div>
            <div className="text-right">{row.totalDebit.toLocaleString()}</div>
          </td>
          <td>
            <div className="float-left">Rp.</div>
            <div className="text-right">{row.totalCredit.toLocaleString()}</div>
          </td>
          <td>{row.remarks}</td>
          <td>
            <div className="d-flex flex-column align-items-center">
              <Button
                className="btn btn-danger action-button"
                size="sm"
                onClick={() => {
                  handleDelete(row.id, row.referenceNumber);
                }}
              >
                {isLoadingDelete && idLoadingDelete === row.id ? (
                  <Spinner size="sm" color="white" />
                ) : (
                  'Delete'
                )}
              </Button>
              <Button
                className="btn btn-info action-button"
                size="sm"
                onClick={() => {
                  window.scrollTo(0, 0);
                  toggleTab('2');
                  dispatch(selectGl(row));
                }}
              >
                Detail
              </Button>
              <Button
                className="btn btn-success action-button"
                size="sm"
                onClick={() => {
                  handleProcess(
                    row.id,
                    row.referenceNumber,
                    row.totalDebit,
                    row.totalCredit,
                  );
                }}
              >
                {isLoadingProcess && idLoadingProcess === row.id ? (
                  <Spinner size="sm" color="white" />
                ) : (
                  'Process'
                )}
              </Button>
            </div>
          </td>
        </tr>
      );
    });

  if (arrJSX.length === 0) {
    return (
      <tbody>
        <tr>
          <td colSpan={7}>
            <div className="d-flex justify-content-center">
              No items Found, Try Another Query
            </div>
          </td>
        </tr>
      </tbody>
    );
  }

  return <tbody>{arrJSX}</tbody>;
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const date = new Date();

const GLJournalList = ({ toggleTab }) => {
  const [searchParams, setSearchParams] = useState('');
  const [journalType, setJournalType] = useState('');
  const [journalDateFrom, setJournalDateFrom] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  // const [journalDateFrom, setJournalDateFrom] = useState(
  //   new Date(date.getFullYear(), date.getMonth() - 4, 1),
  // );
  const [journalDateTo, setJournalDateTo] = useState(date);
  const [journalStatus, setJournalStatus] = useState('0');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [processAllLoading, setProcessAllLoading] = useState(false);

  const history = useHistory();
  const params = useQuery();
  const page = params.get('page');
  const dispatch = useDispatch();

  const glJournals = useSelector((state) => state.glJournal.list);
  // const loading = useSelector((state) => state.glJournal.loading);
  const glStatus = useSelector((state) => state.glJournal.status);

  const redirectPage = useCallback(() => {
    if (page > Math.ceil(glJournals.length / rowsPerPage) || !page) {
      history.push('?page=1');
    }
  }, [glJournals.length, history, page, rowsPerPage]);

  // runs every render
  useEffect(() => {
    redirectPage();
  }, [redirectPage]);

  const filterBySearchParams = useCallback(() => {
    let filtered = glJournals;
    if (journalDateFrom || journalDateTo) {
      filtered = filtered.filter((e) => {
        return (
          moment(e.journalDate).format('YYYY-MM-DD') >=
            moment(journalDateFrom).format('YYYY-MM-DD') &&
          moment(e.journalDate).format('YYYY-MM-DD') <=
            moment(journalDateTo).format('YYYY-MM-DD')
        );
      });
    }
    if (searchParams) {
      filtered = filtered.filter((item) => {
        return item.referenceNumber
          .toLowerCase()
          .includes(searchParams.toLowerCase());
      });
    }
    if (journalType) {
      filtered = filtered.filter((item) => {
        return item.journalType
          .toLowerCase()
          .includes(journalType.toLowerCase());
      });
    }
    if (journalStatus) {
      filtered = filtered.filter((item) => {
        if (Number(journalStatus)) {
          return item.isApproved;
        }
        return !item.isApproved;
      });
    }
    // setFilteredGl(filtered);
    return filtered;
  }, [
    glJournals,
    searchParams,
    journalType,
    journalDateFrom,
    journalDateTo,
    journalStatus,
  ]);

  const handleSearchParams = (e) => {
    history.push('?page=1');
    setSearchParams(e.target.value);
  };

  const handleStartDate = (pickedDate) => {
    history.push('?page=1');
    setJournalDateFrom(pickedDate);
  };

  const handleEndDate = (pickedDate) => {
    history.push('?page=1');
    setJournalDateTo(pickedDate);
  };

  const handleStatus = (e) => {
    history.push('?page=1');
    setJournalStatus(e.target.value);
  };

  const onHandleJournalType = (e) => {
    history.push('?page=1');
    setJournalType(e.target.value);
  };

  const handleFetch = () => {
    dispatch(
      fetchGLJournalList({
        journalDateFrom,
        journalDateTo,
        isApproved: journalStatus,
      }),
    );
  };

  const handleApproveAll = () => {
    const filteredGLJournals = filterBySearchParams();
    if (filteredGLJournals.length === 0) {
      return window.alert('Error. No Item To Confirm.');
    }
    if (
      window.confirm(
        `Apakah anda yakin ingin menyetujui semua list GL dibawah?
        ${filteredGLJournals.length} GL item(s)`,
      )
    ) {
      setProcessAllLoading(true);

      Axios.put(`/gl-journal/approve-all`, { glJournals: filteredGLJournals })
        .then((res) => {
          setProcessAllLoading(false);
          dispatch(
            fetchGLJournalList({
              journalDateFrom,
              journalDateTo,
            }),
          );
          window.alert(res.data.message);
        })
        .catch((err) => {
          setProcessAllLoading(false);
          window.alert(`Terjadi kesalahan pada system. ${err}`);
        });
    }
  };

  return (
    <Card>
      <CardBody>
        <SearchBar
          searchParams={searchParams}
          handleSearchParams={handleSearchParams}
          onHandleJournalType={onHandleJournalType}
          journalType={journalType}
          handleFetch={handleFetch}
          handleStartDate={handleStartDate}
          journalDateFrom={journalDateFrom}
          handleEndDate={handleEndDate}
          journalDateTo={journalDateTo}
          handleStatus={handleStatus}
          journalStatus={journalStatus}
        />
        {glJournals.length || glStatus === 'success' ? (
          <div className="flex-fill">
            <div className="d-flex flex-row mt-3 mb-1">
              <div>
                Total Items: {glJournals.length} item(s) fetched from database,{' '}
                {filterBySearchParams().length} displayed.
              </div>
              <div className="ml-auto mr-1 align-self-center">Rows:</div>
              <select
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  history.push('?page=1');
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <div className="ml-2 align-self-center">
                <Button size="sm" onClick={handleApproveAll}>
                  <span className="mr-2">Process All</span>
                  {processAllLoading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <FontAwesomeIcon
                      style={{ fontSize: '1.4em', cursor: 'pointer' }}
                      icon={faClipboardCheck}
                    />
                  )}
                </Button>
              </div>
            </div>
            <div>
              <Modal isOpen={false}>a</Modal>
            </div>
            <Table striped bordered responsive hover>
              <TableHeader />
              <TableData
                journals={filterBySearchParams()}
                rowsPerPage={rowsPerPage}
                page={page}
                toggleTab={toggleTab}
                journalDateFrom={journalDateFrom}
                journalDateTo={journalDateTo}
              />
            </Table>
            <CustomPagination
              pages={Math.ceil(filterBySearchParams().length / rowsPerPage)}
              active={page}
            />
          </div>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default GLJournalList;
