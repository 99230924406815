import React, { useState, useEffect } from 'react';
import axios from 'supports/api';
import moment from 'moment';
import CustomPagination from 'components/commons/CustomPagination';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  fetchPOList,
  fetchPO,
  fetchBranches,
} from 'redux/modules/purchaseOrder';
import { Table, Card, CardBody, Button, Badge, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Filter, PoSkeleton } from '../components';
import ModalUploadPOProof from '../components/ModalUploadPOProof';
import ModalPreviewPOProof from '../components/ModalPreviewPOProof';

const POList = (props) => {
  const {
    active,
    activeTab,
    click,
    setSelectedId,
    setReadOnly,
    setpoDetailShow,
    setSaveShow,
    setAddToLiquid,
    setBtnAddJournalShow,
    setReadOnlyFromEditMutation,
    setEditMutation,
    setBtnLiquidShow,
    setAddToJournal,
    setSearch,
    search,
  } = props;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const page = params.get('page');
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);
  const [idLoadingProcess, setIdLoadingProcess] = useState(null);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [idLoadingCancel, setIdLoadingCancel] = useState(null);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [idLoadingApprove, setIdLoadingApprove] = useState(null);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const [idLoadingReject, setIdLoadingReject] = useState(null);
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const [idLoadingPrint, setIdLoadingPrint] = useState(null);

  // selected po row
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [open, setOpen] = useState(false);

  // selected po proof to be shown in the modal
  const [selectedProofData, setSelectedProofData] = useState('');
  const [openPreview, setOpenPreview] = useState(false);

  const dispatch = useDispatch();

  const { isFinanceDepartment } = useSelector((state) => state.auth);
  const data = useSelector(({ purchaseOrder }) => purchaseOrder.PO);
  const countData = useSelector(({ purchaseOrder }) => purchaseOrder.countPO);
  const loadStatus = useSelector(({ purchaseOrder }) => purchaseOrder.status);

  useEffect(() => {
    dispatch(fetchBranches());
  }, [dispatch]);

  useEffect(() => {
    if (!page || (Math.ceil(countData / 10) < page && countData !== 0)) {
      history.push('?page=1');
    }
  }, [page, countData, history]);

  // handle upload po proof modal
  const handleOpenModal = (id, type) => {
    if (type === 'open') {
      setSelectedRowId(id);
      setOpen(true);
    }

    if (type === 'close') {
      setSelectedRowId(0);
      setSelectedProofData('');
      setOpen(false);
    }
  };

  // handle preview po proof modal
  const handleOpenPreview = (path, type) => {
    if (type === 'open') {
      setSelectedProofData(path);
      setOpenPreview(true);
    }

    if (type === 'close') {
      setSelectedProofData('');
      setOpenPreview(false);
    }
  };

  const handleProcess = (id, code) => {
    if (window.confirm(`Are you sure want to process ${code} ?`)) {
      setIsLoadingProcess(true);
      setIdLoadingProcess(id);

      axios
        .post(`/poHeader/poToProcess/${id}`)
        .then((res) => {
          dispatch(fetchPOList(page, false, false, false, false));
          setIsLoadingProcess(false);
          setIdLoadingProcess(null);
        })
        .catch((err) => {
          alert(`Terjadi Kesalahan System ${err}`);
          setIsLoadingProcess(false);
          setIdLoadingProcess(null);
        });
    }
  };

  const handleCancel = (id, code) => {
    if (window.confirm(`Are you sure want to cancel ${code} ?`)) {
      setIsLoadingCancel(true);
      setIdLoadingCancel(id);

      axios
        .post(`/poHeader/poToCancel/${id}`)
        .then((res) => {
          dispatch(fetchPOList(page, false, false, false, false));
          setIsLoadingCancel(false);
          setIdLoadingCancel(null);
        })
        .catch((err) => {
          alert(`Terjadi Kesalahan System ${err}`);
          setIsLoadingCancel(false);
          setIdLoadingCancel(null);
        });
    }
  };

  const handleApprove = (id, code) => {
    if (window.confirm(`Are you sure want to approve ${code} ?`)) {
      setIsLoadingApprove(true);
      setIdLoadingApprove(id);

      axios
        .post(`/poHeader/poToApprove/${id}`)
        .then((res) => {
          dispatch(fetchPO(page, true, false, false, false));
          setIsLoadingApprove(false);
          setIdLoadingApprove(null);
        })
        .catch((err) => {
          alert(`Terjadi Kesalahan System ${err}`);
          setIsLoadingApprove(false);
          setIdLoadingApprove(null);
        });
    }
  };

  const handleReject = (id, code) => {
    if (window.confirm(`Are you sure want to reject ${code} ?`)) {
      setIsLoadingReject(true);
      setIdLoadingReject(id);

      axios
        .post(`/poHeader/poToReject/${id}`, {})
        .then((res) => {
          dispatch(fetchPO(page, true, false, false, false));
          setIsLoadingReject(false);
          setIdLoadingReject(null);
        })
        .catch((err) => {
          alert(`Terjadi Kesalahan System ${err}`);
          setIsLoadingReject(false);
          setIdLoadingReject(null);
        });
    }
  };

  const handlePrint = (id) => {
    setIsLoadingPrint(true);
    setIdLoadingPrint(id);

    axios
      .get(`/poHeader/print/${id}`)
      .then((res) => {
        window.open(`${process.env.REACT_APP_BASE_URL}/poHeader/print/${id}`);
        setIsLoadingPrint(false);
        setIdLoadingPrint(null);
      })
      .catch((err) => {
        alert(`Terjadi Kesalahan System ${err}`);
        setIsLoadingPrint(false);
        setIdLoadingPrint(null);
      });
  };

  // const handleLiquid = (id) => {
  //   setIsLoadingLiquid(true);
  //   setIdLoadingLiquid(id);
  //   axios
  //     .post(`/poHeader/poToLiquid/${id}`)
  //     .then((res) => {
  //       dispatch(fetchPO(page, true, false, true, false));
  //       setIsLoadingLiquid(false);
  //       setIdLoadingLiquid(null);
  //     })
  //     .catch((err) => {
  //       alert(`Terjadi Kesalahan System ${err}`);
  //       setIsLoadingLiquid(false);
  //       setIdLoadingLiquid(null);
  //     });
  // };

  const handleButton = (id, isCancel, isReject, code) => {
    if (active === 'PO List' || activeTab === 'PO List') {
      return (
        <div className="d-flex flex-column align-items-center">
          {isFinanceDepartment ? (
            <>
              {isCancel || isReject ? (
                <Button
                  color="success"
                  size="sm"
                  style={{
                    width: '90px',
                    backgroundColor: 'rgb(50, 178, 128)',
                  }}
                  onClick={() => handleProcess(id, code)}
                >
                  {isLoadingProcess && idLoadingProcess === id ? (
                    <Spinner size="sm" color="white" />
                  ) : (
                    'Re-Process'
                  )}
                </Button>
              ) : (
                <Button
                  color="success"
                  size="sm"
                  style={{
                    width: '90px',
                    backgroundColor: 'rgb(50, 178, 128)',
                  }}
                  onClick={() => handleProcess(id, code)}
                >
                  {isLoadingProcess && idLoadingProcess === id ? (
                    <Spinner size="sm" color="white" />
                  ) : (
                    'Process'
                  )}
                </Button>
              )}
            </>
          ) : null}

          {isCancel || isReject ? null : (
            <Button
              className="mt-1"
              color="danger"
              size="sm"
              style={{ width: '90px' }}
              onClick={() => handleCancel(id, code)}
            >
              {isLoadingCancel && idLoadingCancel === id ? (
                <Spinner size="sm" color="white" />
              ) : (
                'Cancel'
              )}
            </Button>
          )}
          <Button
            className="mt-1"
            color="secondary"
            size="sm"
            style={{ width: '90px' }}
            onClick={() => {
              click();
              setSelectedId(id);
              setReadOnly(false);
              setpoDetailShow(true);
              setSaveShow(true);
            }}
          >
            Details
          </Button>
          <Button
            color="primary"
            className="mt-1"
            size="sm"
            style={{
              width: '90px',
            }}
            onClick={() => handlePrint(id)}
          >
            {isLoadingPrint && idLoadingPrint === id ? (
              <Spinner size="sm" color="white" />
            ) : (
              'Print'
            )}
          </Button>
        </div>
      );
    }
    if (active === 'PO Approval') {
      return (
        <div className="d-flex flex-column align-items-center">
          <Button
            color="success"
            size="sm"
            style={{
              width: '90px',
              backgroundColor: 'rgb(50, 178, 128)',
            }}
            onClick={() => handleApprove(id, code)}
          >
            {isLoadingApprove && idLoadingApprove === id ? (
              <Spinner size="sm" color="white" />
            ) : (
              'Approve'
            )}
          </Button>
          <Button
            className="mt-1"
            color="danger"
            size="sm"
            style={{ width: '90px' }}
            onClick={() => handleReject(id, code)}
          >
            {isLoadingReject && idLoadingReject === id ? (
              <Spinner size="sm" color="white" />
            ) : (
              'Reject'
            )}
          </Button>
          <Button
            className="mt-1"
            color="secondary"
            size="sm"
            style={{ width: '90px' }}
            onClick={() => {
              click();
              setSelectedId(id);
              setReadOnly(true);
              setpoDetailShow(true);
            }}
          >
            Details
          </Button>
          <Button
            color="primary"
            className="mt-1"
            size="sm"
            style={{
              width: '90px',
            }}
            onClick={() => handlePrint(id)}
          >
            {isLoadingPrint && idLoadingPrint === id ? (
              <Spinner size="sm" color="white" />
            ) : (
              'Print'
            )}
          </Button>
        </div>
      );
    }
    if (active === 'PO Pencairan') {
      return (
        <div className="d-flex flex-column align-items-center">
          <Button
            color="success"
            size="sm"
            style={{ width: '90px', backgroundColor: 'rgb(50, 178, 128)' }}
            onClick={() => {
              click();
              setSelectedId(id);
              setReadOnly(true);
              setAddToLiquid(true);
              setBtnLiquidShow(true);
            }}
          >
            Liquid
          </Button>
          <Button
            color="primary"
            className="mt-1"
            size="sm"
            style={{
              width: '90px',
            }}
            onClick={() => handlePrint(id)}
          >
            {isLoadingPrint && idLoadingPrint === id ? (
              <Spinner size="sm" color="white" />
            ) : (
              'Print'
            )}
          </Button>
          <Button
            className="mt-1"
            color="secondary"
            size="sm"
            style={{ width: '90px' }}
            onClick={() => {
              click();
              setSelectedId(id);
              setReadOnly(true);
              setpoDetailShow(true);
            }}
          >
            Details
          </Button>

          {/* only users with finance department role can upload po proof */}
          {isFinanceDepartment && (
            <Button
              color="primary"
              className="mt-1"
              size="sm"
              style={{
                width: '90px',
              }}
              onClick={() => handleOpenModal(id, 'open')}
            >
              Upload
            </Button>
          )}
        </div>
      );
    }
    if (active === 'PO Finalization') {
      return (
        <div className="d-flex flex-column align-items-center">
          <Button
            color="success"
            size="sm"
            style={{ width: '90px', backgroundColor: 'rgb(50, 178, 128)' }}
            onClick={() => {
              click();
              setSelectedId(id);
              setAddToLiquid(true);
              setReadOnly(true);
              setpoDetailShow(true);
              setBtnAddJournalShow(true);
              setAddToJournal(true);
            }}
          >
            Add To GL Journal
          </Button>
          <Button
            className="mt-1"
            color="secondary"
            size="sm"
            style={{ width: '90px' }}
            onClick={() => {
              click();
              setSelectedId(id);
              setAddToLiquid(true);
              setReadOnly(true);
              setpoDetailShow(true);
              setReadOnlyFromEditMutation(true);
            }}
          >
            Details
          </Button>
        </div>
      );
    }
    if (active === 'Edit Mutation Date') {
      return (
        <div className="d-flex flex-column align-items-center">
          <Button
            color="success"
            size="sm"
            style={{ width: '90px', backgroundColor: 'rgb(50, 178, 128)' }}
            onClick={() => {
              click();
              setSelectedId(id);
              setReadOnly(true);
              setpoDetailShow(true);
              setAddToLiquid(true);
              setEditMutation(true);
            }}
          >
            Edit
          </Button>
          <Button
            className="mt-1"
            color="secondary"
            size="sm"
            style={{ width: '90px' }}
            onClick={() => {
              click();
              setSelectedId(id);
              setAddToLiquid(true);
              setReadOnly(true);
              setpoDetailShow(true);
              setReadOnlyFromEditMutation(true);
            }}
          >
            Details
          </Button>
        </div>
      );
    }
  };

  const handleStatus = (isReject, isCancel) => {
    let status;
    if (active === 'PO List' || activeTab === 'PO List') {
      if (!isReject && !isCancel) {
        status = 'Pending';
      } else if (isCancel && !isReject) {
        status = 'Cancelled';
      } else {
        status = 'Rejected';
      }
    } else if (active === 'PO Approval') {
      status = 'Process';
    } else if (active === 'PO Pencairan') {
      status = 'Approved';
    } else if (active === 'PO Finalization') {
      status = 'Liquid';
    } else if (active === 'Edit Mutation Date') {
      status = 'Added To GL Journal';
    }
    return status;
  };

  const handleBadge = (isReject, isCancel) => {
    let color;
    if (active === 'PO List' || activeTab === 'PO List') {
      if (!isReject && !isCancel) {
        color = 'warning';
      } else if (isCancel && !isReject) {
        color = 'secondary';
      } else {
        color = 'danger';
      }
    } else if (active === 'PO Approval') {
      color = 'success';
    } else if (active === 'PO Pencairan') {
      color = 'info';
    } else if (active === 'PO Finalization') {
      color = 'primary';
    } else if (active === 'Edit Mutation Date') {
      color = 'secondary';
    }
    return color;
  };

  const renderTable = () => {
    return data?.map((val) => {
      const {
        id,
        code,
        tglPO,
        totalPrice,
        remarks,
        createdBy,
        isCancel,
        isReject,
        tujuanPengeluaran,
        updatedBy,
        proof,
        periodStart,
        periodEnd,
        branchName,
      } = val;

      if (loadStatus === 'loading') {
        return <PoSkeleton active={active} />;
      }
      return (
        <tr key={id}>
          <td className="text-center" style={{ width: '8.56rem' }}>
            <div>
              <Badge
                className="mt-1"
                color={handleBadge(isReject, isCancel)}
                pill
                style={{ fontSize: '14px' }}
              >
                {handleStatus(isReject, isCancel)}
              </Badge>
              <p className="main-text mt-2" style={{ fontSize: '12px' }}>
                {createdBy}
              </p>
            </div>
          </td>
          <td className="text-center" style={{ width: '9.375rem' }}>
            <div>
              <p className="main-text">{code}</p>
              <span style={{ fontSize: '12px' }}>
                {moment(tglPO).format('DD-MMM-YYYY HH:mm')} WIB
              </span>
            </div>
          </td>
          <td style={{ width: '11.562rem' }}>{tujuanPengeluaran}</td>
          {(active === 'PO List' ||
            active === 'PO Approval' ||
            active === 'PO Pencairan' ||
            active === 'PO Finalization') && (
            <>
              <td style={{ width: '9.375rem' }}>{branchName}</td>
              <td style={{ width: '8.56rem' }}>
                {periodStart ? moment(periodStart).format('DD-MMM-yyy') : 'N/A'}
              </td>
              <td style={{ width: '8.56rem' }}>
                {periodEnd ? moment(periodEnd).format('DD-MMM-yyy') : 'N/A'}
              </td>
            </>
          )}
          <td style={{ width: '8.125rem' }}>
            Rp {totalPrice ? totalPrice.toLocaleString() : null}
          </td>
          {active === 'PO Pencairan' ? (
            <td style={{ width: '8.4375rem' }}>{updatedBy}</td>
          ) : null}
          {active === 'PO Finalization' ? (
            <td style={{ width: '8.4375rem' }}>{updatedBy}</td>
          ) : null}
          {active === 'Edit Mutation Date' ? (
            <td style={{ width: '8.4375rem' }}>{updatedBy}</td>
          ) : null}
          <td>{remarks}</td>
          {(active === 'PO Pencairan' || active === 'PO History') && (
            <td style={{ width: '4rem' }}>
              {proof ? (
                <Button
                  size="sm"
                  onClick={() => handleOpenPreview(proof, 'open')}
                >
                  <FontAwesomeIcon icon={['fa', 'image']} />
                </Button>
              ) : (
                <em>N/A</em>
              )}
            </td>
          )}
          <td className="text-center" style={{ width: '7.25rem' }}>
            {handleButton(id, isCancel, isReject, code)}
          </td>
        </tr>
      );
    });
  };

  return (
    <Card>
      <CardBody>
        <Filter
          active={active}
          page={page}
          activeTab={activeTab}
          setSearch={setSearch}
          search={search}
        />
        <CustomPagination
          className="mt-5"
          pages={Math.ceil(countData / 10 || 1)}
          active={page}
        />
        <Table striped bordered responsive hover>
          <thead
            className="font-weight-bold"
            style={{ fontSize: '16px', color: '#505050' }}
          >
            <tr>
              <th>Status</th>
              <th>PO Number</th>
              <th>Tujuan Pengeluaran</th>
              {(active === 'PO List' ||
                active === 'PO Approval' ||
                active === 'PO Pencairan' ||
                active === 'PO Finalization') && (
                <>
                  <th>Branch</th>
                  <th>Period Start</th>
                  <th>Period End</th>
                </>
              )}
              <th>Total Price</th>
              {active === 'PO Pencairan' ? <th>Approved By</th> : null}
              {active === 'PO Finalization' ? <th>Liquid By</th> : null}
              {active === 'Edit Mutation Date' ? <th>Added By</th> : null}
              <th>Remarks</th>
              {(active === 'PO Pencairan' || active === 'PO History') && (
                <th>Proof</th>
              )}
              <th>Action</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: '15px' }}>{renderTable()}</tbody>
        </Table>
      </CardBody>

      {/* Modal Upload PO Proof */}
      <ModalUploadPOProof
        isOpen={open}
        toggle={() => handleOpenModal(0, 'close')}
        activeId={selectedRowId}
      />

      {/* Modal Preview Po Proof */}
      <ModalPreviewPOProof
        isOpen={openPreview}
        toggle={() => handleOpenPreview('', 'close')}
        proofData={selectedProofData}
      />
    </Card>
  );
};

export default POList;
