import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import './gljournal.css';
import GLJournalList from './GLJournalList';
import GLJournalForm from './GLJournalForm';

const GLJournalPage = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="container-fluid">
      <div className="white-box mt-3 py-5 px-5">
        <div className="pb-4">
          <div className="line-green mb-3" />
          <div className="general-title text-gray">GL Journal</div>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              GL Journal List
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
            >
              GL Journal Data
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <GLJournalList toggleTab={toggle} />
          </TabPane>
          <TabPane tabId="2">
            <GLJournalForm />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default GLJournalPage;
