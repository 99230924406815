import React from 'react';
import PaymentCard from './PaymentCard';

const PaymentList = ({
  currency,
  payments,
  transactionId,
  accountsReceivable,
  refresh,
}) => {
  const arrJSX = payments.map((item, index) => {
    return (
      <PaymentCard
        key={JSON.stringify(item)}
        {...item}
        index={index}
        refresh={refresh}
        accountsReceivable={accountsReceivable}
        transactionId={transactionId}
        currency={currency}
      />
    );
  });

  return arrJSX;
};

export default PaymentList;
