import React from 'react';
import { Label, Input, FormText, FormGroup, FormFeedback } from 'reactstrap';

export const InputForm = ({
  label,
  placeholder,
  valid,
  value,
  invalid,
  success,
  error,
  onInput,
  onFocus,
  help,
  additionalHelp,
  className,
  type,
}) => {
  return (
    <FormGroup className={className}>
      <Label for={`form${label}`} className="text-gray font-weight-bold">
        {label}
      </Label>
      <Input
        type={type || 'text'}
        valid={valid}
        invalid={invalid}
        placeholder={placeholder}
        value={value}
        id={`form${label}`}
        onChange={({ target }) => onInput(target.value)}
        onFocus={onFocus}
        onWheel={(e) => e.target.blur()}
      />
      <FormFeedback valid>{success}</FormFeedback>
      <FormFeedback invalid="true">{error}</FormFeedback>
      <FormText>{help}</FormText>
      <FormText>{additionalHelp}</FormText>
    </FormGroup>
  );
};
