import React, { useMemo, useState } from 'react';
import axios from 'supports/api';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchTransactions } from 'redux/modules/transactions';
// import TransactionDetailsModal from './TransactionDetailsModal';
import { encryptV2 } from 'supports/helpers/encryptionV2';
import { transactionIVKey, transactionKey } from 'supports/config';
import RenewModal from './RenewModal';
import TLabel from './TLabel';
import './main.css';

// const useTrxCancel = (id) => {
//   if (window.confirm('Transaksi ini akan dicancel. Lanjutkan?')) {
//     axios
//       .put(`${API_URL_1}/admin/cancel-transaction/${id}`)
//       .then(() => {
//         alert('Cancel Success');
//         // dispatch(fetchTransactions(searchDate));
//       })
//       .catch((err) => {
//         console.log(err);
//         if (err.response) {
//           if (err.response.data) {
//             alert(err.response.data.message);
//           } else {
//             alert(err);
//           }
//         } else {
//           alert(err);
//         }
//       });
//   }
// };

const DisplayDeadline = ({ deadline }) => {
  return (
    <div
      className=""
      style={{ fontSize: '14px', color: '#6e6e6e', minWidth: '90px' }}
    >
      <div className="font-weight-bold" style={{ color: '#505050' }}>
        {moment(deadline).format('DD MMM YYYY')}
      </div>
      <div>{moment(deadline).format('hh:mm A')}</div>
    </div>
  );
};

const TransactionCard = ({
  orderConfirmationNumber,
  invoiceNumber,
  user,
  status,
  createdAt,
  deadline,
  totalPrice,
  accountsReceivable,
  createdBy,
  id,
  invoiceCreatedAt,
  currency,
  refresh,
  programSales,
  alumniEventSales,
  subscriptionSales,
  onlineCourseSales,
  serviceSales,
}) => {
  const { firstName, lastName, email, phone } = user;
  const dispatch = useDispatch();
  const searchDate = useSelector((state) => state.trxSearch.searchDate);
  const [isRenewModalOpen, setIsRenewModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleRenewModal = () => setIsRenewModalOpen(!isRenewModalOpen);

  const onCancelClick = () => {
    if (window.confirm('Transaksi ini akan dicancel. Lanjutkan?')) {
      axios
        .put(`/admin/cancel-transaction/${id}`)
        .then(() => {
          alert('Cancel Success');
          dispatch(fetchTransactions(searchDate));
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            if (err.response.data) {
              alert(err.response.data.message);
            } else {
              alert(err);
            }
          } else {
            alert(err);
          }
        });
    }
  };

  const onDownloadClick = () => {
    if (!invoiceNumber) {
      return window.alert('Invoice Not Available');
    }
    setLoading(true);
    axios
      .get(`/admin/print/invoice/${id}`)
      .then(() => {
        setLoading(false);
        window.open(
          `${process.env.REACT_APP_BASE_URL}/admin/print/invoice/${id}`,
        );
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
        console.log(err);
      });
  };

  const onCopyTransactionLink = async () => {
    const encryptedPayload = encryptV2({
      key: transactionKey,
      ivKey: transactionIVKey,
      data: JSON.stringify({
        userId: user.id,
        transactionId: id,
      }),
      isSameEncryptionKey: true,
    });

    const safeUrl = encodeURIComponent(encryptedPayload);

    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_MAIN_BASE_URL}/transaction-form/${safeUrl}`,
    );

    alert('Copied to Clipboard');
  };

  const hasOtherSalesThanProgram = useMemo(() => {
    return (
      !programSales.length ||
      subscriptionSales.length ||
      alumniEventSales.length ||
      onlineCourseSales.length ||
      serviceSales.length
    );
  }, [
    alumniEventSales.length,
    onlineCourseSales.length,
    programSales.length,
    serviceSales.length,
    subscriptionSales.length,
  ]);

  const RenderButtons = () => {
    const arrButtons = [];

    arrButtons.push(
      <Link to={`transaction-details?id=${id}`} key="DETAILSBUTTON">
        <Button
          color="secondary"
          key="buttonDETAILS"
          onClick={null}
          size="sm"
          className="mb-1 w-100"
          style={{ fontSize: '14px' }}
        >
          Details
        </Button>
      </Link>,
    );
    arrButtons.push(
      <Button
        onClick={onDownloadClick}
        size="sm"
        key="INVOICEBUTTON"
        style={{ fontSize: '14px', color: 'white' }}
        className="mb-1 w-100"
      >
        {loading ? (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          'Invoice'
        )}
      </Button>,
    );

    if (!hasOtherSalesThanProgram) {
      arrButtons.push(
        <Button
          color="info"
          key="buttonGenerate"
          onClick={onCopyTransactionLink}
          size="sm"
          className="mb-1"
          style={{ fontSize: '14px' }}
        >
          Copy Link
        </Button>,
      );
    }

    if (status === 'Cancelled') {
      arrButtons.push(
        <Button
          color="info"
          key="buttonRENEW"
          onClick={toggleRenewModal}
          size="sm"
          className="mb-1"
          style={{ fontSize: '14px' }}
        >
          Renew
        </Button>,
      );
    } else if (status !== 'Paid') {
      arrButtons.push(
        <div
          key="buttonCANCEL"
          onClick={onCancelClick}
          className="mb-1 cancel-button mx-auto"
          role="button"
          onKeyDown={onCancelClick}
          tabIndex={0}
        >
          Cancel
        </div>,
      );
    }

    return <div className="d-flex flex-column">{arrButtons}</div>;
  };

  return (
    <tr>
      <td>
        <TLabel status={status} />
        <div
          className="mt-1 main-text text-center"
          style={{ fontSize: '12px' }}
        >
          {createdBy}
        </div>
      </td>
      <td>
        <div className="main-text">{orderConfirmationNumber}</div>
        <div className="sub-text">
          {moment(createdAt).format('DD MMM YYYY hh:mm A')}
        </div>
      </td>
      <td>
        <div className="main-text">{invoiceNumber ?? 'N/A'}</div>
        <div className="sub-text">
          {invoiceNumber
            ? moment(invoiceCreatedAt).format('DD MMM YYYY hh:mm A')
            : null}
        </div>
      </td>
      <td>
        <div className="main-text">
          {firstName} {lastName}
        </div>
        <div className="sub-text">{email}</div>
        <div className="sub-text">{phone}</div>
      </td>
      <td>
        <div className="main-text">
          {`${currency?.code} ${totalPrice.toLocaleString()}`}
        </div>
      </td>
      <td>
        <div className="main-text">
          {`${currency?.code} ${(
            totalPrice - accountsReceivable
          ).toLocaleString()}`}
        </div>
      </td>
      <td>
        <DisplayDeadline deadline={deadline} />
      </td>
      <td>
        <RenderButtons />
      </td>
      <RenewModal
        isOpen={isRenewModalOpen}
        toggle={toggleRenewModal}
        id={id}
        refresh={refresh}
      />
    </tr>
  );
};

export default TransactionCard;
