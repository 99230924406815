import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Collapse,
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  initialFetchOnlineCourseData,
  filteringOnlineCourseData,
  setFilterQuery,
  setFilterCategory,
  setFilterTopic,
  setFilterLevel,
} from 'redux/modules/onlineCourse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomPaginationV2 from 'components/commons/CustomPaginationV2';
import qs from 'query-string';
import Loader from 'react-loader-spinner';
import OnlineCourseCard from './OnlineCourseCard';

const OnlineCourseList = () => {
  const [filterOpen, setFilterOpen] = useState(false);

  const dispatch = useDispatch();

  const { filterQuery, filterCategory, filterTopic, filterLevel } = useSelector(
    (state) => state.onlineCourse.filteringInput,
  );
  const data = useSelector((state) => state.onlineCourse.data);
  const filteredData = useSelector((state) => state.onlineCourse.filteredData);
  const filteringOptions = useSelector(
    (state) => state.onlineCourse.filteringOptions,
  );
  const loading = useSelector((state) => state.onlineCourse.loading);

  const location = useLocation();
  const history = useHistory();
  const queryParam = qs.parse(location.search);
  const { page } = queryParam;

  useEffect(() => {
    dispatch(initialFetchOnlineCourseData());
  }, [dispatch]);

  useEffect(() => {
    if (data.length) {
      dispatch(
        filteringOnlineCourseData({
          filterQuery,
          filterCategory,
          filterTopic,
          filterLevel,
        }),
      );
    }
  }, [
    dispatch,
    filterQuery,
    filterCategory,
    filterTopic,
    filterLevel,
    history,
    data,
  ]);

  const CardList = () => {
    const sliced = filteredData.slice((page - 1) * 10, (page - 1) * 10 + 10);
    const arrJSX = sliced.map((item, index) => {
      return <OnlineCourseCard key={item.id} index={index} {...item} />;
    });

    return <div className="col-12">{arrJSX}</div>;
  };

  const handleToPageOne = () => {
    history.push(
      `?${qs.stringify({
        ...queryParam,
        page: 1,
      })}`,
    );
  };

  const renderCategoryOptions = () => {
    return filteringOptions.categories.map((category) => {
      return (
        <option key={category.id} value={category.id}>
          {category.name}
        </option>
      );
    });
  };

  const renderTopicOptions = () => {
    return filteringOptions.topics.map((topic) => {
      return (
        <option key={topic.id} value={topic.id}>
          {topic.name}
        </option>
      );
    });
  };

  const renderLevelOptions = () => {
    return filteringOptions.levels.map((level) => {
      return (
        <option key={level.id} value={level.id}>
          {level.name}
        </option>
      );
    });
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ minHeight: '100vh' }}
      >
        <Loader type="ThreeDots" color="black" />
      </div>
    );
  }
  return (
    <div className="white-box">
      <Card>
        <CardBody>
          <div className="container-fluid white-box">
            <div className="row">
              <div className="col-5 my-auto">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Search: </InputGroupText>
                  </InputGroupAddon>
                  <input
                    value={filterQuery}
                    type="text"
                    className="form-control"
                    placeholder="Title / sub header"
                    onChange={(e) => {
                      handleToPageOne();
                      dispatch(setFilterQuery(e.target.value));
                    }}
                  />
                </InputGroup>
                <div className="mt-2 d-flex">
                  <div
                    className="custom-outline filter-button"
                    onClick={() => setFilterOpen(!filterOpen)}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    More Filters{' '}
                    <span>
                      <FontAwesomeIcon
                        className="mx-auto text-center"
                        icon={['fas', `chevron-${filterOpen ? 'up' : 'down'}`]}
                        size="1x"
                      />
                    </span>
                  </div>
                </div>
                <Collapse isOpen={filterOpen}>
                  <div className="form-group mt-1">
                    <select
                      value={filterCategory}
                      onChange={(e) => {
                        handleToPageOne();
                        dispatch(setFilterCategory(e.target.value));
                      }}
                      className="form-control mb-1"
                    >
                      <option value="">All Category</option>
                      {renderCategoryOptions()}
                    </select>
                    <select
                      value={filterTopic}
                      onChange={(e) => {
                        handleToPageOne();
                        dispatch(setFilterTopic(e.target.value));
                      }}
                      className="form-control mb-1"
                    >
                      <option value="">All Topics</option>
                      {renderTopicOptions()}
                    </select>
                    <select
                      value={filterLevel}
                      onChange={(e) => {
                        handleToPageOne();
                        dispatch(setFilterLevel(e.target.value));
                      }}
                      className="form-control"
                    >
                      <option value="">All Levels</option>
                      {renderLevelOptions()}
                    </select>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <CustomPaginationV2
        pages={Math.ceil(filteredData.length / 10) || 1}
        active={page}
      />
      <CardList />
      <CustomPaginationV2
        pages={Math.ceil(filteredData.length / 10) || 1}
        active={page}
      />
    </div>
  );
};

export default OnlineCourseList;
