import React from 'react';
import burger from 'supports/assets/svg/burger.svg';

export default ({ isLoggedIn, onClick, ...props }) => {
  if (isLoggedIn) {
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyDown={onClick}
        className="burger"
        id="sidebar-button"
        {...props}
      >
        <img
          src={burger}
          alt="toggler"
          className="filter-white"
          style={{ height: '12pt' }}
        />
      </div>
    );
  }
  return <div />;
};
