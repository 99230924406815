import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Button,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Axios from 'supports/api';

const date = new Date();

const EFakturGenerateTab = () => {
  const [fromDate, setFromDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [toDate, setToDate] = useState(date);
  const [loading, setLoading] = useState(false);

  const handleDownloadXLS = async () => {
    setLoading(true);
    try {
      const options = {
        params: {
          fromDate,
          toDate,
        },
        responseType: 'blob',
      };
      const response = await Axios.get(
        `/transaction/create-efaktur-xls`,
        options,
      );

      // downloads blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'tabelfakturpajak.xlsx');
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div>
      <Card body>
        <Card className="mt-1">
          <CardBody>
            <Form>
              <Col sm={6} md={6}>
                <FormGroup row>
                  <Label sm={4}>Tax Invoice Start Date</Label>
                  <Col sm={8}>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      selected={fromDate}
                      onChange={(pickedDate) => setFromDate(pickedDate)}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col sm={6} md={6}>
                <FormGroup row>
                  <Label sm={4}>Tax Invoice End Date</Label>
                  <Col sm={8}>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      selected={toDate}
                      onChange={(pickedDate) => setToDate(pickedDate)}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col sm={6} md={6}>
                <div className="d-flex flex-row justify-content-center">
                  <Button onClick={handleDownloadXLS}>
                    {loading ? (
                      <div
                        className="spinner-border text-light spinner-border-sm mr-2"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                    )}
                    Download E-Faktur XLS
                  </Button>
                </div>
              </Col>
            </Form>
          </CardBody>
        </Card>
      </Card>
    </div>
  );
};

export default EFakturGenerateTab;
