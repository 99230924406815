export default ({
  id = 0, // universal
  price = 0, // universal
  discount = 0, // universal with certain privileges
  userId = 0, // universal
  isGuest = false, // program
  description = null, // services
  notes = null, // services
  productType = null, // universal
  qty = 1, // program
  attendees = [], // program
  attendeeName = null, // program
  attendeeEmail = null, // program
  attendeePhone = null, // program
  address = null, // program
}) => {
  return {
    id,
    price,
    discount,
    userId,
    isGuest,
    description,
    notes,
    productType,
    qty,
    attendees,
    attendeeName,
    attendeeEmail,
    attendeePhone,
    address,
  };
};
