import React from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';

const BankReportTable = (props) => {
  const { filteredBalance, loadingBeginning, data, toDate } = props;

  const bankItems = () => {
    // saldo for counting balance for every credit (-) / debit (+)
    let saldo = filteredBalance.beginningBalance;
    // loop through data (which is actually comes from bankReport.filteredData)
    const list = data.map((val, index) => {
      if (val.receiptNumber) {
        saldo += val.nominal;
        return (
          <tr key={val.id}>
            <td className="text-center">{index + 2}</td>
            <td style={{ width: '10%' }}>{val.receiptNumber}</td>
            <td>
              {val.tujuanPenerimaan}{' '}
              {val.transactionId
                ? `- ${val.invoiceNumber} - ${val.customerName}`
                : `- ${val.remarks}`}
            </td>
            <td className="text-center">
              {moment(val.mutationDate).format('DD-MM-YYYY')}
            </td>
            <td className="text-right">Rp.{val.nominal.toLocaleString()}</td>
            <td />
            <td className="text-right">Rp.{saldo.toLocaleString()}</td>
          </tr>
        );
      }
      saldo -= val.totalPrice;
      return (
        <tr key={val.id}>
          <td className="text-center">{index + 2}</td>
          <td style={{ width: '12%' }}>{val.code}</td>
          <td>
            {val.tujuanPengeluaran} - {val.remarks}
          </td>
          <td className="text-center">
            {moment(val.mutationDate).format('DD-MM-YYYY')}
          </td>
          <td />
          <td className="text-right">Rp.{val.totalPrice.toLocaleString()}</td>
          <td className="text-right">Rp.{saldo.toLocaleString()}</td>
        </tr>
      );
    });
    // pushes 'saldo-akhir' to the end of the loop (table)
    list.push(
      <tr key="saldo-akhir">
        <td className="text-center">{list.length + 2}</td>
        <td colSpan={2}>
          <strong>Saldo Akhir</strong>
        </td>
        <td className="text-center">{moment(toDate).format('DD-MM-YYYY')}</td>
        <td />
        <td />
        <td className="text-right">Rp.{saldo.toLocaleString()}</td>
      </tr>,
    );
    return list;
  };

  return (
    <Table id="table-to-xls" striped bordered>
      <thead>
        <tr>
          <th style={{ width: '2%' }} className="text-center">
            No.
          </th>
          <th className="text-center" colSpan={2}>
            Keterangan
          </th>
          <th style={{ width: '12%' }} className="text-center">
            Tanggal Mutasi
          </th>
          <th style={{ width: '10%' }} className="text-center">
            Debit
          </th>
          <th style={{ width: '10%' }} className="text-center">
            Kredit
          </th>
          <th style={{ width: '10%' }} className="text-center">
            Saldo
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-center">1</td>
          <td colSpan={2}>
            <strong>Saldo Awal</strong>
          </td>
          <td className="text-center">
            {moment(filteredBalance.mutationDate).format('DD-MM-YYYY')}
          </td>
          <td />
          <td />
          <td className="text-right">
            {loadingBeginning ? (
              <div
                className="spinner-border text-dark spinner-border-sm"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              `Rp.${filteredBalance.beginningBalance.toLocaleString()}`
            )}
          </td>
        </tr>
        {bankItems()}
      </tbody>
    </Table>
  );
};

export default BankReportTable;
