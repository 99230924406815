import React from 'react';
import moment from 'moment';

moment.locale('id');

const GrandTotalColumn = ({ data }) => {
  const { grandNominal, grandDiscount, grandQty, grandTotalNominal } = data;
  return (
    <tr>
      <td colSpan={7}>
        <span className="float-right font-weight-bold">Grand Total</span>
      </td>
      <td style={{ textAlign: 'right' }}>
        Rp.{grandNominal.toLocaleString('id-ID')}
      </td>
      <td style={{ textAlign: 'right' }}>
        Rp.{grandDiscount.toLocaleString('id-ID')}
      </td>
      <td style={{ textAlign: 'center' }}>{grandQty}</td>
      <td style={{ textAlign: 'right' }}>
        Rp.{grandTotalNominal.toLocaleString('id-ID')}
      </td>
    </tr>
  );
};

const SalesReportTable = (props) => {
  const { groupedData } = props;
  const renderTable = () => {
    let grandNominal = 0;
    let grandDiscount = 0;
    let grandQty = 0;
    let grandTotalNominal = 0;
    const jsx = groupedData.map((val, idx) => {
      return (
        <React.Fragment key={idx.toString()}>
          {Object.keys(val).map((category) => {
            let sumCategoryNominal = 0;
            let sumCategoryDiscount = 0;
            let sumCategoryQty = 0;
            let sumCategoryTotalNominal = 0;
            return (
              <React.Fragment key={category}>
                <tr>
                  <td colSpan={13}>
                    <span
                      className="font-weight-bold"
                      style={{ fontSize: '30px' }}
                    >
                      {category}
                    </span>
                  </td>
                </tr>
                {Object.keys(val[category]).map((product) => {
                  if (val[category][product].length) {
                    let subNominal = 0;
                    let subDiscount = 0;
                    let subQty = 0;
                    let subTotalNominal = 0;
                    return (
                      <React.Fragment key={product}>
                        <tr>
                          <td className="font-weight-bold" colSpan={13}>
                            {product}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">No.</td>
                          {val[category][product][0].description && (
                            <td className="font-weight-bold">Nama customer</td>
                          )}
                          <td
                            className="font-weight-bold"
                            colSpan={
                              val[category][product][0].description ? 1 : 2
                            }
                          >
                            {val[category][product][0].description
                              ? 'Deskripsi'
                              : 'Nama customer'}
                          </td>
                          <td className="font-weight-bold">No. Invoice</td>
                          <td className="font-weight-bold">Admission</td>
                          <td className="font-weight-bold" colSpan={2}>
                            Tgl. Invoice
                          </td>
                          <td className="font-weight-bold">Nominal</td>
                          <td className="font-weight-bold">Discount</td>
                          <td className="font-weight-bold">Qty</td>
                          <td className="font-weight-bold">Total Nominal</td>
                        </tr>
                        {Object.keys(val[category][product]).map(
                          (sales, index) => {
                            const {
                              attendeeName,
                              description,
                              invoiceNumber,
                              createdBy,
                              invoiceCreatedAt,
                              price,
                              finalPrice,
                              id,
                              firstName,
                              lastName,
                            } = val[category][product][sales];
                            const itemNominal = price;
                            const itemDiscount = price - finalPrice;
                            const itemTotalNominal = itemNominal - itemDiscount;

                            subNominal += itemNominal;
                            subDiscount += itemDiscount;
                            subQty += 1;
                            subTotalNominal += itemTotalNominal;

                            sumCategoryNominal += itemNominal;
                            sumCategoryDiscount += itemDiscount;
                            sumCategoryQty += 1;
                            sumCategoryTotalNominal += itemTotalNominal;

                            grandNominal += itemNominal;
                            grandDiscount += itemDiscount;
                            grandQty += 1;
                            grandTotalNominal += itemTotalNominal;
                            const fullName = `${firstName} ${lastName}`;
                            return (
                              <React.Fragment key={id}>
                                <tr>
                                  <td>{index + 1}</td>
                                  {val[category][product][0].description && (
                                    <td>{fullName}</td>
                                  )}
                                  <td
                                    colSpan={
                                      val[category][product][0].description
                                        ? 1
                                        : 2
                                    }
                                  >
                                    {attendeeName || description}
                                    {(product ===
                                      'Biaya Registrasi Program Dana Cita' ||
                                      category === 'Alumni Event') &&
                                      fullName}
                                  </td>
                                  <td>{invoiceNumber || '-'}</td>
                                  <td>{createdBy}</td>
                                  <td
                                    colSpan={2}
                                    // colSpan={
                                    //   !val[category][product][0].description
                                    //     ? 2
                                    //     : 1
                                    // }
                                  >
                                    {invoiceCreatedAt
                                      ? moment(invoiceCreatedAt).format(
                                          'D MMMM YYYY',
                                        )
                                      : '-'}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.{itemNominal.toLocaleString('id-ID')}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.{itemDiscount.toLocaleString('id-ID')}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>{1}</td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.
                                    {itemTotalNominal.toLocaleString('id-ID')}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          },
                        )}
                        <tr>
                          <td colSpan={7}>
                            <span className="float-right">
                              Sum Total {product}
                            </span>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            Rp.{subNominal.toLocaleString('id-ID')}
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            Rp.{subDiscount.toLocaleString('id-ID')}
                          </td>
                          <td style={{ textAlign: 'center' }}>{subQty}</td>
                          <td style={{ textAlign: 'right' }}>
                            Rp.{subTotalNominal.toLocaleString('id-ID')}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  }
                  let productNominal = 0;
                  let productDiscount = 0;
                  let productQty = 0;
                  let productTotalNominal = 0;
                  return (
                    <React.Fragment key={product}>
                      <tr>
                        <td
                          className="font-weight-bold"
                          colSpan={13}
                          style={{ fontSize: '20px' }}
                        >
                          {product}
                        </td>
                      </tr>
                      {Object.keys(val[category][product]).map(
                        (branch, index) => {
                          let subNominal = 0;
                          let subDiscount = 0;
                          let subQty = 0;
                          let subTotalNominal = 0;
                          return (
                            <React.Fragment key={index.toString()}>
                              <tr>
                                <td className="font-weight-bold" colSpan={13}>
                                  {branch}
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-bold">No.</td>
                                <td className="font-weight-bold">
                                  Nama customer
                                </td>
                                <td className="font-weight-bold">Deskripsi</td>
                                <td className="font-weight-bold">
                                  No. Invoice
                                </td>
                                <td className="font-weight-bold">Admission</td>
                                <td className="font-weight-bold">
                                  Tgl. Invoice
                                </td>
                                <td className="font-weight-bold">Batch</td>
                                <td className="font-weight-bold">Nominal</td>
                                <td className="font-weight-bold">Discount</td>
                                <td className="font-weight-bold">Qty</td>
                                <td className="font-weight-bold">
                                  Total Nominal
                                </td>
                              </tr>
                              {Object.keys(val[category][product][branch]).map(
                                (sales, branchIndex) => {
                                  const {
                                    attendeeName,
                                    invoiceNumber,
                                    createdBy,
                                    invoiceCreatedAt,
                                    price,
                                    qty,
                                    finalPrice,
                                    id,
                                    description,
                                    program_code,
                                    program_startDate,
                                  } = val[category][product][branch][sales];
                                  const itemNominal = price * qty;
                                  const itemDiscount = price * qty - finalPrice;
                                  const itemTotalNominal =
                                    itemNominal - itemDiscount;

                                  subNominal += itemNominal;
                                  subDiscount += itemDiscount;
                                  subQty += qty;
                                  subTotalNominal += itemTotalNominal;

                                  productNominal += itemNominal;
                                  productDiscount += itemDiscount;
                                  productQty += qty;
                                  productTotalNominal += itemTotalNominal;

                                  sumCategoryNominal += itemNominal;
                                  sumCategoryDiscount += itemDiscount;
                                  sumCategoryQty += qty;
                                  sumCategoryTotalNominal += itemTotalNominal;

                                  grandNominal += itemNominal;
                                  grandDiscount += itemDiscount;
                                  grandQty += qty;
                                  grandTotalNominal += itemTotalNominal;
                                  return (
                                    <tr key={id}>
                                      <td>{branchIndex + 1}</td>
                                      <td>{attendeeName}</td>
                                      <td>{description || '-'}</td>
                                      <td>{invoiceNumber || '-'}</td>
                                      <td>{createdBy}</td>
                                      <td>
                                        {invoiceCreatedAt
                                          ? moment(invoiceCreatedAt).format(
                                              'D MMMM YYYY',
                                            )
                                          : '-'}
                                      </td>
                                      <td style={{ textAlign: 'right' }}>
                                        {`${moment(program_startDate).format(
                                          'D MMM YY',
                                        )} (${program_code})`}
                                      </td>
                                      <td style={{ textAlign: 'right' }}>
                                        Rp.
                                        {itemNominal.toLocaleString('id-ID')}
                                      </td>
                                      <td style={{ textAlign: 'right' }}>
                                        Rp.
                                        {itemDiscount.toLocaleString('id-ID')}
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        {qty}
                                      </td>
                                      <td style={{ textAlign: 'right' }}>
                                        Rp.
                                        {itemTotalNominal.toLocaleString(
                                          'id-ID',
                                        )}
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                              <tr>
                                <td colSpan={7}>
                                  <span className="float-right">
                                    Sum Total {product} {branch}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  Rp.{subNominal.toLocaleString('id-ID')}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  Rp.{subDiscount.toLocaleString('id-ID')}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {subQty}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  Rp.{subTotalNominal.toLocaleString('id-ID')}
                                </td>
                              </tr>
                              {Object.keys(val[category][product]).length -
                                1 ===
                              index ? (
                                <tr key={branch}>
                                  <td colSpan={7}>
                                    <span className="float-right font-weight-bold">
                                      Sum Total {product}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.{productNominal.toLocaleString('id-ID')}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.{productDiscount.toLocaleString('id-ID')}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    {productQty}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.
                                    {productTotalNominal.toLocaleString(
                                      'id-ID',
                                    )}
                                  </td>
                                </tr>
                              ) : null}
                            </React.Fragment>
                          );
                        },
                      )}
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td colSpan={7}>
                    <span className="float-right font-weight-bold">
                      Sum Total {category}
                    </span>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    Rp.{sumCategoryNominal.toLocaleString('id-ID')}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    Rp.{sumCategoryDiscount.toLocaleString('id-ID')}
                  </td>
                  <td style={{ textAlign: 'center' }}>{sumCategoryQty}</td>
                  <td style={{ textAlign: 'right' }}>
                    Rp.{sumCategoryTotalNominal.toLocaleString('id-ID')}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    });
    if (groupedData.length) {
      jsx.push(
        <GrandTotalColumn
          key="Grand Total"
          data={{
            grandNominal,
            grandDiscount,
            grandQty,
            grandTotalNominal,
          }}
        />,
      );
    }
    return (
      <>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td colSpan={7} style={{ width: '48%' }}>
                <span className="float-right font-weight-bold" />
              </td>
              <td className="font-weight-bold">Nominal</td>
              <td className="font-weight-bold">Discount</td>
              <td className="font-weight-bold">Qty</td>
              <td className="font-weight-bold">Total Nominal</td>
            </tr>
            <GrandTotalColumn
              data={{
                grandNominal,
                grandDiscount,
                grandQty,
                grandTotalNominal,
              }}
            />
          </tbody>
        </table>
        <table id="table-to-xls" className="table table-bordered">
          <tbody>{jsx}</tbody>
        </table>
      </>
    );
  };
  return renderTable();
};

export default SalesReportTable;
