import React from 'react';
import DatePicker from 'react-datepicker';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from 'reactstrap';

const ProcessButton = ({ onClick, isProcessing, isDisabled, className }) => {
  if (isProcessing) {
    return (
      <Button clasName={className} onClick={onClick} disabled={isDisabled}>
        <Loader type="Oval" color="#4aa390" height={80} width="100%" />
      </Button>
    );
  }

  return (
    <Button clasName={className} onClick={onClick} disabled={isDisabled}>
      Process
    </Button>
  );
};

const SearchBar = ({
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  banks = [],
  handleBankId,
  bankId = 0,
  payments = [],
  onSearch,
  onProcess,
  mutationDate,
  handleMutationDate,
}) => {
  const bankArr = banks.map(item => (
    <option key={`bankselect${item.id}`} value={item.id}>
      {item.accountName}
    </option>
  ));

  const checkedItems = payments.filter(item => item.isChecked);
  let totalNominal = 0;
  let totalPotonganBunga = 0;
  let totalNominalMutasi = 0;

  checkedItems.forEach(item => {
    totalNominal += item.nominal;
    totalPotonganBunga += item.interest;
    totalNominalMutasi += item.nominal - item.interest;
  });

  return (
    <div
      className="d-flex flex-column mr-md-3"
      style={{ minWidth: '358px', maxWidth: '500px' }}
    >
      <div className="w-100 mr-md-5 my-3">
        <div className="d-flex flex-row">
          <div className="mb-3 w-100">
            <div className="tag-gray dker font-weight-bold mb-2">
              Mutation Date From
            </div>
            <DatePicker
              className="form-control"
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyy"
              onChange={handleStartDate}
              showMonthYearDropdown
              minDate={moment(new Date())
                .subtract('months', 12)
                .toDate()}
              maxDate={moment(new Date())
                .add('months', 12)
                .toDate()}
            />
          </div>
          <div className="mb-3 w-100">
            <div className="tag-gray dker font-weight-bold mb-2">
              Mutation Date To
            </div>
            <DatePicker
              className="form-control"
              selected={endDate}
              selectsEnd
              minDate={startDate}
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyy"
              onChange={handleEndDate}
              showMonthYearDropdown
              maxDate={moment(new Date())
                .add('months', 12)
                .toDate()}
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="tag-gray dker font-weight-bold mb-2">Bank</div>
          <Input type="select" value={bankId} onChange={handleBankId}>
            <option value={0} disabled hidden>
              Select Bank
            </option>
            {bankArr}
          </Input>
        </div>
      </div>
      <Button onClick={onSearch} disabled={!bankId}>
        Search
      </Button>
      <div className="d-flex flex-column w-100 my-3 justify-content-between">
        <div className="d-flex flex-row align-items-center mb-3">
          <div
            className="tag-gray dker font-weight-bold"
            style={{ minWidth: '150px' }}
          >
            Selected Items
          </div>
          <div>{checkedItems.length}</div>
        </div>
        <div className="d-flex flex-row align-items-center mb-3">
          <div
            className="tag-gray dker font-weight-bold"
            style={{ minWidth: '150px' }}
          >
            Total Nominal
          </div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Rp.</InputGroupText>
            </InputGroupAddon>
            <input
              type="text"
              className="form-control"
              disabled
              value={totalNominal.toLocaleString()}
            />
          </InputGroup>
        </div>
        <div className="d-flex flex-row align-items-center mb-3">
          <div
            className="tag-gray dker font-weight-bold"
            style={{ minWidth: '150px' }}
          >
            Total Potongan Bunga
          </div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Rp.</InputGroupText>
            </InputGroupAddon>
            <input
              type="text"
              className="form-control"
              disabled
              value={totalPotonganBunga.toLocaleString()}
            />
          </InputGroup>
        </div>
        <div className="d-flex flex-row align-items-center mb-3">
          <div
            className="tag-gray dker font-weight-bold"
            style={{ minWidth: '150px' }}
          >
            Total Nominal Mutasi
          </div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>Rp.</InputGroupText>
            </InputGroupAddon>
            <input
              type="text"
              className="form-control"
              disabled
              value={totalNominalMutasi.toLocaleString()}
            />
          </InputGroup>
        </div>
        <div className="mb-3 w-100">
          <div className="tag-gray dker font-weight-bold mb-2">
            Process Mutation Date
          </div>
          <DatePicker
            className="form-control"
            selected={mutationDate}
            dateFormat="dd/MM/yyy"
            onChange={handleMutationDate}
            showMonthYearDropdown
            minDate={moment(new Date())
              .subtract('months', 12)
              .toDate()}
            maxDate={moment(new Date())
              .add('months', 12)
              .toDate()}
          />
        </div>
        <ProcessButton
          onClick={onProcess}
          isDisabled={checkedItems.length === 0}
        />
      </div>
    </div>
  );
};

export default SearchBar;
