export const checkRole = (roles, req) => {
  let access = false;

  roles.forEach((role) => {
    if (role?.name?.toLowerCase() === req?.toLowerCase()) {
      access = true;
    }
  });

  return access;
};
export const checkForm = (roles, req) => {
  let access = false;

  roles.forEach((role) => {
    if (role?.name?.toLowerCase() === 'super admin') {
      access = true;
    } else {
      role.permissions.forEach((permission) => {
        if (permission?.form?.name?.toLowerCase() === req?.toLowerCase()) {
          access = true;
        }
      });
    }
  });

  return access;
};
export const checkTabs = (roles, req) => {
  const tabcheck = [];
  let tabaccess = [];
  req.forEach((tabName) => {
    tabcheck.push(false);
  });

  roles.forEach((role) => {
    if (role?.name?.toLowerCase() === 'super admin') {
      tabaccess = req;
    } else {
      role.permissions.forEach((permission) => {
        req.forEach((tabName, index) => {
          if (
            permission?.form?.name?.toLowerCase() === tabName?.toLowerCase()
          ) {
            tabcheck[index] = true;
          }
        });
      });
    }
  });
  tabcheck.forEach((check, index) => {
    if (check) {
      tabaccess.push(req[index]);
    }
  });

  // check if theres double tab name
  const tabAccessFinal = [...new Set(tabaccess)];

  return tabAccessFinal;
};
