import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OnlineCourseListPage from 'pages/online-course/online-course-list';
import OnlineCourseForm from 'pages/online-course/online-course-form';
import OnlineCourseModuleListPage from 'pages/online-course/module-list';
import OnlineCourseSessionListPage from 'pages/online-course/session-list';
import OnlineCourseTopicListPage from 'pages/online-course/topic-list';

const OnlineCourseRouter = (props) => {
  const { match } = props;
  const { path } = match;
  return (
    <Switch>
      <Route exact path={`${path}`} component={OnlineCourseListPage} />
      <Route exact path={`${path}/create`} component={OnlineCourseForm} />
      <Route
        exact
        path={`${path}/edit/:onlineCourseId`}
        component={OnlineCourseForm}
      />
      <Route
        exact
        path={`${path}/:onlineCourseId`}
        component={OnlineCourseModuleListPage}
      />
      <Route
        exact
        path={`${path}/:onlineCourseId/:onlineCourseModuleId`}
        component={OnlineCourseSessionListPage}
      />
      <Route
        exact
        path={`${path}/:onlineCourseId/:onlineCourseModuleId/:onlineCourseSessionId`}
        component={OnlineCourseTopicListPage}
      />
    </Switch>
  );
};

export default OnlineCourseRouter;
