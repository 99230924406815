import React from 'react';
import moment from 'moment';
import taxValueChecker from 'supports/helpers/taxValueChecker';

moment.locale('id');

const GrandTotalColumn = ({ data }) => {
  const {
    grandNominal,
    grandRegistration,
    grandDiscount,
    grandQty,
    grandTotalNominal,
  } = data;
  return (
    <tr>
      <td colSpan={7}>
        <span className="float-right font-weight-bold">Grand Total</span>
      </td>
      <td style={{ textAlign: 'right' }}>
        Rp.{grandNominal.toLocaleString('id-ID')}
      </td>
      <td style={{ textAlign: 'right' }}>
        Rp.{grandRegistration.toLocaleString('id-ID')}
      </td>
      <td style={{ textAlign: 'right' }}>
        Rp.{grandDiscount.toLocaleString('id-ID')}
      </td>
      <td style={{ textAlign: 'center' }}>{grandQty}</td>
      <td style={{ textAlign: 'right' }}>
        Rp.{grandTotalNominal.toLocaleString('id-ID')}
      </td>
    </tr>
  );
};

const ProgramSalesReportTable = (props) => {
  const { groupedData } = props;
  const renderTable = () => {
    let grandNominal = 0;
    let grandRegistration = 0;
    let grandDiscount = 0;
    let grandQty = 0;
    let grandTotalNominal = 0;
    const jsx = groupedData.map((val, idx) => {
      return (
        <React.Fragment key={idx.toString()}>
          {Object.keys(val).map((category) => {
            let sumCategoryNominal = 0;
            let sumCategoryRegistration = 0;
            let sumCategoryDiscount = 0;
            let sumCategoryQty = 0;
            let sumCategoryTotalNominal = 0;

            return (
              <React.Fragment key={category}>
                <tr>
                  <td colSpan={14}>
                    <span
                      className="font-weight-bold"
                      style={{ fontSize: '30px' }}
                    >
                      {category}
                    </span>
                  </td>
                </tr>
                {Object.keys(val[category]).map((product) => {
                  if (val[category][product].length) {
                    let subNominal = 0;
                    const subRegistration = 0;
                    let subDiscount = 0;
                    let subQty = 0;
                    let subTotalNominal = 0;
                    return (
                      <React.Fragment key={product}>
                        <tr>
                          <td className="font-weight-bold" colSpan={14}>
                            {product}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">No.</td>
                          <td className="font-weight-bold">
                            {val[category][product][0].description
                              ? 'Deskripsi'
                              : 'Nama customer'}
                          </td>
                          <td className="font-weight-bold">Deskripsi</td>
                          <td className="font-weight-bold">No. Invoice</td>
                          <td className="font-weight-bold">No. Order Conf.</td>
                          <td className="font-weight-bold">Admission</td>
                          <td className="font-weight-bold">Tgl. Invoice</td>
                          <td className="font-weight-bold">Nominal</td>
                          <td className="font-weight-bold">Registration Fee</td>
                          <td className="font-weight-bold">Discount</td>
                          <td className="font-weight-bold">Qty</td>
                          <td className="font-weight-bold">Total Nominal</td>
                        </tr>
                        {Object.keys(val[category][product]).map(
                          (sales, index) => {
                            const {
                              attendeeName,
                              description,
                              invoiceNumber,
                              orderConfirmationNumber,
                              createdBy,
                              invoiceCreatedAt,
                              price,
                              finalPrice,
                              id,
                              firstName,
                              lastName,
                              taxBorneByCustomer,
                            } = val[category][product][sales];
                            const taxValue = taxValueChecker(invoiceCreatedAt);
                            const itemNominal = Math.round(
                              price / (1 + taxValue),
                            );
                            const itemDPP = Math.round(
                              finalPrice / (1 + taxValue),
                            );
                            const itemDiscount = itemNominal - itemDPP;
                            let itemPPN = 0;
                            if (!taxBorneByCustomer) {
                              itemPPN = Math.round(
                                (finalPrice / (1 + taxValue)) * taxValue,
                              );
                            }
                            const itemTotalNominal = itemDPP + itemPPN;

                            subNominal += itemNominal;
                            subDiscount += itemDiscount;
                            subQty += 1;
                            subTotalNominal += itemTotalNominal;

                            sumCategoryNominal += itemNominal;
                            sumCategoryDiscount += itemDiscount;
                            sumCategoryQty += 1;
                            sumCategoryTotalNominal += itemTotalNominal;

                            grandNominal += itemNominal;
                            grandDiscount += itemDiscount;
                            grandQty += 1;
                            grandTotalNominal += itemTotalNominal;
                            return (
                              <React.Fragment key={id}>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    {attendeeName || description}{' '}
                                    {product ===
                                      'Biaya Registrasi Program Dana Cita' &&
                                      `(${firstName} ${lastName})`}
                                  </td>
                                  <td>{description || '-'}</td>
                                  <td>{invoiceNumber || '-'}</td>
                                  <td>{orderConfirmationNumber || '-'}</td>
                                  <td>{createdBy}</td>
                                  <td>
                                    {invoiceCreatedAt
                                      ? moment(invoiceCreatedAt).format(
                                          'D MMMM YYYY',
                                        )
                                      : '-'}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.{itemNominal.toLocaleString('id-ID')}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.{'0'.toLocaleString('id-ID')}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.{itemDiscount.toLocaleString('id-ID')}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>{1}</td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.
                                    {itemTotalNominal.toLocaleString('id-ID')}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          },
                        )}
                        <tr>
                          <td colSpan={7}>
                            <span className="float-right">
                              Sum Total {product}
                            </span>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            Rp.{subNominal.toLocaleString('id-ID')}
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            Rp.{subRegistration.toLocaleString('id-ID')}
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            Rp.{subDiscount.toLocaleString('id-ID')}
                          </td>
                          <td style={{ textAlign: 'center' }}>{subQty}</td>
                          <td style={{ textAlign: 'right' }}>
                            Rp.{subTotalNominal.toLocaleString('id-ID')}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  }
                  let productNominal = 0;
                  let productRegistration = 0;
                  let productDiscount = 0;
                  let productQty = 0;
                  let productTotalNominal = 0;
                  return (
                    <React.Fragment key={product}>
                      <tr>
                        <td
                          className="font-weight-bold"
                          colSpan={14}
                          style={{ fontSize: '20px' }}
                        >
                          {product}
                        </td>
                      </tr>
                      {Object.keys(val[category][product]).map(
                        (branch, index) => {
                          let branchNominal = 0;
                          let branchRegistration = 0;
                          let branchDiscount = 0;
                          let branchQty = 0;
                          let branchTotalNominal = 0;
                          return (
                            <React.Fragment key={index.toString()}>
                              <tr>
                                <td className="font-weight-bold" colSpan={14}>
                                  {branch}
                                </td>
                              </tr>
                              {Object.keys(val[category][product][branch]).map(
                                (batch) => {
                                  const { program_startDate: startDate } = val[
                                    category
                                  ][product][branch][batch][0];
                                  let batchNominal = 0;
                                  let batchRegistration = 0;
                                  let batchDiscount = 0;
                                  let batchQty = 0;
                                  let batchTotalNominal = 0;
                                  return (
                                    <React.Fragment key={index.toString()}>
                                      <tr>
                                        <td
                                          className="font-weight-bold"
                                          colSpan={4}
                                        >
                                          {batch}
                                        </td>
                                        <td
                                          className="font-weight-bold"
                                          colSpan={10}
                                        >
                                          Start Date :{' '}
                                          {moment(startDate).format(
                                            'DD MMMM YYYY',
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="font-weight-bold">
                                          No.
                                        </td>
                                        <td className="font-weight-bold">
                                          Nama customer
                                        </td>
                                        <td className="font-weight-bold">
                                          Deskripsi
                                        </td>
                                        <td className="font-weight-bold">
                                          No. Invoice
                                        </td>
                                        <td className="font-weight-bold">
                                          No. Order Conf.
                                        </td>
                                        <td className="font-weight-bold">
                                          Admission
                                        </td>
                                        <td className="font-weight-bold">
                                          Tgl. Invoice
                                        </td>
                                        <td className="font-weight-bold">
                                          Nominal
                                        </td>
                                        <td className="font-weight-bold">
                                          Registration Fee
                                        </td>
                                        <td className="font-weight-bold">
                                          Discount
                                        </td>
                                        <td className="font-weight-bold">
                                          Qty
                                        </td>
                                        <td className="font-weight-bold">
                                          Total Nominal
                                        </td>
                                      </tr>
                                      {Object.keys(
                                        val[category][product][branch][batch],
                                      ).map((sales, salesIndex) => {
                                        const {
                                          attendeeName,
                                          invoiceNumber,
                                          orderConfirmationNumber,
                                          createdBy,
                                          invoiceCreatedAt,
                                          price,
                                          registrationFee,
                                          qty,
                                          finalPrice,
                                          id,
                                          description,
                                        } = val[category][product][branch][
                                          batch
                                        ][sales];

                                        const itemNominal = price * qty;
                                        const itemRegistration =
                                          registrationFee * qty;
                                        const itemTotal = finalPrice;
                                        const itemDiscount =
                                          itemNominal +
                                          itemRegistration -
                                          itemTotal;
                                        const itemTotalNominal = itemTotal;

                                        batchNominal += itemNominal;
                                        batchRegistration += itemRegistration;
                                        batchDiscount += itemDiscount;
                                        batchQty += qty;
                                        batchTotalNominal += itemTotalNominal;

                                        branchNominal += itemNominal;
                                        branchRegistration += itemRegistration;
                                        branchDiscount += itemDiscount;
                                        branchQty += qty;
                                        branchTotalNominal += itemTotalNominal;

                                        productNominal += itemNominal;
                                        productRegistration += itemRegistration;
                                        productDiscount += itemDiscount;
                                        productQty += qty;
                                        productTotalNominal += itemTotalNominal;

                                        sumCategoryNominal += itemNominal;
                                        sumCategoryRegistration += itemRegistration;
                                        sumCategoryDiscount += itemDiscount;
                                        sumCategoryQty += qty;
                                        sumCategoryTotalNominal += itemTotalNominal;

                                        grandNominal += itemNominal;
                                        grandRegistration += itemRegistration;
                                        grandDiscount += itemDiscount;
                                        grandQty += qty;
                                        grandTotalNominal += itemTotalNominal;

                                        return (
                                          <tr key={id}>
                                            <td>{salesIndex + 1}</td>
                                            <td>{attendeeName}</td>
                                            <td>{description || '-'}</td>
                                            <td>{invoiceNumber || '-'}</td>
                                            <td>
                                              {orderConfirmationNumber || '-'}
                                            </td>
                                            <td>{createdBy}</td>
                                            <td>
                                              {invoiceCreatedAt
                                                ? moment(
                                                    invoiceCreatedAt,
                                                  ).format('D MMMM YYYY')
                                                : '-'}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                              Rp.
                                              {itemNominal.toLocaleString(
                                                'id-ID',
                                              )}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                              Rp.
                                              {itemRegistration.toLocaleString(
                                                'id-ID',
                                              )}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                              Rp.
                                              {itemDiscount.toLocaleString(
                                                'id-ID',
                                              )}
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                              {qty}
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                              Rp.
                                              {itemTotalNominal.toLocaleString(
                                                'id-ID',
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                      <tr>
                                        <td colSpan={7}>
                                          <span className="float-right">
                                            Sum Total {product} {batch}
                                          </span>
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                          Rp.
                                          {batchNominal.toLocaleString('id-ID')}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                          Rp.
                                          {batchRegistration.toLocaleString(
                                            'id-ID',
                                          )}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                          Rp.
                                          {batchDiscount.toLocaleString(
                                            'id-ID',
                                          )}
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                          {batchQty}
                                        </td>
                                        <td style={{ textAlign: 'right' }}>
                                          Rp.
                                          {batchTotalNominal.toLocaleString(
                                            'id-ID',
                                          )}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                },
                              )}
                              <tr>
                                <td colSpan={7}>
                                  <span className="float-right">
                                    Sum Total {product} {branch}
                                  </span>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  Rp.{branchNominal.toLocaleString('id-ID')}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  Rp.
                                  {branchRegistration.toLocaleString('id-ID')}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  Rp.{branchDiscount.toLocaleString('id-ID')}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {branchQty}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                  Rp.
                                  {branchTotalNominal.toLocaleString('id-ID')}
                                </td>
                              </tr>
                              {Object.keys(val[category][product]).length -
                                1 ===
                              index ? (
                                <tr key={branch}>
                                  <td colSpan={7}>
                                    <span className="float-right font-weight-bold">
                                      Sum Total {product}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.{productNominal.toLocaleString('id-ID')}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.
                                    {productRegistration.toLocaleString(
                                      'id-ID',
                                    )}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.{productDiscount.toLocaleString('id-ID')}
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    {productQty}
                                  </td>
                                  <td style={{ textAlign: 'right' }}>
                                    Rp.
                                    {productTotalNominal.toLocaleString(
                                      'id-ID',
                                    )}
                                  </td>
                                </tr>
                              ) : null}
                            </React.Fragment>
                          );
                        },
                      )}
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td colSpan={7}>
                    <span className="float-right font-weight-bold">
                      Sum Total {category}
                    </span>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    Rp.{sumCategoryNominal.toLocaleString('id-ID')}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    Rp.{sumCategoryRegistration.toLocaleString('id-ID')}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    Rp.{sumCategoryDiscount.toLocaleString('id-ID')}
                  </td>
                  <td style={{ textAlign: 'center' }}>{sumCategoryQty}</td>
                  <td style={{ textAlign: 'right' }}>
                    Rp.{sumCategoryTotalNominal.toLocaleString('id-ID')}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    });
    if (groupedData.length) {
      jsx.push(
        <GrandTotalColumn
          key="Grand Total"
          data={{
            grandNominal,
            grandRegistration,
            grandDiscount,
            grandQty,
            grandTotalNominal,
          }}
        />,
      );
    }
    return (
      <>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td colSpan={7} style={{ width: '48%' }}>
                <span className="float-right font-weight-bold" />
              </td>
              <td className="font-weight-bold">Nominal</td>
              <td className="font-weight-bold">Registration Fee</td>
              <td className="font-weight-bold">Discount</td>
              <td className="font-weight-bold">Qty</td>
              <td className="font-weight-bold">Total Nominal</td>
            </tr>
            <GrandTotalColumn
              data={{
                grandNominal,
                grandRegistration,
                grandDiscount,
                grandQty,
                grandTotalNominal,
              }}
            />
          </tbody>
        </table>
        <table id="table-to-xls" className="table table-bordered">
          <tbody>{jsx}</tbody>
        </table>
      </>
    );
  };
  return renderTable();
};

export default ProgramSalesReportTable;
