import React, { useState, useEffect, useCallback } from 'react';
import axios from 'supports/api';
import { Button, Table, Badge } from 'reactstrap';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import PCard from 'pages/finance/transactions/main/PCard';
import CustomPagination from 'components/commons/CustomPagination';
import OnlineCourseModuleFormModal from './OnlineCourseModuleFormModal';
import ModalDelete from '../components/ModalDelete';

const OnlineCourseModuleListPage = () => {
  const { onlineCourseId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get('page') || 1;
  const itemsPerPage = 10;

  const [courseFormModalIsOpen, setCourseFormModalIsOpen] = useState(false);
  const [onlineCourseHeader, setOnlineCourseHeader] = useState({});
  const [onlineCourseModuleList, setOnlineCourseModuleList] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(false);

  const getOnlineCourseData = useCallback(async () => {
    try {
      const { data } = await axios.get(`/v2/online-course/${onlineCourseId}`);
      const { courseModules, ...courseHeader } = data?.data;
      setOnlineCourseHeader(courseHeader);
      setOnlineCourseModuleList(courseModules);
    } catch (err) {
      console.log(err);
    }
  }, [onlineCourseId]);

  useEffect(() => {
    getOnlineCourseData();
  }, [getOnlineCourseData]);

  useEffect(() => {
    if (
      !page ||
      (Math.ceil(onlineCourseModuleList.length / itemsPerPage) < page &&
        onlineCourseModuleList.length !== 0)
    ) {
      history.push('?page=1');
    }
  }, [page, onlineCourseModuleList, history]);

  const onButtonDeleteClick = async () => {
    try {
      const result = await axios.delete(
        `v2/online-course/module/${selectedDeleteId}`,
      );
      if (result.data.status === 'SUCCESS') {
        setSelectedDeleteId(null);
        getOnlineCourseData();
        setOpenModalDelete(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  const TableData = () => {
    const arrJSX = onlineCourseModuleList
      ?.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage,
      )
      .map((courseModule) => {
        return (
          <tr key={courseModule.title}>
            <td>{courseModule?.order}</td>
            <td>{courseModule?.title}</td>
            <td>{courseModule?.courseSessions?.length}</td>
            <td>
              <Link to={`${location.pathname}/${courseModule?.id}`}>
                <Button size="sm" className="mr-1">
                  Details
                </Button>
              </Link>
              <Button
                size="sm"
                className="mr-1"
                onClick={() => {
                  setCourseFormModalIsOpen(true);
                  setSelectedModule(courseModule);
                }}
              >
                Edit
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  setOpenModalDelete(true);
                  setSelectedDeleteId(courseModule.id);
                }}
              >
                Delete
              </Button>
            </td>
          </tr>
        );
      });

    return arrJSX;
  };

  return (
    <div className="container" style={{ marginTop: '40px' }}>
      <div className="d-flex flex-row justify-content-between mb-2">
        <div
          className="font-weight-bold"
          style={{ fontSize: '32px', color: '#505050' }}
        >
          {onlineCourseHeader?.title} <Badge>Course</Badge>
        </div>
        <Link
          to={`${location?.pathname.slice(
            0,
            location?.pathname.lastIndexOf('/'),
          )}`}
          className="mt-auto"
        >
          <Button color="danger">Back</Button>
        </Link>
      </div>
      {/* <HeaderDetailsCard /> */}
      <div className="d-flex justify-content-between mb-2">
        <CustomPagination
          pages={Math.ceil(onlineCourseModuleList?.length / itemsPerPage) || 1}
          active={page}
        />
        <Button
          color="success"
          style={{ backgroundColor: '#32b280' }}
          onClick={() => {
            setCourseFormModalIsOpen(true);
            setSelectedModule(null);
          }}
        >
          Add Module
        </Button>
      </div>
      <PCard style={{ minHeight: '600px' }}>
        <div className="d-flex flex-row justify-content-between">
          <Table striped bordered responsive hover>
            <thead
              className="font-weight-bold"
              style={{ fontSize: '14px', color: '#505050' }}
            >
              <tr>
                <th>#</th>
                <th>MODULE</th>
                <th>SESSIONS</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              <TableData />
            </tbody>
          </Table>
        </div>
      </PCard>
      <OnlineCourseModuleFormModal
        isOpen={courseFormModalIsOpen}
        toggle={() => setCourseFormModalIsOpen(!courseFormModalIsOpen)}
        onlineCourseId={onlineCourseHeader?.id}
        lastOrderNumber={onlineCourseModuleList?.length}
        selectedCourseModule={selectedModule}
        refresh={() => {
          getOnlineCourseData();
          setCourseFormModalIsOpen(false);
          setSelectedModule(null);
        }}
      />
      <ModalDelete
        isOpen={openModalDelete}
        toggle={() => setOpenModalDelete(!openModalDelete)}
        onButtonDeleteClick={onButtonDeleteClick}
      />
    </div>
  );
};

export default OnlineCourseModuleListPage;
