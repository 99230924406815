import React, { useCallback, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import axios from 'supports/api';
import LivestreamModuleModal from './LivestreamModuleModal';

const LivestreamModuleListPage = () => {
  const location = useLocation();

  const { programLivestreamId } = useParams();

  const [loading, setLoading] = useState(false);
  const [livestreamData, setLivestreamData] = useState({});
  const [livestreamModuleList, setLivestreamModuleList] = useState([]);
  const [selectedLivestreamModule, setSelectedLivestreamModule] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchModuleData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/v2/program-livestream/${programLivestreamId}`,
      );
      const { programLivestreamModules } = data?.data;
      setLivestreamData(data.data);
      setLivestreamModuleList(programLivestreamModules);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(err);
    }
  }, [programLivestreamId]);

  useEffect(() => {
    fetchModuleData();
  }, [fetchModuleData]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deleteModuleLivestream = async (id) => {
    if (window.confirm('Are you sure delete this module ?')) {
      try {
        await axios.delete(`/v2/program-livestream/module/${id}`);
        fetchModuleData();
      } catch (err) {
        alert(`Terjadi kesalahan pada system. ${err}`);
        console.log(err);
      }
    }
  };

  const LivestreamModuleList = () => {
    if (livestreamModuleList.length) {
      return livestreamModuleList.map((module) => {
        const {
          id,
          title,
          description,
          order,
          programLivestreamSessions,
        } = module;
        return (
          <div
            key={id}
            className="d-flex flex-row my-3 py-2 border justify-content-between"
            style={{ borderRadius: '6px' }}
          >
            <div className="col-10">
              <div className="row">
                <div className="col-3">Module Title</div>
                <div className="col-9">: {title}</div>
              </div>
              <div className="row">
                <div className="col-3">Description</div>
                <div className="col-9">: {description}</div>
              </div>
              <div className="row">
                <div className="col-3">Order</div>
                <div className="col-9">: {order}</div>
              </div>
              <div className="row">
                <div className="col-3">Total Sessions</div>
                <div className="col-9">
                  : {programLivestreamSessions.length}
                </div>
              </div>
            </div>
            <div className="col-2">
              <Button
                color="primary"
                className="mb-1 w-100"
                size="sm"
                onClick={() => {
                  setSelectedLivestreamModule(module);
                  toggleModal();
                }}
              >
                Details
              </Button>
              <Link to={`${location.pathname}/${id}/session`}>
                <Button
                  color="success"
                  size="sm"
                  className="mb-1 w-100 font-weight-bold"
                >
                  Sessions
                </Button>
              </Link>
              <Button
                size="sm"
                className="w-100 font-weight-bold"
                color="danger"
                onClick={() => deleteModuleLivestream(id)}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      });
    }
    return (
      <div className="d-flex justify-content-center pt-5">
        <h4>NO DATA AVAILABLE</h4>
      </div>
    );
  };

  return (
    <div>
      <div
        className="white-box container py-2"
        style={{ minHeight: '100vh', marginTop: '40px' }}
      >
        <div className="mt-2">
          <Link
            to={`${location?.pathname.slice(
              0,
              location?.pathname.lastIndexOf(`/${programLivestreamId}`),
            )}`}
            className="mt-auto"
          >
            <Button color="danger">{'<'} Back</Button>
          </Link>
        </div>
        <div className="d-flex flex-row justify-content-between mt-4 mb-5">
          <div
            className="font-weight-bold"
            style={{ fontSize: '26px', color: '#505050' }}
          >
            {livestreamData.title} - Module Data
          </div>
          <Button
            className="float-right mt-auto"
            onClick={() => {
              toggleModal();
              setSelectedLivestreamModule({});
            }}
          >
            + Create Module
          </Button>
        </div>
        {loading ? (
          <div
            className="d-flex justify-content-center"
            style={{ minHeight: '100vh' }}
          >
            <Loader type="ThreeDots" color="black" />
          </div>
        ) : (
          <LivestreamModuleList />
        )}
      </div>
      <LivestreamModuleModal
        toggle={toggleModal}
        isModalOpen={isModalOpen}
        programLivestreamId={programLivestreamId}
        refresh={fetchModuleData}
        selectedLivestreamModule={selectedLivestreamModule}
        programLivestreamLength={livestreamModuleList.length}
      />
    </div>
  );
};

export default LivestreamModuleListPage;
