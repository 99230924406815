import React from 'react';
import ReactQuill from 'react-quill';

const HTMLEditor2 = ({ id, value, index, name, prop, handleInput }) => {
  // kalo pake state bisa delete berdasarkan index, masalahnya pas ngetik cursornya balik ke depan terus
  return (
    <ReactQuill
      id={id}
      key={id}
      value={value}
      onChange={(evt) => {
        handleInput(name, index, evt, prop);
      }}
    />
  );
};

export default HTMLEditor2;
