// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls
import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  PO: [],
  countPO: 0,
  dataPo: [],
  poList: [],
  poItem: [],
  branches: [],
  poHeaders: [],
  tujuanPengeluaran: [],
  countData: 0,
  status: 'idle',
  error: null,
};

const { actions, reducer } = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    fetchPOListSuccess: (state, action) => {
      state.status = 'success';
      state.poList = action.payload;
    },
    createPoSuccess: (state, action) => {
      state.status = 'success';
      state.dataPo = action.payload;
    },
    fetchPoItemByIdSuccess: (state, action) => {
      state.status = 'success';
      state.poItem = action.payload;
    },
    updatePoItemByIdSuccess: (state, action) => {
      state.status = 'success';
      state.poItem = action.payload;
    },
    fetchPoHeadersByIdSuccess: (state, action) => {
      state.status = 'success';
      state.poHeaders = action.payload;
    },
    fetchTujuanPengeluaranSuccess: (state, action) => {
      state.status = 'success';
      state.tujuanPengeluaran = action.payload;
    },
    fetchBranchesSuccess: (state, action) => {
      state.status = 'success';
      state.branches = action.payload;
    },
    fetchPoSuccess: (state, action) => {
      state.status = 'success';
      state.PO = action.payload.rows;
      state.countPO = action.payload.count;
    },
    fetchFailure: (state, action) => {
      state.status = 'failure';
      state.error = action.payload;
    },
  },
});

export default reducer;

export const {
  fetchStart,
  fetchPOListSuccess,
  fetchTujuanPengeluaranSuccess,
  createPoSuccess,
  fetchPoItemByIdSuccess,
  updatePoItemByIdSuccess,
  fetchPoHeadersByIdSuccess,
  fetchPoSuccess,
  fetchFailure,
  fetchBranchesSuccess,
} = actions;

export const fetchTujuanPengeluaran = () => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const { data } = await axios.get(`/masterTujuanPengeluaran/getAll`);
    dispatch(fetchTujuanPengeluaranSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const createPO = (form) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const { data } = await axios.post(`/poHeader/create`, form);
    dispatch(createPoSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const fetchPoItemById = (id) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const { data } = await axios.get(`/poItem/getById/${id}`);
    dispatch(fetchPoItemByIdSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const fetchPoHeadersById = (id) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const { data } = await axios.get(`/poHeader/getById/${id}`);
    dispatch(fetchPoHeadersByIdSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const editPoItemById = (id, form) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const { data } = axios.put(`/poItem/update/${id}`, form);
    dispatch(updatePoItemByIdSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const fetchBranches = () => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const { data } = await axios.get(`/branch/all`);
    dispatch(fetchBranchesSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const fetchPOList = (
  page,
  isProcess,
  isCair,
  isApproved,
  booleanGlJournal,
  poNumber,
  tujuanPengeluaran,
  createdBy,
  branchId,
  status,
) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const { data } = await axios.post(`/poHeader/getAllPoList`, {
      page,
      isProcess,
      isCair,
      isApproved,
      booleanGlJournal,
      poNumber,
      tujuanPengeluaran,
      createdBy,
      branchId,
      status,
    });
    dispatch(fetchPoSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const fetchPO = (
  page,
  isProcess,
  isCair,
  isApproved,
  booleanGlJournal,
  poNumber,
  tujuanPengeluaran,
  createdBy,
  remarks,
  poDateFrom,
  poDateTo,
) => async (dispatch) => {
  dispatch(fetchStart());

  try {
    const { data } = await axios.post(`/poHeader/getAllPo`, {
      page,
      isProcess,
      isCair,
      isApproved,
      booleanGlJournal,
      poNumber,
      tujuanPengeluaran,
      createdBy,
      remarks,
      poDateFrom,
      poDateTo,
    });
    dispatch(fetchPoSuccess(data.result));
  } catch (err) {
    dispatch(fetchFailure(err));
  }
};

export const fetchPoHistory = (
  page,
  poDateFrom,
  poDateTo,
  poNumber,
  username,
  remarks,
  masterTujuanPengeluaranId,
  branchId,
) => async (dispatch) => {
  dispatch(fetchStart());
  try {
    const { data } = await axios.post(`/poHeader/getPoHistory`, {
      page,
      poDateFrom,
      poDateTo,
      poNumber,
      username,
      remarks,
      masterTujuanPengeluaranId,
      branchId,
    });
    dispatch(fetchPoSuccess(data.result));
  } catch (err) {
    alert(err.message);
    dispatch(fetchFailure(err));
  }
};
