import React, { useState } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { editStudentInfo } from 'redux/modules/studentData';
import Axios from 'supports/api';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';

const IncomeOption = (props) => {
  return (
    <>
      <option value="">---Pilih Penghasilan {props.defaultOption}---</option>
      <option value="< Rp. 1.000.000">&lt; Rp. 1.000.000</option>
      <option value="Rp. 1.000.000 - Rp. 4.999.999">
        Rp. 1.000.000 - Rp. 4.999.999
      </option>
      <option value="Rp. 5.000.000 - Rp. 9.999.999">
        Rp. 5.000.000 - Rp. 9.999.999
      </option>
      <option value="Rp. 10.000.000 - Rp. 14.999.999">
        Rp. 10.000.000 - Rp. 14.999.999
      </option>
      <option value="Rp. 15.000.000 - Rp. 19.999.999">
        Rp. 15.000.000 - Rp. 19.999.999
      </option>
      <option value="> Rp. 20.000.000">&gt; Rp. 20.000.000</option>
    </>
  );
};

const StudentDataDetailTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState(props.data || {});
  const [editMode, setEditMode] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleInput = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleSumbit = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        `Apakah anda ingin mengubah data student a/n ${input.nama}?`,
      )
    ) {
      dispatch(editStudentInfo(input));
    }
  };

  const print = () => {
    setLoading(true);
    Axios.get(`/studentInfo/print/${input.id}`)
      .then(() => {
        setLoading(false);
        window.open(
          `${process.env.REACT_APP_BASE_URL}/studentInfo/print/${input.id}`,
        );
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <Form className="mt-2 mb-4" onSubmit={handleSumbit}>
      <div className="d-flex justify-content-between mb-3 table-buttons">
        <div>
          <Button
            onClick={() => {
              history.goBack();
              setEditMode(false);
            }}
            type="button"
          >
            <FontAwesomeIcon
              className="text-center mr-2"
              icon={['fas', 'chevron-left']}
              size="1x"
            />
            Back
          </Button>
        </div>
        <div>
          <Button
            type="button"
            className="mr-2"
            onClick={() => setEditMode(!editMode)}
            color={editMode ? 'danger' : 'primary'}
            disabled={!(input.id > 0)}
          >
            <FontAwesomeIcon
              className="text-center mr-2"
              icon={editMode ? faBan : faEdit}
              size="1x"
            />
            {editMode ? 'Cancel' : 'Edit'}
          </Button>
          <Button
            type="submit"
            className="mr-2"
            disabled={!editMode}
            color="success"
          >
            <FontAwesomeIcon
              className="text-center mr-2"
              icon={faSave}
              size="1x"
            />
            Save
          </Button>
          <Button type="button" onClick={print}>
            {loading ? (
              <div
                className="spinner-border spinner-border-sm mr-2"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <FontAwesomeIcon
                className="text-center mr-2"
                icon={['fas', 'print']}
                size="1x"
              />
            )}
            Print
          </Button>
        </div>
      </div>
      <div className="d-flex flex-column">
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <b>PROGRAM</b>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Kategori Program</Label>
            </div>
            <div className="col-10">
              <select
                value={input.kategoriProgram}
                name="kategoriProgram"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <option value="">---Pilih Kategori Program---</option>
                <option value="Job Connector">Job Connector</option>
                <option value="Part Time">Part Time</option>
                <option value="After Hour">After Hour</option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Nama Program</Label>
            </div>
            <div className="col-10">
              <select
                value={input.program}
                name="program"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <option value="">---Pilih Nama Program---</option>
                <option value="Data Science & Machine Learning">
                  Data Science & Machine Learning
                </option>
                <option value="Web & Mobile Development">
                  Web & Mobile Development
                </option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="UI/UX Design">UI/UX Design</option>
                <option value="DevOps Engineering"> DevOps Engineering</option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Nama Lengkap</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.nama}
                name="nama"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Tempat Lahir</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.tempatLahir}
                name="tempatLahir"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Tanggal Lahir</Label>
            </div>
            <div className="col-10">
              <input
                style={{ width: '150px' }}
                type="date"
                name="tanggalLahir"
                value={moment(input.tanggalLahir).format('YYYY-MM-DD')}
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Jenis Kelamin</Label>
            </div>
            <div className="col-8 d-flex flex-row align-items-center">
              <input
                type="radio"
                id="laki-laki"
                name="gender"
                value="laki-laki"
                checked={input.gender === 'laki-laki'}
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
              <span className="ml-2">Laki-laki</span>
              <input
                className="ml-2"
                type="radio"
                id="perempuan"
                name="gender"
                value="perempuan"
                checked={input.gender === 'perempuan'}
                onChange={(e) => handleInput(e)}
                disabled={!editMode}
              />
              <span className="ml-2">Perempuan</span>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Agama</Label>
            </div>
            <div className="col-10">
              <select
                value={input.agama}
                name="agama"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <option value="">---Pilih Agama---</option>
                <option value="Islam">Islam</option>
                <option value="Kristen">Kristen</option>
                <option value="Katolik">Katolik</option>
                <option value="Hindu">Hindu</option>
                <option value="Budha">Budha</option>
                <option value="Konghucu">Konghucu</option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>NIK KTP</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.nik}
                name="nik"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Status Pernikahan</Label>
            </div>
            <div className="col-10">
              <select
                value={input.statusPernikahan}
                name="statusPernikahan"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <option value="">---Pilih Status Pernikahan---</option>
                <option value="Sudah Menikah">Sudah Menikah</option>
                <option value="Belum Menikah">Belum Menikah</option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <b>PEKERJAAN / PENDIDIKAN</b>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Pekerjaan</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.pekerjaan}
                name="pekerjaan"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Nama Institusi</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                name="namaInstitusi"
                value={input.namaInstitusi}
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Kategori Pekerjaan</Label>
            </div>
            <div className="col-10">
              <select
                value={input.kategoriPekerjaan}
                name="kategoriPekerjaan"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <option value="">---Pilih Kategori Pekerjaan---</option>
                <option value="Freelance">Freelance</option>
                <option value="Karyawan">Karyawan</option>
                <option value="Pengusaha">Pengusaha</option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Bidang Usaha</Label>
            </div>
            <div className="col-10">
              <select
                value={input.bidangUsaha}
                name="bidangUsaha"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <option value="">---Pilih Kategori Program---</option>
                <option value="Keuangan">Keuangan</option>
                <option value="Media">Media</option>
                <option value="Kesehatan">Kesehatan</option>
                <option value="Pendidikan">Pendidikan</option>
                <option value="Pertambangan">Pertambangan</option>
                <option value="Pertanian">Pertanian</option>
                <option value="Hukum">Hukum</option>
                <option value="IT">IT</option>
                <option value="Marketing">Marketing</option>
                <option value="Lainnya">Lainnya</option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Lama Bekerja (Tahun)</Label>
            </div>
            <div className="col-10">
              <input
                type="number"
                value={input.lamaBekerja}
                name="lamaBekerja"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Penghasilan</Label>
            </div>
            <div className="col-10">
              <select
                value={input.penghasilanStudent}
                name="penghasilanStudent"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <IncomeOption defaultOption="Anda" />
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Pendidikan Terakhir</Label>
            </div>
            <div className="col-10">
              <select
                value={input.pendidikanTerakhir}
                name="pendidikanTerakhir"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <option value="">---Pilih Pendidikan Terakhir---</option>
                <option value="SD">SD</option>
                <option value="SMP">SMP</option>
                <option value="SMA">SMA</option>
                <option value="S1">S1</option>
                <option value="S2">S2</option>
                <option value="S3">S3</option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Institusi Pendidikan</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.institusiPendidikan}
                name="institusiPendidikan"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <b>ORANG TUA / WALI</b>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Nama Ibu</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.namaIbu}
                name="namaIbu"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Pekerjaan Ibu</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.pekerjaanIbu}
                name="pekerjaanIbu"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Tahun Lahir Ibu</Label>
            </div>
            <div className="col-10">
              <input
                type="number"
                value={input.tahunLahirIbu}
                name="tahunLahirIbu"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Penghasilan Ibu</Label>
            </div>
            <div className="col-10">
              <select
                value={input.penghasilanIbu}
                name="penghasilanIbu"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <IncomeOption defaultOption="Ibu" />
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Nama Ayah</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.namaAyah}
                name="namaAyah"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Pekerjaan Ayah</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.pekerjaanAyah}
                name="pekerjaanAyah"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Tahun Lahir Ayah</Label>
            </div>
            <div className="col-10">
              <input
                type="number"
                value={input.tahunLahirAyah}
                name="tahunLahirAyah"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Penghasilan Ayah</Label>
            </div>
            <div className="col-10">
              <select
                value={input.penghasilanAyah}
                name="penghasilanAyah"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <IncomeOption defaultOption="Ayah" />
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Nama Wali</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.namaWali}
                name="namaWali"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Pekerjaan Wali</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.pekerjaanWali}
                name="pekerjaanWali"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Tahun Lahir Wali</Label>
            </div>
            <div className="col-10">
              <input
                type="number"
                value={input.tahunLahirWali}
                name="tahunLahirWali"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Penghasilan Wali</Label>
            </div>
            <div className="col-10">
              <select
                value={input.penghasilanWali}
                name="penghasilanWali"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <IncomeOption defaultOption="Wali" />
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <b>TEMPAT TINGGAL</b>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Jenis Tinggal</Label>
            </div>
            <div className="col-10">
              <select
                value={input.jenisTinggal}
                name="jenisTinggal"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <option value="">---Pilih Jenis tempat tinggal---</option>
                <option value="Orang Tua">Orang Tua</option>
                <option value="Rumah Sendiri">Rumah Sendiri</option>
                <option value="Saudara">Saudara</option>
                <option value="Apartemen">Apartemen</option>
                <option value="Kontrakan/Sewa">Kontrakan/Sewa</option>
                <option value="Kost">Kost</option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Kepemilikan Tempat Tinggal</Label>
            </div>
            <div className="col-10">
              <select
                value={input.kepemilikanTinggal}
                name="kepemilikanTinggal"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <option value="">---Pilih Kepemilikan Tempat Tinggal---</option>
                <option value="Kepemilikan Sendiri">Kepemilikan Sendiri</option>
                <option value="Kepemilikian Orang Tua/Saudara">
                  Kepemilikian Orang Tua/Saudara
                </option>
                <option value="Kepemilikan Orang Lain/Sewa">
                  Kepemilikan Orang Lain/Sewa
                </option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Alat Transportasi</Label>
            </div>
            <div className="col-10">
              <select
                value={input.alatTransportasi}
                name="alatTransportasi"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              >
                <option value="">---Pilih Alat Transportasi---</option>
                <option value="Mobil">Mobil</option>
                <option value="Motor">Motor</option>
                <option value="Alat Transportasi Umum">
                  Alat Transportasi Umum
                </option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Propinsi</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.propinsi}
                name="propinsi"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Kabupaten / Kota</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.kabKota}
                name="kabKota"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Kecamatan</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.kecamatan}
                name="kecamatan"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Desa / Kelurahan</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.kelurahan}
                name="kelurahan"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Alamat Lengkap</Label>
            </div>
            <div className="col-10">
              <textarea
                name="alamat"
                value={input.alamat}
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Kewarganegaraan</Label>
            </div>
            <div className="col-10">
              <input
                type="text"
                value={input.kewarganegaraan}
                name="kewarganegaraan"
                onChange={(e) => handleInput(e)}
                required
                disabled={!editMode}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Status</Label>
            </div>
            <div className="col-10">
              <select
                value={input.status}
                name="status"
                onChange={(e) => handleInput(e)}
                // required
                disabled
              >
                <option value="">---Pilih Status---</option>
                <option value="Aktif">Aktif</option>
                <option value="Lulus">Lulus</option>
                <option value="Tidak Lulus">Tidak Lulus</option>
              </select>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Nilai Logic Test</Label>
            </div>
            <div className="col-10">
              <input
                type="number"
                value={input.nilaiLogicTest}
                name="nilaiLogicTest"
                onChange={(e) => handleInput(e)}
                // required
                disabled
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className="col-12">
          <div className="d-flex flex-row">
            <div className="col-2">
              <Label>Nilai Akhir</Label>
            </div>
            <div className="col-10">
              <input
                type="number"
                value={input.nilaiAkhir}
                name="nilaiAkhir"
                onChange={(e) => handleInput(e)}
                // required
                disabled
              />
            </div>
          </div>
        </FormGroup>
      </div>
    </Form>
  );
};

export default StudentDataDetailTable;
