import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ item, index, onDelete }) => {
  const { productType, data, price, qty, description, currency, notes } = item;

  let cardDesc = '';
  if (productType === 'program') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>
          {data?.code} || {data?.name}
        </div>
        <div className="font-weight-lighter" style={{ fontSize: '12px' }}>
          {`${moment(data?.startDate).format('DD MMM YYYY')} -
            ${moment(data?.endDate).format('DD MMM YYYY')}`}
        </div>
        <div
          className="font-weight-lighter"
          style={{ fontSize: '12px' }}
        >{`${description}`}</div>
      </div>
    );
  }

  if (productType === 'service') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>{data?.name}</div>
        <div
          className="font-weight-lighter"
          style={{ fontSize: '12px' }}
        >{`${description}`}</div>
        <div
          className="font-weight-lighter"
          style={{ fontSize: '12px' }}
        >{`${notes}`}</div>
      </div>
    );
  }

  if (productType === 'online_course') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>{data?.title}</div>
      </div>
    );
  }

  if (productType === 'registrationFee') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>Registration Fee</div>
      </div>
    );
  }

  if (productType === 'subscription') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>{data?.name}</div>
        <div
          className="font-weight-lighter"
          style={{ fontSize: '12px' }}
        >{`${data?.description}`}</div>
        <div className="font-weight-lighter" style={{ fontSize: '12px' }}>
          Notes : {`${notes}`}
        </div>
      </div>
    );
  }

  if (productType === 'alumni_events') {
    cardDesc = (
      <div
        className="d-flex flex-column font-weight-bold"
        style={{ fontSize: '14px' }}
      >
        <div>{data?.title}</div>
        <div className="font-weight-lighter" style={{ fontSize: '12px' }}>
          Alumni Event
        </div>
      </div>
    );
  }

  return (
    <tr>
      <th width="10px">
        {productType !== 'registrationFee' && (
          <FontAwesomeIcon
            icon={['fas', 'times']}
            color="#495057"
            size="1x"
            className="cursor-pointer"
            style={{ width: '10px' }}
            onClick={() => onDelete(index)}
          />
        )}
      </th>
      <td colSpan={2}>{cardDesc}</td>
      <td colSpan={1}>
        <div className="float-right">
          <span className="mr-1">{currency}</span>
          <span>{price?.toLocaleString()}</span>
        </div>
      </td>
      <td colSpan={1} width="10px">
        <div className="float-right">x{qty}</div>
      </td>
      <td>
        <span className="d-flex flex-row justify-content-end">
          <span className="mr-1">{currency}</span>
          <span>{((price ?? 0) * (qty ?? 1)).toLocaleString()}</span>
        </span>
      </td>
    </tr>
  );
};
