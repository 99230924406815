import React, { useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EyeIcon = ({ type, onClick }) => {
  if (type === 'password') {
    return (
      <FontAwesomeIcon
        icon={['fas', 'eye']}
        color="#53b59f"
        onClick={onClick}
      />
    );
  }
  return (
    <FontAwesomeIcon
      icon={['fas', 'eye-slash']}
      color="#53b59f"
      onClick={onClick}
    />
  );
};

export default ({ onChange, value, ...props }) => {
  const [type, setType] = useState('password');

  const toggleType = () => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };

  return (
    <InputGroup {...props}>
      <Input
        placeholder="password"
        type={type}
        value={value}
        onChange={onChange}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText
          style={{ width: '42px' }}
          className="password-toggle"
          onClick={toggleType}
        >
          <EyeIcon type={type} />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};
