import {
  faArrowLeft,
  faArrowRight,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import {
  Carousel,
  CarouselItem,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

const ModalPreviewPOItemProof = ({ isOpen, toggle, files = [], type }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const imageFiles = useMemo(() => {
    return type === 'add'
      ? files
          .filter((file) => file.type.includes('image/'))
          .map((file) => {
            return { path: URL.createObjectURL(file), filename: file.name };
          })
      : files?.filter((path) =>
          ['.jpg', '.jpeg', '.png'].some((ext) => path.includes(ext)),
        ) || [];
  }, [files, type]);

  const pdfFiles = useMemo(() => {
    return type === 'add'
      ? files
          .filter((file) => file.type === 'application/pdf')
          .map((file) => {
            return {
              path: URL.createObjectURL(file),
              filename: file.name,
            };
          })
      : files?.filter((path) => path.includes('.pdf')) || [];
  }, [files, type]);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === imageFiles.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? imageFiles.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle();
        setActiveIndex(0);
      }}
    >
      <ModalHeader>Preview Proof</ModalHeader>
      <ModalBody>
        {' '}
        {Boolean(imageFiles.length) && (
          <>
            <div>Uploaded Images:</div>
            <Carousel activeIndex={activeIndex}>
              {imageFiles.map((file) => {
                return (
                  <CarouselItem
                    onExiting={() => isOpen && setAnimating(true)}
                    onExited={() => setAnimating(false)}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '500px',
                        position: 'relative',
                        paddingInline: imageFiles.length > 1 ? '30px' : 0,
                      }}
                    >
                      <a
                        href={type === 'add' ? file.path : baseUrl + file}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: 'none',
                          cursor: 'pointer',
                          color: 'inherit',
                        }}
                      >
                        <img
                          width="100%"
                          height="500px"
                          src={type === 'add' ? file.path : baseUrl + file}
                          alt="test"
                          style={{ objectFit: 'contain' }}
                        />
                      </a>
                    </div>
                  </CarouselItem>
                );
              })}
              {imageFiles.length > 1 && (
                <>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      width: '25px',
                      height: '25px',
                      cursor: 'pointer',
                    }}
                    onClick={() => previous()}
                  />

                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: 0,
                      width: '25px',
                      height: '25px',
                      cursor: 'pointer',
                    }}
                    onClick={() => next()}
                  />
                </>
              )}
            </Carousel>
          </>
        )}
        {Boolean(pdfFiles.length) && (
          <>
            <div>Uploaded Files:</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                flexWrap: 'wrap',
                marginBlock: '20px',
              }}
            >
              {pdfFiles.map((file) => {
                return (
                  <div style={{ width: '23.7%', height: 'auto' }}>
                    <a
                      href={type === 'add' ? file.path : baseUrl + file}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'none',
                        cursor: 'pointer',
                        color: 'inherit',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          height: '100%',
                          gap: '8px',
                          position: 'relative',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          style={{ width: '50px', height: '50px' }}
                        />
                        <div
                          style={{
                            fontSize: '12px',
                            fontWeight: 500,
                            textAlign: 'center',
                          }}
                        >
                          {type === 'add'
                            ? file.filename
                            : file.split('/')[file.split('/').length - 1]}
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalPreviewPOItemProof;
