// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  loading: false,
  status: 'idle',
  error: null,
  data: [],
  filteredData: [],
  // match state for 'no data found' display logic
  match: true,
  beginningBalance: [],
};

const { actions, reducer } = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    fetchBankReportStart: (state) => {
      state.loading = true;
      state.status = 'loading';
    },
    fetchBankReportAPISuccess: (state, action) => {
      state.data = action.payload;
      state.filteredData = action.payload;
    },
    fetchBankReportSuccess: (state) => {
      state.loading = false;
      state.status = 'success';
    },
    fetchBankReportFailure: (state, action) => {
      state.loading = false;
      state.status = 'failure';
      state.error = action.payload;
    },
    filterBankReports: (state, action) => {
      const { bankId, fromDate, toDate } = action.payload;
      let { data } = state;
      data = JSON.parse(JSON.stringify(data));
      let filtered = data;
      if (fromDate || toDate) {
        filtered = filtered.filter((e) => {
          return (
            moment(e.mutationDate).format('YYYY-MM-DD') >=
              moment(fromDate).format('YYYY-MM-DD') &&
            moment(e.mutationDate).format('YYYY-MM-DD') <=
              moment(toDate).format('YYYY-MM-DD')
          );
        });
      }
      if (bankId) {
        filtered = filtered.filter((val) => {
          return Number(bankId) === val.bankId;
        });
      }
      state.filteredData = filtered;
      state.match = filtered.length !== 0;
    },
    fetchBeginningBalance: (state, action) => {
      state.beginningBalance = action.payload;
    },
  },
});

export default reducer;

export const {
  fetchBankReportStart,
  fetchBankReportAPISuccess,
  fetchBankReportSuccess,
  fetchBankReportFailure,
  filterBankReports,
  fetchBeginningBalance,
} = actions;

export const fetchBankReport = (params) => async (dispatch) => {
  const { fromDate, toDate, bankId } = params;
  dispatch(fetchBankReportStart());
  try {
    const bankReport = await axios.get(`/report/bankreport`, {
      params: {
        fromDate,
        toDate,
      },
    });
    const beginningBalance = await axios.get(
      `/report/bankreport/beginning-balance`,
      {
        params: {
          fromDate,
        },
      },
    );
    dispatch(fetchBeginningBalance(beginningBalance.data.data));
    const { data } = bankReport.data;
    dispatch(fetchBankReportAPISuccess(data));
    dispatch(filterBankReports({ bankId }));
    dispatch(fetchBankReportSuccess());
  } catch (err) {
    alert(`Terjadi kesalahan pada system. ${err}`);
    dispatch(fetchBankReportFailure(err));
  }
};
