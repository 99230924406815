import React from 'react';
import { CardHeader } from 'reactstrap';
import PCard from 'pages/finance/transactions/main/PCard';
import TransactionInstallmentForm from './components/TransactionInstallmentForm';
import TransactionInstallmentFormik from './components/TransactionInstallmentFormik';
import TransactionInstallmentPayment from './components/TransactionInstallmentPayment';

const TransactionInstallment = ({
  currency,
  applyReferralCode,
  installmentFormRef,
}) => {
  return (
    <PCard className="mb-2">
      <CardHeader className="d-flex justify-content-between">
        <b style={{ paddingInline: '10px', alignSelf: 'center' }}>Cicilan</b>
      </CardHeader>

      <TransactionInstallmentFormik installmentFormRef={installmentFormRef}>
        <>
          <TransactionInstallmentForm
            currency={currency}
            applyReferralCode={applyReferralCode}
          />

          <TransactionInstallmentPayment />
        </>
      </TransactionInstallmentFormik>
    </PCard>
  );
};

export default TransactionInstallment;
