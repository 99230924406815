/* eslint-disable import/prefer-default-export */
import React from 'react';
import ReactQuill from 'react-quill';
import {
  Label,
  Input,
  FormGroup,
  FormFeedback,
  FormText,
  Badge,
} from 'reactstrap';
import 'react-quill/dist/quill.snow.css';

export const HTMLForm = ({
  label,
  value,
  valid,
  invalid,
  success,
  error,
  onInput,
  help,
}) => {
  return (
    <div className="mb-3">
      <Label for={`form${label}`} className="text-gray font-weight-bold">
        {label}
        <FormGroup className="ml-4 float-right">
          <Input valid={valid} invalid={invalid} className="d-none" />
          <FormFeedback valid>{success}</FormFeedback>
          <FormFeedback invalid="true">
            <Badge color="danger" style={{ fontSize: 12 }}>
              {error}
            </Badge>
          </FormFeedback>
        </FormGroup>
      </Label>
      <div className="my-1">
        <ReactQuill
          id={label}
          key={label}
          value={value}
          onChange={(evt) => onInput(evt)}
          theme="snow"
        />
      </div>
      <FormText>{help}</FormText>
    </div>
  );
};
