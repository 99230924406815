import React, { useState } from 'react';
import { Modal, ModalBody, Button, Progress, Alert } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'supports/api';
import { useDropzone } from 'react-dropzone';

const DropzoneVideo = ({ setFieldValue }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'video/*',
    maxFiles: 1,
    onDrop: (files) => {
      setFieldValue('video', files[0]);
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag & drop video, or click to select</p>
      </div>
      <aside>
        <div>Video Files</div>
        <ul>{files}</ul>
      </aside>
    </section>
  );
};

const DropzoneMaterial = ({ setFieldValue }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.pdf, .docx, .csv, .zip, .rar, .7z, .txt',
    maxFiles: 1,
    onDrop: (files) => {
      setFieldValue('material', files[0]);
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag & drop file, or click to select file</p>
      </div>
      <aside>
        <div>File Materi</div>
        <ul>{files}</ul>
      </aside>
    </section>
  );
};

const OnlineCourseTopicFormModal = ({
  isOpen,
  toggle,
  onlineCourseSessionId,
  folderId,
  selectedCourseTopic,
  refresh,
}) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Wajib diisi'),
    description: Yup.string().required('Wajib diisi'),
    order: Yup.number().required('Wajib diisi'),
  });

  const initialValues = {
    title: selectedCourseTopic?.title,
    description: selectedCourseTopic?.description,
    video: null,
    material: null,
    videoId: selectedCourseTopic?.videoId,
    order: selectedCourseTopic?.order,
  };
  const [uploadProgress, setUploadProgress] = useState(0);
  const onSubmit = async (values) => {
    try {
      const options = {
        onUploadProgress: (progressEvent) => {
          setUploadProgress(
            Math.round((progressEvent.loaded * 90) / progressEvent.total),
          );
        },
      };
      const formData = new FormData();
      formData.append('title', values.title.trim());
      formData.append('description', values.description.trim());
      formData.append('video', values.video);
      formData.append('material', values.material);
      formData.append('folderId', folderId);

      if (selectedCourseTopic?.id) {
        await axios.put(
          `/v2/online-course/session/${onlineCourseSessionId}/topic/${selectedCourseTopic?.id}`,
          formData,
          options,
        );
      } else {
        formData.append('order', values.order);
        await axios.post(
          `/v2/online-course/session/${onlineCourseSessionId}/topic`,
          formData,
          options,
        );
      }
      alert('Upload telah berhasil');
      setUploadProgress(0);
      refresh();
      toggle();
    } catch (err) {
      setUploadProgress(0);
      console.log(err);
      alert(err);
    }
  };
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, setFieldValue }) => (
        <Modal
          isOpen={isOpen}
          toggle={() => {
            if (!isSubmitting) {
              toggle();
            }
          }}
        >
          <ModalBody>
            <Form>
              <div className="d-flex flex-column">
                <label htmlFor="title" className="font-weight-bold">
                  Judul Topic
                </label>
                <Field
                  name="title"
                  placeholder="Input judul disini"
                  className="form-control mb-2"
                />
                <ErrorMessage
                  name="title"
                  render={(msg) => <Alert color="danger">{msg}</Alert>}
                />
                <label htmlFor="order" className="font-weight-bold">
                  Order Topic
                </label>
                <Field
                  name="order"
                  placeholder="Input order disini"
                  className="form-control mb-2"
                />
                <ErrorMessage
                  name="order"
                  render={(msg) => <Alert color="danger">{msg}</Alert>}
                />
                <label htmlFor="description" className="font-weight-bold">
                  Description
                </label>
                <Field
                  as="textarea"
                  name="description"
                  placeholder="Input deskripsi disini"
                  className="form-control mb-2"
                  rows="6"
                />
                <ErrorMessage
                  name="description"
                  render={(msg) => <Alert color="danger">{msg}</Alert>}
                />
                <label htmlFor="video" className="font-weight-bold">
                  Video
                </label>
                <ErrorMessage
                  name="video"
                  render={(msg) => <Alert color="danger">{msg}</Alert>}
                />
                <DropzoneVideo setFieldValue={setFieldValue} />
                <label htmlFor="material" className="font-weight-bold">
                  Materi (*Optional)
                </label>
                <DropzoneMaterial setFieldValue={setFieldValue} />
                <Button
                  type="submit"
                  color="success"
                  style={{ backgroundColor: '#32b280' }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Progress value={uploadProgress}>
                      Mohon ditunggu. Jangan tutup page sampai selesai.
                    </Progress>
                  ) : (
                    'Submit'
                  )}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </Formik>
  );
};

export default OnlineCourseTopicFormModal;
