import React from 'react';
import { Button } from 'reactstrap';

const CourseTopicCard = ({
  courseTopic,
  onEditClick,
  setOpenModalDelete,
  setSelectedDeleteId,
}) => {
  const parseDuration = (seconds) => {
    const pad = (num) => {
      return `0${num}`.slice(-2);
    };
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const sec = seconds % 60;
    const min = minutes % 60;

    return `${pad(hours)}:${pad(min)}:${pad(sec)}`;
  };

  return (
    <tr key={courseTopic?.title}>
      <td>{courseTopic?.order}</td>
      <td>{courseTopic?.title}</td>
      <td>{courseTopic?.description}</td>
      <td>{courseTopic?.duration && parseDuration(courseTopic?.duration)}</td>
      <td>placeholder</td>
      <td>
        {courseTopic?.locationMateri && (
          <a
            href={`${process.env.REACT_APP_BASE_URL}${courseTopic?.locationMateri}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            view
          </a>
        )}
      </td>
      <td>
        <Button size="sm" color="info" className="mr-2" onClick={onEditClick}>
          Edit
        </Button>
        <Button
          size="sm"
          onClick={() => {
            setOpenModalDelete(true);
            setSelectedDeleteId(courseTopic.id);
          }}
        >
          Delete
        </Button>
      </td>
    </tr>
  );
};

export default CourseTopicCard;
