import * as Yup from 'yup';

export const transactionInstallmentPaymentSchema = Yup.array().of(
  Yup.object().shape({
    id: Yup.number().notRequired().nullable(),
    date: Yup.string().notRequired().nullable(),
    paidAt: Yup.date().notRequired().nullable(),
    status: Yup.string().notRequired(),
    nominal: Yup.number().notRequired('Nominal is required'),
    originalValue: Yup.object().shape({}).notRequired().nullable(),
    transactionId: Yup.number().notRequired().nullable(),
    orderConfirmationNumber: Yup.string().notRequired(),

    // Additional
    isSaved: Yup.boolean().notRequired(),
    readOnly: Yup.boolean().notRequired(),
    allowEdit: Yup.boolean().notRequired(),
    tempNominal: Yup.number()
      .min(1, 'Nominal is required')
      .required('Nominal is required'),
  }),
);

export const transactionInstallmentSchema = Yup.object().shape({
  // Additional
  readOnly: Yup.bool().notRequired(),

  // Transcaction Installment
  type: Yup.string()
    .required('Tipe Cicilan is required')
    .oneOf([
      'Reject Danacita',
      'Cash Bertahap',
      'Balloon Payment',
      'Special Case',
    ]),
  billingDate: Yup.date()
    .required('Tanggal Pembayaran pertama is required')
    .nullable(),
  isInstallment: Yup.bool().notRequired(),
  tenure: Yup.number()
    .min(1, 'Tenor cicilan is required')
    .required('Tenor cicilan is required'),
  interest: Yup.number().notRequired(),
  discount: Yup.number().notRequired(),
  tuitionFee: Yup.number()
    .min(1, 'Biaya pendidikan is required')
    .required('Biaya pendidikan is required'),
  downPayment: Yup.number().notRequired(),
  accountReceivable: Yup.number().notRequired(),
  dpTransactionId: Yup.number().when('type', {
    is: (type) => type === 'Reject Danacita',
    then: Yup.number()
      .min(1, 'Transaksi Lain is required')
      .required('Transaksi Lain is required')
      .nullable(),
    otherwise: Yup.number().notRequired().nullable(),
  }),
  dpOtherTransaction: Yup.number().notRequired(),

  // Transaction Installment Payments
  installmentPayments: transactionInstallmentPaymentSchema.notRequired(),
});
