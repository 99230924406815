import React from 'react';
import { Pagination, PaginationItem, PaginationLink, Button } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { now } from 'moment';
import qs from 'query-string';

const RenderPrevious = ({ activePage, queryParam }) => {
  if (activePage > 1) {
    return (
      <PaginationItem>
        <Link
          to={`?${qs.stringify({
            ...queryParam,
            page: activePage - 1,
          })}`}
        >
          <PaginationLink previous />
        </Link>
      </PaginationItem>
    );
  }
  return null;
};

const RenderNext = ({ activePage, pages, queryParam }) => {
  if (activePage < pages) {
    return (
      <PaginationItem>
        <Link
          to={`?${qs.stringify({
            ...queryParam,
            page: activePage + 1,
          })}`}
        >
          <PaginationLink next />
        </Link>
      </PaginationItem>
    );
  }
  return null;
};

const LoopPagination = ({ activePage, pages, queryParam }) => {
  const arrJSX = [];
  for (let i = 1; i <= pages; i += 1) {
    if (i === activePage - 3) {
      arrJSX.push(
        <PaginationItem key={`page${i * now()}`} disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      );
    } else if (Math.abs(activePage - i) <= 2) {
      arrJSX.push(
        <PaginationItem key={`page${i * now()}`} active={i === activePage}>
          <Link
            to={`?${qs.stringify({
              ...queryParam,
              page: i,
            })}`}
          >
            <PaginationLink style={{ zIndex: 0 }}>{i}</PaginationLink>
          </Link>
        </PaginationItem>,
      );
    } else if (i === activePage + 3) {
      arrJSX.push(
        <PaginationItem key={`page${i * now()}`} disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      );
    }
  }
  return arrJSX;
};

const CustomPaginationV2 = ({ pages = 1, active, ...props }) => {
  const activePage = parseInt(active || 1, 10);

  const location = useLocation();

  const queryParam = qs.parse(location.search);

  return (
    <div {...props}>
      <Pagination listClassName="m-0">
        <PaginationItem>
          <Link
            to={`?${qs.stringify({
              ...queryParam,
              page: 1,
            })}`}
          >
            <Button style={{ borderRadius: '3px 0px 0px 3px' }}>First</Button>
          </Link>
        </PaginationItem>
        <RenderPrevious activePage={activePage} queryParam={queryParam} />
        <LoopPagination
          activePage={activePage}
          pages={pages}
          queryParam={queryParam}
        />
        <RenderNext
          activePage={activePage}
          pages={pages}
          queryParam={queryParam}
        />
        <PaginationItem>
          <Link
            to={`?${qs.stringify({
              ...queryParam,
              page: pages,
            })}`}
          >
            <Button style={{ borderRadius: '0px 3px 3px 0px' }}>Last</Button>
          </Link>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default CustomPaginationV2;
