import React from 'react';
import { Alert } from 'reactstrap';
import autherror from 'supports/assets/svg/autherror.svg';

export default ({ message }) => {
  if (message) {
    return (
      <Alert className="mt-2 py-3 backgroundcolordanger font-10 font-sm-11">
        <img src={autherror} className="pr-2 pb-1" alt="errorimage" />
        {message}
      </Alert>
    );
  }
  return <div />;
};
