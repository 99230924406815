import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomPaginationV2 from 'components/commons/CustomPaginationV2';
import React, { useState, useCallback } from 'react';
import Loader from 'react-loader-spinner';
import qs from 'query-string';
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  FormGroup,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Input,
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router';
import Axios from 'supports/api';
import UserDataModal from './UserDataModal';

const UserMenuListPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [listRoles, setListRoles] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState('idle');

  const itemsPerPage = 10;
  const location = useLocation();
  const history = useHistory();

  const queryParam = qs.parse(location.search);
  const { page } = queryParam;

  const [isOpen, setIsOpen] = useState(true);
  const toggleCollapse = () => setIsOpen(!isOpen);

  const fetchRoles = async () => {
    try {
      const roleOptions = [];

      await Axios.get(`/admin/user-menu/role`)
        .then((res) => {
          const { result } = res.data;
          result.forEach((item) => {
            roleOptions.push({
              label: item.name,
              value: item.id,
            });
          });
        })
        .then(() => {
          setListRoles(roleOptions);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const handleFetchTransactions = useCallback(async () => {
    try {
      setStatus('loading');

      if (filter !== '') {
        const options = {
          params: {
            filter,
          },
        };

        await Axios.get(`/admin/user-menu`, options).then((res) => {
          const { result } = res.data;

          setFilteredData(result);
          fetchRoles();
        });
        setStatus('success');
      } else {
        setStatus('error');
      }
    } catch (err) {
      setStatus('error');
      console.log(err);
    }
  }, [filter]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleToPageOne = () => {
    history.push(
      `?${qs.stringify({
        ...queryParam,
        page: 1,
      })}`,
    );
  };

  const onEnter = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      await handleFetchTransactions();
      handleToPageOne();
    }
  };

  const renderTableBody = () => {
    const sliced = filteredData.slice((page - 1) * 10, (page - 1) * 10 + 10);
    return sliced.map((trx) => {
      return (
        <tr key={trx.id}>
          <td>{trx.username}</td>
          <td>{trx.firstName}</td>
          <td>{trx.lastName}</td>
          <td>{trx.email}</td>
          <td>{trx.isAdmin === true ? 'True' : 'false'}</td>
          <td>
            <Button
              color="secondary"
              key="buttonDetail"
              size="sm"
              className="my-1 mr-2"
              onClick={() => {
                toggleModal();
                setSelectedUsers(trx);
              }}
            >
              Details
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <Row>
      <Col sm="12">
        <Card body>
          <div className={isOpen ? 'dropup' : null}>
            <button
              type="button"
              className="btn btn-secondary mt-2 dropdown-toggle btn-collapse-pym"
              onClick={toggleCollapse}
            >
              Filter
            </button>
          </div>
          <Collapse isOpen={isOpen}>
            <Card className="mt-1">
              <CardBody>
                <Form>
                  <Col sm="12">
                    <FormGroup row>
                      <Col sm={6}>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>Search: </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Name/Email"
                            id="userMenu"
                            value={filter}
                            onKeyPress={(e) => onEnter(e)}
                            onChange={(e) => setFilter(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col sm={5}>
                        <Button
                          color="success"
                          className="mt-0"
                          style={{
                            backgroundColor: 'rgb(50, 178, 128)',
                            width: '20%',
                          }}
                          onClick={() => {
                            handleFetchTransactions();
                            handleToPageOne();
                          }}
                          onKeyDown={() => {}}
                        >
                          {status === 'loading' ? (
                            <Loader
                              type="ThreeDots"
                              color="#ffffff"
                              height={20}
                              width={40}
                            />
                          ) : (
                            <>
                              Search
                              <FontAwesomeIcon
                                icon={faSearch}
                                className="ml-2"
                              />
                            </>
                          )}
                        </Button>
                      </Col>
                    </FormGroup>
                  </Col>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          {filteredData.length ? (
            <>
              <div className="mt-3 d-flex flex-row justify-content-between align-items-center">
                <CustomPaginationV2
                  pages={Math.ceil(filteredData.length / itemsPerPage) || 1}
                  active={page}
                />
              </div>
              <div>
                <Table striped bordered responsive hover>
                  <thead
                    className="font-weight-bold"
                    style={{ fontSize: '14px', color: '#505050' }}
                  >
                    <tr>
                      <th>User Name</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Admin</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableBody()}</tbody>
                </Table>
              </div>{' '}
            </>
          ) : null}
          {status === 'success' && filteredData.length === 0 ? (
            <div className="mt-3 d-flex flex-row justify-content-center">
              <h3>No Data Found</h3>
            </div>
          ) : null}
          <UserDataModal
            isModalOpen={isModalOpen}
            toggle={toggleModal}
            listRoles={listRoles}
            selectedUsers={selectedUsers}
            refresh={handleFetchTransactions}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default UserMenuListPage;
