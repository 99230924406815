import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import axios from 'supports/api';
import qs from 'query-string';
import CustomPagination from 'components/commons/CustomPagination';
import { useFormik } from 'formik';
import Loader from 'react-loader-spinner';
import PromosTable from './components/PromosTable';
import BackgroundTable from './components/BackgroundTable';
import AddPromoModal from './components/AddPromoModal';

const SearchButton = ({ onClick }) => {
  return (
    <Button
      color="success"
      className="rounded-right"
      onClick={onClick}
      style={{ width: 80, backgroundColor: '#53b59f' }}
    >
      Search
    </Button>
  );
};

const CreatePromoPage = () => {
  const [loading, setLoading] = useState(true);
  const [addPromoModal, setAddPromoModal] = useState(false);
  const [type, setType] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const limit = 10;
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);

  const { push } = useHistory();
  const { search: query } = useLocation();

  const paramsUrl = qs.parse(query);
  const { page, search } = paramsUrl;

  const fetchPromos = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get('/v2/transaction-promos', {
        params: { page, limit, search },
      });
      setRows(data.rows);
      setCount(data.count);
      setPageNumber(Number(page) || 1);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [page, limit, search]);

  useEffect(() => {
    fetchPromos();
  }, [fetchPromos]);

  const formik = useFormik({
    initialValues: {
      search: String(search || ''),
    },
    onSubmit: (values) => {
      let queryBuilder = {};
      const { search } = values;
      if (search) {
        queryBuilder = { ...queryBuilder, search };
      }

      push('?'.concat(qs.stringify(queryBuilder)));
    },
  });
  const { handleSubmit, getFieldProps } = formik;

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const filterCard = () => {
    return (
      <Card className="my-4">
        <CardBody>
          <div className="container-fluid white-box">
            <div className="row">
              <div className="col-5 my-auto">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Search: </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    {...getFieldProps('search')}
                    type="text"
                    className="form-control"
                    placeholder="Promo"
                    onKeyPress={onKeyPress}
                  />
                  <InputGroupAddon addonType="append">
                    <SearchButton onClick={() => handleSubmit()} />
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  return (
    <div className="white-box container py-2" style={{ marginTop: '40px' }}>
      <div className="d-flex flex-row justify-content-between mb-2">
        <div
          className="font-weight-bold"
          style={{ fontSize: '32px', color: '#505050' }}
        >
          Promos
        </div>
        <Button
          className="float-right mt-auto"
          onClick={() => {
            setAddPromoModal(true);
            setType('create');
          }}
        >
          + Create Promo
        </Button>
      </div>
      {filterCard()}

      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ minHeight: '100vh' }}
        >
          <Loader type="ThreeDots" color="black" />
        </div>
      ) : (
        <BackgroundTable style={{ minHeight: '600px' }}>
          <PromosTable
            rows={rows}
            page={pageNumber}
            setType={setType}
            type={type}
            refresh={fetchPromos}
            limit={limit}
          />
          <CustomPagination
            pages={Math.ceil(count / limit) || 1}
            active={pageNumber}
          />
        </BackgroundTable>
      )}

      <AddPromoModal
        isOpen={addPromoModal}
        toggle={() => {
          setAddPromoModal(false);
          setType('');
        }}
        type={type}
        refresh={fetchPromos}
      />
    </div>
  );
};

export default CreatePromoPage;
