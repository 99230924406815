// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';
// import { encrypt } from 'supports/helpers/encryption';
// import { appKey } from 'supports/config';

const initialState = {
  loading: false,
  status: 'idle',
  error: null,
  list: [],
  selectedGL: {},
  glJournalItems: [],
};

const { actions, reducer } = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    fetchGLJournalStart: (state) => {
      state.loading = true;
      state.status = 'loading';
    },
    fetchGLJournalListSuccess: (state, action) => {
      state.list = action.payload;
    },
    fetchSuccess: (state) => {
      state.loading = false;
      state.status = 'success';
    },
    fetchFailure: (state, action) => {
      state.loading = false;
      state.status = 'failure';
      state.error = action.payload;
    },
    selectGl: (state, action) => {
      state.selectedGL = { ...state.selectedGL, ...action.payload };
    },
    setGLJournalItems: (state, action) => {
      state.glJournalItems = action.payload;
    },
  },
});

export default reducer;

export const {
  fetchGLJournalStart,
  fetchGLJournalListSuccess,
  fetchSuccess,
  fetchFailure,
  selectGl,
  setGLJournalItems,
} = actions;

export const fetchGLJournalList = (params) => async (dispatch) => {
  const { journalDateFrom, journalDateTo } = params;
  dispatch(fetchGLJournalStart());
  try {
    const options = {
      params: {
        journalDateFrom,
        journalDateTo,
        // isApproved,
      },
    };
    const res = await axios.get(`/gl-journal/gl-list`, options);
    dispatch(fetchGLJournalListSuccess(res.data.result));
    dispatch(fetchSuccess());
  } catch (err) {
    console.log(err);
    alert(`Terjadi kesalahan pada system. ${err}`);
    dispatch(fetchFailure(err));
  }
};
