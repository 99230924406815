import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ModalPreviewPOProof = ({ isOpen, toggle, proofData }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader>Preview PO Proof</ModalHeader>
      <ModalBody>
        <a
          href={process.env.REACT_APP_BASE_URL + proofData}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={process.env.REACT_APP_BASE_URL + proofData}
            alt="po-proof"
            width="100%"
          />
        </a>
      </ModalBody>
    </Modal>
  );
};

export default ModalPreviewPOProof;
