import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody, CustomInput, Button } from 'reactstrap';
import { getUserData } from 'redux/modules/trxUser';
import axios from 'supports/api';

const API_URL_1 = process.env.REACT_APP_BASE_URL;

export default ({ isOpen, toggle }) => {
  const [fileURL, setFileURL] = useState('');
  const [NPWPFile, setNPWPFile] = useState(null);
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.trxUser);
  const { npwpImg } = userData;

  const handleFile = (file) => {
    if (file) {
      setNPWPFile(file);
      setFileURL(URL.createObjectURL(file));
    }
  };

  const resetFile = () => {
    document.getElementById('NPWP').value = '';
    setNPWPFile(null);
  };

  const saveFile = () => {
    const formData = new FormData();
    formData.append('npwpImg', NPWPFile);
    axios
      .put(`/admin/user/documents/${userData.id}`, formData)
      .then(() => {
        dispatch(getUserData(userData.email));
        resetFile();
        alert('Upload berhasil');
        toggle();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  useEffect(() => {
    if (npwpImg) {
      setFileURL(API_URL_1 + npwpImg);
    }
  }, [npwpImg]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalBody>
        <CustomInput
          type="file"
          label={
            npwpImg
              ? 'Pilih photo baru jika ingin reupload'
              : 'Belum ada photo NPWP, silakan upload'
          }
          id="NPWP"
          onChange={(e) => handleFile(e.target?.files[0])}
        />

        {fileURL && (
          <div
            style={{
              border: '1px dashed black',
              width: '100%',
            }}
          >
            <img
              src={fileURL}
              alt="NPWP"
              style={{
                width: '100%',
                maxHeight: '500px',
                objectFit: 'contain',
              }}
            />
          </div>
        )}
        {NPWPFile && (
          <Button onClick={saveFile} className="w-100 mt-2" type="primary">
            Upload
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
};
