import React from 'react';
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from 'reactstrap';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import { object, string, date } from 'yup';
import moment from 'moment';

const PromoInputModal = ({
  isOpen,
  toggle,
  handleAddAdditionalPromos,
  selectedPromo,
  resetSelectedPromo,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      promoMessage: selectedPromo.promoMessage || '',
      startDate: selectedPromo.startDate
        ? new Date(selectedPromo.startDate)
        : null,
      endDate: selectedPromo.endDate ? new Date(selectedPromo.endDate) : null,
    },
    validationSchema: object().shape({
      promoMessage: string().required(),
      startDate: date().required(),
      endDate: date().required(),
    }),
    onSubmit: (value, { resetForm }) => {
      handleAddAdditionalPromos(
        value,
        typeof selectedPromo.index === 'number' ? selectedPromo.index : null,
      );
      toggle();
      resetForm();
    },
  });

  const {
    setFieldValue,
    handleSubmit,
    values,
    touched,
    errors,
    resetForm,
    isValid,
  } = formik;

  const onCancel = () => {
    toggle();
    resetForm();
    resetSelectedPromo();
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel}>
      <ModalHeader toggle={onCancel}>Add Promo</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Promo Message</Label>
            <Input
              placeholder="Ex: Dapatkan potongan 30% untuk intake ini"
              type="text"
              value={values.promoMessage}
              onChange={(e) => setFieldValue('promoMessage', e.target.value)}
            />
            {Boolean(touched.promoMessage) && Boolean(errors.promoMessage) && (
              <FormText tooltip>{errors.promoMessage}</FormText>
            )}
            <Label>Start Date</Label>

            <DatePicker
              className="form-control"
              selected={values.startDate}
              onChange={(e) => {
                setFieldValue('startDate', new Date(moment(e).startOf('day')));
              }}
              maxDate={values.endDate}
              showMonthYearDropdown
              dateFormat="dd/MMM/yyy"
            />
            {Boolean(touched.startDate) && Boolean(errors.startDate) && (
              <FormText tooltip>{errors.startDate}</FormText>
            )}
            <Label>End Date</Label>
            <DatePicker
              className="form-control"
              selected={values.endDate}
              minDate={values.startDate}
              onChange={(e) => {
                setFieldValue('endDate', new Date(moment(e).endOf('day')));
              }}
              showMonthYearDropdown
              dateFormat="dd/MMM/yyy"
            />
            {Boolean(touched.endDate) && Boolean(errors.endDate) && (
              <FormText tooltip>{errors.endDate}</FormText>
            )}
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={handleSubmit} disabled={!isValid}>
          Set Promo
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default PromoInputModal;
