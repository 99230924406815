import React, { useState, useEffect, useCallback } from 'react';
import axios from 'supports/api';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router';
// import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, CustomInput, Input } from 'reactstrap';
import CustomPagination from '../commons/CustomPagination';
import SearchBar from './SearchBar';

const NoItems = ({ isShowing, isLoading }) => {
  if (isLoading) {
    return (
      <div
        className="d-flex w-100 justify-content-center align-items-center border"
        style={{ height: '410px' }}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <Loader type="Oval" color="#4aa390" height={80} width="100%" />
        </div>
      </div>
    );
  }
  if (isShowing) {
    return (
      <div
        className="d-flex w-100 justify-content-center align-items-center border"
        style={{ height: '410px' }}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <FontAwesomeIcon
            className="mr-3 text-center"
            icon={['fas', 'times-circle']}
            color="red"
            size="2x"
          />
          <div className="font-weight-bold">No items</div>
        </div>
      </div>
    );
  }
  return null;
};

const TableHeader = ({ isAllChecked, onCheckPage }) => {
  return (
    <thead>
      <tr>
        <th>Receipt Number</th>
        <th width="200px">Name</th>
        <th width="200px">Mutation Date</th>
        <th width="200px">
          <div className="text-right">Nominal</div>
        </th>
        <th width="150px">
          <div className="text-right">Bunga</div>
        </th>
        <th width="200px">
          <div className="text-right">Nominal Mutasi</div>
        </th>
        <th width="58px">
          <CustomInput
            className="ml-2"
            type="checkbox"
            id="checkboxall"
            checked={isAllChecked}
            onChange={() => onCheckPage(isAllChecked)}
          />
        </th>
      </tr>
    </thead>
  );
};

const TableData = ({
  payments = [],
  onCheckOne,
  page,
  rowsPerPage,
  handleBunga,
  handleNominalMutasi,
}) => {
  const arrJSX = payments
    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
    .map((row) => {
      const {
        id,
        receiptNumber,
        nominal,
        interest,
        nominalMutasi,
        isChecked,
        mutationDate,
      } = row;
      return (
        <tr key={receiptNumber}>
          <td>{receiptNumber}</td>
          <td>name</td>
          <td>{moment(mutationDate).format('DD MMM YYYY')}</td>
          <td>
            <div className="float-left">Rp.</div>
            <div className="float-right">{nominal.toLocaleString()}</div>
          </td>
          <td width="150px">
            <div style={{ width: '150px' }}>
              <Input
                type="string"
                value={interest.toLocaleString()}
                style={{ textAlign: 'right' }}
                onChange={(e) =>
                  handleBunga({
                    selectedId: id,
                    target: e.target,
                    nominal,
                  })
                }
              />
            </div>
          </td>
          <td width="150px">
            <Input
              type="string"
              value={nominalMutasi.toLocaleString()}
              style={{ textAlign: 'right' }}
              onChange={(e) =>
                handleNominalMutasi({
                  selectedId: id,
                  target: e.target,
                  nominal,
                })
              }
              onFocus={(e) => {
                e.target.setSelectionRange(
                  e.target.value.length,
                  e.target.value.length,
                );
              }}
            />
          </td>
          <td>
            <CustomInput
              className="ml-2"
              type="checkbox"
              id={`checkbox${id}`}
              checked={isChecked}
              onChange={() => onCheckOne(id)}
            />
          </td>
        </tr>
      );
    });

  return <tbody>{arrJSX}</tbody>;
};

const EDCJournalPage = () => {
  const [payments, setPayments] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [banks, setBanks] = useState([]);
  const [bankId, setBankId] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [mutationDate, setMutationDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const page = params.get('page');

  const redirectPage = useCallback(() => {
    if (page > Math.ceil(payments.length / rowsPerPage) || !page) {
      history.push('?page=1');
    }
  }, [history, page, payments.length, rowsPerPage]);

  const handleSearchData = useCallback(() => {
    setPayments([]);
    setIsLoading(true);
    setTimeout(async () => {
      try {
        const options = {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
            bankId,
            mutationDate,
          },
        };

        const res = await axios.get(`/admin/payments-to-liquid`, options);
        const newArr = res.data.result.map((item) => ({
          ...item,
          interest: 0,
          nominalMutasi: item.nominal,
          isChecked: false,
        }));
        setPayments(newArr);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        alert(err);
        console.log(err);
      }
    }, 300);
  }, [bankId, endDate, mutationDate, startDate]);

  const getBanks = useCallback(() => {
    axios.get(`/bank/all`).then((res) => {
      const { result } = res.data;
      setBanks(result);
    });
  }, []);

  // runs every render
  useEffect(() => {
    redirectPage();
  }, [redirectPage]);

  // runs only once
  useEffect(() => {
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckOne = useCallback(
    (selectedId) => {
      const tempArr = payments.map((item) => {
        if (item.id === selectedId) {
          return { ...item, isChecked: !item.isChecked };
        }
        return item;
      });
      setPayments(tempArr);
    },
    [payments],
  );

  const onCheckPage = useCallback(
    (isAllChecked) => {
      const tempArr = payments.map((item) => {
        if (isAllChecked) {
          return { ...item, isChecked: false };
        }
        return { ...item, isChecked: true };
      });
      setPayments(tempArr);
    },
    [payments],
  );

  const handleProcess = useCallback(() => {
    const paymentsToProcess = payments.filter((item) => item.isChecked);

    if (paymentsToProcess.length === 0) {
      return alert('No checked payments');
    }
    setIsProcessing(true);

    return setTimeout(() => {
      return axios
        .post(`/admin/process-edc-journal`, {
          payments: paymentsToProcess,
          bankId,
        })
        .then(() => {
          alert('Process complete');
          setIsProcessing(false);
          handleSearchData();
        })
        .catch((err) => {
          setIsProcessing(false);
          alert(err);
          console.log(err);
        });
    }, 300);
  }, [bankId, handleSearchData, payments]);

  const handleBunga = useCallback(
    ({ selectedId, target, nominal }) => {
      let intValue = parseInt(target.value.split(',').join(''), 10) || 0;

      if (intValue > nominal) {
        intValue = nominal;
      }
      const tempArr = payments.map((item) => {
        if (item.id === selectedId) {
          return {
            ...item,
            interest: intValue,
            nominalMutasi: item.nominal - intValue,
          };
        }
        return item;
      });

      return setPayments(tempArr);
    },
    [payments],
  );

  const handleNominalMutasi = useCallback(
    ({ selectedId, target, nominal }) => {
      let intValue = parseInt(target.value.split(',').join(''), 10) || 0;

      if (intValue > nominal) {
        intValue = nominal;
      }

      const tempArr = payments.map((item) => {
        if (item.id === selectedId) {
          return {
            ...item,
            interest: item.nominal - intValue,
            nominalMutasi: intValue,
          };
        }
        return item;
      });
      return setPayments(tempArr);
    },
    [payments],
  );

  return (
    <div className="container-fluid">
      <div className="white-box mt-3 py-5 px-5">
        <div className="pb-4">
          <div className="line-green mb-3" />
          <div className="general-title text-gray">EDC Journal</div>
        </div>
        <div className="d-flex flex-column flex-md-row ">
          <SearchBar
            startDate={startDate}
            endDate={endDate}
            handleStartDate={(date) => {
              if (date > endDate) {
                setEndDate(date);
              }
              setStartDate(date);
            }}
            handleEndDate={(date) => setEndDate(date)}
            banks={banks}
            bankId={bankId}
            handleBankId={(e) => setBankId(e.target.value)}
            totalNominal={0}
            totalPotonganBunga={0}
            totalNominalMutasi={0}
            payments={payments}
            onSearch={handleSearchData}
            onProcess={handleProcess}
            isProcessing={isProcessing}
            mutationDate={mutationDate}
            handleMutationDate={(date) => setMutationDate(date)}
          />
          <div className="flex-fill">
            <div className="d-flex flex-row mt-3 mb-1">
              <div>Total Items: {payments.length}</div>
              <div className="ml-auto mr-1 align-self-center">Rows:</div>
              <select
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </div>
            <Table striped bordered responsive hover className="mb-0">
              <TableHeader
                isAllChecked={
                  payments.filter((item) => !item.isChecked).length === 0 &&
                  payments.length !== 0
                }
                onCheckPage={onCheckPage}
                style={{ whiteSpace: 'nowrap' }}
              />
              <TableData
                payments={payments}
                onCheckOne={onCheckOne}
                page={page}
                rowsPerPage={rowsPerPage}
                handleBunga={handleBunga}
                handleNominalMutasi={handleNominalMutasi}
              />
            </Table>
            <NoItems isShowing={payments.length === 0} isLoading={isLoading} />
            <CustomPagination
              pages={Math.ceil(payments.length / rowsPerPage) || 1}
              active={page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EDCJournalPage;
