import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import ReactDatePicker from 'react-datepicker';
import {
  Button,
  Collapse,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import axios from 'supports/api';
import {
  installmentPaymentInitialValue,
  installmentTypeOptions,
} from '../static';

const TransactionInstallmentForm = ({ currency, applyReferralCode }) => {
  const {
    dirty,
    values,
    errors,
    touched,
    isValid,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const trxUser = useSelector((globalState) => globalState.trxUser);

  const { userData } = trxUser || {};
  const { id } = userData || {};

  const [transactionOptions, setTransactionOptions] = useState({
    loading: false,
    data: [],
  });

  // Calculate Account Receivable
  const calculatedHutang = useMemo(() => {
    const {
      interest,
      discount,
      tuitionFee,
      downPayment,
      dpOtherTransaction,
    } = values;

    let hutang = 0;

    const tuitionFeeAfterDiscount = tuitionFee - discount;

    hutang =
      tuitionFeeAfterDiscount + interest - (downPayment + dpOtherTransaction);

    return hutang;
  }, [values]);

  // ----- Transaction Options Process -----
  const fetchTransactions = useCallback(
    async (search, userId) => {
      setTransactionOptions((prev) => ({ ...prev, loading: true }));

      try {
        if (id || userId || search) {
          const { data } = await axios.get('/v2/transaction', {
            params: {
              search,
              userId: userId || id,

              // 3: Paid
              status: [3],
            },
          });

          const formatedTransaction = data.rows.map((row) => ({
            value: { id: row.id, totalPrice: row.totalPrice },
            label: `${row.orderConfirmationNumber} - ${
              row.invoiceNumber ? `${row.invoiceNumber} -` : ''
            } ${row.user.firstName} ${
              row.user.lastName
            } - Rp${row.totalPrice.toLocaleString()}`,
          }));

          setTransactionOptions((prev) => ({
            ...prev,
            data: formatedTransaction,
          }));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setTransactionOptions((prev) => ({ ...prev, loading: false }));
      }
    },
    [id],
  );

  const [debouncedFetchTransactions] = useState(() =>
    _.debounce((val, userId) => fetchTransactions(val, userId), 1000),
  );

  const handleChange = (search, userId) => {
    debouncedFetchTransactions(search, userId);
  };

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);
  // ----- Transaction Options Process -----

  // ----- Handle Action ------
  const handleSave = () => {
    const date = moment(values.billingDate).set('date', 1);

    // Auto generete transaction installment payments
    if (values.type !== 'Balloon Payment') {
      const billingPerMonth = Math.ceil(calculatedHutang / values.tenure);

      const generatedInstallmentPayments = Array.from(
        { length: values.tenure },
        (_val, index) => {
          let formatedDate = date;

          if (index > 0) {
            formatedDate = formatedDate.add(1, 'month');
          }

          formatedDate = formatedDate.toDate();

          return {
            ...installmentPaymentInitialValue,
            date: formatedDate,
            nominal: billingPerMonth,
            isSaved: true,
            readOnly: true,
            allowEdit: false,
            tempNominal: billingPerMonth,
          };
        },
      );

      setFieldValue('installmentPayments', generatedInstallmentPayments);
    } else {
      const firstBillingDate = date.toDate();

      const newInitialValue = {
        ...installmentPaymentInitialValue,
        date: firstBillingDate,
      };

      setFieldValue('installmentPayments', [newInitialValue]);
    }

    setFieldValue('accountReceivable', calculatedHutang);
    setFieldValue('readOnly', true);
  };

  const handleEdit = () => {
    setFieldValue('readOnly', false);
    setFieldValue('installmentPayments', []);
  };
  // ---- Handle Action -----

  return (
    <div className="p-4">
      {/* Is Installment */}
      <CustomInput
        type="checkbox"
        id="isInstallment"
        label="Cicilan"
        checked={values.isInstallment}
        onChange={(e) => {
          if (e.target.checked) {
            setFieldValue('isInstallment', e.target.checked);
          } else {
            resetForm();
          }

          applyReferralCode({ isInstallment: e.target.checked });
        }}
      />

      <Collapse isOpen={values.isInstallment}>
        {/* Installment Tuition Fee */}
        <>
          <div className="text-gray font-weight-bold mt-3">
            Biaya Pendidikan
          </div>

          <InputGroup className="mb-2">
            <InputGroupAddon addonType="prepend">{currency}</InputGroupAddon>

            <NumberFormat
              thousandSeparator
              customInput={Input}
              placeholder="Input biaya pendidikan"
              disabled={values.readOnly}
              value={values.tuitionFee}
              style={{
                ...(touched.tuitionFee &&
                  errors.tuitionFee && { borderColor: 'red' }),
              }}
              onValueChange={(val) => {
                setFieldValue('tuitionFee', val.floatValue);

                applyReferralCode({
                  isInstallment: values.isInstallment,
                  installmentTuitionFee: val.floatValue,
                });
              }}
              onBlur={() => setFieldTouched('tuitionFee', true)}
            />
          </InputGroup>
        </>

        {/* Installmnet Discount */}
        <>
          <div className="text-gray font-weight-bold mt-3">Diskon</div>

          <InputGroup className="mb-2">
            <InputGroupAddon addonType="prepend" className="text-danger">
              {currency}
            </InputGroupAddon>

            <NumberFormat
              className="text-danger"
              disabled
              thousandSeparator
              customInput={Input}
              prefix="-"
              value={values.discount}
            />
          </InputGroup>
        </>

        {/* Installment Interest */}
        <>
          <div className="text-gray font-weight-bold mt-3">Bunga</div>

          <InputGroup className="mb-2">
            <InputGroupAddon addonType="prepend">{currency}</InputGroupAddon>

            <NumberFormat
              thousandSeparator
              customInput={Input}
              placeholder="Input bunga"
              disabled={values.readOnly}
              value={values.interest}
              style={{
                ...(touched.interest &&
                  errors.interest && { borderColor: 'red' }),
              }}
              onValueChange={(val) => {
                setFieldValue('interest', val.floatValue);
              }}
              onBlur={() => setFieldTouched('interest', true)}
            />
          </InputGroup>
        </>

        {/* Installment Type & Installment Tenure */}
        <div className="d-flex flex-row justify-content-between mt-3">
          {/* Installment Type */}
          <div className="w-50">
            <FormGroup className="w-100 mb-0">
              <div className="text-gray font-weight-bold">Tipe Cicilan</div>

              <Input
                type="select"
                disabled={values.readOnly}
                valid={touched.type && !errors.type}
                invalid={touched.type && errors.type}
                value={values.type}
                onChange={({ target }) => {
                  setFieldValue('type', target.value);

                  if (target.value !== 'Reject Danacita') {
                    setFieldValue('dpTransactionId', null);
                    setFieldValue('dpOtherTransaction', 0);
                  }
                }}
                onBlur={() => setFieldTouched('type', true)}
              >
                <option value="" disabled hidden>
                  Pilih Tipe Cicilan
                </option>

                {installmentTypeOptions.map((installmentTypeOption) => (
                  <option
                    key={`${installmentTypeOption.value}`}
                    value={installmentTypeOption.value}
                  >
                    {installmentTypeOption.label}
                  </option>
                ))}
              </Input>

              <FormFeedback invalid="true">
                *Mohon Pilih Tipe Cicilan
              </FormFeedback>
            </FormGroup>
          </div>

          <div className="mx-3" />

          {/* Installment Tenure */}
          <div className="w-50">
            <div className="text-gray font-weight-bold">Tenor Cicilan</div>
            <InputGroup className="mb-0">
              <NumberFormat
                customInput={Input}
                placeholder="Input tenor cicilan"
                disabled={values.readOnly}
                value={values.tenure}
                isAllowed={(val) => val.formattedValue <= 12}
                style={{
                  ...(touched.tenure &&
                    errors.tenure && { borderColor: 'red' }),
                }}
                onValueChange={(val) => {
                  setFieldValue('tenure', val.floatValue);
                }}
                onBlur={() => setFieldTouched('tenure', true)}
              />
            </InputGroup>

            <div
              style={{
                fontSize: '12px',
                fontStyle: 'italic',
                color: 'GrayText',
              }}
            >
              Tenor Max. 12
            </div>
          </div>
        </div>

        {/* Other Transaction */}
        {values.type === 'Reject Danacita' && (
          <div className="mt-3">
            <div className="text-gray font-weight-bold">Transaksi Lain</div>

            <Select
              isDisabled={values.readOnly}
              isLoading={transactionOptions.loading}
              options={transactionOptions.data}
              onInputChange={(val) => handleChange(val, id)}
              onChange={(val) => {
                setFieldValue('dpTransactionId', Number(val.value.id));
                setFieldValue(
                  'dpOtherTransaction',
                  Number(val.value.totalPrice),
                );
              }}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  ...(touched.dpTransactionId &&
                    errors.dpTransactionId && { borderColor: 'red' }),
                }),
              }}
              onBlur={() => setFieldTouched('dpTransactionId', true)}
            />

            <div
              style={{
                fontSize: '12px',
                fontStyle: 'italic',
                color: 'GrayText',
              }}
            >
              (Search by: Order confirmation number/Invoice number/Email/User
              name)
            </div>
          </div>
        )}

        {/* Down Payment */}
        <>
          <div className="text-gray font-weight-bold mt-3">Total DP</div>

          <InputGroup className="mb-2">
            <InputGroupAddon addonType="prepend">{currency}</InputGroupAddon>

            <NumberFormat
              disabled
              thousandSeparator
              customInput={Input}
              value={values.downPayment + values.dpOtherTransaction}
            />
          </InputGroup>
        </>

        {/* Installment Account Receivable */}
        <>
          <div className="text-gray font-weight-bold mt-3">Hutang</div>

          <InputGroup className="mb-2">
            <InputGroupAddon addonType="prepend">{currency}</InputGroupAddon>

            <NumberFormat
              disabled
              thousandSeparator
              customInput={Input}
              value={calculatedHutang}
            />
          </InputGroup>
        </>

        {/* Billing Date */}
        <>
          <div className="font-weight-bold mt-3">
            Bulan & Tahun Pembayaran Cicilan Pertama
          </div>

          <ReactDatePicker
            disabled={values.readOnly}
            selected={values.billingDate}
            showMonthYearPicker
            dateFormat="MMMM yyyy"
            minDate={moment().startOf('month').toDate()}
            customInput={
              <Input invalid={touched.billingDate && errors.billingDate} />
            }
            onChange={(date) => setFieldValue('billingDate', date)}
            onBlur={() => setFieldTouched('billingDate', true)}
            filterDate={(date) =>
              moment(date).isSameOrAfter(moment(), 'month') ||
              moment(date).isSame(moment(values.billingDate), 'month')
            }
          />
        </>

        {/* Button Action */}
        <div className="d-flex flex-row mt-3 justify-content-end">
          <Button
            disabled={(!dirty || !isValid) && !values.readOnly}
            className="w-100"
            style={{ maxWidth: '100px' }}
            color={values.readOnly ? 'primary' : 'success'}
            onClick={values.readOnly ? handleEdit : handleSave}
          >
            {values.readOnly ? 'Edit' : 'Save'}
          </Button>
        </div>
      </Collapse>
    </div>
  );
};

export default TransactionInstallmentForm;
