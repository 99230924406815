import React, { Children } from 'react';

import { Input, FormGroup } from 'reactstrap';
import { categoryOptions, typeOptions } from '../static/options';

const ProgramCategoryFilter = ({
  data = [],
  filter,
  setDiscount,
  setSelectedAlumniEvent,
}) => {
  const { filterState, setFilterState } = filter;

  return (
    <FormGroup>
      <div className="text-gray font-weight-bold mb-2">Program Category</div>
      <Input
        type="select"
        valid={Boolean(filterState.programCategoryId)}
        value={filterState.programCategoryId}
        onChange={({ target }) => {
          setFilterState({
            ...filterState,
            programCategoryId: Number(target.value),
          });

          setDiscount(0);
          setSelectedAlumniEvent({});
        }}
      >
        <option value={0} disabled hidden>
          Select Event Program Category
        </option>
        {Children.toArray(
          data.map((item) => <option value={item.id}>{item.name}</option>),
        )}
      </Input>
    </FormGroup>
  );
};

const TypeFilter = ({ filter, setDiscount, setSelectedAlumniEvent }) => {
  const { filterState, setFilterState } = filter;

  return (
    <FormGroup>
      <div className="text-gray font-weight-bold mb-2">Type</div>
      <Input
        type="select"
        valid={Boolean(filterState.type)}
        value={filterState.type}
        onChange={({ target }) => {
          setFilterState({
            ...filterState,
            type: target.value,
          });

          setDiscount(0);
          setSelectedAlumniEvent({});
        }}
      >
        <option value="" disabled hidden>
          Select Event Type
        </option>
        {Children.toArray(
          typeOptions.map((item) => (
            <option value={item.value}>{item.label}</option>
          )),
        )}
      </Input>
    </FormGroup>
  );
};

const CategoryFilter = ({ filter, setDiscount, setSelectedAlumniEvent }) => {
  const { filterState, setFilterState } = filter;

  return (
    <FormGroup>
      <div className="text-gray font-weight-bold mb-2">Category</div>
      <Input
        type="select"
        valid={Boolean(filterState.category)}
        value={filterState.category}
        onChange={({ target }) => {
          setFilterState({
            ...filterState,
            category: target.value,
          });

          setDiscount(0);
          setSelectedAlumniEvent({});
        }}
      >
        <option value="" disabled hidden>
          Select Event Category
        </option>
        {Children.toArray(
          categoryOptions.map((item) => (
            <option value={item.value}>{item.label}</option>
          )),
        )}
      </Input>
    </FormGroup>
  );
};

const AlumniEventFilter = ({ selectedEvent, alumniEvents }) => {
  const { selectedAlumniEvent, setSelectedAlumniEvent } = selectedEvent;

  return alumniEvents.length ? (
    <FormGroup>
      <div className="text-gray font-weight-bold mb-2">Alumni Event</div>
      <Input
        type="select"
        valid={Boolean(Object.keys(selectedAlumniEvent).length)}
        value={selectedAlumniEvent.id || 0}
        onChange={({ target }) => {
          const findAlumniEvent = alumniEvents.find(
            (alumniEvent) => alumniEvent.id === Number(target.value),
          );

          setSelectedAlumniEvent(findAlumniEvent);
        }}
      >
        <option value={0} disabled hidden>
          Select Alumni Event
        </option>
        {Children.toArray(
          alumniEvents.map((item) => (
            <option value={item.id}>{item.title}</option>
          )),
        )}
      </Input>
    </FormGroup>
  ) : (
    <FormGroup>
      <div className="text-gray font-weight-bold mb-2">Alumni Event</div>
      <Input type="text" disabled invalid value="No available Alumni Event" />
    </FormGroup>
  );
};

const FilterEvent = ({
  filter,
  setDiscount,
  alumniEvents,
  selectedEvent,
  programCategories,
}) => {
  const { setSelectedAlumniEvent } = selectedEvent;
  const { type, category, programCategoryId } = filter.filterState;

  return (
    <>
      <ProgramCategoryFilter
        filter={filter}
        data={programCategories}
        setDiscount={setDiscount}
        setSelectedAlumniEvent={setSelectedAlumniEvent}
      />

      <TypeFilter
        filter={filter}
        setDiscount={setDiscount}
        setSelectedAlumniEvent={setSelectedAlumniEvent}
      />

      <CategoryFilter
        filter={filter}
        setDiscount={setDiscount}
        setSelectedAlumniEvent={setSelectedAlumniEvent}
      />

      {type && category && programCategoryId && (
        <AlumniEventFilter
          alumniEvents={alumniEvents}
          selectedEvent={selectedEvent}
        />
      )}
    </>
  );
};

export default FilterEvent;
