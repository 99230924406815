import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

class GeneralTooltips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
    };
  }

  render() {
    let revPosition = '';
    this.props.position === 'top' || this.props.position === 'bottom'
      ? (revPosition = this.props.position)
      : this.props.position === 'right'
      ? (revPosition = 'left')
      : (revPosition = 'right');
    return (
      <div>
        <Tooltip
          placement={this.props.position}
          isOpen={this.props.toggle}
          target={this.props.id}
          innerClassName="general-tooltip"
          className={`tooltip ${revPosition}`}
        >
          {this.props.content}
        </Tooltip>
      </div>
    );
  }
}

export default GeneralTooltips;
