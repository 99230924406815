import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import reducer from './modules/reducer';
// import { routerMiddleware } from 'react-router-redux';
// import Immutable from 'immutable';
// import createMiddleware from './middleware/clientMiddleware';

export default function create() {
  // Sync dispatched route actions to the history
  //   const reduxRouterMiddleware = routerMiddleware(history);

  const middleware = [
    // createMiddleware(client),
    // reduxRouterMiddleware,
    thunk,
  ];

  const store = configureStore({
    reducer,
    middleware,
    devTools: true,
  });

  return store;
}
