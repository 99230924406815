import React, { Component } from 'react';
import axios from 'supports/api';
import { Card, CardBody, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkForm } from 'supports/helpers/permissions';
import CustomPagination from '../commons/CustomPagination';
import ProgramCard from './ProgramCard';
import { InputForm } from '../CustomForms';
import CategoryList from './CategoryList';

const INITIAL_STATE = {
  headers: [],
  filteredHeaders: [],
  categories: [],
  categoryId: {
    value: 'all',
  },
  searchParams: {
    value: '',
  },
  modal: false,
};

const INITIAL_METHODS = {
  filterByCategoryId() {
    const filteredResults = [];
    this.headers.forEach((header) => {
      const { program_categoryId } = header;

      if (
        parseInt(this.categoryId.value, 10) ===
          parseInt(program_categoryId, 10) ||
        this.categoryId.value === 'all'
      ) {
        filteredResults.push(header);
      }
    });

    return filteredResults;
  },
  filterBySearchParams() {
    const filteredResults = [];

    const filtered = this.filterByCategoryId
      ? this.filterByCategoryId()
      : this.headers;
    filtered.forEach((header) => {
      const {
        name,
        // pic
      } = header;

      const searchName = name.toLocaleLowerCase();
      const searchParams = this.searchParams.value.toLocaleLowerCase();

      if (searchName.indexOf(searchParams) !== -1) {
        filteredResults.push(header);
      }
    });

    return filteredResults;
  },
  getFilteredHeaders() {
    let filteredResults = [];

    filteredResults = this.filterBySearchParams();

    return filteredResults;
  },
};

class ProgramPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      ...INITIAL_METHODS,
    };
    this.params = new URLSearchParams(this.props.location.search);
  }

  componentDidMount() {
    // if(this.props.render){
    this.getData();
    // }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.params = new URLSearchParams(newProps.location.search);
  }

  getData = () => {
    axios
      .get(`/admin/program/headers`)
      .then(({ data }) => {
        const { result } = data;
        const page = this.params.get('page');

        if (page > Math.abs(result.length / 12) || !page) {
          this.props.history.push('?page=1');
        }
        this.setState((prevState) => ({ ...prevState, headers: result }));
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });

    axios
      .get(`/admin/program/categories`)
      .then(({ data }) => {
        const { result } = data;

        this.setState((prevState) => ({ ...prevState, categories: result }));
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });
  };

  handleInput = (state, value) => {
    this.props.history.push('?page=1');
    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value },
    }));
  };

  SearchBar = () => {
    return (
      <div className="pb-2">
        <Card>
          <CardBody>
            <div className="container-fluid white-box">
              <div className="row">
                <div className="col-12 col-sm my-auto">
                  <InputForm
                    {...this.state.searchParams}
                    label="Search"
                    placeholder="program name"
                    onInput={(value) => this.handleInput('searchParams', value)}
                  />
                </div>
                <div className="col-12 col-sm my-auto">
                  <CategoryList
                    {...this.state.categoryId}
                    categories={this.state.categories}
                    handleInput={this.handleInput}
                  />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };

  ProgramHeadersData = () => {
    const page = this.params.get('page');
    const sliced = this.state
      .getFilteredHeaders()
      .slice((page - 1) * 12, (page - 1) * 12 + 12);
    const arrJSX = sliced.map((item) => {
      return <ProgramCard key={item.id} {...item} />;
    });
    return arrJSX;
  };

  render() {
    const page = this.params.get('page');
    // if(this.props.render){
    return (
      <div className="white-box py-2 container-fluid">
        <div className="row py-4">
          <div className="col-12 offset-md-1 col-md-10 py-3">
            <div>
              <div className="line-green mb-3" />
              <div className="general-title text-gray">
                Programs & Workshops
                <Button
                  className="float-right"
                  onClick={() => {
                    this.props.history.push('create-program');
                  }}
                >
                  + Create Course
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12 offset-md-1 col-md-10 py-4">
            <this.SearchBar />
            <div className="row">
              <this.ProgramHeadersData />
            </div>
            <CustomPagination
              pages={
                Math.ceil(this.state.getFilteredHeaders().length / 12) || 1
              }
              active={page}
            />
          </div>
        </div>
      </div>
    );
    // }
    // else return <Redirect to="/"/>
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const render = checkForm(auth.access, 'Transaction Programs');
  return { auth, render };
};

export default withRouter(connect(mapStateToProps, {})(ProgramPage));
