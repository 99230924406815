import React, { Component } from 'react';
import axios from 'supports/api';
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { checkForm } from 'supports/helpers/permissions';
import 'react-datepicker/dist/react-datepicker.css';
import AdminScholarshipCard from './AdminScholarshipCard';

class AdminWorkshopOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scholarship: [],
      live: [],
      draft: [],
      past: [],
      activeTab: '2',
    };
  }

  componentDidMount() {
    if (this.props.render) {
      this.getData();
    }
  }

  getData = () => {
    axios
      .get(`/scholarship/all`)
      .then((res) => {
        const { result } = res.data;
        const live = [];
        const draft = [];
        const past = [];

        result.forEach((item) => {
          const { isLive, registrationEndDate } = item;

          if (isLive && moment(registrationEndDate) > moment()) {
            live.push(item);
          }
          if (!isLive) {
            draft.push(item);
          }
          if (isLive && moment(registrationEndDate) < moment()) {
            past.push(item);
          }
        });
        this.setState({ scholarship: res.data.result, live, draft, past });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  renderLive() {
    const arrJSX = this.state.live.map((item) => {
      return (
        <AdminScholarshipCard
          key={`scholarshipLive${item.id}`}
          item={item}
          refresh={() => this.getData()}
        />
      );
    });

    return arrJSX;
  }

  renderDraft() {
    const arrJSX = this.state.draft.map((item) => {
      return (
        <AdminScholarshipCard
          key={`scholarshipDraft${item.id}`}
          item={item}
          refresh={() => this.getData()}
        />
      );
    });

    return arrJSX;
  }

  renderPast() {
    const arrJSX = this.state.past.map((item) => {
      return (
        <AdminScholarshipCard
          key={`scholarshipPast${item.id}`}
          item={item}
          refresh={() => this.getData()}
        />
      );
    });

    return arrJSX;
  }

  renderAdminScholarship() {
    if (this.props.render) {
      return (
        <div className="white-box py-2 container-fluid">
          <div className="row px-5 py-5">
            <div className="col-6">
              <div>
                <div className="line-green mb-4" />
                <div className="general-title text-gray">
                  Manage Scholarship
                </div>
              </div>
            </div>
          </div>
          <div className="row px-5 py-2">
            <div className="col-12">
              <Nav tabs>
                <NavItem style={{ cursor: 'pointer' }}>
                  <NavLink
                    active={this.state.activeTab === '1'}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Live
                  </NavLink>
                </NavItem>
                <NavItem style={{ cursor: 'pointer' }}>
                  <NavLink
                    active={this.state.activeTab === '2'}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    Draft
                  </NavLink>
                </NavItem>
                <NavItem style={{ cursor: 'pointer' }}>
                  <NavLink
                    active={this.state.activeTab === '3'}
                    onClick={() => {
                      this.toggle('3');
                    }}
                  >
                    Past
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <div className="row">
                    <div className="col">{this.renderLive()}</div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="row">
                    <div className="col">{this.renderDraft()}</div>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="row">
                    <div className="col">{this.renderPast()}</div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      );
    }
    return <Redirect to="/" />;
  }

  render() {
    return this.renderAdminScholarship();
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const render = checkForm(auth.access, 'Scholarship Manage');
  return { auth, render };
};

export default connect(mapStateToProps, {})(AdminWorkshopOverview);
