import React from 'react';
// import './searchbox.css';
// import PCard from './PCard';
import SearchParams from './SearchParams';
import StatusCheckbox from './StatusCheckbox';
import CategoryCheckbox from './CategoryCheckbox';

const SearchBox = ({ ...props }) => {
  return (
    <div
      className="p-3"
      style={{
        backgroundColor: '#ffffff',
        minHeight: '200px',
        height: '100%',
        borderRadius: '6px',
        boxShadow: '0 20px 40px 0 rgba(102, 102, 102, 0.16)',
      }}
      {...props}
    >
      <div>
        <div className="d-flex flex-wrap justify-content-between">
          <SearchParams />
          <StatusCheckbox />
        </div>
        <div className="d-flex flex-row">
          <CategoryCheckbox />
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
