import React, { useState } from 'react';
import axios from 'supports/api';
import {
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  Input,
} from 'reactstrap';

const GenerateAttendanceSheetModal = ({ isOpen, toggle, programId }) => {
  const [lecturer, setLecturer] = useState('');
  const [sessions, setSessions] = useState('');
  const [moduleName, setModuleName] = useState('');

  const options = {
    params: {
      lecturer,
      sessions,
      moduleName,
    },
  };

  const onClose = () => {
    setLecturer('');
    setSessions('');
    setModuleName('');
  };

  const onGenerate = () => {
    if (!lecturer || !sessions || !moduleName) {
      return alert('Mohon isi semua field dengan lengkap');
    }
    return axios
      .get(`/studentinfo/attendance/${programId}`, options)
      .then((res) => {
        window.open(
          `${process.env.REACT_APP_BASE_URL}/studentinfo/attendance/${programId}?lecturer=${lecturer}&sessions=${sessions}&moduleName=${moduleName}`,
        );
        console.log(res);
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={onClose}>
      <ModalHeader>Generate Absensi</ModalHeader>
      <ModalBody>
        <div className="mb-2">
          <div>Nama Lecturer</div>
          <Input
            value={lecturer}
            onChange={(e) => setLecturer(e.target.value)}
            placeholder="contoh: : Firliandy Murdaya Eddy., S.Si"
          />
        </div>
        <div className="mb-2">
          <div>Nama Modul</div>
          <Input
            value={moduleName}
            onChange={(e) => setModuleName(e.target.value)}
            placeholder="contoh: Programming Fundamental"
          />
        </div>
        <div className="mb-2">
          <div>Jumlah Pertemuan (angka)</div>
          <Input
            type="number"
            value={sessions}
            onChange={(e) => setSessions(e.target.value)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-row justify-content-end">
          <Button color="success" onClick={onGenerate}>
            Generate Absensi
          </Button>
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default GenerateAttendanceSheetModal;
