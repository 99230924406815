import { Formik } from 'formik';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import axios from 'supports/api';
import { encrypt } from 'supports/helpers/encryption';
import { appKey } from 'supports/config';
import { useDispatch } from 'react-redux';
import { getUserData } from 'redux/modules/trxUser';

const validationSchema = Yup.object().shape(
  {
    firstName: Yup.string().required('First Name is Required'),
    lastName: Yup.string().required('Last Name is Required'),
    email: Yup.string().email().required('Email is Required'),
    phone: Yup.string().required('Phone is Required'),
    identityCard: Yup.string()
      .min(16, 'Must contain 16 digits')
      .when('npwp', {
        is: (npwp) => npwp?.trim().length,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required('KTP is required'),
      }),
    npwp: Yup.string()
      .min(15, 'Must contain 15 digits')
      .when('identityCard', {
        is: (identityCard) => identityCard?.trim().length,
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required('NPWP is Required'),
      }),
  },
  [['identityCard', 'npwp']],
);

const CreateUserModal = ({ isOpen, toggle }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const sanitizeInput = (input) => {
    // Combined regex to remove leading/trailing/multiple spaces, new lines, and strange symbols
    if (typeof input === 'string') {
      const sanitized = input
        .replace(/(\n|\r|\t|[^\x20-\x7E])/g, ' ') // Replace new lines, carriage returns, tabs, and strange symbols with a space
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .trim(); // Remove leading and trailing spaces

      return sanitized;
    }

    return input;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Create New User</ModalHeader>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          identityCard: '',
          npwp: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            setIsLoading(true);

            const ep = encrypt(appKey, 'Purwadhika123'); // encrypted password

            const newValues = {};

            Object.keys(values).forEach((valueKey) => {
              newValues[valueKey] = sanitizeInput(values[valueKey]);
            });

            const resp = await axios.post(`/v2/auth/register`, {
              ...newValues,
              ep,
              npwp: newValues.npwp || null,
              cryptoJs: false,
              isVerified: true,
              identityCard: newValues.identityCard || null,
              createdFromAdmin: true,
            });

            setIsLoading(false);

            if (resp.status === 200) {
              alert('User berhasil dibuat');
              resetForm();
              toggle();
              dispatch(getUserData(values.email));
            }
          } catch (err) {
            console.log({ err });
            alert(err.response.data.message);
          }
        }}
      >
        {({ getFieldProps, setFieldValue, touched, errors, handleSubmit }) => {
          return (
            <>
              <ModalBody>
                <Form>
                  <Row>
                    <Col xs={6}>
                      <FormGroup>
                        <Label>First Name</Label>
                        <Input
                          {...getFieldProps('firstName')}
                          placeholder="First Name"
                        />
                        {Boolean(touched.firstName) &&
                          Boolean(errors.firstName) && (
                            <FormText>{errors.firstName}</FormText>
                          )}
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                          {...getFieldProps('lastName')}
                          placeholder="Last Name"
                        />{' '}
                        {Boolean(touched.lastName) &&
                          Boolean(errors.lastName) && (
                            <FormText>{errors.lastName}</FormText>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input {...getFieldProps('email')} placeholder="Email" />
                    {Boolean(touched.email) && Boolean(errors.email) && (
                      <FormText>{errors.email}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Phone</Label>
                    <NumberFormat
                      className="form-control"
                      format="##############"
                      onValueChange={(val) => setFieldValue('phone', val.value)}
                      placeholder="Phone"
                    />
                    {Boolean(touched.phone) && Boolean(errors.phone) && (
                      <FormText>{errors.phone}</FormText>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>KTP</Label>

                    <NumberFormat
                      className="form-control"
                      format="#### #### #### ####"
                      onValueChange={(val) =>
                        setFieldValue('identityCard', val.value)
                      }
                      placeholder="KTP"
                    />
                    {Boolean(touched.identityCard) &&
                      Boolean(errors.identityCard) && (
                        <FormText>{errors.identityCard}</FormText>
                      )}
                  </FormGroup>
                  <FormGroup>
                    <Label>NPWP</Label>

                    <NumberFormat
                      className="form-control"
                      format="##.###.###.#-###.###"
                      onValueChange={(val) => setFieldValue('npwp', val.value)}
                      placeholder="NPWP"
                    />
                    {Boolean(touched.npwp) && Boolean(errors.npwp) && (
                      <FormText>{errors.npwp}</FormText>
                    )}
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button onClick={toggle}>Cancel</Button>
                <Button
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  Create
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateUserModal;
