import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserMenuListPage from './user-menu-list';

const UserMenuRouter = (props) => {
  const { match } = props;
  const { path } = match;
  return (
    <Switch>
      <Route exact path={`${path}`} component={UserMenuListPage} />
    </Switch>
  );
};

export default UserMenuRouter;
