/* eslint-disable no-param-reassign */
import axios from 'axios';

const mainApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

mainApiInstance.interceptors.request.use(
  (config) => {
    const auth_token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${auth_token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default mainApiInstance;
