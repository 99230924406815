import React, { Component } from 'react';
import { Button, CustomInput } from 'reactstrap';
import axios from 'supports/api';
import { API_URL_1 } from '../supports/api-url/apiurl';

class TopicCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      fileName: '',
      fileObject: null,
    };
  }

  editHandler = () => {
    this.setState({ edit: !this.state.edit, fileObject: null, fileName: '' });
  };

  handleFile = (file) => {
    if (file) {
      this.setState({ fileObject: file, fileName: file.name });
    }
  };

  onSaveClick = () => {
    const { item } = this.props;

    const formData = new FormData();
    const headers = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    formData.append('topicIcon', this.state.fileObject || null);
    formData.append('name', this.refs.topicName.value);

    axios
      .put(`/topic/edit/${item.id}`, formData, headers)
      .then((res) => {
        console.log(res);
        this.props.refresh();
        this.editHandler();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderCard() {
    const { index, item } = this.props;
    if (this.state.edit) {
      return (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>
            <input
              className="form-control"
              type="text"
              defaultValue={item.name}
              id="topicName"
              ref="topicName"
            />
          </td>
          <td>
            <div className="col-6">
              <CustomInput
                type="file"
                id={`editImage${index}`}
                name="editImage"
                label="Pilih Icon"
                className="col-6"
                onChange={() => {
                  this.handleFile(
                    document.getElementById(`editImage${index}`).files[0],
                  );
                }}
              />
            </div>
            <div className="col-6">{this.state.fileName}</div>
          </td>
          <td>
            <Button
              color="warning"
              className="mx-2"
              onClick={() => {
                this.editHandler();
              }}
            >
              Cancel
            </Button>
            <Button
              className="mx-2"
              color="success"
              onClick={() => {
                this.onSaveClick();
              }}
            >
              Save
            </Button>
          </td>
        </tr>
      );
    }
    return (
      <tr key={index}>
        <th scope="row">{index + 1}</th>
        <td>
          <div className="mt-3">{item.name}</div>
        </td>
        <td>
          <img
            src={API_URL_1 + item.image}
            alt="icon"
            style={{ width: '80px' }}
          />
        </td>
        <td>
          <Button
            className="mx-2"
            onClick={() => {
              this.editHandler();
            }}
          >
            Edit
          </Button>
        </td>
      </tr>
    );
  }

  render() {
    return this.renderCard();
  }
}

export default TopicCard;
