import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Input, Button } from 'reactstrap';
import axios from 'supports/api';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData } from 'redux/modules/trxUser';

export default ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { userData, email } = useSelector((state) => state.trxUser);
  const { identityCard, npwp, address } = userData;
  const [KTP, setKTP] = useState(identityCard ?? '');
  const [NPWP, setNPWP] = useState(npwp ?? '');
  const [ADDRESS, setADDRESS] = useState(address ?? '');

  useEffect(() => {
    setKTP(identityCard ?? '');
    setNPWP(npwp ?? '');
    setADDRESS(address ?? '');
  }, [identityCard, npwp, address]);

  const onSave = () => {
    const data = {
      identityCard: KTP,
      npwp: NPWP,
      address: ADDRESS,
    };
    axios
      .put(`/admin/user/${userData.id}`, data)
      .then(() => {
        dispatch(getUserData(email));
        toggle();
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={() => {
        setKTP(identityCard);
        setNPWP(npwp);
        setADDRESS(address);
      }}
    >
      <ModalBody>
        <div>
          <div className="mb-3">
            <div className="f--24 f--rich-black font-weight-bold">
              Nomor KTP
            </div>
            <div>
              <Input value={KTP} onChange={(e) => setKTP(e.target.value)} />
            </div>
          </div>
          <div className="mb-3">
            <div className="f--24 f--rich-black font-weight-bold">
              Nomor NPWP
            </div>
            <div>
              <Input value={NPWP} onChange={(e) => setNPWP(e.target.value)} />
            </div>
          </div>
          <div className="mb-3">
            <div className="f--24 f--rich-black font-weight-bold">Alamat</div>
            <div>
              <Input
                value={ADDRESS}
                onChange={(e) => setADDRESS(e.target.value)}
              />
            </div>
          </div>
          <div className="float-right">
            <Button
              color="success"
              className="mr-2"
              style={{ width: 80, backgroundColor: '#53b59f' }}
              onClick={onSave}
            >
              Save
            </Button>
            <Button onClick={toggle}>Cancel</Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
