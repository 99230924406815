import React from 'react';
import { Label, Input, FormText, FormGroup, FormFeedback } from 'reactstrap';

export const TextForm = ({
  label,
  placeholder,
  value,
  valid,
  invalid,
  success,
  error,
  onInput,
  onFocus,
  help,
  limit,
}) => {
  if (limit) {
    var limitHelp = `Characters ${value ? value.length : 0}/${limit}`;
  }

  const limitCheck = value => {
    if (limit && value.length > limit) {
      return null;
    }
    return onInput(value);
  };

  return (
    <FormGroup>
      <Label for={`form${label}`} className="text-gray font-weight-bold">
        {label}
      </Label>
      <Input
        type="textarea"
        valid={valid}
        invalid={invalid}
        placeholder={placeholder}
        value={value}
        id={`form${label}`}
        onChange={({ target }) => limitCheck(target.value)}
        onFocus={onFocus}
        rows={4}
      />
      <FormFeedback valid>{success}</FormFeedback>
      <FormFeedback invalid="true">{error}</FormFeedback>
      <FormText>{help}</FormText>
      <FormText>{limitHelp}</FormText>
    </FormGroup>
  );
};
