import React from 'react';
import { FormGroup, Input, FormFeedback } from 'reactstrap';

const CategoryList = ({
  value,
  valid,
  invalid,
  error,
  categories,
  handleInput,
}) => {
  const arrJSX = categories?.map((item) => {
    return (
      <option value={item.id} key={`${item.name}${item.id}`}>
        {item.name}
      </option>
    );
  });
  return (
    <FormGroup>
      <div className="text-gray font-weight-bold mb-2">Category</div>
      <Input
        type="select"
        valid={valid}
        invalid={invalid}
        value={value}
        onChange={({ target }) => handleInput('categoryId', target.value)}
      >
        <option value="all">All</option>
        {arrJSX}
      </Input>
      <FormFeedback invalid="true">*{error}</FormFeedback>
    </FormGroup>
  );
};

export default CategoryList;
