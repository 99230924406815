import React, { Component } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class VoucherCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  CheckCrossIcon = ({ status }) => {
    if (status) {
      return (
        <FontAwesomeIcon
          className="mx-auto text-center"
          icon={['fas', 'check-circle']}
          color="green"
          size="1x"
        />
      );
    }
    return (
      <FontAwesomeIcon
        className="mx-auto text-center"
        icon={['fas', 'times-circle']}
        color="red"
        size="1x"
      />
    );
  };

  render() {
    const { item } = this.props;

    return (
      <tr>
        <th>{item.id}</th>
        <th>{item.batch}</th>
        <td>{item.number}</td>
        <td>Rp. {item.nominal.toLocaleString()}</td>
        <td>{item.vendor}</td>
        <td>{moment(item.validFrom).format('DD/MM/YYYY')}</td>
        <td>{moment(item.validTo).format('DD/MM/YYYY')}</td>
        <td>
          <this.CheckCrossIcon status={item.available} />
        </td>
        {/* <td><Link to={`transaction-details?id=${item.id}`}><Button>View</Button></Link></td> */}
        {/* <EditPaymentModal isOpen={this.state.editModal} toggle={this.toggleEditModal} item={this.props.item} refresh={this.props.refresh}/> */}
      </tr>
    );
  }
}

export default VoucherCard;
