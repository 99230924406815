import React, { Component } from 'react';
import axios from 'supports/api';
import {
  Form,
  FormGroup,
  Input,
  FormFeedback,
  CustomInput,
  Button,
  Label,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { checkForm } from 'supports/helpers/permissions';
import { API_URL_1 } from '../../supports/api-url/apiurl';
import 'react-datepicker/dist/react-datepicker.css';
import { InputForm, TextForm } from '../CustomForms';
import noimage from './no_image.png';

const INITIAL_STATE = {
  branches: [],
  pics: [],
  categories: [],
  online_courseId: [],
  programLivestreams: [],
  isLoading: false,
};

const INITIAL_FORM = {
  program_livestreamId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Program Livestream',
  },
  program_categoryId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Category',
  },
  picId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a PIC',
  },
  name: {
    value: '',
    valid: false,
    invalid: false,
    error: '*Please input name',
  },
  onlineCourseId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select online course Id',
  },
  description: {
    value: '',
    valid: false,
    invalid: false,
    error: 'Please input description',
  },
  topicIds: {
    value: {},
    valid: false,
    invalid: false,
    error: 'Please select atleast 1 topic',
  },
  image: {
    value: {},
    valid: false,
    invalid: false,
    error: 'Please select an image',
  },
  isMultipleCurrency: false,
};

class EventForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      ...INITIAL_FORM,
    };
  }

  componentDidMount() {
    if (this.props.render) {
      this.getAllData();
    }
    if (this.props.edit) {
      this.getProgramHeaderById();
    }
  }

  getAllData = () => {
    axios
      .get(`/topic/all`)
      .then((res) => {
        const objTopics = {};
        res.data.result.forEach((topic) => {
          objTopics[topic.id] = { name: topic.name, checked: false };
        });
        this.setState((prevState) => ({
          topicIds: { ...prevState.topicIds, value: objTopics },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/admin/program/categories`)
      .then((res) => {
        this.setState({ categories: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/v2/program-livestream`)
      .then((res) => {
        this.setState({ programLivestreams: res.data.data });
      })
      .catch((err) => console.log(err));
    axios
      .get(`/pic/all`)
      .then((res) => {
        this.setState({ pics: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get('/admin/program/online-course-id')
      .then((res) => {
        this.setState({ online_courseId: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProgramHeaderById = () => {
    axios
      .get(`/admin/program/header/${this.props.programHeaderId}`)
      .then((res) => {
        const programHeader = res.data.result;

        const objTopics = {};
        if (programHeader.topics) {
          programHeader.topics.forEach((topic) => {
            objTopics[topic.id] = { name: topic.name, checked: true };
          });
        }
        document.getElementById(
          'imagepreviewtag',
        ).src = `${API_URL_1}/${programHeader.image}`;

        this.setState((prevState) => ({
          program_categoryId: {
            ...prevState.program_categoryId,
            value: programHeader.program_categoryId,
            valid: true,
          },
          name: { ...prevState.name, value: programHeader.name, valid: true },
          onlineCourseId: {
            ...prevState.onlineCourseId,
            value: programHeader.online_courseId,
            valid: true,
          },
          topicIds: {
            ...prevState.topicIds,
            value: { ...prevState.topicIds.value, ...objTopics },
            valid: true,
          },
          picId: {
            ...prevState.picId,
            value: programHeader.picId,
            valid: true,
          },
          description: {
            ...prevState.description,
            value: programHeader.description,
            valid: true,
          },
          program_livestreamId: {
            ...prevState.program_livestreamId,
            value: programHeader.programLivestreamId,
            valid: true,
          },
          isMultipleCurrency: programHeader.isMultipleCurrency,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleInput = (state, value) => {
    const valid = !!value;
    const invalid = !value;

    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value, valid, invalid },
    }));
  };

  handleSelect = (state, value) => {
    let valid = false;
    let invalid = true;

    if (value !== 0) {
      valid = true;
      invalid = false;
    }
    this.setState((prevState) => ({
      ...prevState,
      [state]: { value, valid, invalid },
    }));
  };

  handleFile = (state, value) => {
    if (value) {
      document.getElementById('imagepreviewtag').src = URL.createObjectURL(
        value,
      );
      const valid = true;
      const invalid = false;
      this.setState((prevState) => ({
        ...prevState,
        [state]: { ...prevState[state], valid, invalid, value },
      }));
    }
  };

  validateForm = async (form) => {
    try {
      let result = 0;

      Object.keys(form).forEach(async (obj) => {
        if (obj === 'topicIds') {
          const topicCheck = [];
          Object.keys(form[obj].value).forEach((item) => {
            if (form[obj].value[item].checked) {
              topicCheck.push(true);
            }
          });

          if (topicCheck.length === 0) {
            await this.setState((prevState) => ({
              [obj]: { ...prevState[obj], invalid: true },
            }));
            result += 1;
          }
        } else if (!form[obj].valid) {
          await this.setState((prevState) => ({
            [obj]: { ...prevState[obj], invalid: true },
          }));
          result += 1;
        }
      });

      if (result > 0) {
        return false;
      }
      return true;
    } catch (err) {
      return err;
    }
  };

  resetForm = () => {
    this.setState((prevState) => ({ ...prevState, ...INITIAL_FORM }));
  };

  onCreateHeader = async () => {
    const {
      program_categoryId,
      name,
      picId,
      description,
      topicIds,
      onlineCourseId,
      program_livestreamId,
    } = this.state;
    const validation = this.validateForm({
      program_categoryId,
      name,
      picId,
      description,
      topicIds,
      onlineCourseId,
      program_livestreamId,
    }); // returns a promise

    validation
      .then((valid) => {
        if (valid) {
          if (window.confirm('Are you sure?')) {
            const formData = new FormData();

            const topicIds = [];

            Object.keys(this.state.topicIds.value).forEach((item) => {
              if (this.state.topicIds.value[item].checked) {
                topicIds.push(parseInt(item, 10));
              }
            });

            const data = {
              name: this.state.name.value,
              program_categoryId: this.state.program_categoryId.value,
              topicIds,
              description: this.state.description.value,
              picId: this.state.picId.value,
              onlineCourseId: this.state.onlineCourseId.value,
              programLivestreamId:
                this.state.program_livestreamId.value === 0
                  ? null
                  : this.state.program_livestreamId.value,
              isMultipleCurrency: this.state.isMultipleCurrency,
            };
            // console.log(data)

            formData.append('headerImage', this.state.image.value);
            // formData.append('name', name)
            // formData.append('program_categoryId', program_categoryId)
            // formData.append('topicIds', topicIds)
            formData.append('data', JSON.stringify(data));

            this.setState((prevState) => ({ ...prevState, isLoading: true }));

            setTimeout(() => {
              axios
                .post(`/admin/create/program-header`, formData)
                .then((res) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                  }));
                  this.resetForm();
                  alert('Create header success');
                  this.props.history.push(
                    `/admin/program-details?id=${res.data.result.id}`,
                  );
                  // this.props.refresh()
                  // this.props.toggle()
                })
                .catch(({ response }) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                  }));
                  console.log(response.data);
                  const { message, error } = response.data;
                  alert(`${message}\n${error}`);
                });
            }, 400); // Loads for a minimal of 0.4s
          }
        } else {
          alert('There are invalid fields');
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  onSaveHeader = async () => {
    const {
      program_categoryId,
      name,
      picId,
      description,
      topicIds,
      onlineCourseId,
    } = this.state;
    const validation = this.validateForm({
      program_categoryId,
      name,
      picId,
      description,
      topicIds,
      onlineCourseId,
    }); // returns a promise

    validation
      .then((valid) => {
        if (valid) {
          if (window.confirm('Are you sure?')) {
            const options = {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            };

            const formData = new FormData();

            const topicIds = [];

            Object.keys(this.state.topicIds.value).forEach((item) => {
              if (this.state.topicIds.value[item].checked) {
                topicIds.push(parseInt(item, 10));
              }
            });

            const data = {
              name: this.state.name.value,
              program_categoryId: this.state.program_categoryId.value,
              topicIds,
              description: this.state.description.value,
              picId: this.state.picId.value,
              onlineCourseId: this.state.onlineCourseId.value,
              programLivestreamId: this.state.program_livestreamId.value,
              isMultipleCurrency: this.state.isMultipleCurrency,
            };
            // console.log(data)

            formData.append('headerImage', this.state.image.value);
            // formData.append('name', name)
            // formData.append('program_categoryId', program_categoryId)
            // formData.append('topicIds', topicIds)
            formData.append('data', JSON.stringify(data));

            this.setState((prevState) => ({ ...prevState, isLoading: true }));

            setTimeout(() => {
              axios
                .put(
                  `/admin/program/header/${this.props.programHeaderId}`,
                  formData,
                  options,
                )
                .then((res) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                  }));
                  this.resetForm();
                  alert('Save header success');
                  // this.props.history.push(`/admin/program-details?id=${res.data.result.id}`)
                  this.props.refresh();
                  this.props.toggle();
                })
                .catch(({ response }) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                  }));
                  console.log(response.data);
                  const { message, error } = response.data;
                  alert(`${message}\n${error}`);
                });
            }, 400); // Loads for a minimal of 0.4s
          }
        } else {
          alert('There are invalid fields');
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  TopicSelect = ({ value, invalid, error }) => {
    const onHandleTopic = (key) => {
      this.setState((prevState) => ({
        topicIds: {
          ...prevState.topicIds,
          invalid: false,
          value: {
            ...prevState.topicIds.value,
            [key]: {
              ...prevState.topicIds.value[key],
              checked: !prevState.topicIds.value[key].checked,
            },
          },
        },
      }));
    };

    const arrJSX = Object.keys(value).map((key) => {
      return (
        <label htmlFor={value[key].name} key={`topicselect${key}`}>
          <div
            key={`topic${key}`}
            className="d-inline-block"
            style={{ width: '250px' }}
          >
            <CustomInput
              type="checkbox"
              id={value[key].name}
              label={value[key].name}
              checked={value[key].checked}
              onChange={() => onHandleTopic(key)}
            />
          </div>
        </label>
      );
    });

    return (
      <FormGroup className="mb-2">
        <Label className="text-gray font-weight-bold">Topics</Label>
        <div>{arrJSX}</div>
        <Input invalid={invalid} className="d-none" />
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  MultipleCurrency = () => {
    const onChecked = () => {
      this.setState((prev) => ({
        isMultipleCurrency: !prev.isMultipleCurrency,
      }));
    };

    return (
      <FormGroup className="mb-2">
        <Label className="text-gray font-weight-bold">Currencies</Label>
        <CustomInput
          type="checkbox"
          id="multiplecurrencies"
          label="Multiple Currency"
          checked={this.state.isMultipleCurrency}
          onChange={() => onChecked()}
        />
      </FormGroup>
    );
  };

  BranchSelect = ({ value, valid, invalid, error, label }) => {
    const arrJSX = this.state.branches.map((item) => {
      return (
        <option key={`branch${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });

    return (
      <FormGroup className="mb-2">
        <Label for={`FormGroup${label}`} className="text-gray font-weight-bold">
          {label}
        </Label>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) => this.handleSelect('branchId', target.value)}
        >
          <option value={0} disabled hidden>
            Pilih Branch
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  PICSelect = ({ value, valid, invalid, error, label }) => {
    const arrJSX = this.state.pics.map((item) => {
      return (
        <option key={`pic${item.id}`} value={item.id}>
          {item.name} / {item.email} / +{item.phone}
        </option>
      );
    });

    return (
      <Form className="mb-2">
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          {label}
        </Label>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) => this.handleSelect('picId', target.value)}
        >
          <option value={0} disabled hidden>
            Pilih PIC
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </Form>
    );
  };

  ImageSelect = ({ value, valid, invalid, error, label }) => {
    return (
      <Form className="mb-2">
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          {label}
        </Label>
        <CustomInput
          type="file"
          className="mb-1"
          valid={valid}
          invalid={invalid}
          label={value.name}
          onChange={({ target }) => this.handleFile('image', target.files[0])}
        />
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </Form>
    );
  };

  CategorySelect = ({ value, valid, invalid, error, label }) => {
    const arrJSX = this.state.categories.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      );
    });
    return (
      <Form className="mb-2">
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          {label}
        </Label>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) =>
            this.handleSelect('program_categoryId', target.value)
          }
        >
          <option value={0} disabled hidden>
            Pilih Kategori
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </Form>
    );
  };

  ProgramLivestreamSelect = ({ value, valid, invalid, error, label }) => {
    let arrJSX = [];
    if (this.state.programLivestreams.length) {
      arrJSX = this.state.programLivestreams.map((programLivestream) => {
        return (
          <option key={programLivestream.id} value={programLivestream.id}>
            {programLivestream.title}
          </option>
        );
      });
    }
    return (
      <Form className="mb-2">
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          {label}
        </Label>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) =>
            this.handleSelect('program_livestreamId', target.value)
          }
        >
          <option value={0} disabled hidden>
            Pilih Program Livestream
          </option>
          {this.state.programLivestreams?.length ? (
            arrJSX
          ) : (
            <option value={0} disabled>
              belum ada data
            </option>
          )}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </Form>
    );
  };

  OnlineCourseIdSelect = ({ value, valid, invalid, error, label }) => {
    const arrJSX = this.state.online_courseId.map((item) => {
      return (
        <option key={item.id} value={item.id}>
          {item.title}
        </option>
      );
    });
    return (
      <Form className="mb-2">
        <Label for={`form${label}`} className="text-gray font-weight-bold">
          {label}
        </Label>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) =>
            this.handleSelect('onlineCourseId', target.value)
          }
        >
          <option value={0} disabled hidden>
            Pilih Online Course
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </Form>
    );
  };

  SubmitButton = () => {
    if (this.props.edit) {
      return (
        <CardFooter>
          <Button
            color="success"
            className="w-100"
            style={{ height: 40 }}
            onClick={this.onSaveHeader}
          >
            Save
          </Button>
        </CardFooter>
      );
    }
    return (
      <CardFooter>
        <Button
          color="success"
          className="w-100"
          style={{ height: 40 }}
          onClick={this.onCreateHeader}
        >
          Create
        </Button>
      </CardFooter>
    );
  };

  render() {
    if (this.props.render) {
      return (
        <div className="white-box py-2 container">
          <div className="row py-3">
            <div className="col-12">
              <div>
                <div className="line-green mb-2" />
                <div className="general-title text-gray">Header</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Card className="my-4">
                <CardHeader>Program Header Form</CardHeader>
                <CardBody>
                  <div className="d-flex flex-row justify-content-between">
                    <div className="w-100 mr-5">
                      <this.ImageSelect
                        {...this.state.image}
                        label="Cover Image"
                      />
                      <this.CategorySelect
                        {...this.state.program_categoryId}
                        label="Category"
                      />
                    </div>
                    <div className="w-75">
                      <Label
                        for="form$Cover Image"
                        className="text-gray font-weight-bold"
                      >
                        Preview
                      </Label>
                      <div
                        style={{ width: 120, height: 120 }}
                        className="border rounded"
                      >
                        <img
                          id="imagepreviewtag"
                          src={noimage}
                          alt="coverimage"
                          className="w-100"
                        />
                      </div>
                    </div>
                  </div>
                  <InputForm
                    {...this.state.name}
                    label="Name"
                    onInput={(value) => this.handleInput('name', value)}
                  />
                  <this.OnlineCourseIdSelect
                    {...this.state.onlineCourseId}
                    label="Online Course"
                  />
                  <this.ProgramLivestreamSelect
                    {...this.state.program_livestreamId}
                    label="Program Livestream"
                  />
                  <this.TopicSelect {...this.state.topicIds} />

                  <this.PICSelect label="PIC" {...this.state.picId} />
                  <TextForm
                    {...this.state.description}
                    label="Description"
                    onInput={(value) => this.handleInput('description', value)}
                    limit={400}
                  />
                  <this.MultipleCurrency />
                </CardBody>
                <this.SubmitButton />
              </Card>
            </div>
          </div>
        </div>
      );
    }
    return <Redirect to="/" />;
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const render = checkForm(auth.access, 'Event Create');
  return { auth, render };
};

export default withRouter(connect(mapStateToProps, {})(EventForm));
