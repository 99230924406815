// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import axios from 'supports/api';

const initialState = {
  loading: false,
  loadingEdit: false,
  status: 'idle',
  error: null,
  data: [],
  filteredData: [],
  filteringOptions: {
    categories: [],
    topics: [],
    levels: [],
  },
  SelectionOptions: {
    categories: [],
    topics: [],
    levels: [],
  },
  filteringInput: {
    filterQuery: '',
    filterCategory: '',
    filterTopic: '',
    filterLevel: '',
  },
  selectionInput: {
    selectCategory: 0,
    selectTopic: 0,
    selectLevel: 0,
  },
};

const { actions, reducer } = createSlice({
  name: 'onlineCourseData',
  initialState,
  reducers: {
    fetchingOnlineCourseDataStart: (state) => {
      state.loading = true;
      state.status = 'loading';
    },
    setOnlineCourseData: (state, action) => {
      state.data = action.payload;
      state.filtered = action.payload;
    },
    setFilteringOptions: (state, action) => {
      state.filteringOptions.categories = action.payload.categories;
      state.filteringOptions.topics = action.payload.topics;
      state.filteringOptions.levels = action.payload.levels;
    },
    setSelectionOptions: (state, action) => {
      state.SelectionOptions.categories = action.payload.categories;
      state.SelectionOptions.topics = action.payload.topics;
      state.SelectionOptions.levels = action.payload.levels;
    },
    fetchingOnlineCourseDataSuccess: (state) => {
      state.loading = false;
      state.status = 'success';
    },
    fetchingOnlineCourseDataFailure: (state, action) => {
      state.loading = false;
      state.status = 'failure';
      state.error = action.payload;
    },
    filteringOnlineCourseData: (state, action) => {
      const {
        filterQuery,
        filterCategory,
        filterTopic,
        filterLevel,
      } = action.payload;

      let newFilteredData = state.data;
      if (filterQuery) {
        newFilteredData = newFilteredData.filter((item) => {
          const { title, subHeader } = item;
          const lowerQuery = filterQuery.toLowerCase();
          return (
            title.toString().toLowerCase().indexOf(lowerQuery) !== -1 ||
            subHeader.toString().toLocaleLowerCase().indexOf(lowerQuery) !== -1
          );
        });
      }

      if (filterCategory) {
        newFilteredData = newFilteredData.filter((item) => {
          return Number(filterCategory) === item.online_course_categoryId;
        });
      }

      if (filterTopic) {
        newFilteredData = newFilteredData.filter((item) => {
          return Number(filterTopic) === item.online_course_category_topicId;
        });
      }

      if (filterLevel) {
        newFilteredData = newFilteredData.filter((item) => {
          return Number(filterLevel) === item.online_course_level_id;
        });
      }

      state.filteredData = newFilteredData;
    },
    setFilterQuery: (state, action) => {
      state.filteringInput.filterQuery = action.payload;
    },
    setFilterCategory: (state, action) => {
      state.filteringInput.filterCategory = action.payload;
    },
    setFilterTopic: (state, action) => {
      state.filteringInput.filterTopic = action.payload;
    },
    setFilterLevel: (state, action) => {
      state.filteringInput.filterLevel = action.payload;
    },
    setSelectCategory: (state, action) => {
      state.selectionInput.selectCategory = action.payload;
    },
    setSelectTopic: (state, action) => {
      state.selectionInput.selectTopic = action.payload;
    },
    setSelectLevel: (state, action) => {
      state.selectionInput.selectLevel = action.payload;
    },
  },
});

export default reducer;

export const {
  fetchingOnlineCourseDataStart,
  setOnlineCourseData,
  fetchingOnlineCourseDataSuccess,
  fetchingOnlineCourseDataFailure,
  setFilteringOptions,
  setSelectionOptions,
  filteringOnlineCourseData,
  setFilterQuery,
  setFilterCategory,
  setFilterTopic,
  setFilterLevel,
  setSelectCategory,
  setSelectTopic,
  setSelectLevel,
} = actions;

export const initialFetchOnlineCourseData = () => async (dispatch) => {
  dispatch(fetchingOnlineCourseDataStart());
  try {
    const onlineCourseData = await axios.get(`/v2/online-course`);
    const onlineCourseCategories = await axios.get(
      `/v2/online-course-category/online-course-category`,
    );
    const onlineCourseCategoryTopics = await axios.get(
      `/v2/online-course-category-topic/online-course-category-topic`,
    );
    const onlineCourseLevels = await axios.get(
      `/v2/online-course-level/online-course-level`,
    );

    dispatch(setOnlineCourseData(onlineCourseData.data));
    dispatch(
      setFilteringOptions({
        categories: onlineCourseCategories.data,
        topics: onlineCourseCategoryTopics.data,
        levels: onlineCourseLevels.data,
      }),
    );
    dispatch(
      setSelectionOptions({
        categories: onlineCourseCategories.data,
        topics: onlineCourseCategoryTopics.data,
        levels: onlineCourseLevels.data,
      }),
    );
    dispatch(fetchingOnlineCourseDataSuccess());
  } catch (err) {
    dispatch(fetchingOnlineCourseDataFailure(err));
  }
};
