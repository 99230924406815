import React, { useEffect, useState } from 'react';
import { Table, Input, Button } from 'reactstrap';
import axios from 'supports/api';
import { useDispatch } from 'react-redux';
import { fetchProgramSalesByTrxId } from 'redux/modules/programSales';
import NumberFormat from 'react-number-format';
import '../programs.css';

const AttendeeInputs = (props) => {
  const { data, setSelectedSales } = props;
  const programSalesAttendees = data.program_sales_attendees;

  const dispatch = useDispatch();

  const [inputValues, setInputValues] = useState({
    attendee: [],
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    name: '',
    email: '',
    phone: '',
    identityCard: '',
    address: '',
  });

  useEffect(() => {
    const arr = programSalesAttendees.map((attendee) => ({
      id: attendee.id,
      attendeeName: attendee.attendeeName || '',
      attendeePhone: attendee.attendeePhone || '',
      attendeeEmail: attendee.attendeeEmail || '',
      identityCard: attendee.identityCard || '',
      jacketSize: attendee.jacketSize || '',
      address: attendee.address || '',
      isGuest: 0,
    }));
    setInputValues((i) => {
      return { ...i, attendee: arr };
    });
  }, [programSalesAttendees, data]);

  const trimString = (string) => {
    return string.replace(/^\s+|\s+$/gm, '');
  };

  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const re = /^(\d{3,5}-?){2}\d{3,5}$/;
    return re.test(phoneNumber);
  };

  const handleInput = (e, i) => {
    const { name, value } = e.target;
    const newArr = inputValues.attendee;

    if (name === 'attendeeEmail') {
      if (value.length > 0) {
        const email = validateEmail(value);
        const valid = !email ? 'Format email salah' : '';
        setErrorMessage({ ...errorMessage, email: valid });
      } else {
        setErrorMessage({ ...errorMessage, email: '' });
      }
    } else if (name === 'attendeePhone') {
      if (value.length > 0) {
        const phone = validatePhoneNumber(value);
        const valid = !phone ? 'Format nomor handphone salah' : '';
        setErrorMessage({ ...errorMessage, phone: valid });
      } else {
        setErrorMessage({ ...errorMessage, phone: '' });
      }
    }

    if (name === 'identityCard') {
      if (value.length > 0) {
        const identityCardValue = value.split('-').join('');
        newArr[i] = { ...newArr[i], [name]: identityCardValue };
        setInputValues((prev) => ({ ...prev, attendee: newArr }));
      }
    } else {
      newArr[i] = { ...newArr[i], [name]: value };
      setInputValues((prev) => ({ ...prev, attendee: newArr }));
    }
  };

  const handleSave = async () => {
    const body = {
      program_salesObj: data,
      attendeesInput: inputValues.attendee.map((el) => {
        return {
          ...el,
          attendeeName: trimString(el?.attendeeName),
          attendeePhone: trimString(el?.attendeePhone),
          attendeeEmail: trimString(el?.attendeeEmail),
          identityCard: trimString(el?.identityCard),
          address: trimString(el?.address),
        };
      }),
    };

    try {
      await axios.post(`/admin/attendees`, body);
      dispatch(fetchProgramSalesByTrxId(data.transactionId));
      setLoading(false);
      alert('Attendees input success');
    } catch (err) {
      setLoading(false);
      console.error(err);
      alert(`${err}`);
    }
  };

  const renderInputs = () => {
    return inputValues.attendee.map((attendee, i) => {
      return (
        <tr key={attendee.id}>
          <td>{i + 1}</td>
          <td>
            <Input
              name="attendeeName"
              value={attendee.attendeeName}
              onChange={(e) => handleInput(e, i)}
            />
          </td>
          <td>
            <NumberFormat
              name="attendeePhone"
              className="text-input"
              onChange={(e) => handleInput(e, i)}
              value={attendee.attendeePhone}
            />
            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
              {errorMessage.phone}
            </p>
          </td>
          <td>
            <Input
              name="address"
              value={attendee.address}
              onChange={(e) => handleInput(e, i)}
              type="textarea"
            />
            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
              {errorMessage.address}
            </p>
          </td>
          <td>
            <Input
              name="attendeeEmail"
              value={attendee.attendeeEmail}
              onChange={(e) => handleInput(e, i)}
            />
            <p style={{ fontSize: 12, color: 'red', marginTop: 5 }}>
              {errorMessage.email}
            </p>
          </td>
          <td>
            <NumberFormat
              name="identityCard"
              className="text-input"
              onChange={(e) => handleInput(e, i)}
              disabled={data.code === 'WE'}
              value={attendee.identityCard}
              format="######-######-####"
            />
          </td>
          <td>
            <Input
              name="jacketSize"
              type="select"
              value={attendee.jacketSize}
              onChange={(e) => handleInput(e, i)}
              disabled={data.code === 'WE'}
            >
              <option value="">{data.code === 'WE' ? '' : '-Pilih-'}</option>
              <option value="s/m">S/M</option>
              <option value="l/xl">L/XL</option>
              <option value="xxl/xxxl">XXL/XXXL</option>
            </Input>
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <Table bordered responsive hover>
        <thead>
          <tr>
            <th>#</th>
            <th style={{ width: '20%' }}>Name</th>
            <th style={{ width: '10%' }}>Phone</th>
            <th style={{ width: '20%' }}>Address</th>
            <th style={{ width: '20%' }}>Email</th>
            <th style={{ width: '20%' }}>No. KTP</th>
            <th style={{ width: '10%' }}>Ukuran Jaket</th>
          </tr>
        </thead>
        <tbody>{renderInputs()}</tbody>
        <tfoot>
          <tr>
            <th colSpan={7}>
              <span className="d-flex flex-row justify-content-end">
                <Button
                  color="danger"
                  className="mr-2"
                  onClick={() => setSelectedSales({})}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  disabled={
                    errorMessage.email !== '' ||
                    errorMessage.phone !== '' ||
                    errorMessage.identityCard !== ''
                  }
                  onClick={() => {
                    handleSave();
                    setLoading(true);
                  }}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    'Save'
                  )}
                </Button>
              </span>
            </th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

export default AttendeeInputs;
