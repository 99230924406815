import React from 'react';
import { Modal, ModalBody, Button, ModalHeader, ModalFooter } from 'reactstrap';

const ModalDelete = ({ isOpen, toggle, onButtonDeleteClick }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} />
      <ModalBody>Are you sure to delete this?</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => onButtonDeleteClick()}>
          Delete
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
