// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';
import { encrypt } from 'supports/helpers/encryption';
import { appKey } from 'supports/config';

const initialUser = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  profilePicture: null,
  attendees: [],
  token: null,
  access: [],
};

const initialState = {
  user: initialUser,
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  profilePicture: null,
  attendees: [],
  token: null,
  accountVerified: false,
  isAdmin: false,
  access: [],
  loggingIn: false,
  loginError: null,
  loggingOut: false,
  logoutError: null,
  tokenChecked: false,
};

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.loggingIn = true;
      state.loginError = null;
    },
    loginSuccess: (state, action) => {
      return { ...state, ...action.payload, loggingIn: false };
    },
    loginFail: (state, action) => {
      state.loggingIn = false;
      state.loginError = action.payload || 'Login Failed';
      state.user = initialState;
    },
    logout: (state) => {
      state.loggingOut = true;
    },
    logoutSuccess: () => {
      return { ...initialState, tokenChecked: true };
    },
    logoutFail: (state) => {
      state.loggingOut = false;
      state.logoutError = 'Insert payload error here';
    },
    tokenValid: (state, action) => {
      return { ...state, ...action.payload, tokenChecked: true };
    },
    tokenInvalid: () => {
      return { ...initialState, tokenChecked: true };
    },
  },
});

export default reducer;

export const {
  login,
  loginSuccess,
  loginFail,
  logout,
  logoutSuccess,
  logoutFail,
  tokenValid,
  tokenInvalid,
} = actions;

export const signIn = (data) => {
  return (dispatch) => {
    const { email, password } = data;
    const ep = encrypt(appKey, password); // encrypted password

    dispatch(login());
    axios
      .post(`/admin/login`, {
        email,
        ep,
      })
      .then((res) => {
        const { token } = res.data.result;

        localStorage.setItem('token', token || null);
        localStorage.setItem('loggedUserEmail', email);

        dispatch(loginSuccess(res.data.result));
      })
      .catch((err) => {
        console.log(err);
        const { message } = err?.response?.data;
        dispatch(loginFail(message));
      });
  };
};

export const signOut = () => {
  return (dispatch) => {
    dispatch(logout());
    try {
      localStorage.removeItem('token');
      dispatch(logoutSuccess());
    } catch (err) {
      dispatch(logoutFail());
    }
  };
};

export const checkToken = () => {
  return (dispatch) => {
    axios
      .get(`/admin/keeplogin`)
      .then((res) => {
        dispatch(tokenValid(res.data.result));
      })
      .catch((err) => {
        console.log(err);
        dispatch(tokenInvalid());
      });
  };
};
