import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

const BackButton = () => {
  const history = useHistory();
  return (
    <Button
      onClick={() => history.goBack()}
      color="primary"
      size="sm"
      className="mb-3 ml-0"
    >
      <FontAwesomeIcon icon={faLongArrowAltLeft} size="lg" /> Back
    </Button>
  );
};

export default BackButton;
