import React from 'react';
import { Button } from 'reactstrap';
import { RotateSpinner } from 'react-spinners-kit';
import arrow from 'supports/assets/svg/arrowback.svg';

export default ({ onClick, loggingIn }) => {
  if (loggingIn) {
    return (
      <Button className="btn-rounded-green-border w-100 mb-3">
        <div className="d-flex flex-row justify-content-center">
          <p className="mr-3 pt-1">Loading</p>
          <RotateSpinner size={30} color="#ffffff" loading />
        </div>
      </Button>
    );
  }
  return (
    <Button className="btn-rounded-green-border w-100 mb-3" onClick={onClick}>
      Sign in <img src={arrow} alt="arrow" />
    </Button>
  );
};
