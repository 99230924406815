import moment from 'moment';

export default (transactionCreatedAt) => {
  const NEW_TAX_VALUE = 0.11;
  const OLD_TAX_VALUE = 0.1;

  if (!transactionCreatedAt) {
    return NEW_TAX_VALUE;
  }

  if (moment(transactionCreatedAt).isBefore('1 April 2022')) {
    return OLD_TAX_VALUE;
  }

  return NEW_TAX_VALUE;
};
