import React, { Component } from 'react';
import moment from 'moment';
import Axios from 'supports/api';

class FinanceReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rekap: false,
      selectedReport: '',
      selectedMonth: null,
      selectedYear: null,
      selectedFileType: null,
      isRekap: false,
    };
  }

  renderYear = () => {
    const d = new Date();
    let year = 2017;
    const yearArr = [];

    if (!this.state.isRekap) {
      while (year <= d.getFullYear()) {
        yearArr.push(year);
        year += 1;
      }
    } else {
      while (year < d.getFullYear()) {
        yearArr.push(year);
        year += 1;
      }
    }

    const jsx = yearArr.map((val) => {
      return <option value={val}>{val}</option>;
    });

    return jsx;
  };

  renderMonth = () => {
    const d = new Date();
    const d2 =
      this.state.selectedYear !== d.getFullYear()
        ? new Date(this.state.selectedYear, 11)
        : d;
    let month = 0;
    const monthArr = [];

    while (month <= d2.getMonth()) {
      monthArr.push(month);
      month += 1;
    }

    const jsx = monthArr.map((val) => {
      return (
        <option value={val}>
          {moment((val + 1).toString()).format('MMMM')}
        </option>
      );
    });

    return jsx;
  };

  onBtnDownloadHandler = () => {
    if (!this.state.isRekap) {
      Axios.get(`/report/${this.state.selectedReport}`, {
        params: {
          year: this.state.selectedYear,
          month: this.state.selectedMonth,
          fileType: this.state.selectedFileType,
        },
      })
        .then((res) => {
          window.open(
            `${process.env.REACT_APP_BASE_URL}/report/${this.state.selectedReport}?year=${this.state.selectedYear}&month=${this.state.selectedMonth}&fileType=${this.state.selectedFileType}`,
          );
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Axios.get(`/report/${this.state.selectedReport}rekap`, {
        params: {
          year: this.state.selectedYear,
          fileType: this.state.selectedFileType,
        },
      })
        .then((res) => {
          window.open(
            `${process.env.REACT_APP_BASE_URL}/report/${this.state.selectedReport}rekap?year=${this.state.selectedYear}&fileType=${this.state.selectedFileType}`,
          );
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleCheckbox = (e) => {
    this.setState({
      isRekap: e.target.checked,
      selectedYear: 2017,
      selectedMonth: 1,
    });
  };

  render() {
    return (
      <div className="container mt-3 card">
        <div className="card-body">
          <div className="row text-center">
            <div className="col-5">
              <label>Report Type</label>
              <select
                onChange={(e) =>
                  this.setState({ selectedReport: e.target.value })
                }
                className="custom-select"
              >
                <option value="" disabled selected />
                <option value="neraca">Neraca</option>
                <option value="incomestatement">Income Statement</option>
              </select>
            </div>
            <div className="col-5">
              <label>Year</label>
              <select
                className="custom-select"
                onChange={(e) =>
                  this.setState({ selectedYear: parseInt(e.target.value, 10) })
                }
              >
                <option value="" disabled selected />
                {this.renderYear()}
              </select>
            </div>
            <div className="col-2">
              <label> </label>
              <div className="custom-control custom-switch mt-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="switch"
                  onChange={this.handleCheckbox}
                />
                <label htmlFor="switch" className="custom-control-label">
                  Rekap
                </label>
              </div>
            </div>
          </div>
          <div className="row text-center">
            {!this.state.isRekap ? (
              <div className="col-5">
                <label>Month</label>
                <select
                  onChange={(e) =>
                    this.setState({ selectedMonth: e.target.value })
                  }
                  className="custom-select"
                >
                  <option value="" disabled selected />
                  {this.renderMonth()}
                </select>
              </div>
            ) : null}
            <div className="col-5">
              <label>File Type</label>
              <select
                onChange={(e) =>
                  this.setState({ selectedFileType: e.target.value })
                }
                className="custom-select"
              >
                <option value="" disabled selected />
                <option value="excel">Excel (.xlsx)</option>
                <option value="pdf">PDF (.pdf)</option>
              </select>
            </div>
            {this.state.selectedFileType &&
            this.state.selectedMonth &&
            this.state.selectedYear &&
            this.state.selectedReport ? (
              <div className="col-2">
                <label>&nbsp;</label>
                <input
                  onClick={this.onBtnDownloadHandler}
                  type="button"
                  className="btn btn-success btn-block"
                  value="Download"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default FinanceReportPage;
