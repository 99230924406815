import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMinimalTransactions } from 'redux/modules/transactions';
import DatePicker from 'react-datepicker';
import { Table, Button } from 'reactstrap';
import CustomPagination from 'components/commons/CustomPagination';
import { useLocation, useHistory } from 'react-router';
import Loader from 'react-loader-spinner';
import SearchBox from './SearchBox';
import AttendeeInputs from './AttendeeInputs';
import SalesTable from './SalesTable';

const AttendeesPage = () => {
  const [selectDate, setSelectDate] = useState(new Date());
  const [searchInput, setSearchInput] = useState({
    orderConfirmationNumber: '',
    name: '',
  });
  const [selectedTrx, setSelectedTrx] = useState({});
  const [selectedSales, setSelectedSales] = useState({});

  const filteredData = useSelector(
    (state) => state.transactions.filteredDataMinimal,
  );
  const status = useSelector((state) => state.transactions.status);

  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const page = params.get('page') || 1;
  const itemsPerPage = 5;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMinimalTransactions({ date: selectDate, searchInput }));
  }, [dispatch, selectDate, searchInput]);

  const handleDate = (date) => {
    setSelectDate(date);
    history.push('?page=1');
  };

  const RenderTable = () => {
    let sliced = filteredData.slice(
      (page - 1) * itemsPerPage,
      (page - 1) * itemsPerPage + itemsPerPage,
    );

    if (selectedTrx.id)
      sliced = sliced.filter((val) => val.id === selectedTrx.id);

    return sliced.map((trx) => {
      return (
        <tr className="d-flex" key={trx.id}>
          <td className="col-4">{trx.orderConfirmationNumber}</td>
          <td className="col-6">{`${trx.firstName} ${trx.lastName}`}</td>
          <td className="col-2">
            {selectedTrx.id === trx.id ? (
              <Button
                color="danger"
                size="sm"
                onClick={() => {
                  setSelectedTrx({});
                  setSelectedSales({});
                }}
              >
                Cancel
              </Button>
            ) : (
              <>
                <Button
                  color="info"
                  size="sm"
                  onClick={() => setSelectedTrx(trx)}
                >
                  Select
                </Button>
                {/* <Button
                  size="sm"
                  onClick={() =>
                    window.open(`transaction-details?id=${trx.id}`)
                  }
                >
                  Details
                </Button> */}
              </>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="body-app">
      <div className="container">
        <div className="line-green mb-1" />
        <div className="general-title text-gray mb-1">Program Attendees</div>
        <div
          className="p-3"
          style={{
            backgroundColor: '#ffffff',
            minHeight: '100px',
            height: '100%',
            borderRadius: '6px',
            boxShadow: '0 20px 40px 0 rgba(102, 102, 102, 0.16)',
          }}
        >
          <SearchBox
            setSearchInput={setSearchInput}
            searchInput={searchInput}
            setSelectedSales={setSelectedSales}
            setSelectedTrx={setSelectedTrx}
          />
        </div>
        <hr />
        {status === 'loading' ? (
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <Loader type="ThreeDots" color="#53b59f" height={40} width={40} />
          </div>
        ) : (
          <div
            className="p-3"
            style={{
              backgroundColor: '#ffffff',
              height: '100%',
              borderRadius: '6px',
              boxShadow: '0 20px 40px 0 rgba(102, 102, 102, 0.16)',
            }}
          >
            {!selectedTrx.id ? (
              <div className="row d-flex p-3">
                <div className="col-12">
                  <h5 className="text-center">Select Transaction</h5>
                </div>
                <div className="col-9">
                  <CustomPagination
                    pages={Math.ceil(filteredData.length / itemsPerPage) || 1}
                    active={page}
                  />
                </div>
                <div className="col-3">
                  <DatePicker
                    className="form-control"
                    selected={selectDate}
                    maxDate={Date.now()}
                    showMonthYearPicker
                    dateFormat="MMMM yyyy"
                    onChange={handleDate}
                  />
                </div>
              </div>
            ) : null}
            <div className="col-12">
              <Table size="sm" striped bordered responsive hover>
                <thead
                  className="font-weight-bold"
                  style={{ fontSize: '14px', color: '#505050' }}
                >
                  <tr className="d-flex">
                    <th className="col-4">Order Confirmation Number</th>
                    <th className="col-6">CUSTOMER</th>
                    <th className="col-2">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <RenderTable />
                </tbody>
              </Table>
            </div>
          </div>
        )}{' '}
        {selectedTrx.id ? (
          <SalesTable
            data={selectedTrx}
            setSelectedSales={setSelectedSales}
            selectedSales={selectedSales}
          />
        ) : null}
        {selectedSales.id ? (
          <>
            <hr />
            <div
              className="p-3"
              style={{
                backgroundColor: '#ffffff',
                height: '100%',
                borderRadius: '6px',
                boxShadow: '0 20px 40px 0 rgba(102, 102, 102, 0.16)',
              }}
            >
              <div className="row d-flex">
                <div className="col-12">
                  <h4 className="text-center">Attendee List</h4>
                  <AttendeeInputs
                    data={selectedSales}
                    setSelectedSales={setSelectedSales}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AttendeesPage;
