import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setStatusCheck,
  fetchTransactionStatuses,
} from 'redux/modules/trxSearch';
import { CustomInput } from 'reactstrap';

const StatusCheckbox = () => {
  const dispatch = useDispatch();
  const searchStatuses = useSelector((state) => state.trxSearch.searchStatuses);

  useEffect(() => {
    if (searchStatuses.length === 0) {
      dispatch(fetchTransactionStatuses());
    }
  }, [dispatch, searchStatuses.length]);

  const handleCheck = (e) => {
    dispatch(setStatusCheck(e.target.dataset.statusid));
  };

  let arrJSX = [];
  arrJSX = searchStatuses.map((item) => {
    return (
      <CustomInput
        type="checkbox"
        id={`checkbox-status-${item.name}`}
        key={`checkbox-status-${item.name}`}
        data-statusid={item.id}
        label={item.name}
        inline
        checked={item.checked}
        onChange={handleCheck}
      />
    );
  });

  return (
    <div className="mb-2">
      <div
        className="font-weight-bold"
        style={{ fontSize: '18px', color: '#505050' }}
      >
        Status
      </div>
      <div>{arrJSX}</div>
    </div>
  );
};

export default StatusCheckbox;
