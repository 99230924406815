import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = ({ isOpen, toggle, message, children }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>{children}</ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
