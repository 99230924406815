import React, { Component } from 'react';
import {
  Alert,
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import axios from 'supports/api';
import moment from 'moment';

// Object creator
import createTransactionItem from 'components/Transactions/create-transaction/modals/itemCreator';
import PriceDetails from './PriceDetails';

const INITIAL_STATE = {
  branches: [],
  programHeaders: [],
  categories: [],
  study_methods: [],
  study_schedules: [],
  filterCategory(programHeaders = this.programHeaders) {
    const programHeaderList = programHeaders.filter((programHeader) => {
      const isValidCategory =
        parseInt(this.categoryId.value, 10) ===
        parseInt(programHeader.program_categoryId, 10);
      const isInIDR = this.selectedCurrency?.id === 1;

      // exclude program if category not match
      if (!isValidCategory) {
        return false;
      }

      // always include program if selected currency is IDR
      if (isInIDR) {
        return true;
      }

      // if not in IDR, return only program with `isMultipleCurrency` is true
      return Boolean(programHeader.isMultipleCurrency);
    });

    return programHeaderList;
  },
  filterProgram() {
    let temp = [];
    const programHeaders = this.filterCategory();
    programHeaders.forEach((item) => {
      if (parseInt(this.programHeaderId.value, 10) === parseInt(item.id, 10)) {
        temp = item.programs;
      }
    });
    return temp;
  },
  filterStudyMethod() {
    const temp = [];
    const programs = this.filterProgram();
    programs.forEach((item) => {
      if (
        parseInt(this.study_methodId.value, 10) ===
        parseInt(item.study_methodId, 10)
      ) {
        temp.push(item);
      }
    });
    return temp;
  },
  filterStudySchedule() {
    const temp = [];
    const programs = this.filterStudyMethod();
    programs.forEach((item) => {
      if (
        parseInt(this.study_scheduleId.value, 10) ===
        parseInt(item.study_scheduleId, 10)
      ) {
        temp.push(item);
      }
    });
    return temp;
  },
  filterBranch() {
    const temp = [];
    const programs = this.filterStudySchedule();
    programs.forEach((item) => {
      if (parseInt(this.branchId.value, 10) === parseInt(item.branchId, 10)) {
        temp.push(item);
      }
    });
    return temp;
  },
};

const INITIAL_FILTER = {
  categoryId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Category',
  },
  programHeaderId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Program',
  },
  branchId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Branch',
  },
  study_methodId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Metode Belajar',
  },
  study_scheduleId: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Jadwal Belajar',
  },
};

const INITIAL_FORM = {
  program: {
    value: 0,
    valid: false,
    invalid: false,
    error: 'Please Select a Schedule',
  },
  discount: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
    error: 'Input discount',
  },
  qty: {
    value: 1,
    valid: true,
    invalid: false,
    error: 'Please Input Quantity',
  },
  basePrice: {
    value: 0,
    displayValue: '0',
    valid: true,
    invalid: false,
  },
  description: {
    value: '',
  },
};

class AddProgramModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      ...INITIAL_FILTER,
      ...INITIAL_FORM,
      selectedCurrency: this.props.selectedCurrency,
    };
  }

  componentDidMount() {
    axios
      .get(`/admin/program/list`)
      .then(({ data }) => {
        this.setState({ programHeaders: data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/admin/program/categories`)
      .then(({ data }) => {
        this.setState({ categories: data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/admin/branch/list`)
      .then(({ data }) => {
        this.setState({ branches: data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/program/methods`)
      .then(({ data }) => {
        this.setState({ study_methods: data.result });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`/program/schedules`)
      .then(({ data }) => {
        this.setState({ study_schedules: data.result });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps) {
    const prevCurrency = prevProps.selectedCurrency?.id;
    const currCurrency = this.props.selectedCurrency?.id;
    if (prevCurrency !== currCurrency) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedCurrency: this.props.selectedCurrency,
      });
    }
  }

  resetState = () => {
    this.setState({
      ...INITIAL_FORM,
      ...INITIAL_FILTER,
    });
  };

  validateForm = async (form) => {
    let result = 0;
    const exclude = [];
    const invalidField = [];
    await Object.keys(form).forEach(async (obj) => {
      if (!form[obj].valid && !exclude.includes(obj)) {
        await this.setState((prevState) => ({
          [obj]: { ...prevState[obj], invalid: true },
        }));
        console.log(obj);
        invalidField.push(obj);
        result += 1;
      }
    });

    if (result > 0) {
      let errorMessage = 'Invalid fields: \n';
      invalidField.forEach((item) => {
        console.log(item);
        errorMessage += `${item} \n`;
      });
      throw new Error(errorMessage);
    }
    return true;
  };

  onAddClick = () => {
    const { program, discount, qty } = this.state;
    let optional = {};
    if (program.type === 'program') {
      optional = { discount };
    }
    const validation = this.validateForm({ program, qty, ...optional }); // returns a promise

    validation
      .then(async (valid) => {
        if (valid) {
          const selectedProgram = JSON.parse(this.state.program.value);
          const transactionItem = createTransactionItem({
            id: selectedProgram.id, // universal
            price:
              this.state.basePrice.value +
              (selectedProgram.registrationFee
                ? selectedProgram.registrationFee
                : 0),
            // universal
            discount: this.state.discount.valid ? this.state.discount.value : 0, // program
            attendeeName: `${this.props.userData.firstName} ${this.props.userData.lastName}`, // program
            attendeeEmail: this.props.userData.email, // program
            attendeePhone: this.props.userData.phone, // program
            address: this.props.userData.address, // program
            productType: 'program', // universal,
            isGuest: false,
            qty: this.state.qty.value,
            attendees: [],
            description: this.state.description.value,
          });

          await this.props.addTransactionItem([transactionItem]);
          this.props.toggle();
        } else {
          alert('There are invalid fields');
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleInput = (state, value) => {
    const valid = true;
    const invalid = false;

    this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value, valid, invalid },
    }));
  };

  handleQuantityInput = (value) => {
    let valid = true;
    let invalid = false;
    if (!value || parseInt(value, 10) === 0) {
      valid = false;
      invalid = true;
    }

    this.setState((prevState) => ({
      ...prevState,
      qty: { ...prevState.qty, value, valid, invalid },
    }));
  };

  handleCurrencyInput = (state, value) => {
    let number = parseInt(value.split(',').join(''), 10);
    let displayValue = number.toLocaleString('en-US');
    const program = JSON.parse(this.state.program.value);
    let price = 0;
    let registrationFee = 0;
    if (program) {
      const programPrice = program?.program_prices.find(
        (program_price) =>
          program_price.currencyId === this.props.selectedCurrency?.id,
      );

      price =
        this.state.basePrice.value ??
        programPrice?.normalPrice ??
        program?.normalPrice;

      registrationFee =
        programPrice?.registrationFee ?? program?.registrationFee;
    }
    if (state === 'discount' && number > price + registrationFee) {
      return null;
    }

    if (value.split(',').join('') === '') {
      number = 0;
      displayValue = '0';
    }
    return this.setState((prevState) => ({
      ...prevState,
      [state]: { ...prevState[state], value: number, displayValue },
    }));
  };

  handleSelect = (state, value) => {
    const checkState = (checkedState) => {
      const {
        programHeaderId,
        branchId,
        study_methodId,
        study_scheduleId,
      } = INITIAL_FILTER;
      const { program } = INITIAL_FORM;
      switch (checkedState) {
        case 'categoryId':
          return {
            programHeaderId,
            branchId,
            program,
            study_methodId,
            study_scheduleId,
          };
        case 'programHeaderId':
          return { branchId, program, study_methodId, study_scheduleId };
        case 'study_methodId':
          return { program };
        case 'study_scheduleId':
          return { program };
        case 'branchId':
          return { program };
        default:
          return {};
      }
    };

    const formReset = checkState(state);
    let valid = false;
    let invalid = true;
    if (value !== 0) {
      valid = true;
      invalid = false;
    }

    if (state === 'program') {
      const selectedProgram = JSON.parse(value);

      const multipleCurrencyProgram = selectedProgram?.program_prices.find(
        (program_price) =>
          program_price.currencyId === this.props.selectedCurrency?.id,
      );

      if (multipleCurrencyProgram) {
        this.setState((prevState) => ({
          ...prevState,
          basePrice: {
            value: multipleCurrencyProgram.normalPrice,
            displayValue: multipleCurrencyProgram.normalPrice.toLocaleString(
              'en-US',
            ),
            valid: true,
            invalid: false,
          },
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          basePrice: {
            value: selectedProgram.normalPrice,
            displayValue: selectedProgram.normalPrice.toLocaleString('en-US'),
            valid: true,
            invalid: false,
          },
        }));
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      ...formReset,
      [state]: { value, valid, invalid },
    }));
  };

  handleDescriptionInput = (e) => {
    this.setState((prevState) => ({
      ...prevState,
      description: { ...prevState.description, value: e.target.value },
    }));
  };

  CategorySelector = ({ value, valid, invalid, error }) => {
    const arrJSX = this.state.categories.map((item) => {
      return (
        <option key={JSON.stringify(item)} value={item.id}>
          {item.name}
        </option>
      );
    });

    return (
      <FormGroup>
        <div className="text-gray font-weight-bold mb-2">Category</div>
        <Input
          type="select"
          valid={valid}
          invalid={invalid}
          value={value}
          onChange={({ target }) =>
            this.handleSelect('categoryId', target.value)
          }
        >
          <option value={0} disabled hidden>
            Select Category
          </option>
          {arrJSX}
        </Input>
        <FormFeedback invalid="true">*{error}</FormFeedback>
      </FormGroup>
    );
  };

  ProgramHeaderSelector = ({ value, valid, invalid, error }) => {
    const programs = this.state.filterCategory();
    const arrJSX = programs.map((item) => {
      return (
        <option key={`selectprogram${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });

    if (programs.length > 0) {
      return (
        <FormGroup>
          <div className="text-gray font-weight-bold mb-2">Pilih Program</div>
          <Input
            type="select"
            valid={valid}
            invalid={invalid}
            value={value}
            onChange={({ target }) =>
              this.handleSelect('programHeaderId', target.value)
            }
          >
            <option value={0} disabled hidden>
              Pilih Program
            </option>
            {arrJSX}
          </Input>
          <FormFeedback invalid="true">*{error}</FormFeedback>
        </FormGroup>
      );
    }
    return null;
  };

  BranchSelector = ({ value, valid, invalid, error }) => {
    const arrJSX = this.state.branches.map((item) => {
      return (
        <option key={`selectbranch${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });

    if (this.state.programHeaderId.value) {
      return (
        <FormGroup>
          <div className="text-gray font-weight-bold mb-2">Lokasi</div>
          <Input
            type="select"
            valid={valid}
            invalid={invalid}
            value={value}
            onChange={({ target }) =>
              this.handleSelect('branchId', target.value)
            }
          >
            <option value={0} disabled hidden>
              Pilih Lokasi
            </option>
            {arrJSX}
          </Input>
          <FormFeedback invalid="true">*{error}</FormFeedback>
        </FormGroup>
      );
    }
    return null;
  };

  StudyMethodSelector = ({ value, valid, invalid, error }) => {
    const arrJSX = this.state.study_methods.map((item) => {
      return (
        <option key={`selectstudymethod${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });

    if (this.state.programHeaderId.value) {
      return (
        <FormGroup>
          <div className="text-gray font-weight-bold mb-2">Metode Belajar</div>
          <Input
            type="select"
            valid={valid}
            invalid={invalid}
            value={value}
            onChange={({ target }) => {
              this.handleSelect('study_methodId', target.value);
            }}
          >
            <option value={0} disabled hidden>
              Pilih Metode Belajar
            </option>
            {arrJSX}
          </Input>
          <FormFeedback invalid="true">*{error}</FormFeedback>
        </FormGroup>
      );
    }
    return null;
  };

  StudyScheduleSelector = ({ value, valid, invalid, error }) => {
    const arrJSX = this.state.study_schedules.map((item) => {
      return (
        <option key={`selectschedulemethod${item.id}`} value={item.id}>
          {item.name}
        </option>
      );
    });

    if (this.state.programHeaderId.value) {
      return (
        <FormGroup>
          <div className="text-gray font-weight-bold mb-2">Jadwal Belajar</div>
          <Input
            type="select"
            valid={valid}
            invalid={invalid}
            value={value}
            onChange={({ target }) =>
              this.handleSelect('study_scheduleId', target.value)
            }
          >
            <option value={0} disabled hidden>
              Pilih Jadwal Belajar
            </option>
            {arrJSX}
          </Input>
          <FormFeedback invalid="true">*{error}</FormFeedback>
        </FormGroup>
      );
    }
    return null;
  };

  ScheduleSelector = ({ value, valid, invalid, error }) => {
    const schedules = this.state.filterBranch();
    const arrJSX = schedules.map((item) => {
      return (
        <option key={`selectprogram${item.id}`} value={JSON.stringify(item)}>
          {item.code} {moment(item.startDate).format('DD MMM').toString()} -{' '}
          {moment(item.endDate).format('DD MMM YYYY').toString()}
        </option>
      );
    });
    if (schedules.length > 0) {
      return (
        <FormGroup>
          <div className="text-gray font-weight-bold mb-2">Schedule</div>
          <Input
            type="select"
            valid={valid}
            invalid={invalid}
            value={value}
            onChange={({ target }) =>
              this.handleSelect('program', target.value)
            }
          >
            <option value={0} disabled hidden>
              Pilih Schedule
            </option>
            {arrJSX}
          </Input>
          <FormFeedback invalid="true">*{error}</FormFeedback>
        </FormGroup>
      );
    }
    if (this.state.programHeaderId.value) {
      return (
        <FormGroup>
          <div className="text-gray font-weight-bold mb-2">Schedule</div>
          <Input type="text" disabled invalid value="No available schedule" />
        </FormGroup>
      );
    }
    return null;
  };

  RenderAlert = () => {
    const { invalid, error } = this.state.program;

    if (invalid) {
      return (
        <Alert color="danger" className="py-1">
          {error}
        </Alert>
      );
    }
    return null;
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        size="lg"
        onClosed={this.resetState}
      >
        <ModalHeader>Add Program Item</ModalHeader>
        <ModalBody>
          <Card className="my-1">
            <CardHeader>Select Program</CardHeader>
            <CardBody>
              <this.RenderAlert />
              <this.CategorySelector {...this.state.categoryId} />
              <this.ProgramHeaderSelector {...this.state.programHeaderId} />
              <this.StudyMethodSelector {...this.state.study_methodId} />
              <this.StudyScheduleSelector {...this.state.study_scheduleId} />
              <this.BranchSelector {...this.state.branchId} />
              <this.ScheduleSelector {...this.state.program} />
            </CardBody>
          </Card>
          <Card className="my-1">
            <CardBody>
              <PriceDetails
                item={this.state.program.value}
                discount={this.state.discount.value}
                qty={this.state.qty.value}
                qtyInput={this.state.qty}
                basePriceInput={this.state.basePrice}
                discountInput={this.state.discount}
                handleCurrencyInput={this.handleCurrencyInput}
                handleQuantityInput={this.handleQuantityInput}
                selectedCurrency={this.props.selectedCurrency}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Input
                type="textarea"
                placeholder="Description"
                onChange={this.handleDescriptionInput}
              />
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex-row float-right">
            <Button
              color="success"
              style={{ backgroundColor: '#53b59f' }}
              onClick={this.onAddClick}
            >
              Add
            </Button>
            <Button className="ml-2" color="danger" onClick={this.props.toggle}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddProgramModal;
