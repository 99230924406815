import Axios from 'supports/api';
import { faSearch, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomPaginationV2 from 'components/commons/CustomPaginationV2';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import qs from 'query-string';
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  FormGroup,
  Label,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router';
import { API_URL_1 } from 'supports/api-url/apiurl';

/**
 * Component Fitur Upload PDF E-Faktur
 */
const EFakturUpload = ({
  handleFetchTransactions,
  data,
  status,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  const itemsPerPage = 10;
  const location = useLocation();
  const history = useHistory();

  const queryParam = qs.parse(location.search);
  const { page } = queryParam;
  const [idSelected, setIdSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const toggleCollapse = () => setIsOpen(!isOpen);

  const [openModal, setOpenModal] = useState(false);
  const [eFakturPDF, setEFakturPDF] = useState({
    fileName: null,
    fileObj: null,
    fileDB: null,
    fileOrigin: null,
    action: null,
  });

  /**
   *
   * @param {number} idTrx - mengirim id transaction yang statusnya selected
   * @param {string} filePath - filePath dari file pdf yang ada di table transaction
   * @param {string} action - action pembeda apakah ini proses upload atau hanya preview
   */
  const handlePDF = (idTrx, filePath, action) => {
    if (action === 'upload') {
      const file = document.getElementById(`file-upload-efaktur-${idTrx}`)
        .files[0];
      if (file.name.match(/.(pdf)$/i)) {
        setIdSelected(idTrx);
        setEFakturPDF({
          fileName: file.name,
          fileObj: URL.createObjectURL(file),
          fileDB: file,
          fileOrigin: filePath,
          action,
        });
        setOpenModal(!openModal);
      }
    } else {
      setEFakturPDF({
        fileObj: `${API_URL_1}${filePath}`,
        action,
      });
      setOpenModal(!openModal);
    }
  };

  const handleToPageOne = () => {
    history.push(
      `?${qs.stringify({
        ...queryParam,
        page: 1,
      })}`,
    );
  };

  /**
   *
   * @param {string} taxInvoiceFile - adalah filepath apabila document sudah disave di database
   */
  const PreviewTaxInvoiceFile = ({ taxInvoiceFile }) => {
    if (taxInvoiceFile) {
      return (
        <Button
          color="success"
          className="my-1 mr-2"
          onClick={() => handlePDF(null, taxInvoiceFile, 'preview')}
        >
          View E-Faktur
        </Button>
      );
    }

    return (
      <Button color="secondary" disabled className="my-1 mr-2">
        View E-Faktur
      </Button>
    );
  };

  /**
   * function renderTableBody untuk merender isi table body
   */
  const renderTableBody = () => {
    const sliced = data.slice((page - 1) * 10, (page - 1) * 10 + 10);
    return sliced.map((trx) => {
      // format taxInvoiceNumber
      let { taxInvoiceNumber } = trx;
      if (taxInvoiceNumber) {
        const splitTaxInvoiceNumber = taxInvoiceNumber.split('');
        splitTaxInvoiceNumber.splice(3, 0, '.');
        splitTaxInvoiceNumber.splice(7, 0, '.');
        splitTaxInvoiceNumber.splice(10, 0, '-');
        taxInvoiceNumber = splitTaxInvoiceNumber.join('');
      }

      return (
        <tr key={trx.id}>
          <td>{trx.fullName}</td>
          <td>{trx.invoiceNumber}</td>
          <td>{moment(trx.invoiceCreatedAt).format('DD/MM/YYYY')}</td>
          <td>{taxInvoiceNumber}</td>
          <td>
            {trx.taxInvoiceDate &&
              moment(trx.taxInvoiceDate).format('DD/MM/YYYY')}
          </td>
          <td>
            <PreviewTaxInvoiceFile taxInvoiceFile={trx.taxInvoiceFile} />
          </td>
          <td>
            <label
              htmlFor={`file-upload-efaktur-${trx.id}`}
              className="custom-file-upload"
              style={{
                border: '1px solid #ccc',
                display: 'inline-block',
                padding: ' 6px 12px',
                cursor: 'pointer',
                color: 'white',
                backgroundColor: '#808080',
                borderRadius: '6px',
              }}
            >
              <FontAwesomeIcon icon={faUpload} className="mr-1" />
              Upload E-Faktur PDF
            </label>
            <input
              id={`file-upload-efaktur-${trx.id}`}
              type="file"
              style={{ display: 'none' }}
              onChange={() => handlePDF(trx.id, trx.taxInvoiceFile, 'upload')}
            />
          </td>
        </tr>
      );
    });
  };

  /**
   * toggleModal untuk menutup modal yang sedang terbuka
   */
  const toggleModal = () => {
    if (eFakturPDF.action === 'upload') {
      document.getElementById(`file-upload-efaktur-${idSelected}`).value = '';

      setIdSelected(null);
    }
    setEFakturPDF({
      ...eFakturPDF,
      fileName: null,
      fileObj: null,
      fileDB: null,
      fileOrigin: null,
    });
    setOpenModal(!openModal);
  };

  /**
   * Function untuk mengupload EFaktur Doc/PDF ke database
   */
  const uploadEFakturToDatabase = () => {
    const formData = new FormData();
    formData.append('efaktur', eFakturPDF.fileDB);
    if (eFakturPDF.fileOrigin) {
      formData.append('fileOriginPath', eFakturPDF.fileOrigin);
    }
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    Axios.put(`/transaction/upload-taxfile/${idSelected}`, formData, headers)
      .then(() => {
        toggleModal();
        handleFetchTransactions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * dalam component ini dibedakan berdasarkan action
   * karena apabila hanya preview saja tidak ditampilkan
   */
  const ButtonUpload = () => {
    if (eFakturPDF.action === 'upload') {
      return (
        <Button
          color="success"
          size="lg"
          block
          className="my-1 mr-2"
          onClick={() => uploadEFakturToDatabase()}
        >
          <FontAwesomeIcon icon={faUpload} className="mr-1" />
          Upload PDF
        </Button>
      );
    }

    return null;
  };

  /**
  * 
  * @param {{isOpenModal: boolean, toggleModalPreview: function}}
  }, }}   
  */
  const ModalPreviewPDF = ({ isOpenModal, toggleModalPreview }) => {
    return (
      <Modal toggle={() => toggleModalPreview()} isOpen={isOpenModal} size="xl">
        <ModalHeader toggle={() => toggleModalPreview()}>
          Preview E-Faktur PDF {eFakturPDF.fileName}
        </ModalHeader>
        <ModalBody>
          <object
            data={eFakturPDF.fileObj}
            type="application/pdf"
            width="100%"
            style={{
              height: '100vh',
            }}
            className="pwdk-mt-5"
          >
            <iframe
              title="E-Faktur PDF"
              src={eFakturPDF.fileObj}
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
              }}
            />
          </object>
        </ModalBody>
        <ModalFooter>
          <ButtonUpload />
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <Row>
      <ModalPreviewPDF
        isOpenModal={openModal}
        toggleModalPreview={toggleModal}
      />
      <Col sm="12">
        <Card body>
          {/* Start Filter Region */}
          <div className={isOpen ? 'dropup' : null}>
            <button
              type="button"
              className="btn btn-secondary mt-2 dropdown-toggle btn-collapse-pym"
              onClick={toggleCollapse}
            >
              Filter
            </button>
          </div>
          <Collapse isOpen={isOpen}>
            <Card className="mt-1">
              <CardBody>
                <Form>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>E-Faktur Start Date</Label>
                      <Col sm={7}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          selected={fromDate}
                          onChange={(pickedDate) => setFromDate(pickedDate)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>E-Faktur End Date</Label>
                      <Col sm={7}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          selected={toDate}
                          onChange={(pickedDate) => setToDate(pickedDate)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Form>
                <div className="d-flex flex-row-reverse">
                  <Button
                    color="success"
                    className="mt-0"
                    style={{
                      backgroundColor: 'rgb(50, 178, 128)',
                      width: '20%',
                    }}
                    onClick={() => {
                      handleFetchTransactions();
                      handleToPageOne();
                    }}
                  >
                    {status === 'loading' ? (
                      <Loader
                        type="ThreeDots"
                        color="#ffffff"
                        height={20}
                        width={40}
                      />
                    ) : (
                      <>
                        Search
                        <FontAwesomeIcon icon={faSearch} className="ml-2" />
                      </>
                    )}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Collapse>
          {/* End Filter Region */}
          {/* Start Table Region */}
          {data.length ? (
            <>
              <div className="mt-3 d-flex flex-row justify-content-between align-items-center">
                <CustomPaginationV2
                  pages={Math.ceil(data.length / itemsPerPage) || 1}
                  active={page}
                />
                <div>{data.length} Total Data</div>
              </div>
              <div>
                <Table striped bordered responsive hover>
                  <thead
                    className="font-weight-bold"
                    style={{ fontSize: '14px', color: '#505050' }}
                  >
                    <tr>
                      <th>Nama Customer</th>
                      <th>Invoice</th>
                      <th>Tgl. Invoice</th>
                      <th>No. Faktur</th>
                      <th>Tgl. Faktur</th>
                      <th colSpan={2}>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableBody()}</tbody>
                </Table>
              </div>{' '}
            </>
          ) : null}
          {status === 'success' && data.length === 0 ? (
            <div className="mt-3 d-flex flex-row justify-content-center">
              <h3>No Data Found</h3>
            </div>
          ) : null}
          {/* End Table Region */}
        </Card>
      </Col>
    </Row>
  );
};

export default EFakturUpload;
