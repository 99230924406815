import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'supports/api';
import qs from 'query-string';

const DuplicateLivestreamModal = ({
  isOpen = false,
  toggle = () => {},
  livestream = {},
  refetch = () => {},
}) => {
  const { title = '', id } = livestream || {};

  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const onConfirmToDuplicate = async () => {
    try {
      setLoading(true);

      await axios.post(`/v2/program-livestream/${id}/duplicate`);

      toggle();
      refetch();

      const newQueryParam = { ...qs.parse(location.search), page: 1 };
      history.push(`?${qs.stringify(newQueryParam)}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Duplicate Program Livestream</ModalHeader>

      <ModalBody>
        <p>Are you sure to duplicate &quot;{title}&quot;?</p>
        <p>
          <strong>This action is irreversible!</strong>
        </p>
      </ModalBody>

      <ModalFooter>
        <Button
          type="button"
          className="mr-2"
          color="success"
          disabled={loading}
          onClick={onConfirmToDuplicate}
        >
          {loading && (
            <div
              className="spinner-border spinner-border-sm mr-2 text-center"
              role="status"
            />
          )}
          Duplicate
        </Button>

        <Button type="button" color="danger" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DuplicateLivestreamModal;
