// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import Axios from 'supports/api';

const initialState = {
  status: 'idle',
  data: [],
};

const { actions, reducer } = createSlice({
  name: 'studentReport',
  initialState,
  reducers: {
    fetchingStudentBirthdayStart: (state) => {
      state.status = 'loading';
    },
    setStudentBirthdayData: (state, action) => {
      state.data = action.payload;
    },
    fetchingStudentBirthdaySuccess: (state) => {
      state.status = 'success';
    },
    fetchingStudentBirthdayError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
    },
  },
});

export default reducer;

export const {
  fetchingStudentBirthdayStart,
  setStudentBirthdayData,
  fetchingStudentBirthdayError,
  fetchingStudentBirthdaySuccess,
} = actions;

export const fetchStudentBirthday = ({ startDate, endDate }) => async (
  dispatch,
) => {
  dispatch(fetchingStudentBirthdayStart());
  try {
    const options = {
      params: {
        startDate,
        endDate,
      },
    };
    const res = await Axios.get(`/studentInfo/birthday`, options);

    dispatch(setStudentBirthdayData(res.data.result));

    dispatch(fetchingStudentBirthdaySuccess());
  } catch (err) {
    dispatch(fetchingStudentBirthdayError(err));
  }
};
