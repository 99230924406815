import React, { useMemo } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import Status from 'components/Transactions/create-transaction/commons/Status';

const TableRow = ({ index, onSubmit }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const value = useMemo(() => values.installmentPayments[index], [
    index,
    values.installmentPayments,
  ]);

  const errorData = useMemo(() => {
    const error = {
      tempNominal: '',
    };

    if (Array.isArray(errors.installmentPayments)) {
      const errorObj = errors.installmentPayments?.[index];

      if (typeof errorObj === 'object') {
        error.tempNominal = errorObj.tempNominal || '';
      }
    }

    return error;
  }, [errors.installmentPayments, index]);

  const transactionStatus = useMemo(() => {
    const statusData = {
      color: '#FFC107',
    };

    switch (value?.status) {
      case 'Pending Payment':
        statusData.color = '#FFC107';
        break;

      case 'Pending Confirmation':
        statusData.color = '#3366FF';
        break;

      case 'Paid':
        statusData.color = '#32B280';
        break;

      case 'Cancelled':
        statusData.color = '#FF4842';
        break;

      default:
        break;
    }

    return statusData;
  }, [value?.status]);

  // ----- Handle Action -----
  const handleSave = () => {
    if (value.isSaved) {
      return onSubmit('edit');
    }

    return onSubmit('create');
  };

  const handleEdit = () => {
    setFieldValue(`installmentPayments.${index}.allowEdit`, true);
  };

  const handleCancel = () => {
    setFieldValue(`installmentPayments.${index}`, {
      ...value,
      ...value.originalValue,
    });
    setFieldValue(`installmentPayments.${index}.allowEdit`, false);
  };

  const handleDelete = () => {
    onSubmit('delete');
  };
  // ----- Handle Action -----

  return (
    <tr>
      {/* # */}
      <td style={{ verticalAlign: 'middle' }}>{index + 1}</td>

      {/* Nominal */}
      <td style={{ verticalAlign: 'middle' }}>
        {value.allowEdit ? (
          <InputGroup>
            <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>

            <NumberFormat
              customInput={Input}
              thousandSeparator
              isAllowed={(val) => val.floatValue >= 0}
              defaultValue={value?.tempNominal}
              placeholder="Nominal"
              onValueChange={(val) => {
                setFieldValue(
                  `installmentPayments.${index}.tempNominal`,
                  val.floatValue,
                );
              }}
              style={{
                ...(touched.installmentPayments?.[index]?.tempNominal &&
                  errorData.tempNominal && { borderColor: 'red' }),
              }}
              onBlur={() =>
                setFieldTouched(
                  `installmentPayments.${index}.tempNominal`,
                  true,
                )
              }
            />
          </InputGroup>
        ) : (
          `Rp ${Number(value.nominal).toLocaleString()}`
        )}
      </td>

      {/* Date */}
      <td style={{ verticalAlign: 'middle' }}>
        {value.date ? moment(value.date).format('DD/MM/YYYY').toString() : '-'}
      </td>

      {/* Order Confirmation Number */}
      <td style={{ verticalAlign: 'middle' }}>
        <div className="d-flex align-items-center">
          {value.orderConfirmationNumber || '-'}
        </div>
      </td>

      {/* Status */}
      <td style={{ verticalAlign: 'middle' }}>
        {value.status ? (
          <Status style={{ backgroundColor: transactionStatus.color }}>
            {value.status}
          </Status>
        ) : (
          '-'
        )}
      </td>

      {/* Paid at */}
      <td style={{ verticalAlign: 'middle' }}>
        {value.paidAt
          ? moment(value.paidAt).format('DD/MM/YYYY').toString()
          : '-'}
      </td>

      {/* Action */}
      <td style={{ verticalAlign: 'middle' }}>
        {value.readOnly ? (
          '-'
        ) : (
          <div className="d-flex justify-content-center">
            {value.allowEdit ? (
              <Button className="mr-2" color="success" onClick={handleSave}>
                Save
              </Button>
            ) : (
              <Button className="mr-2" color="primary" onClick={handleEdit}>
                Edit
              </Button>
            )}

            {Boolean(value.isSaved) && value.allowEdit && (
              <Button outline onClick={handleCancel}>
                Cancel
              </Button>
            )}

            {Boolean(value.isSaved) && !value.allowEdit && (
              <Button color="danger" outline onClick={handleDelete}>
                Delete
              </Button>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

const TransactionInstallmentPaymentForm = ({ onSubmit }) => {
  const { values } = useFormikContext();

  return values.installmentPayments.map((_installmentPayment, index) => (
    <TableRow
      index={index}
      onSubmit={(formType) => onSubmit({ index, formType })}
    />
  ));
};

export default TransactionInstallmentPaymentForm;
