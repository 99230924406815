import React, { Component } from 'react';
import axios from 'supports/api';
import { connect } from 'react-redux';
import { CustomInput, Table } from 'reactstrap';
import TopicCard from './TopicCard';

class AdminTopics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      topics: [],
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    axios
      .get(`/topic/all`)
      .then((res) => {
        this.setState({ topics: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onCreateTopic = () => {
    const formData = new FormData();
    const headers = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    if (document.getElementById('topicImage')) {
      formData.append(
        'topicIcon',
        document.getElementById('topicImage').files[0],
      );
    }
    formData.append('name', this.refs.topicName.value);

    axios
      .post(`/topic/create`, formData, headers)
      .then((res) => {
        this.refreshData();
        alert('Membuat Topic Baru Success');
        console.log(res);
      })
      .catch((err) => {
        alert('Membuat Topic Baru Gagal');
        console.log(err);
      });
  };

  renderCreateTopic = () => {
    return (
      <div className="white-box py-1">
        <div className="row px-5 py-3">
          <div className="col-6">
            <div>
              <div className="line-green mb-4" />
              <div className="general-title text-gray">Create Topic</div>
            </div>
            <div className="row">
              <div className="mt-3 pt-3 col-12">
                <label className="text-container">
                  <input
                    type="text"
                    className="text-input"
                    ref="topicName"
                    placeholder="Ketik Nama Topik"
                    id="topicName"
                  />
                  <div className="text">Topic Name :</div>
                </label>
              </div>
              <div className="col-6">
                <CustomInput
                  type="file"
                  id="topicImage"
                  name="topicImage"
                  label="Pilih Icon Topic"
                  onChange={() => {
                    if (document.getElementById('topicImage').files[0]) {
                      this.setState({
                        fileName: document.getElementById('topicImage').files[0]
                          .name,
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">{this.state.fileName}</div>
            </div>
            <div className="mt-3">
              <input
                type="button"
                className="btn-rounded-green-border w-100"
                value="Create Topic"
                onClick={() => {
                  this.onCreateTopic();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTopics = () => {
    const arrJSX = this.state.topics.map((item, index) => {
      return (
        <TopicCard
          key={index}
          item={item}
          index={index}
          refresh={() => this.refreshData()}
        />
      );
    });

    return arrJSX;
  };

  renderTopicsTable = () => {
    return (
      <div className="white-box my-3">
        <div className="row px-5 py-3">
          <div className="col-6">
            <div>
              <div className="line-green mb-4" />
              <div className="general-title text-gray">All Topics</div>
            </div>
          </div>
        </div>
        <div className="row px-5 py-3">
          <div className="col">
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Topic Name</th>
                  <th>Topic Icon</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{this.renderTopics()}</tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  };

  renderAdminTopics = () => {
    return (
      <div className="row">
        <div className="col">
          {this.renderCreateTopic()}
          {this.renderTopicsTable()}
        </div>
      </div>
    );
  };

  render() {
    return this.renderAdminTopics();
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return { auth };
};

export default connect(mapStateToProps, {})(AdminTopics);
