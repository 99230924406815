import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from 'reactstrap';
import qs from 'query-string';
import '../onlineCourseStyle.css';

import OnlineCourseDataList from './OnlineCourseDataList';
import OnlineCourseDetail from './OnlineCourseDetail';

const OnlineCourseDataPage = () => {
  const location = useLocation();
  const history = useHistory();

  const activeTab = qs.parse(location.search).tab;

  const handleTabs = (tab) => {
    const queryParam = qs.parse(location.search);
    const newQueryParam = {
      ...queryParam,
      tab,
    };
    history.push(`?${qs.stringify(newQueryParam)}`);
    // toggle(tab);
  };

  useEffect(() => {
    const queryParam = qs.parse(location.search);
    if (!queryParam.tab) {
      const newQueryParam = {
        ...queryParam,
        tab: 'list',
        page: 1,
      };
      history.push(`?${qs.stringify(newQueryParam)}`);
    }
  }, [history, location.search]);

  return (
    <div
      className="white-box container py-2"
      style={{ minHeight: '100vh', marginTop: '40px' }}
    >
      <div className="d-flex flex-row justify-content-between mb-2">
        <div
          className="font-weight-bold"
          style={{ fontSize: '32px', color: '#505050' }}
        >
          Online Course List
        </div>
        <Button
          className="float-right mt-auto"
          onClick={() => {
            history.push(`${location.pathname}/create`);
          }}
        >
          + Create Course
        </Button>
      </div>
      <div>
        <Nav tabs className="cursor-pointer">
          <NavItem>
            <NavLink
              active={activeTab === 'list'}
              onClick={() => handleTabs('list')}
            >
              List
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activeTab === 'data'}
              onClick={() => handleTabs('data')}
            >
              Data
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="list">
            <OnlineCourseDataList />
          </TabPane>
          <TabPane tabId="data">
            <OnlineCourseDetail />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default OnlineCourseDataPage;
