// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import { createSlice } from '@reduxjs/toolkit';
import axios from 'supports/api';

const initialState = {
  loading: false,
  loadingEdit: false,
  status: 'idle',
  error: null,
  data: [],
  filtered: [],
  input: {
    filterQuery: '',
    filterProgram: '',
    filterBranch: '',
    filterBatch: '',
  },
  options: {
    programNames: [],
    branches: [],
    batches: [],
    filteredBatches: [],
  },
};

const { actions, reducer } = createSlice({
  name: 'studentData',
  initialState,
  reducers: {
    fetchingStudentDataStart: (state) => {
      state.loading = true;
      state.status = 'loading';
    },
    setStudentData: (state, action) => {
      state.data = action.payload;
      state.filtered = action.payload;
    },
    setOptions: (state, action) => {
      state.options.programNames = action.payload.programNames;
      state.options.branches = action.payload.branches;
      state.options.batches = action.payload.batches;
      state.options.filteredBatches = action.payload.batches;
    },
    filteringBatchesOption: (state, action) => {
      const { branchId, programHeaderId } = action.payload;
      let filtered = state.options.batches;
      if (programHeaderId) {
        filtered = filtered.filter(
          (batch) => batch.programHeaderId === Number(programHeaderId),
        );
      }
      if (branchId) {
        filtered = filtered.filter(
          (batch) => batch.branchId === Number(branchId),
        );
      }
      state.options.filteredBatches = filtered;
    },
    fetchingStudentDataSuccess: (state) => {
      state.loading = false;
      state.status = 'success';
    },
    fetchingStudentDataFailure: (state, action) => {
      state.loading = false;
      state.status = 'failure';
      state.error = action.payload;
    },
    filteringStudentData: (state, action) => {
      const {
        filterQuery,
        filterProgram,
        filterBranch,
        filterBatch,
      } = action.payload;

      let newFiltered = state.data;
      if (filterQuery) {
        newFiltered = newFiltered.filter((item) => {
          const { nama, program, tanggalLahir, gender, kabKota } = item;
          const lowerQuery = filterQuery.toLowerCase();
          return (
            nama.toString().toLowerCase().indexOf(lowerQuery) !== -1 ||
            program.toString().toLocaleLowerCase().indexOf(lowerQuery) !== -1 ||
            tanggalLahir.toString().toLocaleLowerCase().indexOf(lowerQuery) !==
              -1 ||
            gender.toString().toLocaleLowerCase().indexOf(lowerQuery) !== -1 ||
            kabKota.toString().toLowerCase().indexOf(lowerQuery) !== -1
          );
        });
      }

      if (filterProgram) {
        newFiltered = newFiltered.filter((item) => {
          return Number(filterProgram) === item.programHeaderId;
        });
      }

      if (filterBranch) {
        newFiltered = newFiltered.filter((item) => {
          return Number(filterBranch) === item.branchId;
        });
      }

      if (filterBatch) {
        newFiltered = newFiltered.filter((item) => {
          return Number(filterBatch) === item.programId;
        });
      }

      state.filtered = newFiltered;
    },
    setFilterQuery: (state, action) => {
      state.input.filterQuery = action.payload;
    },
    setFilterProgram: (state, action) => {
      state.input.filterProgram = action.payload;
    },
    setFilterBranch: (state, action) => {
      state.input.filterBranch = action.payload;
    },
    setFilterBatch: (state, action) => {
      state.input.filterBatch = action.payload;
    },
    editStudentDataStart: (state) => {
      state.loadingEdit = true;
    },
    editStudentDataSuccess: (state) => {
      state.loadingEdit = false;
      state.status = 'Edit Success';
    },
    editStudentDataFailed: (state, action) => {
      state.loadingEdit = false;
      state.error = action.payload;
      state.status = 'edit failure';
    },
  },
});

export default reducer;

export const {
  fetchingStudentDataStart,
  fetchingStudentDataSuccess,
  fetchingStudentDataFailure,
  filteringStudentData,
  setStudentData,
  setOptions,
  filteringBatchesOption,
  setFilterQuery,
  setFilterBranch,
  setFilterProgram,
  setFilterBatch,
  editStudentDataStart,
  editStudentDataSuccess,
  editStudentDataFailed,
} = actions;

export const initialFetchStudentData = () => async (dispatch) => {
  dispatch(fetchingStudentDataStart());
  try {
    const res = await axios.get(`/studentInfo/all`);
    const programNames = await axios.get(`/admin/program/all-header-names`, {
      params: { type: 'program' },
    });
    const branches = await axios.get(`/admin/branch/list`);
    const batches = await axios.get(`/program/batch`);

    dispatch(setStudentData(res.data.result));
    dispatch(
      setOptions({
        programNames: programNames.data.result,
        branches: branches.data.result,
        batches: batches.data.result,
      }),
    );
    dispatch(fetchingStudentDataSuccess());
  } catch (err) {
    dispatch(fetchingStudentDataFailure(err));
  }
};

export const editStudentInfo = (data) => async (dispatch) => {
  dispatch(editStudentDataStart());
  try {
    await axios.put(`/studentInfo/edit/${data.id}`, data);
    dispatch(editStudentDataSuccess());
    window.alert('Data berhasil di simpan');
  } catch (err) {
    dispatch(editStudentDataFailed(err));
    window.alert(err);
  }
};
