import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import SideBar from 'components/SideBar';
// Transactions
import TransactionPage from 'pages/finance/transactions/main';
import TransactionDetails from 'components/Transactions/TransactionDetails';

// import AdminFinanceManagePayment from 'components/AdminFinanceManagePayment
import EFakturPage from 'pages/finance/efaktur/EFakturPage';
import CreateTransactionPage from 'components/Transactions/create-transaction';
import CreatePromoPage from 'components/Transactions/create-promo';
import StudentReportPage from 'pages/student/studentReport/StudentReportPage';
import StudentBirthdayPage from 'pages/student/studentBirthday/StudentBirthdayPage';
import AdminFinanceManagePayment from 'pages/finance/payments/PaymentPage';
import AdminFinancePurchaseOrderNew from 'pages/finance/purchaseOrder/PurchaseOrderPage';
import AdminTopics from 'components/AdminTopics';

import AdminAccountingPosting from 'components/AdminAccountingPosting';
// import AdminFinancePurchaseOrder from 'components/PurchaseOrders/AdminFinancePurchaseOrder';
// import AdminFinanceEDCJournal from 'components/AdminFinanceEDCJournal';
import AdminFinanceMasterTujuanPengeluaran from 'components/AdminFinanceMasterTujuanPengeluaran';
import AdminFinanceMasterTujuanPenerimaan from 'components/AdminFinanceMasterTujuanPenerimaan';
import AdminScholarships from 'components/AdminScholarships';
import AdminScholarshipCreate from 'components/AdminScholarshipCreate';
import AdminScholarshipEdit from 'components/AdminScholarshipEdit';

// Programs
import ProgramHeaderForm from 'components/Programs/ProgramHeaderForm';
import ProgramPage from 'components/Programs/ProgramPage';
import ProgramDetails from 'components/Programs/ProgramDetails';
import ScheduleForm from 'components/Programs/ScheduleForm';
// import ExamSchedule from 'pages/programs/examSchedule';

// Reports
import FinanceReportPage from 'components/Report/FinanceReportPage';
import SalesReportPage from 'pages/reports/salesreport';
import ProgramSalesReportPage from 'pages/reports/programsalesreport';
import BankReportPage from 'pages/reports/bankeport/BankReportPage';
import AdminReportsEnquiries from 'pages/reports/enquiryreport';

// Vouchers
import VouchersPage from 'components/Vouchers/VouchersPage';
import ReferralCodePage from 'pages/vouchers/referralCode';

// Accounting
import AdminAccountingGLGenerator from 'components/AdminAccountingGLGenerator';
import GLJournalPage from 'pages/accounting/gljournal/GLJournalPage';

// Student Database

import StudentDataPage from 'pages/student/studentList/StudentDataPage';

import EDCJournalPage from 'components/EDCJournal/EDCJournalPage';
import AttendeesPage from 'pages/programs/attendees/AttendeesPage';
// import VideoAccess from 'pages/student/videoAccess/VideoAccess';
// import LivestreamAccess from 'pages/student/livestreamAccess/LivestreamAccess';
// import Forum from 'pages/forum';
// import ForumNew from 'pages/forumNew';

// Digital signature
// import DigitalSignaturePage from 'pages/digitalSignature';

// OnlineCourse
import OnlineCourseRouter from 'pages/online-course';
import LivestreamRouter from 'pages/livestream';
// import MentoringSessions from 'pages/mentoring-session';
import LivestreamMentoringSessions from 'pages/livestream-mentoring-session';
// import ProgramLivestreamBatchPage from 'pages/livestream-batch';
// import ExamResultRouter from 'pages/student/examResult';

// User menu
import UserMenuRouter from 'pages/user-menu';

const AdminPage = (props) => {
  const auth = useSelector((state) => state.auth);
  const { match } = props;
  const { path } = match;

  if (!auth.tokenChecked) {
    return (
      <div className="row h-100">
        <div className="col h-100">
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <Loader type="ThreeDots" color="#53b59f" height={40} width={40} />
          </div>
        </div>
      </div>
    );
  }

  if (auth.isAdmin) {
    return (
      <div>
        <SideBar />
        <div
          style={{ minHeight: '95vh', backgroundColor: '#e3e3e3' }}
          id="db-body"
        >
          <Route
            path={`${path}/purchase-order`}
            component={AdminFinancePurchaseOrderNew}
          />
          <Route
            path={`${path}/payment`}
            component={AdminFinanceManagePayment}
          />
          <Route path={`${path}/efaktur`} component={EFakturPage} />
          <Route
            path={`${path}/master-tujuan-pengeluaran`}
            component={AdminFinanceMasterTujuanPengeluaran}
          />
          <Route
            path={`${path}/master-tujuan-penerimaan`}
            component={AdminFinanceMasterTujuanPenerimaan}
          />
          <Route path={`${path}/edc-journal`} component={EDCJournalPage} />
          <Route
            path={`${path}/general-ledger-generator`}
            component={AdminAccountingGLGenerator}
          />
          <Route
            path={`${path}/general-ledger-journal`}
            component={GLJournalPage}
          />
          <Route
            path={`${path}/accounting-posting`}
            component={AdminAccountingPosting}
          />
          <Route path={`${path}/topics`} component={AdminTopics} />
          <Route
            path={`${path}/enquiries-reports`}
            component={AdminReportsEnquiries}
          />
          <Route path={`${path}/sales-reports`} component={SalesReportPage} />
          <Route
            path={`${path}/program-sales-reports`}
            component={ProgramSalesReportPage}
          />
          <Route path={`${path}/bank-reports`} component={BankReportPage} />
          <Route
            path={`${path}/finance-reports`}
            component={FinanceReportPage}
          />
          <Route path={`${path}/vouchers`} component={VouchersPage} />
          <Route path={`${path}/referral-code`} component={ReferralCodePage} />
          <Route
            path={`${path}/scholarship-manage`}
            component={AdminScholarships}
          />
          <Route
            path={`${path}/scholarship-create`}
            component={AdminScholarshipCreate}
          />
          <Route
            path={`${path}/scholarship-edit`}
            component={AdminScholarshipEdit}
          />
          <Route
            path={`${path}/scholarship-copy`}
            component={AdminScholarshipEdit}
          />
          <Route path={`${path}/transactions`} component={TransactionPage} />
          <Route
            path={`${path}/transaction-details`}
            component={TransactionDetails}
          />
          <Route
            path={`${path}/program-headers`}
            component={ProgramHeaderForm}
          />
          <Route
            path={`${path}/create-transaction`}
            component={CreateTransactionPage}
          />
          <Route path={`${path}/promo`} component={CreatePromoPage} />
          <Route path={`${path}/programs`} component={ProgramPage} />
          <Route path={`${path}/program-details`} component={ProgramDetails} />
          <Route path={`${path}/schedule-form`} component={ScheduleForm} />
          <Route
            path={`${path}/create-program`}
            component={ProgramHeaderForm}
          />
          <Route path={`${path}/student-list`} component={StudentDataPage} />
          <Route
            path={`${path}/student-report`}
            component={StudentReportPage}
          />
          <Route
            path={`${path}/student-birthday`}
            component={StudentBirthdayPage}
          />
          {/* <Route path={`${path}/video-access`} component={VideoAccess} /> */}
          {/* <Route
            path={`${path}/livestream-access`}
            component={LivestreamAccess}
          /> */}
          <Route path={`${path}/attendees`} component={AttendeesPage} />
          {/* <Route path={`${path}/forum`} component={Forum} /> */}
          {/* <Route path={`${path}/v2/forum`} component={ForumNew} /> */}
          {/* <Route path={`${path}/exam-schedule`} component={ExamSchedule} /> */}
          <Route
            path={`${path}/online-course`}
            component={OnlineCourseRouter}
          />
          <Route path={`${path}/livestream`} component={LivestreamRouter} />

          {/* <Route
            path={`${path}/mentoring-sessions`}
            component={MentoringSessions}
          /> */}
          <Route
            path={`${path}/livestream-mentoring-sessions`}
            component={LivestreamMentoringSessions}
          />
          <Route path={`${path}/user-menu`} component={UserMenuRouter} />

          {/* <Route
            path={`${path}/digital-sign`}
            component={DigitalSignaturePage}
          /> */}
          {/* <Route
            path={`${path}/livestream-schedule`}
            component={ProgramLivestreamBatchPage}
          /> */}
          {/* <Route path={`${path}/batch-exam`} component={ExamResultRouter} /> */}
        </div>
      </div>
    );
  }
  return <Redirect to="/" />;
};

export default AdminPage;
