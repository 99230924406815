import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCategoryCheck,
  fetchProgramCategories,
} from 'redux/modules/trxSearch';
import { CustomInput } from 'reactstrap';

const CategoryCheckbox = () => {
  const dispatch = useDispatch();
  const searchCategories = useSelector(
    (state) => state.trxSearch.searchCategories,
  );

  useEffect(() => {
    if (searchCategories.length === 0) {
      dispatch(fetchProgramCategories());
    }
  }, [dispatch, searchCategories.length]);

  const handleCheck = (e) => {
    dispatch(setCategoryCheck(e.target.dataset.categoryid));
  };

  let arrJSX = [];
  arrJSX = searchCategories.map((item) => {
    return (
      <CustomInput
        type="checkbox"
        id={`checkbox-category-${item.name}`}
        data-categoryid={item.id}
        key={`checkbox-category-${item.name}`}
        label={item.name}
        inline
        checked={item.checked}
        onChange={handleCheck}
      />
    );
  });

  return (
    <div className="mb-2">
      <div
        className="font-weight-bold"
        style={{ fontSize: '18px', color: '#505050' }}
      >
        Categories
      </div>
      <div>{arrJSX}</div>
    </div>
  );
};

export default CategoryCheckbox;
