import React, { Component } from 'react';
import axios from 'supports/api';
import { Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Loader from 'react-loader-spinner';

class AdminAccountingGLGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGL: '',
      date: new Date(),
      isLoading: false,
    };
  }

  handleGL = (value) => {
    this.setState({
      selectedGL: value,
    });
  };

  handleDate = (dt) => {
    console.log(dt);
    this.setState({
      date: dt,
    });
  };

  handleGenerate = () => {
    if (this.state.date) {
      if (
        window.confirm(
          `Apakah anda yakin ingin menciptakan GL ${
            this.state.selectedGL
          } ${moment(this.state.date).format('MMM YYYY')} ?`,
        )
      ) {
        this.setState({
          isLoading: true,
        });

        const options = {
          params: {
            filterDate: this.state.date,
          },
        };

        const data = {
          year: this.state.date.getFullYear(),
          month: this.state.date.getMonth() + 1,
        };

        setTimeout(() => {
          axios
            .post(`/admin/generate-gl/${this.state.selectedGL}`, data, options)
            .then((res) => {
              alert(res.data.message);
              console.log(res);
              this.setState({
                isLoading: false,
              });
            })
            .catch((err) => {
              console.log(err);
              alert(`Terjadi kesalahan pada system. ${err}`);
              this.setState({
                isLoading: false,
              });
            });
        }, 400); // Loads for a minimal of 0.4s
      }
    } else {
      alert('bulan belum dipilih');
    }
  };

  GenerateButton = ({ isLoading, onClick, disabled }) => {
    if (isLoading)
      return (
        <Button color="secondary" className="rounded-right" disabled>
          <Loader type="Oval" color="white" height={18} width="100%" />
        </Button>
      );
    return (
      <Button
        color="success"
        className="rounded-right"
        onClick={onClick}
        style={{ backgroundColor: '#53b59f' }}
        disabled={disabled}
      >
        Generate
      </Button>
    );
  };

  SearchBar = () => {
    return (
      <div className="container-fluid d-flex flex-column">
        <div className="d-flex flex-column flex-md-row w-100 justify-content-between">
          <div className="mb-3 d-flex flex-column w-100">
            <div className="mb-1">Journal Type</div>
            <select
              className="form-control"
              onChange={(e) => this.handleGL(e.target.value)}
              value={this.state.selectedGL}
            >
              <option value="" disabled hidden>
                Pilih GL
              </option>
              <option value="penjualan">Penjualan</option>
              <option value="pengeluaran">Pengeluaran</option>
              <option value="edc-journal">EDC Journal</option>
              <option value="penerimaan">Penerimaan</option>
            </select>
          </div>
          <div className="mb-3 d-flex flex-column w-100">
            <div className="mb-1">Month & Year</div>
            <DatePicker
              selected={this.state.date}
              onChange={this.handleDate}
              dateFormat="MMM yyyy"
              showMonthYearPicker
              className="form-control"
            />
          </div>
        </div>
        <this.GenerateButton
          disabled={!this.state.selectedGL}
          onClick={this.handleGenerate}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="container my-4">
        <div className="white-box py-5 px-md-5 px-2">
          <div className="container-fluid mb-4">
            <div className="line-green mb-4" />
            <div className="general-title text-gray">GL Generator</div>
          </div>
          <this.SearchBar />
        </div>
      </div>
    );
  }
}

export default AdminAccountingGLGenerator;
