import React from 'react';
import { Modal, ModalBody, Button, Spinner, Alert } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'supports/api';

const OnlineCourseModuleFormModal = ({
  isOpen,
  toggle,
  onlineCourseId,
  selectedCourseModule,
  lastOrderNumber,
  refresh,
}) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Wajib diisi'),
    order: Yup.string().required('Wajib diisi'),
  });

  const initialValues = {
    title: selectedCourseModule?.title,
    order: selectedCourseModule?.order,
  };

  const onSubmit = async (values) => {
    try {
      if (selectedCourseModule?.id) {
        await axios.put(
          `/v2/online-course/${onlineCourseId}/module/${selectedCourseModule?.id}`,
          {
            title: values.title.trim(),
            order: values.order,
          },
        );
      } else {
        await axios.post(`/v2/online-course/${onlineCourseId}/module`, {
          title: values.title.trim(),
          order: lastOrderNumber + 1,
        });
      }

      refresh();
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="d-flex flex-column">
                <label htmlFor="title" className="font-weight-bold">
                  Judul Module
                </label>
                <Field
                  name="title"
                  placeholder="Input judul disini"
                  className="form-control mb-2"
                />
                <ErrorMessage
                  name="title"
                  render={(msg) => <Alert color="danger">{msg}</Alert>}
                />
                <label htmlFor="order" className="font-weight-bold">
                  Order Module
                </label>
                <Field
                  name="order"
                  placeholder="Input order disini"
                  className="form-control mb-2"
                />
                <ErrorMessage
                  name="order"
                  render={(msg) => <Alert color="danger">{msg}</Alert>}
                />
                <Button
                  type="submit"
                  color="success"
                  style={{ backgroundColor: '#32b280' }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <Spinner /> : 'Submit'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default OnlineCourseModuleFormModal;
