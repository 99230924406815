import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import axios from 'supports/api';

const validate = ({ title, description }) => {
  const errors = {};

  if (!title) {
    errors.title = 'Required';
  }
  if (!description) {
    errors.description = 'Required';
  }

  return errors;
};

const LivestreamModal = ({
  toggle,
  isModalOpen,
  selectedLivestream,
  refresh,
}) => {
  const { id, title, description } = selectedLivestream;

  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableInput, setDisableInput] = useState(false);

  useEffect(() => {
    // for determining isEditMode
    if (id) {
      setIsEditMode(true);
      setDisableInput(true);
    } else {
      setIsEditMode(false);
      setDisableInput(false);
    }
  }, [id]);

  const createLivestream = async (formData) => {
    setLoading(true);
    try {
      await axios.post('/v2/program-livestream', formData);
      setLoading(false);
      alert('Livestream Berhasil Dibuat');
    } catch (err) {
      alert(`Terjadi kesalahan pada system. ${err}`);
      console.log(err);
      setLoading(false);
    }
  };

  const editLivestream = async (formData) => {
    setLoading(true);
    try {
      await axios.put(`/v2/program-livestream/${id}`, formData);
      setLoading(false);
      alert('Livestream Berhasil Diubah');
    } catch (err) {
      alert(`Terjadi kesalahan pada system. ${err}`);
      console.log(err);
      setLoading(false);
    }
  };

  const enableInput = () => {
    setDisableInput(false);
  };

  return (
    <Modal isOpen={isModalOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {isEditMode && !disableInput && 'Edit'}
        {!isEditMode && !disableInput && 'Create'} Livestream{' '}
        {disableInput && 'Details'}
      </ModalHeader>
      <ModalBody>
        <div className="container-fluid">
          <Formik
            initialValues={{
              title: title ?? '',
              description: description ?? '',
            }}
            validate={validate}
            onSubmit={async (values) => {
              if (isEditMode) {
                await editLivestream(values);
              } else {
                await createLivestream(values);
              }
              await refresh();
              toggle();
            }}
          >
            {({ touched, errors }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Livestream Title:
                      </div>
                      <Field id="title" name="title">
                        {({ field }) => (
                          <div>
                            <input
                              className="form-control"
                              placeholder="Judul JC Livestream"
                              type="text"
                              disabled={disableInput}
                              {...field}
                            />
                          </div>
                        )}
                      </Field>
                      {touched.title && errors.title && (
                        <div>{errors.title}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3">
                      <div className="tag-gray dker font-weight-bold mb-2">
                        Description:
                      </div>
                      <Field id="description" name="description">
                        {({ field }) => (
                          <div>
                            <input
                              className="form-control"
                              placeholder="Deskripsi JC Livestream"
                              type="text"
                              disabled={disableInput}
                              {...field}
                            />
                          </div>
                        )}
                      </Field>
                      {touched.description && errors.description && (
                        <div>{errors.description}</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 py-3 d-flex justify-content-end">
                      {isEditMode && disableInput && (
                        <Button
                          type="button"
                          className="mr-2"
                          color="primary"
                          onClick={enableInput}
                        >
                          Edit
                        </Button>
                      )}
                      {!disableInput && (
                        <Button
                          disabled={loading}
                          className="mr-2"
                          color="success"
                          type="submit"
                        >
                          {loading && (
                            <div
                              className="spinner-border spinner-border-sm mr-2 text-center"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          {isEditMode ? 'Save' : 'Create'}
                        </Button>
                      )}
                      <Button type="button" color="danger" onClick={toggle}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LivestreamModal;
