import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { signIn } from 'redux/modules/auth';
import { parseJWTToken } from 'supports/helpers/jwt';
import SignInError from './SignInError';
import SignInButton from './SignInButton';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';
import './sign-in.css';

export default () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onSignInClick = useCallback(() => {
    return dispatch(signIn({ email, password }));
  }, [dispatch, email, password]);

  // Listens for Enter
  useEffect(() => {
    const checkEnter = (e) => {
      e.stopPropagation();
      if (e.key === 'Enter') {
        onSignInClick();
      }
    };

    window.addEventListener('keydown', checkEnter, false);
    return () => window.removeEventListener('keydown', checkEnter, false);
  }, [onSignInClick]);

  const RedirectPage = () => {
    const { search } = useLocation();

    const params = new URLSearchParams(search);
    const cont = params.get('cont');
    if (cont !== null) {
      let redirect = '';
      parseJWTToken(cont, (value) => {
        redirect = value.redirect;
      });
      return <Redirect to={redirect} />;
    }
    return <Redirect to="/admin" />;
  };

  if (!auth.email) {
    return (
      <div className="container">
        <div className="mx-auto mt-5 p-5 sign-in-card shadow">
          <div className="d-flex flex-column justify-content-center">
            <div className="general-title my-5 text-center font-weight-bolder">
              Purwadhika Admin
            </div>
            <SignInError message={auth.loginError} />
            <EmailInput
              className="mb-3"
              onChange={(e) => setEmail(e.target.value)}
            />
            <PasswordInput
              className="mb-3"
              onChange={(e) => setPassword(e.target.value)}
            />
            <SignInButton onClick={onSignInClick} loggingIn={auth.loggingIn} />
          </div>
        </div>
      </div>
    );
  }
  return <RedirectPage />;
};
