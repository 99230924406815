import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  fetchPrintPayments,
  setPaymentReceipt,
  setTujuanPenerimaan,
  setNamaPembayar,
} from 'redux/modules/payment';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Collapse,
  Input,
  Button,
} from 'reactstrap';

const FilterPrint = ({
  page,
  active,
  finalSearch,
  setFinalSearch,
  tujPenerimaan,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const paymentReceipt = useSelector(({ payment }) => payment.paymentReceipt);
  const tujuanPenerimaan = useSelector(
    ({ payment }) => payment.tujuanPenerimaan,
  );
  const namaPembayar = useSelector(({ payment }) => payment.namaPembayar);

  const toggleCollapse = () => setIsOpen(!isOpen);

  const renderTujuanPenerimaan = () => {
    return tujPenerimaan.map((val) => {
      return (
        <option key={val} value={val.id}>
          {val.name}
        </option>
      );
    });
  };

  const handleChange = (e) => {
    setFinalSearch({ ...finalSearch, paymentReceipt: e.currentTarget.value });
  };

  const handleNamaPembayar = (e) => {
    setFinalSearch({ ...finalSearch, namaPembayar: e.currentTarget.value });
  };

  const handleSelect = (e) => {
    setFinalSearch({ ...finalSearch, tujuanPenerimaan: e.currentTarget.value });
  };

  const handleClick = () => {
    dispatch(setPaymentReceipt(finalSearch.paymentReceipt));
    dispatch(setTujuanPenerimaan(finalSearch.tujuanPenerimaan));
    dispatch(setNamaPembayar(finalSearch.namaPembayar));

    let isPrinted;
    if (active === 'Print') {
      isPrinted = 0;
    } else {
      isPrinted = 1;
    }
    dispatch(
      fetchPrintPayments(
        page,
        isPrinted,
        paymentReceipt,
        tujuanPenerimaan,
        namaPembayar,
      ),
    );
  };

  return (
    <>
      <div className={isOpen ? 'dropup' : null}>
        <button
          type="button"
          className="btn btn-secondary mt-2 dropdown-toggle btn-collapse-pym"
          onClick={toggleCollapse}
        >
          Filter
        </button>
      </div>
      <Collapse isOpen={isOpen}>
        <Card className="mt-1">
          <CardBody>
            <Form>
              <Row form>
                <Col sm={6} md={6}>
                  <FormGroup row>
                    <Label sm={4}>Payment Receipt</Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        onChange={(e) => handleChange(e)}
                        value={finalSearch.paymentReceipt}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={6} md={6}>
                  <FormGroup row>
                    <Label sm={3}>Receive From</Label>
                    <Col sm={7}>
                      <Input
                        type="text"
                        onChange={(e) => handleNamaPembayar(e)}
                        value={finalSearch.namaPembayar}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col sm={6} md={6}>
                  <FormGroup row>
                    <Label sm={4}>Tujuan Penerimaan</Label>
                    <Col sm={7}>
                      <Input
                        type="select"
                        onChange={(e) => handleSelect(e)}
                        value={finalSearch.tujuanPenerimaan}
                      >
                        <option value="">All</option>
                        {renderTujuanPenerimaan()}
                      </Input>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
          <div className="d-flex flex-row-reverse">
            <Button
              color="success"
              className="mt-0"
              style={{
                position: 'absolute',
                backgroundColor: 'rgb(50, 178, 128)',
                width: '20%',
              }}
              onClick={handleClick}
            >
              Search <FontAwesomeIcon icon={faSearch} className="ml-2" />
            </Button>
          </div>
        </Card>
      </Collapse>
    </>
  );
};

export default FilterPrint;
