import React from 'react';
import Skeleton from 'react-loading-skeleton';

const TransactionCardSkeleton = () => {
  return (
    <tr>
      <td>
        <Skeleton />
        <div
          className="mt-1 main-text text-center"
          style={{ fontSize: '12px' }}
        >
          <Skeleton />
        </div>
      </td>
      <td>
        <div className="main-text">
          <Skeleton />
        </div>
        <div className="sub-text">
          <Skeleton />
        </div>
      </td>
      <td>
        <div className="main-text">
          <Skeleton />
        </div>
        <div className="sub-text">
          <Skeleton />
        </div>
      </td>
      <td>
        <div className="main-text">
          <Skeleton />
        </div>
        <div className="sub-text">
          <Skeleton />
        </div>
        <div className="sub-text">
          <Skeleton />
        </div>
      </td>
      <td>
        <div className="main-text">
          <Skeleton />
        </div>
      </td>
      <td>
        <div className="main-text">
          <Skeleton />
        </div>
      </td>
      <td>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
        <Skeleton />
      </td>
    </tr>
  );
};

export default TransactionCardSkeleton;
