// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchParams: '',
  searchStatuses: [],
  searchCategories: [],
  searchDate: new Date(),
};

const { actions, reducer } = createSlice({
  name: 'trxSearch',
  initialState,
  reducers: {
    setSearchParams: (state, action) => {
      state.searchParams = action.payload;
    },
    setSearchStatuses: (state, action) => {
      state.searchStatuses = action.payload;
    },
    setSearchCategories: (state, action) => {
      state.searchCategories = action.payload;
    },
    setStatusCheck: (state, action) => {
      // action.payload id is a string
      // item.id is a number
      const updatedStatuses = state.searchStatuses.map((item) =>
        item.id === parseInt(action.payload, 10)
          ? { ...item, checked: !item.checked }
          : item,
      );
      state.searchStatuses = updatedStatuses;
    },
    setCategoryCheck: (state, action) => {
      // action.payload id is a string
      // item.id is a number
      const updatedCategories = state.searchCategories.map((item) =>
        item.id === parseInt(action.payload, 10)
          ? { ...item, checked: !item.checked }
          : item,
      );
      state.searchCategories = updatedCategories;
    },
    setSearchDate: (state, action) => {
      state.searchDate = action.payload;
    },
  },
});

export default reducer;

export const {
  setSearchParams,
  setSearchStatuses,
  setSearchCategories,
  setStatusCheck,
  setCategoryCheck,
  setSearchDate,
} = actions;

export const fetchTransactionStatuses = () => (dispatch) => {
  axios
    .get(`/transaction/statuses`)
    .then(({ data }) => {
      const statusWithChecked = data.result.map((item) => {
        return { ...item, checked: false };
      });
      dispatch(setSearchStatuses(statusWithChecked));
    })
    .catch((err) => {
      alert(err);
      console.log(err);
    });
};

export const fetchProgramCategories = () => (dispatch) => {
  axios
    .get(`/admin/program/categories`)
    .then(({ data }) => {
      const statusWithChecked = data.result.map((item) => {
        return { ...item, checked: false };
      });
      dispatch(setSearchCategories(statusWithChecked));
    })
    .catch((err) => {
      alert(err);
      console.log(err);
    });
};
