import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Modal, ModalBody, ModalHeader, Button, Spinner } from 'reactstrap';
import { fetchPO } from 'redux/modules/purchaseOrder';
import axios from 'supports/api';

const ModalUploadPOProof = ({ isOpen, toggle, activeId }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get('page');

  const dispatch = useDispatch();

  const searchPoNumber = useSelector((state) => state.poSearch.poNumber);
  const searchTujPengeluaran = useSelector(
    (state) => state.poSearch.tujuanPengeluaran,
  );
  const searchCreatedBy = useSelector((state) => state.poSearch.createdBy);
  const searchPoDateFrom = useSelector((state) => state.poSearch.poDateFrom);
  const searchPoDateTo = useSelector((state) => state.poSearch.poDateTo);
  const searchRemarks = useSelector((state) => state.poSearch.remarks);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const closeModal = () => {
    toggle();
    setSelectedImage(null);
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handlePaste = async (event) => {
    const url = event.clipboardData.getData('text');

    // get image from url
    if (typeof url === 'string' && url.length) {
      try {
        const res = await fetch(url);
        const type = res.headers.get('content-type');
        const blob = await res.blob();
        const filename = new URL(url).pathname.split('/').pop();
        const file = new File([blob], filename, { type });

        setSelectedImage(file);
      } catch (error) {
        const message = error.message || 'Gagal untuk mendapatkan gambar.';
        alert(message);
      }
    }

    // get image from copy image
    if (event.clipboardData.files[0]?.type?.includes('image/')) {
      setSelectedImage(event.clipboardData.files[0]);
    }
  };

  const uploadImage = async (id) => {
    try {
      if (!selectedImage) {
        alert('No image selected');
        return;
      }

      setUploadLoading(true);

      const formData = new FormData();
      formData.append('proof', selectedImage);

      await axios.put(`/v2/poHeader/${id}/upload`, formData);

      dispatch(
        fetchPO(
          page,
          true,
          false,
          true,
          false,
          searchPoNumber,
          searchTujPengeluaran,
          searchCreatedBy,
          searchRemarks,
          searchPoDateFrom,
          searchPoDateTo,
        ),
      );

      closeModal();

      alert('Upload PO Proof Sukses.');
    } catch (error) {
      console.log(error);
    } finally {
      setUploadLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalHeader>Upload Purchase Order Proof</ModalHeader>
      <ModalBody>
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
          onPasteCapture={handlePaste}
        >
          <div style={{ marginBottom: '8px' }} onPaste>
            {selectedImage ? (
              <img
                alt="not found"
                style={{ width: '100%' }}
                src={URL.createObjectURL(selectedImage)}
              />
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '256px',
                  backgroundColor: 'lightgrey',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>No Image</p>
                <p>Paste image or url here!</p>
              </div>
            )}
          </div>

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={(event) => {
              setSelectedImage(event.target.files[0]);
            }}
            style={{ display: 'none' }}
          />

          <div style={{ marginBottom: '32px' }}>
            <Button size="sm" color="info" onClick={handleClick}>
              Choose File
            </Button>
          </div>

          <div style={{ alignSelf: 'flex-end', display: 'flex', gap: '8px' }}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              onClick={() => uploadImage(activeId)}
              color="success"
              disabled={uploadLoading}
            >
              {uploadLoading ? <Spinner color="white" size="sm" /> : 'Upload'}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalUploadPOProof;
