import React from 'react';
import { Label, Input, FormText, FormGroup, FormFeedback } from 'reactstrap';

const InputTextComponent = ({
  label,
  placeholder,
  valid,
  text,
  invalid,
  success,
  error,
  onInput,
  onFocus,
  help,
  className,
  type,
  disabled,
}) => {
  return (
    <FormGroup className={className}>
      <Label for={`form${label}`} className="text-gray font-weight-bold">
        {label}
      </Label>
      <Input
        type={type || 'text'}
        valid={valid}
        invalid={invalid}
        placeholder={placeholder}
        value={text}
        id={`form${label}`}
        onChange={({ target }) => onInput(target.value)}
        onFocus={onFocus}
        disabled={disabled}
      />
      <FormFeedback valid>{success}</FormFeedback>
      <FormFeedback invalid="true">{error}</FormFeedback>
      <FormText>{help}</FormText>
    </FormGroup>
  );
};

export default InputTextComponent;
