import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ onChange, value, ...props }) => {
  return (
    <InputGroup {...props}>
      <Input placeholder="email/username" onChange={onChange} value={value} />
      <InputGroupAddon addonType="append">
        <InputGroupText style={{ width: '42px' }}>
          <FontAwesomeIcon icon={['fas', 'envelope']} color="#53b59f" />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};
