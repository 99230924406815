import React, { useEffect } from 'react';
import { Input } from 'reactstrap';

import { useDispatch } from 'react-redux';
import { filterMinimalTransactions } from 'redux/modules/transactions';
import { useHistory } from 'react-router';

const SearchBox = (props) => {
  const {
    setSearchInput,
    searchInput,
    setSelectedTrx,
    setSelectedSales,
  } = props;

  const history = useHistory();
  // const [debounceSearch] = useState(() =>
  //   _.debounce((e) => console.log(e), 1000),
  // );

  // const handleChange = (e) => {
  //   debounceSearch(e.target.value);
  // };

  const dispatch = useDispatch();

  const handleInput = (e) => {
    const { name, value } = e.target;
    setSelectedSales({});
    setSelectedTrx({});
    setSearchInput({ ...searchInput, [name]: value });
    history.push({ search: '?page=1' });
  };

  useEffect(() => {
    dispatch(filterMinimalTransactions(searchInput));
  }, [dispatch, searchInput]);

  return (
    <div className="d-flex">
      <div className="col-6">
        <div
          className="font-weight-bold "
          style={{ fontSize: '18px', color: '#505050' }}
        >
          Search By Order Confirmation Number
        </div>
        <Input
          value={searchInput.orderConfirmationNumber}
          onChange={handleInput}
          name="orderConfirmationNumber"
          placeholder="ON"
        />
      </div>
      <div className="col-6">
        <div
          className="font-weight-bold "
          style={{ fontSize: '18px', color: '#505050' }}
        >
          Search By Customer Name
        </div>
        <Input
          value={searchInput.name}
          onChange={handleInput}
          name="name"
          placeholder="Name"
        />
      </div>
    </div>
  );
};

export default SearchBox;
