import React from 'react';
import DatePicker from 'react-datepicker';
import PCard from 'pages/finance/transactions/main/PCard';

const DeadlinePicker = ({ selected, onChange }) => {
  return (
    <PCard className="mb-2 p-3">
      <div className="font-weight-bold mb-2">Deadline</div>
      <DatePicker
        className="form-control"
        selected={selected}
        onChange={onChange}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={1}
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
      />
    </PCard>
  );
};

export default DeadlinePicker;
