import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import moment from 'moment';
import axios from 'supports/api';
import DatePicker from 'react-datepicker';

const RenewModal = ({ isOpen = false, toggle, refresh, ...props }) => {
  const [newDeadline, setNewDeadline] = useState(moment().toDate());

  const onRenewClick = () => {
    const data = {
      deadline: newDeadline,
    };

    if (window.confirm('Are you sure?')) {
      axios
        .put(`/admin/renew-transaction/${props.id}`, data)
        .then(() => {
          alert('success');
          refresh();
          toggle();
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} {...props} size="sm">
      <ModalBody>
        <div className="mb-3">
          <div
            className="mb-2 font-weight-bold"
            style={{ fontSize: '24px', color: '#505050' }}
          >
            Pilih deadline baru:
          </div>
          <DatePicker
            className="form-control"
            selected={newDeadline}
            minDate={moment().toDate()}
            showDisabledMonthNavigation
            timeInputLabel="Time:"
            onChange={(date) => setNewDeadline(date)}
            dateFormat="dd/MM/yyyy h:mm aa"
            showTimeInput
          />
        </div>
        <div className="">
          <Button
            color="info"
            size="sm"
            className="mr-2"
            onClick={onRenewClick}
          >
            Renew
          </Button>
          <Button color="danger" size="sm" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RenewModal;
