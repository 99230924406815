import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  FormFeedback,
  Alert,
  Label,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import PCard from 'pages/finance/transactions/main/PCard';
import Loader from 'react-loader-spinner';
import { getUserData, setUserEmail, resetUser } from 'redux/modules/trxUser';
import ShowUserData from './user-data';
import CreateUserModal from '../modals/CreateUserModal';

const SearchButton = ({ onClick, isLoading, disabled }) => {
  if (isLoading)
    return (
      <Button
        color="secondary"
        className="rounded-right"
        disabled
        style={{ width: 80 }}
      >
        <Loader type="Oval" color="white" height={18} width="100%" />
      </Button>
    );
  return (
    <Button
      color="success"
      className="rounded-right"
      onClick={onClick}
      style={{ width: 80, backgroundColor: '#53b59f' }}
      disabled={disabled}
    >
      Search
    </Button>
  );
};

const VerifyUser = () => {
  const dispatch = useDispatch();
  const trxUser = useSelector((state) => state.trxUser);
  const { email, error, status } = trxUser;
  const [createUserModal, setCreateUserModal] = useState(false);

  useEffect(() => {
    const inputelement = document.getElementById('verifyAccount');
    if (email !== '') {
      inputelement.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
          dispatch(getUserData(email));
        }
      });

      return () =>
        inputelement.removeEventListener('keypress', (e) => {
          if (e.key === 'Enter') {
            dispatch(getUserData(email));
          }
        });
    }

    return null;
  }, [dispatch, email]);

  const buttonStyle = {
    background: 'none',
    border: 'none',
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: 0,
    fontSize: 'inherit',
  };

  return (
    <div>
      <PCard style={{ maxHeight: '400px' }} className="mb-2">
        <div className="d-flex flex-column p-3">
          <FormGroup className="m-0">
            <Label for="verifyAccount" className="text-gray font-weight-bold">
              Search Account
            </Label>
            <InputGroup>
              <Input
                valid={status === 'success'}
                invalid={status === 'failure'}
                placeholder="name@mail.com"
                value={email}
                id="verifyAccount"
                onChange={({ target }) => dispatch(setUserEmail(target.value))}
                onFocus={() => {
                  dispatch(resetUser());
                }}
              />
              <InputGroupAddon addonType="append">
                <SearchButton
                  disabled={!email}
                  onClick={() => {
                    dispatch(getUserData(email));
                  }}
                  isLoading={status === 'loading'}
                />
              </InputGroupAddon>
              <FormFeedback valid>
                <Alert color="success" className="m-0">
                  Email is valid
                </Alert>
              </FormFeedback>
              <FormFeedback invalid="true">
                {error?.includes('not found') ? (
                  <Alert color="danger" className="m-0">
                    {error} Click Here to{' '}
                    <button
                      type="button"
                      style={buttonStyle}
                      onClick={() => setCreateUserModal(true)}
                    >
                      create new user
                    </button>
                  </Alert>
                ) : (
                  <Alert color="danger" className="m-0">
                    {error}
                  </Alert>
                )}
              </FormFeedback>
            </InputGroup>
          </FormGroup>
        </div>
      </PCard>
      <PCard className="mb-2">
        <ShowUserData />
      </PCard>
      <CreateUserModal
        isOpen={createUserModal}
        toggle={() => setCreateUserModal(!createUserModal)}
      />
    </div>
  );
};

export default VerifyUser;
