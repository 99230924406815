import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import Loader from 'react-loader-spinner';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  fetchBankReport,
  filterBankReports,
  fetchBeginningBalance,
} from 'redux/modules/bankReport';
import '../style.css';
import Axios from 'supports/api';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import BankReportTable from './BankReportTable';

const date = new Date();

const BankReportPage = () => {
  const dispatch = useDispatch();

  const dataLength = useSelector((state) => state.bankReports.data.length);
  const data = useSelector((state) => state.bankReports.filteredData);
  const loading = useSelector((state) => state.bankReports.loading);
  const match = useSelector((state) => state.bankReports.match);
  const beginningBalance = useSelector(
    (state) => state.bankReports.beginningBalance,
  );

  const [isOpen, setIsOpen] = useState(true);
  const [fromDate, setFromDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [toDate, setToDate] = useState(date);
  const [banks, setBanks] = useState([]);
  const [bankId, setBankId] = useState(1);
  const [loadingBank, setLoadingBank] = useState(false);
  const [loadingBeginning, setLoadingBeginning] = useState(false);

  const handleFetch = () => {
    dispatch(fetchBankReport({ toDate, fromDate, bankId }));
  };

  useEffect(() => {
    const fetchingBanks = async () => {
      try {
        setLoadingBank(true);
        // fetching all banks for select input
        const res = await Axios.get(`/bank/all`);
        setBanks(res.data.result);
        setLoadingBank(false);
      } catch (err) {
        setLoadingBank(false);
        alert(`Terjadi kesalahan pada system. ${err}`);
      }
    };
    fetchingBanks();
  }, []);

  useEffect(() => {
    if (dataLength) {
      dispatch(filterBankReports({ bankId, toDate, fromDate }));
    }
  }, [dispatch, bankId, dataLength, toDate, fromDate]);

  useEffect(() => {
    // fetch beginning balance everytime fromDate changes
    const fetchingBeginningBalance = async () => {
      setLoadingBeginning(true);
      try {
        const res = await Axios.get(`/report/bankreport/beginning-balance`, {
          params: {
            fromDate,
          },
        });
        dispatch(fetchBeginningBalance(res.data.data));
        setLoadingBeginning(false);
      } catch (err) {
        setLoadingBeginning(false);
        alert(`Terjadi kesalahan pada system. ${err}`);
      }
    };
    fetchingBeginningBalance();
  }, [dispatch, fromDate]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="d-flex justify-content-center">
          <Loader type="ThreeDots" color="#53b59f" />
        </div>
      );
    }
    if (!match) {
      // if state.bankReport.match === false
      return (
        <div className="d-flex justify-content-center">
          <h3>No Data Found</h3>
        </div>
      );
    }
    if (data.length) {
      // finds the balance based on bankId
      const filteredBalance = beginningBalance.find((bankItem) => {
        return bankItem.bankId === Number(bankId);
      });
      return (
        <div className="mt-3 mx-4 p-0">
          <div className="text-center">
            <h3>Bank {banks.find((e) => e.id === Number(bankId))?.bankName}</h3>
          </div>
          <BankReportTable
            filteredBalance={filteredBalance}
            loadingBeginning={loadingBeginning}
            data={data}
            toDate={toDate}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <div className="mt-4">
        <div
          className="mx-4 p-2"
          style={{
            backgroundColor: '#f8f8f8',
            minHeight: '10vh',
          }}
        >
          <div className="line-green mb-2" />
          <h3>Bank Report</h3>
          <div className="mb-2">
            <div className="d-inline-block">
              <span>Date from</span>
              <DatePicker
                selected={fromDate}
                onChange={(pickedDate) => setFromDate(pickedDate)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div className="d-inline-block">
              <span>Date to</span>
              <DatePicker
                selected={toDate}
                onChange={(pickedDate) => setToDate(pickedDate)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <button
              type="button"
              className="btn btn-sm btn-secondary my-2 dropdown-toggle btn-collapse-pym"
              onClick={() => setIsOpen(!isOpen)}
            >
              Filter by
            </button>
          </div>
          <Collapse isOpen={isOpen}>
            <div className="mb-2">
              <div>
                <span>Bank</span>
                <div>
                  <select
                    value={bankId}
                    onChange={(e) => setBankId(e.target.value)}
                    style={{ width: '30%', lineHeight: 'inherit' }}
                  >
                    {banks.map((bank) => (
                      <option key={bank.id} value={bank.id}>
                        {bank.bankName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </Collapse>
          <div
            className="d-flex justify-content-center"
            style={{ width: '30%' }}
          >
            {match && dataLength ? (
              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="btn btn-sm mt-2 mr-5 btn-primary"
                table="table-to-xls"
                filename={`Bank Report ${
                  bankId
                    ? `${banks.find((e) => e.id === Number(bankId))?.bankName} `
                    : ''
                }${moment(fromDate).format('DD-MM-YYYY')} - ${moment(
                  toDate,
                ).format('DD-MM-YYYY')}`}
                sheet="tablexls"
                buttonText="Download as XLS"
              />
            ) : null}
            <button
              type="button"
              className="btn btn-success btn-sm mt-2"
              style={{
                backgroundColor: 'rgb(50, 178, 128)',
              }}
              onClick={handleFetch}
              disabled={loading || loadingBank}
            >
              {loading || loadingBank ? (
                <div
                  className="spinner-border text-light spinner-border-sm"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <>
                  <span>Search From Database</span>
                  <FontAwesomeIcon icon={faSearch} className="ml-2" />
                </>
              )}
            </button>
          </div>
        </div>
        <div className="d-flex">
          <div className="col-1" />
          <div className="col-10">{renderContent()}</div>
          <div className="col-1" />
        </div>
      </div>
    </div>
  );
};

export default BankReportPage;
