import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'supports/api';
import {
  setPoSearch,
  setTujuanPengeluaran,
  setCreatedBy,
  setBranchId,
} from 'redux/modules/poSearch';
import { Nav, TabContent, TabPane, NavLink, NavItem } from 'reactstrap';
import {
  fetchPOList,
  fetchPO,
  fetchPoHistory,
} from 'redux/modules/purchaseOrder';
import { useLocation } from 'react-router-dom';
import { checkTabs } from 'supports/helpers/permissions';
import { POListTab, PODataTab, HistoryPO } from './tabs';

const PurchaseOrderPage = (props) => {
  const location = useLocation();
  const date = new Date();
  const query = new URLSearchParams(location.search);
  const page = query.get('page');
  const auth = useSelector((state) => state.auth);
  const tabAccess = checkTabs(auth.access, [
    'PO List',
    'PO Data',
    'PO History',
    'PO Approval',
    'PO Pencairan',
    'PO Finalization',
    'Edit Mutation Date',
  ]);
  const [activeTab, setActiveTab] = useState(tabAccess[0]);
  const [active, setActive] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [readOnlyFromEditMutation, setReadOnlyFromEditMutation] = useState(
    false,
  );
  const [editMutation, setEditMutation] = useState(false);
  const [poDetailShow, setpoDetailShow] = useState(false);
  const [saveShow, setSaveShow] = useState(false);
  const [addToLiquid, setAddToLiquid] = useState(false);
  const [btnLiquidShow, setBtnLiquidShow] = useState(false);
  const [btnAddJournalShow, setBtnAddJournalShow] = useState(false);
  const [addToJournal, setAddToJournal] = useState(false);
  const [gBank, setGBank] = useState([]);

  const [search, setSearch] = useState({
    poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
    poDateTo: date,
    poNumber: '',
    tujuanPengeluaran: '',
    createdBy: '',
    remarks: '',
    branchId: '',
    status: '',
  });

  const [insertId, setInsertId] = useState([]);

  const [filterPOHistory, setFilterPoHistory] = useState({
    poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
    poDateTo: date,
    poNumber: '',
    username: '',
    tujuanPengeluaranId: '',
    remarks: '',
    branchId: '',
  });

  const [filter, setFilter] = useState({
    poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
    poDateTo: date,
    poNumber: '',
    username: '',
    tujuanPengeluaran: '',
    remarks: '',
    branchId: '',
  });

  const dispatch = useDispatch();
  const searchPoNumber = useSelector((state) => state.poSearch.poNumber);
  const searchTujPengeluaran = useSelector(
    (state) => state.poSearch.tujuanPengeluaran,
  );
  const searchCreatedBy = useSelector((state) => state.poSearch.createdBy);
  const searchPoDateFrom = useSelector((state) => state.poSearch.poDateFrom);
  const searchPoDateTo = useSelector((state) => state.poSearch.poDateTo);
  const searchRemarks = useSelector((state) => state.poSearch.remarks);
  const searchBranchId = useSelector((state) => state.poSearch.branchId);
  const searchStatus = useSelector((state) => state.poSearch.status);

  const toggleTab = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
      if (tab === 'PO Data') {
        props.history.replace(`?tab=${tab.toString()}`);
      } else if (tab === 'PO List') {
        props.history.replace(`?page=${1}&tab=${tab.toString()}`);
        dispatch(setPoSearch(''));
        dispatch(setTujuanPengeluaran(''));
        dispatch(setCreatedBy(''));
        dispatch(setBranchId(''));
        setSearch({
          poNumber: '',
          tujuanPengeluaran: '',
          createdBy: '',
          branchId: '',
          status: '',
        });
        setFilterPoHistory({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          branchId: '',
        });
        setFilter({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          branchId: '',
        });
        setSelectedId(null);
        setAddToLiquid(false);
        setBtnAddJournalShow(false);
        setEditMutation(false);
        setBtnLiquidShow(false);
        setAddToJournal(false);
        setInsertId([]);
      } else if (tab === 'PO History') {
        props.history.replace(`?page=${1}&tab=${tab.toString()}`);
        dispatch(setPoSearch(''));
        dispatch(setTujuanPengeluaran(''));
        dispatch(setCreatedBy(''));
        dispatch(setBranchId(''));
        setSearch({
          poNumber: '',
          tujuanPengeluaran: '',
          createdBy: '',
          branchId: '',
          status: '',
        });
        setSelectedId(null);
        setAddToLiquid(false);
        setBtnAddJournalShow(false);
        setEditMutation(false);
        setBtnLiquidShow(false);
        setAddToJournal(false);
        setInsertId([]);
      } else if (tab === 'PO Approval') {
        props.history.replace(`?page=${1}&tab=${tab.toString()}`);
        dispatch(setPoSearch(''));
        dispatch(setTujuanPengeluaran(''));
        dispatch(setCreatedBy(''));
        dispatch(setBranchId(''));
        setSearch({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          tujuanPengeluaran: '',
          createdBy: '',
          remarks: '',
          branchId: '',
          status: '',
        });
        setFilterPoHistory({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          branchId: '',
        });
        setFilter({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          branchId: '',
        });
        setSelectedId(null);
        setAddToLiquid(false);
        setBtnAddJournalShow(false);
        setEditMutation(false);
        setBtnLiquidShow(false);
        setAddToJournal(false);
        setInsertId([]);
      } else if (tab === 'PO Pencairan') {
        props.history.replace(`?page=${1}&tab=${tab.toString()}`);
        dispatch(setPoSearch(''));
        dispatch(setTujuanPengeluaran(''));
        dispatch(setBranchId(''));
        setSearch({
          poDateFrom: undefined,
          poDateTo: undefined,
          poNumber: '',
          tujuanPengeluaran: '',
          createdBy: '',
          remarks: '',
          branchId: '',
          status: '',
        });
        setInsertId([]);
        setFilterPoHistory({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          branchId: '',
        });
        setFilter({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          branchId: '',
        });
        dispatch(setCreatedBy(''));
        axios
          .get(`/bank/all`)
          .then((res) => setGBank(res.data.result))
          .catch((err) => alert(`Terjadi kesalahan system ${err}`));
        setSelectedId(null);
        setAddToLiquid(false);
        setBtnAddJournalShow(false);
        setEditMutation(false);
        setSelectedId(null);
        setAddToLiquid(false);
        setBtnAddJournalShow(false);
        setEditMutation(false);
        setBtnLiquidShow(false);
        setAddToJournal(false);
      } else if (tab === 'PO Finalization') {
        props.history.replace(`?page=${1}&tab=${tab.toString()}`);
        setInsertId([]);
        dispatch(setPoSearch(''));
        dispatch(setTujuanPengeluaran(''));
        dispatch(setCreatedBy(''));
        dispatch(setBranchId(''));
        setSearch({
          poNumber: '',
          tujuanPengeluaran: '',
          createdBy: '',
          branchId: '',
          status: '',
        });
        setFilterPoHistory({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          branchId: '',
        });
        setFilter({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          branchId: '',
        });
        axios
          .get(`/bank/all`)
          .then((res) => setGBank(res.data.result))
          .catch((err) => alert(`Terjadi kesalahan system ${err}`));
        setSelectedId(null);
        setAddToLiquid(false);
        setBtnAddJournalShow(false);
        setEditMutation(false);
        setBtnLiquidShow(false);
        setAddToJournal(false);
      } else if (tab === 'Edit Mutation Date') {
        props.history.replace(`?page=${1}&tab=${tab.toString()}`);
        setInsertId([]);
        dispatch(setPoSearch(''));
        dispatch(setTujuanPengeluaran(''));
        dispatch(setCreatedBy(''));
        dispatch(setBranchId(''));
        setSearch({
          poNumber: '',
          tujuanPengeluaran: '',
          createdBy: '',
          branchId: '',
          status: '',
        });
        setFilterPoHistory({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          branchId: '',
        });
        setFilter({
          poDateFrom: new Date(date.getFullYear(), date.getMonth(), 1),
          poDateTo: date,
          poNumber: '',
          branchId: '',
        });
        axios
          .get(`/bank/all`)
          .then((res) => setGBank(res.data.result))
          .catch((err) => alert(`Terjadi kesalahan system ${err}`));
        setSelectedId(null);
        setAddToLiquid(false);
        setBtnAddJournalShow(false);
        setEditMutation(false);
        setBtnLiquidShow(false);
        setAddToJournal(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, activeTab, props.history],
  );

  const handleClick = useCallback(
    (tab) => {
      toggleTab(tab);

      switch (tab) {
        case 'PO List':
          setActive(tab);
          break;
        case 'PO Data':
          setActive(tab);
          break;
        case 'PO History':
          setActive(tab);
          break;
        case 'PO Approval':
          setActive(tab);
          break;
        case 'PO Pencairan':
          setActive(tab);
          break;
        case 'PO Finalization':
          setActive(tab);
          break;
        case 'Edit Mutation Date':
          setActive(tab);
          break;
        default:
          setActive('');
          break;
      }
    },
    [toggleTab],
  );

  useEffect(() => {
    if (activeTab === 'PO List') {
      handleClick('PO List');
    }
  }, [activeTab, handleClick]);

  useEffect(() => {
    if (active === 'PO List') {
      dispatch(
        fetchPOList(
          page,
          false,
          false,
          false,
          false,
          searchPoNumber,
          searchTujPengeluaran,
          searchCreatedBy,
          searchBranchId,
          searchStatus,
        ),
      );
    } else if (active === 'PO History') {
      const {
        poDateFrom,
        poDateTo,
        poNumber,
        username,
        remarks,
        tujuanPengeluaranId,
        branchId,
      } = filterPOHistory;
      dispatch(
        fetchPoHistory(
          page,
          poDateFrom,
          poDateTo,
          poNumber,
          username,
          remarks,
          tujuanPengeluaranId,
          branchId,
        ),
      );
    } else if (active === 'PO Approval') {
      const { poDateFrom, poDateTo, remarks } = search;

      dispatch(
        fetchPO(
          page,
          true,
          false,
          false,
          false,
          searchPoNumber,
          searchTujPengeluaran,
          searchCreatedBy,
          remarks,
          poDateFrom,
          poDateTo,
        ),
      );
    } else if (active === 'PO Pencairan') {
      const { poDateFrom, poDateTo, remarks } = search;

      dispatch(
        fetchPO(
          page,
          true,
          false,
          true,
          false,
          searchPoNumber,
          searchTujPengeluaran,
          searchCreatedBy,
          remarks,
          poDateFrom,
          poDateTo,
        ),
      );
    } else if (active === 'PO Finalization') {
      dispatch(
        fetchPO(
          page,
          true,
          true,
          true,
          false,
          searchPoNumber,
          searchTujPengeluaran,
          searchCreatedBy,
        ),
      );
    } else if (active === 'Edit Mutation Date') {
      dispatch(
        fetchPO(
          page,
          true,
          true,
          true,
          true,
          searchPoNumber,
          searchTujPengeluaran,
          searchCreatedBy,
        ),
      );
    }
  }, [
    dispatch,
    active,
    page,
    searchPoNumber,
    searchTujPengeluaran,
    searchCreatedBy,
    filterPOHistory,
    searchRemarks,
    searchPoDateFrom,
    searchPoDateTo,
    searchBranchId,
    searchStatus,
  ]);

  const renderTabs = () => {
    return tabAccess.map((tab) => {
      return (
        <NavItem key={tab}>
          <NavLink active={activeTab === tab} onClick={() => handleClick(tab)}>
            {tab}
          </NavLink>
        </NavItem>
      );
    });
  };

  const containerTab = () => {
    return (
      <>
        <Nav tabs>{renderTabs()}</Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="PO List">
            <POListTab
              active={active}
              activeTab={activeTab}
              click={() => handleClick('PO Data')}
              setReadOnly={setReadOnly}
              setSelectedId={setSelectedId}
              setpoDetailShow={setpoDetailShow}
              setSaveShow={setSaveShow}
              page={page}
              setSearch={setSearch}
              search={search}
            />
          </TabPane>
          <TabPane tabId="PO Data">
            <PODataTab
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              readOnly={readOnly}
              setReadOnly={setReadOnly}
              setpoDetailShow={setpoDetailShow}
              poDetailShow={poDetailShow}
              saveShow={saveShow}
              setSaveShow={setSaveShow}
              addToLiquid={addToLiquid}
              setAddToLiquid={setAddToLiquid}
              btnAddJournalShow={btnAddJournalShow}
              setBtnAddJournalShow={setBtnAddJournalShow}
              gBank={gBank}
              setReadOnlyFromEditMutation={setReadOnlyFromEditMutation}
              readOnlyFromEditMutation={readOnlyFromEditMutation}
              setEditMutation={setEditMutation}
              editMutation={editMutation}
              setBtnLiquidShow={setBtnLiquidShow}
              btnLiquidShow={btnLiquidShow}
              setAddToJournal={setAddToJournal}
              addToJournal={addToJournal}
              insertId={insertId}
              setInsertId={setInsertId}
            />
          </TabPane>
          <TabPane tabId="PO History">
            <HistoryPO
              setFilterPoHistory={setFilterPoHistory}
              click={() => handleClick('PO Data')}
              setSelectedId={setSelectedId}
              setReadOnly={setReadOnly}
              setpoDetailShow={setpoDetailShow}
              filter={filter}
              setFilter={setFilter}
            />
          </TabPane>
          <TabPane tabId="PO Approval">
            <POListTab
              active={active}
              click={() => handleClick('PO Data')}
              setSelectedId={setSelectedId}
              setReadOnly={setReadOnly}
              setpoDetailShow={setpoDetailShow}
              page={page}
              setSearch={setSearch}
              search={search}
            />
          </TabPane>
          <TabPane tabId="PO Pencairan">
            <POListTab
              active={active}
              click={() => handleClick('PO Data')}
              setReadOnly={setReadOnly}
              setSelectedId={setSelectedId}
              setpoDetailShow={setpoDetailShow}
              setAddToLiquid={setAddToLiquid}
              page={page}
              setBtnLiquidShow={setBtnLiquidShow}
              setSearch={setSearch}
              search={search}
            />
          </TabPane>
          <TabPane tabId="PO Finalization">
            <POListTab
              active={active}
              click={() => handleClick('PO Data')}
              setSelectedId={setSelectedId}
              setAddToLiquid={setAddToLiquid}
              setReadOnly={setReadOnly}
              setpoDetailShow={setpoDetailShow}
              setBtnAddJournalShow={setBtnAddJournalShow}
              setReadOnlyFromEditMutation={setReadOnlyFromEditMutation}
              page={page}
              setAddToJournal={setAddToJournal}
              setSearch={setSearch}
              search={search}
            />
          </TabPane>
          <TabPane tabId="Edit Mutation Date">
            <POListTab
              active={active}
              click={() => handleClick('PO Data')}
              setSelectedId={setSelectedId}
              setReadOnly={setReadOnly}
              setpoDetailShow={setpoDetailShow}
              setAddToLiquid={setAddToLiquid}
              setReadOnlyFromEditMutation={setReadOnlyFromEditMutation}
              setEditMutation={setEditMutation}
              page={page}
              setSearch={setSearch}
              search={search}
            />
          </TabPane>
        </TabContent>
      </>
    );
  };

  return (
    <div className="white-box py-2 container-fluid">
      <div className="row py-4">
        <div className="col-12 offset-md-1 col-md-10 py-3">
          <div>
            <div className="line-green mb-2" />
            <div className="general-title text-gray">Purchase Order</div>
          </div>
        </div>
        <div className="col-12 offset-md-1 col-md-10 py-3">
          {containerTab()}
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderPage;
