import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, FormGroup, Input, Form } from 'reactstrap';
import CustomPagination from 'components/commons/CustomPagination';
import { useLocation } from 'react-router';
import Loader from 'react-loader-spinner';
import axios from 'supports/api';
import { Link } from 'react-router-dom';
import BackButton from './components/BackButton';

const StudentList = (props) => {
  const { match } = props;
  const { programId } = match.params;
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get('page') || 1;
  const itemsPerPage = 10;
  const [searchKeyword, setSearchKeyword] = useState('');
  const [checkMentoring, setCheckMentoring] = useState('');

  const [listStudent, setListStudent] = useState([]);
  const [studentFilter, setStudentFilter] = useState([]);
  const fetchListStudent = useCallback(async () => {
    try {
      const students = await axios.get(
        `/v2/attendee/mentoring?programId=${programId}`,
      );
      setListStudent(students.data.result);
      setStudentFilter(students.data.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [programId]);

  useEffect(() => {
    fetchListStudent();
  }, [fetchListStudent]);

  const inputSearchKeyword = (text) => {
    setSearchKeyword(text);
  };

  const fetchListStudentFiltered = useCallback(() => {
    const studentData = listStudent.filter((student) => {
      return (
        student.attendeeName
          .toLowerCase()
          .includes(searchKeyword.toLowerCase()) ||
        student.attendeeEmail
          .toLowerCase()
          .includes(searchKeyword.toLowerCase())
      );
    });
    setStudentFilter(studentData);
  }, [listStudent, searchKeyword]);

  const fetchListStudentFilteredMentoring = useCallback(() => {
    const studentData = listStudent.filter((student) => {
      return checkMentoring
        ? student.ProgramMentoringSessions.length === 0
        : true;
    });

    setStudentFilter(studentData);
  }, [listStudent, checkMentoring]);

  useEffect(() => {
    fetchListStudentFiltered();
  }, [fetchListStudentFiltered]);

  useEffect(() => {
    fetchListStudentFilteredMentoring();
  }, [fetchListStudentFilteredMentoring]);

  const RenderTable = () => {
    const slicedStudentList = studentFilter.slice(
      (page - 1) * itemsPerPage,
      (page - 1) * itemsPerPage + itemsPerPage,
    );

    return slicedStudentList.map((student, index) => {
      return (
        <tr className="d-flex" key={student.id}>
          <td className="col-1">{(page - 1) * itemsPerPage + index + 1}</td>
          <td className="col-4">{student.attendeeName}</td>
          <td className="col-4">{student.attendeeEmail}</td>
          <td className="col-2">{student.ProgramMentoringSessions.length}</td>
          <td className="col-1 forum-link">
            {student.id && (
              <Button color="info" size="sm">
                <Link
                  to={`student/${student.id}/sessions?email=${student.attendeeEmail}`}
                >
                  Select
                </Link>
              </Button>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="body-app">
      <div className="container">
        {loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <Loader type="ThreeDots" color="#53b59f" height={40} width={40} />
          </div>
        ) : (
          <div
            className="p-3"
            style={{
              backgroundColor: '#ffffff',
              height: '100%',
              borderRadius: '6px',
              boxShadow: '0 20px 40px 0 rgba(102, 102, 102, 0.16)',
            }}
          >
            <div className="row d-flex p-3">
              <div className="col-12">
                <h5 className="text-center">Students</h5>
              </div>
            </div>
            <div className="col-12">
              <div className="col-12 pl-0 pr-0 d-flex justify-content-between">
                <BackButton />

                <Form className="row justify-content-end">
                  <FormGroup className="mr-5">
                    <Input
                      type="checkbox"
                      onClick={(e) => {
                        setCheckMentoring(e.target.checked);
                      }}
                    />
                    No Mentoring
                  </FormGroup>

                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Search by student name"
                      onChange={(e) =>
                        inputSearchKeyword(e.currentTarget.value)
                      }
                    />
                  </FormGroup>
                </Form>
              </div>
              <Table size="sm" striped bordered responsive hover>
                <thead
                  className="font-weight-bold"
                  style={{ fontSize: '14px', color: '#505050' }}
                >
                  <tr className="d-flex">
                    <th className="col-1">No</th>
                    <th className="col-4">NAME</th>
                    <th className="col-4">EMAIL</th>
                    <th className="col-2">MENTORING</th>
                    <th className="col-1">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <RenderTable />
                </tbody>
              </Table>
              <div className="col-12 d-flex justify-content-center">
                <CustomPagination
                  pages={
                    Math.ceil(
                      (studentFilter.length || listStudent.length) /
                        itemsPerPage,
                    ) || 1
                  }
                  active={page}
                />
              </div>
            </div>
          </div>
        )}{' '}
      </div>
    </div>
  );
};

export default StudentList;
