import React from 'react';
// import Test from 'components/Programs/ScheduleForm/sections/TimeAndPlace';
// import AddProgramModalV2 from 'components/Transactions/AddProgramModalV2';

const Sandbox = () => {
  return (
    <div className="mt-5">
      {/* <Test /> */}
      <div>
        <a href="https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Javascript%20Certificate&organizationId=13236877&issueYear=2020&issueMonth=2&certUrl=https%3A%2F%2Fdocs.microsoft.com%2Fen-us%2Flearn%2Fcertifications%2Fd365-functional-consultant-sales&certId=z92321S">
          <img
            src="https://download.linkedin.com/desktop/add2profile/buttons/en_US.png "
            alt="LinkedIn Add to Profile button"
          />
        </a>
      </div>
    </div>
  );
};

export default Sandbox;
