import React from 'react';
import { Table } from 'reactstrap';

const TableHead = () => (
  <thead
    className="font-weight-bold"
    style={{ fontSize: '14px', color: '#505050' }}
  >
    <tr>
      <th>#</th>
      <th style={{ width: '20%' }}>Nominal</th>
      <th>Date</th>
      <th>Order Confirmation Number</th>
      <th>Status</th>
      <th>Paid at</th>
      <th className="text-center">Action</th>
    </tr>
  </thead>
);

const TransactionInstallmentPaymentTable = ({ children }) => {
  return (
    <Table striped bordered responsive hover>
      <TableHead />

      {children}
    </Table>
  );
};

export default TransactionInstallmentPaymentTable;
