import React from 'react';
import { Pagination, PaginationItem, PaginationLink, Button } from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';

import qs from 'query-string';
import { now } from 'moment';

const RenderPrevious = ({ activePage, handleNavigation }) => {
  if (activePage > 1) {
    return (
      <PaginationItem>
        <PaginationLink previous onClick={() => handleNavigation('previous')} />
      </PaginationItem>
    );
  }
  return null;
};

const RenderNext = ({ activePage, pages, handleNavigation }) => {
  if (activePage < pages) {
    return (
      <PaginationItem>
        <PaginationLink next onClick={() => handleNavigation('next')} />
      </PaginationItem>
    );
  }
  return null;
};

const LoopPagination = ({ activePage, pages, handleNavigation }) => {
  const arrJSX = [];
  for (let i = 1; i <= pages; i += 1) {
    if (i === activePage - 3) {
      arrJSX.push(
        <PaginationItem key={`page${i * now()}`} disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      );
    } else if (Math.abs(activePage - i) <= 2) {
      arrJSX.push(
        <PaginationItem active={i === activePage}>
          <PaginationLink
            style={{ zIndex: 0 }}
            onClick={() => handleNavigation(i)}
          >
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    } else if (i === activePage + 3) {
      arrJSX.push(
        <PaginationItem key={`page${i * now()}`} disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>,
      );
    }
  }
  return arrJSX;
};

const CustomPagination = ({ pages = 1, active, ...props }) => {
  const activePage = parseInt(active || 1, 10);
  const { push } = useHistory();
  const { search: query } = useLocation();
  const params = qs.parse(query);

  const handleNavigation = (key) => {
    let newParams = params;
    if (key === 'previous') {
      newParams = { ...newParams, page: activePage - 1 };
    } else if (key === 'next') {
      newParams = { ...newParams, page: activePage + 1 };
    } else if (key === 'first') {
      newParams = { ...newParams, page: 1 };
    } else if (key === 'last') {
      newParams = { ...newParams, page: pages };
    } else {
      newParams = { ...newParams, page: key };
    }
    push('?'.concat(qs.stringify(newParams)));
  };

  return (
    <div {...props}>
      <Pagination listClassName="m-0">
        <PaginationItem>
          <Button
            style={{ borderRadius: '3px 0px 0px 3px' }}
            onClick={() => handleNavigation('first')}
          >
            First
          </Button>
        </PaginationItem>
        <RenderPrevious
          activePage={activePage}
          handleNavigation={handleNavigation}
        />
        <LoopPagination
          activePage={activePage}
          pages={pages}
          handleNavigation={handleNavigation}
        />
        <RenderNext
          activePage={activePage}
          pages={pages}
          params={params}
          handleNavigation={handleNavigation}
        />
        <PaginationItem>
          <Button
            style={{ borderRadius: '0px 3px 3px 0px' }}
            onClick={() => handleNavigation('last')}
          >
            Last
          </Button>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default CustomPagination;
