import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Collapse,
  Input,
  Table,
  Badge,
  Button,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import CustomPagination from 'components/commons/CustomPagination';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Axios from 'supports/api';
import { fetchBranches } from 'redux/modules/purchaseOrder';
import { POHistorySkeleton } from '../components';
import ModalPreviewPOProof from '../components/ModalPreviewPOProof';

const HistoryPO = ({
  setFilterPoHistory,
  click,
  setSelectedId,
  setReadOnly,
  setpoDetailShow,
  filter,
  setFilter,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const dispatch = useDispatch();
  const page = params.get('page');
  const [isOpen, setIsOpen] = useState(true);

  const data = useSelector(({ purchaseOrder }) => purchaseOrder.PO);
  const countData = useSelector(({ purchaseOrder }) => purchaseOrder.countPO);
  const loadStatus = useSelector(({ purchaseOrder }) => purchaseOrder.status);
  const tujuanPengeluaranList = useSelector(
    ({ purchaseOrder }) => purchaseOrder.tujuanPengeluaran,
  );
  const branches = useSelector(({ purchaseOrder }) => purchaseOrder.branches);

  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const [idLoadingPrint, setIdLoadingPrint] = useState(null);

  // selected po proof to be shown in the modal
  const [selectedProofData, setSelectedProofData] = useState('');
  const [openPreview, setOpenPreview] = useState(false);

  // handle preview po proof modal
  const handleOpenPreview = (path, type) => {
    if (type === 'open') {
      setSelectedProofData(path);
      setOpenPreview(true);
    }

    if (type === 'close') {
      setSelectedProofData('');
      setOpenPreview(false);
    }
  };

  useEffect(() => {
    dispatch(fetchBranches());
  }, [dispatch]);

  useEffect(() => {
    if (!page || (Math.ceil(countData / 10) < page && countData !== 0)) {
      history.push('?page=1');
    }
  }, [page, countData, history]);

  const toggleCollapse = () => setIsOpen(!isOpen);
  const handleDateFrom = (selectedDate) => {
    setFilter({ ...filter, poDateFrom: selectedDate });
  };
  const handleDateTo = (selectedDate) => {
    setFilter({ ...filter, poDateTo: selectedDate });
  };
  const handleSearch = () => {
    setFilterPoHistory({
      poDateFrom: filter.poDateFrom,
      poDateTo: filter.poDateTo,
      poNumber: filter.poNumber,
      username: filter.username,
      remarks: filter.remarks,
      tujuanPengeluaranId: filter.tujuanPengeluaranId,
      branchId: filter.branchId,
    });
  };

  const handlePrint = (id) => {
    setIsLoadingPrint(true);
    setIdLoadingPrint(id);

    Axios.get(`/poHeader/print/${id}`)
      .then(() => {
        window.open(`${process.env.REACT_APP_BASE_URL}/poHeader/print/${id}`);
        setIsLoadingPrint(false);
        setIdLoadingPrint(null);
      })
      .catch((err) => {
        alert(`Terjadi Kesalahan System ${err}`);
        setIsLoadingPrint(false);
        setIdLoadingPrint(null);
      });
  };

  const handleStatus = (isProcess, isApproved, isCair) => {
    let status;
    if (!isProcess && !isApproved && !isCair) {
      status = 'Belum Diproses';
    } else if (isProcess && !isApproved && !isCair) {
      status = 'Belum Diapprove';
    } else if (isProcess && isApproved && !isCair) {
      status = 'Belum Dicairkan';
    } else {
      status = 'Sudah Cair';
    }
    return status;
  };

  const handleSelect = (e) => {
    setFilter({
      ...filter,
      tujuanPengeluaranId: e.target.value,
    });
  };

  const renderTable = () => {
    return data?.map((po) => {
      const {
        id,
        code,
        tglPO,
        totalPrice,
        createdBy,
        tujuanPengeluaran,
        isCair,
        isApproved,
        isProcess,
        proof,
        periodStart,
        periodEnd,
        branchName,
      } = po;

      if (loadStatus === 'loading') {
        return <POHistorySkeleton key={id} />;
      }
      return (
        <tr key={id}>
          <td className="text-center" style={{ width: '8.56rem' }}>
            <div>
              <Badge
                className="mt-1"
                color="success"
                pill
                style={{ fontSize: '14px' }}
              >
                {code}
              </Badge>
              <p className="main-text mt-2" style={{ fontSize: '12px' }}>
                {createdBy}
              </p>
            </div>
          </td>
          <td>{tujuanPengeluaran}</td>
          <td>{branchName}</td>
          <td style={{ width: '10rem' }}>
            {periodStart ? moment(periodStart).format('DD/MM/yyy') : 'N/A'}
          </td>
          <td style={{ width: '10rem' }}>
            {periodEnd ? moment(periodEnd).format('DD/MM/yyy') : 'N/A'}
          </td>
          <td style={{ width: '10rem' }}>
            {moment(tglPO).format('DD/MM/yyy')}
          </td>
          <td style={{ width: '10rem' }}>
            Rp {totalPrice ? totalPrice.toLocaleString() : null}
          </td>
          <td>{handleStatus(isProcess, isApproved, isCair)}</td>
          <td style={{ width: '4rem' }}>
            {proof ? (
              <Button
                size="sm"
                onClick={() => handleOpenPreview(proof, 'open')}
              >
                <FontAwesomeIcon icon={['fa', 'image']} />
              </Button>
            ) : (
              <em>N/A</em>
            )}
          </td>
          <td className="text-center" style={{ width: '7.25rem' }}>
            <Button
              color="secondary"
              size="sm"
              style={{
                width: '90px',
              }}
              onClick={() => {
                click();
                setSelectedId(id);
                setReadOnly(true);
                setpoDetailShow(true);
              }}
            >
              Details
            </Button>
            <Button
              color="primary"
              className="mt-1"
              size="sm"
              style={{
                width: '90px',
              }}
              onClick={() => handlePrint(id)}
            >
              {isLoadingPrint && idLoadingPrint === id ? (
                <Spinner size="sm" color="white" />
              ) : (
                'Print'
              )}
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderTujuanPengeluaran = () => {
    return tujuanPengeluaranList.map((val) => (
      <option key={val.id} value={val.id}>
        {val.tujuanPengeluaran}
      </option>
    ));
  };

  return (
    <Card>
      <CardBody>
        <div className={isOpen ? 'dropup' : null}>
          <button
            type="button"
            className="btn btn-secondary mt-2 dropdown-toggle btn-collapse-pym"
            onClick={toggleCollapse}
          >
            Filter
          </button>
        </div>
        <Collapse isOpen={isOpen}>
          <Card className="mt-1">
            <CardBody>
              <Form>
                <Row form>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Date From</Label>
                      <Col sm={7}>
                        <DatePicker
                          dateFormat="dd/MM/yyy"
                          className="form-control date-picker-pym"
                          selected={filter.poDateFrom}
                          onChange={handleDateFrom}
                        />
                      </Col>
                    </FormGroup>
                  </Col>

                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>PO Number</Label>
                      <Col sm={7}>
                        <Input
                          type="text"
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              poNumber: e.target.value,
                            })
                          }
                          value={filter.poNumber}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Date To</Label>
                      <Col sm={7}>
                        <DatePicker
                          dateFormat="dd/MM/yyy"
                          className="form-control date-picker-pym"
                          selected={filter.poDateTo}
                          onChange={handleDateTo}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>User</Label>
                      <Col sm={7}>
                        <Input
                          type="text"
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              username: e.target.value,
                            })
                          }
                          value={filter.username}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Tujuan Pengeluaran</Label>
                      <Col sm={7}>
                        <Input
                          type="select"
                          onChange={(e) => handleSelect(e)}
                          value={filter.tujuanPengeluaranId}
                        >
                          <option value="">All</option>
                          {renderTujuanPengeluaran()}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Remarks</Label>
                      <Col sm={7}>
                        <Input
                          type="text"
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              remarks: e.target.value,
                            })
                          }
                          value={filter.remarks}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col sm={6} md={6}>
                    <FormGroup row>
                      <Label sm={4}>Branch</Label>
                      <Col sm={7}>
                        <Input
                          type="select"
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              branchId: e.target.value,
                            })
                          }
                          value={filter.brnachId}
                        >
                          <option value="">All</option>
                          {branches.map((branch) => (
                            <option key={branch.id} value={branch.id} s>
                              {branch.name}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            <div className="d-flex flex-row-reverse">
              <Button
                color="success"
                className="mt-0"
                style={{
                  position: 'absolute',
                  backgroundColor: 'rgb(50, 178, 128)',
                  width: '20%',
                }}
                onClick={handleSearch}
              >
                Search <FontAwesomeIcon icon={faSearch} className="ml-2" />
              </Button>
            </div>
          </Card>
        </Collapse>
        <CustomPagination
          className="mt-5"
          pages={Math.ceil(countData / 10 || 1)}
          active={page}
        />
        <Table striped bordered responsive hover>
          <thead
            className="font-weight-bold"
            style={{ fontSize: '16px', color: '#505050' }}
          >
            <tr>
              <th className="text-center">PO Number</th>
              <th>Tujuan Pengeluaran</th>
              <th>Branch</th>
              <th>Period Start</th>
              <th>Period End</th>
              <th>Po Date</th>
              <th>Total Price</th>
              <th>Latest Status</th>
              <th className="text-center">Proof</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: '15px' }}>{renderTable()}</tbody>
        </Table>
      </CardBody>

      {/* Modal Preview PO Proof */}
      <ModalPreviewPOProof
        isOpen={openPreview}
        toggle={() => handleOpenPreview('', 'close')}
        proofData={selectedProofData}
      />
    </Card>
  );
};

export default HistoryPO;
