export const typeOptions = [
  {
    label: 'Online',
    value: 'Online',
  },
  {
    label: 'Offline',
    value: 'Offline',
  },
];

export const categoryOptions = [
  {
    label: 'Event',
    value: 'Event',
  },
  {
    label: 'Class',
    value: 'Class',
  },
  {
    label: 'Mentoring',
    value: 'Mentoring',
  },
];
