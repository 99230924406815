import React from 'react';
import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import DiscountInput from './DiscountInput';
import QuantityInput from './QuantityInput';

const PriceDetails = ({
  item,
  discount = 0,
  qty = 1,
  discountInput,
  basePriceInput,
  qtyInput,
  handleCurrencyInput,
  handleQuantityInput,
  selectedCurrency,
}) => {
  const program = JSON.parse(item);
  let price = 0;
  let registrationFee = 0;

  if (program) {
    const programPrice = program?.program_prices.find(
      (program_price) => program_price.currencyId === selectedCurrency?.id,
    );

    price =
      basePriceInput?.value ??
      programPrice?.normalPrice ??
      program?.normalPrice;

    registrationFee = programPrice?.registrationFee ?? program?.registrationFee;
  }

  const RegistrationFee = () => {
    if (registrationFee) {
      return (
        <div>
          <div className="text-gray font-weight-bold mb-2">
            Registration Fee
          </div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              {selectedCurrency?.symbol}
            </InputGroupAddon>
            <Input
              type="text"
              disabled
              value={registrationFee.toLocaleString()}
            />
            <InputGroupAddon addonType="append">
              <Button color="success" disabled style={{ width: '30px' }}>
                +
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      );
    }
    return null;
  };

  const TotalRegistrationFee = () => {
    if (registrationFee) {
      return (
        <div>
          <div className="text-gray font-weight-bold mb-2">
            Total Registration Fee
          </div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              {selectedCurrency?.symbol}
            </InputGroupAddon>
            <Input
              type="text"
              disabled
              value={(registrationFee * qty).toLocaleString()}
            />
            <InputGroupAddon addonType="append">
              <Button color="success" disabled style={{ width: '30px' }}>
                +
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      );
    }
    return null;
  };

  const TotalDiscount = () => {
    if (program.type === 'program' || program.type === 'event') {
      return (
        <div>
          <div className="text-gray font-weight-bold mb-2">Total Discount</div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              {selectedCurrency?.symbol}
            </InputGroupAddon>
            <Input
              type="text"
              disabled
              value={(discount * qty).toLocaleString()}
            />
          </InputGroup>
        </div>
      );
    }
    return null;
  };

  const totalPrice = (price + registrationFee - discount) * qty;

  return (
    <div className="d-flex flex-row justify-content-between">
      <div
        className="d-flex flex-column"
        style={{ width: '100%', maxWidth: '350px' }}
      >
        <div className="text-gray font-weight-bold mb-2">Base Price</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            {selectedCurrency?.symbol}
          </InputGroupAddon>

          <Input
            type="text"
            value={basePriceInput.displayValue}
            // NOTE: enabled for all program sales
            disabled={!(Boolean(program) || program.type === 'program')}
            onChange={({ target }) =>
              handleCurrencyInput('basePrice', target.value)
            }
          />
        </InputGroup>

        <RegistrationFee />
        <DiscountInput
          {...discountInput}
          type={program.type}
          handleCurrencyInput={handleCurrencyInput}
        />
        <QuantityInput
          {...qtyInput}
          type={program.type}
          handleQuantityInput={handleQuantityInput}
        />
      </div>
      <div
        className="d-flex flex-column"
        style={{ width: '100%', maxWidth: '350px' }}
      >
        <div className="text-gray font-weight-bold mb-2">Total Base</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            {selectedCurrency?.symbol}
          </InputGroupAddon>
          <Input type="text" disabled value={(price * qty).toLocaleString()} />
        </InputGroup>
        <TotalRegistrationFee />
        <TotalDiscount />
        <div className="text-gray font-weight-bold mb-2">Total Price</div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            {selectedCurrency?.symbol}
          </InputGroupAddon>
          <Input type="text" disabled value={totalPrice.toLocaleString()} />
        </InputGroup>
      </div>
    </div>
  );
};

export default PriceDetails;
