import React from 'react';
import ReactQuill from 'react-quill';

const HTMLEditor = ({ id, value, name, handleInput }) => {
  return (
    <ReactQuill
      id={id}
      key={id}
      value={value}
      onChange={(evt) => {
        handleInput(name, evt);
      }}
    />
  );
};

export default HTMLEditor;
