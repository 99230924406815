// experimental
// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

// Reducers can directly reassign state, or return a new state, BUT NOT BOTH
// Detailed docs on state reassignment in reducers here https://redux-toolkit.js.org/api/createreducer/
// There are pitfalls to immer however, which you can read here https://immerjs.github.io/immer/docs/pitfalls

import axios from 'supports/api';
import { createSlice } from '@reduxjs/toolkit';

const initialUserData = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  isVerified: false,
  profilePicture: '',
  identityCard: '',
  identityCardImg: '',
  npwp: '',
  npwpImg: '',
};

const initialState = {
  status: 'idle',
  error: null,
  email: '',
  userData: initialUserData,
};

const { actions, reducer } = createSlice({
  name: 'trxUser',
  initialState,
  reducers: {
    fetchingUserData: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    fetchSuccess: (state, action) => {
      state.status = 'success';
      state.userData = action.payload;
    },
    fetchFailed: (state, action) => {
      state.status = 'failure';
      state.userData = initialUserData;
      state.error = action.payload;
    },
    setUserEmail: (state, action) => {
      state.email = action.payload;
    },
    resetUser: (state) => {
      state.userData = initialUserData;
      state.status = 'idle';
      state.error = null;
    },
  },
});

export default reducer;

export const {
  fetchingUserData,
  fetchSuccess,
  fetchFailed,
  setUserEmail,
  resetUser,
} = actions;

export const getUserData = (email = '') => async (dispatch) => {
  dispatch(fetchingUserData());
  setTimeout(() => {
    axios
      .post(`/admin/verify-email`, {
        email,
      })
      .then(({ data }) => {
        dispatch(fetchSuccess(data.result));
      })
      .catch(({ response }) => {
        let error = '';
        if (response) {
          error = response.data.message;
        } else {
          error = 'No response from server';
        }
        dispatch(fetchFailed(error));
      });
  }, 400); // Loads for a minimal of 0.4s
};
