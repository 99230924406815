import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Input, Card, CardBody, Table, Collapse } from 'reactstrap';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faSave,
  // faTrash,
  faPlusCircle,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import Axios from 'supports/api';
import CustomPaginationState from 'components/commons/CustomPaginationState';
import NumberFormat from 'react-number-format';
import { selectGl } from 'redux/modules/glJournal';

const SaveButton = ({ isEditable, onClick }) => {
  if (isEditable) {
    return (
      <FontAwesomeIcon
        className="ml-2"
        icon={faSave}
        size="lg"
        title="Save"
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      />
    );
  }
  return null;
};

const NewButton = ({ isEditable, onClick }) => {
  if (isEditable) {
    return (
      <FontAwesomeIcon
        className="ml-2"
        icon={faFile}
        size="lg"
        title="New"
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      />
    );
  }
  return null;
};

// const DeleteButton = ({ isEditable, onClick }) => {
//   if (isEditable) {
//     return (
//       <FontAwesomeIcon
//         icon={faTrash}
//         size="lg"
//         title="Delete"
//         className="ml-2"
//         onClick={onClick}
//       />
//     );
//   }
//   return null;
// };

const GLJournalForm = () => {
  const dispatch = useDispatch();

  // redux
  const selectedGL = useSelector((state) => state.glJournal.selectedGL);

  // header states (plus all items data)
  const [id, setId] = useState(null);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [journalDate, setJournalDate] = useState(new Date());
  const [referenceNumber, setReferenceNumber] = useState('');
  const [remarks, setRemarks] = useState('');
  const [journalType, setJournalType] = useState('');
  const [glJournalItems, setGLJournalItems] = useState([]);
  const [filteredGLJournalItems, setFilteredJournalItems] = useState([]);

  // new item input states
  const [accounts, setAccounts] = useState([]);
  const [accountMasterId, setAccountMasterId] = useState(1);
  const [debit, setDebit] = useState('');
  const [credit, setCredit] = useState('');
  const [description, setDescription] = useState('');

  // validate form input GL journal data
  const [valJournalType, setValJournalType] = useState(false);
  const [valRefNum, setValRefNum] = useState(false);
  const [valRemarks, setValRemarks] = useState(false);

  // indicator color for GL item input
  const [color, setColor] = useState('#E9ECEF');

  // filter states
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const [filterAccountId, setFilterAccountId] = useState('0');

  // pagination states
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    Axios.get(`/admin/accounts`)
      .then((res) => {
        setAccounts(res.data.result);
      })
      .catch((err) => {
        console.log(err);
        alert('failed to get accounts');
      });
  }, []);

  const accountSelection = accounts?.map((item) => {
    return (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    );
  });

  // on selectedGL change
  useEffect(() => {
    if (selectedGL.id) {
      setId(selectedGL.id);
    } else {
      setId(null);
    }
    setJournalDate(moment(selectedGL.journalDate).toDate());
    setJournalType(selectedGL.journalType);
    setReferenceNumber(selectedGL.referenceNumber);
    setRemarks(selectedGL.remarks);
    if (selectedGL.glJournalItems?.length) {
      setGLJournalItems(selectedGL.glJournalItems);
    }
  }, [selectedGL, dispatch]);

  useEffect(() => {
    // counts total debit and credit based on glitems
    let countDebit = 0;
    let countCredit = 0;
    if (glJournalItems.length) {
      glJournalItems.forEach((item) => {
        if (item.debit) countDebit += Number(item.debit);
        if (item.credit) countCredit += Number(item.credit);
      });
    }
    setTotalCredit(countCredit);
    setTotalDebit(countDebit);
  }, [glJournalItems]);

  useEffect(() => {
    if (journalType) setValJournalType(false);
    if (remarks) setValRemarks(false);
    if (referenceNumber) setValRefNum(false);
  }, [journalType, remarks, referenceNumber]);

  useEffect(() => {
    let filtered = glJournalItems;
    if (Number(filterAccountId)) {
      filtered = filtered.filter((item) => {
        return item.accountMasterId === Number(filterAccountId);
      });
    }
    setFilteredJournalItems(filtered);
  }, [glJournalItems, filterAccountId]);

  // const onDeleteClick = () => {
  //   console.log({
  //     id,
  //     totalCredit,
  //     totalDebit,
  //     journalDate,
  //     journalType,
  //     glJournalItems,
  //   });
  // };

  const onSaveClick = async () => {
    if (journalType && remarks && referenceNumber) {
      // if (totalDebit && totalCredit) {
      //   if (totalDebit === totalCredit) {
      const body = {
        id,
        totalDebit,
        totalCredit,
        journalDate,
        referenceNumber,
        remarks,
        journalType,
        glJournalItems,
      };

      if (!id) {
        const newHeader = await Axios.post(
          `/gl-journal/create-new-header`,
          body,
        );
        const res = await Axios.get(`gl-journal/gl-list/${newHeader.data.id}`);
        dispatch(selectGl(res.data.data));
        window.alert('Successfully created new GL Journal.');
      } else {
        await Axios.put(`gl-journal/edit-header/${id}`, body);
        const res = await Axios.get(`gl-journal/gl-list/${id}`);
        dispatch(selectGl(res.data.data));
        window.alert('GL Journal successfully updated.');
      }
      //   } else {
      //     window.alert('Debit and Credit Not Balance !');
      //   }
      // } else {
      //   window.alert('Total Debit And Credit Cannot Be Null !');
      // }
    } else {
      window.alert('Please fill out all required fields');
      if (!journalType) setValJournalType(true);
      if (!referenceNumber) setValRefNum(true);
      if (!remarks) setValRemarks(true);
    }
  };

  useEffect(() => {
    if (!totalCredit && !totalDebit) {
      setColor('#E9ECEF');
    } else if (totalCredit !== totalDebit) {
      setColor('#FF0000');
    } else {
      setColor('#2DA073');
    }
  }, [totalCredit, totalDebit]);

  const onNewClick = () => {
    if (
      window.confirm(
        'Do you want to clear out fields and create new gl journal?',
      )
    ) {
      setId(null);
      setJournalDate(new Date());
      setJournalType('');
      setReferenceNumber('');
      setRemarks('');
      setGLJournalItems([]);
      setValJournalType(false);
      setValRefNum(false);
      setValRemarks(false);
    }
  };

  const handleAddItem = () => {
    const itemObj = {
      debit: Number(debit),
      credit: Number(credit),
      description,
      accountMasterId,
    };
    if (glJournalItems.length) {
      setGLJournalItems(glJournalItems.concat([itemObj]));
    } else {
      setGLJournalItems([itemObj]);
    }
    setAccountMasterId(1);
    setDebit('');
    setCredit('');
    setDescription('');
  };

  const handleDeleteitem = (i) => {
    if (
      window.confirm(`Apakah anda ingin menghapus GL item dengan deskripsi:
    ${glJournalItems[i].description}`)
    ) {
      const prevState = glJournalItems.filter((item, index) => i !== index);
      setGLJournalItems(prevState);
    }
  };

  const handleOnChange = (value, name, i) => {
    const current = JSON.parse(JSON.stringify(glJournalItems));
    current[i] = {
      ...current[i],
      [name]: name === 'description' ? value : Number(value),
    };
    setGLJournalItems(current);
  };

  const slicedGLJournalItems = () => {
    let sliced = filteredGLJournalItems;
    sliced = sliced.slice(
      (page - 1) * itemsPerPage,
      (page - 1) * itemsPerPage + itemsPerPage,
    );
    return sliced;
  };

  return (
    <Card>
      <CardBody>
        {/* header inputs */}
        <div
          className="col-12 d-flex align-items-center justify-content-between indicator mb-2"
          style={{ backgroundColor: '#E9ECEF' }}
        >
          <div style={{ width: '50px' }} />
          <div className="tag-gray dker font-weight-bold">GL Journal</div>
          <div>
            <NewButton isEditable onClick={onNewClick} />
            <SaveButton isEditable onClick={onSaveClick} />
            {/* <DeleteButton isEditable onClick={onDeleteClick} /> */}
          </div>
        </div>
        {/* <div className="d-flex flex-row justify-content-end w-100">
          
        </div> */}
        <div className="row my-2">
          <div className="col-6 mb-3">
            <div className="tag-gray dker font-weight-bold mb-2">
              Journal Date :
            </div>
            <DatePicker
              className="form-control"
              selected={journalDate}
              selectsStart
              dateFormat="dd/MMMM/yyy"
              onChange={(date) => {
                setJournalDate(date);
              }}
              showMonthYearDropdown
            />
          </div>
          <div className="col-6 mb-3">
            <div className="tag-gray dker font-weight-bold mb-2">
              Journal Type :
            </div>
            <Input
              type="text"
              className="form-control"
              value={journalType}
              onChange={(e) => setJournalType(e.target.value)}
              invalid={valJournalType}
            />
          </div>
          <div className="col-6 mb-3">
            <div className="tag-gray dker font-weight-bold mb-2">
              Total Debit :
            </div>
            <Input
              type="text"
              className="form-control"
              disabled
              value={totalDebit.toLocaleString()}
            />
          </div>
          <div className="col-6 mb-3">
            <div className="tag-gray dker font-weight-bold mb-2">
              Total Credit :
            </div>
            <Input type="text" disabled value={totalCredit.toLocaleString()} />
          </div>
          <div className="col-6 mb-3">
            <div className="tag-gray dker font-weight-bold mb-2">
              Reference Number :
            </div>
            <Input
              type="text"
              value={referenceNumber}
              onChange={(e) => setReferenceNumber(e.target.value)}
              invalid={valRefNum}
            />
          </div>
          <div className="col-6 mb-3">
            <div className="tag-gray dker font-weight-bold mb-2">Remarks :</div>
            <Input
              type="textarea"
              className="form-control"
              rows={3}
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              invalid={valRemarks}
            />
          </div>
          <div className="col-12">
            <hr className="mb-0" />
          </div>
          <div className="col-12">
            <hr className="mt-1" />
          </div>

          {/* Filter items */}
          <div className="col-12">
            <div
              className="d-flex flex-column mr-md-3"
              style={{ minWidth: '358px', maxWidth: '600px' }}
            >
              <div className="w-100 mr-md-5 my-1">
                <div style={{ width: '100%' }}>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary my-2 btn-collapse-pym"
                    onClick={toggle}
                  >
                    <div className="d-flex justify-content-between">
                      <span>Filter Items</span>
                      <span>
                        <FontAwesomeIcon
                          icon={!isOpen ? faChevronDown : faChevronUp}
                        />
                      </span>
                    </div>
                  </button>
                </div>
                <Collapse isOpen={isOpen}>
                  <Card>
                    <CardBody>
                      <div className="mb-3 d-flex flex-row">
                        <div
                          style={{ width: '25%' }}
                          className="tag-gray font-weight-bold dker mr-2 d-flex align-items-center"
                        >
                          Account Name
                        </div>
                        <div style={{ width: '75%' }}>
                          <Input
                            value={filterAccountId}
                            onChange={(e) => setFilterAccountId(e.target.value)}
                            type="select"
                          >
                            <option value="0">All Account Name</option>
                            {accountSelection}
                          </Input>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div>
          </div>
          <div className="col-12">
            <hr className="mb-0" />
          </div>
          <div className="col-12">
            <hr className="mt-1" />
          </div>

          {/* Gl item list */}
          <div className="col-12">
            <div
              className="col-12 d-flex justify-content-center align-items-center indicator"
              style={{ backgroundColor: color }}
            >
              <div
                className={
                  color !== '#E9ECEF'
                    ? 'text-indicator'
                    : 'tag-gray dker font-weight-bold'
                }
              >
                GL Journal Item
              </div>
            </div>
            <Table responsive striped>
              <thead>
                <tr>
                  <th width={450}>Account</th>
                  <th width={200}>Debit</th>
                  <th width={200}>Credit</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {slicedGLJournalItems().map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Input
                          type="select"
                          value={item.accountMasterId}
                          onChange={(e) =>
                            handleOnChange(
                              e.target.value,
                              'accountMasterId',
                              index,
                            )
                          }
                        >
                          <option value={0} disabled hidden>
                            None
                          </option>
                          {accountSelection}
                        </Input>
                      </td>
                      <td>
                        {/* <Input
                          type="string"
                          style={{ textAlign: 'right' }}
                          value={item.debit}
                          onChange={(e) =>
                            handleOnChange(e.target.value, 'debit', index)
                          }
                        /> */}
                        <NumberFormat
                          style={{ textAlign: 'right' }}
                          className="form-control"
                          thousandSeparator
                          prefix="Rp."
                          defaultValue={item.debit}
                          onValueChange={(e) =>
                            handleOnChange(e.value, 'debit', index)
                          }
                        />
                      </td>
                      <td>
                        {/* <Input
                          type="string"
                          style={{ textAlign: 'right' }}
                          value={item.credit}
                          onChange={(e) =>
                            handleOnChange(e.target.value, 'credit', index)
                          }
                        /> */}
                        <NumberFormat
                          style={{ textAlign: 'right' }}
                          className="form-control"
                          thousandSeparator
                          prefix="Rp."
                          defaultValue={item.credit}
                          onValueChange={(e) =>
                            handleOnChange(e.value, 'credit', index)
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="textarea"
                          value={item.description}
                          onChange={(e) =>
                            handleOnChange(e.target.value, 'description', index)
                          }
                        />
                      </td>
                      <td>
                        <div className="d-flex flex-row align-items-center justify-content-center">
                          <FontAwesomeIcon
                            icon={['fas', 'minus-circle']}
                            size="lg"
                            title="delete"
                            className="delete-button"
                            onClick={() => handleDeleteitem(index)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr style={{ borderTop: '3px solid #DFDFDF' }}>
                  <td>
                    <Input
                      value={accountMasterId}
                      onChange={(e) => {
                        setAccountMasterId(Number(e.target.value));
                      }}
                      type="select"
                    >
                      <option value={0} disabled hidden>
                        None
                      </option>
                      {accountSelection}
                    </Input>
                  </td>
                  <td>
                    <NumberFormat
                      style={{ textAlign: 'right' }}
                      className="form-control"
                      thousandSeparator
                      prefix="Rp."
                      value={debit}
                      onValueChange={(e) => setDebit(e.value)}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      style={{ textAlign: 'right' }}
                      className="form-control"
                      thousandSeparator
                      prefix="Rp."
                      value={credit}
                      onValueChange={(e) => setCredit(e.value)}
                    />
                  </td>
                  <td>
                    <Input
                      type="textarea"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </td>
                  <td>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        size="lg"
                        title="Add"
                        style={{ color: '#53B59F' }}
                        onClick={handleAddItem}
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
          <div className="col-12">
            <div className="d-flex flex-row justify-content-center">
              <CustomPaginationState
                pages={
                  Math.ceil(filteredGLJournalItems.length / itemsPerPage) || 1
                }
                active={page}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default GLJournalForm;
