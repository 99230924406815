import React, { Component } from 'react';
import axios from 'supports/api';
import { ButtonGroup, Button } from 'reactstrap';
import Select from 'react-select';

class AdminFinanceMasterTujuanPengeluaranRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      tujuanPengeluaran: this.props.tujuanPengeluaran,
      accountMaster: {
        value: this.props.accountMasterId,
        label: this.props.accountMaster,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    // console.log(this.props.accountMaster, nextProps.accountMaster)
    // this.setState({
    //     tujuanPengeluaran: this.props.tujuanPengeluaran,
    //     accountMaster: { value: nextProps.accountMasterId, label: nextProps.accountMaster }
    // })
    // var data = {
    //     tujuanPengeluaran: this.state.tujuanPengeluaran,
    //     accountMaster: this.state.accountMaster,
    //     accountMasterId: this.state.accountMaster.value
    // }
    // this.props.update(this.props.index, data)
  }

  handleInput = (type, input) => {
    this.setState({ [type]: input });
  };

  handleAccountMaster = (selectedOption) => {
    this.setState({ accountMaster: selectedOption });
  };

  handleEdit = () => {
    this.setState({ isEditing: true });
  };

  handleDelete = () => {
    if (window.confirm('Apakah anda yakin ingin menghapus data ini ?')) {
      axios
        .delete(`/masterTujuanPengeluaran/delete/${this.props.id}`)
        .then((res) => {
          alert('Data telah berhasil dihapus');
          console.log(res);
          this.props.refreshList();
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    }
  };

  handleCancel = () => {
    this.setState({
      isEditing: false,
      tujuanPengeluaran: this.props.tujuanPengeluaran,
      accountMaster: {
        value: this.props.accountMasterId,
        label: this.props.accountMaster,
      },
    });
  };

  handleSave = () => {
    if (this.state.tujuanPengeluaran !== '') {
      if (this.state.accountMaster !== '') {
        if (window.confirm('Apakah anda yakin ingin mengupdate data ini ?')) {
          const data = {
            tujuanPengeluaran: this.state.tujuanPengeluaran,
            accountMasterId: this.state.accountMaster.value,
          };

          axios
            .put(`/masterTujuanPengeluaran/update/${this.props.id}`, data)
            .then((res) => {
              alert('Data telah berhasil diperbaharui');
              // console.log(res)
              // data = {
              //     tujuanPengeluaran: this.state.tujuanPengeluaran,
              //     accountMaster: this.state.accountMaster,
              //     accountMasterId: this.state.accountMaster.value
              // }
              // this.props.update(this.props.index, data)

              this.setState({
                isEditing: false,
              });
              this.props.refreshList();
            })
            .catch((err) => {
              console.log(err);
              alert(err.message);
            });
        }
      }
    }
  };

  render() {
    if (!this.state.isEditing) {
      return (
        <tr>
          <th>{this.props.number}</th>
          <td>{this.state.tujuanPengeluaran}</td>
          <td>{this.state.accountMaster.label}</td>
          <td>
            <ButtonGroup>
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  this.handleEdit();
                }}
              >
                Edit
              </Button>
              <Button
                className="btn btn-danger"
                onClick={() => this.handleDelete()}
              >
                Delete
              </Button>
            </ButtonGroup>
          </td>
        </tr>
      );
    }
    return (
      <tr>
        <th>{this.props.number}</th>
        <td>
          <input
            type="text"
            value={this.state.tujuanPengeluaran}
            className="form-control"
            placeholder="Input Tujuan Pengeluaran"
            ref="tujuanPengeluaran"
            onChange={() =>
              this.handleInput(
                'tujuanPengeluaran',
                this.refs.tujuanPengeluaran.value,
              )
            }
          />
        </td>
        <td>
          <Select
            value={this.state.accountMaster}
            options={this.props.listAccountMaster}
            onChange={this.handleAccountMaster}
            id="selectAccountMasterItem"
          />
        </td>
        <td>
          <ButtonGroup>
            <Button
              className="btn btn-secondary"
              onClick={() => {
                this.handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button className="btn btn-info" onClick={() => this.handleSave()}>
              Save
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    );
  }
}

export default AdminFinanceMasterTujuanPengeluaranRow;
