import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import axios from 'supports/api';
import { useHistory, useParams } from 'react-router';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  initialFetchOnlineCourseData,
  setSelectCategory,
  setSelectTopic,
  setSelectLevel,
} from 'redux/modules/onlineCourse';

import InputImageComponent from './InputImageComponent';
import InputTrailerComponent from './InputTrailerComponent';
import InputTextComponent from './InputTextComponent';
import InputPriceComponent from './InputPriceComponent';
import SelectionComponent from './SelectionComponent';

import noimage from './no_image.png';

const INITIAL_FORM = {
  image: {
    file: {},
    valid: false,
    invalid: false,
    error: 'Please select an image',
  },
  video: {
    file: {},
    valid: false,
    invalid: false,
    error: 'Please select a video',
  },
  title: {
    text: '',
    valid: false,
    invalid: false,
    error: '*Please input title',
  },
  subHeader: {
    text: '',
    valid: false,
    invalid: false,
    error: '*Please input sub header',
  },
  code: {
    text: '',
    valid: false,
    invalid: false,
    error: '*Please input code',
  },
  price: {
    price: 0,
    displayedPrice: '0',
    valid: false,
    invalid: false,
    error: '*Please input code',
  },
};

const OnlineCourseForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { onlineCourseId } = useParams();

  const data = useSelector((state) => state.onlineCourse.data);
  const filteringOptions = useSelector(
    (state) => state.onlineCourse.filteringOptions,
  );
  const { selectCategory, selectTopic, selectLevel } = useSelector(
    (state) => state.onlineCourse.selectionInput,
  );

  const categoryOptions = filteringOptions.categories.map((category) => {
    return (
      <option key={category.id} value={category.id}>
        {category.name}
      </option>
    );
  });
  const topicOptions = filteringOptions.topics.map((topic) => {
    return (
      <option key={topic.id} value={topic.id}>
        {topic.name}
      </option>
    );
  });
  const levelOptions = filteringOptions.levels.map((level) => {
    return (
      <option key={level.id} value={level.id}>
        {level.name}
      </option>
    );
  });

  const [thumbnailImage, setThumbnailImage] = useState(INITIAL_FORM.image);
  const [trailerVideo, setTrailerVideo] = useState(INITIAL_FORM.video);
  const [onlineCourseTitle, setOnlineCourseTitle] = useState(
    INITIAL_FORM.title,
  );
  const [onlineCourseSlug, setOnlineCourseSlug] = useState('');
  const [onlineCourseSubHeader, setOnlineCourseSubHeader] = useState(
    INITIAL_FORM.subHeader,
  );
  const [onlineCourseCode, setOnlineCourseCode] = useState(INITIAL_FORM.code);
  const [onlineCoursePrice, setOnlineCoursePrice] = useState(
    INITIAL_FORM.price,
  );
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadingStates, setLoadingStates] = useState(false);

  useEffect(() => {
    dispatch(initialFetchOnlineCourseData());
  }, [dispatch]);

  const [editMode, setEditMode] = useState(false);
  const [onlineCourseData, setOnlineCourseData] = useState({});

  const fetchOnlineCourseById = async (id) => {
    const response = await axios.get(`/v2/online-course/${id}`);
    setOnlineCourseData(response.data.data);
  };

  // useEffects for edit mode
  useEffect(() => {
    if (onlineCourseId) {
      setEditMode(true);
      fetchOnlineCourseById(onlineCourseId);
    }
  }, [onlineCourseId]);

  const handleThumbnailImageFile = (file) => {
    const maxFileSize = 1000000;
    if (file.size <= maxFileSize) {
      document.getElementById('thumbnail-preview').src = URL.createObjectURL(
        file,
      );
      const valid = true;
      const invalid = false;
      setThumbnailImage((prevState) => ({
        ...prevState,
        valid,
        invalid,
        file,
      }));
    } else {
      setThumbnailImage(INITIAL_FORM.image);
      document.getElementById('thumbnail-preview').src = noimage;
      window.alert('Max file size is 1 MB.');
    }
  };

  const handleTrailerVideoFile = (file) => {
    const maxFileSize = 5000000000;
    if (file.size <= maxFileSize) {
      document.getElementById('trailer-preview').src = URL.createObjectURL(
        file,
      );

      const valid = true;
      const invalid = false;
      setTrailerVideo((prevState) => ({
        ...prevState,
        valid,
        invalid,
        file,
      }));
    } else {
      setTrailerVideo(INITIAL_FORM.video);
      document.getElementById('trailer-preview').src = null;
      window.alert('Max file size is 5 GB.');
    }
  };

  const handleInputOnlineCourseTitle = (text) => {
    const valid = !!text;
    const invalid = !text;

    setOnlineCourseTitle((prevState) => ({
      ...prevState,
      valid,
      invalid,
      text,
    }));

    const slug = text.toLowerCase().split(' ').join('-');
    setOnlineCourseSlug(slug);
  };

  const handleInputOnlineCourseSubHeader = (text) => {
    const valid = !!text;
    const invalid = !text;

    setOnlineCourseSubHeader((prevState) => ({
      ...prevState,
      valid,
      invalid,
      text,
    }));
  };

  const handleInputOnlineCourseCode = (code) => {
    let valid = !!code;
    let invalid = !code;
    let error = '*Please input code';
    const text = code.toUpperCase();

    const checkIfTheCodeIsAlreadyUsed = () => {
      const usedCodes = data.filter((item) => item.code === text);
      return usedCodes.length > 0 && !editMode;
    };

    if (checkIfTheCodeIsAlreadyUsed()) {
      valid = false;
      invalid = true;
      error = '*That code has been used, please type another code';
      setOnlineCourseCode((prevState) => ({
        ...prevState,
        error,
        valid,
        invalid,
        text,
      }));
    } else {
      setOnlineCourseCode((prevState) => ({
        ...prevState,
        error,
        valid,
        invalid,
        text,
      }));
    }
  };

  const handleInputOnlineCoursePrice = (price) => {
    const valid = !!price;
    const invalid = !price;

    const number = parseInt(price.split(',').join(''), 10);
    if (price.split(',').join('') === '') {
      setOnlineCoursePrice((prevState) => ({
        ...prevState,
        price: 0,
        displayedPrice: '0',
      }));
    } else {
      setOnlineCoursePrice((prevState) => ({
        ...prevState,
        price: number,
        displayedPrice: number.toLocaleString(),
      }));
    }

    setOnlineCoursePrice((prevState) => ({
      ...prevState,
      valid,
      invalid,
      price,
    }));
  };

  // useEffect for filling inputs on edit mode
  useEffect(() => {
    // only run if onlineCourseData is not an empty object
    if (!_.isEmpty(onlineCourseData)) {
      handleInputOnlineCourseTitle(onlineCourseData.title);
      handleInputOnlineCourseSubHeader(onlineCourseData.subHeader);
      handleInputOnlineCourseCode(onlineCourseData.code);
      handleInputOnlineCoursePrice(onlineCourseData.normalPrice.toString());
      dispatch(setSelectCategory(onlineCourseData.online_course_categoryId));
      dispatch(setSelectTopic(onlineCourseData.online_course_category_topicId));
      dispatch(setSelectLevel(onlineCourseData.online_course_level_id));
      document.getElementById(
        'thumbnail-preview',
      ).src = `${process.env.REACT_APP_BASE_URL}${onlineCourseData.thumbnail}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineCourseData]);

  const buttonDisabled = () => {
    // return true will disabled the button
    if (!thumbnailImage.file.name && !editMode) {
      return true;
    }
    if (onlineCourseTitle.text === '') {
      return true;
    }
    if (
      onlineCourseCode.text === '' ||
      onlineCourseCode.error ===
        '*That code has been used, please type another code'
    ) {
      return true;
    }
    if (selectCategory === 0) {
      return true;
    }
    if (selectTopic === 0) {
      return true;
    }
    if (selectLevel === 0 && !editMode) {
      return true;
    }
    if (loadingStates) {
      return true;
    }
    return false;
  };

  const setInitialForm = () => {
    setThumbnailImage(INITIAL_FORM.image);
    document.getElementById('thumbnail-preview').src = noimage;
    setTrailerVideo(INITIAL_FORM.video);
    document.getElementById('trailer-preview').src = null;
    setOnlineCourseTitle(INITIAL_FORM.title);
    setOnlineCourseSlug('');
    setOnlineCourseSubHeader(INITIAL_FORM.subHeader);
    setOnlineCourseCode(INITIAL_FORM.code);
    setOnlineCoursePrice(INITIAL_FORM.price);
    dispatch(setSelectCategory(0));
    dispatch(setSelectTopic(0));
    dispatch(setSelectLevel(0));
  };

  const createNewOnlineCourse = async () => {
    let normalPrice = onlineCoursePrice.price;
    if (onlineCoursePrice.price !== 0) {
      normalPrice = parseInt(onlineCoursePrice.price.split(',').join(''), 10);
    }
    try {
      setLoadingStates(true);
      const formData = new FormData();
      formData.append('trailer', trailerVideo.file);
      formData.append('thumbnail', thumbnailImage.file);
      formData.append('title', onlineCourseTitle.text);
      formData.append('subHeader', onlineCourseSubHeader.text);
      formData.append('slug', onlineCourseSlug);
      formData.append('code', onlineCourseCode.text);
      formData.append('normalPrice', normalPrice);
      formData.append('onlineCourseCategoryId', selectCategory);
      formData.append('onlineCourseCategoryTopicId', selectTopic);
      formData.append('onlineCourseLevelId', selectLevel);
      const options = {
        onUploadProgress: (progressEvent) => {
          setUploadProgress(
            // multiple by 90 (not 100) so that the max value of progress is 90%
            Math.round((progressEvent.loaded * 90) / progressEvent.total),
          );
        },
      };
      await axios.post(`v2/online-course`, formData, options);
      dispatch(initialFetchOnlineCourseData());
      setInitialForm();
      setLoadingStates(false);
      window.alert('Create new online course data success');
      history.push('/admin/online-course');
    } catch (error) {
      setUploadProgress(0);
      setLoadingStates(false);
      window.alert(error);
    }
  };

  const editOnlineCourse = async () => {
    if (
      window.confirm('Apakah anda yakin untuk mengubah data Online Course ini?')
    ) {
      try {
        setLoadingStates(true);
        const formData = new FormData();

        // new / updated trailer & thumbnail
        formData.append('trailerFile', trailerVideo.file);
        formData.append('thumbnailFile', thumbnailImage.file);

        // old / previous trailer & thumbnail if there is one
        if (onlineCourseData.trailer) {
          formData.append('trailer', onlineCourseData.trailer);
        }
        if (onlineCourseData.thumbnail) {
          formData.append('thumbnail', onlineCourseData.thumbnail);
        }

        formData.append('id', onlineCourseData.id);
        formData.append('folderId', onlineCourseData.folderId);
        formData.append('title', onlineCourseTitle.text);
        formData.append('subHeader', onlineCourseSubHeader.text);
        formData.append(
          'normalPrice',
          parseInt(onlineCoursePrice.price.split(',').join(''), 10),
        );
        formData.append('onlineCourseCategoryId', selectCategory);
        formData.append('onlineCourseCategoryTopicId', selectTopic);

        const options = {
          onUploadProgress: (progressEvent) => {
            setUploadProgress(
              // multiple by 90 (not 100) so that the max value of progress is 90%
              Math.round((progressEvent.loaded * 90) / progressEvent.total),
            );
          },
        };
        await axios.put(`v2/online-course`, formData, options);
        dispatch(initialFetchOnlineCourseData());
        setInitialForm();
        setLoadingStates(false);
        window.alert('Edit Online Course Data Success');
        history.push('/admin/online-course');
      } catch (err) {
        setUploadProgress(0);
        setLoadingStates(false);
        window.alert(err);
      }
    }
  };

  const inputImageComponentProps = {
    thumbnailImage,
    handleThumbnailImageFile,
  };
  const categoriesSelectionComponentProps = {
    arrayOfOptions: categoryOptions,
    label: 'Category',
    value: selectCategory,
    handleSelect: (value) => dispatch(setSelectCategory(value)),
  };
  const topicsSelectionComponentProps = {
    arrayOfOptions: topicOptions,
    label: 'Topic',
    value: selectTopic,
    handleSelect: (value) => dispatch(setSelectTopic(value)),
  };
  const levelsSelectionComponentProps = {
    arrayOfOptions: levelOptions,
    label: 'Level',
    value: selectLevel,
    handleSelect: (value) => dispatch(setSelectLevel(value)),
  };

  return (
    <div className="white-box py-2 container">
      <div className="row py-3">
        <div className="col-12">
          <div>
            <div className="line-green mb-2" />
            <div className="general-title text-gray">Online Course</div>
          </div>
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            setInitialForm();
            history.goBack();
          }}
          type="button"
        >
          <FontAwesomeIcon
            className="text-center mr-2"
            icon={['fas', 'chevron-left']}
            size="1x"
          />
          Back
        </Button>
      </div>
      <div className="row">
        <div className="col-12">
          <Card className="my-4">
            <CardHeader>
              Online Course Form - {editMode ? 'Edit' : 'Create'} Online Course
            </CardHeader>
            <CardBody>
              <SelectionComponent {...topicsSelectionComponentProps} />
              <div className="d-flex flex-row justify-content-between">
                <div className="w-100 mr-5">
                  <SelectionComponent {...categoriesSelectionComponentProps} />
                </div>
                <div className="w-75">
                  <SelectionComponent
                    disabled={editMode}
                    {...levelsSelectionComponentProps}
                  />
                </div>
              </div>
              <InputImageComponent {...inputImageComponentProps} />
              <InputTrailerComponent
                trailerVideo={trailerVideo}
                handleTrailerVideoFile={handleTrailerVideoFile}
              />
              <InputTextComponent
                {...onlineCourseTitle}
                label="Title"
                onInput={(text) => handleInputOnlineCourseTitle(text)}
              />
              <InputTextComponent
                {...onlineCourseSubHeader}
                label="Sub Header"
                onInput={(text) => handleInputOnlineCourseSubHeader(text)}
              />
              <div className="d-flex flex-row justify-content-between">
                <div className="w-75 mr-5">
                  <InputTextComponent
                    {...onlineCourseCode}
                    label="Code"
                    onInput={(text) => handleInputOnlineCourseCode(text)}
                    disabled={editMode}
                  />
                </div>
                <div className="w-100">
                  <InputPriceComponent
                    {...onlineCoursePrice}
                    label="Normal Price"
                    onInput={(price) => handleInputOnlineCoursePrice(price)}
                  />
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <Button
                color="success"
                className="w-100"
                style={{ height: 40 }}
                disabled={buttonDisabled()}
                onClick={editMode ? editOnlineCourse : createNewOnlineCourse}
              >
                {loadingStates
                  ? `Loading...${uploadProgress}%`
                  : `${editMode ? 'Edit' : 'Create'}`}
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default OnlineCourseForm;
