import React from 'react';
import { Button } from 'reactstrap';
import Loader from 'react-loader-spinner';

const LoadingButton = ({ onClick, isLoading = false, children, style }) => {
  if (isLoading) {
    return (
      <Button
        color="secondary"
        className="rounded-right"
        disabled
        style={style}
      >
        <Loader type="Oval" color="white" height={18} width="100%" />
      </Button>
    );
  }

  return (
    <Button
      color="success"
      className="rounded-right"
      onClick={onClick}
      style={{ backgroundColor: '#53b59f', ...style }}
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
