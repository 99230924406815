import React from 'react';
import { Form, FormFeedback, CustomInput, Label } from 'reactstrap';

const InputTrailerComponent = ({ trailerVideo, handleTrailerVideoFile }) => {
  return (
    <div className="d-flex flex-row justify-content-between">
      <div className="w-100 mr-5">
        <Form className="mb-2">
          <Label for="formTrailerVideo" className="text-gray font-weight-bold">
            Trailer Video (*Optional)
          </Label>
          <CustomInput
            id="formTrailerVideo"
            type="file"
            className="mb-1"
            valid={trailerVideo.valid}
            invalid={trailerVideo.invalid}
            label={trailerVideo.file.name}
            onChange={({ target }) => handleTrailerVideoFile(target.files[0])}
          />
          <FormFeedback invalid="true">*{trailerVideo.error}</FormFeedback>
        </Form>
      </div>
      <div className="w-75">
        <Label for="trailer-preview" className="text-gray font-weight-bold">
          Trailer Preview
        </Label>
        <div style={{ width: 440, height: 220 }} className="border rounded">
          <video
            id="trailer-preview"
            autoPlay
            muted
            loop
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
      </div>
    </div>
  );
};

export default InputTrailerComponent;
