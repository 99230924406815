import React, { useState, useEffect } from 'react';
import { Table, Card, CardBody, Button, Spinner } from 'reactstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CustomPagination from 'components/commons/CustomPagination';
import { useLocation, useHistory } from 'react-router';
import axios from 'supports/api';
import { FilterPrint } from '../components';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PrintTab = (props) => {
  const query = useQuery();
  const history = useHistory();
  const {
    active,
    click,
    setSelectedId,
    setFormTab,
    tujPenerimaan,
    finalFilterPrint,
    setFinalFilterPrint,
  } = props;
  const page = query.get('page');
  const [idLodaing, setIdLoading] = useState(null);
  const [loading, setLoading] = useState(false);

  const data = useSelector((state) => state.payment.printPayment);
  const countPrint = useSelector((state) => state.payment.countPrintPayment);

  useEffect(() => {
    if (!page || (Math.ceil(countPrint / 10) < page && countPrint !== 0)) {
      history.push('?page=1');
    }
  }, [page, countPrint, history]);

  const handlePrint = (id, isPrinted) => {
    setLoading(true);
    setIdLoading(id);
    axios
      .get(`/admin/print/payment-receipt/${id}`)
      .then(async () => {
        try {
          setLoading(false);
          setIdLoading(null);
          window.open(
            `${process.env.REACT_APP_BASE_URL}/admin/print/payment-receipt/${id}`,
          );
          if (!isPrinted) {
            await axios.patch(`/payment/update-isprinted`, { id });
          }
        } catch (err) {
          setLoading(false);
          setIdLoading(null);
          alert(err);
        }
      })
      .catch((err) => {
        setLoading(false);
        setIdLoading(null);
        alert(err);
      });
  };

  const renderTable = () => {
    return data?.map((val) => {
      const {
        id,
        receiptNumber,
        tujuanPenerimaan,
        nominal,
        accountNumber,
        namaPembayar,
        mutationDate,
        firstName,
        lastName,
      } = val;

      let isPrinted = 0;
      if (active === 'Re-Print') isPrinted = 1;

      return (
        <tr key={id}>
          <td>{receiptNumber || 'null'}</td>
          <td>{tujuanPenerimaan}</td>
          <td>Rp. {nominal.toLocaleString()}</td>
          <td>{accountNumber}</td>
          <td>{namaPembayar || `${firstName} ${lastName}`}</td>
          <td>{moment(mutationDate).format('DD MMM YYYY hh:mm:ss')}</td>
          <td className="text-center">
            <div className="d-flex flex-column align-items-center">
              <Button
                color="success"
                size="sm"
                style={{
                  width: '72px',
                  backgroundColor: 'rgb(50, 178, 128)',
                }}
                onClick={() => handlePrint(id, isPrinted)}
              >
                {loading && idLodaing === id ? (
                  <Spinner size="sm" color="white" />
                ) : (
                  active
                )}
              </Button>
              <Button
                className="mt-1"
                color="secondary"
                size="sm"
                style={{ width: '72px' }}
                onClick={() => {
                  click();
                  setSelectedId(id);
                  setFormTab(true);
                }}
              >
                Details
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <Card>
      <CardBody>
        <FilterPrint
          page={page}
          active={active}
          finalSearch={finalFilterPrint}
          setFinalSearch={setFinalFilterPrint}
          tujPenerimaan={tujPenerimaan}
        />
        <CustomPagination
          className="mt-5"
          pages={Math.ceil(countPrint / 10) || 1}
          active={page}
        />
        <Table striped bordered responsive hover>
          <thead
            className="font-weight-bold"
            style={{ fontSize: '14px', color: '#505050' }}
          >
            <tr>
              <th>Payment Receipt</th>
              <th>Tujuan Penerimaan</th>
              <th>Nominal</th>
              <th>Account Number</th>
              <th>Receive From</th>
              <th>Mutation Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default PrintTab;
