import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Nav, TabContent, TabPane, NavLink, NavItem } from 'reactstrap';
import axios from 'supports/api';
import { ListTab, DataTab, ApprovalTab } from './tabs';
import {
  fetchListPenerimaan,
  fetchApprovalPenerimaan,
  filterListByPym,
  filterListByTujPenerimaan,
  filterListPenerimaan,
  filterApprovalByTujPenerimaan,
  filterApprovalPenerimaan,
  filterApprovalByPym,
  fetchPrintPayments,
  fetchListSuccess,
  fetchApprovalSuccess,
  fetchPrintPaymentsSuccess,
  setPaymentReceipt,
  setTujuanPenerimaan,
  setNamaPembayar,
} from '../../../redux/modules/payment';
import './payment.css';
import PrintTab from './tabs/PrintTab';

const PaymentPage = (props) => {
  const tabAccess = ['Reception'];
  const submenu = ['List', 'Data', 'Approval', 'Print', 'Re-Print'];
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get('page');
  const [activeTab, setActiveTab] = useState(tabAccess[0]);
  const [activeSubmenu, setActiveSubmenu] = useState(submenu[0]);
  const [selectedId, setSelectedId] = useState(null);
  const [fromTab, setFromTab] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [activePrint, setActivePrint] = useState('');
  const [tujPenerimaan, setTujPenerimaan] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [bank, setBank] = useState([]);
  const [finalFilterList, setFinalFilterList] = useState({
    pymReceipt: '',
    tujPenerimaan: 'All',
  });
  const [finalFilterApproval, setFinalFilterApproval] = useState({
    pymReceiptApp: '',
    tujPenerimaanApp: 'All',
  });
  const [finalFilterPrint, setFinalFilterPrint] = useState({
    paymentReceipt: '',
    tujuanPenerimaan: '',
    namaPembayar: '',
  });

  const dispatch = useDispatch();
  const gPaymentReceipt = useSelector(({ payment }) => payment.paymentReceipt);
  const gTujuanPenerimaan = useSelector(
    ({ payment }) => payment.tujuanPenerimaan,
  );
  const gNamaPembayar = useSelector(({ payment }) => payment.namaPembayar);

  // rendering page for first time (1)
  useEffect(() => {
    if (activeSubmenu === 'List') {
      props.history.replace(`?page=${1}&tab=List`);
    }
  }, [activeSubmenu, props.history]);

  // rendering page for first time (2)
  useEffect(() => {
    axios
      .get(`/masterTujuanPenerimaan/getAll`)
      .then((res) => {
        setTujPenerimaan(res.data.result);
      })
      .catch((err) => alert(`Terjadi kesalahan system. ${err}`));
  }, []);

  const renderingData = useCallback(() => {
    const { pymReceipt, tujPenerimaan } = finalFilterList;
    const { pymReceiptApp, tujPenerimaanApp } = finalFilterApproval;
    if (activeSubmenu === 'List') {
      dispatch(fetchApprovalSuccess({ countApprovalPym: 0 }));
      dispatch(fetchPrintPaymentsSuccess({ countPrintPayment: 0 }));
      if (!pymReceipt && tujPenerimaan === 'All') {
        dispatch(fetchListPenerimaan(page));
      } else if (pymReceipt && tujPenerimaan !== 'All') {
        dispatch(filterListPenerimaan(page, pymReceipt, tujPenerimaan));
      } else if (pymReceipt && tujPenerimaan === 'All') {
        dispatch(filterListByPym(page, pymReceipt));
      } else if (!pymReceipt && tujPenerimaan !== 'All') {
        dispatch(filterListByTujPenerimaan(page, tujPenerimaan));
      }
    } else if (activeSubmenu === 'Approval') {
      dispatch(fetchListSuccess({ countListPym: 0 }));
      dispatch(fetchPrintPaymentsSuccess({ countPrintPayment: 0 }));
      if (!pymReceiptApp && tujPenerimaanApp === 'All') {
        dispatch(fetchApprovalPenerimaan(page));
      } else if (pymReceiptApp && tujPenerimaanApp !== 'All') {
        dispatch(
          filterApprovalPenerimaan(page, pymReceiptApp, tujPenerimaanApp),
        );
      } else if (pymReceiptApp && tujPenerimaanApp === 'All') {
        dispatch(filterApprovalByPym(page, pymReceiptApp));
      } else {
        dispatch(filterApprovalByTujPenerimaan(page, tujPenerimaanApp));
      }
    } else if (activeSubmenu === 'Data') {
      // fetch bank
      axios
        .get(`/bank/all`)
        .then((res) => setBank(res.data.result))
        .catch((err) => alert(`Terjadi kesalahan system. ${err}`));

      // fetch payment method
      axios
        .get(`/transaction/payment-methods`)
        .then((res) => {
          setPaymentMethod(res.data.result);
        })
        .catch((err) => alert(`Terjadi kesalahan system. ${err}`));
    } else if (activeSubmenu === 'Print') {
      setActivePrint('Print');
      dispatch(fetchListSuccess({ countListPym: 0 }));
      dispatch(fetchApprovalSuccess({ countApprovalPym: 0 }));
      dispatch(
        fetchPrintPayments(
          page,
          0,
          gPaymentReceipt, // from global state
          gTujuanPenerimaan, // from global state
          gNamaPembayar, // from global state
        ),
      );
    } else {
      setActivePrint('Re-Print');
      dispatch(fetchListSuccess({ countListPym: 0 }));
      dispatch(fetchApprovalSuccess({ countApprovalPym: 0 }));
      dispatch(
        fetchPrintPayments(
          page,
          1,
          gPaymentReceipt, // from global state
          gTujuanPenerimaan, // from global state
          gNamaPembayar, // from global state
        ),
      );
    }
  }, [
    dispatch,
    activeSubmenu,
    page,
    finalFilterList,
    finalFilterApproval,
    gPaymentReceipt,
    gTujuanPenerimaan,
    gNamaPembayar,
  ]);

  useEffect(() => {
    renderingData();
  }, [renderingData]);

  const toggleTabs = (tab) => (activeTab !== tab ? setActiveTab(tab) : null);
  const toggleSub = (sub) => {
    if (activeSubmenu !== sub) setActiveSubmenu(sub);
    props.history.replace(`?page=${1}&tab=${sub.toString()}`);

    if (sub === 'Re-Print' || sub === 'Print') {
      dispatch(setPaymentReceipt(''));
      dispatch(setTujuanPenerimaan(''));
      dispatch(setNamaPembayar(''));
      setFinalFilterPrint({
        paymentReceipt: '',
        tujuanPenerimaan: '',
        namaPembayar: '',
      });
    }
  };

  const renderTabs = () => {
    return tabAccess.map((tabName) => {
      return (
        <NavItem key={tabName}>
          <NavLink
            active={activeTab === tabName}
            onClick={() => toggleTabs(tabName)}
          >
            {tabName}
          </NavLink>
        </NavItem>
      );
    });
  };

  const renderSubTabs = () => {
    return submenu.map((tab) => {
      return (
        <NavItem key={tab}>
          <NavLink
            active={activeSubmenu === tab}
            onClick={() => toggleSub(tab)}
          >
            {tab}
          </NavLink>
        </NavItem>
      );
    });
  };

  const containerTab = () => {
    return (
      <>
        <Nav tabs>{renderTabs()} </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="Reception">
            <Nav tabs>{renderSubTabs()}</Nav>
            <TabContent activeTab={activeSubmenu}>
              <TabPane tabId="List">
                <ListTab
                  click={() => toggleSub('Data')}
                  tujuanPenerimaan={tujPenerimaan}
                  setSelectedId={setSelectedId}
                  setFromTab={setFromTab}
                  setEditShow={setEditShow}
                  activeSubmenu={activeSubmenu}
                  finalFilterList={finalFilterList}
                  setFinalFilterList={setFinalFilterList}
                />
              </TabPane>
              <TabPane tabId="Data">
                <DataTab
                  tujuanPenerimaan={tujPenerimaan}
                  paymentMethod={paymentMethod}
                  bank={bank}
                  selectedId={selectedId}
                  fromTab={fromTab}
                  setFromTab={setFromTab}
                  setSelectedId={setSelectedId}
                  editShow={editShow}
                  setEditShow={setEditShow}
                />
              </TabPane>
              <TabPane tabId="Approval">
                <ApprovalTab
                  click={() => toggleSub('Data')}
                  tujuanPenerimaan={tujPenerimaan}
                  setSelectedId={setSelectedId}
                  setFromTab={setFromTab}
                  finalFilterApproval={finalFilterApproval}
                  setFinalFilterApproval={setFinalFilterApproval}
                />
              </TabPane>
              <TabPane tabId="Print">
                <PrintTab
                  click={() => toggleSub('Data')}
                  tujPenerimaan={tujPenerimaan}
                  active={activePrint}
                  setSelectedId={setSelectedId}
                  setFormTab={setFromTab}
                  isPrinted={0}
                  finalFilterPrint={finalFilterPrint}
                  setFinalFilterPrint={setFinalFilterPrint}
                />
              </TabPane>
              <TabPane tabId="Re-Print">
                <PrintTab
                  tujPenerimaan={tujPenerimaan}
                  active={activePrint}
                  click={() => toggleSub('Data')}
                  setSelectedId={setSelectedId}
                  setFormTab={setFromTab}
                  isPrinted={1}
                  finalFilterPrint={finalFilterPrint}
                  setFinalFilterPrint={setFinalFilterPrint}
                />
              </TabPane>
            </TabContent>
          </TabPane>
        </TabContent>
      </>
    );
  };

  return (
    <div className="white-box py-2 container-fluid">
      <div className="row py-4">
        <div className="col-12 offset-md-1 col-md-10 py-3">
          <div>
            <div className="line-green mb-2" />
            <div className="general-title text-gray">Payment</div>
          </div>
        </div>
        <div className="col-12 offset-md-1 col-md-10 py-3">
          {containerTab()}
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
