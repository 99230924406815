import React, { useCallback, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import axios from 'supports/api';
import LivestreamMaterialModal from './LivestreamMaterialModal';

const LivestreamMaterialListPage = () => {
  const location = useLocation();

  const { programLivestreamSessionId } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingDeleteId, setLoadingDelete] = useState(0);
  const [livestreamSessionData, setLivestreamSessionData] = useState({});
  const [livestreamMaterialList, setLivestreamMaterialList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchSessionData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/v2/program-livestream/session/${programLivestreamSessionId}`,
      );
      const { materials } = data?.data;
      setLivestreamSessionData(data.data);
      setLivestreamMaterialList(materials);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(err);
    }
  }, [programLivestreamSessionId]);

  useEffect(() => {
    fetchSessionData();
  }, [fetchSessionData]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deleteProgramLivestreamMaterial = async (id) => {
    setLoadingDelete(id);
    try {
      await axios.delete(`/v2/program-livestream/material/${id}`);
      setLoadingDelete(0);
      await fetchSessionData();
    } catch (err) {
      setLoadingDelete(0);
      console.log(err);
      alert(err);
    }
  };

  const confirmDeleteMaterial = (id) => {
    if (window.confirm('Apakah anda yakin untuk menghapus data materi ini?')) {
      deleteProgramLivestreamMaterial(id);
    }
  };

  const LivestreamMaterialList = () => {
    if (livestreamMaterialList.length) {
      return livestreamMaterialList.map((session) => {
        const { id, title, filePath } = session;
        return (
          <div
            key={id}
            className="d-flex flex-row my-3 py-2 border justify-content-between"
            style={{ borderRadius: '6px' }}
          >
            <div className="col-10">
              <div className="row">
                <div className="col-3">Material Title</div>
                <div className="col-9">: {title}</div>
              </div>
              <div className="row">
                <div className="col-3">Material Link</div>
                <div className="col-9">
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}${filePath}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    : Open File
                  </a>
                </div>
              </div>
            </div>
            <div className="col-2">
              <Button
                onClick={() => confirmDeleteMaterial(id)}
                color="danger"
                className="mb-1 w-100"
                size="sm"
              >
                {loadingDeleteId === id && (
                  <div
                    className="spinner-border spinner-border-sm mr-2 text-center"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                Delete
              </Button>
            </div>
          </div>
        );
      });
    }
    return (
      <div className="d-flex justify-content-center pt-5">
        <h4>NO DATA AVAILABLE</h4>
      </div>
    );
  };

  return (
    <div>
      <div
        className="white-box container py-2"
        style={{ minHeight: '100vh', marginTop: '40px' }}
      >
        <div className="mt-2">
          <Link
            to={`${location?.pathname.slice(
              0,
              location?.pathname.lastIndexOf(`/${programLivestreamSessionId}`),
            )}`}
            className="mt-auto"
          >
            <Button color="danger">{'<'} Back</Button>
          </Link>
        </div>
        <div className="d-flex flex-row justify-content-between mt-4 mb-5">
          <div
            className="font-weight-bold"
            style={{ fontSize: '26px', color: '#505050' }}
          >
            {livestreamSessionData.title} - Material Data
          </div>
          <Button
            className="float-right mt-auto"
            onClick={() => {
              toggleModal();
            }}
          >
            + Upload Material
          </Button>
        </div>
        {loading ? (
          <div
            className="d-flex justify-content-center"
            style={{ minHeight: '100vh' }}
          >
            <Loader type="ThreeDots" color="black" />
          </div>
        ) : (
          <LivestreamMaterialList />
        )}
      </div>
      <LivestreamMaterialModal
        isModalOpen={isModalOpen}
        programLivestreamSessionId={programLivestreamSessionId}
        refresh={fetchSessionData}
        toggle={toggleModal}
      />
    </div>
  );
};

export default LivestreamMaterialListPage;
