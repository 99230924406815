import React from 'react';
import { InputGroup, InputGroupAddon } from 'reactstrap';

const InputPriceComponent = ({ displayedPrice, onInput, label }) => {
  const allowNumbersOnly = (e) => {
    const code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className="tag-gray dker font-weight-bold mb-2">{label}: </div>
      <InputGroup id="nominal">
        <InputGroupAddon addonType="prepend">Rp.</InputGroupAddon>
        <input
          type="text"
          className="form-control"
          id="newVoucherNominal"
          placeholder="500,000"
          value={displayedPrice}
          onKeyPress={allowNumbersOnly}
          onChange={({ target }) => onInput(target.value)}
        />
      </InputGroup>
    </div>
  );
};

export default InputPriceComponent;
