import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, FormGroup, Input } from 'reactstrap';
import CustomPagination from 'components/commons/CustomPagination';
import { useLocation } from 'react-router';
import Loader from 'react-loader-spinner';
import axios from 'supports/api';
import { Link } from 'react-router-dom';

const BatchList = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = params.get('page') || 1;
  const itemsPerPage = 10;
  const [searchKeyword, setSearchKeyword] = useState('');

  const [listBatch, setListBatch] = useState([]);
  const [batchFilter, setBatchFilter] = useState([]);
  const fetchListBatch = async () => {
    try {
      const programBatch = await axios.get(`/v2/program?studyMethodId=2`);
      setListBatch(programBatch.data.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchListBatch();
  }, []);

  const inputSearchKeyword = (text) => {
    setSearchKeyword(text);
  };

  const fetchListBatchFiltered = useCallback(() => {
    const batchData = listBatch.filter((batch) => {
      return (
        batch.code.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        batch.programHeader.name
          .toLowerCase()
          .includes(searchKeyword.toLowerCase()) ||
        batch.programHeader.onlineCourse?.title
          .toLowerCase()
          .includes(searchKeyword.toLowerCase())
      );
    });
    setBatchFilter(batchData);
  }, [listBatch, searchKeyword]);

  useEffect(() => {
    fetchListBatchFiltered();
  }, [fetchListBatchFiltered]);

  const RenderTable = () => {
    let batchData = listBatch;
    if (batchFilter.length) batchData = batchFilter;
    if (searchKeyword && !batchFilter.length) batchData = [];
    const slicedBatchList = batchData.slice(
      (page - 1) * itemsPerPage,
      (page - 1) * itemsPerPage + itemsPerPage,
    );

    return slicedBatchList.map((batch, index) => {
      return (
        <tr className="d-flex" key={batch.id}>
          <td className="col-2">{(page - 1) * itemsPerPage + index + 1}</td>
          <td className="col-4">{batch.code}</td>
          <td className="col-4">{batch.programHeader.name}</td>
          <td className="col-2 forum-link">
            {batch.id && (
              <Button color="info" size="sm">
                <Link to={`${batch.id}/student`}>Select</Link>
              </Button>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="body-app">
      <div className="container">
        {loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
            <Loader type="ThreeDots" color="#53b59f" height={40} width={40} />
          </div>
        ) : (
          <div
            className="p-3"
            style={{
              backgroundColor: '#ffffff',
              height: '100%',
              borderRadius: '6px',
              boxShadow: '0 20px 40px 0 rgba(102, 102, 102, 0.16)',
            }}
          >
            <div className="row d-flex p-3">
              <div
                className="font-weight-bold ml-3 mb-3"
                style={{ fontSize: '25px', color: '#505050' }}
              >
                Livestream Mentoring Session
              </div>
              <div className="col-12">
                <h5 className="text-center">Select Batch</h5>
              </div>
            </div>
            <div className="col-12">
              <div className="col-12 pr-0 d-flex justify-content-end">
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="Search by batch name"
                    onChange={(e) => inputSearchKeyword(e.currentTarget.value)}
                  />
                </FormGroup>
              </div>
              <Table size="sm" striped bordered responsive hover>
                <thead
                  className="font-weight-bold"
                  style={{ fontSize: '14px', color: '#505050' }}
                >
                  <tr className="d-flex">
                    <th className="col-2">No</th>
                    <th className="col-4">BATCH</th>
                    <th className="col-4">PROGRAM NAME</th>
                    <th className="col-2">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <RenderTable />
                </tbody>
              </Table>
              <div className="col-12 d-flex justify-content-center">
                <CustomPagination
                  pages={
                    Math.ceil(
                      (batchFilter.length || listBatch.length) / itemsPerPage,
                    ) || 1
                  }
                  active={page}
                />
              </div>
            </div>
          </div>
        )}{' '}
      </div>
    </div>
  );
};

export default BatchList;
