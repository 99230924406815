import React, { Component } from 'react';
import { Card, CardTitle, CardText, Button, Badge } from 'reactstrap';
import moment from 'moment';
import axios from 'supports/api';
import { Link } from 'react-router-dom';
import AdminScholarshipAlbum from './AdminScholarshipAlbum';

class AdminScholarshipCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  onPublishClick = () => {
    axios
      .put(`/scholarship/publish/${this.props.item.id}`)
      .then((res) => {
        this.props.refresh();
        alert('Succesfully published!');
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  onDeleteClick = () => {
    if (window.confirm(`Are you sure?`)) {
      axios
        .delete(`/scholarship/delete/${this.props.item.id}`)
        .then((res) => {
          this.props.refresh();
          alert('Succesfully delete!');
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  onStopClick = () => {
    axios
      .put(`/scholarship/stop/${this.props.item.id}`)
      .then((res) => {
        this.props.refresh();
        alert('Succesfully stopped');
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  renderBadge = () => {
    if (this.props.item.isLive) {
      return <Badge style={{ backgroundColor: '#53b59f' }}>Live</Badge>;
    }
    return <Badge color="secondary">Draft</Badge>;
  };

  renderButtons = () => {
    const { isLive, registrationEndDate, id } = this.props.item;
    if (isLive && moment(registrationEndDate) > moment()) {
      return (
        <div className="col-12">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Link to={`scholarship-edit?id=${id}&purpose=edit`}>
                <Button
                  className="font-10 font-sm-8 font-md-12"
                  color="primary mx-1"
                >
                  Edit
                </Button>
              </Link>
              <Button
                className="font-10 font-sm-8 font-md-12"
                color="primary mx-1"
                onClick={() => this.toggleModal()}
              >
                Album
              </Button>
              <Link to={`scholarship-edit?id=${id}&purpose=copy`}>
                <Button
                  className="font-10 font-sm-8 font-md-12"
                  color="warning mx-1"
                >
                  Copy
                </Button>
              </Link>
            </div>
            <div>
              <Link to={`/scholarship-preview?id=${id}`} target="_blank">
                <Button
                  className="font-10 font-sm-8 font-md-12"
                  color="info mx-1"
                >
                  Preview
                </Button>
              </Link>
              <Button
                className="font-10 font-sm-8 font-md-12"
                color="danger mx-1"
                onClick={() => {
                  this.onStopClick();
                }}
              >
                Unpublish
              </Button>
            </div>
          </div>
        </div>
      );
    }
    if (!isLive) {
      return (
        <div className="col-12">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Link to={`scholarship-edit?id=${id}&purpose=edit`}>
                <Button
                  className="font-10 font-sm-8 font-md-12"
                  color="primary mx-1"
                >
                  Edit
                </Button>
              </Link>
              <Button
                className="font-10 font-sm-8 font-md-12"
                color="primary mx-1"
                onClick={() => this.toggleModal()}
              >
                Album
              </Button>
              <Link to={`scholarship-edit?id=${id}&purpose=copy`}>
                <Button
                  className="font-10 font-sm-8 font-md-12"
                  color="warning mx-1"
                >
                  Copy
                </Button>
              </Link>
              <Button
                className="font-10 font-sm-8 font-md-12"
                color="danger mx-1"
                onClick={() => this.onDeleteClick()}
              >
                Delete
              </Button>
            </div>
            <div>
              <Link to={`/scholarship-preview?id=${id}`} target="_blank">
                <Button
                  className="font-10 font-sm-8 font-md-12"
                  color="info mx-1"
                >
                  Preview
                </Button>
              </Link>
              <Button
                className="font-10 font-sm-8 font-md-12"
                color="success mx-1"
                onClick={() => {
                  this.onPublishClick();
                }}
              >
                Publish
              </Button>
            </div>
          </div>
        </div>
      );
    }
    if (isLive && moment(registrationEndDate) < moment()) {
      return (
        <div className="col-12">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div>
              <Link to={`scholarship-edit?id=${id}&purpose=copy`}>
                <Button
                  className="font-10 font-sm-8 font-md-12"
                  color="warning mx-1"
                >
                  Copy
                </Button>
              </Link>
              <Button
                className="font-10 font-sm-8 font-md-12"
                color="danger mx-1"
                onClick={() => this.onDeleteClick()}
              >
                Delete
              </Button>
            </div>
            <div>
              <Link to={`/scholarship-preview?id=${id}`} target="_blank">
                <Button
                  className="font-10 font-sm-8 font-md-12"
                  color="info mx-1"
                >
                  Preview
                </Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="py-2">
        <Card tag="div" body outline style={{ borderColor: '#53b59f' }}>
          <CardTitle tag="div">
            <div className="row">
              <div className="col-8 font-weight-bold link font-14 font-sm-12 font-md-16">
                Name: {this.props.item.name}
              </div>
              <div className="col-4">
                <div className="float-right">
                  {this.renderBadge()} <Badge>Scholarship</Badge>
                </div>
              </div>
            </div>
          </CardTitle>
          <CardText tag="div">
            <div className="row">
              <div className="col-5 font-12 font-sm-10 font-md-14">
                <div>Provider: {this.props.item.provider}</div>
                <div>
                  Registration End Date:{' '}
                  {moment(this.props.item.registrationEndDate).format(
                    'DD MMM YYYY',
                  )}
                </div>
              </div>
              <div className="col-5 font-12 font-sm-10 font-md-14">
                <div>
                  Program: {this.props.item.program.programHeader.name}{' '}
                  <Badge>
                    {
                      this.props.item.program.programHeader.program_category
                        .name
                    }
                  </Badge>
                </div>
                <div>Code: {this.props.item.program.code}</div>
                <div>
                  Tanggal:{' '}
                  {moment(this.props.item.program.startDate)
                    .format('DD MMM')
                    .toString()}{' '}
                  -{' '}
                  {moment(this.props.item.program.endDate)
                    .format('DD MMM YYYY')
                    .toString()}
                </div>
                <div>Branch: {this.props.item.program.branch.name}</div>
              </div>
            </div>
          </CardText>
          <div className="row mt-4">{this.renderButtons()}</div>
        </Card>
        <AdminScholarshipAlbum
          key={this.props.item.id}
          id={this.props.item.id}
          toggle={this.toggleModal}
          isOpen={this.state.modal}
        />
      </div>
    );
  }
}

export default AdminScholarshipCard;
