import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import transactions from './transactions';
import trxSearch from './trxSearch';
import programSales from './programSales';
import payment from './payment';
import purchaseOrder from './purchaseOrder';
import salesReports from './salesReports';
import programSalesReports from './programSalesReports';
import glJournal from './glJournal';
import poSearch from './poSearch';
import bankReports from './bankReport';
import studentData from './studentData';
import studentReport from './studentReport';
import trxUser from './trxUser';
import studentBirthday from './studentBirthday';
import onlineCourse from './onlineCourse';

export default combineReducers({
  auth,
  transactions,
  trxSearch,
  trxUser,
  programSales,
  payment,
  purchaseOrder,
  salesReports,
  programSalesReports,
  glJournal,
  poSearch,
  bankReports,
  studentData,
  studentReport,
  studentBirthday,
  onlineCourse,
});
