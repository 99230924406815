import React, { Component } from 'react';
import { ModalHeader, ModalBody, Modal, ModalFooter, Button } from 'reactstrap';
import axios from 'supports/api';
import { API_URL_1 } from '../supports/api-url/apiurl';
import 'react-datepicker/dist/react-datepicker.css';

class AdminScholarshipAlbum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      album: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    axios
      .get(`/scholarship/album/${this.props.id}`)
      .then((res) => {
        this.setState({ album: res.data.result });
      })
      .catch((err) => {
        console.log(err);
        alert('Get data error. Contact Administrator');
      });
  }

  addImage(value) {
    const formData = new FormData();
    formData.append('image', value.files[0]);
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    axios
      .post(`/scholarship/addAlbum/${this.props.id}`, formData, headers)
      .then((res) => {
        alert('Add image successful');
        this.getData();
      })
      .catch((err) => {
        alert('Add image failed');
      });
  }

  editImage(value, id) {
    const formData = new FormData();
    formData.append('image', value.files[0]);
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    axios
      .put(`/scholarship/editAlbum/${id}`, formData, headers)
      .then((res) => {
        alert('Edit image successful');
        this.getData();
      })
      .catch((err) => {
        alert('Edit image failed');
      });
  }

  deleteImage(id) {
    axios
      .delete(`/scholarship/deleteAlbum/${id}`)
      .then((res) => {
        alert('Delete Image successful');
        this.getData();
      })
      .catch((err) => {
        alert('Delete Image failed');
      });
  }

  renderImages(vaue) {
    const arrJSX = [];
    this.state.album.forEach((item, index) => {
      console.log(item);
      arrJSX.push(
        <div className="col-4 py-2">
          <div
            style={{ backgroundImage: `url('${API_URL_1}${item.image}')` }}
            className="scholarshipAlbum"
          >
            <div className="content">
              <div className="py-2 contentButton">
                <label
                  htmlFor={`editImage${item.id}`}
                  className="btn btn-warning"
                >
                  Edit
                </label>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  id={`editImage${item.id}`}
                  name={`editImage${item.id}`}
                  onChange={() =>
                    this.editImage(
                      document.getElementById(`editImage${item.id}`),
                      item.id,
                    )
                  }
                />
              </div>
              <div className="py-2 contentButton">
                <Button
                  color="danger"
                  onClick={() => this.deleteImage(item.id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>,
      );
    });
    if (this.state.album.length < 6) {
      arrJSX.push(
        <div className="col-4 py-2">
          <label
            htmlFor={`addImage${this.props.id}`}
            className="scholarshipAddImage"
          >
            <div className="text">Add</div>
          </label>
          <input
            style={{ display: 'none' }}
            type="file"
            id={`addImage${this.props.id}`}
            name="addImage"
            onChange={() =>
              this.addImage(document.getElementById(`addImage${this.props.id}`))
            }
          />
        </div>,
      );
    }
    return arrJSX;
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        size="lg"
      >
        <ModalHeader>Manage Album</ModalHeader>
        <ModalBody>
          <div className="container-fluid">
            <div className="row py-2">{this.renderImages()}</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AdminScholarshipAlbum;
