import React from 'react';

const TLabel = ({ status }) => {
  let color = 'ffa630';
  // const text = status;
  switch (status) {
    case 'Pending Payment':
      color = '#ffa630';
      break;
    case 'Pending Confirmation':
      color = '#0197f6';
      break;
    case 'Paid':
      color = '#32b280';
      break;
    case 'Cancelled':
      color = '#ef767a';
      break;
    default:
      color = '#ffa630';
  }

  return (
    <div
      className="px-2 font-weight-bold"
      style={{
        color: '#ffffff',
        backgroundColor: color,
        height: '100%',
        borderRadius: '3px',
        textAlign: 'center',
        fontSize: '12px',
      }}
    >
      {status}
    </div>
  );
};

export default TLabel;
