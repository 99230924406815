import React from 'react';

const BackgroundTable = ({ children, style, ...props }) => {
  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        height: '100%',
        width: '100%',
        borderRadius: '6px',
        boxShadow: '0 20px 40px 0 rgba(102, 102, 102, 0.16)',
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default BackgroundTable;
