import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter } from 'reactstrap';
import { API_URL_1 } from '../../supports/api-url/apiurl';

const ProgramCard = ({ id, category, name, image, pic }) => {
  return (
    <div className="col-12 col-md-6 col-lg-4 py-1">
      <Card style={{ minHeight: '160px' }}>
        <CardBody>
          <div className="d-flex flex-row">
            <div className="pr-3">
              <img
                src={API_URL_1 + image}
                alt={image || 'no image'}
                style={{ maxWidth: '70px' }}
              />
            </div>
            <div>
              <div className="text-info" style={{ fontSize: 14 }}>
                {category}
              </div>
              <div className="font-weight-bold text-gray">{name}</div>
            </div>
          </div>
        </CardBody>
        <CardFooter className="py-1 pr-1">
          <div className="d-flex flex-row justify-content-between">
            <div className="mt-1">PIC: {pic ? pic.name : '---'}</div>
            <div className="">
              {/* <Button color="info" className="mr-2">Edit</Button> */}
              <Link to={`program-details?id=${id}`}>
                <Button color="info">Info</Button>
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ProgramCard;
