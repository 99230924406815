import CustomPagination from 'components/commons/CustomPagination';
import React, { useCallback, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import axios from 'supports/api';
import qs from 'query-string';
import LivestreamModal from './LivesteramModal';
import DuplicateLivestreamModal from './DuplicateLivestreamModal';

const LivestreamListPage = () => {
  const [loading, setLoading] = useState(false);
  const [livestreamListData, setLivestreamListData] = useState([]);
  const [filteredLivestreamListData, setFilteredLivestreamListData] = useState(
    [],
  );
  const [selectedLivestream, setSelectedLivestream] = useState({});
  const [filterInput, setFilterInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDuplicateOpen, setIsModalDuplicateOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const queryParam = qs.parse(location.search);
  const { page } = queryParam;

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get('/v2/program-livestream/');
      setLivestreamListData(data.data);
      setFilteredLivestreamListData(data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      alert(err);
    }
  };

  const filterData = useCallback(
    (value) => {
      if (value) {
        const loweredTextField = value.toLowerCase();
        const filtered = livestreamListData.filter((referralCode) => {
          const { title } = referralCode;
          return title.toLowerCase().indexOf(loweredTextField) > -1;
        });
        setFilteredLivestreamListData(filtered);
      }
    },
    [livestreamListData],
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    filterData(filterInput);
  }, [filterData, filterInput]);

  useEffect(() => {
    if (!qs?.parse(location.search)?.page) {
      const newQueryParam = {
        ...qs.parse(location.search),
        page: 1,
      };
      history.push(`?${qs.stringify(newQueryParam)}`);
    }
  }, [history, location.search]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleDuplicateModal = () => {
    setIsModalDuplicateOpen(!isModalDuplicateOpen);
  };

  const LivestreamList = () => {
    const sliced = filteredLivestreamListData.slice(
      (page - 1) * 10,
      (page - 1) * 10 + 10,
    );
    return sliced.map((livestream) => {
      const { id, title, description } = livestream;
      return (
        <div
          key={id}
          className="d-flex flex-row my-3 py-2 border justify-content-between"
          style={{ borderRadius: '6px' }}
        >
          <div className="col-10">
            <div className="row">
              <div className="col-3">Title</div>
              <div className="col-9">: {title}</div>
            </div>
            <div className="row">
              <div className="col-3">Description</div>
              <div className="col-9">: {description}</div>
            </div>
          </div>
          <div className="col-2">
            <Button
              onClick={() => {
                setSelectedLivestream(livestream);
                toggleModal();
              }}
              color="primary"
              className="mb-1 w-100"
              size="sm"
            >
              Details
            </Button>
            <Link to={`${location.pathname}/${id}/module`}>
              <Button
                color="success"
                size="sm"
                className=" mb-1 w-100 font-weight-bold"
              >
                Modules
              </Button>
            </Link>
            <Button
              onClick={() => {
                setSelectedLivestream(livestream);
                toggleDuplicateModal();
              }}
              color="warning"
              className="mb-1 w-100"
              size="sm"
            >
              Duplicate
            </Button>
          </div>
        </div>
      );
    });
  };

  const filterCard = () => {
    return (
      <Card className="my-4">
        <CardBody>
          <div className="container-fluid white-box">
            <div className="row">
              <div className="col-5 my-auto">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Search: </InputGroupText>
                  </InputGroupAddon>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Title"
                    value={filterInput}
                    onChange={(e) => setFilterInput(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  return (
    <div>
      <div
        className="white-box container py-2"
        style={{ minHeight: '100vh', marginTop: '40px' }}
      >
        <div className="d-flex flex-row justify-content-between mb-2">
          <div
            className="font-weight-bold"
            style={{ fontSize: '32px', color: '#505050' }}
          >
            Livestream List
          </div>
          <Button
            className="float-right mt-auto"
            onClick={() => {
              toggleModal();
              setSelectedLivestream({});
            }}
          >
            + Create Livestream
          </Button>
        </div>
        {filterCard()}
        <CustomPagination
          pages={Math.ceil(filteredLivestreamListData.length / 10) || 1}
          active={page}
        />
        {loading ? (
          <div
            className="d-flex justify-content-center"
            style={{ minHeight: '100vh' }}
          >
            <Loader type="ThreeDots" color="black" />
          </div>
        ) : (
          <LivestreamList />
        )}
        <CustomPagination
          pages={Math.ceil(filteredLivestreamListData.length / 10) || 1}
          active={page}
        />
      </div>
      <LivestreamModal
        isModalOpen={isModalOpen}
        toggle={toggleModal}
        selectedLivestream={selectedLivestream}
        refresh={fetchData}
      />
      <DuplicateLivestreamModal
        isOpen={isModalDuplicateOpen}
        toggle={toggleDuplicateModal}
        livestream={selectedLivestream}
        refetch={fetchData}
      />
    </div>
  );
};

export default LivestreamListPage;
