import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Collapse } from 'reactstrap';
import Loader from 'react-loader-spinner';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import {
  fetchReports,
  initialFetch,
  filterReports,
} from 'redux/modules/salesReports';
import Axios from 'supports/api';
import SalesReportTable from './SalesReportTable';

import '../style.css';

const date = new Date();

const NewSalesReportPage = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [fromDate, setFromDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [toDate, setToDate] = useState(date);
  const [category, setCategory] = useState('');
  const [productHeader, setProductHeader] = useState('');
  const [productItem, setProductItem] = useState('');
  const [programHeader, setProgramHeader] = useState('');
  const [branch, setBranch] = useState('');
  const [admin, setAdmin] = useState('');
  const [status, setStatus] = useState('paid');

  const { filteredData, loading, groupedData, options, data } = useSelector(
    (state) => state.salesReports,
  );

  const {
    programHeaders,
    serviceHeaders,
    onlineCourseHeaders,
    branches,
    serviceItems,
    programNames,
    subscriptions,
    alumniEventProgramCategories,
  } = options;

  const [onlineCourseItems, setOnlineCourseItems] = useState([]);

  useEffect(() => {
    dispatch(initialFetch());
  }, [dispatch]);

  useEffect(() => {
    if (data.length) {
      dispatch(
        filterReports({
          toDate,
          fromDate,
          category,
          productHeader,
          productItem,
          programHeader,
          admin,
          status,
          branch,
        }),
      );
    }
  }, [
    dispatch,
    toDate,
    fromDate,
    category,
    productHeader,
    productItem,
    programHeader,
    admin,
    data,
    status,
    branch,
  ]);

  useEffect(() => {
    if (
      category === 'Online Course' &&
      filteredData?.length &&
      productHeader === ''
    ) {
      const result = [];
      let isDuplicate = false;
      filteredData.forEach((item) => {
        if (item.onlineCourseSales) {
          item.onlineCourseSales.forEach((sales) => {
            result.forEach((res) => {
              if (res.online_courseId === sales.online_courseId)
                isDuplicate = true;
            });
            if (!isDuplicate) result.push(sales);
          });
        }
      });
      setOnlineCourseItems(result);
    }
  }, [filteredData, category, productHeader]);

  const handleFetch = () => {
    dispatch(
      fetchReports({
        toDate,
        fromDate,
        category,
        productHeader,
        productItem,
        programHeader,
        admin,
        status,
        branch,
      }),
    );
  };

  const renderProducutHeaders = () => {
    let jsx = [];
    switch (category) {
      case 'Program':
        jsx = programHeaders.map((progHeader) => {
          return (
            <option key={progHeader.id} value={progHeader.id}>
              {progHeader.name}
            </option>
          );
        });
        break;
      case 'Service':
        jsx = serviceHeaders.map((serviceHeader) => {
          return (
            <option key={serviceHeader.id} value={serviceHeader.id}>
              {serviceHeader.name}
            </option>
          );
        });
        break;
      case 'Online Course':
        jsx = onlineCourseHeaders.map((onlineCourseHeader) => {
          return (
            <option key={onlineCourseHeader.id} value={onlineCourseHeader.id}>
              {onlineCourseHeader.name}
            </option>
          );
        });
        break;
      case 'Subscription':
        jsx = subscriptions.map((subscription) => {
          return (
            <option key={subscription.id} value={subscription.id}>
              {subscription.name}
            </option>
          );
        });
        break;
      case 'Alumni Event':
        jsx = alumniEventProgramCategories.map((alumniEventProgramCategory) => {
          return (
            <option
              key={alumniEventProgramCategory.id}
              value={alumniEventProgramCategory.name}
            >
              {alumniEventProgramCategory.name}
            </option>
          );
        });
        break;
      default:
        break;
    }
    return jsx;
  };

  const renderProductItems = () => {
    let jsx = [];
    let filtered = [];
    switch (category) {
      case 'Service':
        filtered = serviceItems.filter(
          (e) => e.service_categoryId === Number(productHeader),
        );
        jsx = filtered.map((serviceItem) => {
          return (
            <option key={serviceItem.id} value={serviceItem.id}>
              {serviceItem.name}
            </option>
          );
        });
        break;
      case 'Online Course':
        jsx = onlineCourseItems.map((onlineCourseItem) => {
          return (
            <option
              key={onlineCourseItem.itemId}
              value={onlineCourseItem.itemId}
            >
              {onlineCourseItem.product}
            </option>
          );
        });
        break;
      default:
        break;
    }
    return jsx;
  };

  const renderProgramHeader = () => {
    let filtered = programNames;
    if (productHeader) {
      filtered = filtered.filter(
        (e) => e.program_categoryId === Number(productHeader),
      );
    }
    return filtered.map((name) => {
      return (
        <option key={name.id} value={name.id}>
          {name.name}
        </option>
      );
    });
  };

  const renderAdmins = () => {
    const result = [];
    data.forEach((val) => {
      if (!result.length) {
        result.push(val.createdBy);
      } else {
        let duplicate = false;
        result.forEach((res) => {
          if (res === val.createdBy) duplicate = true;
        });
        if (!duplicate) {
          result.push(val.createdBy);
        }
      }
    });
    return result.map((adminUsername) => {
      return (
        <option key={adminUsername} value={adminUsername}>
          {adminUsername}
        </option>
      );
    });
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
    setProductHeader('');
    setProductItem('');
    setProgramHeader('');
  };

  const handleProductHeader = (e) => {
    setProductHeader(e.target.value);
    setProductItem('');
    setProgramHeader('');
  };

  const handleProgramHeader = (e) => {
    setProgramHeader(e.target.value);
    setProductItem('');
  };

  const handleProductItem = (e) => {
    setProductItem(e.target.value);
  };

  const handleBranch = (e) => {
    setBranch(e.target.value);
  };

  const handleAdmin = (e) => {
    setAdmin(e.target.value);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleDownloadXLS = async () => {
    try {
      const response = await Axios.post(
        '/report/create-sales-xls',
        {
          table: document.getElementById('table-to-xls').outerHTML,
        },
        {
          responseType: 'blob',
        },
      );

      // downloads blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'salesreport.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log(err);
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <Loader type="ThreeDots" color="#53b59f" />
        </div>
      );
    }
    if (groupedData.length === 0) {
      return null;
    }
    if (
      _.isEmpty(groupedData[0]) &&
      _.isEmpty(groupedData[1]) &&
      _.isEmpty(groupedData[2]) &&
      _.isEmpty(groupedData[3]) &&
      _.isEmpty(groupedData[4])
    ) {
      return (
        <div className="d-flex justify-content-center">
          <h3>No Data Found</h3>
        </div>
      );
    }

    return <SalesReportTable groupedData={groupedData} />;
  };

  return (
    <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <div className="mt-4">
        <div
          className="mx-4 p-2"
          style={{
            backgroundColor: '#f8f8f8',
            minHeight: '10vh',
          }}
        >
          <div className="line-green mb-2" />
          <h3>Sales Report</h3>
          <div className="mb-2">
            <div className="d-inline-block">
              <span>Date from</span>
              <DatePicker
                selected={fromDate}
                onChange={(pickedDate) => setFromDate(pickedDate)}
              />
            </div>
            <div className="d-inline-block">
              <span>Date to</span>
              <DatePicker
                selected={toDate}
                onChange={(pickedDate) => setToDate(pickedDate)}
              />
            </div>
          </div>
          <div className="mb-2">
            <div>
              <span>Branch</span>
              <div>
                <select
                  value={branch}
                  onChange={handleBranch}
                  style={{ width: '30%', lineHeight: 'inherit' }}
                >
                  <option value="">All Branch</option>
                  {branches.map((branchItem) => {
                    return (
                      <option key={branchItem.id} value={branchItem.id}>
                        {branchItem.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <button
              type="button"
              className="btn btn-sm btn-secondary my-2 dropdown-toggle btn-collapse-pym"
              onClick={() => setIsOpen(!isOpen)}
            >
              Filter by
            </button>
          </div>
          <Collapse isOpen={isOpen}>
            <div className="mb-2">
              <div>
                <span>Category</span>
                <div>
                  <select
                    value={category}
                    onChange={handleCategory}
                    style={{ width: '30%', lineHeight: 'inherit' }}
                  >
                    <option value="">All Category</option>
                    <option value="Program">Program</option>
                    <option value="Online Course">Online Course</option>
                    <option value="Service">Service</option>
                    <option value="Subscription">Subscription</option>
                    <option value="Alumni Event">Alumni Event</option>
                  </select>
                </div>
              </div>
            </div>
            {category ? (
              <div className="mb-2">
                <div>
                  <span>{category}</span>
                  <div>
                    <select
                      value={productHeader}
                      onChange={handleProductHeader}
                      style={{ width: '30%', lineHeight: 'inherit' }}
                    >
                      <option value="">All {category}</option>
                      {renderProducutHeaders()}
                    </select>
                  </div>
                </div>
              </div>
            ) : null}
            {category === 'Program' ? (
              <div className="mb-2">
                <div>
                  <span>Program Name</span>
                  <div>
                    <select
                      value={programHeader}
                      onChange={handleProgramHeader}
                      style={{ width: '30%', lineHeight: 'inherit' }}
                    >
                      <option value="">All Program Name</option>
                      {renderProgramHeader()}
                    </select>
                  </div>
                </div>
              </div>
            ) : null}
            {productHeader &&
            category !== 'Program' &&
            category !== 'Alumni Event' ? (
              <div className="mb-2">
                <div>
                  <span>Product</span>
                  <div>
                    <select
                      value={productItem}
                      onChange={handleProductItem}
                      style={{ width: '30%', lineHeight: 'inherit' }}
                    >
                      <option value="">All Product</option>
                      {renderProductItems()}
                    </select>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="mb-2">
              <div>
                <span>Admission</span>
                <div>
                  <select
                    value={admin}
                    onChange={handleAdmin}
                    style={{ width: '30%', lineHeight: 'inherit' }}
                  >
                    <option value="">All Admission</option>
                    {renderAdmins()}
                  </select>
                </div>
              </div>
            </div>
            <div className="mb-2">
              <div>
                <span>Status Transaksi</span>
                <div>
                  <input
                    type="radio"
                    name="status"
                    value="paid"
                    checked={status === 'paid'}
                    onChange={(e) => handleStatus(e)}
                    required
                  />
                  <span className="ml-2">Paid</span>
                  <input
                    className="ml-5"
                    type="radio"
                    name="status"
                    value="combine"
                    checked={status === 'combine'}
                    onChange={(e) => handleStatus(e)}
                  />
                  <span className="ml-2">Paid & Unpaid</span>
                  <input
                    className="ml-5"
                    type="radio"
                    name="status"
                    value="unpaid"
                    checked={status === 'unpaid'}
                    onChange={(e) => handleStatus(e)}
                  />
                  <span className="ml-2">Unpaid</span>
                </div>
              </div>
            </div>
          </Collapse>
          <div
            className="d-flex justify-content-center"
            style={{ width: '30%' }}
          >
            {!_.isEmpty(groupedData[0]) ||
            !_.isEmpty(groupedData[1]) ||
            !_.isEmpty(groupedData[2]) ||
            !_.isEmpty(groupedData[3]) ||
            !_.isEmpty(groupedData[4]) ? (
              <Button
                onClick={handleDownloadXLS}
                className="btn btn-sm mt-2 mr-5 btn-primary"
              >
                Download as XLS
              </Button>
            ) : null}
            <button
              type="button"
              className="btn btn-success btn-sm mt-2"
              style={{
                backgroundColor: 'rgb(50, 178, 128)',
              }}
              onClick={handleFetch}
              disabled={loading}
            >
              {loading ? (
                <div
                  className="spinner-border text-light spinner-border-sm"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <>
                  <span>Search From Database</span>
                  <FontAwesomeIcon icon={faSearch} className="ml-2" />
                </>
              )}
            </button>
          </div>
        </div>
        <div className="p-4">{renderContent()}</div>
      </div>
    </div>
  );
};

export default NewSalesReportPage;
