import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  Card,
  Label,
  CardBody,
  FormGroup,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomPagination from 'components/commons/CustomPagination';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import CreateReferralCodeModal from './CreateReferralCodeModal';

const ReferralCodePage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const page = params.get('page');

  const [referralCodeData, setReferralCodeData] = useState([]);
  const [filteredReferralCodeData, setFilteredReferralCodeData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedReferralCodeData, setSelectedReferralCodeData] = useState({});

  const [searchInputValues, setSearchInputValues] = useState({
    textField: '',
    valid: false,
    invalid: false,
    available: false,
    unavailable: false,
  });

  const onSearchInputChange = (e) => {
    if (e.target.type === 'checkbox') {
      setSearchInputValues({
        ...searchInputValues,
        [e.target.name]: e.target.checked,
      });
    } else {
      setSearchInputValues({
        ...searchInputValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const filterData = (inputs) => {
    let filteredResult = referralCodeData;
    const { textField, valid, invalid, available, unavailable } = inputs;
    // filter by code, name & vendor
    if (textField) {
      const loweredTextField = textField.toLowerCase();
      filteredResult = filteredResult.filter((referralCode) => {
        const { code, name, vendor } = referralCode;
        return (
          name.toLowerCase().indexOf(loweredTextField) > -1 ||
          code.indexOf(textField) > -1 ||
          vendor.toLowerCase().indexOf(loweredTextField) > -1
        );
      });
    }
    // filter by valid || invalid
    if (valid || invalid) {
      filteredResult = filteredResult.filter((referralCode) => {
        const { validFrom, validTo, quota, totalUsage } = referralCode;
        const validFromDate = new Date(validFrom);
        const validToDate = new Date(validTo);
        const currentDate = new Date();
        const isValid =
          validFromDate < currentDate && validToDate > currentDate;
        const isQuotaAvailable = totalUsage < quota || quota === 0;
        if (valid && invalid) {
          return true;
        }
        if (valid) {
          return isValid && isQuotaAvailable;
        }
        if (invalid) {
          return !(isValid && isQuotaAvailable);
        }
        return true;
      });
    }
    // filter by availability
    if (available || unavailable) {
      filteredResult = filteredResult.filter((referralCode) => {
        const { isAvailable } = referralCode;
        if (available && unavailable) {
          return true;
        }
        if (available) {
          return isAvailable;
        }
        if (unavailable) {
          return !isAvailable;
        }
        return true;
      });
    }

    setFilteredReferralCodeData(filteredResult);
    history.push('?page=1');
  };

  useEffect(() => {
    if (referralCodeData.length) {
      filterData(searchInputValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValues, referralCodeData]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setIsEditMode(false);
    setSelectedReferralCodeData({});
  };

  const openEditModal = (referralCode) => {
    setIsEditMode(true);
    setSelectedReferralCodeData(referralCode);
    setIsOpen(true);
  };

  const getData = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v2/referral-code/all`,
    );
    if (page > Math.abs(data.data.length / 10) || !page) {
      history.push('?page=1');
    }
    setReferralCodeData(data.data);
    setFilteredReferralCodeData(data.data);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchBar = () => {
    return (
      <Card>
        <CardBody>
          <div className="container-fluid white-box">
            <div className="row">
              <div className="col-5 my-auto">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Search: </InputGroupText>
                  </InputGroupAddon>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Code/Nama/Vendor"
                    name="textField"
                    onChange={onSearchInputChange}
                    value={searchInputValues.textField}
                  />
                </InputGroup>
              </div>
              <div className="col-7 my-auto">
                <FormGroup>
                  <Label for="exampleCheckbox">Status: </Label>
                  <div>
                    <CustomInput
                      type="checkbox"
                      id="checkValid"
                      label="Valid"
                      inline
                      name="valid"
                      onChange={onSearchInputChange}
                      checked={searchInputValues.valid}
                    />
                    <CustomInput
                      type="checkbox"
                      id="checkInvalid"
                      label="Invalid"
                      inline
                      name="invalid"
                      onChange={onSearchInputChange}
                      checked={searchInputValues.invalid}
                    />
                    <CustomInput
                      type="checkbox"
                      id="checkAvailable"
                      label="Available"
                      inline
                      name="available"
                      onChange={onSearchInputChange}
                      checked={searchInputValues.available}
                    />
                    <CustomInput
                      type="checkbox"
                      id="checkUnavailable"
                      label="Unavailable"
                      inline
                      name="unavailable"
                      onChange={onSearchInputChange}
                      checked={searchInputValues.unavailable}
                    />
                  </div>
                </FormGroup>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  const TableEntries = () => {
    const CheckCrossIcon = ({ status }) => {
      return (
        <FontAwesomeIcon
          className="mx-auto text-center"
          icon={['fas', status ? 'check-circle' : 'times-circle']}
          color={status ? 'green' : 'red'}
          size="1x"
        />
      );
    };

    const checkNominal = (nominal, isPercentage) => {
      if (nominal === '0.00') {
        return '0';
      }
      if (isPercentage) {
        return `${nominal}%`;
      }
      return `Rp. ${Number(nominal).toLocaleString('id-ID')}`;
    };

    const slicedFilteredReferralCodeData = filteredReferralCodeData.slice(
      (page - 1) * 10,
      (page - 1) * 10 + 10,
    );

    return slicedFilteredReferralCodeData.map((referralCodeItem) => {
      const {
        id,
        name,
        vendor,
        code,
        nominal,
        isPercentage,
        quota,
        totalUsage,
        validFrom,
        validTo,
        isAvailable,
      } = referralCodeItem;
      return (
        <tr key={id}>
          <th>{id}</th>
          <th>{code}</th>
          <td>{name}</td>
          <td>{vendor}</td>
          <td>{checkNominal(nominal, isPercentage)}</td>
          <td>
            {quota} / {totalUsage}
          </td>
          <td>{moment(validFrom).format('DD/MM/YYYY')}</td>
          <td>{moment(validTo).format('DD/MM/YYYY')}</td>
          <td>
            <CheckCrossIcon status={isAvailable} />
          </td>
          <td>
            <Button onClick={() => openEditModal(referralCodeItem)}>
              Edit
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <div
      className="white-box container-fluid py-2"
      style={{ minHeight: '100vh' }}
    >
      {/* heading section */}
      <div className="row px-1 py-5">
        <div className="col-12 offset-sm-1 col-sm-10">
          <div>
            <div className="line-green mb-4" />
            <div className="general-title text-gray">
              Referral Code
              <Button className="float-right" onClick={toggleModal}>
                + Create Referral Code
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* table section */}
      <div className="row px-1 py-3">
        <div className="col-12 offset-sm-1 col-sm-10">
          {searchBar()}
          <Table striped bordered responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Code</th>
                <th>Nama</th>
                <th>Vendor</th>
                <th>Nominal</th>
                <th>Quota / Usage</th>
                <th>Valid From</th>
                <th>Valid To</th>
                <th>Available</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <TableEntries />
            </tbody>
          </Table>
          <CustomPagination
            pages={Math.ceil(filteredReferralCodeData.length / 10) || 1}
            active={page}
          />
        </div>
      </div>
      <CreateReferralCodeModal
        isOpen={isOpen}
        toggle={toggleModal}
        refresh={getData}
        isEditMode={isEditMode}
        selectedReferralCodeData={selectedReferralCodeData}
      />
    </div>
  );
};

export default ReferralCodePage;
