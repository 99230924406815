import React from 'react';
import Skeleton from 'react-loading-skeleton';

const PoHistorySkeleton = () => {
  return (
    <tr>
      <td className="text-center" style={{ width: '8.56rem' }}>
        <div>
          <Skeleton className="mt-1" />
          <p className="main-text mt-2" style={{ fontSize: '14px' }}>
            <Skeleton />
          </p>
        </div>
      </td>
      <td>
        <Skeleton />
      </td>
      <td style={{ width: '10rem' }}>
        <Skeleton />
      </td>
      <td style={{ width: '10rem' }}>
        <Skeleton />
      </td>
      <td style={{ width: '15rem' }}>
        <Skeleton />
      </td>
      <td className="text-center" style={{ width: '7.25rem' }}>
        <Skeleton />
      </td>
    </tr>
  );
};

export default PoHistorySkeleton;
