import React, { useState } from 'react';
import { Modal, ModalBody, Button, Alert, Spinner } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const DropzoneFile = ({ setFieldValue, values, setShowedAlert }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf, .jpg, .jpeg, .png',
    maxFiles: 5,
    onDrop: (files) => {
      if (values.files.length === 5) {
        setShowedAlert(true);
      } else {
        setFieldValue('files', values.files.concat(files));
      }
    },
  });

  const handleDelete = (index) => {
    const newArray = values.files;
    newArray.splice(index, 1);
    setFieldValue(newArray);
  };

  return (
    <section>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag & drop images or PDFs, or click to select</p>
      </div>
      {Boolean(values.files.length) && (
        <aside>
          {values.files.some(({ type }) => type.includes('image/')) && (
            <>
              <div>Uploaded Images</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  flexWrap: 'wrap',
                  marginBlock: '20px',
                }}
              >
                {values.files.map((file, index) => {
                  if (file.type.includes('image/')) {
                    return (
                      <div
                        style={{
                          width: '23.7%',
                          height: '116px',
                          position: 'relative',
                        }}
                      >
                        <img
                          width="100%"
                          height="100%"
                          src={URL.createObjectURL(file)}
                          alt="test"
                          style={{ objectFit: 'cover' }}
                        />
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          onClick={() => handleDelete(index)}
                          style={{
                            position: 'absolute',
                            top: '-10px',
                            right: '-10px',
                            width: '20px',
                            height: '20px',
                            backgroundColor: 'white',
                            color: 'red',
                            borderRadius: '100%',
                            zIndex: 1,
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </>
          )}
          {values.files.some(({ type }) => type === 'application/pdf') && (
            <>
              <div>Uploaded Files</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  flexWrap: 'wrap',
                  marginBlock: '20px',
                }}
              >
                {values.files.map((file, index) => {
                  if (file.type === 'application/pdf') {
                    return (
                      <div style={{ width: '23.7%', height: 'auto' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100%',
                            gap: '8px',
                            position: 'relative',
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            style={{ width: '50px', height: '50px' }}
                          />
                          <div
                            style={{
                              fontSize: '12px',
                              fontWeight: 500,
                              textAlign: 'center',
                            }}
                          >
                            {file.name}
                          </div>
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={() => handleDelete(index)}
                            style={{
                              position: 'absolute',
                              top: '-10px',
                              right: '-10px',
                              width: '20px',
                              height: '20px',
                              backgroundColor: 'white',
                              color: 'red',
                              borderRadius: '100%',
                              zIndex: 1,
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </>
          )}
        </aside>
      )}
    </section>
  );
};

const ModalUploadPOItemProof = ({ isOpen, toggle, handleUploadImage }) => {
  const validationSchema = Yup.object().shape({
    files: Yup.array()
      .min(1, 'At least one file is required')
      .max(5)
      .required('Files are required'),
  });

  const initialValues = {
    files: [],
  };

  const [showedAlert, setShowedAlert] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle();
        setShowedAlert(false);
      }}
    >
      <ModalBody>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (values) => {
            await handleUploadImage(values.files);
            toggle();
          }}
          enableReinitialize
        >
          {({ setFieldValue, values, errors, isSubmitting }) => (
            <Form>
              <div className="d-flex flex-column">
                <label htmlFor="files" className="font-weight-bold">
                  Upload PO Item Proof
                </label>
                {showedAlert && (
                  <Alert color="danger">
                    You can upload up to 5 files only
                  </Alert>
                )}
                {errors.files && <Alert color="danger">{errors.files}</Alert>}
                <DropzoneFile
                  setFieldValue={setFieldValue}
                  values={values}
                  setShowedAlert={setShowedAlert}
                />
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="success"
                  style={{ backgroundColor: '#32b280', marginTop: '8px' }}
                >
                  {isSubmitting ? <Spinner color="success" /> : 'Submit'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ModalUploadPOItemProof;
