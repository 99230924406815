import React from 'react';
import { Form, Input, FormFeedback, Label } from 'reactstrap';

const SelectionComponent = ({
  arrayOfOptions,
  label,
  value,
  handleSelect,
  disabled,
}) => {
  return (
    <Form className="mb-2">
      <Label for={`form${label}`} className="text-gray font-weight-bold">
        Select {label}
      </Label>
      <Input
        type="select"
        valid={value !== 0}
        invalid={value === 0}
        value={value}
        onChange={({ target }) => handleSelect(target.value)}
        disabled={disabled}
      >
        <option value={0} disabled hidden>
          Select {label}
        </option>
        {arrayOfOptions}
      </Input>
      <FormFeedback invalid="true">*Please Select a {label}</FormFeedback>
    </Form>
  );
};

export default SelectionComponent;
