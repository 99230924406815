import React, { useEffect } from 'react';
import { fetchProgramSalesByTrxId } from 'redux/modules/programSales';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import Loader from 'react-loader-spinner';
import moment from 'moment';

const SalesTable = (props) => {
  const { data, setSelectedSales, selectedSales } = props;
  const dispatch = useDispatch();

  const programSales = useSelector((state) => state.programSales.data);
  const loading = useSelector((state) => state.programSales.loading);

  useEffect(() => {
    dispatch(fetchProgramSalesByTrxId(data.id));
  }, [dispatch, data]);

  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
        <Loader type="ThreeDots" color="#53b59f" height={40} width={40} />
      </div>
    );
  }
  return (
    <>
      <hr />
      <div
        className="p-3"
        style={{
          backgroundColor: '#ffffff',
          height: '100%',
          borderRadius: '6px',
          boxShadow: '0 20px 40px 0 rgba(102, 102, 102, 0.16)',
        }}
      >
        <div className="row d-flex p-3">
          <div className="col-12">
            <h5 className="text-center">Select Program Sales</h5>
          </div>
          <div className="col-12">
            <Table size="sm" bordered responsive hover>
              <thead
                className="font-weight-bold thead-light"
                style={{ fontSize: '14px', color: '#505050' }}
              >
                <tr className="d-flex">
                  <th className="col-1">#</th>
                  <th className="col-3">PROGRAM CODE</th>
                  <th className="col-3">DATE</th>
                  <th className="col-1">QTY</th>
                  <th className="col-3">REGISTERED ATTENDEES</th>
                  <th className="col-1">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {programSales.map((sales, i) => {
                  let count = 0;
                  sales.program_sales_attendees.forEach((attendee) => {
                    if (sales.code === 'WE') {
                      if (
                        attendee.attendeeName &&
                        attendee.attendeePhone &&
                        attendee.attendeeEmail
                      ) {
                        count += 1;
                      }
                    } else if (
                      attendee.attendeeName &&
                      attendee.attendeePhone &&
                      attendee.attendeeEmail &&
                      attendee.identityCard &&
                      attendee.jacketSize
                    ) {
                      count += 1;
                    }
                  });

                  const startDate = moment(sales.startDate).format('MMM YYYY');
                  const endDate = moment(sales.endDate).format('MMM YYYY');

                  return (
                    <tr
                      key={sales.id}
                      className={`d-flex ${
                        selectedSales.id === sales.id ? 'table-active' : ''
                      }`}
                    >
                      <td className="col-1">{i + 1}</td>
                      <td className="col-3">{sales.code}</td>
                      <td className="col-3">{`${startDate} - ${endDate}`}</td>
                      <td className="col-1">{sales.qty}</td>
                      <td className="col-3">{count}</td>
                      <td className="col-1">
                        <Button
                          active={selectedSales.id === sales.id}
                          onClick={() => setSelectedSales(sales)}
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesTable;
