import React from 'react';
import { Table } from 'reactstrap';

const TransactionTable = ({ children }) => {
  return (
    <Table striped bordered responsive hover>
      <thead
        className="font-weight-bold"
        style={{ fontSize: '14px', color: '#505050' }}
      >
        <tr>
          <th>STATUS</th>
          <th>ORDER</th>
          <th>INVOICE</th>
          <th>CUSTOMER</th>
          <th>TOTAL</th>
          <th>PAID</th>
          <th>DEADLINE</th>
          <th>ACTION</th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </Table>
  );
};

export default TransactionTable;
