import React from 'react';
import { Card, CardHeader, Button, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SpeakerCard = ({
  index,
  onClick,
  onDelete,
  onActionSpeaker,
  selectedData,
}) => {
  const onEditSpeaker = () => {
    onActionSpeaker(true, selectedData);
  };

  return (
    <Card className="mb-2">
      <CardHeader
        onClick={onClick}
        className="d-flex align-items-center justify-content-between"
      >
        {selectedData?.name}
        <Row className="d-flex align-items-center mr-1">
          <Button onClick={onEditSpeaker} className="mr-3" color="warning">
            Edit
          </Button>

          <FontAwesomeIcon
            icon={['fas', 'times']}
            color="#495057"
            size="1x"
            className="cursor-pointer"
            onClick={() => onDelete(index)}
          />
        </Row>
      </CardHeader>
    </Card>
  );
};

export default SpeakerCard;
