import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import axios from 'supports/api';
import AddPromoModal from './AddPromoModal';
import ConfirmationModal from './ConfirmationModal';

const PromosTable = ({ rows, page, setType, type, refresh, limit }) => {
  const [selectedData, setSelectedData] = useState({});
  const [selectedId, setSelectedId] = useState(0);
  const [promoModal, setPromoModal] = useState(false);
  const [handleConfirmModal, setHandleConfirmModal] = useState(false);

  const startIndex = (page - 1) * limit;

  const tableCellStyle = {
    verticalAlign: 'middle',
  };

  const formatNominal = (value) => {
    return value.toLocaleString('id-ID');
  };

  const deletePromo = async (id) => {
    try {
      await axios.delete(`/v2/transaction-promos/${id}`);

      setHandleConfirmModal(false);

      refresh();
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <>
      <Table striped bordered responsive hover>
        <thead
          className="font-weight-bold"
          style={{ fontSize: '14px', color: '#505050' }}
        >
          <tr>
            <th>#</th>
            <th>PROMO</th>
            <th>UNIT</th>
            <th>NOMINAL</th>
            <th>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((list, index) => {
            const { id, name, unit, nominal } = list;
            const actualIndex = startIndex + index + 1;

            return (
              <tr key={id}>
                <td style={tableCellStyle}>{actualIndex}</td>
                <td style={tableCellStyle}>{name}</td>
                <td style={tableCellStyle}>{unit}</td>
                <td style={tableCellStyle}>{formatNominal(nominal)}</td>
                <td style={tableCellStyle}>
                  <>
                    <Button
                      color="primary"
                      size="sm"
                      className="my-1 mr-2"
                      onClick={() => {
                        setPromoModal(true);
                        setType('edit');
                        setSelectedData(list);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      color="secondary"
                      size="sm"
                      className="my-1"
                      onClick={() => {
                        setHandleConfirmModal(true);
                        setSelectedId(id);
                      }}
                    >
                      Remove
                    </Button>
                  </>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <AddPromoModal
        isOpen={promoModal}
        toggle={() => {
          setPromoModal(false);
          setType('');
        }}
        type={type}
        selectedData={selectedData}
        refresh={refresh}
      />
      <ConfirmationModal
        isOpen={handleConfirmModal}
        toggle={() => setHandleConfirmModal(false)}
        message="Are you sure want to delete this promo?"
      >
        <Button onClick={() => deletePromo(selectedId)} color="danger">
          Delete
        </Button>
        <Button onClick={() => setHandleConfirmModal(false)}>Cancel</Button>
      </ConfirmationModal>
    </>
  );
};

export default PromosTable;
