import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Button,
  Label,
  Col,
  Collapse,
  Input,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { TableApproval } from '../components';

const ApprovalTab = ({
  tujuanPenerimaan,
  click,
  setSelectedId,
  setFromTab,
  finalFilterApproval,
  setFinalFilterApproval,
}) => {
  const [renderData, setRenderData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState({
    pymReceipt: '',
    tujPenerimaan: 'All',
  });

  const gPayment = useSelector(({ payment }) => payment.approval);

  useEffect(() => {
    if (gPayment) setRenderData(gPayment);
  }, [gPayment]);

  const toggleCollapse = () => setIsOpen(!isOpen);
  const handleChange = (e) => {
    setFilter({ ...filter, pymReceipt: e.target.value });
  };

  const handleSelect = (e) => {
    setFilter({ ...filter, tujPenerimaan: e.currentTarget.value });
  };

  const renderTujuanPenerimaan = () => {
    return tujuanPenerimaan.map((val) => {
      return (
        <option key={val} value={val.id}>
          {val.name}
        </option>
      );
    });
  };

  const handleSearch = () => {
    setFinalFilterApproval({
      pymReceiptApp: filter.pymReceipt,
      tujPenerimaanApp: filter.tujPenerimaan,
    });
  };

  return (
    <Card>
      <CardBody>
        <div className={isOpen ? 'dropup' : null}>
          <button
            type="button"
            className="btn btn-secondary mt-2 dropdown-toggle"
            onClick={toggleCollapse}
            style={{
              color: 'black',
              backgroundColor: '#E9ECEF',
              width: '100%',
              height: '38px',
              textAlign: 'left',
              transition: '0.4s',
              border: 'none',
            }}
          >
            Filter
          </button>
        </div>
        <Collapse isOpen={isOpen}>
          <Card className="mt-1">
            <CardBody>
              <Form>
                <Col sm={6} md={6}>
                  <FormGroup row>
                    <Label sm={4}>Payment Receipt</Label>
                    <Col sm={7}>
                      <Input type="text" onChange={(e) => handleChange(e)} />
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={6} md={6}>
                  <FormGroup row>
                    <Label sm={4}>Tujuan Penerimaan</Label>
                    <Col sm={7}>
                      <Input type="select" onChange={(e) => handleSelect(e)}>
                        <option value="All">All</option>
                        {renderTujuanPenerimaan()}
                      </Input>
                      {/* </Col> */}
                    </Col>
                  </FormGroup>
                </Col>
              </Form>
            </CardBody>
            <div className="d-flex flex-row-reverse">
              <Button
                color="success"
                className="mt-0"
                style={{
                  position: 'absolute',
                  backgroundColor: 'rgb(50, 178, 128)',
                  width: '20%',
                }}
                onClick={handleSearch}
              >
                Search <FontAwesomeIcon icon={faSearch} className="ml-2" />
              </Button>
            </div>
          </Card>
        </Collapse>
        <TableApproval
          renderData={renderData}
          filterPym={finalFilterApproval.pymReceiptApp}
          filterTujPenerimaan={finalFilterApproval.tujPenerimaanApp}
          click={click}
          setSelectedId={setSelectedId}
          setFromTab={setFromTab}
        />
      </CardBody>
    </Card>
  );
};

export default ApprovalTab;
